import gql from 'graphql-tag';

export const UPLOAD_IMAGES = gql`
    mutation UploadContentManagerImages(
        $files:[Upload],
    ){
        UploadContentManagerImages(
            files:$files
        ){
            contentManagerImageID
            name
        }
    }
`;

export const GET_ALL_IMAGES = gql`
    query AllContentManagerImages{
        AllContentManagerImages{
            contentManagerImageID  
            name
        }
    }
`;

export const DELETE_IMAGES = gql`
    mutation RemoveContentManagerImages($contentManagerImageIDs:[ID]) {
        RemoveContentManagerImages(contentManagerImageIDs:$contentManagerImageIDs)
    }
`;



export const GET_ALL_VIDEOS = gql`
    query AllContentManagerVideos($videoTypeID:ID){
        AllContentManagerVideos(videoTypeID:$videoTypeID){
            contentManagerVideoID  
            contentManagerVideoTypeID  
            name
            poster
            embedCode
            orientation
        }
    }
`;

export const DELETE_VIDEOS = gql`
    mutation RemoveContentManagerVideos($contentManagerVideoIDs:[ID]) {
        RemoveContentManagerVideos(contentManagerVideoIDs:$contentManagerVideoIDs)
    }
`;

export const UPLOAD_VIDEO = gql`
    mutation UploadContentManagerVideo(
        $contentManagerVideoTypeID:ID,
        $videoFile:Upload,
        $posterFile:Upload,
        $embedCode: String,
        $orientation: Int
    ){
        UploadContentManagerVideo(
            contentManagerVideoTypeID:$contentManagerVideoTypeID,
            videoFile:$videoFile,
            posterFile:$posterFile,
            embedCode: $embedCode,
            orientation: $orientation
        ){
            contentManagerVideoID
            contentManagerVideoTypeID
            name
            poster
            embedCode
            orientation
        }
    }
`;