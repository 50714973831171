import InputCard from '../../../GlobalComponents/InputCard';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../GlobalComponents/Checkbox';

const Modules = ({
    allModules,
    showInfoContent,
    modules,
    SetModule,
    dontShowWithoutLink,
    mainCheckboxTitle,
    moduleParamID,
    subModuleParamID
}) => {

    const {t} = useTranslation();
    
    return(
        <div>
            {allModules && allModules.map((module,index) => {
                
                if((module.activeByDefault == 0 || !module.activeByDefault) && (!dontShowWithoutLink || (dontShowWithoutLink && module.link)))
                {
                    var checkedMain = false;
                    if(modules.indexOf(module[moduleParamID]) != -1)
                        checkedMain = true;

                    return(
                        <InputCard
                            key={module[moduleParamID]}
                            className = {((index + 1) != allModules.length ? "form-group" : "")}
                            title = {t(module.label)}
                            headerInfoContent={showInfoContent ? [t(module.label+"Description")] : null}
                            minimalized = {!checkedMain}
                            headerChildren = {
                                
                                <Checkbox
                                    name={"m-"+ (module[moduleParamID])}
                                    id={"m-"+ (module[moduleParamID])}
                                    text = {mainCheckboxTitle}
                                    checked={checkedMain}
                                    OnChange={(e) => SetModule(module[moduleParamID],module[subModuleParamID],e.target.checked)}
                                />  
                            }
                        >   
                            {module[subModuleParamID] && module[subModuleParamID].length > 0 ?
                            
                                <>
                                    {module[subModuleParamID].map((item,ind) => {

                                        var checkedSub = false;
                                        if(modules.indexOf(item[moduleParamID]) != -1)
                                            checkedSub = true;

                                        if((item.activeByDefault == 0 || !module.activeByDefault) && (!dontShowWithoutLink || (dontShowWithoutLink && item.link)))
                                            return(
                                                <div 
                                                    key={item[moduleParamID]}
                                                    className="form-group"
                                                >
                                                    <Checkbox
                                                        name={"sm-" + (item[moduleParamID]) + "-" + (ind + 1)}
                                                        id = {"sm-" + (item[moduleParamID]) + "-" + (ind + 1)}
                                                        text = {t(item.label)}
                                                        checked={checkedSub}
                                                        OnChange={(e) => SetModule(item[moduleParamID],[],e.target.checked)}
                                                        infoContent = {showInfoContent ? [t(item.label + "Description")] : null}
                                                    />
                                                </div>
                                            )
                                        else
                                            return ""
                                    })}
                                </>
                            :null}  
                        </InputCard>
                    )
                }
                else
                    return "";
            })}
        </div>
    )
}

export default Modules;