import React, { useState } from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import UseUploadPhotos from '../../../GlobalHooks/UseUploadPhotos';
import UseGetPhotos from '../../../GlobalHooks/UseGetPhotos';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Loading from '../../../GlobalComponents/Loading';
import { SERVER_URL,TINYMCE_IMAGE_URL } from '../../../Config';
import { useTranslation } from 'react-i18next';
import Button from '../../../GlobalComponents/Button';
import photosIcon from '../../../Media/Images/Icons/image.webp';
import FileInput from '../../../GlobalComponents/FileInput';
import InputCard from '../../../GlobalComponents/InputCard';
import NoItems from '../../../GlobalComponents/NoItems';
import UseDeletePhotos from '../../../GlobalHooks/UseDeletePhotos';
import Checkbox from '../../../GlobalComponents/Checkbox';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Label from '../../../GlobalComponents/Label';
import { 
	UPLOAD_TINYMCE_IMAGES,
	GET_TINYMCE_IMAGES,
	DELETE_TINYMCE_IMAGES 
} from '../Queries/tinymce';
import Select from '../../../GlobalComponents/Select';

Modal.setAppElement('#root');

const TinyMCEFileManager = ({
	TinyMCECallBack,
	tinyMCEFileType,
	OnClose
}) => {

	const {t} = useTranslation();
	const [imageSize,SetImageSize] = useState("stredni")

	const {
		allPhotos,
		loading,
		error,
		SetAllPhotos
	} = UseGetPhotos(GET_TINYMCE_IMAGES,"AllTinyMCEImages");

    const {loading:uploadLoading,UploadPhotos} = UseUploadPhotos(UPLOAD_TINYMCE_IMAGES,null,null,(d) => {
        SetAllPhotos((prevPhotos) => {
			return 	[...prevPhotos,...d.UploadTinyMCEImages]
		});
    });

    const {
        selectedItems,
        showDeleteNotification, 
        SelectPhoto, 
        DeletePhotos,
        ShowDeleteNotification
    } = UseDeletePhotos(DELETE_TINYMCE_IMAGES,"imagesID",(d) => {

        const IDs = d.DeleteTinyMCEImages;
		SetAllPhotos((prevPhotos) => {

			var newArr = [...prevPhotos];
			for(let i in newArr)
			{
				for(let id of IDs)
				{
					if(id == newArr[i].imageID)
						newArr.splice(i,1);
				}
		}
			return 	newArr
		});
    });

	const ChoosePhoto = (file) => {
		TinyMCECallBack(file)
		OnClose();
	}
    
	return (
		<Modal
            isOpen={true}
            onRequestClose={() => OnClose()}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
			<ModalHeader 
                title = {t("FileManager")} 
                OnClose ={() => OnClose()}
            />
			
			<div className="modal-body">

				{error ?
					<Error text={GetApolloErrorText(error)} />
				:
					(loading || uploadLoading ?
						<Loading />
					:
						<div>
							<div className="form-group">
								<Label
									text={t("SizeOfImageInEditor")}
									infoContent = {[
										t("SizeOfImageTinyMceInfo1"),
										t("SizeOfImageTinyMceInfo2")
									]}
								/>
								<Select
									name = {"imageSize"}
									OnChange = {(e) => SetImageSize(e.target.value)}
									value = {imageSize}
								>
									<option value={"stredni"}>{t("Middle")}</option>
									<option value={"velka"}>{t("Big")}</option>
									<option value={"maxi"}>{t("Max")}</option>
								</Select>
							</div>
							<InputCard
								title = {(tinyMCEFileType == "image" ? t("Photos") : t("Files") )}
								icon = {photosIcon}
								headerChildren = {
									<>
										<FileInput
											showOnlyButton = {true}
											OnChange = {(e) => UploadPhotos(e.target.files)}
											buttonTitle = {t("UploadImages")}
											btnClassName = "narrow"
											inputClassName = "narrow"
										/>
									
										<Button
											className="btn-brown narrow"
											OnClick = {() => ShowDeleteNotification(true)}
										>
											{t("DeleteSelected")}     
										</Button>
										
									</>
								}
							> 
								{allPhotos && allPhotos.length > 0 ?
									<div className="row">
										{allPhotos.map((item) => {

											var check = false;
											for(let val of selectedItems)
											{
												if(val == item.imageID)
													check = true;   
											}

											return(
												<div key={item.imageID} className="col-25">
													<div className="cm-image-container form-group">
														<div className="select">
															<Checkbox 
																text = {""}
																name = "selectedProductPhoto"
																checked = {check}
																OnChange = {() => SelectPhoto(item.imageID)}
															/>
														</div>
														<img 
															onClick={() => ChoosePhoto(SERVER_URL + "/" + TINYMCE_IMAGE_URL + "/" + imageSize + "_" + item.name)}
															src = {SERVER_URL + "/" + TINYMCE_IMAGE_URL + "/stredni_" + item.name} 
															alt={item.name} 
														/>
													</div>
												</div>
											)
										})}
									</div>
								:
									<NoItems text={t("NoImagesHereYet")} />
								}
							</InputCard>
						</div>
					)
				}
										
			</div>
			
			<div className="modal-footer"> 
				<Button
					className={"btn-primary w-100"}
					OnClick={() => OnClose()}
				>
					{t("Close")}
				</Button>
			</div>

			{showDeleteNotification ?
                <ModalNotification 
                    yesNo = {true} 
                    text = {t("DoYouReallyWantToDeletePhotos")} 
                    CallBack = {DeletePhotos} 
                />
            :null}
			
			
		</Modal>
	);
    
  
}

export default TinyMCEFileManager;
