import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditQuestionnaire from "./AddEditQuestionnaire";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllQuestionnaires from "../Library/UseGetAllQuestionnaires.js";
import UseDeleteQuestionnaires from "../Library/UseDeleteQuestionnaires";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { useParams } from "react-router-dom";
import { GetProjectIDFromParam } from "../../../GlobalFunctions/header";
import { UseAuth } from "../../Auth/Library/UseAuth";

const Questionnaires = () => {

    const params = useParams();
    const projectID = GetProjectIDFromParam(params);

    const {t} = useTranslation();
    const {user} = UseAuth();

    const [selectedQuestionnaireID, SetSelectedQuestionnaireID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedQuestionnaireIDs,
        SelectRow,
        SelectAll,
        UpdateList
    } = UseGetAllQuestionnaires(projectID,user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteQuestionnaires
    } = UseDeleteQuestionnaires(selectedQuestionnaireIDs,() => UpdateList());

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("QuestionnairesList")}
                        OnAddButtonClick={() => SetSelectedQuestionnaireID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (questionnairesID) => SetSelectedQuestionnaireID(questionnairesID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedQuestionnaireID || selectedQuestionnaireID == 0 ?
                <AddEditQuestionnaire
                    projectID = {projectID}
                    selectedQuestionnaireID = {selectedQuestionnaireID}
                    SetSelectedQuestionnaireID = {SetSelectedQuestionnaireID}
                    OnAddEditSuccess = {(d) => {
                        UpdateList();
                        SetSelectedQuestionnaireID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteQuestionnaires")} 
                    CallBack={DeleteQuestionnaires} 
                />
            :null}

        </>
    )
}

export default Questionnaires;