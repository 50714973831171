import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PROJECT_IMAGE_URL,SERVER_URL } from "../../../Config";

const UseEditArticleLinks = (
    selectedLinks,
    selectedProjectID
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedLinks)
        {
            const contentData = GetContentData(selectedLinks);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[selectedLinks])

    var headerData = [
        {
            value: t("Project"),
            className:"static smaller text-center"
        },
        {
            value: t("LinkName"),
            className:"flex-1"
        }
    ];   

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            if(val.deleted == undefined || val.deleted == false)
            {
                var cData = {
                    data:[
                        {
                            value: (val.project && val.project.favicon ? 
                                <img 
                                    className="cursor-help" 
                                    height="32"
                                    width = "32"
                                    title={val.project.name} 
                                    src={SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + val.project.favicon} 
                                /> : ""),
                            className:"static smaller text-center"
                        },
                        {
                            value:val.name,
                            className:"flex-1"
                        }
                    ],
                    
                }

                cData.rowID             = val.linkID;
                cData.selected          = false;
                cData.selectedProjectID = selectedProjectID

                contentData.push(cData)
            }
        }
        return contentData;
    }

    return{
        headerData,
        content
    }

}

export default UseEditArticleLinks;