import { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditCustomer from '../Library/UseAddEditCustomer';
import UseGetCustomer from '../Library/UseGetCustomer';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import BasicInformations from './BasicInformations';
import AddressData from './AddressData';

Modal.setAppElement('#root');

const AddEditCustomer = ({selectedCustomerID,SetSelectedCustomerID,OnAddEditSuccess}) => {

    const {t} = useTranslation();
    
    const {
        loading,
        formData,
        AddEditCustomer,
        InitFormData,
        SetFormData,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj
    } = UseAddEditCustomer(selectedCustomerID,(d) => OnAddEditSuccess(d));

    const {
        loading:getLoading,
        error,
        allLanguages,
        allCountries
    } = UseGetCustomer(selectedCustomerID,(d) => {
        InitFormData(d)    
    });

    const [selectedTab, SetSelectedTab] = useState(1);

    var err = "";
    if(error)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedCustomerID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedCustomerID === 0 ? t("AddCustomer") : t("EditCustomer"))}
                OnClose ={() => SetSelectedCustomerID(null)}
            />

            <div className="modal-body">

                <ul className="tabs">
                    <li 
                        className={(selectedTab == 1 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(1)}
                    >{t("BasicInformations")}</li>
                    <li 
                        className={(selectedTab == 2 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(2)}
                    >{t("AddressAndBankAccount")}</li>
                                        
                </ul>

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                {selectedTab == 1 ?
                                    <BasicInformations 
                                        formData = {formData}
                                        allLanguages = {allLanguages}
                                        SetFormData = {SetFormData}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                    />
                                :null} 
                                {selectedTab == 2 ?
                                    <AddressData 
                                        formData = {formData}
                                        allCountries = {allCountries}
                                        SetFormData = {SetFormData}
                                    />
                                :null} 
                                
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditCustomer()}
                >
                    {(selectedCustomerID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditCustomer;