import { useState } from 'react';
import List from '../../../GlobalComponents/List/List';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import eye from '../../../Media/Images/Icons/eye.webp';
import ChooseMembership from '../../Membership/Components/ChooseMembership';
import membershipIcon from '../../../Media/Images/Icons/membership.webp';
import bombIcon from '../../../Media/Images/Icons/bomb.webp';
import Button from '../../../GlobalComponents/Button';
import UseEditMemberships from '../Library/UseEditMemberships';
import InputCard from '../../../GlobalComponents/InputCard';
import { useTranslation } from 'react-i18next';
import SelectBox from '../../../GlobalComponents/SelectBox';
import { TINY_MCE_TOOLBAR_MEDIA } from '../../../Config';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import UseEditProducts from '../Library/UseEditProducts';
import ChooseProducts from '../../Eshop/Components/Products/ChooseProducts';
import UseEditServices from '../Library/UseEditServices';
import Label from '../../../GlobalComponents/Label';
import RadioButton from '../../../GlobalComponents/RadioButton';
import { UseProject } from '../../../AdminProvider';

const MembershipOrder = ({
    formData,
    allProjectLinks,
    projectID,
    SetFormData,
    FillFormSelectedItemsWithObj,
    RemoveFormDataSelectedItems,
    GetFormLangValue,
    SetFormLangData,
    SetFormDataSelectedItemsWithObj
}) => {

    const {t} = useTranslation();
    const {IsModulEnabled} = UseProject();

    const [showAddProducts,SetShowAddProducts] = useState(false);
    const [showAddMemberships,SetShowAddMemberships] = useState(false);
    const [showAddServices,SetShowAddServices] = useState(false);

    const {
        headerData:productHeaderData,
        content:contentData
    } = UseEditProducts(formData.selectedProducts);

    const {
        headerData,
        content,
        OnDragEnd
    } = UseEditMemberships(
        formData.selectedMemberships,
        SetFormData,
        SetFormDataSelectedItemsWithObj
    );

    const {
        headerData:serviceHeaderData,
        content:serviceContent
    } = UseEditServices(
        formData.selectedServices
    );

    return(
        <>   
            <InputCard
                title = {t("Display")}
                icon = {eye}
                className = {"form-group flex-1"}
            >   
                <div className="row">
                    <div className="col-25">
                        <div className="form-group">
                            
                            <Label
                                text= {t("HideAddress")}
                                infoContent={[
                                    t("HideAddressInfo")
                                ]}
                            />
                            <div className="radio-container">
                                <RadioButton
                                    text={t("Yes")}
                                    name="hideAddress"
                                    id="hideAddress-1"
                                    value={1}
                                    checked = {formData.hideAddress === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text={t("No")}
                                    name="hideAddress"
                                    id="hideAddress-2"
                                    value={0}
                                    checked = {formData.hideAddress === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div> 

                    <div className="col-25">
                        <div className="form-group">
                            
                            <Label
                                text= {t("HidePassword")}
                                infoContent={[
                                    t("HidePasswordInfo")
                                ]}
                            />
                            <div className="radio-container">
                                <RadioButton
                                    text={t("Yes")}
                                    name="hidePassword"
                                    id="hidePassword-1"
                                    value={1}
                                    checked = {formData.hidePassword === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text={t("No")}
                                    name="hidePassword"
                                    id="hidePassword-2"
                                    value={0}
                                    checked = {formData.hidePassword === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div> 

                    <div className="col-25">
                        <div className="form-group">
                            
                            <Label
                                text= {t("UseCreditCard")}
                                infoContent={[
                                    t("UseCreditCardInfo")
                                ]}
                            />
                            <div className="radio-container">
                                <RadioButton
                                    text={t("Yes")}
                                    name="useCreditCardForPayment"
                                    id="useCreditCardForPayment-1"
                                    value={1}
                                    checked = {formData.useCreditCardForPayment === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text={t("No")}
                                    name="useCreditCardForPayment"
                                    id="useCreditCardForPayment-2"
                                    value={0}
                                    checked = {formData.useCreditCardForPayment === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div> 

                    {IsModulEnabled(68) ?
                        <div className="col-25">
                            <div className="form-group">
                                
                                <Label
                                    text= {t("NoteIsRequired")}
                                    infoContent={[
                                        t("NoteIsRequiredInfo")
                                    ]}
                                />
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="noteIsRequired"
                                        id="noteIsRequired-1"
                                        value={1}
                                        checked = {formData.noteIsRequired === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="noteIsRequired"
                                        id="noteIsRequired-2"
                                        value={0}
                                        checked = {formData.noteIsRequired === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div> 
                    :null}
                                                                                  
                </div> 
            </InputCard>          
            <InputCard
                title = {t("Meberships")}
                className="form-group"
                icon = {membershipIcon}
                noBodyPadding={true}
                headerInfoContent={[
                    t("LinkMebershipsInfo")
                ]}
                headerChildren = {
                    <>
                        <Button 
                            OnClick = {(e) => {
                                e.stopPropagation();
                                SetShowAddMemberships(true)
                            }}
                            className="btn-primary narrow"
                        >{formData.selectedMemberships && formData.selectedMemberships.length > 0 ? t("Edit") : t("Choose")}</Button>
                    </>

                }
            >   
                <List 
                    headerData = {headerData} 
                    headerClassName = {"no-top-border"}
                    contentData = {content}
                    isSortable = {true}
                    loading = {false}
                    replaceDotsImg = {cancelIcon}
                    OnDragListRowEnd={(e) => OnDragEnd(e)}
                    ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedMemberships","membershipID",[data.rowID])}
                />
            </InputCard>

            {formData.selectedMemberships.length > 1 ?
                <InputCard
                    title = {t("MebershipPackageData")}
                    className="form-group"
                    icon = {membershipIcon}
                    headerInfoContent={[
                        t("MebershipPackageDataInfo1"),
                        t("MebershipPackageDataInfo2")
                    ]}
                >   
                    
                    <div className="form-group">
                        <label>{t("ThanksPage")}</label>
                        <SelectBox 
                            value={formData.thanksPageLinkID}
                            OnChange={(value) => SetFormData("thanksPageLinkID",value)}
                            nameParam = "name"
                            valueParam = "linkID"
                            subParam = "subLink"
                            items={allProjectLinks}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t("EmailAfterPurchaseThisMembership")} ({formData.selectedLang})</label>
                        <TinyMCEEditor 
                            value = {GetFormLangValue("membershipAfterBuyEmailText")}
                            OnEditorChange={(content) => SetFormLangData("membershipAfterBuyEmailText",content)}
                            toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                            useMediaEditor={true}
                        />
                    </div>

                </InputCard>
            :null}

            <InputCard
                title = {t("UpSellBomb")}
                icon = {bombIcon}
                headerInfoContent={[
                    t("MebershipUpSellBombInfo1"),
                    t("MebershipUpSellBombInfo2")
                ]}
            >   
                <InputCard
                    title = {t("Products")}
                    className="form-group"
                    noBodyPadding={true}
                    headerChildren = {
                        <>
                            <Button 
                                OnClick = {(e) => {
                                    e.stopPropagation();
                                    SetShowAddProducts(true)
                                }}
                                className="btn-primary narrow"
                            >{formData.selectedProducts && formData.selectedProducts.length > 0 ? t("Edit") : t("Choose")}</Button>
                        </>

                    }
                >   
                    <List 
                        headerData = {productHeaderData} 
                        headerClassName = {"no-top-border"}
                        contentData = {contentData}
                        isSortable = {false}
                        loading = {false}
                        replaceDotsImg = {cancelIcon}
                        ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedProducts","productID",[data.rowID])}
                    />

                </InputCard>

                <InputCard
                    title = {t("Services")}
                    className="form-group"
                    noBodyPadding={true}
                    headerChildren = {
                        <>
                            <Button 
                                OnClick = {(e) => {
                                    e.stopPropagation();
                                    SetShowAddServices(true)
                                }}
                                className="btn-primary narrow"
                            >{formData.selectedServices && formData.selectedServices.length > 0 ? t("Edit") : t("Choose")}</Button>
                        </>
    
                    }
                >   
                    <List 
                        headerData = {serviceHeaderData} 
                        headerClassName = {"no-top-border"}
                        contentData = {serviceContent}
                        isSortable = {false}
                        loading = {false}
                        replaceDotsImg = {cancelIcon}
                        ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedProducts","productID",[data.rowID])}
                    />

                </InputCard>

            </InputCard>           
            
            {showAddMemberships ? 
                <ChooseMembership
                    noReccuringNoReservations = {false}
                    projectID = {projectID}
                    formData = {formData}
                    SetShowAddMemberships = {SetShowAddMemberships}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}

            {showAddServices ? 
                <ChooseMembership
                    noReccuringNoReservations = {true}
                    selectedParam = "selectedServices"
                    projectID = {projectID}
                    formData = {formData}
                    SetShowAddMemberships = {SetShowAddServices}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}

            {showAddProducts ? 
                <ChooseProducts 
                    formData = {formData}
                    SetShowAddProducts = {SetShowAddProducts}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
        </>
    )
}

export default MembershipOrder;