import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditAdminUser from "./AddEditAdminUser";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllAdminUsers from "../Library/UseGetAllAdminUsers";
import UseDeleteAdminUsers from "../Library/UseDeleteAdminUsers.js";
import ModalNotification from "../../../GlobalComponents/ModalNotification";

const AdminUsers = () => {

    const {t} = useTranslation();

    const [selectedAdminUserID, SetSelectedAdminUserID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedAdminUserIDs,
        SelectRow,
        SelectAll,
        UpdateList
    } = UseGetAllAdminUsers();

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteAdminUsers
    } = UseDeleteAdminUsers(selectedAdminUserIDs,(d) => UpdateList());

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("AdminUserList")}
                        OnAddButtonClick={() => SetSelectedAdminUserID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (AdminUserID) => SetSelectedAdminUserID(AdminUserID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedAdminUserID || selectedAdminUserID == 0 ?
                <AddEditAdminUser
                    selectedAdminUserID = {selectedAdminUserID}
                    SetSelectedAdminUserID = {SetSelectedAdminUserID}
                    OnAddEditSuccess = {(d) => {
                        UpdateList();
                        SetSelectedAdminUserID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteAdminUsers")} 
                    CallBack={DeleteAdminUsers} 
                />
            :null}

        </>
    )
}

export default AdminUsers;