import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

const UseGetPhotos = (query,dataParam,paramID,paramIDValue) => {

    const [allPhotos,SetAllPhotos] = useState([]);
    var variables = {};
    
    if(paramID && paramIDValue)
        variables[paramID] = paramIDValue;

    const {data,loading,error} = useQuery(query,{
        variables,
        fetchPolicy:"network-only"
    })

    useEffect(() => {
        if(data)
            SetAllPhotos(data[dataParam]) 
    },[data])

    return{
        data,
        allPhotos,
        loading,
        error,
        SetAllPhotos
    }
}

export default UseGetPhotos;