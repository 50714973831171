import { useEffect,useState } from "react";
import { useMutation,useQuery } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_SCHEDULED_NEWSLETTER,GENERATE_NEWSLETTER_LANG } from "../Queries/newsletter";
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { GET_CAMPAIGNS_WITH_BASIC_DATA } from "../../Campaign/Queries/campaign";
import { UseSettings } from "../../../AdminProvider";

const UseAddEditScheduledNewsletter = (selectedScheduledNewsletterID,mainLang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();

    const [allCampaigns, SetAllCampaigns] = useState([]);
    
    const {formData, SetFormData, SetCompleteFormData,OpenImage,GetFormLangValue,SetFormLangData} = UseFormHandle({
        selectedLang:mainLang,
        plannedDate:"",
        projectID:0,
        campaignID:0,
        langs:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_NEWSLETTER_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    useEffect(() => {

        if(formData.projectID == 0)
        {
            SetAllCampaigns([]);
            SetFormData("campaignID",0);
        }

    },[formData.projectID])

    const {loading:projectLoading,error:projectError} = useQuery(GET_CAMPAIGNS_WITH_BASIC_DATA,{
        fetchPolicy:"network-only",
        variables:{
            projectID:formData.projectID,
            onlyActive:true
        },
        skip:(formData.projectID == 0),
        onCompleted:(d) => {
            SetAllCampaigns(d.AllCampaigns);
        }
    });

    const [AddEditScheduledNewsletterMutation,{error,loading}] = useMutation(ADD_EDIT_SCHEDULED_NEWSLETTER,{
        onCompleted:async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    newsletterID: d.AddEditScheduledNewsletter.newsletterID,
                    isEdit: (selectedScheduledNewsletterID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditScheduledNewsletter);
                
                const notifyText = (selectedScheduledNewsletterID != 0 ? t("SuccessfullyUpdated") : t("NewNesletterIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("FormHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditScheduledNewsletter = () => {

        if(CheckInputs(formData))
        {   
            let langs = [];
            for(const lang of formData.langs)
            {
                langs.push({
                    lang:       lang.lang,
                    subject:    lang.subject,
                    preheader:  lang.preheader,
                    text:       lang.text
                });
            }

            AddEditScheduledNewsletterMutation({
                variables:{
                    newsletterID:           selectedScheduledNewsletterID,
                    projectID:              formData.projectID,
                    campaignID:             formData.campaignID,
                    plannedDate:            formData.plannedDate,
                    langs:                  langs,
                    generateTranslations:   formData.generateTranslations
                }
            })  
        }
        else
            AddNotification(t("SomeLanguageHasNotFilledSubjectOrText"),false);
            
    } 

    const CheckInputs = (formData) => {

        var check = true;
        var checkAll = false;

        for(let val of formData.langs)
        {
            if((val.subject && !val.text) || (!val.subject && val.text) || (!val.subject && !val.text && val.preheader))
                check = false;

            if(val.subject && val.text)
                checkAll = true;
        }

        return check && checkAll;
    }

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error || generateError || projectError,
        loading: loading || generateLoading || projectLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        allCampaigns,
        AddEditScheduledNewsletter,
        SetFormData,
        InitFormData,
        OpenImage,
        GetFormLangValue,
        SetFormLangData
    }

}

export default UseAddEditScheduledNewsletter;