import { useState } from "react";
import Button from "../../../../GlobalComponents/Button";
import NoItems from "../../../../GlobalComponents/NoItems";
import InputCard from "../../../../GlobalComponents/InputCard";
import searchIcon from '../../../../Media/Images/Icons/search.webp';
import { useTranslation } from 'react-i18next';
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import UseGetAllProductSearchTags from "../../Library/Products/UseGetAllSearchTags";
import List from "../../../../GlobalComponents/List/List";
import AddEditProductSearchTag from "./AddEditProductSearchTag";
import UseDeleteProductSearchTags from "../../Library/Products/UseDeletetSearchTags";
import { useApolloClient } from "@apollo/client";

const SearchTags = ({
    lang,
    selectedProductID
}) => {

    const client = useApolloClient();
    const {t} = useTranslation();

    const [selectedProductSearchTagID, SetSelectedProductSearchTagID] = useState(null);

    const {
        content,
        headerData,
        loading,
        selectedSearchTagIDs,
        SelectRow,
        SelectAll,
    } = UseGetAllProductSearchTags(lang,selectedProductID);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteProductSearchTags
    } = UseDeleteProductSearchTags(selectedSearchTagIDs,selectedProductID,lang,async (d) => {
        await client.refetchQueries({
            include: ["AllProductSearchTags"],
        });
    });
    
    return(
        <div>
            {selectedProductID && selectedProductID != 0 ?
                <>
                    <InputCard
                        title = {t("Sarch")}
                        icon = {searchIcon}
                        noBodyPadding = {true}
                        headerInfoContent = {[
                            t("SearchTagsInfo")    
                        ]}
                        headerChildren = {
                            <>
                                <Button
                                    className="btn-primary narrow"
                                    OnClick = {() => SetSelectedProductSearchTagID(0)}
                                >
                                {t("Add")}     
                                </Button>
                            
                                <Button
                                    className="btn-brown narrow"
                                    OnClick = {() => ShowDeleteNotification(true)}
                                >
                                {t("Delete")}     
                                </Button>
                            </>
                        }
                    > 
                        
                        <List
                            headerData = {headerData} 
                            headerClassName = {"no-top-border"}
                            contentData = {content}
                            isSortable = {false}
                            loading = {loading}
                            OnSelectAll = {(e) => SelectAll(e)}
                            hideOptions = {true}
                            options = {{
                                OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                            }}
                        />
                        
                    </InputCard>
                    {selectedProductSearchTagID || selectedProductSearchTagID == 0 ?
                        <AddEditProductSearchTag
                            lang={lang}
                            productID = {selectedProductID}
                            selectedProductSearchTagID = {selectedProductSearchTagID}
                            SetSelectedProductSearchTagID = {SetSelectedProductSearchTagID}
                        />
                    :null}

                    {showDeleteNotification ?
                        <ModalNotification 
                            yesNo={true} 
                            text={t("DoYouReallyWantToDeleteSearchTags")} 
                            CallBack={DeleteProductSearchTags} 
                        />
                    :null}
                </>
            :
                <NoItems
                    className = {"no-margin justify-content-center"}
                    text={t("ToAddSearchTagsSaveBasicInformationsFirst")} 
                />   
            }

        </div>
    )
}

export default SearchTags;