import {useApolloClient, useQuery} from '@apollo/client';
import { GET_ALL_BILLING_INFORMATIONS } from '../Queries/billingInformation';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllBillingInformations = () => {

    const client = useApolloClient();

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedBillingInformationIDs,SetSelectedBillingInformationIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_ALL_BILLING_INFORMATIONS,{
        fetchPolicy:"network-only"
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllBillingInformations);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("CompanyName"),
            className:"flex-1"
        } 
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.company,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.billingInformationID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,orderID) => {

        var checked = e.target.checked;
        var newSelectedBillingInformationIDs = [...selectedBillingInformationIDs];
        const newList = SelectBillingInformationID(content,orderID,checked,newSelectedBillingInformationIDs)

        SetContent(newList);
        SetSelectedBillingInformationIDs(newSelectedBillingInformationIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedBillingInformationIDs = [];
        const newList = SelectBillingInformationID(content,"all",checked,newSelectedBillingInformationIDs)

        SetSelectedBillingInformationIDs(newSelectedBillingInformationIDs);
        SetContent(newList);
    }

    const SelectBillingInformationID = (list,orderID,checked,selectedBillingInformationIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(orderID == newList[i].rowID || orderID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedBillingInformationIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedBillingInformationIDs.length; j++)
                    {
                        if(selectedBillingInformationIDs[j] == newList[i].rowID)
                            selectedBillingInformationIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectBillingInformationID(newList[i].subData,orderID,checked,selectedBillingInformationIDs)}
        }

        return newList;
    }

    const UpdateListAfterAddEdit = async (selectedBillingInformationID,data) => {

        await client.refetchQueries({
            include: ["HeaderData"],
        });
        
        const { AllBillingInformations } = client.readQuery({ query: GET_ALL_BILLING_INFORMATIONS });
        var newArray = [...AllBillingInformations];

        if(selectedBillingInformationID && selectedBillingInformationID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].billingInformationID == data.billingInformationID)
                {
                    var d = {...newArray[i],
                        company: data.company
                    }

                    newArray[i] = d;
                }
            } 
        }
        else
        {
            newArray.unshift({
                billingInformationID: data.billingInformationID,
                company: data.company
            })
        }
        
        client.writeQuery({ 
            query:GET_ALL_BILLING_INFORMATIONS,
            data:{
                AllBillingInformations: newArray
            } 
        });
    }

    const UpdateListAfterDelete = async (IDs) => {

        await client.refetchQueries({
            include: ["HeaderData"],
        });

        var response = IDs.split(",");
        
        const { AllBillingInformations} = client.readQuery({ query: GET_ALL_BILLING_INFORMATIONS });
        
        var newArray = [...AllBillingInformations];
        
        for(let id of response)
        {
            for(let i in newArray)
            {
                if(newArray[i].billingInformationID == id)
                {
                    newArray.splice(i,1);
                }
            } 
        } 
        
        client.writeQuery({ 
            query:GET_ALL_BILLING_INFORMATIONS,
            data:{
                AllBillingInformations: newArray
            } 
        });
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedBillingInformationIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    }

}

export default UseGetAllBillingInformations;