import InputCard from '../../../GlobalComponents/InputCard';
import { useTranslation } from 'react-i18next';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import articleIcon from '../../../Media/Images/Icons/articles.webp';
import Checkbox from '../../../GlobalComponents/Checkbox';
import List from '../../../GlobalComponents/List/List';
import { useState } from 'react';
import UseEditRecommendedArticles from '../Library/UseEditRecommendedArticles';
import Button from '../../../GlobalComponents/Button';
import ChooseArticles from './ChooseArticles';

const RecommnededArticles = ({
    selectedArticleID,
    formData,
    SetFormData,
    RemoveFormDataSelectedItems,
    FillFormSelectedItemsWithObj
}) => {

    const {t} = useTranslation();

    const [showAddArticles, SetShowAddArticles] = useState(false);
    const {
        headerData,
        content,
        OnDragEnd
    } = UseEditRecommendedArticles(
        formData.selectedArticles,
        selectedArticleID,
        SetFormData
    );
    
    return(
        <>
            <div className={!formData.generateRandomRecommended ? "form-group" : ""}>
                <Checkbox
                    text = {t("GenerateRecommnededArticlesRandomly")}
                    id = {"generateRandomRecommended"}
                    name = "generateRandomRecommended"
                    checked = {formData.generateRandomRecommended}
                    OnChange = {(e) => SetFormData(e)}
                />
            </div>
            {!formData.generateRandomRecommended ?
                
                <InputCard
                    title = {t("Articles")}
                    icon = {articleIcon}
                    noBodyPadding={true}
                    headerChildren = {
                        <>
                            <Button 
                                OnClick = {(e) => {
                                    e.stopPropagation();
                                    SetShowAddArticles(true)
                                }}
                                className="btn-primary narrow"
                            >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>  
                        </>
                    }
                >       
                    <List 
                        headerData = {headerData} 
                        headerClassName = {"no-top-border"}
                        contentData = {content}
                        isSortable = {true}
                        loading = {false}
                        OnDragListRowEnd={(result) => OnDragEnd(result)}
                        replaceDotsImg = {cancelIcon}
                        ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedArticles","articleID",[data.rowID])}
                    />

                </InputCard>
            :null}

            {showAddArticles ?
                <ChooseArticles 
                    formData = {formData}
                    SetShowAddArticles = {SetShowAddArticles}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
        </>
    )
}

export default RecommnededArticles;