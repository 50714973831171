import Checkbox from "./Checkbox";
import { useTranslation } from "react-i18next";

const GenerateTranslationsSettings = ({items,generateTranslations,OnSelectTranslationItems,OnClose}) => {

    const {t} = useTranslation();
    
    const SetItem = (param) => {

        var arr = [...generateTranslations.onlyForParams];
        var checked = false;

        for(let i = 0; i < arr.length; i++)
        {
            if(arr[i] == param)
            {
                arr.splice(i,1);
                checked = true;
            }
        }

        if(!checked)
            arr.push(param);

        OnSelectTranslationItems(arr)
    }

    return(
        <>
            <div className="translate-settings">

                <div className="form-group">
                    <label>{t("GenerateTranslationFor")}</label>
                </div>

                <div className="checkboxes">

                    {items.map((item,index) => {

                        var checked = false;
                        for(let val of generateTranslations.onlyForParams)
                        {
                            if(val == item.param)
                                checked = true;
                        }

                        return(
                            <div className="form-group">
                                <Checkbox 
                                    key={index}
                                    name="generateTranslationsOnlyForParam" 
                                    id={"generateTranslationsOnlyForParam" + index}
                                    checked={checked} 
                                    OnChange={(e) => SetItem(item.param)} 
                                    text = {item.name}
                                />
                            </div>
                        )
                    })}                
                </div>
            </div>
            <div 
                className="translate-settings-overall"
                onClick = {() => {
                    if(OnClose) OnClose()
                }}
            ></div>
        </>
    )
}

export default GenerateTranslationsSettings;