import { MAX_VIDEO_FILE_SIZE_TO_UPLOAD } from "../Config";

const VIDEO_MIMES = ['video/mp4'];

export const ValidateVideoMimeType = (file) => {

    var index = VIDEO_MIMES.indexOf(file.type);
    if(index == -1)
        return false
    else
        return true
}

export const ValidateSize = (file) => {
  return file.size <= MAX_VIDEO_FILE_SIZE_TO_UPLOAD;
}

