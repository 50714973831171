import {useQuery} from '@apollo/client';
import { GET_TICKLABELS } from '../../Queries/tickLabels';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import YesNo from '../../../../GlobalComponents/YesNo';

const UseGetAllTickLabels = (lang) => {

    const [variables,SetVariables] = useState({
        lang,
        limit:40,
        offset:0
    });

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedTickLabelIDs,SetSelectedTickLabelIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_TICKLABELS,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllTickLabels: [...prev.AllTickLabels, ...fetchMoreResult.AllTickLabels]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllTickLabels);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("TickLabelName"),
            className:"flex-1"
        },
        {
            value: t("Active"),
            className:"static text-center"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value: <YesNo isYes = {!!val.active} />,
                        className:"static text-center"
                    }
                ],
            }

            cData.rowID    = val.tickLabelID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,tickLabelID) => {

        var checked = e.target.checked;
        var newSelectedTickLabelIDs = [...selectedTickLabelIDs];
        const newList = SelectTickLabelID(content,tickLabelID,checked,newSelectedTickLabelIDs)

        SetContent(newList);
        SetSelectedTickLabelIDs(newSelectedTickLabelIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedTickLabelIDs = [];
        const newList = SelectTickLabelID(content,"all",checked,newSelectedTickLabelIDs)

        SetSelectedTickLabelIDs(newSelectedTickLabelIDs);
        SetContent(newList);
    }

    const SelectTickLabelID = (list,tickLabelID,checked,selectedTickLabelIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(tickLabelID == newList[i].rowID || tickLabelID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedTickLabelIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedTickLabelIDs.length; j++)
                    {
                        if(selectedTickLabelIDs[j] == newList[i].rowID)
                            selectedTickLabelIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectTickLabelID(newList[i].subData,tickLabelID,checked,selectedTickLabelIDs)}
        }

        return newList;
    }

    const UpdateListAfterAddEdit = (selectedTickLabelID,data) => {
        
        const { AllTickLabels, AllTickLabelsCount } = client.readQuery({ query: GET_TICKLABELS, variables });
        var newArray = [...AllTickLabels];
        var newAllTickLabelsCount = AllTickLabelsCount;

        if(selectedTickLabelID && selectedTickLabelID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].tickLabelID == data.tickLabelID)
                {
                    newArray[i] = {...newArray[i],
                        name: data.name,
                        active: data.active
                    };
                }
            } 
        }
        else
        {
            newAllTickLabelsCount++
            newArray.unshift({
                tickLabelID: data.tickLabelID,
                name: data.name,
                active: data.active
            })
        }
        
        client.writeQuery({ 
            query:GET_TICKLABELS,
            variables,
            data:{
                AllTickLabels: newArray,
                AllTickLabelsCount: newAllTickLabelsCount
            } 
        });
    }

    const UpdateListAfterDelete = (IDs) => {

        var response = IDs.split(",");
        
        const { AllTickLabels,AllTickLabelsCount } = client.readQuery({ query: GET_TICKLABELS, variables });
        
        var newArray = [...AllTickLabels];
        var newAllTickLabelsCount = AllTickLabelsCount;
        
        for(let id of response)
        {
            for(let i in newArray)
            {
                if(newArray[i].tickLabelID == id)
                {
                    newArray.splice(i,1);
                    newAllTickLabelsCount--
                }
            } 
        } 
        
        client.writeQuery({ 
            query:GET_TICKLABELS,
            variables,
            data:{
                AllTickLabels: newArray,
                AllTickLabelsCount: newAllTickLabelsCount
            } 
        });
    }

    return{
        content,
        totalContentLength: data && data.AllTickLabelsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedTickLabelIDs,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    }

}

export default UseGetAllTickLabels;