import {useQuery} from '@apollo/client';
import { GET_ALL_ARTICLES } from '../Queries/article';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,ARTICLE_IMAGE_URL } from '../../../Config';

const UseChooseArticles = (lang,selectedArticles,FillFormSelectedItemsWithObj) => {

    const [variables,SetVariables] = useState({
        lang,
        searchText:"",
        limit:40,
        offset:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedArticleIDs,SetSelectedArticleIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_ALL_ARTICLES,{
        variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllArticles: [...prev.AllArticles, ...fetchMoreResult.AllArticles]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllArticles);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("ArticleTitle"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];
        
        for(let val of data)
        {

            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.photo ? SERVER_URL + "/" + ARTICLE_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.title,
                        className:"flex-1"
                    }
                ],
            }

            if(selectedArticles.length > 0)
                cData.selected =  (selectedArticles.indexOf(val.articleID) != -1 ? true : false); 
            else
                cData.selected = false;

            cData.rowName  = val.title;
            cData.rowPhoto = val.photo;
            cData.rowID    = val.articleID;

            contentData.push(cData)
        }
        return contentData;
    }

    const FilterArticles = (filterData) => {

        var newVariables = {...variables};
        var updated = false;
       
        if(filterData.searchText != "" || (filterData.searchText == "" && newVariables.searchText != ""))
        {
            newVariables.searchText = filterData.searchText;
            updated = true;
        }

        if(updated)
            SetVariables(newVariables)

    }

    const SelectRow = (e,articleID) => {

        var checked = e.target.checked;
        var newSelectedArticleIDs = [...selectedArticleIDs];
        const newList = SelectArticleID(content,articleID,checked,newSelectedArticleIDs)

        SetContent(newList);
        SetSelectedArticleIDs(newSelectedArticleIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedArticleIDs = [];
        const newList = SelectArticleID(content,"all",checked,newSelectedArticleIDs)

        SetSelectedArticleIDs(newSelectedArticleIDs);
        SetContent(newList);
    }

    const SelectArticleID = (list,articleID,checked,rowsToSelect) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(articleID == newList[i].rowID || articleID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                {
                    for(let j = 0; j < rowsToSelect.length; j++)
                    {
                        if(!rowsToSelect[j].selected)
                            FillFormSelectedItemsWithObj("selectedArticles","articleID",{
                                title: rowsToSelect[j].rowName,
                                articleID: rowsToSelect[j].rowID,
                                photo: rowsToSelect[j].rowPhoto
                            },false);
                    }
                }

                FillFormSelectedItemsWithObj("selectedArticles","articleID",{
                    title: newList[i].rowName,
                    articleID: newList[i].rowID,
                    photo: newList[i].rowPhoto
                },false);
            }                
        }

        return newList;
    }

    return{
        content,
        totalContentLength: data && data.AllArticlesCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedArticleIDs,
        variables,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll,
        FilterArticles
    }

}

export default UseChooseArticles;