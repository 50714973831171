import gql from 'graphql-tag';

export const ADD_EDIT_TICKLABEL = gql`
    mutation AddEditTickLabel(
        $tickLabelID:ID,
        $active:Int, 
        $langs:[TickLabelLangsInput]
    ){
        AddEditTickLabel(
            tickLabelID:$tickLabelID,
            active:$active, 
            langs:$langs
        ){
            tickLabelID
            name
            active
        }
    }
`;

export const GET_TICKLABEL = gql`
    query TickLabelWithLangs($tickLabelID: ID){
        TickLabelWithLangs(tickLabelID:$tickLabelID){
            tickLabelID
            active
            langs{
                lang
                name
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_TICKLABELS = gql`
    query AllTickLabels($lang: String,$limit:Int,$offset:Int){
        AllTickLabels(lang:$lang,limit:$limit,offset:$offset){
            tickLabelID
            name
            active
        }
        AllTickLabelsCount
    }
`;

export const DELETE_TICKLABELS = gql`
    mutation DeleteTickLabels($tickLabelIDs:ID) {
        DeleteTickLabels(tickLabelIDs:$tickLabelIDs)
    }
`;