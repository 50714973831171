import React from 'react';
import NoItems from '../GlobalComponents/NoItems';
import { useTranslation } from 'react-i18next';
import ContentBody from '../GlobalComponents/ContentBody';

const NoEnabledProjectModule = () => {

    const {t} = useTranslation();
		
	return (
        <ContentBody>
            <div className={"no-module-enabled"}>
                <NoItems text={t("ThisContentIsNotAvailableBecouseModeIsNotEnabled")} />
            </div>
        </ContentBody>
	);
	
}


export default NoEnabledProjectModule;