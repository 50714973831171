import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButton from '../../../GlobalComponents/RadioButton';
import Input from '../../../GlobalComponents/Input';
import InputCard from '../../../GlobalComponents/InputCard';
import eye from '../../../Media/Images/Icons/eye.webp';
import FileInput from '../../../GlobalComponents/FileInput';
import imageIcon from '../../../Media/Images/Icons/image.webp';
import calendarIcon from '../../../Media/Images/Icons/calendar.webp';
import fileIcon from '../../../Media/Images/Icons/file.webp';
import projectIcon from '../../../Media/Images/Icons/projects.webp';
import Info from '../../../GlobalComponents/Info';
import Label from '../../../GlobalComponents/Label';
import Select from '../../../GlobalComponents/Select';
import ChooseCustomers from '../../Customer/Components/ChooseCustomers';
import userIcon from '../../../Media/Images/Icons/user.webp';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import Button from '../../../GlobalComponents/Button';
import List from '../../../GlobalComponents/List/List';
import UseEditReservationPlaceCustomers from '../Library/UseEditReservationPlaceCustomers';

const BasicInformations = ({
    selectedProjectID,
    formData,
    allProjects,
    SetFormData,
    OpenMedia,
    SetSelectedProjectID,
    OpenImageToArrayOfObject,
    GetFormLangArrayValue,
    SetFormLangArrayData,
    GetFormArrayValueByParam,
    FillFormSelectedItemsWithObj,
    SetFormDataSelectedItemsWithObj
}) => {

    const {t} = useTranslation();

    const [showAddCustomers,SetShowAddCustomers] = useState(false);
    const {headerData,content} = UseEditReservationPlaceCustomers(formData.selectedCustomers);

    return(
        
        <>
            <div className={"row form-group"}>
                
                <div className="col-50 d-flex flex-direction-column">

                    <div className="form-group">
                        <label>*{t("InternalName")}</label>
                        <Input
                            name="internalName"
                            value={formData.internalName}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>

                    <InputCard
                        title = {t("Display")}
                        icon = {eye}
                        className = {"flex-1"}
                    >   
                        <div className="row">
                            <div className="col-100">
                                <div className="form-group">
                                    
                                    <label>{t("Active")} </label>
                                    <div className="radio-container">
                                        <RadioButton
                                            text={t("Yes")}
                                            name="active"
                                            id="active-1"
                                            value={1}
                                            checked = {formData.active === 1 ? true : false}
                                            OnChange={(e) => SetFormData(e)}
                                        />
                                        <RadioButton
                                            text={t("No")}
                                            name="active"
                                            id="active-2"
                                            value={0}
                                            checked = {formData.active === 0 ? true : false}
                                            OnChange={(e) => SetFormData(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-100">
                                <div className="form-group">
                                    
                                    <label>{t("ConnectGoogleCalendar")} </label>
                                    <div className="radio-container">
                                        <RadioButton
                                            text={t("Yes")}
                                            name="useGoogleCalendar"
                                            id="useGoogleCalendar-1"
                                            value={1}
                                            checked = {formData.useGoogleCalendar === 1 ? true : false}
                                            OnChange={(e) => SetFormData(e)}
                                        />
                                        <RadioButton
                                            text={t("No")}
                                            name="useGoogleCalendar"
                                            id="useGoogleCalendar-2"
                                            value={0}
                                            checked = {formData.useGoogleCalendar === 0 ? true : false}
                                            OnChange={(e) => SetFormData(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                                                                                                    
                    </InputCard>

                </div>
                <div className="col-50">

                    <InputCard
                        title = {t("ProjectData")}
                        icon={projectIcon}
                        headerChildren = {
                            <Select
                                className="narrow"
                                name="projectID"
                                value={selectedProjectID}
                                OnChange = {(e) => SetSelectedProjectID(e.target.value)}
                            >
                                {allProjects.map((item) => (
                                    <option
                                        key={item.projectID}
                                        value={item.projectID}
                                    >{item.name}</option>
                                ))}
                                
                            </Select>
                        }
                    >
                        <div className="form-group">
                            <label>{t("NameInProject")} ({formData.selectedLang})</label>
                            <Input
                                name="name"
                                value={GetFormLangArrayValue("projectData","projectID",selectedProjectID,"name")}
                                OnChange={(e) => SetFormLangArrayData(e,"projectData","projectID",selectedProjectID)}
                            />
                        </div>
                    
                        <InputCard
                            title = {t("Image")}
                            icon = {imageIcon}
                            className = {"form-group"}
                            
                        > 
                            {GetFormArrayValueByParam("projectData","projectID",selectedProjectID,"mainPhoto") ?
                                
                                <div className="open-image-container form-group">
                                    <img className="no-margin" src = {GetFormArrayValueByParam("projectData","projectID",selectedProjectID,"mainPhoto")} />
                                </div>
                                
                            :null}

                            <div className="form-group">                
                                <FileInput
                                    name = "photo"
                                    OnChange = {(e) => OpenImageToArrayOfObject(e,"projectData","photo","mainPhoto","projectID",selectedProjectID)}
                                />
                            </div>

                        </InputCard>
                    </InputCard>

                </div>
            </div>

            <InputCard
                title = {t("Users")}
                icon = {userIcon}
                className = {(formData.useGoogleCalendar ? "form-group" : "")}
                noBodyPadding={true}
                headerInfoContent={[
                    t("ReservationPlaceUsersInfo1")
                ]}
                headerChildren = {
                    <Button 
                        OnClick = {(e) => {
                            e.stopPropagation();
                            SetShowAddCustomers(true)
                        }}
                        className="btn-primary narrow"
                    >
                        {formData.selectedCustomers && formData.selectedCustomers.length > 0 ? 
                            t("Edit") 
                        : 
                            t("Choose")
                        }
                    </Button>
                }
            >   
                <List 
                    headerData = {headerData} 
                    headerClassName = {"no-top-border"}
                    contentData = {content}
                    isSortable = {false}
                    loading = {false}
                    replaceDotsImg = {cancelIcon}
                    ReplaceDotsOnClick = {(data) => SetFormDataSelectedItemsWithObj("deleted","selectedCustomers","customerID",data.rowID,true)}
                />
            </InputCard> 

            {formData.useGoogleCalendar ?
            
                <InputCard
                    title = {t("ConnectGoogleCalendar")}
                    icon = {calendarIcon}
                    headerInfoContent = {[t("ConnectGoogleCalendarInfo")]}
                >   
                    <div className="row">
                        <div className="col-50 d-flex">

                            <InputCard
                                title = {"*" + t("JSONfile")}
                                icon = {fileIcon}
                                className = {"form-group flex-1"}
                                headerInfoContent = {[t("JSONfileGoogleCalendarInfo")]}
                            >   
                                <div className="align-items-center">
                                    {formData.googleCredentialsFileName ?
                                        
                                        <Info 
                                            className="word-break"
                                            text={formData.googleCredentialsFileName} 
                                        />
                                        
                                    :null}

                                    <div className="form-group">                
                                        <FileInput
                                            label = {t("ChooseFile")}
                                            name = "googleCredentialsFile"
                                            OnChange = {(e) => OpenMedia(e,"googleCredentialsFile","googleCredentialsFileName")}
                                        />
                                    </div>
                                </div>
                                
                            </InputCard>

                        </div>
                        <div className="col-50">
                            <div className="form-group">
                                <Label 
                                    text={"*" + t("Email")} 
                                    infoContent = {[t("ReservationGoogleCalendarEmailInfo")]}
                                />
                                <Input
                                    name="email"
                                    value={formData.email}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                            <div className="form-group">
                                <label>*{t("GoogleCalendarID")}</label>
                                <Input
                                    name="googleCalendarID"
                                    value={formData.googleCalendarID}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>
                    
                </InputCard>
            :null}

            {showAddCustomers ? 
                <ChooseCustomers 
                    title = {t("ChooseUsers")}
                    formData = {formData}
                    SetShowAddCustomers = {SetShowAddCustomers}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
        </>                   
    )
}

export default BasicInformations;