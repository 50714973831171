import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../Queries/project";
import { useState } from 'react';
import { GetMainLanguage, CompleteLangsArray } from "../../../GlobalFunctions/helper";
import { GetProjectPortalModuleObj } from "./UseEditProjectPortalMenu";
import { 
    UNSUBSCRIBE_EMAIL_TEXT,
    BASE_EMAIL_LINK_COLOR,
    BASE_LINK_COLOR,
    BASE_HEADLINE_COLOR,
    BASE_BUTTON_BG_COLOR,
    BASE_BUTTON_TEXT_COLOR
} from "../../../Config";

const UseGetProject = (selectedProjectID,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allModules, SetAllModules] = useState([]);
    const [allBillingInformations, SetAllBillingInformations] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_PROJECT,{
        variables:{
            projectID:selectedProjectID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                webEmail:"", 
                webTel:"", 
                sendersEmail:"", 
                sendersName:"",
                emailSignature:"",
                emailFooterText:"",
                emailNewsletterSignature:"",
                unsubscribeText: UNSUBSCRIBE_EMAIL_TEXT,
                metaTitle:""
            }

            if(selectedProjectID != 0)
            { 
                let langs = CompleteLangsArray(d.ProjectWithLangs.langs,d.AllLanguageMutations,emptyLang);

                var buttonBgColorType = 1;
                var headlineColorType = 1;

                if(d.ProjectWithLangs.buttonBgColor)
                {
                    const splitedColor = d.ProjectWithLangs.buttonBgColor.split("-");
                    if(splitedColor.length > 1)
                        buttonBgColorType = 2
                }

                if(d.ProjectWithLangs.headlineColor)
                {
                    const splitedColor = d.ProjectWithLangs.headlineColor.split("-");
                    if(splitedColor.length > 1)
                        headlineColorType = 2
                }

                CallBack({
                    billingInformationID:               d.ProjectWithLangs.billingInformationID,
                    name:                               d.ProjectWithLangs.name,
                    logo:                               null,
                    logoFooter:                         null,
                    logoPortal:                         null,
                    logoInvoice:                        null,
                    favicon:                            null,
                    emailHeader:                        null,
                    langs:                              langs,
                    mainLogo:                           d.ProjectWithLangs.logo,
                    mainLogoFooter:                     d.ProjectWithLangs.logoFooter,
                    mainLogoPortal:                     d.ProjectWithLangs.logoPortal,
                    mainLogoInvoice:                    d.ProjectWithLangs.logoInvoice,
                    mainEmailHeader:                    d.ProjectWithLangs.emailHeader,
                    mainFavicon:                        d.ProjectWithLangs.favicon,
                    active:                             d.ProjectWithLangs.active,
                    dateEdit:                           d.ProjectWithLangs.dateEdit, 
                    linkColor:                          d.ProjectWithLangs.linkColor || BASE_LINK_COLOR,
                    emailLinkColor:                     d.ProjectWithLangs.emailLinkColor || BASE_EMAIL_LINK_COLOR,
                    buttonBgColor:                      d.ProjectWithLangs.buttonBgColor || BASE_BUTTON_BG_COLOR,
                    buttonTextColor:                    d.ProjectWithLangs.buttonTextColor || BASE_BUTTON_TEXT_COLOR,
                    buttonBorderRadius:                 d.ProjectWithLangs.buttonBorderRadius, 
                    headlineColor:                      d.ProjectWithLangs.headlineColor || BASE_HEADLINE_COLOR,
                    inputBorderRadius:                  d.ProjectWithLangs.inputBorderRadius, 
                    stripePublishableKey:               d.ProjectWithLangs.stripePublishableKey, 
                    stripeSecretKey:                    d.ProjectWithLangs.stripeSecretKey, 
                    stripeWebHook:                      d.ProjectWithLangs.stripeWebHook, 
                    purchaseInfoEmail:                  d.ProjectWithLangs.purchaseInfoEmail, 
                    newRegistrationInfoEmail:           d.ProjectWithLangs.newRegistrationInfoEmail, 
                    cancelMembershipInTrialPeriodEmail: d.ProjectWithLangs.cancelMembershipInTrialPeriodEmail, 
                    completeQuestionnaireEmail:         d.ProjectWithLangs.completeQuestionnaireEmail, 
                    affiliatePaidOfEmail:               d.ProjectWithLangs.affiliatePaidOfEmail, 
                    invoiceNumberCharacter:             d.ProjectWithLangs.invoiceNumberCharacter,
                    domains:                            GetDomains(d.ProjectWithLangs.domains),
                    modules:                            GetModules(d.ProjectWithLangs.modules),
                    selectedPortalModules:              GetSelectedPortalModules(d.ProjectWithLangs.projectPortalModules),
                    buttonBgColorType,
                    headlineColorType,
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);
                CallBack({
                    langs: langs, 
                    linkColor: BASE_LINK_COLOR,
                    emailLinkColor: BASE_EMAIL_LINK_COLOR, 
                    buttonBgColor: BASE_BUTTON_BG_COLOR, 
                    headlineColor: BASE_HEADLINE_COLOR,
                    buttonTextColor:BASE_BUTTON_TEXT_COLOR
                });
            }

            SetAllModules(d.AllModulesBy);
            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllBillingInformations(d.AllBillingInformations);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    const GetSelectedPortalModules = (membershipPortalModules) => {
    
            if(membershipPortalModules)
            {
                var newPortalModules = [];
                for(let val of membershipPortalModules)
                {
                    newPortalModules.push({...GetProjectPortalModuleObj(val),label:val.portalModule.label});
                }
    
                return newPortalModules;
            }
            else
                return [];
        }

    const GetDomains = (domains) => {

        if(domains)
        {
            var newDomains = [];
            for(let val of domains)
            {
                newDomains.push({...val,isDeleted:false});
            }

            return newDomains;
        }
        else
            return []
    }

    const GetModules = (modules) => {

        if(modules)
        {
            var newModules = [];
            for(let val of modules)
            {
                newModules.push(val.moduleID);
            }

            return newModules;
        }
        else
            return []
    }

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allModules,
        allBillingInformations,
        mainLang
    }

}

export default UseGetProject;