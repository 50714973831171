import { useTranslation } from "react-i18next";
import SearchInput from "../../../GlobalComponents/SearchInput";
import UseSearchCustomer from "../../Reservation/Library/UseSearchCustomer";
import List from "../../../GlobalComponents/List/List";
import plusIcon from '../../../Media/Images/Icons/plus.webp'

const SearchCustomer = ({
    OnChangeCustomer,
    listPosition
}) => {

    const {t} = useTranslation();

    const {
        content,
        SetSearchText,
        SetContent
    } = UseSearchCustomer();

    const SelectCustomer = (data) => {

        SetContent(null);
        if(OnChangeCustomer)
            OnChangeCustomer(data);
    }

    return(
        <div className="search-customer">
            <SearchInput 
                placeholder = {t("SearchCustomer")}
                OnChange = {(text) => SetSearchText(text)}
            />
            <div className = {"search-customer-list" + (listPosition ? " " + listPosition : "")}>
                {content ?
                    <List 
                        contentData = {content}
                        rowClassname = {"cursor-pointer"}
                        hideOptions={true}
                        options = {{
                            OnRowClick: (e,rowData) => SelectCustomer(rowData),
                        }}
                    />
                :null}
            </div>
        </div>
    )
}

export default SearchCustomer;