import { useQuery } from "@apollo/client";
import { GET_ARTICLE } from "../Queries/article";
import { useState } from 'react';
import { GetMainLanguage, CompleteLangsArray } from "../../../GlobalFunctions/helper";

const UseGetArticle = (selectedArticleID,lang,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allProjects, SetAllProjects] = useState([]);
    const [allForms, SetAllForms] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {data,loading,error,refetch} = useQuery(GET_ARTICLE,{
        variables:{
            lang,
            articleID:selectedArticleID
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy:"network-only",
        onCompleted:(d) => {
            
            const emptyLang = {
                title:"", 
                perex:"",
                text:"",
                metaTitle:"",
                metaKeywords:"",
                metaDescription:"",
                publishDate:"",
                projectData:[]
            }

            if(selectedArticleID != 0)
            {                 
                let langs = CompleteLangsArray(d.ArticleWithLangs.langs,d.AllLanguageMutations,emptyLang);

                CallBack({
                    articleID:                      d.ArticleWithLangs.articleID,
                    photo:                          null,
                    mainPhoto:                      d.ArticleWithLangs.photo,
                    generateRandomRecommended:      d.ArticleWithLangs.generateRandomRecommended,
                    alone:                          d.ArticleWithLangs.alone,
                    withoutMainImage:               d.ArticleWithLangs.withoutMainImage,
                    langs:                          langs,
                    selectedLinks:                  GetSelectedLinks(d.ArticleWithLangs.links),
                    selectedProjects:               GetSelectedProjects(d.ArticleWithLangs.links),
                    selectedArticles:               GetSelectedArticles(d.ArticleWithLangs.recommendedArticles),
                    selectedMarketingBanners:       GetSelectedMarketingBanners(d.ArticleWithLangs.marketingBanners),
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);
                CallBack({
                    langs: langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllProjects(d.AllProjects);
            SetAllForms(d.AllForms);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    const GetSelectedMarketingBanners = (marketingBanners) => {

        var selectedMarketingBanners = [];
        for(let val of marketingBanners)
        {
            selectedMarketingBanners.push({
                marketingBannerID: val.marketingBannerID,
                name: val.marketingBanner.name,
                photo: val.marketingBanner.photo
            })
        }

        return selectedMarketingBanners;
    }

    const GetSelectedArticles = (recommendedArticles) => {

        var selectedRecommendedArticles = [];
        for(let val of recommendedArticles)
        {
            selectedRecommendedArticles.push({
                articleID: val.recommendedArticleID,
                title: val.recommendedArticle.title,
                photo: val.recommendedArticle.photo
            })
        }

        return selectedRecommendedArticles;
    }

    const GetSelectedLinks = (links) => {

        var selectedLinks = {};
        for(let val of links)
        {
            if(val.link)
            {
                if(!selectedLinks["" + val.link.projectID + ""])
                    selectedLinks["" + val.link.projectID + ""] = [{
                        linkID: val.linkID,
                        name: val.link.name,
                        project:{
                            favicon:val.link.project.favicon,
                            name:val.link.project.name
                        }
                    }]
                else
                    selectedLinks["" + val.link.projectID + ""].push({
                        linkID: val.linkID,
                        name: val.link.name,
                        project:{
                            favicon:val.link.project.favicon,
                            name:val.link.project.name
                        }
                    })
            }
        }

        return selectedLinks;
    }

    const GetSelectedProjects = (links) => {

        var selectedProjects = [];
        for(let val of links)
        {
            if(val.link)
            {
                var checked = false;
                for(let p of selectedProjects)
                {
                    if(val.link.projectID == p.projectID)
                        checked = true;
                }

                if(!checked)
                    selectedProjects.push({
                        projectID:val.link.projectID,
                        name: val.link.project.name
                    })
            }
        }

        return selectedProjects;
    }

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allProjects,
        allForms,
        mainLang,
        refetch
    }

}

export default UseGetArticle;