import { useState,useMemo } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditCustomerRole from '../Library/UseAddEditCustomerRole';
import UseGetCustomerRole from '../Library/UseGetCustomerRole';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Input from '../../../GlobalComponents/Input';
import InputCard from '../../../GlobalComponents/InputCard';
import Modules from '../../Project/Components/Modules';
import portalIcon from '../../../Media/Images/Icons/portal.webp';
import globalIcon from '../../../Media/Images/Icons/global.webp';
import Checkbox from '../../../GlobalComponents/Checkbox';
import Select from '../../../GlobalComponents/Select';

Modal.setAppElement('#root');

const AddEditCustomerRole = ({selectedCustomerRoleID,SetSelectedCustomerRoleID,OnAddEditSuccess}) => {

    const {t} = useTranslation();
    
    const {
        loading:getLoading,
        error:getError,
        allPortalModules,
        allProjects
    } = UseGetCustomerRole(selectedCustomerRoleID,(d) => {
        InitFormData(d)    
    });

    const {
        loading,
        error,
        formData,
        AddEditCustomerRole,
        InitFormData,
        SetFormData,
        SetPortalModule,
    } = UseAddEditCustomerRole(selectedCustomerRoleID,(d) => OnAddEditSuccess(d));

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedCustomerRoleID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedCustomerRoleID === 0 ? t("AddCustomerRole") : t("EditCustomerRole"))}
                OnClose ={() => SetSelectedCustomerRoleID(null)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                <div className="row">
                                    <div className="col-50">
                                        <div className="form-group">
                                            <label>*{t("Name")}</label>
                                            <Input
                                                name="name"
                                                value={formData.name}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="form-group">
                                            <label>*{t("Project")}</label>
                                            <Select
                                                name="projectID"
                                                value={formData.projectID}
                                                OnChange={(e) => SetFormData(e)}
                                            >
                                                <option val={0} >{"- - -" + t("Choose") + "- - -"}</option>
                                                {allProjects.map((item) => (
                                                    <option
                                                        key={item.projectID}
                                                        value={item.projectID}
                                                    >{item.name}</option>
                                                ))}
                                                
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <InputCard
                                    icon = {portalIcon}
                                    title = {t("EnableModules")}
                                >
                                    <div className="form-group">
                                        <Modules 
                                            allModules = {allPortalModules}
                                            dontShowWithoutLink = {false}
                                            modules={formData.portalModules}
                                            SetModule= {SetPortalModule}
                                            showInfoContent = {true}
                                            mainCheckboxTitle = {t("Enable")}
                                            moduleParamID = "portalModuleID"
                                            subModuleParamID = "subPortalModule"
                                        />
                                    </div>
                                </InputCard>
                                
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditCustomerRole()}
                >
                    {(selectedCustomerRoleID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditCustomerRole;