import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { RESET_PASSWORD } from '../Queries/auth';
import {useMutation } from '@apollo/client';
import UseFormHandle from '../../../GlobalHooks/UseFormHandle';
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';

const UseResetPassword = () => {

	const {t} = useTranslation();
    const navigate = useNavigate();

	const {formData, SetFormData} = UseFormHandle({
		email:""
	});
	
	const [disabled,SetDisabled] = useState(false);
    const [error,SetError] = useState("");
	const [success,SetSuccess] = useState(false);

    useEffect(() => {

		document.addEventListener("keyup",PressKey);
		return () => {
			document.removeEventListener("keyup",PressKey);
		}

	},[formData])

    const [ResetAdminPassword,{loading}] = useMutation(RESET_PASSWORD,{

		onCompleted: async (data) => {

			SetSuccess(true);
		},
		onError:(err) => {

			err = GetApolloErrorText(err);
			if(err == "EMAIL_NOT_EXISTS")
				err = t(err);

			SetError(err);
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	});

    const Reset = () => {

		const {email} = formData;

		SetDisabled(true);
						
		if(email !== ''){

			ResetAdminPassword({
				variables:{
					email
				}
			});  
			
		}else{
			SetDisabled(false);
			SetError(t('NotFilledEmail'));
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	}

    const PressKey = (e) => {
		if(e.key == "Enter")
            Reset();
	}

    return {
        formData,
        disabled,
        error,
		success,
		loading,
		SetFormData,
        Reset
    }

}

export default UseResetPassword;