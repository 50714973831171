import Modal from 'react-modal';
import { useQuery } from '@apollo/client';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import Checkbox from '../../../GlobalComponents/Checkbox';
import { useTranslation } from 'react-i18next';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Loading from '../../../GlobalComponents/Loading';
import { GET_PARAMETERS_WITH_VALUES } from '../../Eshop/Queries/parameters';
import { UseAuth } from '../../Auth/Library/UseAuth';

const ChooseParameters = ({
    formData,
    SetShowAddParameters,
    FillFormSelectedItemsWithObj
}) => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    var lang = user ? user.lang : "";

    const {data,loading,error} = useQuery(GET_PARAMETERS_WITH_VALUES,{
        variables:{
            lang: lang,
            limit:1000000,
            offset:0
        },
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddParameters(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("ChooseParameters")}
                OnClose ={() => SetShowAddParameters(false)}
            />

            <div className="modal-body">

                {error ?
                    <Error text={GetApolloErrorText(error)} />
                :
                    (loading ?
                        <Loading />
                    :
                        <>
                            {data.AllParameters && data.AllParameters.length > 0 ?
                                
                                <div className='row'>

                                    {data.AllParameters.map((item) => {

                                        var check = false;
                                        for(let val of formData.selectedParameters)
                                        {
                                            if(val.parameterID == item.parameterID)
                                                check = true;
                                        }

                                        if(item.values && item.values.length > 0)
                                        {
                                            return(
                                                <div key={item.parameterID} className="col-20">
                                                    <div className="form-group">
                                                        <Checkbox 
                                                            name = "parameters"
                                                            id = {"p-" + item.parameterID}
                                                            checked = {check}
                                                            OnChange={() => FillFormSelectedItemsWithObj("selectedParameters","parameterID",{
                                                                name:item.name,
                                                                parameterID:item.parameterID
                                                            })}
                                                            text = {item.name}
                                                        /> 
                                                    </div>
                                                </div>
                                            )
                                        }
                                        else
                                            return null;
                                    })}
                                    
                                </div> 
                            :null}
                        </>
                    )
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddParameters(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )

}

export default ChooseParameters;