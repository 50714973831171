import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import UseGetAllLinks from "../Library/UseGetAllLinks";
import UseSortableList from "../Library/UseSortableList";
import { useState } from "react";
import AddEditLink from "./AddEditLink";
import { UseAuth } from '../../Auth/Library/UseAuth';
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { useApolloClient } from "@apollo/client";
import UseDeleteLinks from "../Library/UseDeleteLinks";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import UseCopyLink from "../Library/UseCopyLink";
import ContentManager from "../../ContentManager/Components/ContentManager";
import { UseContentManager } from "../../ContentManager/Library/UseContentManager";
import { useParams } from "react-router-dom";
import { GetProjectIDFromParam } from "../../../GlobalFunctions/header";

const Links = ({isEshopCategory}) => {

    const params = useParams();
    const projectID = GetProjectIDFromParam(params);

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedLinkID, SetSelectedLinkID] = useState(null);
    
    const {CopyLink}  = UseCopyLink((d) => ReFetchLinks(d));
    const {SetContentManagerLinkID,SetContentManagerProjectID} = UseContentManager();

    const {
        content,
        loading,
        error,
        headerData,
        selectedLinkIDs,
        allProjects,
        selectedProjectID,
        SelectRow,
        SelectAll,
        SetSelectedProjectID
    } = UseGetAllLinks(user.lang,isEshopCategory,projectID);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteLinks
    } = UseDeleteLinks(selectedLinkIDs,(d) => ReFetchLinks(d));

    const {OnDragEnd} = UseSortableList(client,user.lang,isEshopCategory,(isEshopCategory ? selectedProjectID : projectID));

    const ReFetchLinks = async(d) => {
        await client.refetchQueries({
            include: ["AllLinks"],
        });
    }
    
    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {(isEshopCategory ? t("CategoryList") : t("LinkList"))}
                        allProjects = {allProjects}
                        selectedProjectID = {selectedProjectID}
                        OnAddButtonClick={() => SetSelectedLinkID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                        OnProjectSelect = {(projectID) => SetSelectedProjectID(projectID)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {true}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        OnDragListRowEnd = {(result) => OnDragEnd(result)}
                        options = {{
                            OnEditClick: (linkID) => SetSelectedLinkID(linkID),
                            OnCopyClick: (linkID) => CopyLink(linkID),
                            OnContentClick: !isEshopCategory && ((linkID) => {
                                SetContentManagerLinkID(linkID);
                                SetContentManagerProjectID(projectID);
                            }),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedLinkID || selectedLinkID == 0 ?
                <AddEditLink
                    projectID = {selectedProjectID}
                    isEshopCategory = {isEshopCategory}
                    selectedLinkID = {selectedLinkID}
                    SetSelectedLinkID = {SetSelectedLinkID}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteLinks")} 
                    CallBack={DeleteLinks} 
                />
            :null}

            <ContentManager />
        </>
    )

}

export default Links;