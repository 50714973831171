import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_DATA } from "../Queries/customer";
import { useState } from 'react';
import { GetCustomerRoleObj } from "../../Membership/Library/UseEditCustomerRoles";

const UseGetCustomer = (selectedCustomerID,CallBack) => {

    const [allLanguages, SetAllLanguageMutations] = useState([]);
    const [allCountries, SetAllCountries] = useState([]);

    const {loading,error} = useQuery(GET_CUSTOMER_DATA,{
        variables:{
            customerID:selectedCustomerID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            if(selectedCustomerID != 0)
            { 
                CallBack({
                    lang:                   d.CustomerBy.lang,
                    name:                   d.CustomerBy.name,
                    surname:                d.CustomerBy.surname,
                    email:                  d.CustomerBy.email,
                    tel:                    d.CustomerBy.tel,
                    street:                 d.CustomerBy.street,
                    street2:                d.CustomerBy.street2,
                    city:                   d.CustomerBy.city,
                    zip:                    d.CustomerBy.zip,
                    state:                  d.CustomerBy.state,
                    countryID:              d.CustomerBy.countryID,
                    company:                d.CustomerBy.company,
                    ic:                     d.CustomerBy.ic,
                    dic:                    d.CustomerBy.dic,
                    icdph:                  d.CustomerBy.icdph,
                    deliveryName:           d.CustomerBy.deliveryName,
                    deliverySurname:        d.CustomerBy.deliverySurname,
                    deliveryStreet:         d.CustomerBy.deliveryStreet,
                    deliveryStreet2:        d.CustomerBy.deliveryStreet2,
                    deliveryCity:           d.CustomerBy.deliveryCity,
                    deliveryZip:            d.CustomerBy.deliveryZip,
                    deliveryState:          d.CustomerBy.deliveryState,
                    deliveryCountryID:      d.CustomerBy.deliveryCountryID,
                    internalNote:           d.CustomerBy.internalNote,
                    bankAccount:            d.CustomerBy.bankAccount,
                    swift:                  d.CustomerBy.swift,
                    iban:                   d.CustomerBy.iban,
                    selectedCustomerRoles:  GetCustomerRoles(d.CustomerBy.customerRoles),
                });
            }

            SetAllCountries(d.AllCountries);
            SetAllLanguageMutations(d.AllLanguageMutations);
        }
    });

    const GetCustomerRoles = (customerRoles) => {

        if(customerRoles)
        {
            var newCustomerRoles = [];
            for(let val of customerRoles)
            {
                newCustomerRoles.push(
                    {...GetCustomerRoleObj(val),
                        customerRoleCustomerID: val.customerRoleCustomerID,
                        name:val.customerRole.name,
                        backgroundColor: val.customerRole.backgroundColor
                    }
                );
            }

            return newCustomerRoles;
        }
        else
            return []
    }

    return{
        loading: loading,
        error: error,
        allLanguages,
        allCountries
    }

}

export default UseGetCustomer;