import { useQuery } from "@apollo/client";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";
import { GET_COVER_TITLE } from "../../Queries/coverTitles";

const UseGetCoverTitle = (selectedCoverTitleID,lang,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const {loading,error} = useQuery(GET_COVER_TITLE,{
        variables:{
            coverTitleID:selectedCoverTitleID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:"",                    
            }

            if(selectedCoverTitleID != 0)
            {
                let langs = CompleteLangsArray(d.CoverTitleWithLangs.langs,d.AllLanguageMutations,emptyLang);

                CallBack({
                    selectedLang: lang,
                    shortcut:     d.CoverTitleWithLangs.shortcut,
                    langs:        langs,
                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
        }
    });

    return{
        loading: (loading),
        error: (error),
        allLanguageMutations
    }

}

export default UseGetCoverTitle;