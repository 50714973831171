const Lock = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <path style={{"fill":primaryColor}} d="M384.926,216.993v-83.248C384.926,59.998,327.09,0,256,0S127.075,59.998,127.075,133.745v83.247   c-29.031,31.356-46.806,73.277-46.806,119.277C80.269,433.168,159.102,512,256,512s175.73-78.832,175.73-175.731   C431.731,290.269,413.956,248.349,384.926,216.993z M256,22c58.959,0,106.926,50.128,106.926,111.745v63.169   c-29.642-22.798-66.725-36.376-106.926-36.376c-40.2,0-77.283,13.578-106.925,36.376v-63.169C149.075,72.128,197.041,22,256,22z    M256,490c-84.768,0-153.731-68.963-153.731-153.731S171.233,182.538,256,182.538s153.73,68.963,153.73,153.731   S340.768,490,256,490z"/>
                    <path style={{"fill":primaryColor}} d="M256,377.767c-22.882,0-41.498-18.616-41.498-41.498c0-22.882,18.616-41.498,41.498-41.498   c22.882,0,41.498,18.616,41.498,41.498C297.498,359.151,278.882,377.767,256,377.767z M256,316.771   c-10.752,0-19.498,8.747-19.498,19.498c0,10.751,8.746,19.498,19.498,19.498c10.751,0,19.498-8.747,19.498-19.498   C275.498,325.518,266.751,316.771,256,316.771z"/>
                    <path style={{"fill":secondaryColor}} d="M370.741,336.312c0-0.048-0.007-0.093-0.007-0.141c-0.053-63.201-51.47-114.606-114.677-114.637   c-0.005,0-0.01-0.001-0.014-0.001c-0.003,0-0.006,0-0.009,0c-0.011,0-0.022,0-0.034,0c-63.265,0-114.735,51.47-114.735,114.735   c0,63.265,51.47,114.735,114.735,114.735c63.195,0,114.617-51.355,114.733-114.523   C370.734,336.425,370.741,336.369,370.741,336.312z M336.27,347.312h11.806c-2.067,17.343-8.935,33.234-19.241,46.296l-8.328-8.327   c-4.297-4.296-11.262-4.295-15.557,0c-4.296,4.296-4.296,11.261,0,15.557l8.321,8.321c-13.071,10.292-28.968,17.145-46.314,19.196   v-11.815c0-6.075-4.925-11-11-11c-6.075,0-11,4.925-11,11v11.806c-17.343-2.067-33.233-8.935-46.296-19.24l8.327-8.327   c4.296-4.296,4.295-11.261-0.001-15.557c-4.295-4.296-11.26-4.295-15.556,0.001l-8.321,8.321   c-10.293-13.071-17.145-28.968-19.197-46.315h11.815c6.075,0,11-4.925,11-11c0-6.075-4.925-11-11-11h-11.806   c2.067-17.343,8.935-33.234,19.241-46.296l8.328,8.328c2.147,2.148,4.963,3.222,7.778,3.222c2.815,0,5.63-1.074,7.778-3.222   c4.296-4.295,4.295-11.26,0-15.556l-8.321-8.321c13.071-10.293,28.967-17.145,46.314-19.197V256c0,6.075,4.925,11,11,11   c6.075,0,11.001-4.925,11.001-11v-11.806c17.343,2.067,33.234,8.935,46.296,19.241l-8.328,8.328   c-4.296,4.296-4.296,11.261,0,15.557c2.148,2.148,4.963,3.222,7.778,3.222c2.815,0,5.631-1.074,7.778-3.222l8.321-8.321   c10.293,13.071,17.145,28.968,19.197,46.315H336.27c-6.075,0-11,4.925-11,11S330.195,347.312,336.27,347.312z"/>
                </g>
                
            </svg>
        </>
    )
}

export default Lock;