import {useQuery} from '@apollo/client';
import { GET_ALL_FAQ } from '../Queries/faq';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,FAQ_IMAGE_URL } from '../../../Config';
import YesNo from '../../../GlobalComponents/YesNo';
import { ConvertHtmlToText } from '../../../GlobalFunctions/helper';

const UseGetAllFaqs = (lang,projectID) => {

    const [variables,SetVariables] = useState({
        lang,
        projectID,
        limit:40,
        offset:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedFaqIDs,SetSelectedFaqIDs] = useState([]);

    const {data,loading,error,refetch,fetchMore} = useQuery(GET_ALL_FAQ,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllFaqs);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("Question"),
            className:"flex-1"
        },
        {
            value: t("Active"),
            className:"static smallest"
        }        
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:
                            <span 
                                className="cursor-help"
                                title={ConvertHtmlToText(val.question)}
                            >
                            {ConvertHtmlToText(val.question)}
                            </span>,
                        className:"flex-1 one-line-with-dots"
                    },
                    {
                        value:<YesNo isYes = {!!val.active} />,
                        className:"static smallest"
                    }
                ],
            }

            cData.rowID    = val.faqID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllFaqs: [...prev.AllFaqs, ...fetchMoreResult.AllFaqs]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    const SelectRow = (e,faqID) => {

        var checked = e.target.checked;
        var newSelectedFaqIDs = [...selectedFaqIDs];
        const newList = SelectFaqID(content,faqID,checked,newSelectedFaqIDs)

        SetContent(newList);
        SetSelectedFaqIDs(newSelectedFaqIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedFaqIDs = [];
        const newList = SelectFaqID(content,"all",checked,newSelectedFaqIDs)

        SetSelectedFaqIDs(newSelectedFaqIDs);
        SetContent(newList);
    }

    const SelectFaqID = (list,faqID,checked,selectedFaqIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(faqID == newList[i].rowID || faqID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedFaqIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedFaqIDs.length; j++)
                    {
                        if(selectedFaqIDs[j] == newList[i].rowID)
                            selectedFaqIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectFaqID(newList[i].subData,faqID,checked,selectedFaqIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        refetch();
    }

    return{
        content,
        headerData,
        totalContentLength: data && data.AllFaqsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        loading,
        error,
        selectedFaqIDs,
        SelectRow,
        SelectAll,
        UpdateList,
        FetchMore,
        GoToPage
    }

}

export default UseGetAllFaqs;