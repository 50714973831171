import gql from 'graphql-tag';

export const ADD_EDIT_LINK = gql`
    mutation AddEditLink(
        $linkID:ID,
        $parentID: ID, 
        $projectID: ID, 
        $thanksPageLinkID:ID,
        $isEshopCategory: Int, 
        $photo:Upload, 
        $shortcut:String,
        $primarilySortBy:String,
        $active:Int, 
        $inHeader:Int, 
        $inFooter:Int, 
        $isBlog:Int,
        $isThisTermsAndConditions:Int,
        $isThisPersonalInformation:Int,
        $hideMenu:Int,
        $hideAddress: Int,
        $hidePassword: Int,
        $noteIsRequired: Int,
        $usePriceFilter:Int,
        $useDepthFilter:Int,
        $useWidthFilter:Int,
        $useCreditCardForPayment:Int,
        $langs:[LinkLangsInput],
        $selectedParameters:[ID],
        $selectedMemberships:[LinkMembershipInput],
        $upSellProducts:[ID],
        $upSellServices:[ID]
    ){
        AddEditLink(
            linkID:$linkID,
            parentID: $parentID, 
            projectID: $projectID,
            thanksPageLinkID:$thanksPageLinkID,
            isEshopCategory: $isEshopCategory, 
            photo:$photo, 
            shortcut:$shortcut,
            primarilySortBy:$primarilySortBy,
            active:$active, 
            inHeader:$inHeader,
            inFooter:$inFooter,
            isBlog:$isBlog,
            isThisTermsAndConditions:$isThisTermsAndConditions,
            isThisPersonalInformation:$isThisPersonalInformation,
            hideMenu:$hideMenu,
            hideAddress: $hideAddress,
            hidePassword:$hidePassword,
            noteIsRequired: $noteIsRequired, 
            usePriceFilter:$usePriceFilter,
            useDepthFilter:$useDepthFilter,
            useWidthFilter:$useWidthFilter,
            useCreditCardForPayment:$useCreditCardForPayment,
            langs:$langs,
            selectedParameters:$selectedParameters,
            selectedMemberships:$selectedMemberships,
            upSellProducts:$upSellProducts,
            upSellServices:$upSellServices
        )
    }
`;

export const UPDATE_LINK_PRIORITY = gql`
    mutation UpdateLinkPriority(
        $parentID:ID,
        $linkID:ID,
        $projectID:ID,
        $isEshopCategory:Int,
        $fromIndex:Int,
        $toIndex:Int
    ) {
        UpdateLinkPriority(
            parentID:$parentID,
            linkID:$linkID,
            projectID:$projectID,
            isEshopCategory:$isEshopCategory,
            fromIndex:$fromIndex,
            toIndex:$toIndex
        )
    }
`;

export const COPY_LINK = gql`
    mutation CopyLink($linkID:ID) {
        CopyLink(linkID:$linkID)
    }
`;

export const GET_LINK = gql`
    query LinkWithLangs($linkID: ID,$lang: String,$projectID:ID){
        LinkWithLangs(linkID:$linkID){
            linkID
            projectID
            thanksPageLinkID
            isEshopCategory
            photo
            shortcut
            active
            parentID
            inHeader
            inFooter 
            isBlog
            isThisTermsAndConditions
            isThisPersonalInformation
            hideMenu
            hideAddress
            hidePassword
            noteIsRequired
            usePriceFilter
            useDepthFilter
            useWidthFilter
            useCreditCardForPayment
            primarilySortBy
            parameters{
                parameterID
                parameter(lang:$lang){
                    name
                }
            }
            memberships{
                membershipID
                discount
                membership(lang:$lang){
                    membershipID
                    name
                    canBeReserved
                }
            }
            upSellBombProducts{
                productID
                product(lang:$lang){
                    name
                    mainPhoto

                }
            }
            upSellBombMemberships{
                membershipID
                membership(lang:$lang){
                    name
                }
            }
            langs{
                lang
                name
                ownUrl
                description
                niceName
                metaTitle
                metaKeywords
                metaDescription
                metaImage
                membershipAfterBuyEmailText
                trialPeriodButtonName
                trialPeriodPopUpTextUp
                trialPeriodPopUpTextDown
                membershipTypeButtonName
                reservationPlaceButtonName
                reservationPlacePopUpTextUp
                reservationPlacePopUpTextDown
                paymentButtonName
                reservationDateButtonName
                personalDataButtonName
                membershipTypePopUpTextUp
                membershipTypePopUpTextDown
                textBellowFillCreditCard
                noteHeadline
                upSellBombTitle
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        AllParameters(lang:$lang,limit:1000000,offset:0){
            parameterID
            name
            values{
                parameterValueID
            }
        }
        AllLinks(
            lang:$lang,
            projectID:$projectID,
            isEshopCategory:0
        ){
            linkID
            name
            subLink{
                linkID
                name
                subLink{
                    linkID
                    name
                    subLink{
                        linkID
                        name
                        subLink{
                            linkID
                            name
                            subLink{
                                linkID
                                name
                                subLink{
                                    linkID
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
        AllProjects{
            projectID
            name
        }
    }
`;

export const GET_LINKS = gql`
    query AllLinks(
        $lang: String,
        $projectID: ID,
        $isEshopCategory:Int
    ){
        AllLinks(
            lang:$lang,
            projectID:$projectID,
            isEshopCategory:$isEshopCategory
        ){
            linkID
            name
            active
            parentID
            inHeader
            inFooter 
            hideMenu
            subLink{
                linkID
                name
                active
                parentID
                inHeader
                inFooter 
                hideMenu
                subLink{
                    linkID
                    name
                    active
                    parentID
                    inHeader
                    inFooter 
                    hideMenu
                    subLink{
                        linkID
                        name
                        active
                        parentID
                        inHeader
                        inFooter 
                        hideMenu
                        subLink{
                            linkID
                            name
                            active
                            parentID
                            inHeader
                            inFooter 
                            hideMenu
                            subLink{
                                linkID
                                name
                                active
                                parentID
                                inHeader
                                inFooter 
                                hideMenu
                                subLink{
                                    linkID
                                    name
                                    active
                                    parentID
                                    inHeader
                                    inFooter 
                                    hideMenu
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_LINKS_WITH_PROJECTS = gql`
    query AllLinks(
        $lang: String,
        $projectID: ID,
        $isEshopCategory:Int
    ){
        AllLinks(
            lang:$lang,
            projectID:$projectID,
            isEshopCategory:$isEshopCategory
        ){
            linkID
            projectID
            project{
                name
                favicon
            }
            name
            active
            parentID
            inHeader
            inFooter 
            hideMenu
            hideAddress
            hidePassword
            noteIsRequired
            useCreditCardForPayment
            subLink{
                linkID
                projectID
                project{
                    name
                    favicon
                }
                name
                active
                parentID
                inHeader
                inFooter 
                hideMenu
                subLink{
                    linkID
                    project{
                        name
                        favicon
                    }
                    name
                    active
                    parentID
                    inHeader
                    inFooter 
                    hideMenu
                    subLink{
                        linkID
                        project{
                            name
                            favicon
                        }
                        name
                        active
                        parentID
                        inHeader
                        inFooter 
                        hideMenu
                        subLink{
                            linkID
                            project{
                                name
                                favicon
                            }
                            name
                            active
                            parentID
                            inHeader
                            inFooter 
                            hideMenu
                            subLink{
                                linkID
                                project{
                                    name
                                    favicon
                                }
                                name
                                active
                                parentID
                                inHeader
                                inFooter 
                                hideMenu
                                subLink{
                                    linkID
                                    project{
                                        name
                                        favicon
                                    }
                                    name
                                    active
                                    parentID
                                    inHeader
                                    inFooter 
                                    hideMenu
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const DELETE_LINKS = gql`
    mutation DeleteLinks($linkIDs:ID) {
        DeleteLinks(linkIDs:$linkIDs)
    }
`;