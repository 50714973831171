import gql from 'graphql-tag';

export const ADD_EDIT_QUESTIONNAIRE = gql`
    mutation AddEditQuestionnaire(
        $questionnaireID:ID,
        $projectID:ID,
        $internalName:String,
        $langs:[QuestionnaireLangsInput],
        $questionnaireSteps:[QuestionnaireStepInput]
        $generateTranslations:GenerateQuestionnaireTranslationsInput
    ){
        AddEditQuestionnaire(
            questionnaireID:$questionnaireID,
            projectID:$projectID,
            internalName:$internalName,
            langs:$langs,
            generateTranslations:$generateTranslations,
            questionnaireSteps:$questionnaireSteps
        ){
            questionnaireID
            internalName
        }
    }
`;

export const GET_QUESTIONNAIRE = gql`
    query QuestionnaireWithLangs($questionnaireID:ID){

        QuestionnaireWithLangs(questionnaireID:$questionnaireID){
            questionnaireID
            internalName
            langs{
                lang
                welcomeMessage
            }
            questionnaireSteps{
                questionnaireStepID
                questionnaireID
                questionTypeID
                items{
                    questionnaireStepItemID
                    questionnaireStepID
                    langs{
                        lang
                        text
                    }
                }
                langs{
                    lang
                    question
                }
            }
        }        
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }

        AllQuestionTypes{
            questionTypeID
            type
        }
    }
`;

export const GET_QUESTIONNAIRES = gql`
    query AllQuestionnaires($projectID: ID,$lang: String){
        AllQuestionnaires(projectID:$projectID,lang: $lang){
            questionnaireID
            internalName
        }
    }
`;

export const DELETE_QUESTIONNAIRES = gql`
    mutation DeleteQuestionnaire($questionnaireIDs:ID!) {
        DeleteQuestionnaire(questionnaireIDs:$questionnaireIDs)
    }
`;

export const GENERATE_QUESTIONNAIRE_LANG = gql`
    mutation GenerateQuestionnaireLang(
        $questionnaireID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateQuestionnaireLang(
            questionnaireID:$questionnaireID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;
