import gql from 'graphql-tag';

export const GET_CAMPAIGN_CONTACTS = gql`
    query GetCampaignContacts(
        $campaignID: ID,
        $searchText:String,
        $limit:Int,
        $offset:Int,
        $lang:String
    ){
        AllCampaignContacts(
            campaignID:$campaignID,
            searchText:$searchText,
            limit:$limit,
            offset:$offset,
            lang:$lang
        ){
            campaignContactID
            campaignID
            sendDate
            email
            lang
            isFinished
            dateAdd
            dateUnsubscribe
            unsubscribeByAdminID
        }

        AllCampaignContactsCount(
            campaignID:$campaignID,
            searchText:$searchText,
            lang:$lang
        )
    }
`;
export const UNSUBSCRIBE_CAMPAIGN_CONTACTS = gql`
    mutation CampaignUnsubscribe($campaignContactIDs:ID!) {
        UnsubscribeCampaigns(campaignContactIDs:$campaignContactIDs){
            campaignContactIDs
            isFinished
            dateUnsubscribe
            unsubscribeByAdminID
        }
    }
`;
export const ADD_CAMPAIGN_CONTACTS = gql`
    mutation AddCampaignContacts(
        $emails:String,
        $campaignID: ID,
        $lang:String
    ){
        AddCampaignContacts(
            emails:$emails,
            lang:$lang,
            campaignID:$campaignID
        ){
            error
            contacts{
                campaignContactID
                campaignID
                sendDate
                email
                isFinished
                dateAdd
                dateUnsubscribe
                unsubscribeByAdminID
            }
        }
    }
`;