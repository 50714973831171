import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import UseGetAllCampaigns from "../Library/UseGetAllCampaigns";
import { useState } from "react";
import AddEditCampaign from "./AddEditCampaign";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseDeleteCampaigns from "../Library/UseDeleteCampaigns";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { GetProjectIDFromParam } from "../../../GlobalFunctions/header";
import { useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import UseCopyCampaign from "../Library/UseCopyCampaign";
import { UseProject } from "../../../AdminProvider";

const Campaigns = () => {

    const params = useParams();
    const projectID = GetProjectIDFromParam(params);

    const {t} = useTranslation();
    const client = useApolloClient();
    const {IsModulEnabled} = UseProject();

    const [selectedCampaignID, SetSelectedCampaignID] = useState(null);
    const [selectedLang, SetSelectedLang] = useState("");

    const {CopyCampaign}  = UseCopyCampaign((d) => UpdateCampaignList(d));

    const {
        content,
        loading,
        error,
        headerData,
        selectedCampaignIDs,
        allLanguages,
        SelectRow,
        SelectAll
    } = UseGetAllCampaigns(selectedLang,projectID);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteCampaigns
    } = UseDeleteCampaigns(selectedCampaignIDs,(d) => UpdateCampaignList(d));

    const UpdateCampaignList = async (d) => {
        await client.refetchQueries({
            include: ["AllCampaigns"],
        });        
    }

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("CampaignList")}
                        OnAddButtonClick={() => SetSelectedCampaignID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                        allLanguages = {allLanguages}
                        selectedLang = {selectedLang}
                        OnLangSelect = {(e) => SetSelectedLang(e.target.value)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (campaignID) => SetSelectedCampaignID(campaignID),
                            OnCopyClick: (campaignID) => CopyCampaign(campaignID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedCampaignID || selectedCampaignID == 0 ?
                <AddEditCampaign
                    projectID = {projectID}
                    selectedCampaignID = {selectedCampaignID}
                    SetSelectedCampaignID = {SetSelectedCampaignID}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteCampaigns")} 
                    CallBack={DeleteCampaigns} 
                />
            :null}
                
        </>
    )

}

export default Campaigns;