import { useQuery } from "@apollo/client";
import { GET_ADMIN_ROLE } from "../Queries/adminRole";
import { useState } from 'react';

const UseGetAdminRole = (selectedAdminRoleID,CallBack) => {

    const [allModules, SetAllModules] = useState([]);
    const [allProjects, SetAllProjects] = useState([]);

    const {loading,error} = useQuery(GET_ADMIN_ROLE,{
        variables:{
            adminRoleID:selectedAdminRoleID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {
            if(selectedAdminRoleID != 0)
            { 
                CallBack({
                    name:     d.AdminRole.name,
                    modules:  GetModules(d.AdminRole.modules),
                    projects: GetProjects(d.AdminRole.projects)
                    
                });
            }

            SetAllModules(d.AllModulesBy);
            SetAllProjects(d.AllProjects);
        }
    });

    const GetProjects = (projects) => {

        if(projects)
        {
            var newProjects = [];
            for(let val of projects)
            {
                newProjects.push(val.projectID);
            }

            return newProjects;
        }
        else
            return []
    }

    const GetModules = (modules) => {

        if(modules)
        {
            var newModules = [];
            for(let val of modules)
            {
                newModules.push(val.moduleID);
            }

            return newModules;
        }
        else
            return []
    }

    return{
        loading: loading,
        error: error,
        allModules,
        allProjects
    }

}

export default UseGetAdminRole;