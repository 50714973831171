import gql from 'graphql-tag';

export const GET_ALL_ADMIN_USERS = gql`
    query AllAdminUsers{
        AllAdminUsers{
            adminUserID
            adminRoleID
            name
            surname
            email
            lang
            backgroundColor
            adminRole{
                name
            }
        }
    }
`;

export const GET_ADMIN_USER = gql`
    query AdminUser($adminUserID:ID){
        AdminUser(adminUserID:$adminUserID){
            adminUserID
            adminRoleID
            name
            surname
            email
            lang
        }
        AllAdminRoles
        {
            adminRoleID
            name
        } 
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }   
    }
`;

export const ADD_EDIT_ADMIN_USER = gql`
    mutation AddEditAdminUser(
        $adminUserID:ID,
        $adminRoleID:ID,
        $name:String,
        $surname:String,
        $email:String,
        $lang:String,
        $password:String
    ){
        AddEditAdminUser(
            adminUserID:$adminUserID,
            adminRoleID:$adminRoleID, 
            name:$name, 
            surname:$surname, 
            email:$email, 
            lang:$lang, 
            password:$password
        ){
            adminUserID
            adminRoleID
            name
            surname
            email
            lang
        }
    }
`;

export const DELETE_ADMIN_USERS = gql`
    mutation DeleteAdminUsers($adminUserIDs:ID) {
        DeleteAdminUsers(adminUserIDs:$adminUserIDs)
    }
`;

