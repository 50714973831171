const Article = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M413,0.001H11c-6.075,0-11,4.925-11,11v490c0,6.075,4.925,11,11,11h375.021v-22H22v-380h380v168.65h22V11.001
                            C424,4.926,419.075,0.001,413,0.001z M22,88.001v-66h380v66H22z"/>
                        <path style={{"fill":primaryColor}} d="M49.89,50.791c-3.107,6.931,2.569,15.428,10.16,15.21c7.64,0.163,13.25-8.213,10.16-15.21
                            C66.6,41.838,53.507,41.813,49.89,50.791z"/>
                        <path style={{"fill":primaryColor}} d="M93.89,50.791c-3.112,6.929,2.572,15.422,10.16,15.21c4.352,0.034,8.541-2.739,10.16-6.79
                            C119.264,45.558,100.018,37.525,93.89,50.791z"/>
                        <path style={{"fill":primaryColor}} d="M140.27,47.221c-6.93,6.635-1.788,18.977,7.779,18.78c7.639,0.167,13.25-8.212,10.16-15.21
                            C155.511,43.693,145.486,41.713,140.27,47.221z"/>
                        <path style={{"fill":primaryColor}} d="M363.948,44.001H214.227c-6.075,0-11,4.925-11,11s4.925,11,11,11h149.721c6.075,0,11-4.925,11-11
                            S370.023,44.001,363.948,44.001z"/>
                        <path style={{"fill":primaryColor}} d="M274.341,254.858c6.075,0,11-4.925,11-11s-4.925-11-11-11h-99.763c-6.075,0-11,4.925-11,11s4.925,11,11,11H274.341z"/>
                        <path style={{"fill":primaryColor}} d="M380,195.429v-48.428c0-6.075-4.925-11-11-11H174.578c-6.075,0-11,4.925-11,11v48.428c0,6.075,4.925,11,11,11H369
                            C375.075,206.429,380,201.504,380,195.429z M358,184.429H185.578v-26.428H358V184.429z"/>
                        <path style={{"fill":primaryColor}} d="M335.069,388.713c0-6.075-4.925-11-11-11H55c-6.075,0-11,4.925-11,11s4.925,11,11,11h269.069
                            C330.144,399.713,335.069,394.788,335.069,388.713z"/>
                        <path style={{"fill":primaryColor}} d="M355.904,340.286c0-6.075-4.925-11-11-11H55c-6.075,0-11,4.925-11,11s4.925,11,11,11h289.904
                            C350.979,351.286,355.904,346.361,355.904,340.286z"/>
                        <path style={{"fill":primaryColor}} d="M368.856,291.858c0-6.075-4.925-11-11-11H55c-6.075,0-11,4.925-11,11s4.925,11,11,11h302.856
                            C363.931,302.858,368.856,297.933,368.856,291.858z"/>
                        <path style={{"fill":primaryColor}} d="M55,425.713c-6.075,0-11,4.925-11,11s4.925,11,11,11h201.125c6.075,0,11-4.925,11-11s-4.925-11-11-11H55z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}}  
                            d="M503.847,182.926l-60.83-16.3c-2.819-0.756-5.821-0.36-8.347,1.099
                            c-2.526,1.459-4.37,3.861-5.125,6.68l-74.358,277.511c-0.646,2.409-0.453,4.966,0.545,7.252l20.209,46.239
                            c1.34,3.065,4.001,5.354,7.232,6.22c0.939,0.252,1.895,0.375,2.847,0.375c2.324,0,4.616-0.737,6.527-2.146l40.621-29.94
                            c2.008-1.479,3.453-3.598,4.099-6.008l74.358-277.51C513.198,190.53,509.715,184.498,503.847,182.926z M447.948,190.724
                            l39.58,10.605l-9.267,34.586l-39.58-10.605L447.948,190.724z M417.014,464.493l-26.431,19.48l-13.149-30.086l55.553-207.326
                            l39.58,10.605L417.014,464.493z"/>
                        <path 
                            style={{"fill":secondaryColor}}  
                            d="M103.136,144.391c-0.038-0.102-0.078-0.202-0.119-0.302c-2.022-4.915-6.76-8.088-12.073-8.088
                            c-0.005,0-0.011,0-0.016,0c-5.319,0.007-10.056,3.192-12.068,8.115c-0.034,0.082-0.066,0.164-0.098,0.247L44.72,233.745
                            c-2.162,5.677,0.688,12.032,6.365,14.194c1.289,0.491,2.611,0.724,3.913,0.724c4.433,0,8.61-2.699,10.282-7.088l5.78-15.176
                            h39.511l5.715,15.145c2.145,5.684,8.49,8.551,14.175,6.408c5.684-2.146,8.553-8.492,6.408-14.176L103.136,144.391z M79.439,204.4
                            l11.467-30.108l11.362,30.108H79.439z"/>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Article;
