import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import Loading from "../../../GlobalComponents/Loading";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import InputCard from "../../../GlobalComponents/InputCard";
import emailIcon from '../../../Media/Images/Icons/email.webp';
import UseGetNewsletterStatistics from "../Library/UseGetNewsletterStatistics";
import List from "../../../GlobalComponents/List/List";
import { UseAuth } from "../../Auth/Library/UseAuth";

const NewsletterStatistics = () => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    const {
        loading,
        error,
        headerData,
        content
    } = UseGetNewsletterStatistics(user.lang);

    return(
        <>
            <ContentHeader
                title = {t("NewsletterStatistics")}           
            />
            <ContentBody>

                {error ?
                    <Error text = {GetApolloErrorText(error)} />
                :
                    (loading ?
                        <Loading />
                    :
                        
                        <InputCard
                            title = {t("NumberOfEmails")}
                            icon = {emailIcon}
                            noBodyPadding={true}
                        > 
                            <List 
                                headerData = {headerData} 
                                headerClassName={"smaller-vertical-padding"}
                                contentData = {content}
                                isSortable = {false}
                                loading = {loading}
                                hideOptions={true}
                            />
                        </InputCard> 
  
                    )
                }

            </ContentBody>
        </>
    )
}

export default NewsletterStatistics;