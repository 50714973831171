import { useEffect, useState } from "react";
import { useMutation,useQuery } from "@apollo/client";
import { GetMainLanguage } from "../../../GlobalFunctions/helper";
import { GET_NEWSLETTER_DATA,SEND_NEWSLETTER } from "../Queries/newsletter";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GET_CAMPAIGNS_WITH_BASIC_DATA } from "../../Campaign/Queries/campaign";
import { CompleteLangsArray } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from "react-i18next";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const UseSendNewsletter = (OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();

    const [mainLang, SetMainLang] = useState({});
    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allProjects, SetAllProjects] = useState([]);
    const [allCampaigns, SetAllCampaigns] = useState([]);

    const {
        formData, 
        SetFormData, 
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj
    } = UseFormHandle({
        selectedLang:"",
        projectID:0,
        campaignID:0,
        photo:null,
        langs:[],
        generateTranslations:{
            enabled:true
        }
    });

    useEffect(() => {

        if(formData.projectID == 0)
        {
            SetAllCampaigns([]);
            SetFormData("campaignID",0);
        }

    },[formData.projectID])

    const {loading,error} = useQuery(GET_NEWSLETTER_DATA,{
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                subject:"", 
                preheader:"", 
                text:"", 
            }
            let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);
            SetFormData("langs",langs);

            SetAllProjects(d.AllProjects);
            SetAllLanguageMutations(d.AllLanguageMutations);
            
            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetFormData("selectedLang",mainLang.suffix);
            SetMainLang(mainLang);
        }
    });

    const {loading:projectLoading,error:projectError} = useQuery(GET_CAMPAIGNS_WITH_BASIC_DATA,{
        fetchPolicy:"network-only",
        variables:{
            projectID:formData.projectID,
            onlyActive:true
        },
        skip:(formData.projectID == 0),
        onCompleted:(d) => {
            SetAllCampaigns(d.AllCampaigns);
        }
    });

    const [SendNewsletterMutation,{loading:sendNewsletterLoading}] = useMutation(SEND_NEWSLETTER,{
        onCompleted:(d) => {

            if(d.SendNewsletter.error.length > 0)
            {
                var err = "";
                for(let val of d.SendNewsletter.error)
                {
                    err += val.lang + ": " + t(val.message) + "<br /><br />";
                }

                AddNotification(err,false);
            }
            else
            {
                AddNotification(t("AndItFlies"),true);
                if(OnCompleted)
                    OnCompleted(d.DeleteCampaigns);
            }
        },
        onError:(err) => {     
            
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const SendNewsletter = () => {

        if(CheckInputs(formData))
        {
            SendNewsletterMutation({
                variables:{
                    projectID:    formData.projectID,
                    campaignID:   formData.campaignID,
                    langs:        formData.langs,
                }
            })
                    
        }else
            AddNotification(t("SomeLanguageHasNotFilledSubjectOrText"),false);        
    }

    const CheckInputs = (formData) => {

        var check = true;
        var checkAll = false;

        for(let val of formData.langs)
        {
            if((val.subject && !val.text) || (!val.subject && val.text) || (!val.subject && !val.text && val.preheader))
                check = false;

            if(val.subject && val.text)
                checkAll = true;
        }

        return check && checkAll;
    }

    return{
        loading: loading || sendNewsletterLoading,
        error: error || projectError,
        allProjects,
        allCampaigns,
        allLanguageMutations,
        formData, 
        mainLang,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj,
        SendNewsletter
    }
}

export default UseSendNewsletter;