import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import SendEmailSettings from "../../../GlobalComponents/SendEmailSettings";
import UseEditNewsletterSettings from "../Library/UseEditNewsletterSettings";
import UseGetNewsletterSettings from "../Library/UseGetNewsletterSettings";
import Loading from "../../../GlobalComponents/Loading";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import dayjs from "dayjs";
import { SERVER_URL,NEWSLETTER_SETTINGS_IMAGE_URL } from "../../../Config";
import Label from "../../../GlobalComponents/Label";
import Select from "../../../GlobalComponents/Select";
import InputCard from "../../../GlobalComponents/InputCard";
import emailIcon from '../../../Media/Images/Icons/email.webp';

const NewsletterSettings = () => {

    const {t} = useTranslation();

    const {
        loading:getLoading,
        error,
        allLanguageMutations,
        allProjects,
        mainLang
    } = UseGetNewsletterSettings((d) => {

        var fileVersion = "";
        if(d.dateEdit != "0000-00-00 00:00:00")
            fileVersion = "?" + dayjs(d.dateEdit).format("YYYYMMDDHHmmss");

        var initData = {...d};
        initData.mainEmailHeader =  d.mainEmailHeader ? SERVER_URL + "/" + NEWSLETTER_SETTINGS_IMAGE_URL + "/velka_" + d.mainEmailHeader + fileVersion : "";
       
        InitFormData(initData)
        
    });

    const {
        formData,
        loading,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenImage,
        EditNewsletterSettings,
        InitFormData
    } = UseEditNewsletterSettings(mainLang.suffix);

    return(
        <>
            <ContentHeader
                title = {t("Settings")}
                isEdit = {false}
                //allLanguages = {allLanguageMutations}
                //selectedLang = {formData.selectedLang}
                //OnLangSelect = {(e) => SetFormData(e)}  
                OnSaveButtonClick = {() => EditNewsletterSettings()}            
            />
            <ContentBody>

                {error ?
                    <Error text = {GetApolloErrorText(error)} />
                :
                    (loading || getLoading ?
                        <Loading />
                    :
                        <div className="row">
                            <div className="col-50">
                                <InputCard
                                    title = {t("GlobalNewsletter")}
                                    icon = {emailIcon}
                                > 
                                    <div className="form-group">
                                        <Label 
                                            text={t("DataFromProject")}
                                            infoContent={[
                                                t("GlobalNewsletterSettingsProjectInfo")
                                            ]}
                                        />
                                        <Select
                                            name="projectID"
                                            value={formData.projectID}
                                            OnChange = {(e) => SetFormData(e)}
                                        >
                                            <option value= {0} >{"- - -"+t("Choose")+"- - -"}</option>
                                            {allProjects && allProjects.map((item) => (
                                                <option
                                                    key= {item.projectID}
                                                    value= {item.projectID}
                                                >{item.name}</option>
                                            ))}
                                            
                                        </Select>
                                    </div>
                                
                                    {false ? //je to dané na false, protože jsem to při vývoji takto vytvořil a nakonec změnil tak, že se vybere jen projekt z kterého bude globální newsletter čerpat data, ale nechávám to tady, kdyby došlo ke změně a globální newsletter by mohl mít odlišné data ne jakýkoliv jiný projekt
                                        <>
                                            <div className="form-group">
                                                <Label 
                                                    text={t("ProjectFormUnsubscribe")}
                                                    infoContent={[
                                                        t("ProjectFormUnsubscribeInfo1"),
                                                        t("ProjectFormUnsubscribeInfo2")
                                                    ]}
                                                />
                                                <Select
                                                    name="projectID"
                                                    value={formData.projectID}
                                                    OnChange = {(e) => SetFormData(e)}
                                                >
                                                    <option value= {0} >{"- - -"+t("Choose")+"- - -"}</option>
                                                    {allProjects && allProjects.map((item) => (
                                                        <option
                                                            key= {item.projectID}
                                                            value= {item.projectID}
                                                        >{item.name}</option>
                                                    ))}
                                                    
                                                </Select>
                                            </div>
                                            
                                            <SendEmailSettings 
                                                title = {t("Emailing")}
                                                headerInfoContent={[t("GlobalNewsletterEmailSettingsInfo")]}
                                                formData = {formData}
                                                OpenImage = {OpenImage}
                                                GetFormLangValue = {GetFormLangValue}
                                                SetFormLangData = {SetFormLangData}
                                            />
                                        </>
                                    :null}
                                </InputCard>
                            </div>
                        </div>  
                    )
                }

            </ContentBody>
        </>
    )
}

export default NewsletterSettings;