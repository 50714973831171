import {useApolloClient, useQuery} from '@apollo/client';
import { GET_PRODUCTS, SET_PRODUCT_NEW_LINK_PRIORITY } from '../../Eshop/Queries/products';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,PRODUCT_IMAGE_URL } from '../../../Config';
import { UseNotification } from '../../Notification/Library/UseNotification';

const UseGetLinkProducts = (lang,linkID) => {

    const [variables,SetVariables] = useState({
        lang,
        limit:40,
        offset:0,
        searchText:"",
        linkID:linkID
    });

    const {AddNotification} = UseNotification();

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedProductIDs,SetSelectedProductIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_PRODUCTS,{
        variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" || linkID == 0 ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllProducts: [...prev.AllProducts, ...fetchMoreResult.AllProducts]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllProducts);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("ProductName"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];
        
        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.mainPhoto ? SERVER_URL + "/" + PRODUCT_IMAGE_URL + "/p-" + val.productID + "/mala_" + val.mainPhoto : "",
                        className:"no-padding-right"
                    },
                    {
                        value: val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.priority = val.inLinkPriority;
            cData.rowID    = val.productID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SetNewPriority = (productID,newPriority) => {

        var newPriority = parseInt(newPriority);

        if(newPriority && newPriority > 0)
        {            
            client.mutate({
                mutation:SET_PRODUCT_NEW_LINK_PRIORITY,
                variables:{
                    productID,
                    linkID,
                    newPriority
                }
            }).then(async () => {
                await client.refetchQueries({
                    include: ["AllProducts"],
                });
            })
        }
        else
        {
            AddNotification(t("WrongNumberWasAdded"),false);
        }
    }

    const FilterProducts = (filterData) => {

        var newVariables = {...variables};
        var updated = false;
       
        if(filterData.searchText != "" || (filterData.searchText == "" && newVariables.searchText != ""))
        {
            newVariables.searchText = filterData.searchText;
            updated = true;
        }

        if(updated)
            SetVariables(newVariables)

    }

    return{
        content,
        totalContentLength: data && data.AllProductsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedProductIDs,
        GoToPage,
        FetchMore,
        SetNewPriority,
        FilterProducts
    }

}

export default UseGetLinkProducts;