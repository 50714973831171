import { useState, useEffect } from 'react';
import { SAVE_PASSWORD } from '../Queries/auth';
import {useMutation } from '@apollo/client';
import UseFormHandle from '../../../GlobalHooks/UseFormHandle';
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';

const UseSavePassword = (hash) => {

	const {t} = useTranslation();

	const {formData, SetFormData} = UseFormHandle({
		password:"",
        checkPassword:""
	});
	
	const [disabled,SetDisabled] = useState(false);
    const [error,SetError] = useState("");
	const [success,SetSuccess] = useState(false);

    useEffect(() => {

		document.addEventListener("keyup",PressKey);
		return () => {
			document.removeEventListener("keyup",PressKey);
		}

	},[formData])

    const [SaveAdminPassword,{loading}] = useMutation(SAVE_PASSWORD,{

		onCompleted: async (data) => {

			SetSuccess(true);
		},
		onError:(err) => {

			err = GetApolloErrorText(err);

            if(err === "LINK_EXPIRED")
                err = t(err);
			
			SetError(err);
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	});

    const Save = () => {

		const {password,checkPassword} = formData;

		SetDisabled(true);
			
        if(hash)
        {
            if(password !== '' && checkPassword !== ''){

                if(password === checkPassword){

                    SaveAdminPassword({
                        variables:{
                            password,
                            hash
                        }
                    });  
                    
                }else{
                    SetDisabled(false);
                    SetError(t('PasswordsAreNotSame'));
                    setTimeout(() => {SetError(''); SetDisabled(false)},3000);
                }
                
            }else{
                SetDisabled(false);
                SetError(t('NotFilledPassword'));
                setTimeout(() => {SetError(''); SetDisabled(false)},3000);
            }
        }else{
            SetDisabled(false);
            SetError(t('WrongUser'));
            setTimeout(() => {SetError(''); SetDisabled(false)},3000);
        }
	}

    const PressKey = (e) => {
		if(e.key == "Enter")
            Save();
	}

    return {
        formData,
        disabled,
        error,
		success,
		loading,
		SetFormData,
        Save
    }

}

export default UseSavePassword;