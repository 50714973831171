import { useQuery } from "@apollo/client";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";
import { GET_MANUFACTURER } from "../../Queries/manufacturers";

const UseGetManufacturer = (selectedManufacturerID,lang,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const {loading,error} = useQuery(GET_MANUFACTURER,{
        variables:{
            manufacturerID:selectedManufacturerID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                deliveryTimeToCustomer:"",                    
            }

            if(selectedManufacturerID != 0)
            {
                let langs = CompleteLangsArray(d.ManufacturerWithLangs.langs,d.AllLanguageMutations,emptyLang);

                CallBack({
                    selectedLang: lang,
                    name:         d.ManufacturerWithLangs.name,
                    shortcut:     d.ManufacturerWithLangs.shortcut,
                    deliveryDays: d.ManufacturerWithLangs.deliveryDays,
                    langs:        langs,
                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
        }
    });

    return{
        loading: (loading),
        error: (error),
        allLanguageMutations
    }

}

export default UseGetManufacturer;