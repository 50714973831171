import { useEffect } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import Input from '../../../GlobalComponents/Input';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditMarketingBanner from '../Library/UseAddEditMarketingBanner';
import UseGetMarketingBanner from '../Library/UseGetMarketingBanner';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseAuth } from '../../Auth/Library/UseAuth';
import { TINY_MCE_TOOLBAR_MEDIA } from '../../../Config';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import Select from '../../../GlobalComponents/Select';
import linkIcon from '../../../Media/Images/Icons/link.webp';
import InputCard from '../../../GlobalComponents/InputCard';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';
import FileInput from '../../../GlobalComponents/FileInput';
import imageIcon from '../../../Media/Images/Icons/image.webp';
import Label from '../../../GlobalComponents/Label';
import RadioButton from '../../../GlobalComponents/RadioButton';
import { SERVER_URL,MARKETING_BANNER_IMAGE_URL } from '../../../Config';
import dayjs from 'dayjs';
import SelectBoxColors from '../../../GlobalComponents/SelectBoxColors';

Modal.setAppElement('#root');

const AddEditMarketingBanner = ({selectedMarketingBannerID,SetSelectedMarketingBannerID,OnAddEditSuccess}) => {

    const {user} = UseAuth();
    const {t} = useTranslation();

    var itemsToTranslate = [
        {name:"Text",param:"text"},
        {name:"Text na tlačítku",param:"buttonText"}
    ];

    const {
        allLanguageMutations,
        allProjects,
        allMarketingBannerTypes,
        mainLang,
        loading:getLoading,
        error:getError
    } = UseGetMarketingBanner(selectedMarketingBannerID,(d) => {

        var fileVersion = "";
        if(d.dateEdit != "0000-00-00 00:00:00")
            fileVersion = "?" + dayjs(d.dateEdit).format("YYYYMMDDHHmmss");

        var initData = {...d};
        initData.mainPhoto =  d.mainPhoto ? SERVER_URL + "/" + MARKETING_BANNER_IMAGE_URL + "/maxi_" + d.mainPhoto + fileVersion : "";
        initData.mainPhotoPortrait =  d.mainPhotoPortrait ? SERVER_URL + "/" + MARKETING_BANNER_IMAGE_URL + "/maxi_" + d.mainPhotoPortrait + fileVersion : "";
       
        InitFormData(initData);
    });

    const {
        loading,
        error,
        formData,
        selectedProjectID, 
        translatedLangsCount,
        translatedLang,
        SetSelectedProjectID,
        AddEditMarketingBanner,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenImage,
        GetFormArrayValueByParam,
        SetFormDataSelectedItemsWithObj
    } = UseAddEditMarketingBanner(selectedMarketingBannerID,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    useEffect(() => {
        if(allProjects && allProjects.length > 0 )
            SetSelectedProjectID(allProjects[0].projectID);
    },[allProjects])

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedMarketingBannerID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {selectedMarketingBannerID === 0 ? t("AddMarketingBanner") : t("EditMarketingBanner")}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedMarketingBannerID === 0 ? false : true}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedMarketingBannerID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        :  
                            <div className="row">

                                <div className="col-50 d-flex">

                                    <InputCard
                                        title = {"*" + t("LandscapeImage")}
                                        icon = {imageIcon}
                                        className="form-group flex-1"
                                    >   
                                        <div className="form-group">
                                            {formData.mainPhoto ?

                                                <div className="open-image-container form-group">
                                                    <img className="no-margin" src = {formData.mainPhoto} />
                                                </div>
                                                
                                            :null}

                                            <FileInput
                                                name = "photo"
                                                OnChange = {(e) => OpenImage(e,"photo","mainPhoto")}
                                            />
                                        </div>
                                    </InputCard>
                                </div>
                                <div className="col-50">

                                    <InputCard
                                        title = {"*" + t("PortraitImage")}
                                        icon = {imageIcon}
                                        className="form-group"
                                    >   
                                        <div className="form-group">
                                            {formData.mainPhotoPortrait ?
                                
                                                <div className="open-image-container form-group">
                                                    <img className="no-margin" src = {formData.mainPhotoPortrait} />
                                                </div>
                                                
                                            :null}

                                            <FileInput
                                                name = "photoPortrait"
                                                OnChange = {(e) => OpenImage(e,"photoPortrait","mainPhotoPortrait")}
                                            />
                                        </div>
                                    </InputCard>
                                </div>
                                <div className="col-50">
                                    <div className="form-group">
                                        <label>*{t("MarketingBannerName")}</label>
                                        <Input
                                            name="name"
                                            value={formData.name}
                                            OnChange={(e) => SetFormData(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-50">
                                    <label>*{t("MarketingBannerType")}</label>
                                    <Select
                                        name="marketingBannerTypeID"
                                        value={formData.marketingBannerTypeID}
                                        OnChange={(e) => SetFormData(e)}
                                    >
                                        <option value={0} >{"- - -" + t("Choose") + "- - -"}</option>
                                        {allMarketingBannerTypes && allMarketingBannerTypes.map((item) => (
                                            <option
                                                key={item.marketingBannerTypeID}
                                                value={item.marketingBannerTypeID}
                                            >{t(item.type)}</option>
                                        ))}
                                    </Select>
                                </div>
                               

                                <div className="col-50">
                                    <div className="form-group">
                                        <label>{t("ButtonText")} ({formData.selectedLang})</label>
                                        <Input
                                            name="buttonText"
                                            value={GetFormLangValue("buttonText")}
                                            OnChange={(e) => SetFormLangData(e)}
                                        />
                                    </div>

                                </div>
                                <div className="col-50">
                                    <div className="form-group">
                                        <label>{t("ButtonColor")}</label>
                                        <Select
                                            name="buttonColor"
                                            value={formData.buttonColor}
                                            OnChange={(e) => SetFormData(e)}
                                        >
                                            <option value={""} >{"- - -" + t("Choose") + "- - -"}</option>
                                            <SelectBoxColors />
                                        </Select>
                                    </div>

                                </div>
                                <div className="col-100">
                                    <div className="form-group">
                                        <Label 
                                            text={t("Url") + "(" + formData.selectedLang +")"}
                                        />
                                        <Input
                                            name="buttonUrl"
                                            value={GetFormLangValue("buttonUrl")}
                                            OnChange={(e) => SetFormLangData(e)}
                                        />
                                    </div>
                                </div>
                    
                                <div className="col-100">

                                    <label>{t("TextInBanner")} ({formData.selectedLang})</label>
                                    <div className="form-group">
                                        <TinyMCEEditor 
                                            value = {GetFormLangValue("text")}
                                            OnEditorChange={(content) => SetFormLangData("text",content)}
                                            toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                                            useMediaEditor={true}
                                        />
                                    </div>
                                    
                                    <InputCard
                                        title = {"*" + t("ProjectsData")}
                                        icon = {linkIcon}
                                        headerChildren = {
                                            <>
                                                {allProjects && allProjects.length > 0 ? 
                                                    <Select 
                                                        className = "narrow"
                                                        name="selectedProjectID"
                                                        value={selectedProjectID}
                                                        OnChange={(e) => {
                                                            SetSelectedProjectID(e.target.value);
                                                        }}
                                                    >
                                                        {allProjects.map((item) => (
                                                            <option 
                                                                key={item.projectID} 
                                                                value = {item.projectID}
                                                            >{item.name}</option>
                                                        ))}
                                                    </Select>
                                                :null}
                                            </>
                                        }
                                    >   
                                        
                                        <div className="row">
                                            <div className="col-33">
                                                <div className="form-group">
                                                    
                                                    <label>{t("ShowInProject")} </label>
                                                    <div className="radio-container">
                                                        <RadioButton
                                                            text="Ano"
                                                            name="showInProject"
                                                            id="showInProject-1"
                                                            value={1}
                                                            checked = {GetFormArrayValueByParam("projectData","projectID",selectedProjectID,"showInProject") === 1 ? true : false}
                                                            OnChange={(e) => SetFormDataSelectedItemsWithObj(e,"projectData","projectID",selectedProjectID)}
                                                        />
                                                        <RadioButton
                                                            text="Ne"
                                                            name="showInProject"
                                                            id="showInProject-2"
                                                            value={0}
                                                            checked = {GetFormArrayValueByParam("projectData","projectID",selectedProjectID,"showInProject") === 0 ? true : false}
                                                            OnChange={(e) => SetFormDataSelectedItemsWithObj(e,"projectData","projectID",selectedProjectID)}
                                                        />
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="col-33">
                                                <div className="form-group">
                                                    
                                                    <label>{t("ShowInBlog")} </label>
                                                    <div className="radio-container">
                                                        <RadioButton
                                                            text="Ano"
                                                            name="showInBlog"
                                                            id="showInBlog-1"
                                                            value={1}
                                                            checked = {GetFormArrayValueByParam("projectData","projectID",selectedProjectID,"showInBlog") === 1 ? true : false}
                                                            OnChange={(e) => SetFormDataSelectedItemsWithObj(e,"projectData","projectID",selectedProjectID)}
                                                        />
                                                        <RadioButton
                                                            text="Ne"
                                                            name="showInBlog"
                                                            id="showInBlog-2"
                                                            value={0}
                                                            checked = {GetFormArrayValueByParam("projectData","projectID",selectedProjectID,"showInBlog") === 0 ? true : false}
                                                            OnChange={(e) => SetFormDataSelectedItemsWithObj(e,"projectData","projectID",selectedProjectID)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>  
                                            <div className="col-33">
                                                <div className="form-group">
                                                    
                                                    <label>{t("ShowInAllArticles")} </label>
                                                    <div className="radio-container">
                                                        <RadioButton
                                                            text="Ano"
                                                            name="showInAllArticles"
                                                            id="showInAllArticles-1"
                                                            value={1}
                                                            checked = {GetFormArrayValueByParam("projectData","projectID",selectedProjectID,"showInAllArticles") === 1 ? true : false}
                                                            OnChange={(e) => SetFormDataSelectedItemsWithObj(e,"projectData","projectID",selectedProjectID)}
                                                        />
                                                        <RadioButton
                                                            text="Ne"
                                                            name="showInAllArticles"
                                                            id="showInAllArticles-2"
                                                            value={0}
                                                            checked = {GetFormArrayValueByParam("projectData","projectID",selectedProjectID,"showInAllArticles") === 0 ? true : false}
                                                            OnChange={(e) => SetFormDataSelectedItemsWithObj(e,"projectData","projectID",selectedProjectID)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>                                                             
                                        </div> 
                                    
                                    </InputCard>
                                </div>
                                                                    
                            </div>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditMarketingBanner()}
                    >
                        {(selectedMarketingBannerID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditMarketingBanner;