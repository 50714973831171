import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import TextResponziveSettings from "./TextResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { TEXT } from "../../../Library/elements";
import Select from "../../../../../GlobalComponents/Select";

const TextSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(TEXT.text); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"text");
            SetCompleteFormData(settings);
        }

    },[showSettingsElement])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"text");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"text");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <TextResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <TextResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <TextResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <TextResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <TextResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <TextResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="row">
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("Opacity")}</label>
                        <Select
                            name = {"opacity"}
                            value = {formData.opacity}
                            OnChange = {(e) => SetS(e)}
                        >
                            <option value = {"10"}>1</option>
                            <option value = {"9"}>0.9</option>
                            <option value = {"8"}>0.8</option>
                            <option value = {"7"}>0.7</option>
                            <option value = {"6"}>0.6</option>
                            <option value = {"5"}>0.5</option>
                            <option value = {"4"}>0.4</option>
                            <option value = {"3"}>0.3</option>
                            <option value = {"2"}>0.2</option>
                            <option value = {"1"}>0.1</option>
                            <option value = {"0"}>0</option>
                        </Select>
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default TextSettings;