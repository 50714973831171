import { useQuery} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,CUSTOMER_IMAGE_URL } from '../../../Config';
import { GET_ALL_CUSTOMERS } from '../Queries/customer';

const UseGetAllCustomers = (lang) => {

    const [variables,SetVariables] = useState({
        searchText:"",
        limit:40,
        offset:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedCustomerIDs,SetSelectedCustomerIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_ALL_CUSTOMERS,{
        variables,
        fetchPolicy:"network-only"
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCustomers);
            SetContent(contentData);
        }

    },[data])

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    var headerData = [
        {
            value: t("FullName"),
            className:"flex-1"
        },
        {
            value: t("Email"),
            className:"flex-1"
        },
        {
            value: t("Tel"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var text = val.email;
            if(val.name || val.surname)
                text = val.name + " " + val.surname;

            var cData = {
                data:[
                    {
                        elm: "lis",
                        backgroundColor: val.backgroundColor,
                        text: text,
                        photo: val.photo ? SERVER_URL + "/" + CUSTOMER_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right flex-1"
                    },
                    {
                        value:val.email,
                        className:"flex-1"
                    },
                    {
                        value:val.tel,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.customerID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllCustomers: [...prev.AllCustomers, ...fetchMoreResult.AllCustomers]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    const FilterCustomers = (filterData) => {

        var newVariables = {...variables};
        var updated = false;
        
        if(filterData.searchText != "" || (filterData.searchText == "" && newVariables.searchText != ""))
        {
            newVariables.searchText = filterData.searchText;
            updated = true;
        }

        if(updated)
            SetVariables(newVariables)

    }

    const SelectRow = (e,customerID) => {

        var checked = e.target.checked;
        var newSelectedCustomerIDs = [...selectedCustomerIDs];
        const newList = SelectCustomerID(content,customerID,checked,newSelectedCustomerIDs)

        SetContent(newList);
        SetSelectedCustomerIDs(newSelectedCustomerIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedCustomerIDs = [];
        const newList = SelectCustomerID(content,"all",checked,newSelectedCustomerIDs)

        SetSelectedCustomerIDs(newSelectedCustomerIDs);
        SetContent(newList);
    }

    const SelectCustomerID = (list,customerID,checked,selectedCustomerIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(customerID == newList[i].rowID || customerID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedCustomerIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedCustomerIDs.length; j++)
                    {
                        if(selectedCustomerIDs[j] == newList[i].rowID)
                            selectedCustomerIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectCustomerID(newList[i].subData,customerID,checked,selectedCustomerIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        refetch();
    }

    return{
        content,
        totalContentLength: data && data.AllCustomersCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedCustomerIDs,
        SelectRow,
        SelectAll,
        FetchMore,
        GoToPage,
        FilterCustomers,
        UpdateList
    }

}

export default UseGetAllCustomers;