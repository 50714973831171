import { useMutation } from "@apollo/client";
import { ADD_EDIT_LANGUAGE } from "../../Queries/language";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseAddEditLanguage = (selectedLanguageID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData
    } = UseFormHandle({
        enabled:0   
    });

    const [AddEditLanguageMutation,{error,loading}] = useMutation(ADD_EDIT_LANGUAGE,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.addEditLanguage);

            const notifyText = (selectedLanguageID != 0 ? t("SuccessfullyUpdated") : t("NewLanguageIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditLanguage = () => {

        AddEditLanguageMutation({
            variables:{
                languageID: selectedLanguageID,
                enabled:    (formData.enabled ? 1 : 0),
                
            }
        })
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditLanguage,
        SetFormData,
        InitFormData
    }

}

export default UseAddEditLanguage;