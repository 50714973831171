import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { EDIT_SETTINGS } from "../../Queries/settings";

const UseEditWebSettings = (OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
    } = UseFormHandle({
        openAiApiKey:"",
        useThirdPartyForTransaltion:0
    });

    const [EditSettingsMutation,{error,loading}] = useMutation(EDIT_SETTINGS,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.EditSettings);

            AddNotification(t("SuccessfullyUpdated"),true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const EditWebSettings = () => {

        formData.useThirdPartyForTransaltion = formData.useThirdPartyForTransaltion.toString();
        
        if(formData.useThirdPartyForTransaltion == 1 && !formData.openAiApiKey)
            return AddNotification(t("OpenAiApiKeyNeedsToBeFilled"),false);

        EditSettingsMutation({
            variables:{
                data: formData
            }
        })
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        EditWebSettings,
        SetFormData,
        InitFormData
    }

}

export default UseEditWebSettings;