import Input from "../../../GlobalComponents/Input";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import Loading from "../../../GlobalComponents/Loading";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import Error from "../../../GlobalComponents/Error";
import UseEditSettings from "../Library/EmailSettings/UseEditSettings";
import UseGetSettings from "../Library/EmailSettings/UseGetSettings";
import Label from "../../../GlobalComponents/Label";
import { UseSettings } from "../../../AdminProvider";

const EmailSettings = () => {

    const {t} = useTranslation();
    const {RefetchSettings} = UseSettings();

    const {
        formData,
        loading:editLoading,
        InitFormData,
        SetFormData,
        EditWebSettings
    } = UseEditSettings(() => {
        RefetchSettings();
    });

    const {loading,error} = UseGetSettings((d) => {
        InitFormData(d);
    });

    return(
        <>
            <ContentHeader
                title = {t("Emails")}
                OnSaveButtonClick = {() => EditWebSettings()}
            />
            <ContentBody>

                {error ?
                    <Error text = {GetApolloErrorText(error)} />
                :
                    (loading || editLoading ?
                        <Loading />
                    :
                        <>
                            <div className="row">
                                <div className="col-50">
                                    <Label 
                                        text={t("Email")}
                                        infoContent = {[
                                            t("AdminEmailInfo1"),
                                            t("AdminEmailInfo2")
                                        ]}
                                    />
                                    <div className="form-group">
                                        <Input 
                                            className="form-control" 
                                            type="text" 
                                            name="email"
                                            OnChange={(e) => SetFormData(e)}
                                            value={formData.email}
                                        />
                                        
                                    </div>
                                </div>
                                <div className="col-50">

                                    <Label 
                                        text={t("EmailName")}
                                        infoContent = {[
                                            t("AdminemailNameInfo"),
                                        ]}
                                    />
                                    <div className="form-group">
                                        <Input 
                                            className="form-control" 
                                            type="text" 
                                            name="emailName"
                                            OnChange={(e) => SetFormData(e)}
                                            value={formData.emailName}
                                        />
                                        
                                    </div>

                                </div>
                                <div className="col-50">

                                    <label className="input-label">{t("AdminUrl")}</label>
                                    <div className="form-group">
                                        <Input 
                                            className="form-control" 
                                            type="text" 
                                            name="adminUrl"
                                            OnChange={(e) => SetFormData(e)}
                                            value={formData.adminUrl}
                                        />
                                        
                                    </div>

                                </div>

                                <div className="col-50">
                                    <Label 
                                        text={t("EmailForTesting")}
                                        infoContent = {[
                                            t("EmailForTestingInfo")
                                        ]}
                                    />
                                    <div className="form-group">

                                        <Input 
                                            className="form-control" 
                                            type="text" 
                                            name="emailForTesting"
                                            OnChange={(e) => SetFormData(e)}
                                            value={formData.emailForTesting}
                                        />
                                        
                                    </div>
                                </div>

                                <div className="col-100">

                                    <label className="input-label">{t("SendgridApiKey")}</label>
                                    <div className="form-group">
                                        <Input 
                                            className="form-control" 
                                            type="text" 
                                            name="sendgridApiKey"
                                            OnChange={(e) => SetFormData(e)}
                                            value={formData.sendgridApiKey}
                                        />
                                        
                                    </div>
                                </div>
                            </div>               
                        </>                                                                    
                    )
                }

            </ContentBody>
        </>
    )
}

export default EmailSettings;