import { useState } from "react";
import { DELETE_IMAGES } from "../../Queries/fileManager";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { GET_ALL_IMAGES } from "../../Queries/fileManager";

const UseDeleteImages = (client) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);
    const [selectedItems, SetSelectedImages] = useState([]);

    const [DelImages] = useMutation(DELETE_IMAGES,{
        onCompleted:(d) => {

            AddNotification(t("TheyAreGone"),true);
            
            var IDs = d.RemoveContentManagerImages.split(",");

            const { AllContentManagerImages } = client.readQuery({ query: GET_ALL_IMAGES });
            
            var newArray = [...AllContentManagerImages];
            for(let j in IDs)
            {
                for(let i in newArray)
                {
                    if(newArray[i].contentManagerImageID == IDs[j])
                        newArray.splice(i,1); 
                } 
            }

            client.writeQuery({ 
                query:GET_ALL_IMAGES,
                data:{
                    AllContentManagerImages: newArray
                } 
            });

            SetSelectedImages([]);

        },
        onError:(err) => {          
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            AddNotification(t("NoSelectedImages"),false);
        }
    }

    const DeleteImages = (action) => {

        if(action)
        {       
            var selItems = [];   
            for(let val of selectedItems)
            {
                selItems.push(val.contentManagerImageID);
            }

            DelImages({
                variables:{
                    contentManagerImageIDs:selItems
                }
            })
        }

        SetShowDeleteNotification(false);
    }

    const SelectImage = (item) => {

        var arr = [...selectedItems];

        var check = false;
        for(let i in arr)
        {
            if(item.contentManagerImageID == arr[i].contentManagerImageID)
            {
                check = true;
                arr.splice(i,1);
            }
        }

        if(!check)
            arr.push(item);

        SetSelectedImages(arr);
    }

    return{
        selectedItems,
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteImages,
        SelectImage
    }
}

export default UseDeleteImages;