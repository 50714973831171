import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins,GetElementFontSize,GetOpacityClass } from "../../Library/functions";
import TinyMCEEditor from '../TinyMCEEditor';

const Text = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement,SetSettings} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins    = GetElementMargins(data);
    var className = GetElementFontSize(data);
        className +=  " " + GetOpacityClass(data.opacity);
        className += " " + margins.styleClassName;

    const OnEditorChange = (content) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu,"text",content,"text")
    }

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "text"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={className + " " + data.className + " " + margins.className} style={margins.styles} >
                <TinyMCEEditor 
                    value = {data.text}
                    OnEditorChange={(content) => OnEditorChange(content)}
                    toolbar = 'bold italic forecolor link bullist numlist alignleft aligncenter alignright alignjustify removeformat'
                />
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Text;