import {useQuery} from '@apollo/client';
import { GET_COVERS } from '../../Queries/covers';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllCovers = (lang) => {

    const [variables,SetVariables] = useState({
        manufacturerID:0,
        searchText:"",
        lang,
        limit:40,
        offset:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedCoverIDs,SetSelectedCoverIDs] = useState([]);
    const [allManufacturers, SetAllManufacturers] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_COVERS,{
        variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllCovers: [...prev.AllCovers, ...fetchMoreResult.AllCovers]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCovers);
            SetContent(contentData);

            const allManufacturers = [{manufacturerID:"0",name:t("---choose---")},...data.AllManufacturers]
            SetAllManufacturers(allManufacturers);
        }

    },[data])

    var headerData = [
        {
            value: t("PrivateName"),
            className:"flex-1"
        },
        {
            value: t("Shortcut"),
            className:"static text-center"
        },
        {
            value: t("AssignedToManufacturer"),
            className:"flex-1 text-center"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];
        
        for(let val of data)
        {
            var manufacturers = "";
            if(val.manufacturers && val.manufacturers.length > 0)
            for(let v of val.manufacturers)
            {
                if(manufacturers)
                    manufacturers += ", ";

                if(v.manufacturer)
                    manufacturers += v.manufacturer.name;
            }

            var cData = {
                data:[
                    {
                        value:val.privateName,
                        className:"flex-1"
                    },
                    {
                        value:val.shortcut,
                        className:"static text-center"
                    },
                    {
                        value:manufacturers,
                        className:"flex-1 text-center"
                    }
                ],
            }

            cData.rowID    = val.coverID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,coverID) => {

        var checked = e.target.checked;
        var newSelectedCoverIDs = [...selectedCoverIDs];
        const newList = SelectCoverID(content,coverID,checked,newSelectedCoverIDs)

        SetContent(newList);
        SetSelectedCoverIDs(newSelectedCoverIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedCoverIDs = [];
        const newList = SelectCoverID(content,"all",checked,newSelectedCoverIDs)

        SetSelectedCoverIDs(newSelectedCoverIDs);
        SetContent(newList);
    }

    const SelectCoverID = (list,coverID,checked,selectedCoverIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(coverID == newList[i].rowID || coverID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedCoverIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedCoverIDs.length; j++)
                    {
                        if(selectedCoverIDs[j] == newList[i].rowID)
                            selectedCoverIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectCoverID(newList[i].subData,coverID,checked,selectedCoverIDs)}
        }

        return newList;
    }

    const Filter = (filterData) => {

        var newVariables = {...variables};
        var updated = false;
        var manufacturerID = 0;

        if(filterData.manufacturerID != 0)
            manufacturerID = filterData.manufacturerID;
       
        if(filterData.searchText != "" || (filterData.searchText == "" && newVariables.searchText != ""))
        {
            newVariables.searchText = filterData.searchText;
            updated = true;
        }

        if(manufacturerID != 0 || (manufacturerID == 0 && newVariables.manufacturerID != 0))
        {
            newVariables.manufacturerID = manufacturerID;
            updated = true;
        }

        if(updated)
            SetVariables(newVariables)

    }

    return{
        content,
        totalContentLength: data && data.AllCoversCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedCoverIDs,
        allManufacturers,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll,
        Filter
    }

}

export default UseGetAllCovers;