import { useTranslation } from 'react-i18next';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import { TINY_MCE_TOOLBAR_MEDIA, TINY_MCE_TOOLBAR_BASIC_FORMAT } from '../../../Config';
import Label from '../../../GlobalComponents/Label';
import Input from '../../../GlobalComponents/Input';

const TextsToSend = ({
    formData,
    GetFormLangValue,
    SetFormLangData,
    SetFormData
}) => {

    const {t} = useTranslation();
    
    return(
        <> 
            {formData.selectedReservationPlaces && formData.selectedReservationPlaces.length > 0 ?
                <>
                    <div className="form-group">
                        <Label
                            text = {"*" + t("NumberOfDaysBeforeToSendReservationReminder")}
                            infoContent={[t("NumberOfDaysBeforeToSendReservationReminderInfo")]}
                        />
                        
                        <Input
                            name="numberOfDaysBeforeToSendReservationReminder"
                            type="number"
                            value={formData.numberOfDaysBeforeToSendReservationReminder}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                    <div className="form-group">
                        <Label 
                            text = {t("ReservationReminderEmail") + " (" + formData.selectedLang + ")"} 
                            badgeText = {t("IncludeAddressingAndResData")}
                            infoContent={[
                                t("ReservationReminderEmailInfo1"),
                                t("ReservationReminderEmailInfo2")
                            ]}
                        />
                        <TinyMCEEditor 
                            value = {GetFormLangValue("reservationReminderEmail")}
                            OnEditorChange={(content) => SetFormLangData("reservationReminderEmail",content)}
                            toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                            useMediaEditor={true}
                        />
                    </div>
                </>
            :null}
            <div className="form-group">
                <Label 
                    text = {t("MessageAfterSuccessfulOrder") + " (" + formData.selectedLang + ")"} 
                    infoContent={[
                        t("MessageAfterSuccessfulOrderInfo1"),
                        t("MessageAfterSuccessfulOrderInfo2")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("successMessage")}
                    OnEditorChange={(content) => SetFormLangData("successMessage",content)}
                    toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                    useMediaEditor={true}
                />
            </div>
            <div className="form-group">
                <Label 
                    text = {t("EmailAfterPurchaseThisMembership") + " (" + formData.selectedLang + ")"} 
                    badgeText = {t("IncludeAddressingAndResData")}
                    infoContent={[
                        t("EmailAfterPurchaseThisMembershipInfo")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("emailText")}
                    OnEditorChange={(content) => SetFormLangData("emailText",content)}
                    toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                    useMediaEditor={true}
                />
            </div>
            
            {formData.isRecurring ?
                <>
                    <div className="form-group">
                        <Label 
                            text = {t("NewSuccessfulRecurringPaymentEmail") + " (" + formData.selectedLang + ")"} 
                            badgeText = {t("IncludeAddressing")}
                            infoContent={[
                                t("NewSuccessfulRecurringPaymentEmailInfo1"),
                                t("NewSuccessfulRecurringPaymentEmailInfo2"),
                                t("NewSuccessfulRecurringPaymentEmailInfo3")
                            ]}
                        />
                        <TinyMCEEditor 
                            value = {GetFormLangValue("recurringPaymentInfoEmail")}
                            OnEditorChange={(content) => SetFormLangData("recurringPaymentInfoEmail",content)}
                            toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                            useMediaEditor={true}
                        />
                    </div>
                </>
            :null}
            {formData.useFreeTrialPeriod == 1 ?
                <div className="form-group">
                    <Label 
                        text = {t("MessageWhenTrialPeriodIsNotPossible") + " (" + formData.selectedLang + ")"} 
                        infoContent={[
                            t("MessageWhenTrialPeriodIsNotPossibleInfo")
                        ]}
                    />
                    <TinyMCEEditor 
                        value = {GetFormLangValue("noMoreFreeTrialPeriodWarning")}
                        OnEditorChange={(content) => SetFormLangData("noMoreFreeTrialPeriodWarning",content)}
                        toolbar = {TINY_MCE_TOOLBAR_BASIC_FORMAT}
                        useMediaEditor={true}
                    />
                </div>
            :null} 
            <div className="form-group">  
                <Label 
                    text = {t("MessageWhenMakeOrderIsNotPossible") + " (" + formData.selectedLang + ")"} 
                    infoContent={[
                        t("MessageWhenMakeOrderIsNotPossibleInfo")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("notPossibleToMakeOrderWarning")}
                    OnEditorChange={(content) => SetFormLangData("notPossibleToMakeOrderWarning",content)}
                    toolbar = {TINY_MCE_TOOLBAR_BASIC_FORMAT}
                    useMediaEditor={true}
                />
            </div>
            <div className={(formData.useGuide ? "form-group" : "")}>
                <Label 
                    text = {t("UpSellBombDescription") + " (" + formData.selectedLang + ")"} 
                    infoContent={[
                        t("UpSellBombDescriptionInfo1"),
                        t("UpSellBombDescriptionInfo2")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("upSellBombDescription")}
                    OnEditorChange={(content) => SetFormLangData("upSellBombDescription",content)}
                    toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                    useMediaEditor={true}
                />
            </div>
            {formData.useGuide ?
                <div>
                    <Label 
                        text = {t("EmailForCustomerWhenAddedToGuide") + " (" + formData.selectedLang + ")"} 
                        badgeText = {t("IncludeAddressing")}
                        infoContent={[
                            t("EmailForCustomerWhenAddedToGuideInfo1"),
                            t("EmailForCustomerWhenAddedToGuideInfo2")
                        ]}
                    />
                    <TinyMCEEditor 
                        value = {GetFormLangValue("emailForCustomerWhenAddedToGuide")}
                        OnEditorChange={(content) => SetFormLangData("emailForCustomerWhenAddedToGuide",content)}
                        toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                        useMediaEditor={true}
                    />
                </div>
            :null}             
        </>
    )
}

export default TextsToSend;