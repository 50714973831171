import { useMutation } from "@apollo/client";
import { UPDATE_COVER_TITLE_PRIORITY,GET_COVER_TITLES } from "../../Queries/coverTitles";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseSortCoverTitle = (client,variables) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const [UpdateCoverTitlePriority] = useMutation(UPDATE_COVER_TITLE_PRIORITY,{
        onCompleted:(d) => {

            const notifyText = t("NewPositionSaved");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const Reorder = (list, startIndex, endIndex) => {
        
        var newList = [...list];
    
        const [removed] = newList.splice(startIndex, 1);
        newList.splice(endIndex, 0, removed);
      
        return newList;
    };

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {
            const { AllCoverTitles } = client.readQuery({ query: GET_COVER_TITLES, variables:variables });
            const newArray = Reorder(AllCoverTitles,result.source.index,result.destination.index);

            client.writeQuery({ 
                query:GET_COVER_TITLES,
                variables:variables,
                data:{
                    AllCoverTitles: newArray
                } 
            });

            UpdateCoverTitlePriority({
                variables:{
                    coverTitleID: result.draggableId,
                    fromIndex:result.source.index,
                    toIndex:  result.destination.index
                }
            })
        }
    } 

    return {
        OnDragEnd
    }

}

export default UseSortCoverTitle;