import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_ROLE } from "../Queries/customerRole";
import { useState } from 'react';

const UseGetCustomerRole = (selectedCustomerRoleID,CallBack) => {

    const [allPortalModules, SetAllPortalModules] = useState([]);
    const [allProjects, SetAllProjects] = useState([]);

    const {loading,error} = useQuery(GET_CUSTOMER_ROLE,{
        variables:{
            customerRoleID:selectedCustomerRoleID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            if(selectedCustomerRoleID != 0)
            { 
                CallBack({
                    name:           d.CustomerRole.name,
                    projectID:      d.CustomerRole.projectID,
                    portalModules:  GetPortalModules(d.CustomerRole.portalModules)
                });
            }

            SetAllPortalModules(d.AllPortalModulesBy);
            SetAllProjects(d.AllProjects);
        }
    });

    const GetPortalModules = (portalModules) => {

        if(portalModules)
        {
            var newPortalModules = [];
            for(let val of portalModules)
            {
                newPortalModules.push(val.portalModuleID);
            }

            return newPortalModules;
        }
        else
            return []
    }

    return{
        loading: loading,
        error: error,
        allPortalModules,
        allProjects
    }

}

export default UseGetCustomerRole;