import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { LOGIN_USER } from '../Queries/auth';
import {useMutation,useQuery } from '@apollo/client';
import UseFormHandle from '../../../GlobalHooks/UseFormHandle';
import { useTranslation } from 'react-i18next';
import { UseAuth } from './UseAuth';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { GET_SETTINGS } from '../../Settings/Queries/settings';

const UseLogin = () => {

	const {
		user,
		token,
		GetAdminUserContext,
		SaveToken,
		Logout
	} = UseAuth();

	const {t} = useTranslation();
    const navigate = useNavigate();

	const {data:settData,loading:settLoading} = useQuery(GET_SETTINGS)

	const {formData, SetFormData} = UseFormHandle({
		email:"",
		password:""
	});
	
	const [disabled,SetDisabled] = useState(false);
    const [error,SetError] = useState("");

    useEffect(() => {

		document.addEventListener("keyup",PressKey);
		return () => {
			document.removeEventListener("keyup",PressKey);
		}

	},[formData])

	useEffect(() => {

		if(user.adminUserID != 0)
		{
			var url = localStorage.getItem("urlToShow");
            localStorage.setItem("urlToShow", "");

			if(url && url != "")
				navigate(url);
			else
				RedirectTo(user);
		}

	},[user])

	useEffect(() => {

		if(token)
			GetAdminUserContext();	

	},[token])

    const [LoginUser,{loading}] = useMutation(LOGIN_USER,{

		onCompleted: async (data) => {

			if(!data.AdminLogin.adminUser)
			{
				SetError(t('WronCredentialInserted'));
				setTimeout(() => {SetError(''); SetDisabled(false)},3000);
			}
			else
				SaveToken(data.AdminLogin.loginToken);
		},
		onError:(err) => {

			err = GetApolloErrorText(err);

			SetError(err);
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	});

    const Login = () => {

		const {password,email} = formData;

		SetDisabled(true);
						
		if(password !== '' && email !== ''){

			LoginUser({
				variables:{
					email,
					password
				}
			});  
			
		}else{
			SetDisabled(false);
			SetError(t('NotFilledEmailOrPwd'));
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	}

	const RedirectTo = (adminUser) => {

		if(adminUser.adminRoleModules && adminUser.adminRoleModules.length > 0)
		{
			var modules = [...adminUser.adminRoleModules];
			var projects = [...adminUser.adminRoleProjects];

			modules.sort((a,b) => a.module.isForProject - b.module.isForProject);

			var link = modules[0].module.link;

			for(let val of modules)
			{
				if(val.module.parentModuleID == modules[0].moduleID)
				{
					link = val.module.link;
				}
			}

			if(modules[0].module.isForProject)
			{
				if(projects.length == 0)
				{
					Logout();
					SetError(t("YouHaveNoAccessToAdmin"));
					setTimeout(() => {SetError(''); SetDisabled(false)},3000);
					return;
				}

				link = "/" + projects[0].projectID + "-" + projects[0].project.niceName + link;
			}
			
			navigate(link);
		}
		else
		{
			Logout();
			SetError(t("YouHaveNoAccessToAdmin"));
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	}

    const PressKey = (e) => {
		if(e.key == "Enter")
        Login();
	}

    return {
        formData,
        disabled,
        error,
		loading: (loading || settLoading),
		settData,
		SetFormData,
        Login,
		RedirectTo
    }

}

export default UseLogin;