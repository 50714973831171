const Moon = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M506.13,302.45c-3.843-2.548-8.863-2.429-12.58,0.295c-33.372,24.453-72.994,37.378-114.584,37.378
                        c-106.609,0-193.342-86.228-193.342-192.215c0-48.03,17.913-94.011,50.439-129.472c3.118-3.4,3.784-8.385,1.667-12.484
                        s-6.567-6.442-11.146-5.866C97.951,16.258,0.948,125.857,0.948,255.023c0,68.653,26.88,133.193,75.688,181.732
                        c48.792,48.523,113.659,75.246,182.653,75.246c29.882,0,59.174-5.034,87.061-14.96c26.952-9.595,51.991-23.529,74.422-41.417
                        c44.906-35.813,76.863-86.055,89.984-141.47C511.817,309.668,509.972,304.997,506.13,302.45z M259.289,490.001
                        c-130.318,0-236.341-105.411-236.341-234.978c0-108.421,74.782-201.77,178.071-227.788
                        c-24.279,35.369-37.396,77.267-37.396,120.673c0,57.23,22.407,111.032,63.094,151.495c40.67,40.446,94.74,62.721,152.248,62.721
                        c36.108,0,70.895-8.758,102.09-25.533C447.256,428.199,359.768,490.001,259.289,490.001z"/>
                    
                </g>
                
            </svg>
        </>
    )
}

export default Moon;
