import { useEffect } from "react";
import Input from "../../../../GlobalComponents/Input";
import Select from "../../../../GlobalComponents/Select";
import { UseContentManager } from "../../Library/UseContentManager";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import imgIcon from "../../../../Media/Images/Icons/image.webp";
import responziveIcon from "../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../GlobalComponents/InputCard";
import SectionResponziveSettings from "./SectionResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../Library/functions";
import RadioButton from "../../../../GlobalComponents/RadioButton";

const SectionSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(
        {
            backgroundColorClass:"",
            backgroundImage:"",
            fullHeight:0,
            fullWidth:0,
            sectionName:"",
            className:"",
            imageFill:"",
            imageAlign:"",
            contentAlign:"",
            fadeWithBackground:0,
            maxFullWidth:0,
            ptXXS:0,
            pbXXS:0,
            prXXS:0,
            plXXS:0,
            ptXS:0,
            pbXS:0,
            prXS:0,
            plXS:0,
            ptSM:0,
            pbSM:0,
            prSM:0,
            plSM:0,
            ptMD:0,
            pbMD:0,
            prMD:0,
            plMD:0,
            ptLG:0,
            pbLG:0,
            prLG:0,
            plLG:0,
            ptXL:0,
            pbXL:0,
            prXL:0,
            plXL:0
        }
    ); 
    const {
        showSettingsElement,
        GetSectionSettings,
        SetSectionSettings,
        RemoveSection,
        ClearSelection,
        SetOpenFileManagerData
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSectionSettings();
            SetCompleteFormData({
                backgroundColorClass:   settings.backgroundColorClass,
                backgroundImage:        settings.backgroundImage,
                fullHeight:             settings.fullHeight,
                fullWidth:              settings.fullWidth,
                maxFullWidth:           settings.maxFullWidth,
                sectionName:            settings.sectionName,
                className:              settings.className,
                imageFill:              settings.imageFill,
                imageAlign:             settings.imageAlign,
                contentAlign:           settings.contentAlign,
                fadeWithBackground:     settings.fadeWithBackground,
                ptXXS:                  settings.ptXXS,
                pbXXS:                  settings.pbXXS,
                prXXS:                  settings.prXXS,
                plXXS:                  settings.plXXS,
                ptXS:                   settings.ptXS,
                pbXS:                   settings.pbXS,
                prXS:                   settings.prXS,
                plXS:                   settings.plXS,
                ptSM:                   settings.ptSM,
                pbSM:                   settings.pbSM,
                prSM:                   settings.prSM,
                plSM:                   settings.plSM,
                ptMD:                   settings.ptMD,
                pbMD:                   settings.pbMD,
                prMD:                   settings.prMD,
                plMD:                   settings.plMD,
                ptLG:                   settings.ptLG,
                pbLG:                   settings.pbLG,
                prLG:                   settings.prLG,
                plLG:                   settings.plLG,
                ptXL:                   settings.ptXL,
                pbXL:                   settings.pbXL,
                prXL:                   settings.prXL,
                plXL:                   settings.plXL
            })
        }

    },[showSettingsElement])

    const SetSettings = (e,val) => {
        SetFormData(e,val);
        SetSectionSettings(showSettingsElement.index,e,val);
    }

    const RemoveSec = () => {
        console.log(showSettingsElement);
        RemoveSection(showSettingsElement.index);
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <SectionResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetSettings(e)} />,
        LG : <SectionResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetSettings(e)} />,
        MD : <SectionResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetSettings(e)} />,
        SM : <SectionResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetSettings(e)} />,
        XS : <SectionResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetSettings(e)} />,
        XXS : <SectionResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetSettings(e)} />,
    });

    return(
        <>
            <div className="form-group">
                <InputCard
                    title = {t("BackgroundImage")}
                    icon = {imgIcon}
                >
                    <div className="form-group">
                        {formData.backgroundImage ?
                            <div className = "row">
                                <div className = "col-50">
                                    <Button 
                                        className="btn-primary w-100"
                                        OnClick = {() => SetOpenFileManagerData({
                                            element:"section",
                                            type:1,
                                            index:showSettingsElement.index,
                                            CallBack: (fileName) => SetSettings("backgroundImage",fileName)
                                        })}
                                    >{t("ChangePhoto")}</Button>
                                </div>
                                <div className = "col-50">
                                    <Button 
                                        className="btn-brown w-100"
                                        OnClick = {() => SetSettings("backgroundImage","")}
                                    >{t("RemovePhoto")}</Button>
                                </div>
                            </div>
                        :
                            <Button 
                                className="btn-primary w-100"
                                OnClick = {() => SetOpenFileManagerData({
                                    element:"section",
                                    type:1,
                                    index:showSettingsElement.index,
                                    CallBack: (fileName) => SetSettings("backgroundImage",fileName)
                                })}
                            >{t("ChoosePhoto")}</Button>
                        }
                    </div>
                    <div className="form-group">
                        <label>{t("ImageFillType")}</label>
                        <Select
                            name = {"imageFill"}
                            OnChange = {(e) => SetSettings(e)}
                            value = {formData.imageFill}
                        >
                            <option value={"cover"}>{t("coverWholeContent")}</option>
                            <option value={"contain"}>{t("showWholeImage")}</option>
                            <option value={"auto"}>{t("staticAsItIs")}</option>
                        </Select>
                    </div>
                    <div className="form-group">
                        <label>{t("ImageAlignType")}</label>
                        <Select
                            name = {"imageAlign"}
                            OnChange = {(e) => SetSettings(e)}
                            value = {formData.imageAlign}
                        >
                            <option value={"left"}>{t("left")}</option>
                            <option value={"center"}>{t("center")}</option>
                            <option value={"right"}>{t("right")}</option>
                        </Select>
                    </div>
                    <div className="form-group">
                                                
                        <label>{t("FadeWithBackground")}</label>
                        <div className="radio-container">
                            <RadioButton
                                text="Ano"
                                name="fadeWithBackground"
                                id="fadeWithBackground-1"
                                value={1}
                                checked = {formData.fadeWithBackground === 1 ? true : false}
                                OnChange={(e) => SetSettings(e)}
                            />
                            <RadioButton
                                text="Ne"
                                name="fadeWithBackground"
                                id="fadeWithBackground-2"
                                value={0}
                                checked = {formData.fadeWithBackground === 0 ? true : false}
                                OnChange={(e) => SetSettings(e)}
                            />
                        </div>
                    </div>
                </InputCard>
            </div>
            <div className="row">
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Height")}</label>
                        <Select
                            name = {"fullHeight"}
                            OnChange = {(e) => SetSettings(e)}
                            value = {formData.fullHeight}
                        >
                            <option value={0}>{t("Auto")}</option>
                            <option value={1}>{t("ToWholeHeight")}</option>
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("ContentAlign")}</label>
                        <Select
                            name = {"contentAlign"}
                            OnChange = {(e) => SetSettings(e)}
                            value = {formData.contentAlign}
                        >
                            <option value={"top"}>{t("ToTop")}</option>
                            <option value={"center"}>{t("ToCenter")}</option>
                            <option value={"bottom"}>{t("ToBottom")}</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Width")}</label>
                        <Select
                            name = {"fullWidth"}
                            OnChange = {(e) => SetSettings(e)}
                            value = {formData.fullWidth}
                        >
                            <option value={0}>{t("FixedWidth")}</option>
                            <option value={1}>{t("ToWholeWidth")}</option>
                        </Select>
                    </div>
                </div>
                {formData.fullWidth == 1 ?
                    <div className="col-50">
                        <div className="form-group">
                            <label>{t("MaxFullWidth")}</label>
                            <Input 
                                type = "number"
                                name = "maxFullWidth"
                                value = {formData.maxFullWidth}
                                OnChange = {(e) => SetSettings(e)}
                            />
                        </div>
                    </div>
                :null}
                
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("BackgroundColor")}</label>
                        <Select
                            name = {"backgroundColorClass"}
                            OnChange = {(e) => SetSettings(e)}
                            value = {formData.backgroundColorClass}
                        >
                            <option value={""}></option>
                            <option value={"grey"}>Šedá</option>
                            <option value={"red"}>Červená</option>
                            <option value={"brown"}>Hnědá</option>
                            <option value={"black"}>Černá</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("SectionName")}</label>
                        <Input 
                            type = "text"
                            name = "sectionName"
                            value = {formData.sectionName}
                            OnChange = {(e) => SetSettings(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetSettings(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            {showSettingsElement.index != 0 ?
                <div className="cm-remove-section-elm">
                    <Button 
                        className="btn-brown"
                        OnClick = {() => RemoveSec()}
                    >
                            {t("RemoveSection")}
                    </Button>
                </div>
            :null}
        </>
    )
}

export default SectionSettings;