const WebContent = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <g>
                            <g>
                                <path style={{"fill":secondaryColor}}  d="M208.017,96.143H75.67c-6.075,0-11-4.925-11-11s4.925-11,11-11h132.347c6.075,0,11,4.925,11,11
                                    S214.092,96.143,208.017,96.143z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path style={{"fill":secondaryColor}}  d="M354.569,96.14c-4.534,0-8.684-2.885-10.284-7.123c-1.598-4.233-0.379-9.157,3.031-12.14
                                        c3.61-3.158,8.985-3.598,13.06-1.072c3.852,2.388,5.857,7.045,4.983,11.485C364.358,92.374,359.739,96.14,354.569,96.14z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path style={{"fill":secondaryColor}}  d="M395.45,96.14c-4.61,0-8.78-2.97-10.346-7.292c-1.563-4.313-0.175-9.295,3.369-12.204
                                        c3.508-2.878,8.544-3.283,12.473-1.023c4.03,2.317,6.21,7.111,5.282,11.67C405.195,92.373,400.655,96.14,395.45,96.14z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path style={{"fill":secondaryColor}}  d="M436.33,96.14c-4.587,0-8.818-2.964-10.35-7.292c-1.533-4.33-0.19-9.283,3.371-12.204
                                        c3.508-2.877,8.547-3.284,12.476-1.023c4.029,2.317,6.209,7.113,5.282,11.67C446.076,92.37,441.533,96.14,436.33,96.14z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M417.841,165.723H94.159c-6.075,0-11,4.925-11,11v54.384c0,6.075,4.925,11,11,11h323.682c6.075,0,11-4.925,11-11v-54.384
                            C428.841,170.648,423.916,165.723,417.841,165.723z M406.841,220.107H105.159v-32.384h301.682V220.107z"/>
                        <path style={{"fill":primaryColor}} d="M164.438,269.703H94.159c-6.075,0-11,4.925-11,11v48.515c0,6.075,4.925,11,11,11h70.278c6.075,0,11-4.925,11-11v-48.515
                            C175.438,274.627,170.513,269.703,164.438,269.703z M153.438,318.218h-48.278v-26.515h48.278V318.218z"/>
                        <path style={{"fill":primaryColor}} d="M291.139,269.703h-70.277c-6.075,0-11,4.925-11,11v48.515c0,6.075,4.925,11,11,11h70.277c6.075,0,11-4.925,11-11v-48.515
                            C302.139,274.627,297.214,269.703,291.139,269.703z M280.139,318.218h-48.277v-26.515h48.277V318.218z"/>
                        <path style={{"fill":primaryColor}} d="M417.841,269.703h-70.278c-6.075,0-11,4.925-11,11v48.515c0,6.075,4.925,11,11,11h70.278c6.075,0,11-4.925,11-11v-48.515
                            C428.841,274.627,423.916,269.703,417.841,269.703z M406.841,318.218h-48.278v-26.515h48.278V318.218z"/>
                        <path style={{"fill":primaryColor}} d="M471.19,33.083H40.81C18.307,33.083,0,51.391,0,73.893v364.214c0,22.502,18.307,40.81,40.81,40.81H471.19
                            c22.503,0,40.81-18.307,40.81-40.81V73.893C512,51.391,493.693,33.083,471.19,33.083z M40.81,55.083H471.19
                            c10.372,0,18.81,8.438,18.81,18.81v41.31H22v-41.31C22,63.521,30.438,55.083,40.81,55.083z M471.19,456.917H40.81
                            c-10.372,0-18.81-8.438-18.81-18.81V137.203h468v300.904C490,448.479,481.562,456.917,471.19,456.917z"/>
                        <path style={{"fill":primaryColor}} d="M164.438,361.479H94.159c-6.075,0-11,4.925-11,11s4.925,11,11,11h70.278c6.075,0,11-4.925,11-11
                            S170.513,361.479,164.438,361.479z"/>
                        <path style={{"fill":primaryColor}} d="M164.438,402.664H94.159c-6.075,0-11,4.925-11,11s4.925,11,11,11h70.278c6.075,0,11-4.925,11-11
                            S170.513,402.664,164.438,402.664z"/>
                        <path style={{"fill":primaryColor}} d="M291.139,361.479h-70.277c-6.075,0-11,4.925-11,11s4.925,11,11,11h70.277c6.075,0,11-4.925,11-11
                            S297.214,361.479,291.139,361.479z"/>
                        <path style={{"fill":primaryColor}} d="M291.139,402.664h-70.277c-6.075,0-11,4.925-11,11s4.925,11,11,11h70.277c6.075,0,11-4.925,11-11
                            S297.214,402.664,291.139,402.664z"/>
                        <path style={{"fill":primaryColor}} d="M417.841,361.479h-70.278c-6.075,0-11,4.925-11,11s4.925,11,11,11h70.278c6.075,0,11-4.925,11-11
                            S423.916,361.479,417.841,361.479z"/>
                        <path style={{"fill":primaryColor}} d="M417.841,402.664h-70.278c-6.075,0-11,4.925-11,11s4.925,11,11,11h70.278c6.075,0,11-4.925,11-11
                            S423.916,402.664,417.841,402.664z"/>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default WebContent;