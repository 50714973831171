import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { CompleteLangsArray, GetApolloErrorText } from '../../../GlobalFunctions/helper';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';
import UseGetMembershipType from '../Library/UseGetMembershipType';
import UseAddEditMembershipType from '../Library/UseAddEditMembershipType';
import RadioButton from '../../../GlobalComponents/RadioButton';
import Label from '../../../GlobalComponents/Label';
import Select from '../../../GlobalComponents/Select';
import InputCard from '../../../GlobalComponents/InputCard';
import Input from '../../../GlobalComponents/Input';
import MembershipTypePrice from './MemebrshipTypePrice';
import priceIcon from '../../../Media/Images/Icons/price.webp';
import { UseAuth } from '../../Auth/Library/UseAuth';
import { GetMonths } from '../../../GlobalFunctions/helper';
import NoItems from '../../../GlobalComponents/NoItems';
import UseMembershipTypePrice from '../Library/UseMembershipTypePrice';

Modal.setAppElement('#root');

const AddEditMembershipType = ({
    selectedMembershipTypeID,
    SetSelectedMembershipTypeID,
    OnAddEditSuccess,
    selectedMembershipID
}) => {

    const {user} = UseAuth();
    const {t} = useTranslation();
    const {GetEmptyMembershipTypePrice} = UseMembershipTypePrice();
    
    var itemsToTranslate = [
        {name:"Název",param:"name"}
    ];

    const {
        allLanguageMutations,
        allMembershipTypes,
        mainLang,
        loading:getLoading,
        error:getError
    } = UseGetMembershipType(selectedMembershipID,selectedMembershipTypeID,user.lang,(d) => {
        InitFormData(d);
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditMembershipType,
        InitFormData,
        SetFormData,
        GetFormLangValue,
        SetFormLangData,
        SetFormDataSelectedItemsWithObjAndIndex,
        SetBooleanFormDataSelectedItemsWithIndex
    } = UseAddEditMembershipType(selectedMembershipTypeID,selectedMembershipID,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    const AddEmptyMembershipTypePrice = () => {

        const obj = GetEmptyMembershipTypePrice(allLanguageMutations);
        SetFormDataSelectedItemsWithObjAndIndex("prices",obj,formData.prices.length);

    }

    const hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);
    const months = GetMonths(12,t);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedMembershipTypeID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {selectedMembershipTypeID === 0 ? t("AddMembershipType") : t("EditMembershipType")}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedMembershipTypeID === 0 ? false : true}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedMembershipTypeID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        :  
                            <>

                               <div className="row">
                                    <div className="col-33">
                                        <div className="form-group">
                                            
                                            <Label
                                                text= {t("ShowForPurchaseOnWeb")}
                                                infoContent={[
                                                    t("ShowForPurchaseOnWebInfo")
                                                ]}
                                            />
                                            <div className="radio-container">
                                                <RadioButton
                                                    text={t("Yes")}
                                                    name="showForPurchaseOnWeb"
                                                    id={"showForPurchaseOnWeb-1"}
                                                    value={1}
                                                    checked = {formData.showForPurchaseOnWeb === 1 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                                <RadioButton
                                                    text={t("No")}
                                                    name="showForPurchaseOnWeb"
                                                    id={"showForPurchaseOnWeb-2"}
                                                    value={0}
                                                    checked = {formData.showForPurchaseOnWeb === 0 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col-33">
                                        <div className="form-group">
                                            
                                            <Label
                                                text= {t("ShowForPurchaseInPortal")}
                                                infoContent={[
                                                    t("ShowForPurchaseInPortalInfo")
                                                ]}
                                            />
                                            <div className="radio-container">
                                                <RadioButton
                                                    text={t("Yes")}
                                                    name="showForPurchaseInPortal"
                                                    id={"showForPurchaseInPortal-1"}
                                                    value={1}
                                                    checked = {formData.showForPurchaseInPortal === 1 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                                <RadioButton
                                                    text={t("No")}
                                                    name="showForPurchaseInPortal"
                                                    id={"showForPurchaseInPortal-2"}
                                                    value={0}
                                                    checked = {formData.showForPurchaseInPortal === 0 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>                                                          
                                </div> 
                                <div className="row">
                                    <div className="col-40">
                                        <div className="form-group">
                                            <label>*{t("Name")} ({formData.selectedLang})</label>
                                            <Input
                                                name="name"
                                                value={GetFormLangValue("name")}
                                                OnChange={(e) => SetFormLangData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-20">
                                        <div className="form-group">
                                            <Label
                                                text= {t("NumberOfRepetitions")}
                                                infoContent={[
                                                    t("NumberOfRepetitionsInfo1"),
                                                    t("NumberOfRepetitionsInfo2"),
                                                    t("NumberOfRepetitionsInfo3")
                                                ]}
                                            />
                                            <Input
                                                name="numberOfRepetitions"
                                                type="number"
                                                disabled = {selectedMembershipTypeID != 0 ? true : false}
                                                value={formData.numberOfRepetitions}
                                                OnChange={(e) => selectedMembershipTypeID == 0 && SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-40">
                                        <div className="form-group">
                                            <Label
                                                text= {t("LengthOfOneRepetition")}
                                                infoContent={[
                                                    t("LengthOfOneRepetitionInfo1"),
                                                    t("LengthOfOneRepetitionInfo2"),
                                                    t("LengthOfOneRepetitionInfo3")
                                                ]}
                                            />
                                            <Select
                                                name="lengthOfOneRepetition"
                                                value={formData.lengthOfOneRepetition}
                                                disabled = {selectedMembershipTypeID != 0 ? true : false}
                                                OnChange={(e) => SetFormData(e)}
                                            >
                                                {months.map((item) => (
                                                    <option
                                                        key={item.value}
                                                        value={item.value}
                                                    >{item.name}</option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>

                                <InputCard
                                    title = {t("Prices")}
                                    icon = {priceIcon}
                                    headerInfoContent={[
                                        t("MembershipPriceTypeInfo1"),
                                        t("MembershipPriceTypeInfo2"),
                                        t("MembershipPriceTypeInfo3")
                                    ]}
                                    headerChildren={
                                        <Button
                                            className = "btn-primary narrow"
                                            OnClick = {() => AddEmptyMembershipTypePrice()}
                                        >
                                            {t("Add")}
                                        </Button>
                                    }
                                > 
                                    {formData.prices && formData.prices.length > 0 ?
                                        (formData.prices.map((item,index) => (
                                            <MembershipTypePrice 
                                                key={index}
                                                membershipTypePriceIndex = {index}
                                                disablePrice = {item.membershipTypePriceID != 0 ? true : false}
                                                allMembershipTypes = {allMembershipTypes}
                                                data={item}
                                                selectedLang = {formData.selectedLang}
                                                SetParentIndexData = {(obj) => SetFormDataSelectedItemsWithObjAndIndex("prices",obj,index)}
                                                SetBooleanFormDataSelectedItemsWithIndex = {SetBooleanFormDataSelectedItemsWithIndex}
                                            />
                                        )))
                                    :
                                        <NoItems text={t("NoItems")} />
                                    }

                                </InputCard> 
                                                       
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditMembershipType()}
                    >
                        {(selectedMembershipTypeID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditMembershipType;