import InputCard from '../../../GlobalComponents/InputCard';
import { useTranslation } from 'react-i18next';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import articleIcon from '../../../Media/Images/Icons/articles.webp';
import List from '../../../GlobalComponents/List/List';
import { useState } from 'react';
import UseEditArticleMarketingBanners from '../Library/UseEditArticleMarketingBanners';
import Button from '../../../GlobalComponents/Button';
import ChooseMarketingBanners from './ChooseMarketingBanners';

const ArticleMarketingBanners = ({
    selectedMarketingBannerID,
    formData,
    SetFormData,
    RemoveFormDataSelectedItems,
    FillFormSelectedItemsWithObj
}) => {

    const {t} = useTranslation();

    const [showAddMarketingBanners, SetShowAddMarketingBanners] = useState(false);
    const {
        headerData,
        content,
        OnDragEnd
    } = UseEditArticleMarketingBanners(
        formData.selectedMarketingBanners,
        selectedMarketingBannerID,
        SetFormData
    );
    
    return(
        <>
            <InputCard
                title = {t("MarketingBanners")}
                icon = {articleIcon}
                noBodyPadding={true}
                headerChildren = {
                    <>
                        <Button 
                            OnClick = {(e) => {
                                e.stopPropagation();
                                SetShowAddMarketingBanners(true)
                            }}
                            className="btn-primary narrow"
                        >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>  
                    </>
                }
            >       
                <List 
                    headerData = {headerData} 
                    headerClassName = {"no-top-border"}
                    contentData = {content}
                    isSortable = {true}
                    loading = {false}
                    OnDragListRowEnd={(result) => OnDragEnd(result)}
                    replaceDotsImg = {cancelIcon}
                    ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedMarketingBanners","marketingBannerID",[data.rowID])}
                />

            </InputCard>

            {showAddMarketingBanners ?
                <ChooseMarketingBanners 
                    formData = {formData}
                    SetShowAddMarketingBanners = {SetShowAddMarketingBanners}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
        </>
    )
}

export default ArticleMarketingBanners;