const Cancel = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}}  
                            d="M254.733,509.467c-68.042,0-132.011-26.497-180.124-74.61C26.497,386.745,0,322.775,0,254.733
                            S26.497,122.722,74.609,74.61C122.723,26.497,186.691,0,254.733,0s132.011,26.497,180.124,74.61
                            c48.112,48.112,74.609,112.082,74.609,180.124s-26.497,132.011-74.609,180.124C386.744,482.97,322.775,509.467,254.733,509.467z
                            M254.733,22C126.403,22,22,126.404,22,254.733s104.403,232.733,232.733,232.733s232.733-104.404,232.733-232.733
                            S383.063,22,254.733,22z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M358.553,406.56c-1.9,1.3-4.06,1.92-6.2,1.92c-3.51,0-6.96-1.68-9.09-4.79l-88.4-129.27l-87.86,129.24
                            c-2.12,3.13-5.58,4.82-9.1,4.82c-2.13,0-4.28-0.62-6.18-1.91c-5.02-3.41-6.33-10.25-2.91-15.28l92.72-136.38l-93.49-136.71
                            c-3.43-5.02-2.15-11.86,2.87-15.29c5.01-3.43,11.86-2.15,15.29,2.87l88.61,129.59l88.09-129.57c3.41-5.02,10.25-6.32,15.28-2.91
                            c5.02,3.42,6.33,10.26,2.91,15.28l-92.94,136.7l93.27,136.4C364.853,396.28,363.573,403.13,358.553,406.56z"/>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Cancel;
