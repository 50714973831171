import { useEffect,useState } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import buttonIcon from "../../../../../Media/Images/Icons/button.webp";
import imageIcon from "../../../../../Media/Images/Icons/image.webp";
import eyeIcon  from "../../../../../Media/Images/Icons/eye.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import ReservationPlaceResponziveSettings from "./ReservationPlaceResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { RESERVATION_PLACE } from "../../../Library/elements";
import { GET_LINK_CAN_BE_RESERVED_MEMBERSHIPS } from "../../../Queries/contentManager";
import { useQuery } from "@apollo/client";
import Loading from "../../../../../GlobalComponents/Loading";
import Select from "../../../../../GlobalComponents/Select";
import Error from "../../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../../GlobalFunctions/helper";
import RadioButton from "../../../../../GlobalComponents/RadioButton";
import UseEditReviews from "../../../Library/UseEditReviews";
import List from "../../../../../GlobalComponents/List/List";
import ChooseReviews from "../../../../Reviews/Components/ChooseReviews";
import reviewIcon from '../../../../../Media/Images/Icons/review.webp';
import cancelIcon from '../../../../../Media/Images/Icons/cancel.webp';
import SelectBoxColors from "../../../../../GlobalComponents/SelectBoxColors";

const ReservationPlaceSettings = () => {

    const [showAddReviews, SetShowAddReviews] = useState(false);
    const [reservationPlaces, SetReservationPlaces] = useState([]);
    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData,FillFormSelectedItemsWithObj,RemoveFormDataSelectedItems} = UseFormHandle(RESERVATION_PLACE.reservationPlace); 
    const {
        contentManagerLinkID,
        formData:cmFormData,
        showSettingsElement,
        contentManagerProjectID,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    const {
        headerData,
        content,
        loading:reviewLoading,
        error:reviewError,
        GetSelectedReviews,
        OnDragEnd
    } = UseEditReviews(cmFormData.selectedLang,formData.selectedReviews,SetFormData);

    useEffect(() => {

        const GetSelReviews = async () => {
            const settings = GetSettings(showSettingsElement.index,"reservationPlace");
            settings.selectedReviews = await GetSelectedReviews(settings.selectedReviews);
            SetCompleteFormData(settings);
        }

        if(showSettingsElement)
            GetSelReviews();

    },[showSettingsElement])

    useEffect(() => {

        var selectedReviews = [];
        for(let val of formData.selectedReviews)
        {
            selectedReviews.push(val.reviewID);
        }

        SetSettings(showSettingsElement.index,"selectedReviews",selectedReviews,"reservationPlace");

    },[formData.selectedReviews])

    const {data,loading,error} = useQuery(GET_LINK_CAN_BE_RESERVED_MEMBERSHIPS,{
        variables:{
            lang:cmFormData.userLang,
            linkID:contentManagerLinkID
        },
        fetchPolicy:"network-only",
        skip:(cmFormData.userLang == "" ? true : false)
    })

    useEffect(() => {

        if(data && formData.membershipID != 0)
        {
            if(data.AllLinkCanBeReservedMemberships)
            {
                for(let val of data.AllLinkCanBeReservedMemberships)
                {
                    if(val.membershipID == formData.membershipID)
                    {
                        SetReservationPlaces(val.reservationPlaces);
                        break;
                    }
                }
            }
        }

    },[formData.membershipID,data])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"reservationPlace");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"reservationPlace");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <ReservationPlaceResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <ReservationPlaceResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <ReservationPlaceResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <ReservationPlaceResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <ReservationPlaceResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <ReservationPlaceResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>   
            {error ?
                <Error text = {GetApolloErrorText(error)} />
            :
                (loading ?
                    <Loading />
                :
                    <>   
                        <div className="form-group">
                            <label>{t("Membership")}</label>
                            <Select
                                name="membershipID"
                                value={formData.membershipID}
                                OnChange = {(e) => SetS(e)}
                            >
                                <option value={0}>---{t("Choose")}---</option>
                                {data.AllLinkCanBeReservedMemberships && data.AllLinkCanBeReservedMemberships.map((item) => (
                                    <option
                                        key={item.membershipID}
                                        value={item.membershipID}
                                    >{item.name}</option>
                                ))}
                                
                            </Select>
                        </div>    

                        {formData.membershipID != 0 ?
                            <div className="form-group">
                                <label>{t("ReservationPlace")}</label>
                                <Select
                                    name="reservationPlaceID"
                                    value={formData.reservationPlaceID}
                                    OnChange = {(e) => SetS(e)}
                                >
                                    <option value={0}>---{t("Choose")}---</option>
                                    {reservationPlaces.map((item) => (
                                        <option
                                            key={item.reservationPlaceID}
                                            value={item.reservationPlaceID}
                                        >{item.reservationPlace.internalName}</option>
                                    ))}
                                    
                                </Select>
                            </div>    
                        :null} 

                        <div className="form-group">
                            <InputCard
                                className = "form-group"
                                title = {t("Reviews")}
                                icon = {reviewIcon}
                                noBodyPadding = {true}
                                headerChildren = {
                                    <Button 
                                        OnClick = {(e) => {
                                            e.stopPropagation();
                                            SetShowAddReviews(true)
                                        }}
                                        className="btn-primary narrow"
                                    >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>
                                }
                            >   
                                {error ? 
                                    <Error text={error} />
                                :
                                    <List 
                                        headerData = {headerData} 
                                        headerClassName = {"no-top-border smaller-padding"}
                                        rowClassname = {"smaller-padding"}
                                        contentData = {content}
                                        isSortable = {true}
                                        OnDragListRowEnd={(e)=> OnDragEnd(e)}
                                        loading = {loading}
                                        replaceDotsImg = {cancelIcon}
                                        optionsTitle = {""}
                                        optionsClassName = "smallest"
                                        ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedReviews","reviewID",[data.rowID])}
                                    />
                                }
                            
                            </InputCard>
                            {showAddReviews ? 
                                <ChooseReviews 
                                    projectID = {contentManagerProjectID}
                                    formData = {formData}
                                    SetShowAddReviews = {SetShowAddReviews}
                                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                />
                            :null}
                        </div>                  
                        
                        {formData.reservationPlaceID != 0 && formData.membershipID != 0 ?
                            <>
                                <InputCard
                                    title = {t("Display")}
                                    icon = {eyeIcon}
                                    className="form-group"
                                >
                                    <div className = "form-group">
                                        <label>{t("ShowName")}</label>
                                        <div className="radio-container">
                                            <RadioButton
                                                text={t("Yes")}
                                                name="showName"
                                                id="showName-1"
                                                value={1}
                                                checked = {formData.showName == 1 ? true : false}
                                                OnChange={(e) => SetS(e)}
                                            />
                                            <RadioButton
                                                text={t("No")}
                                                name="showName"
                                                id="showName-2"
                                                value={0}
                                                checked = {formData.showName == 0 ? true : false}
                                                OnChange={(e) => SetS(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className = "form-group">
                                        <label>{t("ShowImage")}</label>
                                        <div className="radio-container">
                                            <RadioButton
                                                text={t("Yes")}
                                                name="showImage"
                                                id="showImage-1"
                                                value={1}
                                                checked = {formData.showImage == 1 ? true : false}
                                                OnChange={(e) => SetS(e)}
                                            />
                                            <RadioButton
                                                text={t("No")}
                                                name="showImage"
                                                id="showImage-2"
                                                value={0}
                                                checked = {formData.showImage == 0 ? true : false}
                                                OnChange={(e) => SetS(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className = "form-group">
                                        <label>{t("ShowCalendar")}</label>
                                        <div className="radio-container">
                                            <RadioButton
                                                text={t("Yes")}
                                                name="showCalendar"
                                                id="showCalendar-1"
                                                value={1}
                                                checked = {formData.showCalendar == 1 ? true : false}
                                                OnChange={(e) => SetS(e)}
                                            />
                                            <RadioButton
                                                text={t("No")}
                                                name="showCalendar"
                                                id="showCalendar-2"
                                                value={0}
                                                checked = {formData.showCalendar == 0 ? true : false}
                                                OnChange={(e) => SetS(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className = "form-group">
                                        <label>{t("ShowReservationButton")}</label>
                                        <div className="radio-container">
                                            <RadioButton
                                                text={t("Yes")}
                                                name="showReservationButton"
                                                id="showReservationButton-1"
                                                value={1}
                                                checked = {formData.showReservationButton == 1 ? true : false}
                                                OnChange={(e) => SetS(e)}
                                            />
                                            <RadioButton
                                                text={t("No")}
                                                name="showReservationButton"
                                                id="showReservationButton-2"
                                                value={0}
                                                checked = {formData.showReservationButton == 0 ? true : false}
                                                OnChange={(e) => SetS(e)}
                                            />
                                        </div>
                                    </div>
                                        
                                </InputCard>

                                {formData.showImage == 1 ?
                                    <InputCard
                                        title = {t("Image")}
                                        icon = {imageIcon}
                                        className="form-group"
                                    >
                                        <div className="row">  
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>{t("Radius")}</label>
                                                    <Input 
                                                        type = "number"
                                                        name = "imageBorderRadius"
                                                        value = {formData.imageBorderRadius}
                                                        OnChange = {(e) => SetS(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </InputCard>
                                :null}

                                {formData.showReservationButton == 1 ?
                                    <InputCard
                                        title = {t("Button")}
                                        icon = {buttonIcon}
                                        className="form-group"
                                    >
                                        <div className="row">
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>{t("Color")}</label>
                                                    <Select
                                                        name = {"color"}
                                                        OnChange = {(e) => SetS(e)}
                                                        value = {formData.color}
                                                    >
                                                        <SelectBoxColors />
                                                        
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>{t("Size")}</label>
                                                    <Select
                                                        name = {"size"}
                                                        OnChange = {(e) => SetS(e)}
                                                        value = {formData.size}
                                                    >
                                                        <option value={""}>{t("Small")}</option>
                                                        <option value={"middle"}>{t("Middle")}</option>
                                                        <option value={"bigger"}>{t("Big")}</option>
                                                        
                                                    </Select>
                                                </div>
                                            </div>   
                                            
                                        </div>
                                    </InputCard>
                                :null}
                            </>
                        :null}

                        <div className="form-group">
                            <label>{t("ClassName")}</label>
                            <Input 
                                type = "text"
                                name = "className"
                                value = {formData.className}
                                OnChange = {(e) => SetS(e)}
                            />
                        </div>
                            
                        <div className="form-group">
                            <InputCard
                                title = {t("ResponziveData")}
                                icon = {responziveIcon}
                                tabsData = {responziveTabs}
                            />
                        </div>
                        
                        <div className="cm-remove-section-elm">
                            <Button 
                                className="btn-brown"
                                OnClick = {() => RemoveElm()}
                            >
                                {t("RemoveElement")}
                            </Button>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default ReservationPlaceSettings;