import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import HeadlineResponziveSettings from "./HeadlineResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import RadioButton from "../../../../../GlobalComponents/RadioButton";
import { HEADLINE } from "../../../Library/elements";
import Select from "../../../../../GlobalComponents/Select";

const HeadlineSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(HEADLINE.headline); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"headline");
            SetCompleteFormData(settings);
        }

    },[showSettingsElement])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"headline");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"headline");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <HeadlineResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <HeadlineResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <HeadlineResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <HeadlineResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <HeadlineResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <HeadlineResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="row">
                <div className="col-100">
                    <div className="form-group">
                                                    
                        <label>{t("ShowSubText")}</label>
                        <div className="radio-container">
                            <RadioButton
                                text="Ano"
                                name="hasSubText"
                                id="hasSubText-1"
                                value={1}
                                checked = {formData.hasSubText === 1 ? true : false}
                                OnChange={(e) => SetS(e)}
                            />
                            <RadioButton
                                text="Ne"
                                name="hasSubText"
                                id="hasSubText-2"
                                value={0}
                                checked = {formData.hasSubText === 0 ? true : false}
                                OnChange={(e) => SetS(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Align")}</label>
                        <Select
                            name = {"align"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.align}
                        >
                            <option value={"start"}>{t("Left")}</option>
                            <option value={"center"}>{t("Center")}</option>
                            <option value={"end"}>{t("Right")}</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Numbering")}</label>
                        <Input 
                            type = "text"
                            name = "numbering"
                            value = {formData.numbering}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default HeadlineSettings;