import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { UseAuth } from '../Modules/Auth/Library/UseAuth';

const NoMatch = () => {
		
	const {Logout} = UseAuth();
	const navigate =  useNavigate();

	useEffect(() => {
		Logout();
		navigate("/");
	},[])
	
	
	return (
		<div></div>
	);
	
}


export default NoMatch;