const Invoice = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <g>
                            <path 
                                style={{"fill":primaryColor}} 
                                d="M428.212,0H83.788c-22.607,0-41,18.393-41,41v430c0,22.607,18.393,41,41,41h344.424c22.607,0,41-18.393,41-41V41
                                C469.212,18.393,450.82,0,428.212,0z M447.212,471c0,10.477-8.523,19-19,19H83.788c-10.477,0-19-8.523-19-19V41
                                c0-10.477,8.523-19,19-19h344.424c10.477,0,19,8.523,19,19V471z"/>
                        </g>
                        <g>
                            <path 
                                style={{"fill":primaryColor}} 
                                d="M381.924,152.125H130.076c-17.093,0-31,13.907-31,31v243.077c0,17.093,13.907,31,31,31h251.847c17.093,0,31-13.907,31-31
                                V183.125C412.924,166.032,399.017,152.125,381.924,152.125z M390.924,183.125v25.129H195.886v-34.129h186.037
                                C386.886,174.125,390.924,178.163,390.924,183.125z M121.076,183.125c0-4.962,4.038-9,9-9h43.81v34.129h-52.81V183.125z
                                M121.076,426.202V230.254h52.81v204.947h-43.81C125.114,435.202,121.076,431.164,121.076,426.202z M390.924,426.202
                                c0,4.962-4.038,9-9,9H195.886V230.254h195.037V426.202z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path 
                                style={{"fill":secondaryColor}} 
                                d="M110.076,76.798h157.375c6.075,0,11-4.925,11-11s-4.925-11-11-11H110.076c-6.075,0-11,4.925-11,11
                                S104.001,76.798,110.076,76.798z"/>
                        </g>
                        <g>
                            <path 
                                style={{"fill":secondaryColor}} 
                                d="M199.068,97.983h-88.992c-6.075,0-11,4.925-11,11s4.925,11,11,11h88.992c6.075,0,11-4.925,11-11
                                S205.143,97.983,199.068,97.983z"/>
                        </g>
                        <g>
                            <path 
                                style={{"fill":secondaryColor}} 
                                d="M306.135,321.365c-7.429-2.626-16.499-6.082-20.729-9.526c-0.854-0.695-1.214-2.424-0.896-4.303
                                c0.414-2.449,2.058-5.502,5.429-6.727c7.375-2.68,12.606,1.653,13.18,2.156c4.407,3.519,10.834,2.788,14.339-1.631l1.344-1.695
                                c3.762-4.744,2.559-11.665-2.531-14.944c-3.639-2.344-7.74-4.212-12.102-5.371v-1.553c0-6.075-4.925-11-11-11s-11,4.925-11,11
                                v2.463c-10.116,3.783-17.522,12.81-19.351,23.635c-1.669,9.879,1.582,19.235,8.698,25.029c6.418,5.226,15.55,9.059,27.288,13.208
                                c7.232,2.556,7.391,8.418,6.842,11.685c-0.667,3.962-3.946,10.683-12.013,10.737c-6.887,0.067-9.895-0.924-16.83-5.46
                                c-5.128-3.353-12.018-1.875-15.312,3.316c-3.262,5.14-1.498,11.998,3.607,15.316c5.877,3.82,10.89,6.411,17.072,7.753v1.223
                                c0,6.075,4.925,11,11,11s11-4.925,11-11v-1.799c11.937-3.841,20.926-14.081,23.172-27.438
                                C330.052,341.304,321.531,326.807,306.135,321.365z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Invoice;