import React from 'react';

const Info = ({text,className}) => {
    return (
    	<div 
			className={"alert alert-info text-center" + (className ? " " + className : "")}
			dangerouslySetInnerHTML={{__html:text}}
		>	
    	</div>
    );
}

export default Info;