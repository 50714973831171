import arrows from '../Media/Images/Icons/arrows.webp';
import global from '../Media/Images/Icons/global.webp';
import arrow from '../Media/Images/Icons/arrow.svg';
import { NavLink } from 'react-router-dom';
import { UseAuth } from '../Modules/Auth/Library/UseAuth';
import UseHeader from '../GlobalHooks/UseHeader';
import { useTranslation } from 'react-i18next';
import { SERVER_URL,PROJECT_IMAGE_URL } from '../Config';
import { useParams } from 'react-router-dom';
import { GetProjectIDFromParam,GetModuleLink } from '../GlobalFunctions/header';
import Loading from '../GlobalComponents/Loading';
import { IsServerDev } from '../Config';
import AllIcons from '../GlobalComponents/Icons/AllIcons';

const Header = ({isShrink,SetIsShrink}) => {

    const params = useParams();
    const projectID = GetProjectIDFromParam(params);

    const {t} = useTranslation();

    const {
        Logout,
        GetAdminUserFirstModule
    } = UseAuth();

    const {
        headerData,
        loading,
        selectedMenu,
        selectedParentID,
        SetSelectedParentID,
        showProjects,
        selectedProject,
        ShowSubmenu,
        Shrink,
        SetShowProjects
    } = UseHeader(SetIsShrink,projectID);

    const firstGlobalModule = GetAdminUserFirstModule("global");
    const firstProjectModule = GetAdminUserFirstModule("project");
    const isServerDev = IsServerDev();

    return(
        <>
            {isServerDev ?
                <div className="alert alert-danger no-margin justify-content-center huge no-border-radius">
                    {t("ThisIsDevelopmentVersion")}
                </div>
            :null}
            <header className = {(isShrink ? "shrink" : "") + (isServerDev ? " dev" : "")}>
                
                <img 
                    onClick={() => Shrink()}
                    className="arrows" 
                    src={arrows} 
                    alt="" 
                />
                <nav>
                    <ul>
                        <li className="choose-project">
                            {loading ?
                                <Loading className="small" />
                            :
                                <>
                                    <div 
                                        onClick = {() => SetShowProjects(!showProjects)}
                                        className="project"
                                    >
                                        {selectedProject ?
                                            <>
                                                {selectedProject.favicon ?
                                                    <img src={SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + selectedProject.favicon} />
                                                :null}
                                                {selectedProject.name}
                                            </>
                                        :
                                            <>
                                                <img src={global} />
                                                {t("GlobalData")}
                                            </>
                                        }
                                        <img className="arrow" src={arrow} />
                                    </div>
                                    {showProjects ?
                                        <>
                                            <ul>
                                                {firstGlobalModule ?
                                                    <li>
                                                        <NavLink 
                                                            onClick = {() => SetShowProjects(false)}
                                                            to={firstGlobalModule.module.link}
                                                        >
                                                            <img src={global} />
                                                            {t("GlobalData")}
                                                        </NavLink>
                                                    </li>
                                                :null}
                                                {headerData.AllProjects.map((item) => {

                                                    if(item.adminRoleProject && firstProjectModule)
                                                        return(
                                                            <li key={item.projectID}>
                                                                <NavLink 
                                                                    onClick = {() => SetShowProjects(false)}
                                                                    to={"/" + item.projectID + "-" + item.niceName + firstProjectModule.module.link}
                                                                >
                                                                    {item.favicon ?
                                                                        <img src={SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + item.favicon} />
                                                                    :null}
                                                                    {item.name}
                                                                </NavLink>
                                                            </li>
                                                        )
                                                    else
                                                        return "";
                                                })}
                                                
                                            </ul>
                                            <div 
                                                onClick = {() => SetShowProjects(false)}
                                                className="universal-overlay"
                                            ></div>
                                        </>
                                    :null}
                                </>
                            }
                        </li>
                        
                        <li>
                            <NavLink to={"/settings/change-password"}>
                                <AllIcons name={"user"} />
                            </NavLink>
                        </li>
                        <li onClick = {() => Logout()}>
                            <AllIcons name={"logout"} />
                        </li>
                    </ul>
                </nav>

            </header>
            <div className={"sidebar" + (isShrink ? " shrink" : "") + (isServerDev ? " dev" : "")}>
                {loading ?
                    <Loading />
                :
                    <>
                        <div className="logo">
                            {isShrink ?
                                (selectedProject ?
                                    (selectedProject.favicon ?
                                        <img src={SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + selectedProject.favicon} />
                                    :null)
                                :
                                    <img src={global} />
                                )
                            :
                                (selectedProject ?
                                    (selectedProject.logo ?
                                        <img src={SERVER_URL + "/" + PROJECT_IMAGE_URL + "/mala_" + selectedProject.logo} />
                                    :null)
                                :
                                    <img src={global} />
                                )
                            }
                        </div>
                        <nav>
                            <ul>
                                {headerData && headerData.AllModulesBy.map((item) => {

                                    var className= "";
                                    if(selectedMenu === item.moduleID)className += " selected";
                                    if(selectedParentID === item.moduleID)className += " active";

                                    const link = GetModuleLink(item,selectedProject);

                                    if(item.link && (item.activeByDefault || item.adminRoleModule))
                                    {
                                        return(
                                            <li key={item.moduleID} className={className}>
                                                <NavLink className="main" to={link} onClick={(e) => ShowSubmenu(e,item.moduleID,link)}>
                                                    {headerData && headerData.NotApprovedProductReviewsCount && item.isEshop ?
                                                        <span className="badge">{headerData.NotApprovedProductReviewsCount}</span>
                                                    :null}
                                                    <AllIcons name={item.icon} />
                                                    <span>{t(item.label)}</span>
                                                    {item.subModule && item.subModule.length > 0 ?
                                                        <img className="arrow" src={arrow} />
                                                    :null}
                                                </NavLink>
                                                {item.subModule && item.subModule.length > 0 ?
                                                    <ul>
                                                        {item.subModule.map((it,ind) => {
                                                            
                                                            if(it.link && (it.activeByDefault || it.adminRoleModule))
                                                            {
                                                                const l = GetModuleLink(it,selectedProject);

                                                                return(
                                                                    <li key={ind}>
                                                                        {headerData && headerData.NotApprovedProductReviewsCount && it.isProductReview ?
                                                                            <span className="badge">{headerData.NotApprovedProductReviewsCount}</span>
                                                                        :null}
                                                                        <NavLink to={l} onClick={() => SetSelectedParentID(null)}>
                                                                            {t(it.label)}
                                                                        </NavLink>
                                                                    </li>
                                                                )
                                                            }
                                                            else
                                                                return "";
                                                        })}
                                                    </ul>
                                                :null}
                                            </li>
                                        )
                                    }
                                    else
                                        return ""
                                })}

                                <li className="logout">
                                    <NavLink onClick = {() => Logout()} className="main" to={"/"} >
                                        <AllIcons name={"logout"} />
                                        <span>{t("Logout")}</span>
                                    </NavLink>
                                </li>

                            </ul>
                        </nav>
                    </>
                }
            </div>
        </>
    )
}

export default Header;