import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditAdminUser from '../Library/UseAddEditAdminUser';
import UseGetAdminUser from '../Library/UseGetAdminUser';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Input from '../../../GlobalComponents/Input';
import Select from '../../../GlobalComponents/Select';
import InputCard from '../../../GlobalComponents/InputCard';
import passworIcon from '../../../Media/Images/Icons/password_input.svg'

Modal.setAppElement('#root');

const AddEditAdminUser = ({selectedAdminUserID,SetSelectedAdminUserID,OnAddEditSuccess}) => {

    const {t} = useTranslation();
    
    const {
        loading:getLoading,
        error,
        allAdminRoles,
        allLanguages
    } = UseGetAdminUser(selectedAdminUserID,(d) => {
        InitFormData(d)    
    });

    const {
        loading,
        formData,
        AddEditAdminUser,
        InitFormData,
        SetFormData,
    } = UseAddEditAdminUser(selectedAdminUserID,(d) => OnAddEditSuccess(d));

    var err = "";
    if(error)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedAdminUserID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedAdminUserID === 0 ? t("AddAdminUser") : t("EditAdminUser"))}
                OnClose ={() => SetSelectedAdminUserID(null)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                <div className="row">
                                    <div className="col-33">
                                        <div className="form-group">
                                            <label>*{t("FirstName")}</label>
                                            <Input
                                                name="name"
                                                value={formData.name}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-33">
                                        <div className="form-group">
                                            <label>{t("Surname")}</label>
                                            <Input
                                                name="surname"
                                                value={formData.surname}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-33">
                                        <div className="form-group">
                                            <label>*{t("Role")}</label>
                                            <Select
                                                name="adminRoleID"
                                                value={formData.adminRoleID}
                                                OnChange={(e) => SetFormData(e)}
                                            >
                                                <option>{"- - -" + t("Choose") + "- - -"}</option>
                                                {allAdminRoles.map((item) => (
                                                    <option
                                                        key={item.adminRoleID}
                                                        value={item.adminRoleID}
                                                    >{item.name}</option> 
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="form-group">
                                            <label>*{t("Email")}</label>
                                            <Input
                                                name="email"
                                                value={formData.email}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="form-group">
                                            <label>*{t("Lang")}</label>
                                            <Select
                                                name="lang"
                                                value={formData.lang}
                                                OnChange={(e) => SetFormData(e)}
                                            >
                                                <option>{"- - -" + t("Choose") + "- - -"}</option>
                                                {allLanguages.map((item) => (
                                                    <option
                                                        key={item.languageID}
                                                        value={item.suffix}
                                                    >{item.suffix}</option> 
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                {selectedAdminUserID == 0 ?
                                    <InputCard 
                                        title = {t("Password")}
                                        icon={passworIcon}
                                    >
                                        <div className="row">
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>*{t("Password")}</label>
                                                    <Input
                                                        name="password"
                                                        type="password"
                                                        value={formData.password}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>*{t("RepeatPassword")}</label>
                                                    <Input
                                                        name="repeatPassword"
                                                        type="password"
                                                        value={formData.repeatPassword}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </InputCard>
                                :null}
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditAdminUser()}
                >
                    {(selectedAdminUserID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditAdminUser;