import { useState } from 'react';
import { useTranslation } from "react-i18next";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import List from '../../../GlobalComponents/List/List';
import AddEditArticle from './AddEditArticle';
import { useApolloClient } from '@apollo/client';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import UseGetAllArticles from '../Library/UseGetAllArticles';
import UseDeleteArticles from '../Library/UseDeleteArticles';
import ContentFilterSidebar from '../../../GlobalComponents/ContentFilterSidebar';
import ArticleFilter from './ArticleFilter';
import ContentHeader from '../../../GlobalComponents/ContentHeader';
import { UseAuth } from '../../Auth/Library/UseAuth';

const Articles = () => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const {user} = UseAuth();

    const [showFilterSidebar, SetShowFilterSidebar] = useState(false);
    const [selectedArticleID, SetSelectedArticleID] = useState(null);

    const {
        content,
        variables,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedArticleIDs,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        FilterArticles
    } = UseGetAllArticles(user.lang);

    
    const {
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteArticles
    } = UseDeleteArticles(selectedArticleIDs,(d) => UpdateList(d));

    const UpdateList = async () => {

        SetSelectedArticleID(null);
        await client.refetchQueries({
            include: ["GetAllArticles"],
        });
    }

    return(
        <>
            {error ? 
                <Error className="no-margin" text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ArticleList")}
                        //OnFilterClick = {() => SetShowFilterSidebar(true)}
                        OnAddButtonClick={() => SetSelectedArticleID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                        OnSearch = {(filterData) => FilterArticles(filterData)}
                    />
                    
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        options = {{
                            OnEditClick: (articleID) => SetSelectedArticleID(articleID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    /> 

                    <ContentFilterSidebar
                        isOpen = {showFilterSidebar}
                        OnClose = {() => SetShowFilterSidebar(false)}
                    >
                        <ArticleFilter
                            OnFilter = {(filterData) => FilterArticles(filterData)}
                            variables = {variables}
                        />
                    </ContentFilterSidebar>
                    

                </>
            }

            {selectedArticleID || selectedArticleID == 0 ?
                <AddEditArticle
                    selectedArticleID = {selectedArticleID}
                    SetSelectedArticleID = {SetSelectedArticleID}
                    OnAddEditSuccess = {() => UpdateList()}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteEmails")} 
                    CallBack={DeleteArticles} 
                />
            :null}
        </>
    )
}

export default Articles;