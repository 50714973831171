import { useQuery } from "@apollo/client";
import { GET_CAMPAIGN_EMAIL } from "../Queries/campaignEmail";
import { useState } from "react";
import { CompleteLangsArray, GetMainLanguage } from "../../../GlobalFunctions/helper";

const UseGetCampaignEmail = (selectedCampaignEmailID,projectID,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_CAMPAIGN_EMAIL,{
        variables:{
            campaignEmailID:selectedCampaignEmailID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                subject:"", 
                preheader:"", 
                text:""
            }

            if(selectedCampaignEmailID != 0)
            {
                let langs = CompleteLangsArray(d.CampaignEmailWithLangs.langs,d.AllLanguageMutations,emptyLang);
                
                CallBack({
                    projectID,
                    testEmail:            d.CampaignEmailWithLangs.testEmail,
                    hours:                d.CampaignEmailWithLangs.hours,
                    langs:                langs,
                    generateTranslations: {
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    projectID,
                    langs:langs,
                });
            }
            
            SetAllLanguageMutations(d.AllLanguageMutations);
            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        mainLang
    }

}

export default UseGetCampaignEmail;