import { useMutation } from "@apollo/client";
import { COPY_CAMPAIGN } from "../Queries/campaign";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseCopyCampaign = (OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const [CopyCampaignMutation] = useMutation(COPY_CAMPAIGN,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.CopyCampaign); 

            const notifyText = t("NowIHaveTwin");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    
    const CopyCampaign = (campaignID) => {

        CopyCampaignMutation({
            variables:{
                campaignID
            }
        })
    } 

    return {
        CopyCampaign
    }

}

export default UseCopyCampaign;