import { useState } from 'react';
import Button from "../../../GlobalComponents/Button";
import NoItems from "../../../GlobalComponents/NoItems";
import InputCard from "../../../GlobalComponents/InputCard";
import emailIcon from '../../../Media/Images/Icons/email.webp';
import { useTranslation } from "react-i18next";
import UseGetAllCampaignEmails from "../Library/UseGetAllCampaignEmails";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import List from '../../../GlobalComponents/List/List';
import AddEditCampaignEmail from './AddEditCampaignEmail';
import { useApolloClient } from '@apollo/client';
import { UseAuth } from '../../Auth/Library/UseAuth';
import UseDeleteCampaignEmails from '../Library/UseDeleteCampaignEmails';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import UseSortList from '../../../GlobalHooks/UseSortList';
import { UPDATE_CAMPAIGN_EMAIL_PRIORITY,GET_CAMPAIGN_EMAILS } from '../Queries/campaignEmail';

const CampaignEmails = ({
    selectedCampaignID,
    projectID
}) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const {user} = UseAuth();

    const [selectedCampaignEmailID, SetSelectedCampaignEmailID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedEmailIDs,
        SelectRow,
        SelectAll
    } = UseGetAllCampaignEmails(user.lang,selectedCampaignID);

    const {
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteCampaignEmails
    } = UseDeleteCampaignEmails(selectedEmailIDs,selectedCampaignID,(d) => UpdateList(d));

    const {OnDragEnd} = UseSortList(
        client,
        UPDATE_CAMPAIGN_EMAIL_PRIORITY,
        GET_CAMPAIGN_EMAILS,
        "AllCampaignEmails",
        "campaignEmailID",
        null,
        null,
        {campaignID:selectedCampaignID},
        {lang:user.lang,campaignID:selectedCampaignID},
    );

    const UpdateList = async () => {

        SetSelectedCampaignEmailID(null);
        await client.refetchQueries({
            include: ["AllCampaignEmails"],
        });
    }

    return(
        <div>
            {selectedCampaignID && selectedCampaignID != 0 ?

                <>
                    {error ? 
                        <Error className="no-margin" text={GetApolloErrorText(error)} />
                    :
                        <InputCard
                            title = {t("Emails")}
                            icon = {emailIcon}
                            noBodyPadding={true}
                            headerChildren = {
                                <>
                                    <Button
                                        className="btn-primary narrow"
                                        OnClick = {() => SetSelectedCampaignEmailID(0)}
                                    >
                                        {t("Add")}     
                                    </Button>
                                
                                    <Button
                                        className="btn-brown narrow"
                                        OnClick = {() => ShowDeleteNotification(true)}
                                    >
                                        {t("DeleteSelected")}     
                                    </Button>
                                </>
                            }
                        > 
                            <List 
                                headerData = {headerData} 
                                contentData = {content}
                                isSortable = {true}
                                loading = {loading}
                                OnSelectAll = {(e) => SelectAll(e)}
                                OnDragListRowEnd = {(e)=> OnDragEnd(e)}
                                options = {{
                                    OnEditClick: (campaignEmailID) => SetSelectedCampaignEmailID(campaignEmailID),
                                    OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                                }}
                            /> 

                        </InputCard>
                    }

                    {selectedCampaignEmailID || selectedCampaignEmailID == 0 ?
                        <AddEditCampaignEmail
                            campaignID = {selectedCampaignID}
                            projectID = {projectID}
                            selectedCampaignEmailID = {selectedCampaignEmailID}
                            SetSelectedCampaignEmailID = {SetSelectedCampaignEmailID}
                            OnAddEditSuccess = {() => UpdateList()}
                        />
                    :null}

                    {showDeleteNotification ?
                        <ModalNotification 
                            yesNo={true} 
                            text={t("DoYouReallyWantToDeleteEmails")} 
                            CallBack={DeleteCampaignEmails} 
                        />
                    :null}
                </>
            :
                <NoItems
                    className = {"no-margin justify-content-center"}
                    text={t("ToAddEmailsSaveBasicInformationsFirst")} 
                />   
            }
        </div>
    )
}

export default CampaignEmails;