const Care = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M175.303,325.347l-47.65-39.921c-6.771-6.165-15.567-9.362-24.754-9c-9.303,0.361-17.892,4.307-24.185,11.109
                            c-1.521,1.646-2.854,3.398-3.997,5.234l-3.487-89.944c-0.604-15.565-7.151-29.456-18.437-39.112
                            c-11.025-9.435-26.01-14.278-42.219-13.656c-5.905,0.229-10.573,5.083-10.573,10.992v168.846c0,9.725,3.509,19.662,9.878,27.98
                            l75.46,98.571v44.555c0,6.075,4.925,11,11,11h114.662c6.075,0,11-4.925,11-11v-80.021
                            C222.001,364.469,201.392,347.205,175.303,325.347z M200.001,490.002h-92.662v-37.282c0-2.417-0.796-4.768-2.266-6.687
                            L27.346,344.502c-3.397-4.437-5.346-9.761-5.346-14.606V172.801c6.368,1.22,12.031,3.813,16.489,7.628
                            c6.667,5.706,10.387,13.745,10.755,23.249l4.006,103.299c0.113,2.915,1.379,5.665,3.52,7.646l23.784,22.017
                            c0.022,0.021,0.044,0.041,0.066,0.062l0.038,0.035l43.258,40.043c4.459,4.127,11.419,3.858,15.545-0.6
                            c4.127-4.458,3.858-11.418-0.6-15.545l-43.33-40.109c-5.144-4.797-5.447-12.885-0.668-18.051c2.302-2.488,5.458-3.933,8.889-4.065
                            c3.439-0.13,6.688,1.061,9.174,3.362c0.134,0.123,0.27,0.243,0.408,0.359l47.839,40.079
                            c25.036,20.975,38.827,32.528,38.827,78.771V490.002z"/>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M501.428,150.057c-16.2-0.62-31.192,4.221-42.219,13.656c-11.285,9.656-17.832,23.547-18.437,39.112l-3.487,89.944
                            c-1.144-1.836-2.476-3.589-3.997-5.233c-6.292-6.804-14.881-10.749-24.185-11.11c-9.175-0.371-17.982,2.836-24.754,9
                            l-47.65,39.921c-26.09,21.857-46.698,39.122-46.698,95.634v80.021c0,6.075,4.925,11,11,11h114.662c6.075,0,11-4.925,11-11v-44.555
                            l75.459-98.57c6.37-8.319,9.879-18.257,9.879-27.981V161.049C512.001,155.14,507.333,150.287,501.428,150.057z M490.001,329.895
                            c0,4.846-1.948,10.17-5.347,14.607l-77.726,101.53c-1.47,1.919-2.266,4.27-2.266,6.687v37.282h-92.662v-69.021
                            c0-46.242,13.791-57.796,38.827-78.771l47.839-40.079c0.139-0.116,0.274-0.236,0.408-0.359c2.486-2.302,5.738-3.492,9.175-3.362
                            c3.43,0.133,6.586,1.576,8.888,4.065c4.763,5.149,4.478,13.2-0.616,18.003l-43.381,40.157c-4.458,4.127-4.727,11.087-0.6,15.545
                            s11.087,4.727,15.545,0.6l43.225-40.013c0.024-0.022,0.048-0.044,0.071-0.066c0.042-0.038,0.084-0.077,0.124-0.115l23.726-21.962
                            c2.141-1.981,3.406-4.731,3.52-7.646l4.006-103.298c0.368-9.505,4.088-17.544,10.755-23.25c4.458-3.814,10.12-6.409,16.489-7.628
                            V329.895z"/>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M408.398,189.313c19.415-33.63,27.107-67.678,22.238-98.452c-4.354-27.515-18.748-51.873-40.531-68.589
                            c-20.035-15.373-44.83-23.253-69.83-22.173c-23.629,1.016-45.655,9.755-64.274,25.402C237.382,9.855,215.355,1.115,191.726,0.099
                            c-25.008-1.088-49.794,6.8-69.829,22.173c-21.784,16.716-36.179,41.074-40.532,68.589c-4.869,30.775,2.821,64.82,22.238,98.452
                            c16.826,29.144,42.947,59.62,73.552,85.816c27.829,23.82,56.24,41.202,75.999,46.497c0.932,0.25,1.89,0.375,2.847,0.375
                            s1.915-0.125,2.848-0.375c19.758-5.295,48.169-22.677,75.998-46.497C365.452,248.933,391.573,218.457,408.398,189.313z
                                M256.001,299.524c-32.222-10.58-99.25-62.157-133.345-121.211C84.634,112.456,104.252,63.543,135.29,39.726
                            c14.015-10.754,32.638-17.83,52.349-17.83c20.326,0,41.809,7.525,60.584,26.301c4.297,4.295,11.26,4.295,15.557,0
                            c36.98-36.98,84.464-30.315,112.934-8.471c31.037,23.816,50.655,72.73,12.633,138.587
                            C355.252,237.367,288.223,288.944,256.001,299.524z"/>
                    </g>
                    <path 
                        style={{"fill":secondaryColor}} 
                        d="M311.001,127.322h-21.319v-21.32c0-6.075-4.925-11-11-11h-45.36c-6.075,0-11,4.925-11,11v21.32
                    h-21.32c-6.075,0-11,4.925-11,11v45.36c0,6.075,4.925,11,11,11h21.32v21.32c0,6.075,4.925,11,11,11h45.36c6.075,0,11-4.925,11-11
                    v-21.32h21.319c6.075,0,11-4.925,11-11v-45.36C322.001,132.247,317.076,127.322,311.001,127.322z M300.001,172.682h-21.319
                    c-6.075,0-11,4.925-11,11v21.32h-23.36v-21.32c0-6.075-4.925-11-11-11h-21.32v-23.36h21.32c6.075,0,11-4.925,11-11v-21.32h23.36
                    v21.32c0,6.075,4.925,11,11,11h21.319V172.682z"/>
                </g>
                
            </svg>
        </>
    )
}

export default Care;