const Copy = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M411.167,65.334H389.5V43.667C389.5,19.589,369.911,0,345.833,0H166.167c-2.917,0-5.715,1.159-7.778,3.222l-98,98   c-2.063,2.063-3.222,4.861-3.222,7.778v294c0,24.078,19.589,43.667,43.667,43.667H122.5v21.667c0,11.67,4.545,22.637,12.788,30.869   c8.243,8.253,19.208,12.798,30.878,12.798h245c24.078,0,43.667-19.589,43.667-43.667V109   C454.833,84.922,435.245,65.334,411.167,65.334z M170.723,22h0.777v70.666c0,11.947-9.72,21.667-21.667,21.667H79.167v-0.777   L170.723,22z M79.167,403V136.333h70.667c24.078,0,43.667-19.589,43.667-43.667V22h152.333c11.947,0,21.667,9.72,21.667,21.667V403   c0,11.947-9.72,21.667-21.667,21.667h-245C88.886,424.667,79.167,414.947,79.167,403z M432.833,468.333   c0,11.947-9.72,21.667-21.667,21.667h-245c-5.788,0-11.226-2.253-15.322-6.355c-4.091-4.085-6.344-9.523-6.344-15.311v-21.667   h201.333c24.078,0,43.667-19.589,43.667-43.667V87.334h21.667c11.947,0,21.667,9.72,21.667,21.666V468.333z"/>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M296.833,218h-147c-6.075,0-11-4.925-11-11s4.925-11,11-11h147c6.075,0,11,4.925,11,11    S302.909,218,296.833,218z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M296.833,283.333h-147c-6.075,0-11-4.925-11-11s4.925-11,11-11h147c6.075,0,11,4.925,11,11    S302.909,283.333,296.833,283.333z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M231.5,348.667h-81.667c-6.075,0-11-4.925-11-11s4.925-11,11-11H231.5c6.075,0,11,4.925,11,11    S237.575,348.667,231.5,348.667z"/>
                    </g>
                </g>                
            </svg>
        </>
    )
}

export default Copy;