import { useQuery} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,CUSTOMER_IMAGE_URL } from '../../../Config';
import { GET_ALL_RESERVATIONS } from '../Queries/reservations';
import { GetDateFormat } from '../../../GlobalFunctions/helper';
import dayjs from 'dayjs';
import Status from '../../../GlobalComponents/Status';

const UseGetAllReservations = (lang) => {

    const [variables,SetVariables] = useState({
        searchText:"",
        limit:40,
        offset:0,
        lang
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedReservationIDs,SetSelectedReservationIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_ALL_RESERVATIONS,{
        variables,
        fetchPolicy:"network-only"
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllReservations);
            SetContent(contentData);
        }

    },[data])

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    var headerData = [
        
        {
            value: t("FullName"),
            className:"flex-1 "
        },
        {
            value: t("Email"),
            className:"flex-1 bigger"
        },
        {
            value: t("Membership"),
            className:"static text-center"
        },
        {
            value: t("ReservationDate"),
            className:"static text-center"
        },
        {
            value: t("Status"),
            className:"static text-center"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            const rDate = val.date.split(" ");

            var text = val.email;
            if(val.customer.name || val.customer.surname)
                text = val.customer.name + " " + val.customer.surname;

            var cData = {
                data:[
                    {
                        elm: "lis",
                        backgroundColor: val.customer.backgroundColor,
                        text: text,
                        photo: val.customer.photo ? SERVER_URL + "/" + CUSTOMER_IMAGE_URL + "/mala_" + val.customer.photo : "",
                        className:"no-padding-right flex-1 "
                    },
                    {
                        value:val.customer.email,
                        className:"flex-1 bigger"
                    },
                    {
                        value:<> 
                            <div>{(val.membership ? val.membership.name : "")}</div>
                            <div>{(val.reservationPlace ? val.reservationPlace.internalName : "")}</div>
                        </>,
                        className:"static text-center"
                    },
                    {
                        value:<>
                            <div className="text-center">{GetDateFormat(rDate[0],lang)}</div>
                            <div className="text-center">{dayjs(val.date).format("HH:mm")}</div>
                        </>,
                        className:"static text-center"
                    },
                    {
                        value: <Status status = {val.reservationStatus.status} />,
                        className:"static text-center"
                    }

                ],
            }

            cData.showConfirmClick    = (val.reservationStatusID == 1 ? false : true)
            cData.rowID               = val.reservationID;
            cData.reservationStatusID = val.reservationStatusID;
            cData.selected            = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllReservations: [...prev.AllReservations, ...fetchMoreResult.AllReservations]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    const FilterReservations = (filterData) => {

        var newVariables = {...variables};
        var updated = false;
        
        if(filterData.searchText != "" || (filterData.searchText == "" && newVariables.searchText != ""))
        {
            newVariables.searchText = filterData.searchText;
            updated = true;
        }

        if(updated)
            SetVariables(newVariables)

    }

    const SelectRow = (e,reservationID) => {

        var checked = e.target.checked;
        var newSelectedReservationIDs = [...selectedReservationIDs];
        const newList = SelectReservationID(content,reservationID,checked,newSelectedReservationIDs)

        SetContent(newList);
        SetSelectedReservationIDs(newSelectedReservationIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedReservationIDs = [];
        const newList = SelectReservationID(content,"all",checked,newSelectedReservationIDs)

        SetSelectedReservationIDs(newSelectedReservationIDs);
        SetContent(newList);
    }

    const SelectReservationID = (list,reservationID,checked,selectedReservationIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(reservationID == newList[i].rowID || reservationID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedReservationIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedReservationIDs.length; j++)
                    {
                        if(selectedReservationIDs[j] == newList[i].rowID)
                            selectedReservationIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectReservationID(newList[i].subData,reservationID,checked,selectedReservationIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        SetSelectedReservationIDs([]);
        refetch();
    }

    return{
        content,
        totalContentLength: data && data.AllReservationsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedReservationIDs,
        SelectRow,
        SelectAll,
        FetchMore,
        GoToPage,
        FilterReservations,
        UpdateList
    }

}

export default UseGetAllReservations;