import Input from "../../../GlobalComponents/Input";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import Loading from "../../../GlobalComponents/Loading";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import Error from "../../../GlobalComponents/Error";
import UseEditSettings from "../Library/TranslationSettings/UseEditSettings";
import UseGetSettings from "../Library/TranslationSettings/UseGetSettings";
import RadioButton from "../../../GlobalComponents/RadioButton";
import { UseSettings } from "../../../AdminProvider";

const TranslationSettings = () => {

    const {t} = useTranslation();
    const {RefetchSettings} = UseSettings();

    const {
        formData,
        loading:editLoading,
        InitFormData,
        SetFormData,
        EditWebSettings
    } = UseEditSettings(() => {
        RefetchSettings();
    });

    const {loading,error} = UseGetSettings((d) => {
        InitFormData(d);
    });

    return(
        <>
            <ContentHeader
                title = {t("Emails")}
                OnSaveButtonClick = {() => EditWebSettings()}
            />
            <ContentBody>

                {error ?
                    <Error text = {GetApolloErrorText(error)} />
                :
                    (loading || editLoading ?
                        <Loading />
                    :
                        <>
                            <div className="row">
                                
                                <div className="col-50">
                                    <label>{t("UseThirdPartyForTransaltion")}</label>
                                    <div className="form-group">

                                        <div className="radio-container">
                                            <RadioButton
                                                text={t("Yes")}
                                                name="useThirdPartyForTransaltion"
                                                id="useThirdPartyForTransaltion-1"
                                                value={1}
                                                checked = {formData.useThirdPartyForTransaltion == 1 ? true : false}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                            <RadioButton
                                                text={t("No")}
                                                name="useThirdPartyForTransaltion"
                                                id="useThirdPartyForTransaltion-2"
                                                value={0}
                                                checked = {formData.useThirdPartyForTransaltion == 0 ? true : false}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                        
                                    </div>
                                </div>

                                {formData.useThirdPartyForTransaltion == 1 ?
                                    <div className="col-100">

                                        <label className="input-label">{t("OpenAiApiKey")}</label>
                                        <div className="form-group">
                                            <Input 
                                                className="form-control" 
                                                type="text" 
                                                name="openAiApiKey"
                                                OnChange={(e) => SetFormData(e)}
                                                value={formData.openAiApiKey}
                                            />
                                            
                                        </div>
                                    </div>
                                :null}
                            </div>               
                        </>                                                                    
                    )
                }

            </ContentBody>
        </>
    )
}

export default TranslationSettings;