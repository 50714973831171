import { useState,useEffect } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText, ValidateEmail, GetTimeInMinutes } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_RESERVATION } from "../Queries/reservations";
import { MAKE_MEMBERSHIP_ORDER } from "../../Orders/Queries/order";
import { GET_MEMBERSHIPS_BY_RESERVATION_PLACE } from "../../Membership/Queries/membership";
import dayjs from "dayjs";

const UseAddEditReservation = (selectedReservationID,lang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const client = useApolloClient();

    const [allMemberships, SetAllMemberships] = useState([]);
    const [loadingMembership, SetLoadingMemberships] = useState(false);
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData
    } = UseFormHandle({
        handOverReservation:0,
        changeReservationData:0,
        customerHash:"",
        customerName:"",
        customerLang:"",
        email:"",
        couponCode:"",
        couponStatus:0,
        reservationPlaceID:0,
        membershipID:0,
        reservationDate:"",
        reservationPlaceTimeID:0,
        reservationPlaceTimeName:"",
        reservationPlaceVariationID:0
    });

    const [showNotificationType, SetShowNotifiationType] = useState(0);
    const [hasVariations, SetHasVariations] = useState(false);
    const [membershipData, SetMembershipData] = useState({});

    const [tempCustomer, SetTempCustomer] = useState({
        customerHash:"",
        customerName:"",
        customerLang:"",
        email:"",
    });

    const [tempResData, SetTempResData] = useState({
        reservationPlaceID:0,
        membershipID:0,
        reservationDate:"",
        reservationPlaceTimeID:0,
        reservationPlaceTimeName:"",
        reservationPlaceVariationID:0
    });

    const [EditReservationMutation,{loading}] = useMutation(ADD_EDIT_RESERVATION,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditReservation);

            const notifyText = t("SuccessfullyUpdated");

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            err = GetApolloErrorText(err);
            if(err == "RESERVATION_ALLREADY_EXISTS")
                err = t("RESERVATION_ALLREADY_EXISTS");

            AddNotification(err,false);  
        }
    });

    const [AddReservation,{loading:addLoading}] = useMutation(ADD_EDIT_RESERVATION,{
        onCompleted: (d) => {

            const notifyText = t("AndThereItIsDone");
            AddNotification(notifyText,true);

            if(OnCompleted)
                OnCompleted();

        },
        onError:(err) => {
            err = GetApolloErrorText(err);
            if(err == "RESERVATION_ALLREADY_EXISTS")
                err = t("RESERVATION_ALLREADY_EXISTS");

            SetCompleteFormData({
                reservationDate:"",
                reservationPlaceTimeID:0,
                reservationPlaceTimeName:"",
                reservationPlaceVariationID:0,
                reservationPlaceTimeVariationName:""
            })

            AddNotification(err,false);
        }
    });

    const [MakeMembershipOrder,{loading:orderLoading}] = useMutation(MAKE_MEMBERSHIP_ORDER,{
        onCompleted: (d) => {

            const notifyText = t("AndThereItIsDone");
            AddNotification(notifyText,true);

            if(OnCompleted)
                OnCompleted();

        },
        onError:(err) => {
            err = GetApolloErrorText(err);
            if(err == "RESERVATION_ALLREADY_EXISTS" || err == "COUPON_IS_NOT_AVAILABLE")
                err = t(err);

            SetCompleteFormData({
                reservationDate:"",
                reservationPlaceTimeID:0,
                reservationPlaceTimeName:"",
                reservationPlaceVariationID:0,
                reservationPlaceTimeVariationName:""
            })

            AddNotification(err,false);
        }
    });

    useEffect(() => {

        if(formData.reservationPlaceID)
            GetReservationPlaceMemberships()

    },[formData.reservationPlaceID])

    useEffect(() => {

        if(formData.membershipID)
        {
            for(let val of allMemberships)
            {
                if(val.membershipID == formData.membershipID)
                {
                    SetMembershipData(val);
                    break;
                }
            }
        }

    },[formData.membershipID])

    const GetReservationPlaceMemberships = async () => {

        SetLoadingMemberships(true);

        try
        {
            const response = await client.query({
                query: GET_MEMBERSHIPS_BY_RESERVATION_PLACE,
                variables:{
                    lang,
                    reservationPlaceID:formData.reservationPlaceID
                }
            })
            SetAllMemberships(response.data.AllMembershipsByReservationPlace);
        }
        catch(err)
        {
            err = GetApolloErrorText(err);
            AddNotification(err,false);
        }

        SetLoadingMemberships(false);
    }

    const NotificationCallBack = (act) => {

        if(act)
        {
            var duration = formData.reservationPlaceTimeName.split(" - ");
            var durationInt = GetTimeInMinutes(duration[1]) - GetTimeInMinutes(duration[0]);

            if(showNotificationType == 3 || showNotificationType == 6)
            {
                AddReservation({
                    variables:{
                        customerHash: formData.customerHash,
                        membershipID: formData.membershipID,
                        orderID:0,
                        reservationPlaceID: formData.reservationPlaceID,
                        reservationPlaceTimeID: formData.reservationPlaceTimeID,
                        reservationPlaceVariationID:formData.reservationPlaceVariationID,
                        reservationStatusID: (showNotificationType == 3 ? 6 : 1),
                        isActive: (showNotificationType == 3 ? 0 : 1),
                        date: formData.reservationDate + " " + duration[0] + ":00",
                        duration: durationInt,
                        note:"",
                        possibilityToBeActiveTo: (showNotificationType == 3 ? dayjs().add(3,"d").format("YYYY-MM-DD 23:59:59") : "0000-00-00 00:00:00"),
                        sendEmailToCustomer:true,
                        showPaymentButtonInEmail: (showNotificationType == 3 ? true : false),
                    }
                })
            }
            else
            {
                var reservationStatusID = 0;
                if(showNotificationType == 1)
                    reservationStatusID = 3;
                if(showNotificationType == 2)
                    reservationStatusID = 2;
                if(showNotificationType == 4 || showNotificationType == 7)
                    reservationStatusID = 1;
                if(showNotificationType == 5)
                    reservationStatusID = 3;

                if(showNotificationType != 7 || (showNotificationType == 7 && formData.couponCode && formData.couponStatus == 2))
                {
                    MakeMembershipOrder({
                        variables:{
                            linkID:0,
                            projectID:membershipData.projectID,
                            membershipID:formData.membershipID,
                            membershipTypeID:0,
                            membershipTypePriceID:0,
                            lang:formData.customerLang,
                            couponCode:formData.couponCode,
                            usePrepaymentPrice:(showNotificationType == 1 || showNotificationType == 5 ? true : false),
                            customerData:{
                                email:formData.email
                            },
                            reservationData:{
                                reservationPlaceID: formData.reservationPlaceID,
                                reservationPlaceTimeID: formData.reservationPlaceTimeID,
                                reservationPlaceVariationID: formData.reservationPlaceVariationID,
                                reservationStatusID:reservationStatusID,
                                note:"",
                                reservationDate:formData.reservationDate,
                            },
                            ownStatusData:{
                                paymentIntentID:"0",
                                paymentStatus: (showNotificationType == 4 || showNotificationType == 5 || showNotificationType == 7 ? "COMPLETED" : "WAITING_FOR_PAYMENT"),
                                paymentStatusMessage:""
                            }
                        }
                    })

                    SetShowNotifiationType(0);
                    SetFormData("couponCode","");
                }
                else
                    AddNotification(t("InvalidCouponCode"),false);
            }
        }
        else
        {
            SetShowNotifiationType(0);
            SetFormData("couponCode","");
        }
    }

    const EditReservation = () => {

        if(formData.reservationPlaceID)
        {   
            if(formData.customerHash != "")
            {     
                if(formData.membershipID)
                {     
                    if(formData.reservationDate && formData.reservationPlaceTimeID && (!hasVariations || (hasVariations && formData.reservationPlaceVariationID)))
                    {     
                        var duration = formData.reservationPlaceTimeName.split(" - ");
                        var durationInt = GetTimeInMinutes(duration[1]) - GetTimeInMinutes(duration[0]);

                        EditReservationMutation({
                            variables:{
                                reservationID:                  selectedReservationID,
                                customerHash:                   formData.customerHash,
                                membershipID:                   formData.membershipID,
                                reservationPlaceID:             formData.reservationPlaceID,
                                reservationPlaceTimeID:         formData.reservationPlaceTimeID,
                                reservationPlaceVariationID:    formData.reservationPlaceVariationID,
                                date:                           formData.reservationDate + " " + duration[0] + ":00",
                                duration:                       durationInt,
                                sendEmailToCustomer:            true                                
                            }
                        })  
                    }
                    else
                        AddNotification(t("InvalidReservationData"),false); 
                }
                else
                    AddNotification(t("InvalidMembership"),false); 
            }
            else
                AddNotification(t("InvalidCustomer"),false); 
        }
        else
            AddNotification(t("InvalidReservationPlace"),false); 
    } 

    const ReservationPlaceCalendarDataChanged = (data) => {

        if(data.reservationDate != formData.reservationDate)
            SetFormData("reservationDate",data.reservationDate);

        if(data.reservationPlaceTimeID != formData.reservationPlaceTimeID)
            SetFormData("reservationPlaceTimeID",data.reservationPlaceTimeID);

        if(data.reservationPlaceVariationID != formData.reservationPlaceVariationID)
            SetFormData("reservationPlaceVariationID",data.reservationPlaceVariationID);

        if(data.reservationPlaceTimeName != formData.reservationPlaceTimeName)
            SetFormData("reservationPlaceTimeName",data.reservationPlaceTimeName);

        if(data.reservationPlaceTimeVariationName != formData.reservationPlaceTimeVariationName)
            SetFormData("reservationPlaceTimeVariationName",data.reservationPlaceTimeVariationName);
    }

    const GetNotificationMessage = () => {

        var message = "";
        switch(showNotificationType)
        {
            case 1: message = t("ReallyWaitingOnPrepayment"); break;
            case 2: message = t("ReallyWaitingOnCompletePayment"); break;
            case 3: message = t("ReallyBook"); break;
            case 4: message = t("ReallyPaymentIsOnAccount"); break;
            case 5: message = t("ReallyPrePaymentIsOnAccount"); break;
            case 6: message = t("ReallyReservatonForFree"); break;
            case 7: message = t("ReallyPayByCoupon"); break;
        }
        return message;
    }

    const HandOverReservation = (e) => {

        var value = e.target.value;
        if(value == 1)
        {
            SetTempCustomer({
                customerHash: formData.customerHash,
                customerName: formData.customerName,
                customerLang: formData.customerLang,
                email: formData.email,
            })
        }
        else
            SetCompleteFormData(tempCustomer)

        SetFormData(e);
    }

    const ChangeReservationData = (e) => {

        var value = e.target.value;
        if(value == 1)
        {
            SetTempResData({
                reservationPlaceID:formData.reservationPlaceID,
                membershipID:formData.membershipID,
                reservationDate:formData.reservationDate,
                reservationPlaceTimeID:formData.reservationPlaceTimeID,
                reservationPlaceTimeName:formData.reservationPlaceTimeName,
                reservationPlaceVariationID:formData.reservationPlaceVariationID
            })
        }
        else
            SetCompleteFormData(tempResData)

        SetFormData(e);
    }
    
    const InitFormData = (data) => {
        console.log(data);
        SetCompleteFormData(data);
    }

    return {
        loading: loading || loadingMembership || orderLoading || addLoading,
        formData,
        allMemberships,
        hasVariations,
        membershipData,
        showNotificationType,
        EditReservation,
        SetFormData,
        InitFormData,
        ReservationPlaceCalendarDataChanged,
        SetHasVariations,
        SetShowNotifiationType,
        GetNotificationMessage,
        NotificationCallBack,
        HandOverReservation,
        ChangeReservationData
    }

}

export default UseAddEditReservation;