import { useState,useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_REVIEW,GENERATE_REVIEW_LANG } from "../Queries/review";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";

const UseAddEditReview = (projectID,selectedReviewID,mainLang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();
        
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj,
        OpenImage
    } = UseFormHandle({
        selectedLang:mainLang,
        active:1,
        photo:null,
        langs:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_REVIEW_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditReviewMutation,{error,loading}] = useMutation(ADD_EDIT_REVIEW,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    reviewID: d.AddEditReview.reviewID,
                    isEdit: (selectedReviewID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditReview);
                
                const notifyText = (selectedReviewID != 0 ? t("SuccessfullyUpdated") : t("NewReviewIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("ReviewHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditReview = () => {

        var checkText = true;
        let langs = [];

        for(const lang of formData.langs)
        {
            if(lang.lang == formData.selectedLang && !lang.text)
            {
                checkText = false;
                break;
            }

            langs.push({
                text:       lang.text,
                signature:  lang.signature,
                lang:       lang.lang
            });
        }

        if(checkText)
        {
            AddEditReviewMutation({
                variables:{
                    reviewID:               selectedReviewID,
                    projectID:              projectID,
                    active:                 parseInt(formData.active),
                    photo:                  formData.photo,
                    langs:                  langs,
                    generateTranslations:   formData.generateTranslations
                }
            })
        }
        else
            AddNotification(t("NoTextNoGo"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditReview,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj,
        OpenImage
    }

}

export default UseAddEditReview;