import gql from 'graphql-tag';

export const GET_ALL_ADMIN_ROLES = gql`
    query AllAdminRoles{
        AllAdminRoles{
            adminRoleID
            name
            backgroundColor
        }
    }
`;

export const GET_ADMIN_ROLE = gql`
    query AdminRole($adminRoleID:ID){
        AdminRole(adminRoleID:$adminRoleID){
            adminRoleID
            name
            modules{
                moduleID
            }
            projects{
                projectID
            }
        }
        AllModulesBy(param:"activeByDefault",value:0,parentModuleID:0)
        {
            moduleID
            activeByDefault
            isForProject
            label
            link
            subModule{
                moduleID
                activeByDefault
                isForProject
                label
                link
            }
        } 
        AllProjects{
            projectID
            name
        }   
    }
`;

export const ADD_EDIT_ADMIN_ROLE = gql`
    mutation AddEditAdminRole(
        $adminRoleID:ID,
        $name:String,
        $modules:[ID],
        $projects:[ID] 
    ){
        AddEditAdminRole(
            adminRoleID:$adminRoleID,
            name:$name, 
            modules:$modules, 
            projects:$projects
        ){
            adminRoleID
            name
        }
    }
`;

export const DELETE_ADMIN_ROLES = gql`
    mutation DeleteAdminRoles($adminRoleIDs:ID) {
        DeleteAdminRoles(adminRoleIDs:$adminRoleIDs)
    }
`;

