/**
 * hlavní sekce
 */

const SECTION = {
    backgroundColorClass:"",
    backgroundImage:"",
    sectionName:"",
    fullWidth:0,
    fullHeight:0,
    elements:[],
    className:"",
    imageFill:"cover",
    imageAlign:"left",
    contentAlign:"top",
    fadeWithBackground:0,
    ptXL:110,
    pbXL:110,
    prXL:20,
    plXL:20,
    ptLG:100,
    pbLG:100,
    prLG:20,
    plLG:20,
    ptMD:80,
    pbMD:80,
    prMD:15,
    plMD:15,
    ptSM:80,
    pbSM:80,
    prSM:15,
    plSM:15,
    ptXS:60,
    pbXS:60,
    prXS:15,
    plXS:15,
    ptXXS:60,
    pbXXS:60,
    prXXS:15,
    plXXS:15,
}

/**
 * definice elementu seřazeného sloupce
 */

const COLUMN = {
    className:"",
    contentAlign:"start",
    hasBorder:0,
    borderRadius:0,
    colsXL:"25",
    colsLG:"25",
    colsMD:"25",
    colsSM:"25",
    colsXS:"25",
    colsXXS:"25",
    mtXL:0,
    mbXL:0,
    mtLG:0,
    mbLG:0,
    mtMD:0,
    mbMD:0,
    mtSM:0,
    mbSM:0,
    mtXS:0,
    mbXS:0,
    mtXXS:0,
    mbXXS:0,
    ptXL:0,
    pbXL:0,
    prXL:0,
    plXL:0,
    ptLG:0,
    pbLG:0,
    prLG:0,
    plLG:0,
    ptMD:0,
    pbMD:0,
    prMD:0,
    plMD:0,
    ptSM:0,
    pbSM:0,
    prSM:0,
    plSM:0,
    ptXS:0,
    pbXS:0,
    prXS:0,
    plXS:0,
    ptXXS:0,
    pbXXS:0,
    prXXS:0,
    plXXS:0,
    elements:[],
}

/**
 * definice elementu kontejneru pro seřazeného sloupce
 */

const COLUMNS = {
    columns:{
        cols:[COLUMN],
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        colsPaddingXL: 20,
        colsPaddingLG: 20,
        colsPaddingMD: 15,
        colsPaddingSM: 15,
        colsPaddingXS: 15,
        colsPaddingXXS: 15,
        wrapColumnsXL: "cm-wrap",
        wrapColumnsLG: "cm-wrap",
        wrapColumnsMD: "cm-wrap",
        wrapColumnsSM: "cm-wrap",
        wrapColumnsXS: "cm-wrap",
        wrapColumnsXXS: "cm-wrap",
        reverseOrderXL: "cm-clasic-row",
        reverseOrderLG: "cm-clasic-row",
        reverseOrderMD: "cm-clasic-row",
        reverseOrderSM: "cm-clasic-row",
        reverseOrderXS: "cm-clasic-row",
        reverseOrderXXS: "cm-clasic-row"
    }
}

/**
 * definice elementu obsah záložky
 */

const TAB = {
    name:"Název záložky",
    className:"",
    ptXL:0,
    pbXL:0,
    prXL:0,
    plXL:0,
    ptLG:0,
    pbLG:0,
    prLG:0,
    plLG:0,
    ptMD:0,
    pbMD:0,
    prMD:0,
    plMD:0,
    ptSM:0,
    pbSM:0,
    prSM:0,
    plSM:0,
    ptXS:0,
    pbXS:0,
    prXS:0,
    plXS:0,
    ptXXS:0,
    pbXXS:0,
    prXXS:0,
    plXXS:0,
    elements:[]
}

/**
 * definice elementu pro záložku
 */

const TABS = {
    tabs:{
        tabs:[TAB],
        className:"",
        borderRadius:0,
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    }
}

/**
 * Definice elementu nadpis
 */

const HEADLINE = { 

    headline: {    
        text:"Text nadpisu",
        subText:"Nějaký text pod nadpisem, který lze schovat",
        align:"start",
        numbering:"",
        hasSubText:1,
        showGlow:1,
        className:"",
        selectedTag:"h1",
        subHfsXL:"26",
        subHfsLG:"24",
        subHfsMD:"22",
        subHfsSM:"20",
        subHfsXS:"18",
        subHfsXXS:"16",
        numberingHeightXL:"90",
        numberingHeightLG:"70",
        numberingHeightMD:"50",
        numberingHeightSM:"40",
        numberingHeightXS:"35",
        numberingHeightXXS:"33",

        h1mtXL:0,
        h1mbXL:70,
        h1mtLG:0,
        h1mbLG:60,
        h1mtMD:0,
        h1mbMD:40,
        h1mtSM:0,
        h1mbSM:30,
        h1mtXS:0,
        h1mbXS:20,
        h1mtXXS:0,
        h1mbXXS:20,
        h1fsXL:"85",
        h1fsLG:"75",
        h1fsMD:"55",
        h1fsSM:"45",
        h1fsXS:"35",
        h1fsXXS:"30",
        
        h2mtXL:0,
        h2mbXL:70,
        h2mtLG:0,
        h2mbLG:60,
        h2mtMD:0,
        h2mbMD:40,
        h2mtSM:0,
        h2mbSM:30,
        h2mtXS:0,
        h2mbXS:20,
        h2mtXXS:0,
        h2mbXXS:20,
        h2fsXL:"65",
        h2fsLG:"55",
        h2fsMD:"45",
        h2fsSM:"35",
        h2fsXS:"26",
        h2fsXXS:"26",
        
        h3mtXL:0,
        h3mbXL:30,
        h3mtLG:0,
        h3mbLG:30,
        h3mtMD:0,
        h3mbMD:30,
        h3mtSM:0,
        h3mbSM:30,
        h3mtXS:0,
        h3mbXS:20,
        h3mtXXS:0,
        h3mbXXS:20,
        h3fsXL:"45",
        h3fsLG:"35",
        h3fsMD:"30",
        h3fsSM:"25",
        h3fsXS:"22",
        h3fsXXS:"22",
        
        h4mtXL:0,
        h4mbXL:30,
        h4mtLG:0,
        h4mbLG:30,
        h4mtMD:0,
        h4mbMD:30,
        h4mtSM:0,
        h4mbSM:30,
        h4mtXS:0,
        h4mbXS:20,
        h4mtXXS:0,
        h4mbXXS:20,
        h4fsXL:"35",
        h4fsLG:"30",
        h4fsMD:"25",
        h4fsSM:"22",
        h4fsXS:"20",
        h4fsXXS:"20",

        h5mtXL:0,
        h5mbXL:30,
        h5mtLG:0,
        h5mbLG:30,
        h5mtMD:0,
        h5mbMD:30,
        h5mtSM:0,
        h5mbSM:30,
        h5mtXS:0,
        h5mbXS:20,
        h5mtXXS:0,
        h5mbXXS:20,
        h5fsXL:"30",
        h5fsLG:"27",
        h5fsMD:"24",
        h5fsSM:"21",
        h5fsXS:"20",
        h5fsXXS:"20",

        h6mtXL:0,
        h6mbXL:30,
        h6mtLG:0,
        h6mbLG:30,
        h6mtMD:0,
        h6mbMD:30,
        h6mtSM:0,
        h6mbSM:30,
        h6mtXS:0,
        h6mbXS:20,
        h6mtXXS:0,
        h6mbXXS:20,
        h6fsXL:"28",
        h6fsLG:"26",
        h6fsMD:"23",
        h6fsSM:"20",
        h6fsXS:"20",
        h6fsXXS:"20",
    }
}

const TEXT = { 

    text: {
        text:"<p>Obsah textu</p>",
        className:"",
        opacity:"10",
        mtXL:20,
        mbXL:20,
        mtLG:20,
        mbLG:20,
        mtMD:15,
        mbMD:15,
        mtSM:15,
        mbSM:15,
        mtXS:15,
        mbXS:15,
        mtXXS:15,
        mbXXS:15,
        fsXL:"20",
        fsLG:"20",
        fsMD:"18",
        fsSM:"16",
        fsXS:"15",
        fsXXS:"14"
    }   
}

const IMAGE = { 

    image: {
        fileName:"",
        imageAlign:"center",
        borderRadius:0,
        useDimensions:"0",
        imageSize:"stredni",
        linkTarget:"_blank",
        link:"",
        className:"",
        useOverlayByText:0,
        overlayText:"Nadpis",
        overlayColor:"black",
        overlayOpacity: 7,
        overlayHorizontalAlign:"start",
        overlayVerticalAlign:"end",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        widthXL:300,
        widthLG:300,
        widthMD:300,
        widthSM:300,
        widthXS:300,
        widthXXS:300,
        heightXL:300,
        heightLG:300,
        heightMD:300,
        heightSM:300,
        heightXS:300,
        heightXXS:300,
        overlayfsXL:"30",
        overlayfsLG:"26",
        overlayfsMD:"22",
        overlayfsSM:"18",
        overlayfsXS:"16",
        overlayfsXXS:"16",
        mirrorXl:"",
        mirrorLG:"",
        mirrorMD:"",
        mirrorSM:"",
        mirrorXS:"",
        mirrorXXS:""
    }  
}

const IMAGE_GALLERY = { 

    imageGallery: {
        images:[],
        borderRadius:0,
        imageSize:"stredni",
        colsXL:"25",
        colsLG:"25",
        colsMD:"25",
        colsSM:"25",
        colsXS:"25",
        colsXXS:"25",
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        ptXL:20,
        pbXL:20,
        prXL:20,
        plXL:20,
        ptLG:20,
        pbLG:20,
        prLG:20,
        plLG:20,
        ptMD:15,
        pbMD:15,
        prMD:15,
        plMD:15,
        ptSM:15,
        pbSM:15,
        prSM:15,
        plSM:15,
        ptXS:15,
        pbXS:15,
        prXS:15,
        plXS:15,
        ptXXS:15,
        pbXXS:15,
        prXXS:15,
        plXXS:15
    }  
}

const BUTTON = { 

    button: {
        type:1,
        text:"Název odkazu",
        buttonAlign:"center",
        url:"",
        color:"btn-primary",
        size:"small",
        dividingLine:"0",
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
    }
}

const VIDEO = { 

    video: {
        embedCode:"",
        videoTypeID:1,
        mp4File:"",
        mp4FilePoster:"",
        mp4Align:"center",
        mp4Width:300,
        mp4Height:300,
        controls:"0",
        useDimensions:0,
        borderRadius:0,
        changeVideo:false,
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
    }
}

const VIDEO_GALLERY = { 

    videoGallery: {
        videoGalleryTypeID:1,
        videoTypeID:1,
        mainLeftVideo:"",
        mainRightVideo:"",
        selectedVideos:[],
        borderRadius:10,
        slideshowTheme:"dark",
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
    }
}

const REVIEWS = { 
    reviews: {
        className:"",
        selectedReviews:[],
        withoutQuotationMarks:0,
        borderRadius:10,
        mtXL:0,
        mbXL:0,
        mrXL:0,
        mlXL:0,
        mtLG:0,
        mbLG:0,
        mrLG:0,
        mlLG:0,
        mtMD:0,
        mbMD:0,
        mrMD:0,
        mlMD:0,
        mtSM:0,
        mbSM:0,
        mrSM:0,
        mlSM:0,
        mtXS:0,
        mbXS:0,
        mrXS:0,
        mlXS:0,
        mtXXS:0,
        mbXXS:0,
        mrXXS:0,
        mlXXS:0
    }
}

const RESERVATION_PLACE = { 
    reservationPlace: {
        className:"",
        selectedReviews:[],
        membershipID:0,
        reservationPlaceID:0,
        showCalendar:1,
        showName:1,
        showImage:1,
        showReservationButton:1,
        color:"btn-primary",
        size:"small",
        buttonText:"Objednat se",
        reviewText:"Recenze",
        imageBorderRadius:0,
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    }
}

const FAQ = { 
    faq: {
        className:"",
        selectedFaqs:[],
        borderRadius:10,
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    }
}

const NEWSLETTER = { 
    newsletter: {
        className:"",
        color:"btn-primary",
        size:"",
        inputSize:"",
        text:"Odeslat",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    }
}

const NEWEST_ARTICLES = { 
    newestArticles: {
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    }
}

const HTML = { 

    html: {
        code:"code",
        type:"html",
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    } 
}

const SERVICE_ELM = (langs) => { 

    return {
        service: {
            showMenu:true,
            showSettings:false,
            className:"",
            serviceID:0,
            color:""
        }
    }
    
}

const ALERT = { 

    alert: {
        text:"Nějaký text pro upozornění.",
        type:"success",
        className:"",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        fsXL:"20",
        fsLG:"20",
        fsMD:"16",
        fsSM:"16",
        fsXS:"14",
        fsXXS:"14"
    } 
}

const SLIDER = { 
    slider: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const SEPARATOR = { 
    separator: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const RECOMMENDED_PRODUCTS = { 

    recommendedProducts: {
        className:"",
        color:"btn-primary",
        size:"small",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0,
        howToBuy:0,
        selectedProducts:[]
    }  
}

const TOP_CATEGORIES = (projectID) => { 

    return{
        topCategories: {
            className:"",
            mtXL:0,
            mbXL:0,
            mtLG:0,
            mbLG:0,
            mtMD:0,
            mbMD:0,
            mtSM:0,
            mbSM:0,
            mtXS:0,
            mbXS:0,
            mtXXS:0,
            mbXXS:0,
            selectedCategories:{[projectID]:[]}
        } 
    } 
}

const BANNER = { 

    banner: {
        className:"",
        smallTopBannerID:0,
        smallBottomBannerID:0,
        bigBannerIDs:[0,0,0],
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    }  
}

const FORM = { 

    form: {
        className:"",
        formID:0,
        color:"btn-primary",
        size:"",
        inputSize:"",
        text:"Odeslat",
        mtXL:0,
        mbXL:0,
        mtLG:0,
        mbLG:0,
        mtMD:0,
        mbMD:0,
        mtSM:0,
        mbSM:0,
        mtXS:0,
        mbXS:0,
        mtXXS:0,
        mbXXS:0
    } 
}

export {
    SECTION,
    COLUMNS,
    COLUMN,
    HEADLINE,
    TEXT,
    IMAGE,
    IMAGE_GALLERY,
    BUTTON,
    VIDEO,
    NEWEST_ARTICLES,
    NEWSLETTER,
    HTML, 
    SERVICE_ELM,
    ALERT,
    SLIDER,
    SEPARATOR,
    RECOMMENDED_PRODUCTS,
    TOP_CATEGORIES,
    BANNER,
    FORM,
    REVIEWS,
    FAQ,
    VIDEO_GALLERY,
    RESERVATION_PLACE,
    TABS,
    TAB
};