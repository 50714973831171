import {useQuery} from '@apollo/client';
import { GET_RESERVATION_PLACE_VARIATIONS } from '../Queries/reservationPlaceVariation';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseChooseReservationPlaceVariations = (
    lang,
    selectedVariations,
    FillFormSelectedItemsWithObj
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    
    const {data,loading,error} = useQuery(GET_RESERVATION_PLACE_VARIATIONS,{
        fetchPolicy:"network-only",
        variables:{
            lang
        }
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllReservationPlaceVariations);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[data,selectedVariations])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value: val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.selected = false;
            if(selectedVariations && selectedVariations.length > 0)
            {
                for(let v of selectedVariations)
                {
                    if(val.reservationPlaceVariationID == v.reservationPlaceVariationID)
                        cData.selected = true;
                }
            }

            cData.rowName = val.name;
            cData.rowID   = val.reservationPlaceVariationID;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,data) => {

        SelectFormID(content,data.rowID);    
    }

    const SelectFormID = (list,rowID) => {

        for(let i = 0; i < list.length; i++)
        {
            if(rowID == list[i].rowID || rowID === "all")
            {              
                FillFormSelectedItemsWithObj("selectedVariations","reservationPlaceVariationID",{
                    name: list[i].rowName,
                    reservationPlaceVariationID: list[i].rowID
                },false);

                break;
            }
        }
    }

    return{
        content,
        headerData,
        loading,
        error,
        SelectRow
    }

}

export default UseChooseReservationPlaceVariations;