import React,{ Component } from 'react';

const Success = ({text,className}) => {
    return (
    	<div 
			className= {"alert alert-success text-center" + (className ? " " + className : "")}
			dangerouslySetInnerHTML={{__html: text}}
		></div>
    );
}

export default Success;