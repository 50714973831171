import { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditScheduledNewsletter from '../Library/UseAddEditScheduledNewsletter';
import UseGetScheduledNewsletter from '../Library/UseGetScheduledNewsletter';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import dayjs from 'dayjs';
import NewsletterForm from './NewsletterForm';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';

Modal.setAppElement('#root');

const AddEditScheduledNewsletter = ({selectedScheduledNewsletterID,SetSelectedScheduledNewsletterID,OnAddEditSuccess}) => {

    const {t} = useTranslation();

    var itemsToTranslate = [
        {name:"Předmět",param:"subject"},
        {name:"Preheader",param:"preheader"},
        {name:"Text",param:"text"},
    ];

    const {
        loading:getLoading,
        error:getError,
        allLanguageMutations,
        allProjects,
        mainLang
    } = UseGetScheduledNewsletter(selectedScheduledNewsletterID,(d) => {
        InitFormData(d) 
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        allCampaigns,
        AddEditScheduledNewsletter,
        InitFormData,
        SetFormData,
        GetFormLangValue,
        SetFormLangData
    } = UseAddEditScheduledNewsletter(
        selectedScheduledNewsletterID,
        mainLang.suffix,
        allLanguageMutations,
        (d) => OnAddEditSuccess(d)
    );

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);
    
    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedScheduledNewsletterID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content modal-for-emailing"}
        >
            <ModalHeader 
                title = {(selectedScheduledNewsletterID === 0 ? t("AddScheduledNewsletter") : t("EditScheduledNewsletter"))}
                OnClose ={() => SetSelectedScheduledNewsletterID(null)}
                allLanguages={allLanguageMutations}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedScheduledNewsletterID === 0 ? false : true}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            (translatedLangsCount != 0 ?
                                <TranslationProgressBar
                                    translatedLang = {translatedLang}
                                    translatedLangsCount = {translatedLangsCount}
                                    totalLangs = {allLanguageMutations.length}
                                />
                            :
                                <Loading />
                            )
                        : 
                            <>
                                <NewsletterForm
                                    allProjects = {allProjects}
                                    allCampaigns = {allCampaigns}
                                    isPlanned = {true}
                                    formData = {formData}
                                    GetFormLangValue = {GetFormLangValue}
                                    SetFormLangData = {SetFormLangData}
                                    SetFormData = {SetFormData}
                                />
                                
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditScheduledNewsletter()}
                >
                    {(selectedScheduledNewsletterID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditScheduledNewsletter;