import {useMutation, useQuery} from '@apollo/client';
import { SAVE_EXCHANGE_RATES } from '../../Queries/currencies';
import { useState } from 'react';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { UseNotification } from '../../../Notification/Library/UseNotification';
import { useTranslation } from 'react-i18next';

const UseGetAllExchangeRates = () => {

    const {t} = useTranslation();
    const [allCurrencies, SetAllCurrencies] = useState([]);
    const {AddNotification} = UseNotification();

    const [SaveExchangeRatesMutation,{loading,error}] = useMutation(SAVE_EXCHANGE_RATES,{
        onCompleted:(data) => {
            AddNotification(t("SuccessfullySaved"),true);
        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const CopyAndSaveExchangeRates = () => {

        var arr = [...allCurrencies];
        for(let i = 0; i < arr.length; i++)
        {
            arr[i] = {...arr[i],exchangeRate:arr[i].worldExchangeRate}
        }

        SetAllCurrencies(arr);
        setTimeout(() => SaveExchangeRates(),500)
    }

    const SaveExchangeRates = () => {

        SetAllCurrencies((prevAllCurrencies) => {

            var check = true;
            var dataToSend = [];
            for(let val of prevAllCurrencies)
            {
                if(!val.exchangeRate)
                    check = false;

                dataToSend.push({
                    currencyID:val.currencyID,
                    exchangeRate: val.exchangeRate
                })
            }

            if(check)
                SaveExchangeRatesMutation({
                    variables:{
                        data:dataToSend
                    }
                })
            else
                AddNotification(t("SomeExchangeRateDidNotBeFilled"),false);

            return prevAllCurrencies;

        });
    }

    const EditCurrency = (currencyID,value) => {
        
        var arr = [...allCurrencies];
        for(let i = 0; i < arr.length; i++)
        {
            if(arr[i].currencyID == currencyID)
                arr[i] = {...arr[i],exchangeRate:parseFloat(value)}
        }

        SetAllCurrencies(arr);

    }

    return {
        loading,
        error,
        allCurrencies,
        EditCurrency,
        SaveExchangeRates,
        CopyAndSaveExchangeRates,
        SetAllCurrencies
    }

}

export default UseGetAllExchangeRates;