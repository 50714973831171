import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import InputCard from "../../../GlobalComponents/InputCard";
import Button from "../../../GlobalComponents/Button";
import Loading from "../../../GlobalComponents/Loading";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseSendNewsletter from "../Library/UseSendNewsletter";
import NewsletterForm from "./NewsletterForm";
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import TranslationProgressBar from "../../../GlobalComponents/TranslationProgressBar";
import { GENERATE_NEWSLETTER_LANG_TO_OBJECT } from "../Queries/newsletter";

const Newsletter = () => {

    const {t} = useTranslation();

    const {
        formData,
        loading,
        error,
        allLanguageMutations,
        allProjects,
        allCampaigns,
        mainLang,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj,
        SendNewsletter
    } = UseSendNewsletter();

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(
        GENERATE_NEWSLETTER_LANG_TO_OBJECT,
        allLanguageMutations,
        "GenerateNewsletterLangToObject",
        (d) => OnSuccessTranslation(d)
    );

    const OnSuccessTranslation = (d) => {
        if(d)
        {
            for(let val of d)
            {
                SetFormDataSelectedItemsWithObj("subject","langs","lang",val.lang,val.subject);
                SetFormDataSelectedItemsWithObj("preheader","langs","lang",val.lang,val.preheader);
                SetFormDataSelectedItemsWithObj("text","langs","lang",val.lang,val.text);
            }
        }
    }

    return(
        <>
            <ContentHeader
                title = {t("SendNewsletter")}
                isEdit = {false}
                allLanguages = {allLanguageMutations}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}   
                OnTranslateButtonClick = {() => GenerateOtherTranstalions({
                    subject:GetFormLangValue("subject"),
                    preheader:GetFormLangValue("preheader"),
                    text:GetFormLangValue("text")
                })}            
            />
            <ContentBody>

                <InputCard
                    className="input-card-for-emailing"
                >
                    {loading || generateLoading ?
                        <>  
                            {translatedLangsCount != 0 ?
                                <div className="form-group">
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                </div>
                            :
                                <Loading />
                            }
                        </>
                    :
                        (error || generateError ?
                            <Error text = {GetApolloErrorText(error || generateError)} />
                        :
                            <>
                                <div className="form-group">
                                    <NewsletterForm
                                        allProjects = {allProjects}
                                        allCampaigns = {allCampaigns}
                                        isPlanned = {false}
                                        formData = {formData}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormLangData = {SetFormLangData}
                                        SetFormData = {SetFormData}
                                    />
                                </div>
                                <div className="form-group">
                                    <Button
                                        className={"btn-primary w-100"}
                                        OnClick={() => SendNewsletter()}
                                    >{t("Send")}</Button>
                                </div>
                            </>
                        )
                    }
                    
                </InputCard>

            </ContentBody>
        </>
    )
}

export default Newsletter;