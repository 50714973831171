import Input from '../../../GlobalComponents/Input';
import InputCard from '../../../GlobalComponents/InputCard';
import eye from '../../../Media/Images/Icons/eye.webp';
import credtCard from '../../../Media/Images/Icons/credit_card.webp';
import invoiceIcon from '../../../Media/Images/Icons/invoice.webp';
import worldwideIcon from '../../../Media/Images/Icons/worldwide.webp';
import RadioButton from '../../../GlobalComponents/RadioButton';
import { useTranslation } from 'react-i18next';
import imageIcon from '../../../Media/Images/Icons/image.webp';
import contactIcon from '../../../Media/Images/Icons/contact.webp';
import FileInput from '../../../GlobalComponents/FileInput';
import Button from '../../../GlobalComponents/Button';
import NoItems from '../../../GlobalComponents/NoItems';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import Select from '../../../GlobalComponents/Select';
import Label from '../../../GlobalComponents/Label';
import { useMemo } from 'react';
import { UseProject } from '../../../AdminProvider';

const BasicInformations = ({
    formData,
    allBillingInformations,
    allLanguageMutations,
    SetFormData,
    OpenImage,
    AddDomain,
    RemoveDomain,
    SetDomainValue,
    GetFormLangValue,
    SetFormLangData
}) =>{

    const {IsModulEnabled} = UseProject();
    const {t} = useTranslation();

    const notDeletedDomans = useMemo(()=>{
        return formData.domains.filter((item) => !item.isDeleted)
    },[formData.domains])

    return(
        <div className="row">
            <div className="col-50">

                <div className="form-group">
                    <label>*{t("ProjectName")}</label>
                    <Input
                        name="name"
                        value={formData.name}
                        OnChange={(e) => SetFormData(e)}
                    />
                </div>
            </div>

            <div className="col-50">

                <div className="form-group">
                    <Label 
                        text = {"*" + t("ProjectNameInTab") + " (" + formData.selectedLang + ")"}
                        infoContent={[
                            t("ProjectNameInTabInfo")
                        ]}
                    />
                    <Input
                        name="metaTitle"
                        value={GetFormLangValue("metaTitle")}
                        OnChange={(e) => SetFormLangData(e)}
                    />
                </div>
            </div>
            
            <div className="col-33 d-flex">

                <InputCard
                    title = {t("Logo")}
                    icon = {imageIcon}
                    className = {"form-group flex-1"}
                > 
                    {formData.mainLogo ?
                        
                        <div className="open-image-container form-group">
                            <img className="no-margin" src = {formData.mainLogo} />
                        </div>
                        
                    :null}

                    <div className="form-group">                
                        <FileInput
                            name = "logo"
                            OnChange = {(e) => OpenImage(e,"logo","mainLogo")}
                        />
                    </div>

                </InputCard>

            </div>

            <div className="col-33 d-flex">

                <InputCard
                    title = {t("LogoFooter")}
                    icon = {imageIcon}
                    className = {"form-group flex-1"}
                > 
                    {formData.mainLogoFooter ?
                        
                        <div className="open-image-container form-group">
                            <img className="no-margin" src = {formData.mainLogoFooter} />
                        </div>
                        
                    :null}

                    <div className="form-group">                
                        <FileInput
                            name = "logoFooter"
                            OnChange = {(e) => OpenImage(e,"logoFooter","mainLogoFooter")}
                        />
                    </div>

                </InputCard>

            </div>

            <div className="col-33 d-flex">
                
                <InputCard
                    title = {t("LogoInPortal")}
                    icon = {imageIcon}
                    className = {"form-group flex-1"}
                > 
                    {formData.mainLogoPortal ?
                        
                        <div className="open-image-container form-group">
                            <img className="no-margin" src = {formData.mainLogoPortal} />
                        </div>
                        
                    :null}

                    <div className="form-group">                
                        <FileInput
                            name = "logoPortal"
                            OnChange = {(e) => OpenImage(e,"logoPortal","mainLogoPortal")}
                        />
                    </div>

                </InputCard>
                    
            </div>

            <div className="col-50">
                <InputCard
                    title = {t("Favicon")}
                    icon = {imageIcon}
                    className = {"form-group"}
                    headerInfoContent = {[t("FaviconInfo")]}
                >   
                    <div className="d-flex align-items-center">
                        {formData.mainFavicon ?
                            
                            <div className="open-image-container favicon form-group">
                                <img className="no-top-margin" src = {formData.mainFavicon} />
                            </div>
                            
                        :null}

                        <div className="form-group">                
                            <FileInput
                                name = "favicon"
                                OnChange = {(e) => OpenImage(e,"favicon","mainFavicon")}
                            />
                        </div>
                    </div>
                    
                </InputCard>
            </div>
            <div className="col-50">
                <InputCard
                    title = {t("Display")}
                    icon = {eye}
                    className = {"form-group flex-1"}
                >   
                    <div className="row">
                        <div className="col-25">
                            <div className="form-group">
                                
                                <label>{t("Active")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="active"
                                        id="active-1"
                                        value={1}
                                        checked = {formData.active === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="active"
                                        id="active-2"
                                        value={0}
                                        checked = {formData.active === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>                                                             
                    </div> 
                </InputCard>
            </div>
            <div className="col-100">

                <InputCard
                    title = {t("ContactOnWeb") + " (" + formData.selectedLang+ ")"}
                    icon = {contactIcon}
                    className = {"form-group flex-1"}
                > 

                    <div className="row">
                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("Email")}</label>
                                <Input
                                    name="webEmail"
                                    value={GetFormLangValue("webEmail")}
                                    OnChange={(e) => SetFormLangData(e)}
                                />
                            </div>
                        </div>
                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("Tel")}</label>
                                <Input
                                    name="webTel"
                                    value={GetFormLangValue("webTel")}
                                    OnChange={(e) => SetFormLangData(e)}
                                />
                            </div>
                        </div>
                    </div>

                </InputCard>

                <InputCard
                    title = {t("BillingInformation")}
                    icon = {invoiceIcon}
                    className = {"form-group"}
                >   
                    <div className="row">
                        <div className="col-33">
                            <InputCard
                                title = {t("InvoiceLogo")}
                                icon = {imageIcon}
                                className = {"form-group flex-1"}
                            > 
                                {formData.mainLogoInvoice ?
                                    
                                    <div className="open-image-container form-group">
                                        <img className="no-margin" src = {formData.mainLogoInvoice} />
                                    </div>
                                    
                                :null}

                                <div className="form-group">                
                                    <FileInput
                                        name = "logoInvoice"
                                        OnChange = {(e) => OpenImage(e,"logoInvoice","mainLogoInvoice")}
                                    />
                                </div>

                            </InputCard>
                        </div>
                        <div className="col-66">
                            <div className="form-group">
                                <label>{t("BillingInformation")}</label>

                                {allBillingInformations.length > 0 ?
                                    <Select
                                        name="billingInformationID"
                                        value={formData.billingInformationID}
                                        OnChange={(e) => SetFormData(e)}
                                    >
                                        <option value={0}>{" - - - " + t("choose") + " - - - "}</option>
                                        {allBillingInformations.map((item) => (
                                            <option 
                                                key={item.billingInformationID}
                                                value={item.billingInformationID}
                                            >{item.company}</option>
                                        ))}
                                    </Select>
                                :
                                    <NoItems text = {t("WithoutBillingInformationPaymentIsDisabled")} />
                                }
                            </div>
                            
                            <div className="form-group">
                                <Label
                                    text={t("CharacterBeforeInvoiceNumber")}
                                    infoContent={[
                                        t("CharacterBeforeInvoiceNumberInfo")
                                    ]}
                                />
                                <Input
                                    name="invoiceNumberCharacter"
                                    value={formData.invoiceNumberCharacter}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                        
                    </div>

                </InputCard>

                <InputCard
                    title = {t("Payments-PaymentGatewayStripe")}
                    icon = {contactIcon}
                    className = {"form-group"}
                > 
                    <div className="form-group">
                        <label>{t("PublishableKey")}</label>
                        <Input
                            name="stripePublishableKey"
                            value={formData.stripePublishableKey}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t("SecretKey")}</label>
                        <Input
                            name="stripeSecretKey"
                            value={formData.stripeSecretKey}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t("WebHook")}</label>
                        <Input
                            name="stripeWebHook"
                            value={formData.stripeWebHook}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                </InputCard>
            
                <InputCard
                    title = {t("Domains")}
                    icon = {worldwideIcon}
                    className = {"flex-1"}
                    headerInfoContent = {[
                        t("ProjectDomainInfo1"),  
                        t("ProjectDomainInfo2"),
                        t("ProjectDomainInfo3")  
                    ]}
                    headerChildren = {
                        <Button 
                            OnClick = {() => AddDomain()}
                            className="btn-primary narrow"
                        >
                            {t("Add")}
                        </Button>
                    }
                > 
                    {notDeletedDomans && notDeletedDomans.length > 0 ?
                        <>
                            {formData.domains.map((item,index) => {
                                
                                if(!item.isDeleted)
                                    return (
                                        <div className="row" key = {index}>
                                            <div className="col-20">
                                                <label>{t("Language")}</label>
                                                <Select
                                                    name="lang"
                                                    value = {item.lang}
                                                    OnChange={(e) => SetDomainValue(e,index)}
                                                >
                                                    <option 
                                                        key={0} 
                                                        value={""}
                                                    >{t("Multilingual")}</option>
                                                    {allLanguageMutations.map((lang) => (
                                                        <option 
                                                            key={lang.languageID} 
                                                            value={lang.suffix}
                                                        >{lang.suffix}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="col-20">
                                                
                                                <div className="form-group">
                                                    <Label 
                                                        text = {t("GTM-ID")}
                                                        infoContent = {[
                                                            t("GTM-IDinfo1"),
                                                            t("GTM-IDinfo2")
                                                        ]}
                                                    />
                                                    <Input
                                                        name="gtmID"
                                                        value={item.gtmID}
                                                        OnChange={(e) => SetDomainValue(e,index)}
                                                    />
                                                </div>
                                                
                                            </div>
                                            
                                            <div className="col-20">
                                                
                                                <div className="form-group">
                                                    <label>{t("SubdomainForPortal")}</label>
                                                    <Input
                                                        name="portalSubdomain"
                                                        value={item.portalSubdomain}
                                                        OnChange={(e) => SetDomainValue(e,index)}
                                                    />
                                                </div>
                                                
                                            </div>
                                            
                                            <div className={"col-40"}>
                                                
                                                <div className="form-group">
                                                    <label>{t("Name")}</label>
                                                    <Input
                                                        name="name"
                                                        value={item.name}
                                                        OnChange={(e) => SetDomainValue(e,index)}
                                                        append = {
                                                            <img 
                                                                onClick = {() => RemoveDomain(index)}
                                                                className = "cursor-pointer" 
                                                                src={cancelIcon} 
                                                            />
                                                        }
                                                        preppend = {
                                                            <p>www.</p>
                                                        }
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    )
                                else
                                    return "";
                            })}

                        </>
                    :
                        <NoItems text = {t("NoDomainsYet")} />
                    }

                </InputCard>
            </div>
        </div>
    )
}

export default BasicInformations;