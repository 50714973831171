import { useEffect,useState } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import VideoGalleryResponziveSettings from "./VideoGalleryResponziveSettings.js";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { VIDEO_GALLERY } from "../../../Library/elements";
import videoGalleryIcon from '../../../../../Media/Images/Icons/video_gallery.webp';
import cancelIcon from '../../../../../Media/Images/Icons/cancel.webp';
import List from "../../../../../GlobalComponents/List/List";
import Select from "../../../../../GlobalComponents/Select";
import UseEditVideoGallery from "../../../Library/UseEditVideoGallery";

const VideoGallerySettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData,RemoveFormDataSelectedItems} = UseFormHandle(VIDEO_GALLERY.videoGallery); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection,
        SetOpenFileManagerData
    } = UseContentManager();

    const {
        headerData,
        content,
        OnDragEnd
    } = UseEditVideoGallery(formData.selectedVideos,formData.videoTypeID,SetFormData);

    useEffect(() => {

        const settings = GetSettings(showSettingsElement.index,"videoGallery");
        SetCompleteFormData(settings);
        
    },[showSettingsElement])

    useEffect(() => {

        SetSettings(showSettingsElement.index,"selectedVideos",formData.selectedVideos,"videoGallery");

    },[formData.selectedVideos])

    const SetS = (e,val) => {
        SetFormData(e,val);
        SetSettings(showSettingsElement.index,e,val,"videoGallery");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"videoGallery");
        ClearSelection();
    }

    const SetSelectedVideos = (data) => {
        var sVideos = [];
        for(let val of data)
        {
            if(formData.videoTypeID == 3)
                sVideos.push({
                    "contentManagerVideoID" : val.contentManagerVideoID,
                    "mp4File":val.name,
                    "mp4FilePoster":val.poster,
                    "orientation":val.orientation
                })
            else
                sVideos.push({
                    "contentManagerVideoID" : val.contentManagerVideoID,
                    "embedCode":val.embedCode,
                    "orientation":val.orientation
                })
        }
        SetFormData("selectedVideos",sVideos)
    }

    const SetMainVideo = (param,data) => {

        var videoData;

        if(formData.videoTypeID == 3)
            videoData = {
                "contentManagerVideoID" : data.contentManagerVideoID,
                "mp4File":data.name,
                "mp4FilePoster":data.poster,
                "orientation":data.orientation
            }
        else
            videoData = {
                "contentManagerVideoID" : data.contentManagerVideoID,
                "embedCode":data.embedCode,
                "orientation":data.orientation
            }

        SetS(param,videoData);

    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <VideoGalleryResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <VideoGalleryResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <VideoGalleryResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <VideoGalleryResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <VideoGalleryResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <VideoGalleryResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="form-group">
                <label>{t("VideoGalleryType")}</label>
                <Select
                    name = {"videoGalleryTypeID"}
                    OnChange = {(e) => SetS(e)}
                    value = {formData.videoGalleryTypeID}
                >
                    <option value={1}>{t("Gallery")}</option>
                    <option value={2}>{t("Slideshow")}</option>
                </Select>
            </div>
            <div className="form-group">
                <label>{t("VideoType")}</label>
                <Select
                    name = {"videoTypeID"}
                    OnChange = {(e) => SetS(e)}
                    value = {formData.videoTypeID}
                >
                    <option value={1}>{t("Youtube")}</option>
                    <option value={2}>{t("Vimeo")}</option>
                    <option value={3}>{t("Mp4")}</option>
                </Select>
            </div>

            <div className="form-group">
                <InputCard
                    className = "form-group"
                    title = {t("VideoGallery")}
                    icon = {videoGalleryIcon}
                    noBodyPadding = {true}
                    headerChildren = {
                        <Button 
                            className="btn-primary narrow"
                            OnClick = {() => SetOpenFileManagerData({
                                element:"videoGallery",
                                type:2,
                                videoTypeID:formData.videoTypeID,
                                index:showSettingsElement.index,
                                selectMultiple:true,
                                CallBack: (data) => SetSelectedVideos(data)
                            })}
                        >
                            {formData.selectedVideos && formData.selectedVideos.length > 0 ?
                                t("AddVideos")
                                :
                                t("ChooseVideos")
                            }
                        </Button>
                    }
                >   
                <List 
                    headerData = {headerData} 
                    headerClassName = {"no-top-border smaller-padding"}
                    rowClassname = {"smaller-padding"}
                    contentData = {content}
                    isSortable = {true}
                    OnDragListRowEnd={(e)=> OnDragEnd(e)}
                    loading = {false}
                    replaceDotsImg = {cancelIcon}
                    optionsTitle = {""}
                    optionsClassName = "smallest"
                    ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedVideos","contentManagerVideoID",[data.rowID])}
                />

                </InputCard>
            </div>

            {formData.videoGalleryTypeID == 1 ?
                <>
                    <div className="form-group">
                        <label>{t("MainLeftVideo")}</label>
                        <div className="row">
                            <div className = {formData.mainLeftVideo ? "col-50" : "col-100"}>
                                <Button 
                                    className={"w-100 btn-primary"}
                                    OnClick = {() => SetOpenFileManagerData({
                                        element:"videoGallery",
                                        type:2,
                                        videoTypeID:formData.videoTypeID,
                                        index:showSettingsElement.index,
                                        CallBack: (data) => SetMainVideo("mainLeftVideo",data)
                                    })}
                                >
                                    {formData.mainLeftVideo ?
                                        t("ChangeVideo")
                                        :
                                        t("ChooseVideo")
                                    }
                                </Button>
                            </div>
                            {formData.mainLeftVideo ?
                                <div className = "col-50">
                                    <Button 
                                        className="btn-brown w-100"
                                        OnClick = {() => {
                                            SetS("mainLeftVideo","");
                                        }}
                                    >{t("RemoveVideo")}</Button>
                                </div>
                            :null}

                        </div>
                    </div>

                    <div className="form-group">
                        <label>{t("MainRightVideo")}</label>
                        <div className="row">
                            <div className = {formData.mainRightVideo ? "col-50" : "col-100"}>
                                <Button 
                                    className={"w-100 btn-primary"}
                                    OnClick = {() => SetOpenFileManagerData({
                                        element:"videoGallery",
                                        type:2,
                                        videoTypeID:formData.videoTypeID,
                                        index:showSettingsElement.index,
                                        CallBack: (data) => SetMainVideo("mainRightVideo",data)
                                    })}
                                >
                                    {formData.mainRightVideo ?
                                        t("ChangeVideo")
                                        :
                                        t("ChooseVideo")
                                    }
                                </Button>
                            </div>
                            {formData.mainRightVideo ?
                                <div className = "col-50">
                                    <Button 
                                        className="btn-brown w-100"
                                        OnClick = {() => {
                                            SetS("mainRightVideo","");
                                        }}
                                    >{t("RemoveVideo")}</Button>
                                </div>
                            :null}
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label>{t("Radius")}</label>
                        <Input 
                            type = "number"
                            name = "borderRadius"
                            value = {formData.borderRadius}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </>
            :
                <>
                    <div className="form-group">
                        <label>{t("SlideshowTheme")}</label>
                        <Select
                            name = {"slideshowTheme"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.slideshowTheme}
                        >
                            <option value={"dark"}>{t("Dark")}</option>
                            <option value={"light"}>{t("Light")}</option>
                            <option value={"ac"}>{t("AcTheme")}</option>
                        </Select>
                    </div>
                </>
            }
        
            <div className="form-group">
                <label>{t("ClassName")}</label>
                <Input 
                    type = "text"
                    name = "className"
                    value = {formData.className}
                    OnChange = {(e) => SetS(e)}
                />
            </div>
                
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default VideoGallerySettings;