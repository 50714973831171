import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseAuth } from '../../Auth/Library/UseAuth';
import List from '../../../GlobalComponents/List/List';
import { useState } from 'react';
import UseChooseFaq from '../Library/UseChooseFaq';

Modal.setAppElement('#root');

const ChooseFaq = ({
    projectID,
    formData,
    SetShowAddFaq,
    FillFormSelectedItemsWithObj
}) => {

    var selectedFaqs = [];
    if(formData.selectedFaqs && formData.selectedFaqs.length > 0)
    {
        for(let val of formData.selectedFaqs)
        {
            selectedFaqs.push(val.faqID);
        }
    }
    
    const {user} = UseAuth();
    const {t} = useTranslation();

    const [showFilter,SetShowFilter] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll
    } = UseChooseFaq(projectID,user.lang,selectedFaqs,FillFormSelectedItemsWithObj);

    var err = "";
    if(error)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddFaq(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("AddFaq")}
                OnClose ={() => SetShowAddFaq(false)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <div className="modal-body-no-padding">
                        <List 
                            headerClassName={"no-top-border"}
                            headerData = {headerData} 
                            contentData = {content}
                            totalContentLength = {totalContentLength}
                            paggingOffset = {paggingOffset}
                            paggingLimit = {paggingLimit}
                            FetchMore = {FetchMore}
                            GoToPage = {GoToPage}
                            isSortable = {false}
                            loading = {loading}
                            hideOptions = {true}
                            OnSelectAll = {(e) => SelectAll(e)}
                            options = {{
                                OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                            }}
                        />
                    </div> 
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddFaq(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )
}

export default ChooseFaq;