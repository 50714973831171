import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditReview from "./AddEditReview";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllReviews from "../Library/UseGetAllReviews";
import UseDeleteReviews from "../Library/UseDeleteReviews";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { useParams } from "react-router-dom";
import { GetProjectIDFromParam } from "../../../GlobalFunctions/header";
import { UseAuth } from "../../Auth/Library/UseAuth";

const Reviews = () => {

    const params = useParams();
    const projectID = GetProjectIDFromParam(params);

    const {t} = useTranslation();
    const {user} = UseAuth();

    const [selectedReviewID, SetSelectedReviewID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedReviewIDs,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        UpdateList
    } = UseGetAllReviews(user.lang,projectID);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteReviews
    } = UseDeleteReviews(selectedReviewIDs,() => UpdateList());

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ReviewsList")}
                        OnAddButtonClick={() => SetSelectedReviewID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}

                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}

                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (reviewsID) => SetSelectedReviewID(reviewsID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedReviewID || selectedReviewID == 0 ?
                <AddEditReview
                    projectID = {projectID}
                    selectedReviewID = {selectedReviewID}
                    SetSelectedReviewID = {SetSelectedReviewID}
                    OnAddEditSuccess = {() => {
                        UpdateList();
                        SetSelectedReviewID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteReviews")} 
                    CallBack={DeleteReviews} 
                />
            :null}

        </>
    )
}

export default Reviews;