import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText, ValidateEmail } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_CUSTOMER } from "../Queries/customer";
import { GetCustomerRoleObj } from "../../Membership/Library/UseEditCustomerRoles";

const UseAddEditCustomer = (selectedCustomerID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        OpenImage,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj
    } = UseFormHandle({
        photo:null,
        lang:"",
        name:"",
        surname:"",
        email:"",
        tel:"",
        street:"",
        street2:"",
        city:"",
        zip:"",
        state:"",
        countryID:"",
        company:"",
        ic:"",
        dic:"",
        icdph:"",
        deliveryName:"",
        deliverySurname:"",
        deliveryStreet:"",
        deliveryStreet2:"",
        deliveryCity:"",
        deliveryZip:"",
        deliveryState:"",
        deliveryCountryID:"",
        internalNote:"",
        bankAccount:"",
        swift:"",
        iban:"",
        selectedCustomerRoles:[]
    });

    const [AddEditCustomerMutation,{loading}] = useMutation(ADD_EDIT_CUSTOMER,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditCustomer);

            const notifyText = (selectedCustomerID != 0 ? t("SuccessfullyUpdated") : t("NewCustomerIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            err = GetApolloErrorText(err);
            if(err == "CUSTOMER_EXISTS")
                err = t("CustomerExistsWithThisEmail")

            AddNotification(err,false);  
        }
    });

    const AddEditCustomer = () => {

        if(ValidateEmail(formData.email))
        {   
            if(formData.lang != "")
            {   
                if(formData.countryID != 0)
                {   
                    AddEditCustomerMutation({
                        variables:{
                            customerID:             selectedCustomerID,
                            lang:                   formData.lang,
                            name:                   formData.name,
                            surname:                formData.surname,
                            email:                  formData.email,
                            tel:                    formData.tel,
                            street:                 formData.street,
                            street2:                formData.street2,
                            city:                   formData.city,
                            zip:                    formData.zip,
                            state:                  formData.state,
                            countryID:              formData.countryID,
                            company:                formData.company,
                            ic:                     formData.ic,
                            dic:                    formData.dic,
                            icdph:                  formData.icdph,
                            deliveryName:           formData.deliveryName,
                            deliverySurname:        formData.deliverySurname,
                            deliveryStreet:         formData.deliveryStreet,
                            deliveryStreet2:        formData.deliveryStreet2,
                            deliveryCity:           formData.deliveryCity,
                            deliveryZip:            formData.deliveryZip,
                            deliveryState:          formData.deliveryState,
                            deliveryCountryID:      formData.deliveryCountryID,
                            internalNote:           formData.internalNote,
                            bankAccount:            formData.bankAccount,
                            swift:                  formData.swift,
                            iban:                   formData.iban,
                            selectedCustomerRoles:  GetCustomerRoles(formData.selectedCustomerRoles)
                        }
                    }) 
                }
                else
                    AddNotification(t("InvalidCountry"),false);  
            }
            else
                AddNotification(t("InvalidLang"),false); 
        }
        else
            AddNotification(t("InvalidEmail"),false); 
    } 

    const GetCustomerRoles = (customerRoles) => {

        if(customerRoles)
        {
            var newCustomerRoles = [];
            for(let val of customerRoles)
            {
                newCustomerRoles.push(
                    {...GetCustomerRoleObj(val),
                        customerRoleCustomerID:(
                            val.customerRoleCustomerID ? 
                                val.customerRoleCustomerID 
                            : 0
                        )
                    }
                );
            }

            return newCustomerRoles;
        }
        else
            return []
    }

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        loading: loading,
        formData,
        AddEditCustomer,
        SetFormData,
        InitFormData,
        OpenImage,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj
    }

}

export default UseAddEditCustomer;