import gql from 'graphql-tag';

export const ADD_EDIT_COVER = gql`
    mutation AddEditCover(
        $coverID:ID,
        $coverMaterialTypeID:ID,
        $privateName:String,
        $shortcut:String,
        $langs:[CoverLangsInput]
        $selectedManufacturers:[CoverManufacturerInput]
        $coverPhotos:[CoverPhotoInput]
        $excludedProducts:[ID]
    ){
        AddEditCover(
            coverID:$coverID,
            coverMaterialTypeID: $coverMaterialTypeID,
            privateName: $privateName, 
            shortcut:$shortcut,
            langs:$langs,
            selectedManufacturers:$selectedManufacturers
            coverPhotos: $coverPhotos
            excludedProducts:$excludedProducts
        ){
            coverID
            privateName
            name
        }
    }
`;

export const GET_COVER = gql`
    query CoverWithLangs($coverID: ID,$lang: String){
        CoverWithLangs(coverID:$coverID){
            coverID
            coverMaterialTypeID
            privateName
            shortcut
            manufacturers{
                manufacturerID
                percentage
                manufacturer{
                    name
                }
            }
            coverPhotos{
                coverPhotoID
                name
                description
                number
                isMain
            }
            langs{
                lang
                name
                description
            }  
            excludedProducts{
                productID
            }  
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        AllManufacturers(lang:$lang,limit:1000000,offset:0){
            manufacturerID
            name
        }
        AllCoverMaterialTypes(lang:$lang){
            coverMaterialTypeID
            name
        }
    }
`;

export const GET_COVERS = gql`
    query AllCovers($lang: String,$searchText: String,$limit:Int,$offset:Int,$manufacturerID:ID){
        AllCovers(lang:$lang,searchText:$searchText,limit:$limit,offset:$offset,manufacturerID:$manufacturerID){
            coverID
            name
            privateName
            shortcut
            manufacturers{
                manufacturerID
                manufacturer{
                    name
                }
            }
        }
        AllCoversCount(searchText:$searchText,lang:$lang,manufacturerID:$manufacturerID)
        AllManufacturers(lang:$lang,limit:1000000,offset:0,searchText:"")
        {
            manufacturerID
            name
        }
    }
`;

export const DELETE_COVERS = gql`
    mutation DeleteCovers($coverIDs:ID) {
        DeleteCovers(coverIDs:$coverIDs)
    }
`;

export const UPLOAD_COVER_PHOTOS = gql`
    mutation UploadCoverPhotos(
        $files:[Upload],
        $coverID: ID
    ){
        UploadCoverPhotos(
            files:$files,
            coverID:$coverID
        ){
            coverPhotoID
            name
            description
            number
        }
    }
`;

export const DELETE_COVER_PHOTOS = gql`
    mutation DeleteCoverPhotos($coverPhotoIDs:[ID]){
        DeleteCoverPhotos(coverPhotoIDs:$coverPhotoIDs)
    }
`;

export const GET_COVER_PHOTOS = gql`
    query AllCoverPhotos($coverID:ID){
        AllCoverPhotos(coverID:$coverID){
            coverPhotoID
            name
            number
        }
    }
`;