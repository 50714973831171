const Reservation = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path style={{"fill":secondaryColor}} d="M127.03,305.926H92.929c-19.033,0-34.518-15.485-34.518-34.518v-34.101
                            c0-19.033,15.485-34.518,34.518-34.518h34.101c19.033,0,34.518,15.485,34.518,34.518v34.101
                            C161.548,290.441,146.063,305.926,127.03,305.926z M92.929,224.789c-6.902,0-12.518,5.616-12.518,12.518v34.101
                            c0,6.902,5.616,12.518,12.518,12.518h34.101c6.902,0,12.518-5.616,12.518-12.518v-34.101c0-6.902-5.616-12.518-12.518-12.518
                            H92.929z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}} d="M273.05,305.926h-34.101c-19.033,0-34.518-15.485-34.518-34.518v-34.101
                            c0-19.033,15.485-34.518,34.518-34.518h34.101c19.033,0,34.518,15.485,34.518,34.518v34.101
                            C307.568,290.441,292.083,305.926,273.05,305.926z M238.949,224.789c-6.902,0-12.518,5.616-12.518,12.518v34.101
                            c0,6.902,5.616,12.518,12.518,12.518h34.101c6.902,0,12.518-5.616,12.518-12.518v-34.101c0-6.902-5.616-12.518-12.518-12.518
                            H238.949z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}} d="M419.07,305.926h-34.101c-19.033,0-34.518-15.485-34.518-34.518v-34.101
                            c0-19.033,15.485-34.518,34.518-34.518h34.101c19.033,0,34.518,15.485,34.518,34.518v34.101
                            C453.588,290.441,438.104,305.926,419.07,305.926z M384.969,224.789c-6.902,0-12.518,5.616-12.518,12.518v34.101
                            c0,6.902,5.616,12.518,12.518,12.518h34.101c6.902,0,12.518-5.616,12.518-12.518v-34.101c0-6.902-5.616-12.518-12.518-12.518
                            H384.969z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}} d="M127.03,443.32H92.929c-19.033,0-34.518-15.485-34.518-34.518v-34.101
                            c0-19.033,15.485-34.518,34.518-34.518h34.101c19.033,0,34.518,15.485,34.518,34.518v34.101
                            C161.548,427.835,146.063,443.32,127.03,443.32z M92.929,362.183c-6.902,0-12.518,5.616-12.518,12.518v34.101
                            c0,6.902,5.616,12.518,12.518,12.518h34.101c6.902,0,12.518-5.616,12.518-12.518v-34.101c0-6.902-5.616-12.518-12.518-12.518
                            H92.929z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}} d="M419.07,443.32h-34.101c-19.033,0-34.518-15.485-34.518-34.518v-34.101
                            c0-19.033,15.485-34.518,34.518-34.518h34.101c19.033,0,34.518,15.485,34.518,34.518v34.101
                            C453.588,427.835,438.104,443.32,419.07,443.32z M384.969,362.183c-6.902,0-12.518,5.616-12.518,12.518v34.101
                            c0,6.902,5.616,12.518,12.518,12.518h34.101c6.902,0,12.518-5.616,12.518-12.518v-34.101c0-6.902-5.616-12.518-12.518-12.518
                            H384.969z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}} d="M273.05,443.32h-34.101c-19.033,0-34.518-15.485-34.518-34.518v-34.101
                            c0-19.033,15.485-34.518,34.518-34.518h34.101c19.033,0,34.518,15.485,34.518,34.518v34.101
                            C307.568,427.835,292.083,443.32,273.05,443.32z M238.949,362.183c-6.902,0-12.518,5.616-12.518,12.518v34.101
                            c0,6.902,5.616,12.518,12.518,12.518h34.101c6.902,0,12.518-5.616,12.518-12.518v-34.101c0-6.902-5.616-12.518-12.518-12.518
                            H238.949z"/>
                    </g>
                    <path style={{"fill":primaryColor}} d="M461.163,39.277H436.15c-0.647-20.816-17.77-37.553-38.74-37.553s-38.093,16.738-38.74,37.553H153.329
                        c-0.647-20.816-17.77-37.553-38.74-37.553s-38.093,16.738-38.74,37.553H50.837C22.806,39.277,0,62.083,0,90.115v369.324
                        c0,28.032,22.806,50.837,50.837,50.837h410.325c28.032,0,50.837-22.806,50.837-50.837V90.115
                        C512,62.083,489.194,39.277,461.163,39.277z M380.639,40.495c0-9.247,7.523-16.771,16.771-16.771s16.771,7.523,16.771,16.771
                        v25.566c0,9.248-7.523,16.771-16.771,16.771s-16.771-7.523-16.771-16.771V40.495z M97.818,40.495
                        c0-9.247,7.523-16.771,16.771-16.771s16.771,7.523,16.771,16.771v25.566c0,9.248-7.523,16.771-16.771,16.771
                        s-16.771-7.523-16.771-16.771V40.495z M22,90.115c0-15.901,12.937-28.837,28.837-28.837h24.981v4.783
                        c0,21.378,17.393,38.771,38.771,38.771s38.771-17.393,38.771-38.771v-4.783h205.279v4.783c0,21.378,17.393,38.771,38.771,38.771
                        s38.771-17.393,38.771-38.771v-4.783h24.981c15.901,0,28.837,12.937,28.837,28.837v44.767v0.952H22v-0.952V90.115z
                        M461.163,488.276H50.837C34.937,488.276,22,475.339,22,459.438V157.833h468v301.605C490,475.339,477.063,488.276,461.163,488.276z
                        "/>
                </g>
            </svg>
        </>
    )
}

export default Reservation;
