import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_COVER_TITLE } from "../../Queries/coverTitles";

const UseAddEditCoverTitle = (selectedCoverTitleID,lang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue} = UseFormHandle({
        selectedLang:lang,
        shortcut:"",
        langs:[]
    });

    const [AddEditCoverTitleMutation,{error,loading}] = useMutation(ADD_EDIT_COVER_TITLE,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditCoverTitle);

            const notifyText = (selectedCoverTitleID != 0 ? t("SuccessfullyUpdated") : t("NewCoverTitleIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditCoverTitle = () => {

        var check = true;

        for(let val of formData.langs)
        {
            if(val.name === "" && val.lang === lang)
                check = false;
        }

        if(check)
        {
            if(formData.shortcut != "")
            {
                let langs = [];
                for(const lang of formData.langs)
                {
                    langs.push({
                        name: lang.name,
                        lang: lang.lang
                    });
                }
                AddEditCoverTitleMutation({
                    variables:{
                        coverTitleID:     selectedCoverTitleID,
                        shortcut:         formData.shortcut,
                        langs:            langs
                    }
                })
            }
            else
                AddNotification(t("ShurtcutIsImportant"),false); 
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
        
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditCoverTitle,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    }

}

export default UseAddEditCoverTitle;