import { useTranslation } from "react-i18next";
import Input from "../../../../../GlobalComponents/Input";
import Select from "../../../../../GlobalComponents/Select";
import WindowWidthResponziveInfo from "../WindowWidthResponziveInfo";

const ColumnsResponziveSettings = ({size,formData,OnChange}) => {

    const {t} = useTranslation();

    return (
        <>
            <WindowWidthResponziveInfo size={size} />
            
            <div className="row">
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingTop")}</label>
                        <Input 
                            type = "number"
                            name = {"mt" + size}
                            value = {formData["mt" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingBottom")}</label>
                        <Input 
                            type = "number"
                            name = {"mb" + size}
                            value = {formData["mb" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ColsPadding")}</label>
                        <Select 
                            type = "number"
                            name = {"colsPadding" + size}
                            value = {formData["colsPadding" + size]}
                            OnChange = {(e) => OnChange(e)}
                        >
                            <option value="0">0</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </Select>
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("WrapColumns")}</label>
                        <Select 
                            name = {"wrapColumns" + size}
                            value = {formData["wrapColumns" + size]}
                            OnChange = {(e) => OnChange(e)}
                        >
                            <option value="cm-wrap">Ano</option>
                            <option value="cm-no-wrap">Ne</option>
                        </Select>
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ColumnSorting")}</label>
                        <Select 
                            name = {"reverseOrder" + size}
                            value = {formData["reverseOrder" + size]}
                            OnChange = {(e) => OnChange(e)}
                        >
                            <option value="cm-clasic-row">Tak jak jsou vloženy</option>
                            <option value="cm-reverse-column">Řadit obráceně řádky</option>
                            <option value="cm-reverse-row">Řadit obráceně sloupce</option>
                        </Select>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ColumnsResponziveSettings;