const Select = ({children,value,name,className,append,disabled,OnChange,OnClick}) => {
    return(
        <div className={"input-group" + (className ? " " + className : "")}>
            <select 
                name={name}
                onChange={(e) => !disabled && OnChange(e)}
                onClick={(e) => {if(OnClick && !disabled)OnClick(e)}}
                value={value}
                disabled = {disabled}
            >
                {children}
            </select>
            {append ?
                <div className="append">
                    {append}
                </div>
            :null}
        </div>
    )
}

export default Select;