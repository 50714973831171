import { useQuery } from "@apollo/client";
import { GET_LANGUAGE } from "../../Queries/language";

const UseGetLanguage = (selectedLanguageID,CallBack) => {

    const {loading,error} = useQuery(GET_LANGUAGE,{
        variables:{
            languageID:selectedLanguageID,
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            console.log(d);
            if(selectedLanguageID != 0)
            {
                CallBack({
                    enabled:d.LanguageMutationByID.enabled
                });
            }
            else
            {
                CallBack({
                    enabled:0
                });
            }

        }
    });

    return{
        loading,
        error
    }

}

export default UseGetLanguage;