import { useState } from "react";
import { DELETE_MARKETING_BANNERS } from "../Queries/marketingBanner";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const UseDeleteMarketingBanners = (selectedItems,OnCompleted) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);

    const [DelMarketingBanners] = useMutation(DELETE_MARKETING_BANNERS,{
        onCompleted:(d) => {

            AddNotification(t("TheyAreGone"),true);
            if(OnCompleted)
                OnCompleted(d.DeleteMarketingBanners);
        },
        onError:(err) => {          
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            AddNotification(t("NoSelectedRows"),false);
        }
    }

    const DeleteMarketingBanners = (action) => {

        if(action)
        {              
            DelMarketingBanners({
                variables:{
                    marketingBannerIDs:selectedItems.join(",")
                }
            })
        }

        SetShowDeleteNotification(false);
    }

    return{
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteMarketingBanners
    }
}

export default UseDeleteMarketingBanners;