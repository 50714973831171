import { useQuery } from "@apollo/client";
import { useState } from 'react';
import { GetMainLanguage, CompleteLangsArray } from "../../../GlobalFunctions/helper";
import { UNSUBSCRIBE_EMAIL_TEXT } from "../../../Config";
import { GET_NEWSLETTER_SETTINGS } from "../Queries/newsletter";

const UseGetNewsletterSettings = (CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allProjects, SetAllProjects] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_NEWSLETTER_SETTINGS,{
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                sendersEmail:"", 
                sendersName:"",
                emailSignature:"",
                unsubscribeText: UNSUBSCRIBE_EMAIL_TEXT
            }

            let langs = CompleteLangsArray(d.NewsletterSettings.langs,d.AllLanguageMutations,emptyLang);

            CallBack({
                emailHeader:     null,
                langs:           langs,
                projectID:       d.NewsletterSettings.projectID, 
                mainEmailHeader: d.NewsletterSettings.emailHeader  
            });
            
            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllProjects(d.AllProjects);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    return{
        loading,
        error,
        allLanguageMutations,
        allProjects,
        mainLang
    }

}

export default UseGetNewsletterSettings;