import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const GetProjectPortalModuleObj = (data) => {

    var langs = [];

    for(let l of data.langs)
    {
        langs.push({
            lang:l.lang,
            name:l.name
        })
    }

    return{
        projectPortalModuleID: (data.projectPortalModuleID ? data.projectPortalModuleID : 0),
        portalModuleID: (data.portalModuleID ? data.portalModuleID : 0),
        deleted: (data.deleted ? true : false),
        added: (data.added ? true : false),
        langs: langs
    }
}

const UseEditProjectPortalModules = (
    selectedLang,
    selectedPortalModules,
    SetFormData,
    SetFormSelectedItemsLangValue,
    GetFormSelectedItemsLangValue,
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedPortalModules)
        {
            const contentData = GetContentData(selectedPortalModules);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[selectedPortalModules,selectedLang])

    var headerData = [
        {
            value: t("ItemName"),
            className:"flex-1"
        },
        {
            value: t("OwnName"),
            className:"flex-1 text-center"
        }
    ];
    

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value: t(val.label),
                        className:"flex-1"
                    },
                    {
                        elm:"input",
                        name:"name",
                        param:"name",
                        OnChange: (e) => SetFormSelectedItemsLangValue(e,"selectedPortalModules","portalModuleID",val.portalModuleID),
                        className:"flex-1"
                    }
                ],
            }

            cData["name"]  = GetFormSelectedItemsLangValue("selectedPortalModules","portalModuleID",val.portalModuleID,"name");
            cData.rowID    = val.portalModuleID;
            cData.deleted  = val.deleted;
            cData.selected = false;

            contentData.push(cData);         
        }
        return contentData;
    }

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {
            const startIndex = result.source.index;
            const endIndex = result.destination.index;
            
            var arr = [...selectedPortalModules];
    
            const [removed] = arr.splice(startIndex, 1);
            arr.splice(endIndex, 0, removed);
            
            SetFormData("selectedPortalModules",arr);
        }
    } 

    return{
        headerData,
        content,
        OnDragEnd
    }

}

export default UseEditProjectPortalModules;