import dayjs from 'dayjs';

export const GetApolloErrorText = (error) => {

    var newError = JSON.stringify(error);
    newError = JSON.parse(newError);
    
    if(newError.graphQLErrors && newError.graphQLErrors[0])
        return newError.graphQLErrors[0].message;
    if(newError.networkError && newError.networkError.result.errors)
        return newError.networkError.result.errors[0].message;
    else
        return error.message;

}

export const ValidateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const OpenImage = (e,CallBack) => {
    var photo = e.target.files[0];        
    var reader = new FileReader();
    reader.onload = () => {
        var dataURL = reader.result;
        CallBack(photo,dataURL);
    };
    reader.readAsDataURL(photo);
}

export const CompleteLangsArray = (langsFromDb,allActiveLanguages,emptyData,uploadParamName,uploadParamNameUrl,allDataParam = "suffix", fromDbParam = "lang") => {

    let langs = [...langsFromDb];
    for(const l of allActiveLanguages)
    {
        let langSet = false;
        for(let i = 0; i < langs.length;i++)
        {
            if(l[allDataParam] == langs[i][fromDbParam])
            {
                if(typeof uploadParamName == "string")
                {
                    if(uploadParamName && emptyData[uploadParamName] === null)
                    {
                        var upn = langs[i][uploadParamName];
                        langs[i] = {...langs[i],[uploadParamName] : null,[uploadParamName + "Show"] : (upn ? uploadParamNameUrl + upn : "")};
                    }

                    langSet = true;
                    break;
                }
                else
                {
                    for(let p in uploadParamName)
                    {
                        if(uploadParamName[p] && emptyData[uploadParamName[p]] === null)
                        {
                            var upn = langs[i][uploadParamName[p]];
                            langs[i] = {...langs[i],[uploadParamName[p]] : null,[uploadParamName[p] + "Show"] : (upn ? uploadParamNameUrl + upn : "")};
                        }
                    }
                    
                    langSet = true;
                    break;
                }
            }
        }

        if(!langSet)
        {
            var dataToPush = {...emptyData};
            dataToPush[fromDbParam] = l[allDataParam];
            langs.push(dataToPush);
        }
    }
    
    return langs;
}

export const GetMainLanguage = (allLanguageMutations) => {
    var mainLang = allLanguageMutations[0];
    for(let val of allLanguageMutations)
    {
        if(val.main == 1)
            mainLang = val;
    }

    return mainLang;
}

export const IsUsAndEnLangEnabledTogether = (allLanguageMutations) => {
    
    var isEn = false;
    var isUs = false;

    for(let val of allLanguageMutations)
    {
        if(val.suffix == "en")
            isEn = true
        if(val.suffix == "us")
            isUs = true
    }

    return (isEn && isUs ? true : false);
}

export const GetResponsiveDimensions = (size) => {

    var dimensions = "";
    switch(size)
    {
        case "XL" : dimensions = "1400px a větší"; break;
        case "LG" : dimensions = "1200px - 1399px"; break;
        case "MD" : dimensions = "992px - 1199px"; break;
        case "SM" : dimensions = "768px - 991px"; break;
        case "XS" : dimensions = "576px - 767px"; break;
        case "XXS" : dimensions = "menší než 576px"; break;
    }

    return dimensions;
}

export const GetPriceString = (price,lang,fraction = 0) => {

    switch(lang)
    {
        case "cz": return new Intl.NumberFormat('cz', { style: 'currency', currency: 'CZK', maximumFractionDigits : fraction }).format(price);
        case "sk": return new Intl.NumberFormat('sk', { style: 'currency', currency: 'EUR', maximumFractionDigits : fraction }).format(price);
    }
}

export const GetPriceStringNew = (price,lang,currencyCode,fraction = 0) =>
{
    var l = lang;
    if(lang == "us")
        l = "en-us";

    if(lang = "cz")
        l = "cs-cz";
    
    return new Intl.NumberFormat(l, { 
        style: 'currency', 
        currency: currencyCode, 
        maximumFractionDigits : fraction
    }).format(price);
}

export const GetDateFormat = (date,lang) => {

    var l = lang;
    if(lang == "us")
        l = "en-us";

    if(lang = "cz")
        l = "cs-cz";
    
    const newDate = dayjs(date).toDate();
    return new Intl.DateTimeFormat(l).format(newDate);
}

export const GetNumberString = (number,lang,fraction = 0) => {

    return new Intl.NumberFormat(lang, { maximumFractionDigits : fraction }).format(number);
    
}

export const ConvertHtmlToText = (html) => {

    const parser = new DOMParser();
    return parser.parseFromString(html, "text/html").body.innerText;
}

export const GetInitialShortcatFromText = (text) => {

    if(text)
    {
        var shorcut = text.replace(/\s\s+/g, ' ').trim().split(" ");
        if(shorcut.length > 1)
            shorcut = shorcut[0][0] + shorcut[1][0];
        else
            shorcut = shorcut[0][0] + shorcut[0][1]

        return shorcut;
    }
    else
        return "";
}

export const GetMonths = (number,t) => {

    var months = [];

    if(parseInt(number) > 0)
        for(let i = 1; i <= number; i++)
        {
            var monthName = t("mesic")
            if(i >= 2 && i <= 4)
                monthName = t("mesice")
            if(i >= 5)
                monthName = t("mesicu")

            months.push({
                name: i + " " + monthName,
                value:i
            })
        }
    

    return months;
}

export const GetTimesOfDay = (iterationInMinutes) => {

    var times = [];

    for(let t = 0; t <= 1440; t += iterationInMinutes)
    {
        times.push({
            minutes:t,
            time:GetTimeInString(t)
        });
    }
    
    return times;
}

export const GetTimeInString = (minutes) => {

    minutes = parseInt(minutes);
    if(minutes || minutes == 0)
    {
        const h = Math.floor(minutes / 60);
        const m = minutes % 60;
        
        return h.toString().padStart(2, "0") + ":" + m.toString().padStart(2, "0");
    }
    return "";
}

export const GetTimeInMinutes = (time) => {

    var minutes = time.split(":");
    minutes = (parseInt(minutes[0]) * 60) + parseInt(minutes[1]);
    
    return minutes;
}

export const CopyToClipboard = (text) => {
    return new Promise((resolve,reject) => {
        navigator.clipboard.writeText(text).then(() => {
            resolve();
        }).catch(err => {
            reject(err);
        });
    });
}