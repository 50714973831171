import {useQuery} from '@apollo/client';
import { GET_CAMPAIGNS } from '../Queries/campaign';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import YesNo from '../../../GlobalComponents/YesNo';
import { GET_ALL_ENABLED_LANGUAGES } from '../../Settings/Queries/language';

const UseGetAllCampaigns = (lang,projectID) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedCampaignIDs,SetSelectedCampaignIDs] = useState([]);
    const [allLanguages,SetAllLanguages] = useState([]);

    const {data:languages,loading:langLoading,error:langError} = useQuery(GET_ALL_ENABLED_LANGUAGES,{
        fetchPolicy:"network-only"
    })

    const {data,loading,error} = useQuery(GET_CAMPAIGNS,{
        variables:{
            lang,
            projectID,
            onlyActive:false
        },
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCampaigns);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[data])

    useEffect(() => {

        if(languages)
        {
            var lang = [...languages.AllLanguageMutations];
            lang.unshift({
                languageID:0,
                suffix:""
            })
            SetAllLanguages(lang);
        }

    },[languages])

    var headerData = [
        {
            value: t("CampaignName"),
            className:"flex-1"
        },
        {
            value: <>{t("TotalEmails")} <br /> <span>({t("IncSubscribed")})</span></>,
            className:"static text-center"
        }, 
        {
            value: <>{t("TotalEmails")} <br /> <span>({t("WithoutSubscribed")})</span></>,
            className:"static text-center"
        }, 
        {
            value: <>{t("UniqueEmails")} <br /> <span>({t("WithoutSubscribed")})</span></>,
            className:"static text-center"
        }, 
        {
            value: <>{t("ActiveEmails")} <br /> <span>({t("WithoutSubscribed")})</span></>,
            className:"static text-center"
        },
        {
            value: t("Active"),
            className:"static text-center"
        }       
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value:val.contactCount,
                        className:"static text-center"
                    },
                    {
                        value:val.contactCountWithoutUnsubscribed,
                        className:"static text-center"
                    },
                    {
                        value:val.uniqueContactCount,
                        className:"static text-center"
                    },
                    {
                        value:val.activeContactCount,
                        className:"static text-center"
                    },
                    {
                        value: <YesNo isYes={!!val.active} />,
                        className:"static text-center"
                    }
                ],
            }

            cData.rowID    = val.campaignID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,campaignID) => {

        var checked = e.target.checked;
        var newSelectedCampaignIDs = [...selectedCampaignIDs];
        const newList = SelectCampaignID(content,campaignID,checked,newSelectedCampaignIDs)

        SetContent(newList);
        SetSelectedCampaignIDs(newSelectedCampaignIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedCampaignIDs = [];
        const newList = SelectCampaignID(content,"all",checked,newSelectedCampaignIDs)

        SetSelectedCampaignIDs(newSelectedCampaignIDs);
        SetContent(newList);
    }

    const SelectCampaignID = (list,campaignID,checked,selectedCampaignIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(campaignID == newList[i].rowID || campaignID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedCampaignIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedCampaignIDs.length; j++)
                    {
                        if(selectedCampaignIDs[j] == newList[i].rowID)
                            selectedCampaignIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectCampaignID(newList[i].subData,campaignID,checked,selectedCampaignIDs)}
        }

        return newList;
    }

    return{
        content,
        headerData,
        loading: loading || langLoading,
        error: error || langError,
        selectedCampaignIDs,
        allLanguages,
        SelectRow,
        SelectAll
    }

}

export default UseGetAllCampaigns;