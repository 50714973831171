import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_COUPON } from "../../Queries/coupons";

const UseAddEditCoupon = (selectedCouponID,lang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems
    } = UseFormHandle({
        selectedLang:lang,
        vatID: 1,
        forLangID:0,
        isLimitedQuantity: 0,
        byManufacturer: 0,
        byLink: 0,
        canBeAppliedToDiscountedProducts: 0,
        showInProductDetail: 0,
        quantity: 0,
        code: "",
        dateFrom: "",
        dateTo: "",
        internalDescription: "",
        langs:[],
        selectedCategories:[],
        selectedManufacturers:[]
        
    });

    const [AddEditCouponMutation,{error,loading}] = useMutation(ADD_EDIT_COUPON,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditCoupon);

            const notifyText = (selectedCouponID != 0 ? t("SuccessfullyUpdated") : t("NewCouponIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditCoupon = () => {

        var checkAmountForLang = false;
        var checkAmountForAllLangs = true;
        var forLang = "";

        for(let val of allLanguageMutations)
        {
            if(formData.forLangID == val.languageID)
                forLang = val.suffix;
        }

        for(let val of formData.langs)
        {
            var p = parseFloat(val.amount);
            if(p && p > 0)
            {
                if(forLang && forLang == val.lang)
                    checkAmountForLang = true;
            }
            else
                checkAmountForAllLangs = false;
        }

        if(formData.selectedCategories.length == 0)
            formData.byLink = 0;

        if(formData.selectedManufacturers.length == 0)
            formData.byManufacturer = 0;
        
        if(formData.code)
        {
            if(formData.dateFrom && formData.dateTo)
            {
                if(checkAmountForLang || checkAmountForAllLangs)
                {
                    if((formData.selectedCategories.length > 0 && formData.byLink) || !formData.byLink)
                    {
                        if((formData.selectedManufacturers.length > 0 && formData.byManufacturer) || !formData.byManufacturer)
                        {
                            if((formData.isLimitedQuantity && formData.quantity > 0) || !formData.isLimitedQuantity)
                            {
                                const byLink = parseInt(formData.byLink);
                                const byManufacturer = parseInt(formData.byManufacturer);

                                let langs = [];
                                var selectedLinks = [];
                                var selectedManufacturers = [];

                                if(byLink)
                                    selectedLinks = GetSelectedCategories(formData.selectedCategories);
                                if(byManufacturer)
                                    selectedManufacturers = GetSelectedManufacturers(formData.selectedManufacturers);
                                
                                for(const lang of formData.langs)
                                {
                                    langs.push({
        
                                        amount:             parseFloat(lang.amount),
                                        inPercentage:       (lang.inPercentage ? 1 : 0),
                                        minimumOrderPrice:  parseFloat(lang.minimumOrderPrice),          
                                        lang:               lang.lang
                                    });
                                }
        
                                AddEditCouponMutation({
                                    variables:{
                                        couponID:                           selectedCouponID,
                                        vatID:                              formData.vatID,
                                        forLangID:                          formData.forLangID,
                                        isLimitedQuantity:                  parseInt(formData.isLimitedQuantity),
                                        byManufacturer:                     byManufacturer,
                                        byLink:                             byLink,
                                        canBeAppliedToDiscountedProducts:   parseInt(formData.canBeAppliedToDiscountedProducts),
                                        showInProductDetail:                parseInt(formData.showInProductDetail),
                                        quantity:                           parseInt(formData.quantity),
                                        code:                               formData.code,
                                        dateFrom:                           formData.dateFrom,
                                        dateTo:                             formData.dateTo,
                                        internalDescription:                formData.internalDescription,
                                        langs:                              langs,
                                        selectedLinks:                      selectedLinks,
                                        selectedManufacturers:              selectedManufacturers
                                        
                                    }
                                })
                            }
                            else
                                AddNotification(t("PleaseFillQuantity"),false);
                        }
                        else
                            AddNotification(t("PleaseChooseManufacturers"),false);
                    }
                    else
                        AddNotification(t("PleaseChooseCategories"),false);
                }
                else
                    AddNotification(t("InvalidAmountForChoosenLang"),false);
            }
            else
                AddNotification(t("PleaseChooseValidity"),false);
        }
        else
            AddNotification(t("NoCodeNoGo"),false); 
        
    } 

    const GetSelectedCategories = (selectedCategories) => {

        var categories = [];
        for(let val of selectedCategories)
        {
            if(!val.deleted || val.add)
            categories.push(val.linkID);
        }

        return categories;
    }

    const GetSelectedManufacturers = (selectedManufacturers) => {

        var manufacturers = [];
        for(let val of selectedManufacturers)
        {
            manufacturers.push(val.manufacturerID);
        }

        return manufacturers;

    }
    
    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditCoupon,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems
    }

}

export default UseAddEditCoupon;