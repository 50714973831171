import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_QUESTIONNAIRE,GENERATE_QUESTIONNAIRE_LANG } from "../Queries/questionnaire";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";
import { GetQuestionnaireStepObj } from "./UseEditQuestionnaire";

const UseAddEditQuestionnaire = (
    selectedQuestionnaireID,
    projectID,
    mainLang,
    allLanguageMutations,
    OnCompleted
) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();

    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
    } = UseFormHandle({
        selectedLang:mainLang,
        internalName:"",
        questionnaireSteps:[],
        langs:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_QUESTIONNAIRE_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditQuestionnaireMutation,{error,loading}] = useMutation(ADD_EDIT_QUESTIONNAIRE,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    questionnaireID: d.AddEditQuestionnaire.questionnaireID,
                    isEdit: (selectedQuestionnaireID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditQuestionnaire);
                
                const notifyText = (selectedQuestionnaireID != 0 ? t("SuccessfullyUpdated") : t("NewQuestionnaireIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("QuestionnaireHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditQuestionnaire = () => {

        let langs = [];
        for(let l of formData.langs)
        {
            langs.push({
                lang: l.lang,
                welcomeMessage: l.welcomeMessage,
            });
        }

        if(formData.internalName)
        {
            const filteredSteps = formData.questionnaireSteps.filter((item) => !item.deleted);

            if(filteredSteps.length > 0)
            {
                console.log(langs);
                AddEditQuestionnaireMutation({
                    variables:{
                        questionnaireID:            selectedQuestionnaireID,
                        projectID:                  projectID,
                        internalName:               formData.internalName,
                        langs:                      langs,
                        questionnaireSteps:         GetQuestionnaireSteps(formData.questionnaireSteps),
                        generateTranslations:       formData.generateTranslations
                    }
                }) 
            }  
            else
                AddNotification(t("QuestionnaireStepsAreNecessary"),false);             
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
    } 

    const GetQuestionnaireSteps = (questionnaireSteps) => {
        
        var newSteps = [];
        if(questionnaireSteps && questionnaireSteps.length > 0)
        {
            for(let val of questionnaireSteps)
            {
                newSteps.push(GetQuestionnaireStepObj(val));
            }
        }

        return newSteps;
    }

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditQuestionnaire,
        SetFormData,
        InitFormData,
        SetFormLangData,
        GetFormLangValue
    }
}

export default UseAddEditQuestionnaire;