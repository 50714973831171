import { 
    SERVER_URL,
    CONTENT_MANAGER_VIDEO_URL,
    CONTENT_MANAGER_VIDEO_POSTER_URL 
} from "../Config";

const Video = ({
    data,
    borderRadiusStyle,
    hideControls,
    videoRefs,
    index,
    autoPlay
}) => {

    return(
        <>
            {data.videoTypeID == 3 ?
                <video 
                    ref = {el => videoRefs ? videoRefs.current[index] = el : null}
                    key = {data.mp4File}
                    controlsList="nodownload nofullscreen" 
                    controls = {data.controls == "1" || ! hideControls ? true : false} 
                    className = {(data.useDimensions == 1  ? "cm-has-dimensions" : "")} 
                    playsInline loop muted 
                    autoPlay = {(autoPlay ? true : false)} 
                    poster = {SERVER_URL + "/" + CONTENT_MANAGER_VIDEO_POSTER_URL + "/maxi_" + data.mp4FilePoster} 
                    style={(borderRadiusStyle ? borderRadiusStyle : null)}
                >
                    <source src={SERVER_URL + "/" + CONTENT_MANAGER_VIDEO_URL + "/" + data.mp4File} type="video/mp4"></source>
                </video>
            :null}

            {data.videoTypeID == 1 ?
                <iframe className={data.className} src={"https://www.youtube.com/embed/" + data.embedCode + (hideControls ? "?controls=0&modestbranding=0&showinfo=0" : "")} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            :null}

            {data.videoTypeID == 2 ?
                <iframe className={data.className} src={"https://player.vimeo.com/video/" + data.embedCode + (hideControls ? "?showinfo=0&controls=0" : "")} allow="autoplay; fullscreen" allowFullScreen></iframe>
            :null}
        </>
    )
}

export default Video;