import {useApolloClient, useQuery} from '@apollo/client';
import { GET_ALL_ADMIN_ROLES } from '../Queries/adminRole';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllAdminRoles = () => {

    const client = useApolloClient();

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedAdminRoleIDs,SetSelectedAdminRoleIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_ALL_ADMIN_ROLES,{
        fetchPolicy:"network-only"
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllAdminRoles);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        }      
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"lis",
                        backgroundColor: val.backgroundColor,
                        text: val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.adminRoleID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,orderID) => {

        var checked = e.target.checked;
        var newSelectedAdminRoleIDs = [...selectedAdminRoleIDs];
        const newList = SelectAdminRoleID(content,orderID,checked,newSelectedAdminRoleIDs)

        SetContent(newList);
        SetSelectedAdminRoleIDs(newSelectedAdminRoleIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedAdminRoleIDs = [];
        const newList = SelectAdminRoleID(content,"all",checked,newSelectedAdminRoleIDs)

        SetSelectedAdminRoleIDs(newSelectedAdminRoleIDs);
        SetContent(newList);
    }

    const SelectAdminRoleID = (list,orderID,checked,selectedAdminRoleIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(orderID == newList[i].rowID || orderID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedAdminRoleIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedAdminRoleIDs.length; j++)
                    {
                        if(selectedAdminRoleIDs[j] == newList[i].rowID)
                            selectedAdminRoleIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectAdminRoleID(newList[i].subData,orderID,checked,selectedAdminRoleIDs)}
        }

        return newList;
    }

    const UpdateListAfterAddEdit = async (selectedAdminRoleID,data) => {
        
        const { AllAdminRoles } = client.readQuery({ query: GET_ALL_ADMIN_ROLES });
        var newArray = [...AllAdminRoles];

        if(selectedAdminRoleID && selectedAdminRoleID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].adminRoleID == data.adminRoleID)
                {
                    var d = {...newArray[i],
                        name: data.name
                    }

                    newArray[i] = d;
                }
            } 
        }
        else
        {
            newArray.push({
                adminRoleID: data.adminRoleID,
                name: data.name
            })
        }
        
        client.writeQuery({ 
            query:GET_ALL_ADMIN_ROLES,
            data:{
                AllAdminRoles: newArray
            } 
        });
    }

    const UpdateListAfterDelete = async (IDs) => {

        var response = IDs.split(",");
        
        const { AllAdminRoles} = client.readQuery({ query: GET_ALL_ADMIN_ROLES });
        
        var newArray = [...AllAdminRoles];
        
        for(let id of response)
        {
            for(let i in newArray)
            {
                if(newArray[i].adminRoleID == id)
                {
                    newArray.splice(i,1);
                }
            } 
        } 
        
        client.writeQuery({ 
            query:GET_ALL_ADMIN_ROLES,
            data:{
                AllAdminRoles: newArray
            } 
        });
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedAdminRoleIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    }

}

export default UseGetAllAdminRoles;