import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { PUBLISH_ARTICLE,UNPUBLISH_ARTICLE } from "../Queries/article";
import { useTranslation } from "react-i18next";

const UsePublishArticle = (articleID,formData,OnCompleted) => {

    const {t} = useTranslation();
    const [showPublishNotifi, SetShowPublishNotifi] = useState(false);
    const [showUnPublishNotifi, SetShowUnPublishNotifi] = useState(false);
    const {AddNotification} = UseNotification();

    const [PublishArticleMutation,{loading}] = useMutation(PUBLISH_ARTICLE,{
        onCompleted:(d) => {

            if(d.PublishArticle.error && d.PublishArticle.error.length > 0)
            {
                var err = t("ArticleHasNotBeenPublished") + "<br /><br />";
                for(let val of d.PublishArticle.error)
                {
                    err += val.project + " (" + val.lang + ")" + ": " + t(val.message) + "<br /><br />";
                }

                AddNotification(err,false);
            }
            else
            {
                AddNotification(t("AndTheyArePublic"),true);
                if(OnCompleted)
                    OnCompleted(d.PublishArticle);
            }

            
        },
        onError:(err) => {          
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const [UnPublishArticleMutation,{loading:unLoading}] = useMutation(UNPUBLISH_ARTICLE,{
        onCompleted:(d) => {

            AddNotification(t("AndTheyAreNotPublic"),true);
            if(OnCompleted)
                OnCompleted(d.UnPublishArticle);
        },
        onError:(err) => {          
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const ShowPublishNotification = () => {
        if(formData.selectedProjectsForPublication.length > 0)
            SetShowPublishNotifi(true);
        else
            AddNotification(t("NoProjectsSelectedForPublication"),false);
    }

    const ShowUnPublishNotification = () => {
        if(formData.selectedProjectsForUnPublication.length > 0)
            SetShowUnPublishNotifi(true);
        else
            AddNotification(t("NoProjectsSelectedForUnPublication"),false);
    }

    const PublishArticle = (action) => {

        if(action)
        {
            var langs = [formData.selectedLang];
            var projectIDs = [];

            if(formData.publishAllLangs)
            {
                langs = [];
                for(let val of formData.langs)
                {
                    langs.push(val.lang);
                }
            }

            for(let val of formData.selectedProjectsForPublication)
            {
                projectIDs.push(val.projectID);
            }

            PublishArticleMutation({
                variables:{
                    articleID,
                    langs,
                    sendNewsletter:formData.sendNewsletterWhenPublishing ? true : false,
                    projectIDs
                }
            });
        }

        SetShowPublishNotifi(false);
    }

    const UnPublishArticle = (action) => {
        if(action)
        {
            var langs = [formData.selectedLang];
            var projectIDs = [];

            if(formData.unPublishAllLangs)
            {
                langs = [];
                for(let val of formData.langs)
                {
                    langs.push(val.lang);
                }
            }

            for(let val of formData.selectedProjectsForUnPublication)
            {
                projectIDs.push(val.projectID);
            }

            UnPublishArticleMutation({
                variables:{
                    articleID,
                    langs,
                    projectIDs
                }
            });
        }

        SetShowUnPublishNotifi(false);
    }

    const IsArticlePublishedInSelectedLangAndProject = (projectID) => {
        
        let langs  = formData.langs;
        for(let l of langs)
        {
            if(formData.selectedLang == l.lang)
            {
                var published = false;
                for(let p of l.projectData)
                {
                    if(p.projectID == projectID && p.isPublished == 1)
                        published = true;
                }

                return published;
            }
        }
        return false;
    }

    const IsArticlePublishedInAllLangsAndProject = (projectID) => {
        
        var published = true;

        let langs  = formData.langs;
        for(let l of langs)
        {
            var checked = false;
            for(let p of l.projectData)
            {
                if(p.projectID == projectID && p.isPublished == 1)
                    checked = true;
            }

            if(!checked)
                published = false;
        }
        return published;
    }

    const IsArticlePublishedInAnyLangsAndProject= (projectID) => {
        
        var published = false;

        let langs  = formData.langs;
        for(let l of langs)
        {
            for(let p of l.projectData)
            {
                if(p.projectID == projectID && p.isPublished == 1)
                    published = true;     
            }
        }
        return published;
    }

    return{
        loading,
        unLoading,
        showPublishNotifi,
        showUnPublishNotifi,
        SetShowPublishNotifi,
        SetShowUnPublishNotifi,
        ShowPublishNotification,
        ShowUnPublishNotification,
        PublishArticle,
        UnPublishArticle,
        IsArticlePublishedInAllLangsAndProject,
        IsArticlePublishedInSelectedLangAndProject,
        IsArticlePublishedInAnyLangsAndProject
    }
}

export default UsePublishArticle;