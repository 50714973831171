import Input from '../../../GlobalComponents/Input';
import RadioButton from '../../../GlobalComponents/RadioButton';
import eye from '../../../Media/Images/Icons/eye.webp';
import InputCard from '../../../GlobalComponents/InputCard';
import SelectBox from '../../../GlobalComponents/SelectBox';
import { useTranslation } from 'react-i18next';

const LinkForm = ({
    formData,
    allLinks,
    GetFormLangValue,
    SetFormLangData,
    SetFormData
}) => {

    const {t} = useTranslation();

    return(  
        <div className="row">
            <div className={"col-50"}>
                        
                <div className="form-group">
                    <label>*{t("LinkName")} ({formData.selectedLang})</label>
                    <Input
                        name="name"
                        value={GetFormLangValue("name")}
                        OnChange={(e) => SetFormLangData(e)}
                    />
                </div>
            </div>
            <div className="col-50">
                <div className="form-group">
                    <label>*{t("ParentLink")}</label>
                    <SelectBox 
                        value={formData.parentID}
                        OnChange={(value) => SetFormData("parentID",value)}
                        nameParam = "name"
                        valueParam = "linkID"
                        subParam = "subLink"
                        items={allLinks}
                    />
                </div>
            </div>
            
            <div className="col-100">
                <div className="form-group">
                    <label>{t("OwnURL")} ({formData.selectedLang})</label>
                    <Input
                        name="ownUrl"
                        value={GetFormLangValue("ownUrl")}
                        OnChange={(e) => SetFormLangData(e)}
                    />
                </div>
            </div>
            
            <div className="col-100">
                <InputCard
                    title = {t("Display")}
                    icon = {eye}
                    className = {"form-group"}
                >   
                    <div className="row">
                        <div className="col-25">
                            <div className="form-group">
                                
                                <label>{t("Active")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="active"
                                        id="active-1"
                                        value={1}
                                        checked = {formData.active === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="active"
                                        id="active-2"
                                        value={0}
                                        checked = {formData.active === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-25">
                            <div className="form-group">
                                
                                <label>{t("InHeader")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="inHeader"
                                        id="inHeader-1"
                                        value={1}
                                        checked = {formData.inHeader === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="inHeader"
                                        id="inHeader-2"
                                        value={0}
                                        checked = {formData.inHeader === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-25">
                            <div className="form-group">
                                
                                <label>{t("InFooter")}</label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="inFooter"
                                        id="inFooter-1"
                                        value={1}
                                        checked = {formData.inFooter === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="inFooter"
                                        id="inFooter-2"
                                        value={0}
                                        checked = {formData.inFooter === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-25">
                            <div className="form-group">
                                
                                <label>{t("ShowArticles")}</label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="isBlog"
                                        id="isBlog-1"
                                        value={1}
                                        checked = {formData.isBlog === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="isBlog"
                                        id="isBlog-2"
                                        value={0}
                                        checked = {formData.isBlog === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>    
                        <div className="col-25">
                            <div className="form-group">
                                
                                <label>{t("HideMenu")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="hideMenu"
                                        id="hideMenu-1"
                                        value={1}
                                        checked = {formData.hideMenu === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="hideMenu"
                                        id="hideMenu-2"
                                        value={0}
                                        checked = {formData.hideMenu === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>       
                        <div className="col-33">
                            <div className="form-group">
                                
                                <label>{t("IsThisTermsAndConditions")}</label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="isThisTermsAndConditions"
                                        id="isThisTermsAndConditions-1"
                                        value={1}
                                        checked = {formData.isThisTermsAndConditions === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="isThisTermsAndConditions"
                                        id="isThisTermsAndConditions-2"
                                        value={0}
                                        checked = {formData.isThisTermsAndConditions === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div> 
                        <div className="col-33">
                            <div className="form-group">
                                
                                <label>{t("IsThisPersonalInformation")}</label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="isThisPersonalInformation"
                                        id="isThisPersonalInformation-1"
                                        value={1}
                                        checked = {formData.isThisPersonalInformation === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="isThisPersonalInformation"
                                        id="isThisPersonalInformation-2"
                                        value={0}
                                        checked = {formData.isThisPersonalInformation === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>                   
                    </div> 
                </InputCard>
            </div>
        </div>
    )
}

export default LinkForm;