import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins } from "../../Library/functions";
import TinyMCEEditor from '../TinyMCEEditor';

const Button = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {
        showHelpers,
        projectData,
        indexToShowMenu,
        SelectElement,
        SetSettings
    } = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    const buttonBgColor = projectData.buttonBgColor.split("-");

    var margins = GetElementMargins(data);

    const OnEditorChange = (content) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu,"text",content,"button")
    }

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "button"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={"cm-button-content cm-d-flex cm-justify-content-" + data.buttonAlign + " " + margins.styleClassName} style={margins.styles}>
                <button 
                    className={"btn " + data.size + " " + data.color + " " + data.className + (buttonBgColor.length > 1 ? " gradient" : "")} 
                >
                    <TinyMCEEditor 
                        value = {data.text}
                        OnEditorChange={(content) => OnEditorChange(content)}
                        toolbar = 'bold italic forecolor removeformat'
                        forcedRootBlock = {"div"}
                    />
                </button>
                {data.dividingLine == "1" ?
                    <div className="cm-button-delimiter"></div>
                :null}
            </div>

            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Button;