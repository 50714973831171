import { useQuery } from "@apollo/client";
import { GET_MEMBERSHIP_TYPE } from "../Queries/membership";
import { useState } from "react";
import { CompleteLangsArray, GetMainLanguage } from "../../../GlobalFunctions/helper";

const UseGetMembershipType = (selectedMembershipID,selectedMembershipTypeID,lang,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allMembershipTypes, SetAllMembershipTypes] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_MEMBERSHIP_TYPE,{
        variables:{
            membershipTypeID:selectedMembershipTypeID,
            membershipID:selectedMembershipID,
            lang:lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:""
            }

            if(selectedMembershipTypeID != 0)
            {
                let langs = CompleteLangsArray(d.MembershipTypeWithLangs.langs,d.AllLanguageMutations,emptyLang);
                
                CallBack({
                    showForPurchaseOnWeb:       d.MembershipTypeWithLangs.showForPurchaseOnWeb,
                    showForPurchaseInPortal:    d.MembershipTypeWithLangs.showForPurchaseInPortal,
                    numberOfRepetitions:        d.MembershipTypeWithLangs.numberOfRepetitions,
                    lengthOfOneRepetition:      d.MembershipTypeWithLangs.lengthOfOneRepetition,
                    langs:                      langs,
                    prices:                     GetPrices(d.MembershipTypeWithLangs.prices),
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllMembershipTypes(d.AllMembershipTypes);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    const GetPrices = (membershipTypePrices) => {

        if(membershipTypePrices)
        {
            var newMembershipTypePrices = [];
            for(let val of membershipTypePrices)
            {
                newMembershipTypePrices.push({
                    membershipTypePriceID:val.membershipTypePriceID,
                    connectedMembershipTypeID:val.connectedMembershipTypeID,
                    connectedMembershipTypePriceID:val.connectedMembershipTypePriceID,
                    isMain:val.isMain == 1 ? true : false,
                    langs:val.langs
                });
            }

            return newMembershipTypePrices;
        }
        else
            return [];
    }

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allMembershipTypes,
        mainLang
    }

}

export default UseGetMembershipType;