const Form = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M368.545,12.798C360.304,4.545,349.337,0,337.667,0H109c-2.918,0-5.715,1.159-7.778,3.222l-98,98   C1.159,103.285,0,106.083,0,109v359.333C0,492.411,19.589,512,43.667,512h228.667v-22H43.667C31.72,490,22,480.28,22,468.333v-332   h70.666c24.078,0,43.667-19.589,43.667-43.667V22h201.333c5.789,0,11.227,2.253,15.322,6.355c4.091,4.086,6.345,9.524,6.345,15.312   v277.667h22V43.667C381.333,31.997,376.789,21.031,368.545,12.798z M114.334,92.667c0,11.947-9.72,21.667-21.667,21.667H22v-0.777   L113.556,22h0.777V92.667z"/>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}}  
                            d="M256,218H92.666c-6.075,0-11-4.925-11-11c0-6.075,4.925-11,11-11H256c6.075,0,11,4.925,11,11    C267,213.075,262.075,218,256,218z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M174.333,283.333H92.666c-6.075,0-11-4.925-11-11s4.925-11,11-11h81.667c6.075,0,11,4.925,11,11    S180.409,283.333,174.333,283.333z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}}  
                            d="M174.333,348.667H92.666c-6.075,0-11-4.925-11-11s4.925-11,11-11h81.667c6.075,0,11,4.925,11,11    S180.409,348.667,174.333,348.667z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M174.333,414H92.666c-6.075,0-11-4.925-11-11s4.925-11,11-11h81.667c6.075,0,11,4.925,11,11S180.409,414,174.333,414z"/>
                    </g>
                    <path 
                        style={{"fill":secondaryColor}}  
                        d="M501,310.333H239.667c-6.075,0-11,4.925-11,11V501c0,6.075,4.925,11,11,11H501   c6.075,0,11-4.925,11-11V321.333C512,315.258,507.075,310.333,501,310.333z M488.778,332.333l-118.444,74.028l-118.445-74.028   H488.778z M250.667,490V357.513l113.837,71.148c1.784,1.115,3.807,1.672,5.83,1.672c2.023,0,4.046-0.557,5.83-1.672L490,357.513   V490H250.667z"/>
                </g>
                
            </svg>
        </>
    )
}

export default Form;
