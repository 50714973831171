import { useTranslation } from 'react-i18next';
import Input from '../Input';
import Button from '../Button';
import { useState,useRef,useEffect  } from 'react';
import AllIcons from '../Icons/AllIcons';

const ListRowOptions = ({options,data,priority,SetShowOptions}) => {

    const ref = useRef();

    const {t} = useTranslation();
    const [newPriority, SetPriority] = useState("");
    const [optionPosition, SetOptionPosition] = useState({});

    useEffect(() => {

        if(ref.current)
        {
            const listContent = ref.current.closest(".list-content");
            const lContentClientHeight = listContent.getBoundingClientRect().y;
            const optionsClientHeight = ref.current.getBoundingClientRect().y;

            var height = listContent.offsetHeight;

            var optionsHeight = ref.current.offsetHeight;
            if((optionsClientHeight - lContentClientHeight) + optionsHeight > height){
                SetOptionPosition({"top": "-"+(optionsHeight + 5) + "px"});
            }
        }

    },[ref.current])

    const {
        OnContentClick,
        OnEditClick,
        OnCopyClick,
        OnDeleteClick,
        OnPriorityClick,
        OnConfirmClick,
        OnInvoiceClick,
        OnCancelClick
    } = options;

    return(
        <div 
            className = {"list-row-options "}
            ref = {ref}
            style={optionPosition}
        >
            {OnContentClick ?
                <div 
                    className="options-row" 
                    onClick={() => {SetShowOptions(false); OnContentClick(data.rowID)}}
                >
                    <AllIcons name="content" />
                    {t("Content")}
                </div>
            :null}
            {OnEditClick ?
                <div 
                    className="options-row" 
                    onClick={() => {SetShowOptions(false); OnEditClick(data.rowID)}}
                >
                    <AllIcons name="edit" />
                    {t("Edit")}
                </div>
            :null}
            {OnCopyClick ?
                <div 
                    className="options-row" 
                    onClick={() => {SetShowOptions(false); OnCopyClick(data.rowID)}}
                >
                    <AllIcons name="copy" />
                    {t("Copy")}
                </div>
            :null}
            {OnDeleteClick ?
                <div
                    className="options-row"  
                    onClick={() => {SetShowOptions(false); OnDeleteClick(data.rowID)}}
                >
                    <AllIcons name="delete" />
                    {t("Delete")}
                </div>
            :null}
            {OnConfirmClick && data.showConfirmClick ?
                <div
                    className="options-row"  
                    onClick={() => {SetShowOptions(false); OnConfirmClick(data.rowID)}}
                >
                    <AllIcons name="tickInCircle" />
                    {t("Confirm")}
                </div>
            :null}
            {OnInvoiceClick ?
                <div
                    className="options-row"  
                    onClick={() => {SetShowOptions(false); OnInvoiceClick(data.rowID)}}
                >
                    <AllIcons name="invoice" />
                    {t("Invoice")}
                </div>
            :null}
            {OnCancelClick ?
                <div
                    className="options-row"  
                    onClick={() => {SetShowOptions(false); OnCancelClick(data.rowID)}}
                >
                    <AllIcons name="cancel" primaryColor={"#D28E5D"} secondaryColor={"#D28E5D"} />
                    {t("Cancel")}
                </div>
            :null}
            {OnPriorityClick ?
                <div className="options-row no-hover">
                    <div>
                        {t("Priority")}
                        <div className="d-flex">

                            <Input 
                                value={newPriority} 
                                type="number" 
                                OnChange={(e) =>SetPriority(e.target.value)}
                            />
                            <Button 
                                OnClick={() => {SetShowOptions(false); OnPriorityClick(data.rowID,newPriority)}}
                                className="btn-primary"
                            >
                                <AllIcons name="move" />
                            </Button>
                        </div>
                    </div>
                </div>
            :null}
        </div>
    )
}

export default ListRowOptions;