import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_ADMIN_ROLE } from "../Queries/adminRole";

const UseAddEditAdminRole = (selectedAdminRoleID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,OpenImage} = UseFormHandle({
        name:"",
        modules:[],
        projects:[]
    });

    const [AddEditAdminRoleMutation,{error,loading}] = useMutation(ADD_EDIT_ADMIN_ROLE,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditAdminRole);

            const notifyText = (selectedAdminRoleID != 0 ? t("SuccessfullyUpdated") : t("NewAdminRoleIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditAdminRole = () => {

        if(formData.name)
        {   
            AddEditAdminRoleMutation({
                variables:{
                    adminRoleID:    selectedAdminRoleID,
                    name:           formData.name,
                    modules:        formData.modules,
                    projects:       formData.projects
                }
            })  
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const SetProject = (projectID,checked) => {

        var newProjects = [...formData.projects];
        
        if(checked)
            newProjects.push(projectID);
        else
        {
            const index = newProjects.indexOf(projectID);
            if(index != -1)
                newProjects.splice(index,1);
        }
        
        SetFormData("projects",newProjects);
    }

    const SetModule = (moduleID,subModules,checked) => {

        var newModules = [...formData.modules];
        
        if(checked)
            newModules.push(moduleID);
        else
        {
            const index = newModules.indexOf(moduleID);
            if(index != -1)
                newModules.splice(index,1);

            if(subModules && subModules.length > 0)
            {
                for(let val of subModules)
                {
                    const i = newModules.indexOf(val.moduleID);
                    if(i != -1)
                        newModules.splice(i,1);
                }
            }
        }
        
        SetFormData("modules",newModules);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditAdminRole,
        SetFormData,
        InitFormData,
        OpenImage,
        SetModule,
        SetProject
    }

}

export default UseAddEditAdminRole;