import { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditReservationPlace from '../Library/UseAddEditReservationPlace';
import UseGetReservationPlace from '../Library/UseGetReservationPlace';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';
import dayjs from 'dayjs';
import BasicInformations from './BasicInformations';
import { useEffect } from 'react';
import { 
    SERVER_URL,
    RESERVATION_PLACE_IMAGE_URL
} from '../../../Config';
import TimesForReservations from './TimesForReservations';
import { UseAuth } from '../../Auth/Library/UseAuth';

Modal.setAppElement('#root');

const AddEditReservationPlace = ({selectedReservationPlaceID,SetSelectedReservationPlaceID,OnAddEditSuccess}) => {

    const {user} = UseAuth();
    const {t} = useTranslation();
    const [selectedTab, SetSelectedTab] = useState(1);

    var itemsToTranslate = [
        {name:"Název v rámci projektu",param:"name"}
    ];

    const {
        allLanguageMutations,
        allProjects,
        mainLang,
        loading:getLoading,
        error:getError
    } = UseGetReservationPlace(selectedReservationPlaceID,user.lang,(d) => {

        var fileVersion = "";
        if(d.dateEdit != "0000-00-00 00:00:00")
            fileVersion = "?" + dayjs(d.dateEdit).format("YYYYMMDDHHmmss");

        var initData = {...d};
        initData.mainPhoto =  d.mainPhoto ? SERVER_URL + "/" + RESERVATION_PLACE_IMAGE_URL + "/stredni_" + d.mainPhoto + fileVersion : "";

        InitFormData(initData);
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        selectedProjectID,
        AddEditReservationPlace,
        InitFormData,
        SetFormData,
        OpenImageToArrayOfObject,
        SetSelectedProjectID,
        GetFormArrayValueByParam,
        GetFormLangArrayValue,
        SetFormLangArrayData,
        OpenMedia,
        SetFormDataSelectedItemsWithObjAndIndex,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj
    } = UseAddEditReservationPlace(selectedReservationPlaceID,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    useEffect(() => {
        if(allProjects && allProjects.length > 0 )
            SetSelectedProjectID(allProjects[0].projectID);
    },[allProjects])

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    console.log(formData);

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedReservationPlaceID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {selectedReservationPlaceID === 0 ? t("AddReservationPlace") : t("EditReservationPlace")}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedReservationPlaceID === 0 ? false : true}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedReservationPlaceID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        :  
                            <>
                                <ul className="tabs">
                                    <li 
                                        className={(selectedTab == 1 ? "selected" : "")} 
                                        onClick = {() => SetSelectedTab(1)}
                                    >{t("BasicInformations")}</li>
                                    <li 
                                        className={(selectedTab == 2 ? "selected" : "")} 
                                        onClick = {() => SetSelectedTab(2)}
                                    >{t("TimesForReservations")}</li>
                                </ul>
                                {selectedTab == 1 ?
                                    <BasicInformations 
                                        formData = {formData}
                                        allProjects = {allProjects}
                                        selectedProjectID = {selectedProjectID}
                                        SetFormData = {SetFormData}
                                        OpenMedia = {OpenMedia}
                                        SetSelectedProjectID = {SetSelectedProjectID}
                                        OpenImageToArrayOfObject = {OpenImageToArrayOfObject}
                                        GetFormLangArrayValue = {GetFormLangArrayValue}
                                        SetFormLangArrayData = {SetFormLangArrayData}
                                        GetFormArrayValueByParam = {GetFormArrayValueByParam}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                    />
                                :null} 
                                {selectedTab == 2 ?
                                    <TimesForReservations 
                                        formData = {formData}
                                        SetFormDataSelectedItemsWithObjAndIndex = {SetFormDataSelectedItemsWithObjAndIndex}
                                    />
                                :null}    
                                
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditReservationPlace()}
                    >
                        {(selectedReservationPlaceID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditReservationPlace;