import { Fragment } from "react";
import ListRow from "./ListRow";
import Button from "../Button";
import ListPagging from "./ListPagging";
import { useTranslation } from "react-i18next";

const ListContent = ({
    index,
    rowClassname,
    addRowButtonTitle,
    AddRowButtonOnClick,
    data,
    options,
    hideOptions,
    optionsClassName,
    level,
    totalContentLength, 
    paggingOffset,
    paggingLimit,
    FetchMore, 
    GoToPage,
    replaceDotsImg,
    ReplaceDotsOnClick
}) => {

    const {t} = useTranslation();

    return(
        <>
            {data && data.map((item,ind) => {

                if(item.deleted)
                    return "";
                else
                    return(
                        <Fragment key={ind} >
                            <ListRow 
                                rowClassname = {rowClassname}
                                parentIndex = {index}
                                data = {item}
                                options = {options} 
                                hideOptions = {hideOptions}
                                optionsClassName = {optionsClassName}
                                level = {level}
                                replaceDotsImg = {replaceDotsImg}
                                ReplaceDotsOnClick = {ReplaceDotsOnClick}
                                paggingOffset = {paggingOffset}
                                index = {ind}
                            />
                            {item.subData && item.subData.length > 0 ?
                                <ListContent 
                                    rowClassname = {rowClassname}
                                    addRowButtonTitle = {addRowButtonTitle}
                                    AddRowButtonOnClick = {AddRowButtonOnClick}
                                    data = {item.subData} 
                                    options = {options} 
                                    hideOptions = {hideOptions}
                                    optionsClassName = {optionsClassName}
                                    level = {level + 1}
                                    replaceDotsImg = {replaceDotsImg}
                                    ReplaceDotsOnClick = {ReplaceDotsOnClick}
                                    index = {ind}
                                />
                            :null}
                        </Fragment>       
                    )
            })}

            {addRowButtonTitle && AddRowButtonOnClick ?
                <div className={"list-row"}>
                    <Button
                        className="btn-primary narrow"
                        OnClick={() => AddRowButtonOnClick(level,index)}
                    >{addRowButtonTitle}</Button>
                </div>
            :null}
            
            {GoToPage || FetchMore ?
                <div className="list-fetch-more">
                    {GoToPage ?
                        <ListPagging 
                            GoToPage = {GoToPage} 
                            paggingOffset = {paggingOffset}
                            paggingLimit = {paggingLimit}
                            totalContentLength = {totalContentLength}
                            dataLength = {data.length}
                        />
                    :null}
                    {FetchMore && totalContentLength && totalContentLength > paggingOffset + data.length ?
                        <Button 
                            className = "btn-primary narrow"
                            OnClick = {() => FetchMore(paggingOffset + data.length)}
                        >{t("LoadMore")}</Button>
                    :null}
                </div>
            :null}
        </>                
    )
}

export default ListContent;