import Modal from 'react-modal';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditOrder from '../Library/UseAddEditOrder';
import UseGetOrder from '../Library/UseGetOrder';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText, GetPriceString } from '../../../GlobalFunctions/helper';
import InputCard from '../../../GlobalComponents/InputCard';
import contactIcon from '../../../Media/Images/Icons/contact.webp';
import packageIcon from '../../../Media/Images/Icons/package.webp';
import creditCardIcon from '../../../Media/Images/Icons/credit_card.webp';
import dayjs from 'dayjs';
import List from '../../../GlobalComponents/List/List';
import UseGetAllOrderItems from '../Library/UseGetAllOrderItems';


Modal.setAppElement('#root');

const AddEditOrder = ({selectedOrderID,SetSelectedOrderID,OnAddEditSuccess}) => {

    const {t} = useTranslation();
    const {
        paymentHeaderData,
        paymentContent,
        loading,
        error,
        formData,
        InitFormData,
    } = UseAddEditOrder(selectedOrderID,(d) => OnAddEditSuccess(d));

    const {
        loading:getLoading,
        error:getError
    } = UseGetOrder(selectedOrderID,(d) => {
        InitFormData(d)
    });

    const {headerData,content} = UseGetAllOrderItems(selectedOrderID,formData.lang);

    var totalPrice = 0;
    if(content && content.length > 0)
    {
        for(let val of content)
        {
            totalPrice += val.totalProductPrice;
        }
    }
    
    if(formData.shippingType)
        totalPrice += formData.shippingTypePrice;

    if(formData.couponID != 0)
        totalPrice -= formData.couponDiscountPrice;

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedOrderID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("OrderDetail")}
                OnClose ={() => SetSelectedOrderID(null)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                <div>
                                    <p><strong>{t("OrderNumber")}</strong>: {formData.orderNumber}</p>
                                </div>
                                <div className="form-group">
                                    <p><strong>{t("OrderDate")}</strong>: {(
                                        formData.orderDate == "0000-00-00 00:00:00" ?
                                            t("NoOrderedYet")
                                        :
                                            dayjs(formData.orderDate).format("DD.MM.YYYY HH:mm:ss")
                                        )}
                                    </p>
                                </div>
                                <div className="row form-group">

                                    <div className='col-33'>
                                        <InputCard
                                            title = {t("InvoiceData")}
                                            icon = {contactIcon}
                                            className="flex-1"
                                        >   
                                            {formData.name && formData.surname ?
                                                <div className="form-group">
                                                    <p>{formData.name} {formData.surname}</p>
                                                    <p>{formData.street}</p>   
                                                    <p>{formData.zip}, {formData.city}</p>                      
                                                </div>
                                            :null}
                                            <div className="form-group">
                                                <p><strong>{t("Email")}</strong>: {formData.email}</p>
                                                <p><strong>{t("Tel")}</strong>: {formData.tel}</p>                      
                                            </div>
                                        </InputCard>
                                    </div>
                                    {formData.deliveryName && formData.deliverySurname ?
                                        <div className='col-33'>
                                            <InputCard
                                                title = {t("DeliveryData")}
                                                icon = {contactIcon}
                                            >   
                                                <div className="form-group">
                                                    <p>{formData.deliveryName} {formData.deliverySurname}</p>
                                                    <p>{formData.deliveryStreet}</p>   
                                                    <p>{formData.deliveryZip}, {formData.deliveryCity}</p>                      
                                                </div>
                                            </InputCard>
                                        </div>
                                    :null}
                                    {formData.company ?
                                        <div className='col-33'>
                                            <InputCard
                                                title = {t("CompanyData")}
                                                icon = {contactIcon}
                                            >   
                                                <div className="form-group">
                                                    <p>{formData.company}</p>
                                                    <p>IČ: {formData.ic}</p>   
                                                    <p>DIČ: {formData.dic}</p>  
                                                    {formData.lang == "sk" ?      
                                                        <p>IČDPH: {formData.icdph}</p>  
                                                    :null}             
                                                </div>
                                            </InputCard>
                                        </div>
                                    :null}
                                    
                                </div> 

                                <InputCard
                                    className = "form-group"
                                    title = {t("Products")}
                                    icon = {packageIcon}
                                    noBodyPadding = {true}
                                    canBeMinimalized = {false}
                                >   
                                    <List 
                                        headerData = {headerData} 
                                        headerClassName = {"no-top-border"}
                                        contentData = {content}
                                        isSortable = {false}
                                        loading = {false}
                                        hideOptions = {true}
                                    />
                                
                                </InputCard>

                                <InputCard
                                    className = "form-group"
                                    title = {t("PaymentsAndDelivery")}
                                    icon = {creditCardIcon}
                                    noBodyPadding = {true}
                                    canBeMinimalized = {false}
                                >   
                                    <List 
                                        headerData = {paymentHeaderData} 
                                        headerClassName = {"no-top-border"}
                                        contentData = {paymentContent}
                                        isSortable = {false}
                                        loading = {false}
                                        hideOptions = {true}
                                    />
                                
                                </InputCard>

                                <InputCard
                                    title = {t("TOTAL")}
                                    className="flex-1"
                                    headerChildren = {
                                        <strong className="fs-18">{GetPriceString(totalPrice,formData.lang)} </strong>
                                    }
                                ></InputCard>
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">

            </div>

        </Modal>
    )
}

export default AddEditOrder;