import {useApolloClient, useQuery} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import YesNo from '../../../GlobalComponents/YesNo';
import { GET_ALL_SCHEDULED_NEWSLETTERS } from '../Queries/newsletter';
import dayjs from 'dayjs';
import { PROJECT_IMAGE_URL, SERVER_URL } from '../../../Config';
import global from '../../../Media/Images/Icons/global.webp';

const UseGetAllScheduledNewsletters = (lang) => {

    const client = useApolloClient();

    const [variables,SetVariables] = useState({
        lang
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedScheduledNewsletterIDs,SetSelectedScheduledNewsletterIDs] = useState([]);

    const {data,loading,error,refetch} = useQuery(GET_ALL_SCHEDULED_NEWSLETTERS,{
        variables,
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllScheduledNewsletters);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("Subject"),
            className:"flex-1"
        },
        {
            value: t("Project"),
            className:"static text-center"
        },
        {
            value: t("IsForCampaign"),
            className:"static text-center"
        },
        {
            value: t("ScheduledDate"),
            className:"static text-center"
        }
        
    ];

    const GetContentData = (data) => {

        var contentData = [];

        console.log(data);

        for(let val of data)
        {
            var imgSrc = global;
            var imgTitle = t("Global");

            if(val.projectID && val.projectID != "0")
            {
                imgSrc = SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + val.project.favicon
                imgTitle = val.project.name;
            }

            var cData = {
                data:[
                    {
                        value:val.subject,
                        className:"flex-1"
                    },
                    {
                        elm:"img",
                        src: imgSrc,
                        title: imgTitle,
                        className:"static text-center justify-content-center d-flex"
                    },
                    {
                        value: <YesNo isYes = {val.campaignID && val.campaignID != "0" ? true : false} />,
                        className:"static text-center"
                    },
                    {
                        value: dayjs(val.plannedDate).format("DD.MM.YYYY"),
                        className:"static text-center"
                    }
                    
                ],
            }

            cData.rowID    = val.newsletterID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,newsletterID) => {

        var checked = e.target.checked;
        var newSelectedScheduledNewsletterIDs = [...selectedScheduledNewsletterIDs];
        const newList = SelectScheduledNewsletterID(content,newsletterID,checked,newSelectedScheduledNewsletterIDs)

        SetContent(newList);
        SetSelectedScheduledNewsletterIDs(newSelectedScheduledNewsletterIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedScheduledNewsletterIDs = [];
        const newList = SelectScheduledNewsletterID(content,"all",checked,newSelectedScheduledNewsletterIDs)

        SetSelectedScheduledNewsletterIDs(newSelectedScheduledNewsletterIDs);
        SetContent(newList);
    }

    const SelectScheduledNewsletterID = (list,newsletterID,checked,selectedScheduledNewsletterIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(newsletterID == newList[i].rowID || newsletterID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedScheduledNewsletterIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedScheduledNewsletterIDs.length; j++)
                    {
                        if(selectedScheduledNewsletterIDs[j] == newList[i].rowID)
                            selectedScheduledNewsletterIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectScheduledNewsletterID(newList[i].subData,newsletterID,checked,selectedScheduledNewsletterIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        refetch();   
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedScheduledNewsletterIDs,
        SelectRow,
        SelectAll,
        UpdateList
    }

}

export default UseGetAllScheduledNewsletters;