import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import UseGetAllCovers from "../../Library/Covers/UseGetAllCovers";
import { useState } from "react";
import AddEditCover from "./AddEditCover";
import { UseAuth } from '../../../Auth/Library/UseAuth';
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { useApolloClient } from "@apollo/client";
import UseDeleteCovers from "../../Library/Covers/UseDeleteCovers";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import ContentFilterSidebar from "../../../../GlobalComponents/ContentFilterSidebar";
import CoverFilter from "./CoverFilter";

const Covers = () => {

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedCoverID, SetSelectedCoverID] = useState(null);
    const [showFilterSidebar,SetShowFilterSidebar] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedCoverIDs,
        allManufacturers,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        Filter
    } = UseGetAllCovers(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteCovers
    } = UseDeleteCovers(selectedCoverIDs,(d) => ReFetchCovers(d));

    const ReFetchCovers = async(d) => {
        await client.refetchQueries({
            include: ["AllCovers"],
        });
    }
    
    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("CoverList")}
                        //OnFilterClick = {() => SetShowFilterSidebar(true)}
                        OnAddButtonClick={() => SetSelectedCoverID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                        OnSearch = {(filterData) => Filter(filterData)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (linkID) => SetSelectedCoverID(linkID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />

                    <ContentFilterSidebar
                        isOpen = {showFilterSidebar}
                        OnClose = {() => SetShowFilterSidebar(false)}
                    >
                        <CoverFilter
                            allManufacturers = {allManufacturers}
                            OnFilter = {(filterData) => Filter(filterData)}
                        />
                    </ContentFilterSidebar>
                </>
            }

            {selectedCoverID || selectedCoverID == 0 ?
                <AddEditCover
                    selectedCoverID = {selectedCoverID}
                    SetSelectedCoverID = {SetSelectedCoverID}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteCovers")} 
                    CallBack={DeleteCovers} 
                />
            :null}
        </>
    )

}

export default Covers;