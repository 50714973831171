import InputCard from "../../../GlobalComponents/InputCard";
import { useTranslation } from "react-i18next";
import reservationTimesIcon from '../../../Media/Images/Icons/reservation_times.webp';
import NoItems from "../../../GlobalComponents/NoItems";
import Times from "./Times";
import Button from "../../../GlobalComponents/Button";
import UseTimes from "../Library/UseTimes";
import { useMemo } from "react";

const TimesForReservations = ({
    formData,
    SetFormDataSelectedItemsWithObjAndIndex
}) => {

    const {t} = useTranslation();
    const {GetTimesObject} = UseTimes();

    const AddEmptyTime = () => {

        const obj = GetTimesObject(null);
        SetFormDataSelectedItemsWithObjAndIndex("selectedTimes",obj,formData.selectedTimes.length);

    }

    const filteredTimes = useMemo(() => {
        const filteredData = formData.selectedTimes.filter((item) => item.deleted != true);
        return filteredData;
    },[formData.selectedTimes])

    return(
        <>
            <InputCard
                title = {t("TimesForReservations")}
                icon={reservationTimesIcon}
                headerChildren={
                    <Button
                        className="btn-primary narrow"
                        OnClick={() => AddEmptyTime()}
                    >{t("AddTime")}</Button>
                }
            >

                {filteredTimes && filteredTimes.length > 0 ?
                    (formData.selectedTimes.map((item,index) => {
                        if(!item.deleted)
                            return(
                                <div key={index} className="form-group">
                                    <Times
                                        data = {item}
                                        SetParentIndexData = {(obj) => SetFormDataSelectedItemsWithObjAndIndex("selectedTimes",obj,index)}
                                    />
                                </div>
                            )
                        else
                            return "";
                    }))
                :
                    <NoItems text={t("NoItems")} />
                }
            
            </InputCard>
        </>
    )
}

export default TimesForReservations;