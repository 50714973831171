import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_MEMBERSHIP_TYPE,GENERATE_MEMBERSHIP_TYPE_LANG } from "../Queries/membership";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";

const UseAddEditMembershipType = (
    selectedMembershipTypeID,
    membershipID,
    mainLang,
    allLanguageMutations,
    OnCompleted
) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();

    const {
        formData, 
        SetFormData, 
        SetFormLangData,
        GetFormLangValue,
        SetCompleteFormData,
        SetFormDataSelectedItemsWithObjAndIndex,
        SetBooleanFormDataSelectedItemsWithIndex
    } = UseFormHandle({
        selectedLang:mainLang,
        showForPurchaseOnWeb:1,
        showForPurchaseInPortal:1,
        numberOfRepetitions:0,
        lengthOfOneRepetition:1,
        langs:[],
        prices:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_MEMBERSHIP_TYPE_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditMembershipTypeMutation,{error,loading}] = useMutation(ADD_EDIT_MEMBERSHIP_TYPE,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    membershipTypeID: d.AddEditMembershipType.membershipTypeID,
                    isEdit: (selectedMembershipTypeID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditMembershipType);
                
                const notifyText = (selectedMembershipTypeID != 0 ? t("SuccessfullyUpdated") : t("NewMembershipTypeIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("MembershipTypeHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditMembershipType = () => {

        var checkName = true;
        var checkPrice = true;
        var checkMainPrice = false;

        let langs = [];
        for(const lang of formData.langs)
        {
            if(lang.lang == formData.selectedLang && !lang.name)
            {
                checkName = false;
                break;
            }

            langs.push({
                lang: lang.lang,
                name: lang.name
            });
        }

        for(let pr of formData.prices)
        {
            if(pr.isMain)
                checkMainPrice = true;

            for(let l of pr.langs)
            {
                const price = parseFloat(l.price);
                if((!price && price != 0) || price < 0)
                    checkPrice = false;
            }
        }

        if(checkName)
        {
            if(formData.prices.length > 0)
            {
                if(checkPrice)
                {
                    if(checkMainPrice)
                    {
                        AddEditMembershipTypeMutation({
                            variables:{
                                membershipTypeID:           selectedMembershipTypeID,
                                membershipID:               membershipID,
                                showForPurchaseOnWeb:       parseInt(formData.showForPurchaseOnWeb),
                                showForPurchaseInPortal:    parseInt(formData.showForPurchaseInPortal),
                                numberOfRepetitions:        parseInt(formData.numberOfRepetitions),
                                lengthOfOneRepetition:      parseInt(formData.lengthOfOneRepetition),   
                                langs:                      langs,
                                prices:                     GetPrices(formData.prices), 
                                generateTranslations:       formData.generateTranslations
                            }
                        })
                    }
                    else
                        AddNotification(t("MainPriceIsNecessary"),false);
                }
                else
                    AddNotification(t("PriceIsInvalidInSomeLanguage"),false);
            }
            else
                AddNotification(t("AtLeastOnePriceIsNecessary"),false); 
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const GetPrices = (membershipTypePrices) => {

        if(membershipTypePrices)
        {
            var newMembershipTypeTypePrices = [];
            for(let val of membershipTypePrices)
            {
                var langs = [];
                for(let l of val.langs)
                {
                    langs.push({
                        lang:l.lang,
                        price: parseFloat(l.price),
                        description:l.description
                    })
                }

                newMembershipTypeTypePrices.push({
                    membershipTypePriceID:val.membershipTypePriceID,
                    connectedMembershipTypeID:val.connectedMembershipTypeID,
                    connectedMembershipTypePriceID:val.connectedMembershipTypePriceID,
                    isMain:val.isMain ? 1 : 0,
                    langs:langs
                });
            }

            return newMembershipTypeTypePrices;
        }
        else
            return [];
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditMembershipType,
        SetFormData,
        InitFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObjAndIndex,
        SetBooleanFormDataSelectedItemsWithIndex
    }

}

export default UseAddEditMembershipType;