import { useTranslation } from "react-i18next";
import SearchInput from "../../../../GlobalComponents/SearchInput";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { useEffect } from "react";
import Select from "../../../../GlobalComponents/Select";
import Button from "../../../../GlobalComponents/Button";

const CoverFilter = ({OnFilter,allManufacturers}) => {

    const {t} = useTranslation();

    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle({
        searchText:"",
        manufacturerID:0,
        init:true
    })

    useEffect(() => {

        if(!formData.init)
            OnFilter(formData);

    },[formData])
       
    return(
        <div className="row">

            <div className="col-50">
                <label>{t("SearchCoverByName")}</label>
                <div className="form-group">
                    <SearchInput
                        initValue = {formData.searchText}
                        placeholder = {t("Search...")}
                        OnChange = {(value) => {
                            SetFormData("searchText",value);
                            SetFormData("init",false);
                        }}
                    />
                </div>
            </div>
            <div className="col-50">
                <label>{t("SearchCoverByManufacturer")}</label>
                <div className="form-group">
                    <Select
                        value={formData.manufacturerID}
                        name = "manufacturerID"
                        OnChange = {(e) => {
                            SetFormData("manufacturerID",e.target.value);
                            SetFormData("init",false);
                        }}
                    >
                        {allManufacturers && allManufacturers.map((item,index) => (
                            <option 
                                key={item.manufacturerID}
                                value={item.manufacturerID}
                            >{item.name}</option>
                        ))}
                        
                    </Select>
                </div>
            </div>
            <div className="col-100">
                <Button 
                    className = "btn-brown"
                    OnClick={() => SetCompleteFormData({
                        searchText:"",
                        manufacturerID:0,
                        init:false
                    })}
                >{t("ClearFilter")}</Button>
            </div>

        </div>
    )
}

export default CoverFilter;