import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditArticle from '../Library/UseAddEditArticle';
import UseGetArticle from '../Library/UseGetArticle';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';
import BasicInformations from './BasicInformations';
import { ARTICLE_IMAGE_URL, SERVER_URL } from '../../../Config';
import dayjs from 'dayjs';
import { UseAuth } from '../../Auth/Library/UseAuth';
import RecommnededArticles from './RecommendedArticles';
import ArticleMarketingBanners from './ArticleMarketingBanners';

Modal.setAppElement('#root');

const AddEditArticle = ({selectedArticleID,SetSelectedArticleID,OnAddEditSuccess}) => {

    const {t} = useTranslation();
    const [selectedTab, SetSelectedTab] = useState(1);
    const {user} = UseAuth();
   
    var itemsToTranslate = [
        {name:"Nadpis článku",param:"title"},
        {name:"Perex",param:"perex"},
        {name:"Text",param:"text"},
        {name:"Meta nadpis",param:"metaTitle"},
        {name:"Meta klíčová slova",param:"metaKeywords"},
        {name:"Meta popis",param:"metaDescription"}
    ];

    const {
        loading:getLoading,
        error:getError,
        allLanguageMutations,
        allProjects,
        allForms,
        mainLang,
        refetch
    } = UseGetArticle(selectedArticleID,user.lang,(d) => {

        var fileVersion = "";
        if(d.dateEdit != "0000-00-00 00:00:00")
            fileVersion = "?" + dayjs(d.dateEdit).format("YYYYMMDDHHmmss");

        var initData = {...d};
        initData.mainPhoto =  d.mainPhoto ? SERVER_URL + "/" + ARTICLE_IMAGE_URL + "/stredni_" + d.mainPhoto + fileVersion : "";

        InitFormData(initData) 
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        selectedProjectID,
        AddEditArticle,
        InitFormData,
        SetFormData,
        GetFormLangValue,
        SetFormLangData,
        OpenImage,
        FillFormSelectedItemsWithObj,
        SetSelectedProjectID,
        SetFormDataSelectedItemsWithObjToArray,
        FillFormSelectedItemsWithObjToArray,
        RemoveFormDataSelectedItems
    } = UseAddEditArticle(
        selectedArticleID,
        mainLang.suffix,
        allLanguageMutations,
        (d) => OnAddEditSuccess(d)
    );

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);
    
    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedArticleID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedArticleID === 0 ? t("AddArticle") : t("EditArticle"))}
                OnClose ={() => SetSelectedArticleID(null)}
                allLanguages={allLanguageMutations}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedArticleID === 0 ? false : true}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            (translatedLangsCount != 0 ?
                                <TranslationProgressBar
                                    translatedLang = {translatedLang}
                                    translatedLangsCount = {translatedLangsCount}
                                    totalLangs = {allLanguageMutations.length}
                                />
                            :
                                <Loading />
                            )
                        : 
                            <>
                                <ul className="tabs">
                                    <li 
                                        className={(selectedTab == 1 ? "selected" : "")} 
                                        onClick = {() => SetSelectedTab(1)}
                                    >{t("BasicInformations")}</li>
                                    <li 
                                        className={(selectedTab == 2 ? "selected" : "")} 
                                        onClick = {() => SetSelectedTab(2)}
                                    >{t("RecommendedArtcles")}</li>
                                    <li 
                                        className={(selectedTab == 3 ? "selected" : "")} 
                                        onClick = {() => SetSelectedTab(3)}
                                    >{t("MarketingBanners")}</li>
                                                                        
                                </ul> 

                                {selectedTab == 1 ?
                                    <BasicInformations 
                                        formData = {formData}
                                        selectedArticleID = {selectedArticleID}
                                        allProjects = {allProjects}
                                        selectedProjectID = {selectedProjectID}
                                        allForms = {allForms}
                                        refetch = {refetch}
                                        SetFormData = {SetFormData}
                                        OpenImage = {OpenImage}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormLangData = {SetFormLangData}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                        SetSelectedProjectID = {SetSelectedProjectID}
                                        SetFormDataSelectedItemsWithObjToArray = {SetFormDataSelectedItemsWithObjToArray}
                                        FillFormSelectedItemsWithObjToArray = {FillFormSelectedItemsWithObjToArray}
                                    />
                                :null}   

                                {selectedTab == 2 ?
                                    <RecommnededArticles 
                                        formData = {formData}
                                        selectedArticleID = {selectedArticleID}
                                        SetFormData = {SetFormData}
                                        RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                    />
                                :null}   
                                {selectedTab == 3 ?
                                    <ArticleMarketingBanners 
                                        formData = {formData}
                                        selectedArticleID = {selectedArticleID}
                                        SetFormData = {SetFormData}
                                        RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                    />
                                :null} 
                          
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditArticle()}
                    >
                        {(selectedArticleID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditArticle;