import { useTranslation } from "react-i18next";
import Badge from "./Badge";

const Status = ({status}) => {

    const {t} = useTranslation();

    var color = "";

    switch(status)
    {
        case "COMPLETED": color = "success"; break;
        case "WAITING_FOR_PAYMENT": color = "warning"; break;
        case "WAITING_FOR_REPAYMENT": color = "warning"; break;
        case "WAITING_FOR_PAYMENT_CONFIRMATION": color = "warning"; break;
        case "WAITING_FOR_REPAYMENT_CONFIRMATION": color = "warning"; break;
        case "CREATED": color = "info"; break;
        case "CANCELED": color = "danger"; break;
        default: color = "warning"; break;
    }

    return(
        <>
            {status ?
                <Badge 
                    type = {color}
                    text = {t(status)}
                />
            :null}
        </>
        
    )
}

export default Status;