import React,{ useRef, useState } from 'react';
import Slider from "react-slick";
import Video from '../../../../../GlobalComponents/Video';
import arrowLeftIcon from '../../../../../Media/Images/Icons/white_arrow_left.webp';
import slideshowDarkBg from '../../../../../Media/Images/Videogallery/slideshow_dark.webp';
import slideshowLightBg from '../../../../../Media/Images/Videogallery/slideshow_light.webp';
import slideshowAcBg from '../../../../../Media/Images/Videogallery/slideshow_ac.webp';
import slideshowAcPlay from '../../../../../Media/Images/Videogallery/slideshow_ac_play.webp';

const VideoGallerySlideshow = ({
    selectedVideos,
    videoTypeID,
    slideshowTheme
}) => {

    var [actualVideo, SetActualVideo] = useState(0);
    var [playing, SetPlaying] = useState(false);

    var sliderRef = useRef();
    var videoRefs = useRef([]);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: false,
        arrows:false
    };

    const Next = () => {

        if(selectedVideos.length > actualVideo + 1)
        {
            if(videoTypeID == 3) 
                videoRefs.current[actualVideo].pause();  

            sliderRef.current.slickNext();

            SetActualVideo((prevValue) => {
                if(videoTypeID == 3) 
                    videoRefs.current[prevValue + 1].play();
                return prevValue + 1;
            });
        }
    }
    const Previous = () => {

        
        if(actualVideo > 0)
        {    
            if(videoTypeID == 3)  
                videoRefs.current[actualVideo].pause();

            sliderRef.current.slickPrev();

            SetActualVideo((prevValue) => {
                if(videoTypeID == 3) 
                    videoRefs.current[prevValue - 1].play();
                return prevValue - 1;
            });
        }  
    }

    const Play = () => {
        videoRefs.current[0].play();    
        SetPlaying(true);
    }

    const GetSlideshowTheme = (theme) => {
        switch(theme)
        {
            case "dark": return {bg:slideshowDarkBg,play:slideshowAcPlay}
            case "light": return {bg:slideshowLightBg,play:slideshowAcPlay}
            case "ac": return {bg:slideshowAcBg,play:slideshowAcPlay}
            default: return {bg:slideshowDarkBg,play:slideshowAcPlay}
        }
    }

    const themeData = GetSlideshowTheme(slideshowTheme);
   
    return (
        <div className="cm-video-slideshow">

            <img className="frame" src={themeData.bg} />
            {!playing && videoTypeID == 3 ?
                <img onClick={() => Play()} className="play" src={themeData.play} />
            :null}

            <Slider 
                ref={sliderRef}
                {...settings}
                key={selectedVideos}
            >
                {selectedVideos.map((item,index) => {
                    return(
                        <div key={index}>
                            <Video
                                index = {index}
                                videoRefs = {videoRefs}
                                data = {{...item,videoTypeID}}
                                hideControls = {(videoTypeID == 3 ? true : false)}
                            />
                        </div>
                    )
                })}
            </Slider>

            {playing && videoTypeID == 3 || videoTypeID != 3 ?
                <div className="controls">
                    <img onClick={() => Previous()} className={(actualVideo == 0 ? "disabled" : "")} src={arrowLeftIcon} />
                    <img onClick={() => Next()} className={"right" + ((actualVideo + 1) == selectedVideos.length ? " disabled" : "")} src={arrowLeftIcon} />
                </div>
            :null}
            
        </div>
    );
    
}

export default VideoGallerySlideshow;