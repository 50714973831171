import { useMutation } from "@apollo/client";
import { BLOCK_DAY } from "../Queries/blockedDays";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from "react-i18next";

const UseBlockDay = (OnSuccess) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [BlDay,{error,loading}] = useMutation(BLOCK_DAY,{
        onCompleted:(d) => {

            OnSuccess(d.reservationBlockDay);
            
            AddNotification(t("AndNoOneCanGoThere"),true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const BlockDay = (date,reservationPlaceID) => {
 
        BlDay({
            variables:{
                date: date,
                reservationPlaceID
            }
        }) 
    }

    return{
        loading,
        error,
        BlockDay
    }

}

export default UseBlockDay;