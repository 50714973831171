const Newsletter = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path style={{"fill":secondaryColor}}  d="M316.741,249.769h-121.48c-6.075,0-11-4.925-11-11c0-6.075,4.925-11,11-11h121.48    c6.075,0,11,4.925,11,11C327.741,244.844,322.816,249.769,316.741,249.769z"/>
                        <path style={{"fill":secondaryColor}}  d="M316.741,188.774h-121.48c-6.075,0-11-4.925-11-11s4.925-11,11-11h121.48c6.075,0,11,4.925,11,11    S322.816,188.774,316.741,188.774z"/>
                    </g>
                    <path style={{"fill":primaryColor}}  d="M509.887,192.351c0.006-3.426-1.671-6.769-4.419-8.814l-90.427-67.517V92.54c0-6.075-4.925-11-11-11h-35.18L262.582,2.186   c-3.903-2.914-9.258-2.915-13.162,0L143.14,81.54h-35.18c-6.075,0-11,4.925-11,11v23.48L6.534,183.537   c-2.748,2.045-4.425,5.388-4.419,8.814V501c-0.098,5.903,5.094,11.106,11,11h485.772c5.907,0.106,11.097-5.097,11-11V192.351z    M24.115,214.658l138.881,106.577L24.115,472.724V214.658z M180.516,334.681l48.183,36.975c8.039,6.169,17.669,9.252,27.302,9.252   c9.631,0,19.265-3.084,27.302-9.252l48.183-36.975L473.879,490H38.123L180.516,334.681z M349.006,321.235l138.881-106.577v258.066   L349.006,321.235z M480.662,192.471l-65.621,50.357v-99.353L480.662,192.471z M256.001,24.728l76.088,56.812H179.912   L256.001,24.728z M393.041,103.54v156.171l-123.132,94.492c-8.19,6.284-19.627,6.284-27.816,0L118.96,259.711V103.54H393.041z    M96.96,242.828L31.34,192.471l65.621-48.996V242.828z"/>
                </g>
            </svg>
        </>
    )
}

export default Newsletter;
