/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_CAMPAIGN_EMAIL = gql`
   mutation AddEditCampaignEmail(
       $campaignEmailID:ID,
       $campaignID:ID,
       $testEmail:String,
       $hours:Int,
       $langs:[CampaignEmailLangsInput],
       $generateTranslations:GenerateCampaignEmailTranslationsInput
   ){
       AddEditCampaignEmail(
           campaignEmailID:$campaignEmailID,
           campaignID:$campaignID,
           testEmail:$testEmail,
           hours:$hours,
           langs:$langs,
           generateTranslations:$generateTranslations
       ){
           campaignEmailID
           subject
       }
   }
`;
 
/**
 *  Data pro jednu kategorii
 */

export const GET_CAMPAIGN_EMAIL = gql`
    query CampaignEmailWithLangs($campaignEmailID: ID!){
       CampaignEmailWithLangs(campaignEmailID:$campaignEmailID){
           campaignEmailID
           hours
           testEmail
           langs{
               lang
               subject
               preheader
               text
           }  
        }
        Settings{
            emailForTesting
        }
        AllLanguageMutations(onlyEnabled:true){
           languageID
           title
           suffix
           generateNiceTitle
           langTitle
           decimal
           priority
           main
           currencyTitle
       }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_CAMPAIGN_EMAILS = gql`
    query AllCampaignEmails($campaignID: ID,$lang: String){
       AllCampaignEmails(campaignID:$campaignID,lang:$lang){
           campaignEmailID
           subject
       }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_CAMPAIGN_EMAIL = gql`
    mutation DeleteCampaignEmails($campaignEmailIDs:ID!,$campaignID:ID) {
        DeleteCampaignEmails(campaignEmailIDs:$campaignEmailIDs,campaignID:$campaignID)
    }
`;

export const UPDATE_CAMPAIGN_EMAIL_PRIORITY = gql`

   mutation UpdateCampaignEmailPriority($campaignEmailID:ID!,$campaignID:ID!,$fromIndex:Int!,$toIndex:Int!) {
       UpdateCampaignEmailPriority(campaignEmailID:$campaignEmailID,campaignID:$campaignID,fromIndex:$fromIndex,toIndex:$toIndex)
   }

`;

export const GENERATE_CAMPAIGN_EMAIL_LANG = gql`
   mutation GenerateCampaignEmailLang(
       $campaignEmailID:ID,
       $lang:String,
       $isEdit:Boolean
       $onlyForParams:[String]
   ) {
       GenerateCampaignEmailLang(
           campaignEmailID:$campaignEmailID,
           lang:$lang,
           isEdit:$isEdit
           onlyForParams:$onlyForParams
       )
   }
`;

