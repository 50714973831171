import gql from 'graphql-tag';

export const ADD_EDIT_FORM = gql`
    mutation AddEditForm(
        $formID:ID,
        $formThanksPageTypeID: ID, 
        $thanksLinks: [FormThanksLinksInput], 
        $name:String,
        $langs:[FormLangsInput],
        $generateTranslations:GenerateFormTranslationsInput
    ){
        AddEditForm(
            formID:$formID,
            formThanksPageTypeID: $formThanksPageTypeID, 
            thanksLinks: $thanksLinks, 
            name:$name, 
            langs:$langs,
            generateTranslations:$generateTranslations
        ){
            formID
            name
        }
    }
`;

export const GET_FORM = gql`
    query FormWithLangs($formID: ID){
        FormWithLangs(formID:$formID){
            formID
            formThanksPageTypeID
            name
            thanksLinks{
                linkID
                projectID
            }
            langs{
                lang
                popUpText
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        AllFormThanksPageTypes{
            formThanksPageTypeID
            name
        }
        AllProjects{
            projectID
            name
        }
    }
`;

export const GET_FORMS = gql`
    query AllForms($lang: String){
        AllForms(lang:$lang){
            formID
            name
        }
    }
`;

export const DELETE_FORMS = gql`
    mutation DeleteForms($formIDs:ID) {
        DeleteForms(formIDs:$formIDs)
    }
`;

export const GENERATE_FORM_LANG = gql`
    mutation GenerateFormLang(
        $formID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateFormLang(
            formID:$formID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;