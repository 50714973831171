import { SEND_TEST_EMAIL } from "../Queries/newsletter";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const UseSendTestEmail = (OnCompleted) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [SendTestEmailMutation,{loading}] = useMutation(SEND_TEST_EMAIL,{
        onCompleted:(d) => {

            AddNotification(t("TestSended"),true);
            if(OnCompleted)
                OnCompleted(d.DeleteCampaigns);
        },
        onError:(err) => {     
            
            var e = GetApolloErrorText(err);
            if(
                e == "SendersNameIsNotFilledInProject" || 
                e == "SendersEmailIsNotFilledInProject" || 
                e == "DomainNameIsNotFilledInProject"
            )
                e = t(e);

            AddNotification(e,false);
        }
    });

    const SendTestEmail = (formData) => {

        var data;
        for(let val of formData.langs)
        {
            if(val.lang == formData.selectedLang)
                data = val;
        }

        if(data.subject)
        {
            if(data.text)
            {
                SendTestEmailMutation({
                    variables:{
                        projectID:formData.projectID,
                        email:formData.testEmail,
                        hash:"test",
                        lang:formData.selectedLang,
                        preheader:data.preheader,
                        text:data.text,
                        subject:data.subject
                    }
                })
            }
            else
                AddNotification(t("TextIsNecessary"),false);
        }
        else
            AddNotification(t("SubjectIsNecessary"),false);

    } 

    return{
        loading,
        SendTestEmail
    }
}

export default UseSendTestEmail;