import InputCard from "../../../GlobalComponents/InputCard";
import { useTranslation } from "react-i18next";
import UseSendTestEmail from "../Library/UseSendTestEmail";
import Loading from "../../../GlobalComponents/Loading";
import emailIcon from '../../../Media/Images/Icons/email.webp';
import Button from "../../../GlobalComponents/Button";
import Input from "../../../GlobalComponents/Input";
import { useState,useEffect } from "react";
import { UseSettings } from "../../../AdminProvider";

const SendTestEmailForm = ({
    className,
    initTestEmail,
    formData,
    OnEmailChange
}) => {

    const {t} = useTranslation();
    const {settings} = UseSettings();

    const {loading, SendTestEmail} = UseSendTestEmail();
    const [testEmail, SetTestEmail] = useState("");

    useEffect(() => {

        if(OnEmailChange)
            OnEmailChange(testEmail)

    },[testEmail])

    useEffect(() => {

        if(settings.emailForTesting)
            SetTestEmail(settings.emailForTesting);

    },[settings.emailForTesting])

    useEffect(() => {

        if(initTestEmail)
            SetTestEmail(initTestEmail)

    },[initTestEmail])

    return(
        <InputCard
            className={className}
            title = {t("SendTestEmail")}
            icon = {emailIcon}
            headerInfoContent={[
                t("SendTestEmailInfo1"),
                t("SendTestEmailInfo2")
            ]}
        >
            
            {loading ?
                <Loading />
            :
                <div className="row form-group">
                    <div className="col-50">
                        
                        <Input
                            name="testEmail"
                            value={testEmail}
                            OnChange={(e) => SetTestEmail(e.target.value)}
                        />
                    
                    </div>
                    <div className="col-50">
                        
                        <Button
                            className={"btn-primary w-100"}
                            OnClick={() => SendTestEmail({...formData,testEmail})}
                        >
                            {t("SendTestEmail")}
                        </Button>  
                        
                    </div>
                </div>  
            }
            
        </InputCard>
    )
}

export default SendTestEmailForm;