
const ContentBody = ({children}) => {


    return(
        <div className="content-body">
            {children}
        </div>
    )
}

export default ContentBody;