import { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditReservation from '../Library/UseAddEditReservation';
import UseGetReservation from '../Library/UseGetReservation';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseAuth } from '../../Auth/Library/UseAuth';
import SearchCustomer from '../../Customer/Components/SearchCustomer';
import Select from '../../../GlobalComponents/Select';
import ReservationPlaceCalendar from '../../ContentManager/Components/Elements/ReservationPlace/ReservationPlaceCalendar';
import NoItems from '../../../GlobalComponents/NoItems';
import dayjs from 'dayjs';
import { GetPriceStringNew } from '../../../GlobalFunctions/helper';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import RadioButton from '../../../GlobalComponents/RadioButton';
import CouponCodeInput from '../../../GlobalComponents/CouponCodeInput';

Modal.setAppElement('#root');

const AddEditReservation = ({selectedReservationID,SetSelectedReservationID,OnAddEditSuccess}) => {

    const {t} = useTranslation();
    const {user} = UseAuth();
    
    const {
        loading,
        formData,
        allMemberships,
        hasVariations,
        membershipData,
        showNotificationType,
        EditReservation,
        InitFormData,
        SetFormData,
        ReservationPlaceCalendarDataChanged,
        SetHasVariations,
        SetShowNotifiationType,
        GetNotificationMessage,
        NotificationCallBack,
        HandOverReservation,
        ChangeReservationData
    } = UseAddEditReservation(selectedReservationID,user.lang,(d) => OnAddEditSuccess(d));

    const {
        error,
        loading:getLoading,
        allReservationPlaces
    } = UseGetReservation(selectedReservationID,user.lang,(d) => {
        InitFormData(d)    
    });

    var showButtons = (formData.reservationDate != "" && formData.reservationPlaceTimeID != 0 && (!hasVariations || hasVariations && formData.reservationPlaceVariationID != 0) ? true : false);
    var showPrepayment = (membershipData.prepaymentPrice == 0 ? false : true);
    
    if(formData.reservationDate)
    {
        if(dayjs(formData.reservationDate) < dayjs().add(1,"month"))
            showPrepayment = false;
    }

    var err = "";
    if(error)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedReservationID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedReservationID === 0 ? t("AddReservation") : t("EditReservation")) + (formData.customerName ? ": " + formData.customerName : "")}
                OnClose ={() => SetSelectedReservationID(null)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                <div className = {"form-group"}>
                                    {selectedReservationID == 0 ?
                                        <label>{t("ToWhomeTheReservationWillBeCreated")}</label>
                                    :
                                        <>
                                            <label>{t("HandOverReservationToSomeOneElse")}</label>
                                            <div className="radio-container form-group">
                                                <RadioButton 
                                                    text ={t("Yes")}
                                                    id = "handOver"
                                                    name = "handOverReservation" 
                                                    value = {1}
                                                    checked = {formData.handOverReservation == 1 ? true : false}
                                                    OnChange = {(e) => HandOverReservation(e)}
                                                />
                                                <RadioButton 
                                                    text ={t("No")}
                                                    id = "handOver"
                                                    name = "handOverReservation" 
                                                    value = {0}
                                                    checked = {formData.handOverReservation == 0 ? true : false}
                                                    OnChange = {(e) => HandOverReservation(e)}
                                                />
                                            </div>
                                        </>
                                    }
                                    {selectedReservationID == 0 || (selectedReservationID != 0 && formData.handOverReservation == 1) ?
                                        <SearchCustomer
                                            OnChangeCustomer = {(cust) => {
                                                SetFormData("customerHash",cust.hash);
                                                SetFormData("customerName",cust.name + " " + cust.surname);
                                                SetFormData("customerLang",cust.lang);
                                                SetFormData("email",cust.email);
                                            }}
                                        />  
                                    :null}
                                </div>  

                                {selectedReservationID != 0 ?
                                    <div className={formData.changeReservationData == 1 ? "form-group" : ""}>
                                        <label>{t("ChangeReservationData")}</label>
                                        <div className="radio-container">
                                            <RadioButton 
                                                text ={t("Yes")}
                                                id = "changeRes"
                                                name = "changeReservationData" 
                                                value = {1}
                                                checked = {formData.changeReservationData == 1 ? true : false}
                                                OnChange = {(e) => ChangeReservationData(e)}
                                            />
                                            <RadioButton 
                                                text ={t("No")}
                                                id = "changeRes"
                                                name = "changeReservationData" 
                                                value = {0}
                                                checked = {formData.changeReservationData == 0 ? true : false}
                                                OnChange = {(e) => ChangeReservationData(e)}
                                            />
                                        </div>
                                    </div>
                                :null}

                                {selectedReservationID == 0 || (selectedReservationID != 0 && formData.changeReservationData == 1) ?
                                    <div className="row">
                                        <div className="col-50">
                                            {formData.customerHash != "" ?
                                                <div className = {"form-group"}>
                                                    <label>{t("ChooseReservationPlace")}</label>
                                                    <Select
                                                        name = "reservationPlaceID"
                                                        value = {formData.reservationPlaceID}
                                                        OnChange = {(e) => {
                                                            SetFormData("membershipID",0);
                                                            SetFormData(e)
                                                        }}
                                                    >
                                                        <option value={0}>{"- - -" + t("choose") + "- - -"}</option>
                                                        {allReservationPlaces && allReservationPlaces.map((item) => (
                                                            <option 
                                                                key={item.reservationPlaceID}
                                                                value={item.reservationPlaceID}
                                                            >{item.internalName}</option>
                                                        ))}
                                                    </Select> 
                                                </div>  
                                            :null}  
                                        </div>
                                        <div className="col-50">
                                            {formData.reservationPlaceID != 0 && allMemberships.length == 0 ?
                                                <div className="form-group">
                                                    <label>{t("ChooseMembershipToReserve")}</label>
                                                    <NoItems text={t("ThisReservationPlaceIsNotInAnyMembership")} />
                                                </div>   
                                            :null}
                                            {allMemberships && allMemberships.length > 0 ?
                                                <div className="form-group">
                                                    <label>{t("ChooseMembershipToReserve")}</label>
                                                    <Select
                                                        name = "membershipID"
                                                        value = {formData.membershipID}
                                                        OnChange = {(e) => SetFormData(e)}
                                                    >
                                                        <option value={0}>{"- - -" + t("choose") + "- - -"}</option>
                                                        {allMemberships && allMemberships.map((item) => (
                                                            <option 
                                                                key={item.membershipID}
                                                                value={item.membershipID}
                                                            >{item.name}</option>
                                                        ))}
                                                    </Select> 
                                                </div>   
                                            :null}  
                                        </div>
                                        <div className="col-100">

                                            {formData.membershipID != 0 ?
                                                <>
                                                    {selectedReservationID == 0 ?
                                                        <label>{t("ReservationDate")}</label>
                                                    :
                                                        <label>{t("ChangeReservationDateTo")}</label>
                                                    }
                                                    <div className={"form-group"}>
                                                        <ReservationPlaceCalendar 
                                                            projectData = {{
                                                                projectID:membershipData.projectID
                                                            }}
                                                            reservationPlaceID = {formData.reservationPlaceID}
                                                            membershipID = {formData.membershipID}
                                                            lang = {user.lang}
                                                            OnDataChange = {(data) => ReservationPlaceCalendarDataChanged(data)}
                                                            OnHasVariations = {(value) => SetHasVariations(value)}
                                                        />
                                                    </div>
                                                </>
                                            :null}  

                                        </div>
                                    </div>   
                                :null}
                                 
                                {showButtons && selectedReservationID == 0 ?
                                    <>
                                        <hr />
                                        <div className="row">
                                            {showPrepayment ?
                                                <div className="col-33">
                                                    
                                                    <div className="form-group">
                                                        <Button 
                                                            OnClick = {() => SetShowNotifiationType(1)}
                                                            className="btn-primary w-100"
                                                        >{t("WaitingForThePrepayment")} {GetPriceStringNew(membershipData.prepaymentPrice,user.lang,user.language.currencyCode)}</Button>
                                                    </div>

                                                </div>
                                            :null}
                                            <div className={(showPrepayment ? "col-33" : "col-25")}>
                                                
                                                <div className="form-group">
                                                    <Button 
                                                        OnClick = {() => SetShowNotifiationType(2)}
                                                        className="btn-primary w-100"
                                                    >{t("WaitingForTheFullAmountPayment")}</Button>
                                                </div>

                                            </div>
                                            <div className={(showPrepayment ? "col-33" : "col-25")}>
                                                <div className="form-group">
                                                    <Button 
                                                        OnClick = {() => SetShowNotifiationType(3)}
                                                        className="btn-primary w-100"
                                                    >{t("BookFor3Days")}</Button>
                                                </div>
                                            </div>

                                            <div className={(showPrepayment ? "col-33" : "col-25")}>
                                                <div className="form-group">
                                                    <Button 
                                                        OnClick = {() => SetShowNotifiationType(5)}
                                                        className="btn-primary w-100"
                                                    >{t("PrePaymentIsReceivedOnAccount")}</Button>
                                                </div>
                                            </div>

                                            <div className={(showPrepayment ? "col-33" : "col-25")}>
                                                <div className="form-group">
                                                    <Button 
                                                        OnClick = {() => SetShowNotifiationType(4)}
                                                        className="btn-primary w-100"
                                                    >{t("PaymentIsReceivedOnAccount")}</Button>
                                                </div>
                                            </div>
                                            <div className={(showPrepayment ? "col-33" : "col-50")}>
                                                <div className="form-group">
                                                    <Button 
                                                        OnClick = {() => SetShowNotifiationType(7)}
                                                        className="btn-primary w-100"
                                                    >{t("PayByCoupon")}</Button>
                                                </div>
                                            </div>
                                            <div className={(showPrepayment ? "col-100" : "col-50")}>
                                                <div className="form-group">
                                                    <Button 
                                                        OnClick = {() => SetShowNotifiationType(6)}
                                                        className="btn-primary w-100"
                                                    >{t("ReservationWithoutOrderAndInvoice")}</Button>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </>
                                :null}   

                                {showNotificationType && showNotificationType != 7 ?
                                    <ModalNotification 
                                        loading = {loading}
                                        yesNo = {true}
                                        CallBack = {NotificationCallBack}
                                        text = {t("IdBbetterMakeSureBeforeIsendIt") + " - " + GetNotificationMessage()}
                                    />
                                :null}

                                {showNotificationType && showNotificationType == 7 ?
                                    <ModalNotification 
                                        loading = {loading}
                                        yesNo = {true}
                                        CallBack = {NotificationCallBack}
                                        text = {t("IdBbetterMakeSureBeforeIsendIt") + " - " + GetNotificationMessage()}
                                    >
                                        <CouponCodeInput 
                                            initValue = {formData.couponCode}
                                            lang = {formData.customerLang}
                                            placeholder={t("CouponCode")}
                                            OnChange = {(value,status,coupon) => {
                                                SetFormData("couponCode",(status == 2 ? value : ""));
                                                SetFormData("couponStatus",status);
                                            }}
                                        />
                                    </ModalNotification> 
                                :null}

                            </>
                        }
                    </>
                }
                
            </div>
            
            <div className={"modal-footer" + (selectedReservationID == 0 ? " empty-footer" : "")}>
                {selectedReservationID != 0 ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => EditReservation()}
                    >
                        {t("Edit")}
                    </Button>
                :null}
            </div>
            

        </Modal>
    )
}

export default AddEditReservation;