import { useMutation } from "@apollo/client";
import { useState } from "react";
import { REMOVE_BLOCKED_DAY } from "../Queries/blockedDays";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from "react-i18next";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const UseRemoveBlockedDay = (OnSuccess) => {

    const [showRemoveNotify, SetShowRemoveNotify] = useState(false);
    const [selectedData, SetSelectedData] = useState(null);

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [RemBlockDay,{error,loading}] = useMutation(REMOVE_BLOCKED_DAY,{
        onCompleted:(d) => {

            OnSuccess();
            AddNotification(t("AndItIsGone"),true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const ShowRemoveNotification = (date,reservationPlaceID) => {
        SetSelectedData({date,reservationPlaceID});
        SetShowRemoveNotify(true);
    }

    const RemoveBlockedDay = (act) => {

        if(act)
        {
            RemBlockDay({
                variables:{
                    date: selectedData.date,
                    reservationPlaceID:selectedData.reservationPlaceID,
                }
            })
        }
        SetSelectedData(null);
        SetShowRemoveNotify(false);
    }

    return {
        showRemoveNotify,
        ShowRemoveNotification,
        RemoveBlockedDay
    }
}

export default UseRemoveBlockedDay;