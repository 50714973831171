import { useState,useEffect } from 'react';
import { SketchPicker } from 'react-color'

const ColorInput = ({name,defaultColor,colorCode,append,placeholder,value,ariaLabel,OnChange}) => {


    const [colorData, SetColorData] = useState({
        color:(colorCode ? colorCode : defaultColor),
        value:value
    });
    const [showColorPicker, SetShowColorPicker] = useState(false); 
    const [colorPickerClassName, SetColorPickerClassName] = useState(false);

    useEffect(() => {

        SetColorData({
            color:colorCode,
            value
        })

    },[value,colorCode])

    const ChangeValue = (value) => {
        SetColorData((prevValue) => {

            var newValue = {...prevValue};
            newValue.value = value;

            if(OnChange)
                OnChange(newValue.value,newValue.color);

            return newValue;
        })
    }

    const ChangeColor = (colorCode) => {
        SetColorData((prevValue) => {

            var newValue = {...prevValue};
            newValue.color = colorCode;

            if(OnChange)
                OnChange(newValue.value,newValue.color);

            return newValue;
        })
    }


    const ShowColorPicker = (e) => {

        let y = e.clientY;
        let windowHeight = window.innerHeight;

        if(windowHeight - y < 350)
            SetColorPickerClassName("top");
        else
            SetColorPickerClassName("");

        SetShowColorPicker(true);
    }
    
    var className = ""
    if(append) className += " with-append";

    return(
        <div className="input-group">
            
            <div className="preppend" style={{backgroundColor:colorData.color}}></div>

            <input 
                name = {name} 
                type = "text" 
                value = {colorData.value} 
                placeholder = {placeholder}
                onClick ={(e) => ShowColorPicker(e)}
                onChange = {(e) => ChangeValue(e.target.value)} 
                aria-label = {ariaLabel}
                className={className}
            />
            
            {append ?
                <div className="append">
                    {append}
                </div>
            :null}

            {showColorPicker ?
                <>
                    <div className={"color-picker" + (colorPickerClassName ? " " + colorPickerClassName : "")}>
                        <SketchPicker 
                            color = {colorData.color}
                            onChangeComplete={ (color) => ChangeColor(color.hex) }
                        />
                    </div>
                    <div 
                        onClick ={() => SetShowColorPicker(false)}
                        className="color-picker-overlay"
                    ></div>
                </>
            :null}

        </div>
    )

}

export default ColorInput;