import { useState } from "react";

const UseCopyToClipboard = () => {
    const [copied, setCopied] = useState(false);
    
    const Copy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 500)
        })
    };
    
    return { copied, Copy };
}

export default UseCopyToClipboard;