const Email = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <path style={{"fill":secondaryColor}} d="M500.256,435.021c-3.472,0-6.133,1.447-8.272-1.614L341.52,218.113   c-3.48-4.98,0.617-6.787,5.596-10.267c4.981-3.48,8.957-7.314,12.436-2.336l150.464,215.294c3.48,4.98-0.217,7.187-5.196,10.668   C502.901,432.812,502.431,435.021,500.256,435.021z"/>
                    <path style={{"fill":secondaryColor}}  d="M14.467,432.993c-2.176,0-6.702,0.327-8.62-1.014c-4.979-3.48-7.343-6.194-3.862-11.175   L152.448,205.51c3.48-4.979,5.616-3.68,10.595-0.199c4.98,3.48,10.918,7.822,7.437,12.803L20.016,433.407   C17.877,436.468,17.938,432.993,14.467,432.993z"/>
                    <path style={{"fill":primaryColor}}  d="M511.989,84.42c-0.121-2.702-1.282-5.366-3.201-7.292c-2.04-2.047-4.899-3.238-7.789-3.238c0,0-489.993,0-490,0   c-2.913,0-5.796,1.211-7.839,3.288C1.161,79.211,0,82.038,0,84.89v342.22c0,6.07,4.92,11,11,11h490c6.08,0,11-4.93,11-11   c0,0,0-342.215,0-342.22C512,84.733,511.996,84.576,511.989,84.42z M470.9,95.89L269.69,265.61c-8.19,6.91-19.19,6.91-27.38,0   L41.1,95.89H470.9z M490,416.11H22V108.56l206.12,173.87c8.21,6.92,18.04,10.38,27.88,10.38c9.84,0,19.67-3.46,27.88-10.38   L490,108.56V416.11z"/>
                </g>
                
            </svg>
        </>
    )
}

export default Email;