import { useState } from "react";
const UseFormHandle = (formData) => {

    const [newFormData, SetNewFormdata] = useState(formData);

    const SetFormData = (e,val) => {

        var {n,v} = GetEventData(e,val);
        
        SetNewFormdata((prevValue) => {
            return {...prevValue,[n]: v}
        });
    }

    const SetCompleteFormData = (data) => {
        SetNewFormdata((prevValue) => {
            return {...prevValue,...data}
        });
    }

    const SetFormLangData = (e,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            let langs = [...prevValue.langs];
            for(let i in langs)
            {
                if(langs[i].lang == prevValue.selectedLang)
                {
                    langs[i] = {...langs[i],[n] : v};
                }
            }

            return {...prevValue,langs: langs}
        });
    }

    const SetFormLangArrayData = (e,langParam,arrayParam,arrayParamID,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            let langs = [...prevValue.langs];
            for(let i in langs)
            {
                if(langs[i].lang == prevValue.selectedLang)
                {
                    var arr = [...langs[i][langParam]];
                    var check = false;

                    for(let j in arr)
                    {
                        if(arr[j][arrayParam] == arrayParamID)
                        {
                            arr[j] = {...arr[j],[n]:v};
                            check = true;
                        }  

                    }
                    if(!check)
                        arr.push({
                            [arrayParam]:arrayParamID,
                            [n]:v
                        })
                        
                    langs[i] = {...langs[i],[langParam]:arr};
                }
            }

            return {...prevValue,langs: langs}
        });
    }

    const SetFormSelectedItemsLangValue = (e,selectedParam,paramID,paramIDValue,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            var arr = [...newFormData[selectedParam]];

            for(let i in arr)
            {
                if(arr[i][paramID] == paramIDValue)
                {
                    var langs = [...arr[i].langs];

                    for(const j in langs)
                    {
                        if(langs[j].lang == newFormData.selectedLang)
                        {
                            langs[j] = {...langs[j],[n]:v}
                        }
                    }

                    arr[i] = {...arr[i],langs};
                }
            }

            return {...prevValue,[selectedParam]: arr}
        });
    }

    const OpenLangImage = (e) => {
        
        var param = e.target.name;
        var photo = e.target.files[0];   

        var reader = new FileReader();
        reader.onload = () => {

            var dataURL = reader.result;
            let langs = [...newFormData.langs];

            for(let i in langs)
            {
                if(langs[i].lang == newFormData.selectedLang)
                    langs[i] = {...langs[i],[param] : photo,[param + "Show"] : dataURL};
            }

            SetNewFormdata({...newFormData,langs: langs});

        };

        reader.readAsDataURL(photo);
    }

    const OpenImage = (e,param = "photo",mainParam = "mainPhoto") => {
        
        var photo = e.target.files[0];   

        var reader = new FileReader();
        reader.onload = () => {

            var dataURL = reader.result;
            SetNewFormdata({...newFormData,[param]: photo,[mainParam]:dataURL});

        };

        reader.readAsDataURL(photo);
    }

    const OpenImageToArrayOfObject = (e,param,objParam,objMainParam,objParamID,paramID) => {
        
        var photo = e.target.files[0];   

        var reader = new FileReader();
        reader.onload = () => {

            var dataURL = reader.result;

            SetFormDataSelectedItemsWithObj(objParam,param,objParamID,paramID,photo);
            SetFormDataSelectedItemsWithObj(objMainParam,param,objParamID,paramID,dataURL);

        };

        reader.readAsDataURL(photo);
    }

    const OpenMedia = (e,param,mainParam) => {
        
        var media = e.target.files[0];   

        var reader = new FileReader();
        reader.onload = () => {
            SetNewFormdata({...newFormData,[param]: media,[mainParam]:media.name});
        };

        reader.readAsDataURL(media);
    }

    const GetFormLangValue = (param) => {

        for(const lang of newFormData.langs)
        {
            if(lang.lang == newFormData.selectedLang)
            {
                if(lang[param] === null)
                    return "";
                else
                    return lang[param];
            }
        }
        return "";
    }

    const GetFormSelectedItemsLangValue = (selectedParam,paramID,paramIDValue,param) => {

        var arr = [...newFormData[selectedParam]];

        for(let i in arr)
        {
            if(arr[i][paramID] == paramIDValue)
            {
                for(const lang of arr[i].langs)
                {
                    if(lang.lang == newFormData.selectedLang)
                    {
                        if(lang[param] === null)
                            return "";
                        else
                            return lang[param];
                    }
                }
            }
        }

        return "";
    }

    const GetFormLangArrayValue = (langParam,arrayParam,arrayParamID,param) => {

        for(const lang of newFormData.langs)
        {
            if(lang.lang == newFormData.selectedLang)
            {
                if(lang[langParam] === null || lang[langParam].length == 0)
                    return "";
                else{
                    for(let p of lang[langParam])
                    {
                        if(p[arrayParam] == arrayParamID)
                            return p[param];
                    }
                }
            }
        }
        return "";
    }

    const GetFormArrayValueByParam = (param,objParam,objParamID,valueParam) => {

        for(const v of newFormData[param])
        {
            if(v[objParam] == objParamID)
            {
                if(v[valueParam] === null)
                    return "";
                else
                    return v[valueParam];
            }
        }
        return "";
    }

    const FillFormSelectedItems = (param,value) => {

        var arr = [...newFormData[param]];

        const index = arr.indexOf(value);
        if(index != -1)
            arr.splice(index,1);
        else
            arr.push(value);

        SetNewFormdata({...newFormData,[param]: arr});
    }

    const FillFormSelectedItemsWithObj = (param,paramID,obj,useDelete) => {

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[param]];
            var checked = false;

            for(let i in arr)
            {
                if(arr[i][paramID] == obj[paramID])
                {
                    checked = true;
                    if(arr[i].deleted == undefined || arr[i].deleted == false)
                    {
                        if(useDelete && !arr[i].add)
                            arr[i] = {...arr[i],deleted:true}
                        else
                            arr.splice(i,1);
                        break;
                    }
                    else if(arr[i].deleted)
                        arr[i] = {...arr[i],deleted:false}
                }
            }

            if(!checked)
                arr.push(obj);

            return {...prevValue,[param]: arr};
        })
        
    }

    const FillFormSelectedItemsWithObjToArray = (param,paramID,obj,useDelete,index) => {

        SetNewFormdata((prevValue) => {

            var arr = {...prevValue[param]};
            var checked = false;
            var newArrIndex = (arr[index] ? [...arr[index]] : []);

            for(let i in newArrIndex)
            {
                if(newArrIndex[i][paramID] == obj[paramID])
                {
                    checked = true;
                    if(newArrIndex[i].deleted == undefined || newArrIndex[i].deleted == false)
                    {
                        if(useDelete && !newArrIndex[i].add)
                            newArrIndex[i] = {...newArrIndex[i],deleted:true}
                        else
                            newArrIndex.splice(i,1);
                        break;
                    }
                    else if(newArrIndex[i].deleted)
                        newArrIndex[i] = {...newArrIndex[i],deleted:false}
                }
            }

            if(!checked)
            {
                if(!newArrIndex)
                    newArrIndex = [obj]
                else
                    newArrIndex.push(obj);
            }

            arr[index] = newArrIndex;

            return {...prevValue,[param]: arr};
        })
        
    }

    const SetFormDataSelectedItemsWithObj = (e,objParam,objParamID,paramID,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];
            var check = false;

            for(let i in arr)
            {
                if(arr[i][objParamID] == paramID)
                {
                    check = true;
                    arr[i] = {...arr[i],[n]:v}
                }
            }

            if(!check)
            {
                arr.push({
                    [objParamID]:paramID,
                    [n]:v
                })
            }

            return {...prevValue,[objParam]: arr}
        })
    }

    const SetFormDataSelectedItemsWithObjToArray = (e,objParam,objParamID,paramID,val,index) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            var arr = {...prevValue[objParam]};
            var check = false;

            var newArrIndex = (arr[index] ? [...arr[index]] : []);

            for(let i in newArrIndex)
            {
                if(newArrIndex[i][objParamID] == paramID)
                {
                    check = true;
                    newArrIndex[i] = {...newArrIndex[i],[n]:v}
                }
            }

            if(!check)
            {
                newArrIndex.push({
                    [objParamID]:paramID,
                    [n]:v
                })
            }

            arr[index] = newArrIndex;

            return {...prevValue,[objParam]: arr}
        })
    }

    const SetFormDataSelectedItemsWithObjAndIndex = (objParam,obj,index) => {

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];
            
            arr[index] = {...arr[index],...obj};
            
            return {...prevValue,[objParam]: arr}
        })
    }

    const SetBooleanFormDataSelectedItemsWithIndex = (param,objParam,index) => {

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];

            for(let i in arr)
            {
                if(i == index)
                    arr[i] = {...arr[i],[param]:true}
                else
                    arr[i] = {...arr[i],[param]:false}
            }

            return {...prevValue,[objParam]: arr}

        });
    }

    const SetBooleanFormDataSelectedItemsWithObj = (param,objParam,objParamID,paramID) => {

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];

            for(let i in arr)
            {
                if(arr[i][objParamID] == paramID)
                    arr[i] = {...arr[i],[param]:true}
                else
                    arr[i] = {...arr[i],[param]:false}
            }

            return {...prevValue,[objParam]: arr}

        });
    }

    const SetBooleanFormDataSelectedItemsWithObjToArray = (param,objParam,objParamID,paramID,index) => {

        SetNewFormdata((prevValue) => {

            var arr = {...prevValue[objParam]};
            var newArrIndex = (arr[index] ? [...arr[index]] : []);

            for(let i in newArrIndex)
            {
                if(newArrIndex[i][objParamID] == paramID)
                    newArrIndex[i] = {...newArrIndex[i],[param]:true}
                else
                    newArrIndex[i] = {...newArrIndex[i],[param]:false}
            }

            arr[index] = newArrIndex;

            return {...prevValue,[objParam]: arr}

        });
    }

    const SetArrayValue = (objParam,index,val) => {

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];
            arr[index] = val;
            
            return {...prevValue,[objParam]: arr}
        })
    }

    const SetArrayObjValue = (e,objParam,index,val) => {

        var {n,v} = GetEventData(e,val);

        SetNewFormdata((prevValue) => {

            var arr = [...prevValue[objParam]];
            arr[index] = {...arr[index],[n]:v}
            
            return {...prevValue,[objParam]: arr}
        })
    }

    const RemoveFormDataSelectedItems = (param,paramID,IDs) => {

        SetNewFormdata((prevValue) => {

            var newArray = [...prevValue[param]];
            
            for(let id of IDs)
            {
                for(let i in newArray)
                {
                    if(newArray[i][paramID] == id)
                        newArray.splice(i,1);
                } 
            } 

            return {...prevValue,[param]: newArray}

        });
    }

    const SetFormDataSelectedItemsPriority = (param,oldIndex,newIndex) => {

        SetNewFormdata((prevValue) => {

            var newArray = [...prevValue[param]];
            
            const elm = newArray[oldIndex];

            newArray = [...newArray.slice(0,oldIndex),...newArray.slice(oldIndex + 1)];
            newArray = [...newArray.slice(0,newIndex),elm,...newArray.slice(newIndex)];

            for(let i in newArray)
            {
                newArray[i] = {...newArray[i],priority:parseInt(i) + 1} 
            }

            return {...prevValue,[param]: newArray}

        });
    }

    const GetEventData = (e,val) => {

        var n,v;
    
        if(!e.target)
        {
            v = val;
            n = e;
        }
        else
        {
            const t = e.target;
            if(t.type === 'checkbox')
                v = t.checked
            else if(t.type === 'radio')
                v = parseInt(t.value);
            else
                v = t.value;
    
            n = t.name;
        }
    
        return {n,v}
    }
   
    return{
        formData:newFormData,
        SetFormData,
        SetNewFormdata,
        SetCompleteFormData,
        SetFormLangData,
        SetFormLangArrayData,
        SetFormSelectedItemsLangValue,
        GetFormLangValue,
        GetFormLangArrayValue,
        GetFormSelectedItemsLangValue,
        OpenLangImage,
        OpenImage,
        OpenImageToArrayOfObject,
        OpenMedia,
        FillFormSelectedItems,
        FillFormSelectedItemsWithObj,
        FillFormSelectedItemsWithObjToArray,
        SetFormDataSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObjToArray,
        SetBooleanFormDataSelectedItemsWithIndex,
        SetBooleanFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObjToArray,
        RemoveFormDataSelectedItems,
        SetArrayValue,
        SetArrayObjValue,
        SetFormDataSelectedItemsPriority,
        GetEventData,
        GetFormArrayValueByParam,
        SetFormDataSelectedItemsWithObjAndIndex
    }
}

export default UseFormHandle;