import React,{ useEffect, useState } from 'react';
import Notifications from '../Modules/Notification/Components/Notifications';
import Header from './Header';
import { UseAuth } from '../Modules/Auth/Library/UseAuth';
import { useNavigate, Outlet } from "react-router-dom";
import { IsServerDev } from '../Config';

const Layout = () => {

    const isServerDev = IsServerDev();

    const [isShrink,SetIsShrink] = useState(false);
	const {IsSignedIn,user,GetAdminUserContext} = UseAuth();

	const navigate = useNavigate();
	
	useEffect(() => {
        if(!IsSignedIn())
            navigate("/");
    },[IsSignedIn])

    if(!user.adminUserID && IsSignedIn())
        GetAdminUserContext();
        	
    return (
		<>
			{IsSignedIn() ?	   
               		       
                <> 
                    <Header isShrink = {isShrink} SetIsShrink = {SetIsShrink} />  
                    <div className={"whole-content" + (isShrink ? " shrink" : "") + (isServerDev ? " dev" : "")}>
                                                
                        <div className={"l-content"}>
                                                
                            <Outlet />	
                            
                        </div>
                        
                    </div>
                    <Notifications />
                 </>           
                
			:null}
   		</>
    );
  
}

export default Layout;
