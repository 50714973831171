import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConvertHtmlToText } from "../../../GlobalFunctions/helper";
import Video from "../../../GlobalComponents/Video";

const UseEditVideoGallery = (
    selectedVideos,
    videoTypeID,
    SetFormData
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedVideos)
        {
            const contentData = GetContentData(selectedVideos);
            SetContent(contentData);
        }

    },[selectedVideos])

    var headerData = [
        {
            value: t("Video"),
            className:"flex-1 no-padding-left"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value: 
                            <Video 
                                hideControls = {true}
                                data={{
                                    videoTypeID:videoTypeID,
                                    embedCode:val.embedCode,
                                    mp4File: val.mp4File,
                                    mp4FilePoster: val.mp4FilePoster
                                }} 
                            />,
                        className:"flex-1 one-line-with-dots"
                    }
                ],
            }
            cData.rowID         = val.contentManagerVideoID;
            cData.selected      = false;

            contentData.push(cData)   
        }

        return contentData;
    }

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {        
            var newList = [...selectedVideos];
            const [removed] = newList.splice(result.source.index, 1);
            newList.splice(result.destination.index, 0, removed);

            console.log(newList);

            SetFormData("selectedVideos",newList);
        }
    }

    return{
        headerData,
        content,
        OnDragEnd
    }

}

export default UseEditVideoGallery;