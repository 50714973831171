import { useTranslation } from "react-i18next";
import Input from "../../../../../GlobalComponents/Input";
import WindowWidthResponziveInfo from "../WindowWidthResponziveInfo";
import Select from "../../../../../GlobalComponents/Select";

const ColumnResponziveSettings = ({size,formData,OnChange}) => {

    const {t} = useTranslation();

    return (
        <>
            <WindowWidthResponziveInfo size={size} />
            
            <div className="row">

                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ColWidth")}</label>
                        <Select
                            name = {"cols" + size}
                            OnChange = {(e) => OnChange(e)}
                            value = {formData["cols" + size]}
                        >
                            <option value="100">100%</option>
                            <option value="84">84%</option>
                            <option value="80">80%</option>
                            <option value="75">75%</option>
                            <option value="66">66%</option>
                            <option value="60">60%</option>
                            <option value="50">50%</option>
                            <option value="40">40%</option>
                            <option value="33">33%</option>
                            <option value="25">25%</option>
                            <option value="20">20%</option>
                            <option value="16">16%</option>
                            <option value="0">{t("ByContent")}</option>
                            
                        </Select>
                    </div>
                </div>

                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingTop")}</label>
                        <Input 
                            type = "number"
                            name = {"pt" + size}
                            value = {formData["pt" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingBottom")}</label>
                        <Input 
                            type = "number"
                            name = {"pb" + size}
                            value = {formData["pb" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingLeft")}</label>
                        <Input 
                            type = "number"
                            name = {"pl" + size}
                            value = {formData["pl" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingRight")}</label>
                        <Input 
                            type = "number"
                            name = {"pr" + size}
                            value = {formData["pr" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>

                <div className="col-50">
                    <div className="form-group">
                        <label>{t("MarginTop")}</label>
                        <Input 
                            type = "number"
                            name = {"mt" + size}
                            value = {formData["mt" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("MarginBottom")}</label>
                        <Input 
                            type = "number"
                            name = {"mb" + size}
                            value = {formData["mb" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default ColumnResponziveSettings;