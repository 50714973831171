import {useQuery} from '@apollo/client';
import { GET_LINKS_WITH_PROJECTS } from '../../Content/Queries/link';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseChooseLinks = (
    lang,
    selectedLinks,
    FillFormSelectedItemsWithObjToArray,
    useDeletedAttribute,
    projectID
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    
    const {data,loading,error} = useQuery(GET_LINKS_WITH_PROJECTS,{
        variables:{
            lang,
            isEshopCategory: 0,
            projectID: projectID
        },
        fetchPolicy:"network-only",
        skip:(lang == "" || projectID == 0 ? true : false)
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllLinks);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[data,selectedLinks])

    var headerData = [
        {
            value: t("LinkName"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.selected = false;
            if(selectedLinks && selectedLinks.length > 0)
            {
                for(let v of selectedLinks)
                {
                    if((v.deleted == undefined || v.deleted == false) && val.linkID == v.linkID)
                        cData.selected = true;
                }
            }

            cData.project  = val.project;
            cData.rowName  = val.name;
            cData.rowID    = val.linkID;
            cData.parentID = val.parentID;

            if(val.subLink)
                cData.subData = GetContentData(val.subLink);

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,data) => {

        var checked = e.target.checked;
        SelectLinkID(content,data.rowID,checked,true,[]);    
    }

    const SelectLinkID = (list,rowID,checked,firstCall,rowsToSelect) => {

        for(let i = 0; i < list.length; i++)
        {
            if(firstCall)
                rowsToSelect = new Array();

            if(rowID == list[i].rowID || rowID === "all")
            {                
                if(checked)
                {
                    for(let j = 0; j < rowsToSelect.length; j++)
                    {
                        if(!rowsToSelect[j].selected)
                            FillFormSelectedItemsWithObjToArray("selectedLinks","linkID",{
                                name: rowsToSelect[j].rowName,
                                linkID: rowsToSelect[j].rowID,
                                isMain:0,
                                add:true,
                                deleted:false,
                                project:rowsToSelect[j].project
                                
                            },useDeletedAttribute,projectID);
                    }
                }

                FillFormSelectedItemsWithObjToArray("selectedLinks","linkID",{
                    name: list[i].rowName,
                    linkID: rowID,
                    isMain:0,
                    add:true,
                    deleted:false,
                    project:list[i].project
                },useDeletedAttribute,projectID);

                break;

            }

            if(list[i].subData)
            {
                rowsToSelect.push({
                    rowName: list[i].rowName,
                    project: list[i].project,
                    rowID: list[i].rowID,
                    selected: list[i].selected
                })
                
                SelectLinkID(list[i].subData,rowID,checked,false,rowsToSelect);
        
            }
        }
    }

    return{
        content,
        headerData,
        loading,
        error,
        SelectRow
    }

}

export default UseChooseLinks;