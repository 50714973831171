import {useQuery} from '@apollo/client';
import { GET_PRODUCT_REVIEWS } from '../../Queries/products';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import YesNo from '../../../../GlobalComponents/YesNo';

const UseGetAllProductReviews = () => {

    const [variables,SetVariables] = useState({
        limit:40,
        offset:0
    });
    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedProductReviewIDs,SetSelectedProductReviewIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_PRODUCT_REVIEWS,{
        variables,
        fetchPolicy:"network-only"
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllProductReviews: [...prev.AllProductReviews, ...fetchMoreResult.AllProductReviews]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllProductReviews);
            SetContent(contentData);
        }

    },[data])

    const headerData = [
        {
            value: t("ProductReviewRating"),
            className:"static text-center"
        },
        {
            value: t("Email"),
            className:"flex-1"
        },
        {
            value: t("Description"),
            className:"flex-1"
        },
        {
            value: t("Approved"),
            className:"static text-center"
        },
        {
            value: t("Pinned"),
            className:"static text-center"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.rating,
                        className:"static text-center"
                    },
                    {
                        value:val.email,
                        className:"flex-1"
                    },
                    {
                        value:val.description,
                        className:"flex-1"
                    },
                    {
                        value: <>
                            {val.approved != 0 ?
                                <YesNo isYes = {val.approved == 2 ? true : false} />
                            :""}
                        </>,
                        className:"static text-center"
                    },
                    {
                        value: <YesNo isYes = {!!val.pinned} />,
                        className:"static text-center"
                    }
                ],
            }

            cData.rowID       = val.productReviewID;
            cData.selected    = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,productReviewID) => {

        var checked = e.target.checked;
        var newSelectedProductReviewIDs = [...selectedProductReviewIDs];
        const newList = SelectProductReviewID(content,productReviewID,checked,newSelectedProductReviewIDs)

        SetContent(newList);
        SetSelectedProductReviewIDs(newSelectedProductReviewIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedProductReviewIDs = [];
        const newList = SelectProductReviewID(content,"all",checked,newSelectedProductReviewIDs)

        SetSelectedProductReviewIDs(newSelectedProductReviewIDs);
        SetContent(newList);
    }

    const SelectProductReviewID = (list,productReviewID,checked,selectedProductReviewIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(newList[i].allowDelete == undefined || newList[i].allowDelete == true)
            {
                if(productReviewID == newList[i].rowID || productReviewID === "all")
                {
                    newList[i] = {...newList[i],selected:checked}
                    if(checked)
                        selectedProductReviewIDs.push(newList[i].rowID);
                    else
                    {
                        for(let j = 0; j < selectedProductReviewIDs.length; j++)
                        {
                            if(selectedProductReviewIDs[j] == newList[i].rowID)
                                selectedProductReviewIDs.splice(j,1);  
                        } 
                    }
                }

                if(newList[i].subData)
                    newList[i] = {...newList[i],subData:SelectProductReviewID(newList[i].subData,productReviewID,checked,selectedProductReviewIDs)}
            }
        }

        return newList;
    }

    const UpdateListAfterAddEdit = (selectedProductReviewID,data) => {
        
        const { AllProductReviews } = client.readQuery({ query: GET_PARAMETERS, variables });
        var newArray = [...AllProductReviews];

        if(selectedProductReviewID && selectedProductReviewID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].productReviewID == data.productReviewID)
                {
                    newArray[i] = {...newArray[i],name: data.name};
                }
            } 
        }
        else
            newArray.unshift({
                productReviewID: data.productReviewID,
                name: data.name,
            })
        
        client.writeQuery({ 
            query:GET_PARAMETERS,
            variables,
            data:{
                AllProductReviews: newArray
            } 
        });
    }

    return{
        content,
        totalContentLength: data && data.AllProductReviewsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedProductReviewIDs,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit
    }
}

export default UseGetAllProductReviews;