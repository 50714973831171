import gql from 'graphql-tag';

export const ADD_EDIT_REVIEW = gql`
    mutation AddEditReview(
        $reviewID:ID,
        $projectID:ID,
        $active: Int,
        $photo:Upload,
        $langs:[ReviewLangsInput],
        $generateTranslations:GenerateReviewTranslationsInput
    ){
        AddEditReview(
            reviewID:$reviewID,
            projectID:$projectID,
            active: $active, 
            photo:$photo, 
            langs:$langs,
            generateTranslations:$generateTranslations
        ){
            reviewID
            text
            photo
        }
    }
`;

export const GET_REVIEW = gql`
    query ReviewWithLangs($reviewID: ID){
        ReviewWithLangs(reviewID:$reviewID){
            reviewID
            projectID
            active
            photo
            dateEdit
            langs{
                lang
                text
                signature
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_REVIEWS = gql`
    query AllReviews(
        $lang: String,
        $projectID:ID,
        $limit:Int,
        $offset:Int
    ){
        AllReviews(
            lang:$lang,
            projectID:$projectID,
            limit:$limit,
            offset:$offset
        ){
            reviewID
            active
            text
            photo
        }

        AllReviewsCount(lang:$lang,projectID:$projectID)
    }
`;

export const DELETE_REVIEWS = gql`
    mutation DeleteReviews($reviewIDs:ID) {
        DeleteReviews(reviewIDs:$reviewIDs)
    }
`;

export const GENERATE_REVIEW_LANG = gql`
    mutation GenerateReviewLang(
        $reviewID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateReviewLang(
            reviewID:$reviewID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;