import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import LoadingIcon from "./LoadingIcon";
import { CHECK_VALID_COUPON } from "../Modules/Eshop/Queries/coupons";
import YesNo from "./YesNo";

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

var timer = null;

const CouponCodeInput = ({placeholder,className,initValue,lang,OnChange}) => {

    const client = useApolloClient();
    const [value,SetValue] = useState((initValue ? initValue : ""));
    const [status,SetStatus] = useState(0);

    useEffect(() => {

        if(initValue)
        {
            SetValue(initValue);
            TextChanged(initValue)
        }

    },[initValue])

    const ChangeText = (e) => {

        if(status != 1)
            SetStatus(1);

        clearTimeout(timer);
        SetValue(e.target.value);
        timer = setTimeout(() => TextChanged(e.target.value),WAIT_INTERVAL);
    }

    const HandleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) {
            clearTimeout(timer);
            TextChanged(e.target.value);
        }
    }

    const TextChanged = async (value) => {

        var status = 0;

        if(value)
        {
            SetStatus(status);
        
            const response = await client.query({
                query:CHECK_VALID_COUPON,
                variables:{
                    lang:lang,
                    param:"code",
                    value:value,
                    orderPrice:3000
                },
                fetchPolicy:"network-only"
            })

            if(response.data.ValidCouponBy)
            {
                status = 2;
            }
            else
                status = 3;

            SetStatus(status);
            OnChange((status == 2 ? value : ""),status,status == 2 ? response.data.ValidCouponBy : null);
        }
        else
        {
            SetStatus(0);
            OnChange("",0,null);
        }
    
    }
	
    return (
        <div className = {"input-group" + (status == 3 ? " required" : (status == 2 ? " success" : ""))}>
            <input 
                type        = "text" 
                className   = {className} 
                placeholder = {placeholder} 
                value       = {value} 
                onChange    = {(e) => ChangeText(e)}
                onKeyDown   = {(e) => HandleKeyDown(e)}
            />
            <div className={"append"}>
                {status == 1 ? 
                    <LoadingIcon />
                :null}
                {status == 2 ? 
                    <YesNo isYes = {true} />
                :null}
                {status == 3 ? 
                    <YesNo isYes = {false} />
                :null}

            </div>
        </div>
    );
}


export default CouponCodeInput;
