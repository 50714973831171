import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { EDIT_NEWSLETTER_SETTINGS } from "../Queries/newsletter";

const UseEditNewsletterSettings = (mainLang,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,OpenImage,GetFormLangValue,SetFormLangData} = UseFormHandle({
        selectedLang:mainLang,
        emailHeader:null,
        langs:[]
    });

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [EditNewsletterSettingsMutation,{loading}] = useMutation(EDIT_NEWSLETTER_SETTINGS,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.EditNewsletterSettings);

            AddNotification(t("SuccessfullyUpdated"),true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const EditNewsletterSettings = () => {

        let langs = [];
        for(const lang of formData.langs)
        {
            langs.push({
                sendersEmail:       lang.sendersEmail,
                emailSignature:     lang.emailSignature,
                sendersName:        lang.sendersName,
                unsubscribeText:    lang.unsubscribeText,
                lang:               lang.lang
            });
        }

        EditNewsletterSettingsMutation({
            variables:{
                projectID:      formData.projectID,
                emailHeader:    formData.emailHeader,
                langs:          langs
            }
        })   
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        loading: loading,
        formData,
        EditNewsletterSettings,
        SetFormData,
        InitFormData,
        OpenImage,
        GetFormLangValue,
        SetFormLangData
    }

}

export default UseEditNewsletterSettings;