import { useQuery } from "@apollo/client";
import { GET_BILLING_INFORMATION } from "../Queries/billingInformation";
import { useState } from 'react';

const UseGetBillingInformation = (selectedBillingInformationID,CallBack) => {

    const [allCountries, SetAllCountries] = useState([]);
    const {loading,error} = useQuery(GET_BILLING_INFORMATION,{
        variables:{
            billingInformationID:selectedBillingInformationID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            if(selectedBillingInformationID != 0)
            { 
                CallBack({
                    company:                     d.BillingInformation.company,       
                    street1:                     d.BillingInformation.street1,
                    street2:                     d.BillingInformation.street2,
                    city:                        d.BillingInformation.city,
                    zip:                         d.BillingInformation.zip,
                    state:                       d.BillingInformation.state,
                    countryID:                   d.BillingInformation.countryID,
                    idNumber:                    d.BillingInformation.idNumber,
                    vatNumber:                   d.BillingInformation.vatNumber,
                    bankAccountNumber:           d.BillingInformation.bankAccountNumber,
                    bankAccountCountryID:        d.BillingInformation.bankAccountCountryID,
                    iban:                        d.BillingInformation.iban,
                    swift:                       d.BillingInformation.swift,
                    email:                       d.BillingInformation.email
                });
            }

            SetAllCountries(d.AllCountries);
        }
    });

    return{
        loading: loading,
        error: error,
        allCountries
    }
}

export default UseGetBillingInformation;