const User = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g id="Profile">
                    <path 
                        style={{"fill":secondaryColor}} 
                        d="M256,308.525c-51.859,0-94.051-42.191-94.051-94.051c0-51.86,42.191-94.051,94.051-94.051   c51.86,0,94.052,42.191,94.052,94.051C350.052,266.334,307.86,308.525,256,308.525z M256,142.424   c-39.729,0-72.051,32.322-72.051,72.051s32.322,72.051,72.051,72.051c39.729,0,72.052-32.322,72.052-72.051   S295.729,142.424,256,142.424z"/>
                    <path 
                        style={{"fill":secondaryColor}} 
                        d="M401.265,450.665c-22.585-60.18-80.962-100.614-145.264-100.614   c-64.303,0-122.68,40.434-145.265,100.614l-20.598-7.73c12.534-33.398,34.63-61.963,63.899-82.605   c29.942-21.118,65.2-32.28,101.963-32.28c36.762,0,72.021,11.162,101.963,32.28c29.268,20.642,51.364,49.207,63.898,82.605   L401.265,450.665z"/>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M256,512C112.448,512,0,399.551,0,256S112.448,0,256,0s256,112.449,256,256S399.552,512,256,512z M256,22   C124.785,22,22,124.785,22,256s102.785,234,234,234s234-102.785,234-234S387.215,22,256,22z"/>
                </g>
            </svg>
        </>
    )
}

export default User;
