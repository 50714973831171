import gql from 'graphql-tag';

export const GET_NEWSLETTER_DATA = gql`
    query GetNewsletterData{
        AllProjects(onlyActive:true){
            projectID
            name
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_NEWSLETTER_SETTINGS = gql`
    query NewsletterSettings{
        NewsletterSettings{
            projectID
            emailHeader
            dateEdit
            langs{
                lang
                sendersEmail
                sendersName
                emailSignature
                unsubscribeText
            }
        }
        AllProjects(onlyActive:true)
        {
            projectID
            name
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const EDIT_NEWSLETTER_SETTINGS = gql`
    mutation EditNewsletterSettings(
        $projectID:ID
        $emailHeader:Upload,
        $langs:[NewsletterSettingsLangsInput]
    ){
        EditNewsletterSettings(
            projectID:$projectID
            emailHeader:$emailHeader,
            langs:$langs
        )
    }
`;

export const SEND_TEST_EMAIL = gql`

    mutation SendEmail(
        $projectID:ID,
        $email:String,
        $lang:String,
        $preheader:String,
        $text:String,
        $subject:String,
        $hash:String
    ){
        SendEmail(
            projectID:$projectID
            email:$email,
            lang:$lang,
            preheader:$preheader,
            text:$text,
            subject:$subject,
            hash:$hash
        )
    }

`;

export const EXPORT_EMAILS = gql`
    mutation ExportEmails{
        ExportEmails
    }
`;

export const GENERATE_NEWSLETTER_LANG = gql`
    mutation GenerateNewsletterLang(
        $newsletterID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateNewsletterLang(
            newsletterID:$newsletterID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;

export const GENERATE_NEWSLETTER_LANG_TO_OBJECT = gql`
    mutation GenerateNewsletterLangToObject(
        $lang:String,
        $subject:String,
        $preheader:String,
        $text:String
    ) {
        GenerateNewsletterLangToObject(
            lang:$lang,
            subject:$subject
            preheader:$preheader
            text:$text
        ){
            lang
            subject
            preheader
            text
        }
    }
`;

export const SEND_NEWSLETTER = gql`
    mutation SendNewsletter(
        $projectID:ID,
        $campaignID:ID,
        $langs:[SendNewsletterLangsInput],
    ){
        SendNewsletter(
            projectID:$projectID,
            campaignID:$campaignID
            langs:$langs
        ){
            error{
                lang
                message
            }
        }
    }
`;

export const GET_ALL_SCHEDULED_NEWSLETTERS = gql`
    query GetAllScheduledNewsletters(
        $lang:String
    ){
        AllScheduledNewsletters(
            lang:$lang
        ){
            newsletterID
            campaignID
            projectID
            subject
            plannedDate
            project{
                favicon
                name
            }
        }
    }
`;

export const DELETE_SCHEDULED_NEWSLETTER = gql`
    mutation DeleteScheduledNewsletters($ids:ID){
        DeleteScheduledNewsletters(ids:$ids)
    }
`;

export const GET_SCHEDULED_NEWSLETTER = gql`
    query GetScheduledNewsletterWithLangs(
        $newsletterID:ID
    ){
        ScheduledNewsletterWithLangs(
            newsletterID:$newsletterID
        ){
            newsletterID
            campaignID
            projectID
            plannedDate
            langs{
                lang
                subject
                preheader
                text
            }
        }

        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }

        AllProjects(onlyActive:true)
        {
            projectID
            name
        }
    }
`;

export const ADD_EDIT_SCHEDULED_NEWSLETTER = gql`
    mutation AddEditScheduledNewsletter(
        $newsletterID:ID,
        $campaignID:ID,
        $projectID:ID,
        $plannedDate:String,
        $langs:[NewsletterLangsInput],
        $generateTranslations:GenerateNewsletterTranslationsInput
    ){
        AddEditScheduledNewsletter(
            newsletterID:$newsletterID,
            campaignID:$campaignID,
            projectID:$projectID,
            plannedDate:$plannedDate,
            langs:$langs,
            generateTranslations:$generateTranslations
        ){
            newsletterID
            campaignID
            projectID
            plannedDate
        }
    }
`;

export const GET_NEWSLETTER_STATISTICS = gql`
query GetNewsletterStatistics{

    AllProjects(onlyActive:true)
    {
        projectID
        favicon
        name
        newsletterStatistics{
            numberOfAllNotUnsubscribedEmails
            numberOfAllNotUnsubscribedEmailsInLang{
                lang
                number
            }
        }
    }

    NumberOfAllNotUnsubscribedNewsletterEmails
    NumberOfAllNotUnsubscribedNewsletterEmailsInLangs{
        lang
        number
    }

    AllLanguageMutations(onlyEnabled:true){
        title
        suffix
    }

}
`;