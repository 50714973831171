import { useMutation } from "@apollo/client";
import { ADD_EDIT_PRODUCT_REVIEW } from "../../Queries/products";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseAddEditProductReview = (selectedProductReviewID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue,OpenLangImage} = UseFormHandle({ 
        approved:0,
        pinned:0,
        rating:0,
        name:"",
        email:"",
        city:"",
        description:""
    });

    const [AddEditProductReviewMutation,{error,loading}] = useMutation(ADD_EDIT_PRODUCT_REVIEW,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditProductReview);

            const notifyText = (selectedProductReviewID != 0 ? t("SuccessfullyUpdated") : t("NewProductReviewIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditProductReview = () => {

        const rating = parseInt(formData.rating);
        const approved = parseInt(formData.approved);

        if(rating && rating >= 1 && rating <= 5)
        {
            if(approved == 1 || approved == 2)
            {
                AddEditProductReviewMutation({
                    variables:{
                        productReviewID:  selectedProductReviewID,
                        approved:         parseInt(formData.approved),
                        pinned:           parseInt(formData.pinned),
                        rating:           parseInt(formData.rating),
                        name:             formData.name,
                        city:             formData.city,
                        email:            formData.email,
                        description:      formData.description
                    }
                })
            }
            else
                AddNotification(t("InvalidApproval"),false); 
        }
        else
            AddNotification(t("InvalidRating"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditProductReview,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        OpenLangImage
    }

}

export default UseAddEditProductReview;