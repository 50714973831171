import { useState,useEffect } from "react";
import ColorPicker from "./ColorPicker";
import { useTranslation } from "react-i18next";

const ChooseGradientColor = ({
    color,
    OnChange
}) => {

    const {t} = useTranslation();

    const [cFrom,SetColorFrom] = useState("");
    const [cTo,SetColorTo] = useState("");

    useEffect(() => {
        if(color && color != "-")
        {
            var c = color.split("-");
            if(c[0])
                SetColorFrom(c[0]);

            if(c[1])
                SetColorTo(c[1]);
            else if(c[0])
                SetColorTo(c[0]);
        }
    },[color])

    
    useEffect(() => {
        if(OnChange)
            OnChange(cFrom,cTo)
    },[cFrom,cTo])

    return(
        <div className="row"> 
            <div className="col-50">
                <div className="form-group">
                    <ColorPicker 
                        OnChange={(color) => SetColorFrom(color)}
                        colorCode = {cFrom}
                        text = {t("From")}
                    />
                </div>
            </div>
            <div className="col-50">
                <div className="form-group">
                    <ColorPicker 
                        OnChange={(color) => SetColorTo(color)}
                        colorCode = {cTo}
                        text = {t("To")}
                    />
                </div>
            </div>
        </div>
    )
}

export default ChooseGradientColor;