import LoadingIcon from './LoadingIcon';

const Loading = ({className}) => {
	
    return (
        <div className={"loading" + (className ? " " + className : "")}>
            <LoadingIcon />
        </div> 	
    );
  
}
export default Loading;