import { useMutation } from "@apollo/client";
import { ADD_EDIT_PARAMETER_TEMPLATE } from "../../Queries/parameterTemplates";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseAddEditParameterTemplate = (selectedParameterTemplateID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,FillFormSelectedItemsWithObj,RemoveFormDataSelectedItems} = UseFormHandle({
        name:"",
        selectedParameters:[]
    });

    const [AddEditParameterTemplateMutation,{error,loading}] = useMutation(ADD_EDIT_PARAMETER_TEMPLATE,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditParameterTemplate);

            const notifyText = (selectedParameterTemplateID != 0 ? t("SuccessfullyUpdated") : t("NewParameterTemplateIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditParameterTemplate = () => {


        if(formData.name)
        {
            if(formData.selectedParameters.length > 0)
            {
                var parameters = [];
                for(let val of formData.selectedParameters)
                {
                    parameters.push(val.parameterID);
                }

                AddEditParameterTemplateMutation({
                    variables:{
                        parameterTemplateID:    selectedParameterTemplateID,
                        name:                   formData.name,
                        parameters:             parameters
                    }
                })
            }
            else
                AddNotification(t("ParameterTemplateHasNoParameters"),false); 
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditParameterTemplate,
        SetFormData,
        SetFormLangData,
        InitFormData,
        FillFormSelectedItemsWithObj,
        RemoveFormDataSelectedItems
    }

}

export default UseAddEditParameterTemplate;