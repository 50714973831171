import { useState,useEffect } from 'react';
import { SketchPicker } from 'react-color'

const ColorPicker = ({colorCode,append,text,OnChange}) => {


    const [color, SetColor] = useState(colorCode);
    const [showColorPicker, SetShowColorPicker] = useState(false); 
    const [colorPickerClassName, SetColorPickerClassName] = useState(false);

    useEffect(() => {

        SetColor(colorCode)

    },[colorCode])

    const ChangeColor = (colorCode) => {
        SetColor(() => {

            if(OnChange)
                OnChange(colorCode);

            return colorCode;
        })
    }


    const ShowColorPicker = (e) => {

        let y = e.clientY;
        let windowHeight = window.innerHeight;

        if(windowHeight - y < 350)
            SetColorPickerClassName("top");
        else
            SetColorPickerClassName("");

        SetShowColorPicker(true);
    }
    
    var className = ""
    if(append) className += " with-append";

    return(
        <div className="color-picker-group">
            
            <div 
                className="color" 
                style={{backgroundColor:color}}
                onClick ={(e) => ShowColorPicker(e)}
            ></div>
            
            {text ?
                <label>{text}</label>
            :null}

            {showColorPicker ?
                <>
                    <div className={"color-picker" + (colorPickerClassName ? " " + colorPickerClassName : "")}>
                        <SketchPicker 
                            color = {color}
                            onChangeComplete={ (color) => ChangeColor(color.hex) }
                        />
                    </div>
                    <div 
                        onClick ={() => SetShowColorPicker(false)}
                        className="color-picker-overlay"
                    ></div>
                </>
            :null}

        </div>
    )

}

export default ColorPicker;