import { useEffect, useState } from "react";
import { UseContentManager } from "../../../Library/UseContentManager";
import ControlsMenu from "../../ControlsMenu";
import { GetElementMargins,GetColumnWidthByCount } from "../../../Library/functions";
import recProdIcon from '../../../../../Media/Images/Icons/recommended_products.webp';
import { useTranslation } from "react-i18next";
import UseEditRecomendedProducts from "../../../Library/UseEditRecomendedProducts";
import Product from "./Product";

const RecommendedProducts = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {
        showHelpers,
        indexToShowMenu,
        SelectElement,
        formData,
        projectData,
        selectedLangData
    } = UseContentManager();

    const {t} = useTranslation();

    const {GetSelectedProducts} = UseEditRecomendedProducts(formData.userLang);
    const [allProducts,SetAllProducts] = useState([]);

    useEffect(() => {

        const GetProducts = async () => {
            const products = await GetSelectedProducts(data.selectedProducts);
            SetAllProducts(products);
        }
        if(data.selectedProducts.length > 0)
            GetProducts();

    },[data.selectedProducts])

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);
  
    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "recommendedProducts"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={margins.styleClassName +  " " + data.className} style={margins.styles}>
                
                {allProducts.length == 0 ?
                    <div className="cm-start-info big">
                        <img src={recProdIcon} alt="" />
                        <h2 className="cm-no-bottom-margin">{t("RecommendedProducts")}</h2>
                        {t("HereWillBeRecomendedProducts")}
                    </div>
                :  
                    <div className="cm-row cm-wrap cols-padding-20 cols-padding-md-15">
                        {allProducts.map((item) => {
                            return(
                                <div className = {"cm-col " + GetColumnWidthByCount(allProducts.length)}>
                                    <Product
                                        elementData={data}
                                        productData={item}
                                        projectData={projectData}
                                        selectedLangData = {selectedLangData}
                                        doNotUseAspectRatio = {allProducts.length == 1 ? true : false}
                                    />
                                </div>
                            )
                        })}
                    </div>
                }

            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default RecommendedProducts;