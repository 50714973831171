import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import buttonIcon from "../../../../../Media/Images/Icons/button.webp";
import inputIcon from "../../../../../Media/Images/Icons/input.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import NewsletterResponziveSettings from "./NewsletterResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { NEWSLETTER } from "../../../Library/elements";
import Select from "../../../../../GlobalComponents/Select";
import SelectBoxColors from "../../../../../GlobalComponents/SelectBoxColors";

const NewsletterSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(NEWSLETTER.newsletter); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    useEffect(() => {

        const settings = GetSettings(showSettingsElement.index,"newsletter");
        SetCompleteFormData(settings);

    },[showSettingsElement])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"newsletter");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"newsletter");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <NewsletterResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <NewsletterResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <NewsletterResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <NewsletterResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <NewsletterResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <NewsletterResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>  

            <div className="form-group">
                <label>{t("ClassName")}</label>
                <Input 
                    type = "text"
                    name = "className"
                    value = {formData.className}
                    OnChange = {(e) => SetS(e)}
                />
            </div>

            <InputCard
                title = {t("Input")}
                icon = {inputIcon}
                className="form-group"
            >
                <div className="row">
                    <div className="col-50">
                        <div className="form-group">
                            <label>{t("Size")}</label>
                            <Select
                                name = {"inputSize"}
                                OnChange = {(e) => SetS(e)}
                                value = {formData.inputSize}
                            >
                                <option value={""}>{t("Small")}</option>
                                <option value={"middle"}>{t("Middle")}</option>
                                <option value={"bigger"}>{t("Big")}</option>
                                
                            </Select>
                        </div>
                    </div>
                </div>
            </InputCard>

            <InputCard
                title = {t("Button")}
                icon = {buttonIcon}
                className="form-group"
            >
                <div className="row">
                    <div className="col-50">
                        <div className="form-group">
                            <label>{t("Color")}</label>
                            <Select
                                name = {"color"}
                                OnChange = {(e) => SetS(e)}
                                value = {formData.color}
                            >
                                <SelectBoxColors />
                                
                            </Select>
                        </div>
                    </div>
                    <div className="col-50">
                        <div className="form-group">
                            <label>{t("Size")}</label>
                            <Select
                                name = {"size"}
                                OnChange = {(e) => SetS(e)}
                                value = {formData.size}
                            >
                                <option value={""}>{t("Small")}</option>
                                <option value={"middle"}>{t("Middle")}</option>
                                <option value={"bigger"}>{t("Big")}</option>
                                
                            </Select>
                        </div>
                    </div>   
                </div>
            </InputCard>  
                
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default NewsletterSettings;