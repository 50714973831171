import { useTranslation } from "react-i18next";
import Input from "../../../../../GlobalComponents/Input";
import WindowWidthResponziveInfo from "../WindowWidthResponziveInfo";
import Select from "../../../../../GlobalComponents/Select";

const TextResponziveSettings = ({size,formData,OnChange}) => {

    const {t} = useTranslation();

    return (
        <>
            <WindowWidthResponziveInfo size={size} />
            
            <div className="row">
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("FontSize")}</label>
                        <Select 
                            type = "number"
                            name = {"fs" + size}
                            value = {formData["fs" + size]}
                            OnChange = {(e) => OnChange(e)}
                        >
                            <option value="12">12px</option>
                            <option value="13">13px</option>
                            <option value="14">14px</option>
                            <option value="15">15px</option>
                            <option value="16">16px</option>
                            <option value="17">17px</option>
                            <option value="18">18px</option>
                            <option value="19">19px</option>
                            <option value="20">20px</option>
                            <option value="22">22px</option>
                            <option value="24">24px</option>
                            <option value="26">26px</option>
                            <option value="28">28px</option>
                            <option value="30">30px</option>
                            
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingTop")}</label>
                        <Input 
                            type = "number"
                            name = {"mt" + size}
                            value = {formData["mt" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingBottom")}</label>
                        <Input 
                            type = "number"
                            name = {"mb" + size}
                            value = {formData["mb" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TextResponziveSettings;