import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins } from "../../Library/functions";
import faqIcon from '../../../../Media/Images/Icons/faq.webp';
import { useTranslation } from "react-i18next";
import { GET_SELECTED_FAQS } from "../../Queries/contentManager";
import { useApolloClient } from "@apollo/client";
import { useState,useEffect } from "react";
import AnimateHeight from 'react-animate-height';
import Arrow from "../../../../GlobalComponents/Arrow";

const Faqs = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {t} = useTranslation();
    const client = useApolloClient();
    const {formData,projectData,showHelpers,indexToShowMenu,SelectElement} = UseContentManager();
    const [faqsList, SetFaqList] = useState([]);
    const [height, SetHeight] = useState([]);

    const borderRadiusStyle = {borderRadius:data.borderRadius + "px"};

    useEffect(() => {
        if(data.selectedFaqs.length > 0)
            GetFaqs();
    },[data.selectedFaqs])

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);

    const ShowAnswer = (index) => {

        const newH = [...height];
        newH[index] = height[index] === 0 ? 'auto' : 0;
        SetHeight(newH);
        
    }

    const GetFaqs = async() => {
        var faqListData = await client.query({
            query: GET_SELECTED_FAQS,
            variables:{
                lang:formData.selectedLang,
                faqIDs: data.selectedFaqs
            },
            fetchPolicy:"network-only"
        });

        var items = [];
        var height = [];
        for(let id of data.selectedFaqs)
        {
            for(let val of faqListData.data.AllFaqsByFaqIDs)
            {
                if(id == val.faqID)
                {
                    var item = {...val};
                    items.push(item);
                    height.push(0);
                }
            }
        }

        SetHeight(height);
        SetFaqList(items);
    }
        
    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "faq"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={margins.styleClassName + " " + data.className} style={margins.styles}>
                
                {faqsList.length == 0 ?
                    <div className="cm-start-info big">
                        <img src={faqIcon} alt="" />
                        <h2 className="cm-no-bottom-margin">{t("Faqs")}</h2>
                        {t("HereWillBeSelectedFaqs")}
                    </div>
                :
                    <div className="cm-faq-element">
                        {faqsList.map((item,index) => (

                            <div 
                                key={item.faqID} 
                                className={height[index] === 0 ? "faq-question" : "faq-question open"}
                            >
                                <div 
                                    style={borderRadiusStyle}
                                    className="title" 
                                    onClick={() => ShowAnswer(index)} 
                                >
                                    <Arrow 
                                        size = {"small"}
                                        color = {projectData.buttonBgColor}
                                        className={(height[index] === 0 ? "" : " down")} 
                                    />
                                    <h4>
                                        <span dangerouslySetInnerHTML={{__html:item.question}}></span>
                                    </h4>
                                </div>
                                <AnimateHeight
                                    id='example-panel'
                                    duration={ 500 }
                                    height={ height[index] } // see props documentation below
                                >
                                    <div className="answer">
                                        <p dangerouslySetInnerHTML={{__html:item.answer}}></p>
                                    </div>
                                </AnimateHeight>

                            </div>
                        ))}
                    </div>
                }
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Faqs;