import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useMutation } from "@apollo/client";
import { UPLOAD_IMAGES, GET_ALL_IMAGES } from "../../Queries/fileManager";
import { ValidateImageMimeType, ValidateSize } from "../../../../GlobalFunctions/images";
import { useTranslation } from "react-i18next";
import { MAX_IMAGE_FILE_SIZE_TO_UPLOAD } from "../../../../Config";

const UseUploadImages = (client) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [UploadImgs,{loading,error}] = useMutation(UPLOAD_IMAGES,{
        onCompleted:(d) => {

            const { AllContentManagerImages } = client.readQuery({ query: GET_ALL_IMAGES });
            
            var newArray = [...d.UploadContentManagerImages,...AllContentManagerImages];

            client.writeQuery({ 
                query:GET_ALL_IMAGES,
                data:{
                    AllContentManagerImages: newArray
                } 
            });
        }
    });

    const UploadImages = async (files) => {

        var checkSize = true;
        var checkImage = true;

        for(let val of files)
        {
            var isImg = await ValidateImageMimeType(val);

            if(!isImg)
                checkImage = false;

            if(!ValidateSize(val))
                checkSize = false;
        }

        if(checkSize)
        {
            if(checkImage)
            {
                UploadImgs({
                    variables:{
                        files
                    }
                })
            }else
                AddNotification(t("SomeFileIsNotImage"),false);
        }else
            AddNotification(t("MaxAllowedImageFileSizeIs") + " " + (MAX_IMAGE_FILE_SIZE_TO_UPLOAD / 1000000) + "MB",false);
    }

    return{
        loading,
        error,
        UploadImages
    }
}

export default UseUploadImages;