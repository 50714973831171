import gql from 'graphql-tag';

export const GET_ALL_CUSTOMERS = gql`
    query AllCustomers($limit:Int,$offset:Int,$searchText:String){
        AllCustomers(
            limit:$limit,
            offset:$offset,
            searchText:$searchText
        ){
            customerID
            hash
            photo
            name
            surname
            email
            tel
            backgroundColor
            lang
        }

        AllCustomersCount(searchText:$searchText)
    }
`;

export const GET_CUSTOMER_DATA = gql`
    query CustomerData($customerID:ID){
        CustomerBy(param:"customerID", value:$customerID){
            customerID
            lang
            name
            surname
            email
            tel
            photo
            street
            street2
            city
            zip
            state
            countryID
            company
            ic
            dic
            icdph
            deliveryName
            deliverySurname
            deliveryStreet
            deliveryStreet2
            deliveryCity
            deliveryZip
            deliveryState
            deliveryCountryID
            internalNote
            bankAccount
            swift
            iban
            customerRoles{
                customerRoleCustomerID
                customerRoleID
                customerRole{
                    name
                    backgroundColor
                }
            }
        }
        AllCountries{
            countryID
            name
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const ADD_EDIT_CUSTOMER = gql`
    mutation AddEditCustomer(
        $customerID:ID,
        $lang:String,
        $name:String,
        $surname:String,
        $email:String,
        $tel:String,
        $photo:Upload,
        $street:String,
        $street2:String,
        $city:String,
        $zip:String,
        $state:String,
        $countryID:ID,
        $company:String,
        $ic:String,
        $dic:String,
        $icdph:String,
        $deliveryName:String,
        $deliverySurname:String,
        $deliveryStreet:String,
        $deliveryStreet2:String,
        $deliveryCity:String,
        $deliveryZip:String,
        $deliveryState:String,
        $deliveryCountryID:ID,
        $internalNote:String,
        $bankAccount:String,
        $swift:String,
        $iban:String,
        $selectedCustomerRoles:[CustomerRolesInput],
    ){
        AddEditCustomer(
            customerID:$customerID,
            lang:$lang,
            name:$name, 
            surname:$surname, 
            email:$email, 
            tel:$tel, 
            photo:$photo, 
            street:$street, 
            street2:$street2, 
            city:$city, 
            zip:$zip, 
            state:$state, 
            countryID:$countryID, 
            company:$company,
            ic:$ic, 
            dic:$dic, 
            icdph:$icdph, 
            deliveryName:$deliveryName, 
            deliverySurname:$deliverySurname, 
            deliveryStreet:$deliveryStreet, 
            deliveryStreet2:$deliveryStreet2, 
            deliveryCity:$deliveryCity, 
            deliveryZip:$deliveryZip, 
            deliveryState:$deliveryState, 
            deliveryCountryID:$deliveryCountryID,
            internalNote: $internalNote,
            bankAccount:$bankAccount,
            swift:$swift,
            iban:$iban,
            selectedCustomerRoles:$selectedCustomerRoles,
        ){
            customerID
        }
    }
`;

export const DELETE_CUSTOMERS = gql`
    mutation DeleteCustomers($customerIDs:ID) {
        DeleteCustomers(customerIDs:$customerIDs)
    }
`;

