const DashBoard = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g id="Vote_In_Computer">
                    <g>
                        <path style={{"fill":primaryColor}} d="M440.004,82.996h-92.51c-6.075,0-11-4.925-11-11s4.925-11,11-11h92.51c6.075,0,11,4.925,11,11    S446.079,82.996,440.004,82.996z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}}  d="M440.004,143.992h-92.51c-6.075,0-11-4.925-11-11s4.925-11,11-11h92.51c6.075,0,11,4.925,11,11    S446.079,143.992,440.004,143.992z"/>
                    </g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M440.004,204.987h-92.51c-6.075,0-11-4.925-11-11s4.925-11,11-11h92.51c6.075,0,11,4.925,11,11    S446.079,204.987,440.004,204.987z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}}  d="M440.004,265.983h-92.51c-6.075,0-11-4.925-11-11s4.925-11,11-11h92.51c6.075,0,11,4.925,11,11    S446.079,265.983,440.004,265.983z"/>
                    </g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M470.5,0h-429C18.62,0,0,18.62,0,41.5v304.98c0,22.88,18.62,41.5,41.5,41.5h157.75V490h-49.99c-6.08,0-11,4.92-11,11    s4.92,11,11,11h213.48c6.08,0,11-4.92,11-11s-4.92-11-11-11h-49.99V387.98H470.5c22.88,0,41.5-18.62,41.5-41.5V41.5    C512,18.62,493.38,0,470.5,0z M221.25,490V387.98h69.5V490H221.25z M490,346.48c0,10.75-8.75,19.5-19.5,19.5h-429    c-10.75,0-19.5-8.75-19.5-19.5v-19.5h468V346.48z M490,304.98H22V41.5C22,30.75,30.75,22,41.5,22h429c10.75,0,19.5,8.75,19.5,19.5    V304.98z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}}  d="M134.008,174.49c-22.882,0-41.498-18.616-41.498-41.498s18.616-41.498,41.498-41.498    s41.498,18.616,41.498,41.498S156.891,174.49,134.008,174.49z M134.008,113.494c-10.751,0-19.498,8.747-19.498,19.498    s8.747,19.498,19.498,19.498s19.498-8.747,19.498-19.498S144.76,113.494,134.008,113.494z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}}  d="M195.004,265.983H71.996c-6.075,0-11-4.925-11-11c0-19.217,7.701-37.328,21.684-50.997    c13.854-13.542,32.082-20.999,51.328-20.999c39.699,0,71.996,32.297,71.996,71.996    C206.004,261.059,201.079,265.983,195.004,265.983z M84.24,243.983h98.544c-5.024-22.295-24.986-38.996-48.776-38.996    C109.734,204.987,89.366,221.688,84.24,243.983z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}}  d="M286.5,265.98c-7.624,0.185-13.26-8.221-10.16-15.2c2.753-7.143,12.651-9.08,17.94-3.58    c0.999,1.022,1.83,2.241,2.37,3.58C299.764,257.748,294.116,266.168,286.5,265.98z"/>
                    </g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M286.5,204.99c-4.348,0.03-8.537-2.741-10.16-6.79c-2.375-5.406,0.451-12.228,5.95-14.37c2.674-1.117,5.746-1.117,8.42,0    c7.156,2.824,9.072,12.587,3.57,17.94C292.242,203.791,289.373,204.999,286.5,204.99z"/>
                    </g>
                    <g>
                        <path style={{"fill":secondaryColor}}  d="M286.5,143.973c-7.586,0.031-13.267-8.124-10.16-15.193c3.051-6.943,12.499-8.925,17.94-3.57    C300.92,131.744,295.822,143.936,286.5,143.973z"/>
                    </g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M286.5,83c-8.667,0.075-14.042-9.942-9.15-17.11c4.715-7.258,16.105-6.156,19.3,1.9c1.126,2.654,1.131,5.751,0,8.41    C295.044,80.257,290.845,83.025,286.5,83z"/>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default DashBoard;
