import { useEffect,useMemo } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import Input from '../../../GlobalComponents/Input';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditForm from '../Library/UseAddEditForm';
import UseGetForm from '../Library/UseGetForm';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseAuth } from '../../Auth/Library/UseAuth';
import RadioButton from '../../../GlobalComponents/RadioButton';
import { TINY_MCE_TOOLBAR_MEDIA } from '../../../Config';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import Select from '../../../GlobalComponents/Select';
import linkIcon from '../../../Media/Images/Icons/link.webp';
import InputCard from '../../../GlobalComponents/InputCard';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';

Modal.setAppElement('#root');

const AddEditForm = ({selectedFormID,SetSelectedFormID,OnAddEditSuccess}) => {

    const {user} = UseAuth();
    const {t} = useTranslation();

    var itemsToTranslate = [
        {name:"Děkovací stránka",param:"popUpText"}
    ];

    const {
        allLanguageMutations,
        allFormThanksPageTypes,
        allProjects,
        mainLang,
        loading:getLoading,
        error:getError
    } = UseGetForm(selectedFormID,(d) => {
        InitFormData(d);
    });

    const {
        loading,
        error,
        formData,
        selectedProjectID, 
        projectLinks,
        translatedLangsCount,
        translatedLang,
        SetSelectedProjectID,
        AddEditForm,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj
    } = UseAddEditForm(selectedFormID,user.lang,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    useEffect(() => {
        if(allProjects && allProjects.length > 0 )
            SetSelectedProjectID(allProjects[0].projectID);
    },[allProjects])

    var selectedLinkID = useMemo(() => {

        var arr = formData.thanksLinks.filter((item) => item.projectID == selectedProjectID); 
        if(arr[0]) 
            return arr[0].linkID;
        else
            return 0;

    },[selectedProjectID,formData.thanksLinks])

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedFormID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {selectedFormID === 0 ? t("AddForm") : t("EditForm")}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedFormID === 0 ? false : true}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedFormID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        :  
                            <>
                                <div className="form-group">
                                    <label>*{t("FormName")}</label>
                                    <Input
                                        name="name"
                                        value={formData.name}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>

                                <div className={(formData.formThanksPageTypeID != 1 ? "form-group" : "")}>
                                    <label>*{t("ThanksPage")} ({formData.selectedLang})</label>
                                    <div className="radio-container">
                                        {allFormThanksPageTypes && allFormThanksPageTypes.map((item) => (
                                            <RadioButton
                                                key={item.formThanksPageTypeID}
                                                text={item.name}
                                                name="formThanksPageTypeID"
                                                id={"formThanksPageTypeID-" + item.formThanksPageTypeID}
                                                value={item.formThanksPageTypeID}
                                                checked = {formData.formThanksPageTypeID == item.formThanksPageTypeID ? true : false}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        ))}
                                    </div>
                                </div>
                                {formData.formThanksPageTypeID == 2 ?
                                    <div>
                                        <TinyMCEEditor 
                                            value = {GetFormLangValue("popUpText")}
                                            OnEditorChange={(content) => SetFormLangData("popUpText",content)}
                                            toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                                            useMediaEditor={true}
                                        />
                                    </div>
                                :null}
                                {formData.formThanksPageTypeID == 3 ?
                                    <div>
                                        <InputCard
                                            title = {"*" + t("Link")}
                                            icon = {linkIcon}
                                            headerChildren = {
                                                <>
                                                    {allProjects && allProjects.length > 0 ? 
                                                        <Select 
                                                            className = "narrow"
                                                            name="selectedProjectID"
                                                            value={selectedProjectID}
                                                            OnChange={(e) => {
                                                                SetSelectedProjectID(e.target.value);
                                                            }}
                                                        >
                                                            {allProjects.map((item) => (
                                                                <option 
                                                                    key={item.projectID} 
                                                                    value = {item.projectID}
                                                                >{item.name}</option>
                                                            ))}
                                                        </Select>
                                                    :null}
                                                </>
                                            }
                                        >   
                                            <Select
                                                className="form-group"
                                                name = "linkID"
                                                OnChange = {(e) => SetFormDataSelectedItemsWithObj(e,"thanksLinks","projectID",selectedProjectID)}
                                                value={selectedLinkID}
                                            >
                                                <option 
                                                    key= {0}
                                                    value = {0}
                                                >{"---" + t("Choose") + "---"}</option>

                                                {projectLinks && projectLinks.map((item) => (
                                                    <option 
                                                        key= {item.linkID}
                                                        value = {item.linkID}
                                                    >{item.name}</option>
                                                ))}
                                                
                                            </Select>

                                        
                                        </InputCard>
                                    </div>
                                :null}
                                
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditForm()}
                    >
                        {(selectedFormID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditForm;