import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditBillingInformation from "./AddEditBillingInformation";
import { UseAuth } from '../../Auth/Library/UseAuth';
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllBillingInformations from "../Library/UseGetAllBillingInformations";
import UseDeleteBillingInformations from "../Library/UseDeleteBillingInformations";
import ModalNotification from "../../../GlobalComponents/ModalNotification";

const BillingInformation = () => {

    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedBillingInformationID, SetSelectedBillingInformationID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedBillingInformationIDs,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete,
        SelectAll,
        SelectRow
    } = UseGetAllBillingInformations(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteBillingInformations
    } = UseDeleteBillingInformations(selectedBillingInformationIDs,(d) => UpdateListAfterDelete(d));

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("BillingInformationList")}
                        OnAddButtonClick={() => SetSelectedBillingInformationID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (billingInformationID) => SetSelectedBillingInformationID(billingInformationID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedBillingInformationID || selectedBillingInformationID == 0 ?
                <AddEditBillingInformation
                    selectedBillingInformationID = {selectedBillingInformationID}
                    SetSelectedBillingInformationID = {SetSelectedBillingInformationID}
                    OnAddEditSuccess={(d) => {
                        UpdateListAfterAddEdit(selectedBillingInformationID,d);
                        SetSelectedBillingInformationID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteBillingInformations")} 
                    CallBack={DeleteBillingInformations} 
                />
            :null}

        </>
    )
}

export default BillingInformation;