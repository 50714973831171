import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_MULTIPLE_LINK_PRODUCTS } from "../../Eshop/Queries/products";

const UseAddMultipleProducts = (linkID,selectedProductIDs,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,SetFormLangData,GetFormLangValue} = UseFormHandle({
        onBothSides:0,
    });

    const [AddRelatedProductMutation,{error,loading}] = useMutation(ADD_MULTIPLE_LINK_PRODUCTS,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddMultipleLinkProducts);

            const notifyText = t("NewProductsAreInCategory");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddRelatedProduct = () => {

        if(selectedProductIDs && selectedProductIDs.length > 0)
        {    
            AddRelatedProductMutation({
                variables:{
                    linkID:      linkID,
                    productIDs:  selectedProductIDs
                }
            })
           
        }
        else
            AddNotification(t("YouHaveNotChoosenProducts"),false); 
        
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddRelatedProduct,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue
    }

}

export default UseAddMultipleProducts;