import { useQuery } from "@apollo/client";
import { useState,useEffect } from 'react';
import { GET_NEWSLETTER_STATISTICS } from "../Queries/newsletter";
import { useTranslation } from "react-i18next";
import { PROJECT_IMAGE_URL, SERVER_URL } from "../../../Config";
import { GetNumberString } from "../../../GlobalFunctions/helper";
import globalIcon from '../../../Media/Images/Icons/global.webp';

const UseGetNewsletterStatistics = (lang) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [headerData,SetHeaderData] = useState(null);

    const {data,loading,error} = useQuery(GET_NEWSLETTER_STATISTICS,{
        fetchPolicy:"network-only"
    });

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllProjects,data.AllLanguageMutations,data.NumberOfAllNotUnsubscribedNewsletterEmailsInLangs);
            SetContent(contentData);

            const headerData = GetHeaderData(data.AllProjects,data.NumberOfAllNotUnsubscribedNewsletterEmails);
            SetHeaderData(headerData);
        }

    },[data])


    const GetHeaderData = (projects,numberOfAllNotUnsubscribedNewsletterEmails) => {

        var headerData = [{
            value:"",
            className:"static smallest"
        },{
            value:<>
                <img className="cursor-help" title={t("Global")} src={globalIcon} />
                <p>{GetNumberString(numberOfAllNotUnsubscribedNewsletterEmails,lang)}</p>
            </>,
            className:"static text-center"
        }];

        for(let val of projects)
        {
            var favicon = "";
            if(val.favicon)
                favicon = SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + val.favicon;

            headerData.push({
                value: <>
                    <img className="cursor-help" title={val.name} src={favicon} />
                    <p>{GetNumberString(val.newsletterStatistics.numberOfAllNotUnsubscribedEmails,lang)}</p>
                </>,
                className:"static text-center"
            })
        }

        return headerData;
    }

    const GetContentData = (projects,languages,numberOfAllNotUnsubscribedNewsletterEmailsInLangs) => {

        var contentData = [];

        for(let val of languages)
        {
            var row = [{
                value:<strong className="cursor-help" title={val.title}>{val.suffix}</strong>,
                className:"static smallest text-center"
            }]

            var checkGlobal = false;
            for(let n of numberOfAllNotUnsubscribedNewsletterEmailsInLangs)
            {
                if(n.lang == val.suffix)
                {
                    row.push({
                        value:(n.number ? n.number : 0),
                        className:"static text-center"
                    });
                    checkGlobal = true;
                }
            }

            if(!checkGlobal)
                row.push({
                    value:0,
                    className:"static text-center"
                });

            for(let p of projects)
            {
                var check = false;
                for(let l of p.newsletterStatistics.numberOfAllNotUnsubscribedEmailsInLang)
                {
                    if(l.lang == val.suffix)
                    {
                        row.push({
                            value:(l.number ? l.number : 0),
                            className:"static text-center"
                        });
                        check = true;
                    }
                }

                if(!check)
                    row.push({
                        value:0,
                        className:"static text-center"
                    });
            }

            var cData = {
                data:row,
            }

            cData.rowID    = val.suffix;
            cData.selected = false;

            contentData.push(cData)
        }

        return contentData;
    }

    return{
        loading,
        error,
        headerData,
        content
    }

}

export default UseGetNewsletterStatistics;