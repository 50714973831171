import gql from 'graphql-tag';

export const ADD_RELATED_PRODUCT = gql`
    mutation AddRelatedProduct(
        $productID:ID, 
        $relProductID:[ID], 
        $onBothSides:Int
    ){
        AddRelatedProduct(
            productID:$productID,
            relProductID: $relProductID, 
            onBothSides:$onBothSides
        )
    }
`;

export const GET_RELATED_PRODUCTS = gql`
    query AllAdminRelatedProducts($productID:ID,$lang:String){
        AllAdminRelatedProducts(productID:$productID,lang:$lang){
            relatedProductID
            relProductID
            name
            mainPhoto
        }
    }
`;

export const DELETE_RELATED_PRODUCTS = gql`
    mutation DeleteRelatedProducts($relatedProductIDs:ID) {
        DeleteRelatedProducts(relatedProductIDs:$relatedProductIDs)
    }
`;