import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import List from '../../../GlobalComponents/List/List';
import UseChooseMarketingBanners from '../Library/UseChooseMarketingBanners';

Modal.setAppElement('#root');

const ChooseMarketingBanners = ({
    formData,
    SetShowAddMarketingBanners,
    FillFormSelectedItemsWithObj
}) => {

    var selectedMarketingBanners = [];
    if(formData.selectedMarketingBanners && formData.selectedMarketingBanners.length > 0)
    {
        for(let val of formData.selectedMarketingBanners)
        {
            selectedMarketingBanners.push(val.marketingBannerID);
        }
    }
    
    const {t} = useTranslation();

    const {
        content,
        loading,
        error,
        headerData,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        FilterArticles
    } = UseChooseMarketingBanners(selectedMarketingBanners,FillFormSelectedItemsWithObj);

    var err = "";
    if(error)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddMarketingBanners(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("AddMarketingBanners")}
                OnClose ={() => SetShowAddMarketingBanners(false)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <div className="modal-body-no-padding">
                        <List 
                            headerClassName={"no-top-border"}
                            headerData = {headerData} 
                            contentData = {content}
                            isSortable = {false}
                            loading = {loading}
                            hideOptions = {true}
                            OnSelectAll = {(e) => SelectAll(e)}
                            options = {{
                                OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                            }}
                        />
                    </div> 
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddMarketingBanners(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )
}

export default ChooseMarketingBanners;