import Loading from '../../../GlobalComponents/Loading';
import Input from "../../../GlobalComponents/Input";
import UseResetPassword from '../Library/UseResetPassword';
import { useTranslation } from 'react-i18next';
import emailIcon from '../../../Media/Images/Icons/email_input.svg';
import Button from '../../../GlobalComponents/Button';
import { Link } from "react-router-dom";
import Success from '../../../GlobalComponents/Success';
import Error from '../../../GlobalComponents/Error';

const ResetPassword = () => {

    var loading = false;

    const {t} = useTranslation();
    const {
        formData,
        disabled,
        error,
		success,
        Reset,
        SetFormData
    } = UseResetPassword();

    return(
        <div className="auth-panel">
			{loading ?
				<Loading />
			:
				<>
					<div className="card">

						<div className="card-body">

							{success ?
								<Success 
									className = "d-block"
									text={`
										<p class="form-group">${t('AdminSuccessPasswordResetText1')}</p>
										<p><strong>${t('AdminSuccessPasswordResetText2')}</strong></p>
									`} 
								/>
							:
								<>
									<p className="form-group text-center">{t("FillYourEmailToSendGenerationLink")}</p>
									<p className="form-group text-center"> <strong>{t("LinkWillBeValidOnly15min")}</strong></p>
								
									<div className="form-group">

										<Input 
											OnChange={e => SetFormData(e)}
											value={formData.email}
											type="text" 
											name="email"
											placeholder={t('Email')} 
											ariaLabel="Email" 
											preppend={<img alt="" src={emailIcon} />}
										/>

									</div>
																
									<div className="form-group">
										<Button 
											disabled={disabled} 
											loading={loading} 
											OnClick={() => Reset()} 
											className="btn btn-primary full-width"
										>{t('Send')}</Button>
									</div>
								</>
							}
							
							{error ? 
								<Error text = {error} />
							:null}
							
							<div className="text-center">
								<Link to='/'>{t('Back')}</Link>
							</div>
							
						</div>
					</div>
				</>
			}
        </div>
    )
}

export default ResetPassword;