import { UseContentManager } from "../../../Library/UseContentManager";
import ControlsMenu from "../../ControlsMenu";
import { GetElementMargins } from "../../../Library/functions";
import videoGalleryIcon from '../../../../../Media/Images/Icons/video_gallery.webp';
import { useTranslation } from "react-i18next";
import VideoGalleryItem from "./VideoGalleryItem";
import VideoGallerySlideshow from "./VideoGallerySlideshow";

const VideoGallery = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {t} = useTranslation();
    const {showHelpers,indexToShowMenu,SelectElement} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);
    const borderRadiusStyle = {borderRadius:data.borderRadius + "px"};
        
    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "videoGallery"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={margins.styleClassName + " " + data.className} style={margins.styles}>
                
                {data.selectedVideos.length == 0 && !data.mainLeftVideo && !data.mainRightVideo ?
                    <div className="cm-start-info big">
                        <img src={videoGalleryIcon} alt="" />
                        <h2 className="cm-no-bottom-margin">{t("VideoGallery")}</h2>
                        {t("HereWillBeVideoGallery")}
                    </div>
                :
                    (data.videoGalleryTypeID == 1 ?
                        <div className="cm-video-gallery">
                            
                            <div className="cm-row cm-wrap cols-padding-15">
                                <div className="cm-col cm-col-25 cm-col-sm-100"></div>
                                <div className="cm-col cm-col-25 cm-col-sm-50 cm-col-xxs-100 main-left">
                                    {data.mainLeftVideo ?
                                        <VideoGalleryItem 
                                            data={{...data.mainLeftVideo,videoTypeID:data.videoTypeID}}
                                            borderRadiusStyle = {borderRadiusStyle}
                                        />
                                    :null}
                                </div>
                                <div className="cm-col cm-col-25 cm-col-sm-50 cm-col-xxs-100 main-right">
                                    {data.mainRightVideo ?
                                        <VideoGalleryItem 
                                            data={{...data.mainRightVideo,videoTypeID:data.videoTypeID}}
                                            borderRadiusStyle = {borderRadiusStyle}
                                        />
                                    :null}
                                </div>
                                <div className="cm-col cm-col-25 cm-col-sm-100"></div>
                            </div>

                            <div className="slider-container">
                                <div className="slider-panel">
                                    {data.selectedVideos.map((item,index) => (

                                        <VideoGalleryItem 
                                            key={index}
                                            data={{...item,videoTypeID:data.videoTypeID}}
                                            borderRadiusStyle = {borderRadiusStyle}
                                        />

                                    ))}
                                </div>
                            </div>

                        </div>
                    :
                        <VideoGallerySlideshow
                            selectedVideos={data.selectedVideos}
                            videoTypeID = {data.videoTypeID}
                            slideshowTheme = {data.slideshowTheme}
                        />
                    )

                }
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default VideoGallery;