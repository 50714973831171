import gql from 'graphql-tag';

export const ADD_EDIT_FAQ = gql`
    mutation AddEditFaq(
        $faqID:ID,
        $projectID:ID,
        $active: Int,
        $langs:[FaqLangsInput],
        $generateTranslations:GenerateFaqTranslationsInput
    ){
        AddEditFaq(
            faqID:$faqID,
            projectID:$projectID,
            active: $active, 
            langs:$langs,
            generateTranslations:$generateTranslations
        ){
            faqID
            question
        }
    }
`;

export const GET_FAQ = gql`
    query FaqWithLangs($faqID: ID){
        FaqWithLangs(faqID:$faqID){
            faqID
            projectID
            active
            dateEdit
            langs{
                lang
                question
                answer
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_ALL_FAQ = gql`
    query AllFaqs(
        $lang: String,
        $projectID:ID,
        $limit:Int,
        $offset:Int
    ){
        AllFaqs(
            lang:$lang,
            projectID:$projectID,
            limit:$limit,
            offset:$offset
        ){
            faqID
            active
            question
            answer
        }

        AllFaqsCount(lang:$lang,projectID:$projectID)
    }
`;

export const DELETE_FAQS = gql`
    mutation DeleteFaqs($faqIDs:ID) {
        DeleteFaqs(faqIDs:$faqIDs)
    }
`;

export const GENERATE_FAQ_LANG = gql`
    mutation GenerateFaqLang(
        $faqID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateFaqLang(
            faqID:$faqID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;