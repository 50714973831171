import {useQuery} from '@apollo/client';
import { GET_MEMBERSHIPS } from '../Queries/membership';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import YesNo from '../../../GlobalComponents/YesNo';
import { MEMBERSHIP_IMAGE_URL, SERVER_URL } from '../../../Config';
import { UseProject } from '../../../AdminProvider';

const UseGetAllMemberships = (projectID,lang) => {

    const [variables,SetVariables] = useState({
        lang,
        projectID
    });

    const {IsModulEnabled} = UseProject();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedMembershipIDs,SetSelectedMembershipIDs] = useState([]);

    const {data,loading,error,refetch} = useQuery(GET_MEMBERSHIPS,{
        fetchPolicy:"network-only",
        variables,
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllMemberships);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("Name"),
            className:"flex-1"
        },
        {
            value: t("ShowForPurchase"),
            className:"static text-center"
        },
        {
            value: t("Recurring"),
            className:"static text-center"
        }         
    ];

    if(IsModulEnabled(11))
        headerData.push({
            value: t("UseFreeTrialPeriod"),
            className:"static text-center"
        })

    if(IsModulEnabled(66))
        headerData.push({
            value: t("UseGuide"),
            className:"static text-center"
        })

    const GetContentData = (data) => {

        var contentData = [];
        var useFreeTrialPeriod = false;
        var useGuide = false;

        if(IsModulEnabled(11))
            useFreeTrialPeriod = true;
    
        if(IsModulEnabled(66))
            useGuide = true;

        for(let val of data)
        {
            var data = [
                {
                    elm:"img",
                    src: val.photo ? SERVER_URL + "/" + MEMBERSHIP_IMAGE_URL + "/mala_" + val.photo : "",
                    className:"no-padding-right"
                },
                {
                    value:val.name,
                    className:"flex-1"
                },
                {
                    value:<YesNo isYes = {!!val.showForPurchase} />,
                    className:"static text-center"
                },
                {
                    value:<YesNo isYes = {!!val.isRecurring} />,
                    className:"static text-center"
                },
                
            ];

            if(useFreeTrialPeriod)
                data.push({
                    value:<YesNo isYes = {!!val.useFreeTrialPeriod} />,
                    className:"static text-center"
                })

            if(useGuide)
                data.push({
                    value:<YesNo isYes = {!!val.useGuide} />,
                    className:"static text-center"
                })

            var cData = {data}

            cData.rowID    = val.membershipID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,membershipID) => {

        var checked = e.target.checked;
        var newSelectedMembershipIDs = [...selectedMembershipIDs];
        const newList = SelectMembershipID(content,membershipID,checked,newSelectedMembershipIDs)

        SetContent(newList);
        SetSelectedMembershipIDs(newSelectedMembershipIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedMembershipIDs = [];
        const newList = SelectMembershipID(content,"all",checked,newSelectedMembershipIDs)

        SetSelectedMembershipIDs(newSelectedMembershipIDs);
        SetContent(newList);
    }

    const SelectMembershipID = (list,membershipID,checked,selectedMembershipIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(membershipID == newList[i].rowID || membershipID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedMembershipIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedMembershipIDs.length; j++)
                    {
                        if(selectedMembershipIDs[j] == newList[i].rowID)
                            selectedMembershipIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectMembershipID(newList[i].subData,membershipID,checked,selectedMembershipIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        refetch();
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedMembershipIDs,
        SelectRow,
        SelectAll,
        UpdateList
    }

}

export default UseGetAllMemberships;