import {useApolloClient, useQuery} from '@apollo/client';
import { GET_ALL_MARKETING_BANNERS } from '../Queries/marketingBanner';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,MARKETING_BANNER_IMAGE_URL } from '../../../Config';

const UseGetAllMarketingBanners = () => {

    const client = useApolloClient();

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedMarketingBannerIDs,SetSelectedMarketingBannerIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_ALL_MARKETING_BANNERS,{
        fetchPolicy:"network-only"
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllMarketingBanners);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("MarketingBannerName"),
            className:"flex-1"
        } 
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.photo ? SERVER_URL + "/" + MARKETING_BANNER_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.name,
                        className:"flex-1"
                    } 
                ],
            }

            cData.rowID    = val.marketingBannerID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,orderID) => {

        var checked = e.target.checked;
        var newSelectedMarketingBannerIDs = [...selectedMarketingBannerIDs];
        const newList = SelectMarketingBannerID(content,orderID,checked,newSelectedMarketingBannerIDs)

        SetContent(newList);
        SetSelectedMarketingBannerIDs(newSelectedMarketingBannerIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedMarketingBannerIDs = [];
        const newList = SelectMarketingBannerID(content,"all",checked,newSelectedMarketingBannerIDs)

        SetSelectedMarketingBannerIDs(newSelectedMarketingBannerIDs);
        SetContent(newList);
    }

    const SelectMarketingBannerID = (list,orderID,checked,selectedMarketingBannerIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(orderID == newList[i].rowID || orderID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedMarketingBannerIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedMarketingBannerIDs.length; j++)
                    {
                        if(selectedMarketingBannerIDs[j] == newList[i].rowID)
                            selectedMarketingBannerIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectMarketingBannerID(newList[i].subData,orderID,checked,selectedMarketingBannerIDs)}
        }

        return newList;
    }

    const UpdateListAfterAddEdit = async (selectedMarketingBannerID,data) => {
        
        const { AllMarketingBanners } = client.readQuery({ query: GET_ALL_MARKETING_BANNERS });
        var newArray = [...AllMarketingBanners];

        if(selectedMarketingBannerID && selectedMarketingBannerID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].marketingBannerID == data.marketingBannerID)
                {
                    var d = {...newArray[i],
                        name: data.name
                    }

                    if(data.photo)
                        d.photo = data.photo;

                    newArray[i] = d;
                }
            } 
        }
        else
        {
            newArray.unshift({
                marketingBannerID: data.marketingBannerID,
                name: data.name,
                photo: data.photo
            })
        }
        
        client.writeQuery({ 
            query:GET_ALL_MARKETING_BANNERS,
            data:{
                AllMarketingBanners: newArray
            } 
        });
    }

    const UpdateListAfterDelete = async (IDs) => {

        var response = IDs.split(",");
        
        const { AllMarketingBanners} = client.readQuery({ query: GET_ALL_MARKETING_BANNERS });
        
        var newArray = [...AllMarketingBanners];
        
        for(let id of response)
        {
            for(let i in newArray)
            {
                if(newArray[i].marketingBannerID == id)
                {
                    newArray.splice(i,1);
                }
            } 
        } 
        
        client.writeQuery({ 
            query:GET_ALL_MARKETING_BANNERS,
            data:{
                AllMarketingBanners: newArray
            } 
        });
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedMarketingBannerIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    }

}

export default UseGetAllMarketingBanners;