import {useQuery} from '@apollo/client';
import { GET_ALL_CUSTOMERS } from '../Queries/customer';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,CUSTOMER_IMAGE_URL } from '../../../Config';

const UseChooseCustomers = (
    selectedCustomers,
    FillFormSelectedItemsWithObj
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    const [variables,SetVariables] = useState({
        searchText:"",
        limit:40,
        offset:0
    });
    
    const {data,loading,error,fetchMore} = useQuery(GET_ALL_CUSTOMERS,{
        variables,
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCustomers);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[data,selectedCustomers])

    var headerData = [
        {
            value: t("FullName"),
            className:"flex-1"
        },
        {
            value: t("Email"),
            className:"flex-1"
        },
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var text = val.email;
            if(val.name || val.surname)
                text = val.name + " " + val.surname;

            var cData = {
                data:[
                    {
                        elm: "lis",
                        backgroundColor: val.backgroundColor,
                        text: text,
                        photo: val.photo ? SERVER_URL + "/" + CUSTOMER_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right flex-1"
                    },
                    {
                        value:val.email,
                        className:"flex-1"
                    },
                ],
            }

            cData.selected = false;
            if(selectedCustomers && selectedCustomers.length > 0)
            {
                for(let v of selectedCustomers)
                {
                    if((v.deleted == undefined || v.deleted == false) && val.customerID == v.customerID)
                        cData.selected = true;
                }
            }

            cData.rowBackgroundColor    = val.backgroundColor;
            cData.rowName               = val.name;
            cData.rowSurname            = val.surname;
            cData.rowEmail              = val.email;
            cData.rowPhoto              = val.photo;
            cData.rowID                 = val.customerID;

            contentData.push(cData)
        }
        return contentData;
    }

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllCustomers: [...prev.AllCustomers, ...fetchMoreResult.AllCustomers]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    const FilterCustomers = (filterData) => {

        var newVariables = {...variables};
        var updated = false;
        
        if(filterData.searchText != "" || (filterData.searchText == "" && newVariables.searchText != ""))
        {
            newVariables.searchText = filterData.searchText;
            updated = true;
        }

        if(updated)
            SetVariables(newVariables)

    }

    const SelectRow = (e,data) => {

        SelectFormID(content,data.rowID);    
    }

    const SelectFormID = (list,rowID) => {

        for(let i = 0; i < list.length; i++)
        {
            if(rowID == list[i].rowID || rowID === "all")
            {              
                FillFormSelectedItemsWithObj("selectedCustomers","customerID",{
                    name: list[i].rowName,
                    surname: list[i].rowSurname,
                    email: list[i].rowEmail,
                    photo: list[i].rowPhoto,
                    customerID: list[i].rowID,
                    add:true,
                    deleted:false,
                    backgroundColor: list[i].rowBackgroundColor
                },false);

                break;
            }
        }
    }

    return{
        content,
        headerData,
        totalContentLength: data && data.AllCustomersCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        loading,
        error,
        SelectRow,
        FetchMore,
        GoToPage,
        FilterCustomers
    }

}

export default UseChooseCustomers;