import { useQuery } from "@apollo/client";
import { GET_QUESTIONNAIRE } from "../Queries/questionnaire";
import { useState } from "react";
import { CompleteLangsArray, GetMainLanguage } from "../../../GlobalFunctions/helper";
import { useTranslation } from "react-i18next";
import {GetQuestionnaireStepItemObj,GetQuestionnaireStepObj} from "./UseEditQuestionnaire";

const UseGetQuestionnaire = (selectedQuestionnaireID,projectID,lang,CallBack) => {

    const {t} = useTranslation();

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allQuestionTypes, SetAllQuestionTypes] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_QUESTIONNAIRE,{
        variables:{
            questionnaireID:selectedQuestionnaireID,
            lang,
            projectID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            console.log(d);
            const emptyLang = {
                welcomeMessage:""
            }

            if(selectedQuestionnaireID != 0)
            {
                let langs = CompleteLangsArray(d.QuestionnaireWithLangs.langs,d.AllLanguageMutations,emptyLang);
                
                CallBack({
                    internalName:               d.QuestionnaireWithLangs.internalName,
                    langs:                      langs,
                    questionnaireSteps:         GetQuestionnaireSteps(d.QuestionnaireWithLangs.questionnaireSteps),
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllQuestionTypes(d.AllQuestionTypes);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    const GetQuestionnaireSteps = (questionnaireSteps) => {
        
        var newSteps = [];
        if(questionnaireSteps && questionnaireSteps.length > 0)
        {
            for(let val of questionnaireSteps)
            {
                newSteps.push(GetQuestionnaireStepObj(val));
            }
        }

        return newSteps;
    }

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        mainLang,
        allQuestionTypes
    }

}

export default UseGetQuestionnaire;