const Delete = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M364.833,193.932h-43.111c-6.075,0-11,4.925-11,11v238.653c0,6.075,4.925,11,11,11h43.111
                            c6.075,0,11-4.925,11-11V204.932C375.833,198.857,370.908,193.932,364.833,193.932z M353.833,432.585h-21.111V215.932h21.111
                            V432.585z"/>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M190.278,193.932h-43.111c-6.075,0-11,4.925-11,11v238.653c0,6.075,4.925,11,11,11h43.111
                            c6.075,0,11-4.925,11-11V204.932C201.278,198.857,196.354,193.932,190.278,193.932z M179.278,432.585h-21.111V215.932h21.111
                            V432.585z"/>
                        <path 
                            style={{"fill":secondaryColor}}
                            d="M277.556,193.932h-43.111c-6.075,0-11,4.925-11,11v238.653c0,6.075,4.925,11,11,11h43.111
                            c6.075,0,11-4.925,11-11V204.932C288.556,198.857,283.631,193.932,277.556,193.932z M266.556,432.585h-21.111V215.932h21.111
                            V432.585z"/>
                    </g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M403.117,60.138h-75.806V32.626c0-17.99-14.64-32.626-32.635-32.626h-77.354c-17.995,0-32.635,14.636-32.635,32.626v27.512
                        h-75.806c-24.93,0-45.212,20.282-45.212,45.211v42.168c0,6.075,4.925,11,11,11h14.148v308.278
                        c0,24.926,20.285,45.205,45.219,45.205h243.924c24.934,0,45.219-20.279,45.219-45.205V158.518h14.148c6.075,0,11-4.925,11-11
                        V105.35C448.329,80.42,428.047,60.138,403.117,60.138z M206.688,32.626c0-5.859,4.771-10.626,10.635-10.626h77.354
                        c5.864,0,10.635,4.767,10.635,10.626v27.512h-98.623V32.626z M401.181,466.795c0,12.795-10.416,23.205-23.219,23.205H134.038
                        c-12.803,0-23.219-10.41-23.219-23.205V158.518h290.361V466.795z M426.329,136.518H85.671V105.35
                        c0-12.799,10.413-23.211,23.212-23.211h294.234c12.799,0,23.212,10.413,23.212,23.211V136.518z"/>
                </g>                
            </svg>
        </>
    )
}

export default Delete;