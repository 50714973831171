import {useQuery} from '@apollo/client';
import { GET_PRODUCT_SEARCH_TAGS } from '../../Queries/products';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

const UseGetAllProductSearchTags = (lang,productID) => {

    const [variables,SetVariables] = useState({
        lang,
        productID
    });

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedSearchTagIDs,SetSelectedSearchTagIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_PRODUCT_SEARCH_TAGS,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" || productID == 0 ? true : false)
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllProductSearchTags);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("SearchTagName"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.productSearchTagID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,productSearchTagID) => {

        var checked = e.target.checked;
        var newSelectedSearchTagIDs = [...selectedSearchTagIDs];
        const newList = SelectSearchTagID(content,productSearchTagID,checked,newSelectedSearchTagIDs)

        SetContent(newList);
        SetSelectedSearchTagIDs(newSelectedSearchTagIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedSearchTagIDs = [];
        const newList = SelectSearchTagID(content,"all",checked,newSelectedSearchTagIDs)

        SetSelectedSearchTagIDs(newSelectedSearchTagIDs);
        SetContent(newList);
    }

    const SelectSearchTagID = (list,productSearchTagID,checked,selectedSearchTagIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(productSearchTagID == newList[i].rowID || productSearchTagID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedSearchTagIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedSearchTagIDs.length; j++)
                    {
                        if(selectedSearchTagIDs[j] == newList[i].rowID)
                            selectedSearchTagIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectSearchTagID(newList[i].subData,productSearchTagID,checked,selectedSearchTagIDs)}
        }

        return newList;
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedSearchTagIDs,
        SelectRow,
        SelectAll
    }

}

export default UseGetAllProductSearchTags;