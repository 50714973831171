import { useEffect, useMemo,useState } from "react";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import InputCard from "../../../GlobalComponents/InputCard";
import Select from "../../../GlobalComponents/Select";
import { GetTimesOfDay,GetTimeInString } from "../../../GlobalFunctions/helper";
import { useTranslation } from "react-i18next";
import List from "../../../GlobalComponents/List/List";
import UseEditVariationsInTimes from "../Library/UseEditVariationsInTimes";
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import Button from "../../../GlobalComponents/Button";
import ChooseReservationPlaceVariations from "./ChooseReservationPlaceVariations";

const Times = ({
    data,
    SetParentIndexData
}) => {

    const {t} = useTranslation();
    const {
        formData,
        SetFormData,
        RemoveFormDataSelectedItems,
        FillFormSelectedItemsWithObj
    } = UseFormHandle(data);

    const [showAddVariations,SetShowAddVariations] = useState(false);

    const {headerData,content} = UseEditVariationsInTimes(formData.selectedVariations);

    const times = useMemo(() => {

        const times = GetTimesOfDay(15);

        if(formData.timeFrom == 0 && formData.timeTo == 0)
        {
            SetFormData("timeFrom",times[0].minutes);
            SetFormData("timeTo",times[times.length - 1].minutes);
        }

        return times;

    },[]);

    useEffect(() => {
        SetParentIndexData(formData)
    },[formData])

    return(
        <InputCard
            title = {
                GetTimeInString(formData.timeFrom) + " - " + GetTimeInString(formData.timeTo)
            }
            headerChildren={
                <img 
                    className="cursor-pointer"
                    src={cancelIcon} 
                    onClick = {() => SetFormData("deleted",true)}
                />
            }
        >
            <div className="row">
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("TimeFrom")}</label>
                        <Select
                            name = "timeFrom"
                            value = {formData.timeFrom}
                            OnChange = {(e) => SetFormData(e)}
                        >
                            {times.map((item) => (
                                <option
                                    key={item.minutes}
                                    value={item.minutes}
                                >{item.time}</option>
                            ))} 
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("TimeTo")}</label>
                        <Select
                            name = "timeTo"
                            value = {formData.timeTo}
                            OnChange = {(e) => SetFormData(e)}
                        >
                            {times.map((item) => (
                                <option
                                    key={item.minutes}
                                    value={item.minutes}
                                >{item.time}</option>
                            ))} 
                        </Select>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("VariationsForReservationTimes")}
                    noBodyPadding={true}
                    headerChildren = {
                        <Button 
                            OnClick = {(e) => {
                                e.stopPropagation();
                                SetShowAddVariations(true)
                            }}
                            className="btn-primary narrow"
                        >
                            {formData.selectedVariations && formData.selectedVariations.length > 0 ? 
                                t("Edit") 
                            : 
                                t("Choose")
                            }
                        </Button>
                    }
                >
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {false}
                        replaceDotsImg = {cancelIcon}
                        ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedVariations","reservationPlaceVariationID",[data.rowID])}
                    />
                    
                </InputCard>
                {showAddVariations ? 
                    <ChooseReservationPlaceVariations 
                        formData = {formData}
                        SetShowAddVariations = {SetShowAddVariations}
                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                    />
                :null}
            </div>
        </InputCard>
    )
}

export default Times;