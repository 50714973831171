import gql from 'graphql-tag';

export const GET_ALL_ARTICLES = gql`
    query GetAllArticles(
        $lang: String,
        $searchText:String,
        $limit:Int,
        $offset:Int,
    ){
        AllArticles(
            lang:$lang
            searchText:$searchText,
            limit:$limit,
            offset:$offset
        ){
            articleID
            articleLangID
            photo
            title
            niceTitle
            publishedProjects{
                articleLangID
                isPublished
                projectID
                linkNiceName
                project{
                    projectID
                    name
                    favicon
                    domains{
                        name
                    }
                }
            }
        }

        AllArticlesCount(lang:$lang,searchText:$searchText)
    }
`;

export const GET_ARTICLE = gql`
    query ArticleWithLangs($articleID:ID,$lang:String){
        ArticleWithLangs(articleID:$articleID){
            articleID
            photo
            generateRandomRecommended
            alone
            withoutMainImage
            links{
                linkID
                link(lang:$lang){
                    name
                    projectID
                    project{
                        name
                        favicon
                    }
                }
            }
            recommendedArticles{
                articleID
                recommendedArticleID
                recommendedArticle(lang:$lang){
                    articleID
                    photo
                    title
                }
            }
            marketingBanners{
                articleID
                marketingBannerID
                marketingBanner(lang:$lang){
                    marketingBannerID
                    photo
                    name
                }
            }
            langs{
                articleLangID
                lang
                title
                niceTitle
                perex
                text
                projectData{
                    projectID
                    isPublished
                }
                publishDate
                metaTitle
                metaKeywords
                metaDescription
            }   
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }

        AllProjects(onlyActive:true){
            projectID
            name
        }
        AllForms(lang:$lang){
            formID
            name
        } 
    }
`;

export const ADD_EDIT_ARTICLE = gql`
    mutation AddEditArticle(
        $articleID:ID,
        $generateRandomRecommended:Int,
        $alone:Int,
        $withoutMainImage:Int,
        $photo:Upload,
        $links:[ArtilceLinksInput],
        $langs:[ArticleLangsInput],
        $recommnededArticles:[ID],
        $marketingBanners:[ID],
        $generateTranslations:GenerateArticleTranslationsInput
    ){
        AddEditArticle(
            articleID:$articleID,
            generateRandomRecommended:$generateRandomRecommended,
            alone:$alone,
            withoutMainImage:$withoutMainImage,
            photo:$photo,
            links:$links,
            langs:$langs,
            recommnededArticles:$recommnededArticles,
            marketingBanners:$marketingBanners,
            generateTranslations:$generateTranslations
        ){
            articleID
        }
    }
`;

export const DELETE_PROJECTS = gql`
    mutation DeleteProjects($projectIDs:ID) {
        DeleteProjects(projectIDs:$projectIDs)
    }
`;

export const PUBLISH_ARTICLE = gql`
    mutation PublishArticle(
        $articleID:ID!,
        $langs:[String],
        $sendNewsletter:Boolean
        $projectIDs:[ID]
    ){
        PublishArticle(
            articleID:$articleID,
            langs:$langs,
            sendNewsletter:$sendNewsletter,
            projectIDs:$projectIDs
        ){
            error{
                lang
                project
                message
            }
        }
    }
`;

export const UNPUBLISH_ARTICLE = gql`
    mutation UnPublishArticle(
        $articleID:ID!,
        $langs:[String],
        $projectIDs:[ID]
    ){
        UnPublishArticle(
            articleID:$articleID,
            langs:$langs,
            projectIDs:$projectIDs
        )
    }
`;

export const DELETE_ARTICLES = gql`
    mutation DeleteArticles($articleIDs:ID!) {
        DeleteArticles(articleIDs:$articleIDs)
    }
`;

export const GENERATE_ARTICLE_LANG = gql`
    mutation GenerateArticleLang(
        $articleID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateArticleLang(
            articleID:$articleID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;
