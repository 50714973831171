import { useState } from "react";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../../GlobalComponents/ContentHeader";
import ContentBody from "../../../GlobalComponents/ContentBody";
import InputCard from "../../../GlobalComponents/InputCard";
import UseGetAllBlockedDays from "../Library/UseGetAllBlockedDays";
import Calendar from "../../Calendar/Components/Calendar";
import { UseAuth } from "../../Auth/Library/UseAuth";
import Select from "../../../GlobalComponents/Select";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import Loading from "../../../GlobalComponents/Loading";
import NoItems from "../../../GlobalComponents/NoItems";
import UseBlockDay from "../Library/UseBlockDay";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import UseRemoveBlockedDay from "../Library/UseRemoveBlockedDay";

const BlockedDays = () => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    const [selectedDate,SetSelectedDate] = useState(null);

    const {
        calendarKey,
        formData,
        loading,
        error,
        allReservationPlaces,
        SetFormData,
        GetBlockedDays,
        RefreshCalendar
    } = UseGetAllBlockedDays(user.lang);

    const {
        loading:blockLoading,
        BlockDay,
    } = UseBlockDay(() => RefreshCalendar());

    const {
        showRemoveNotify,
        ShowRemoveNotification,
        RemoveBlockedDay
    } = UseRemoveBlockedDay(() => RefreshCalendar());

    return(
        <>
            <ContentHeader
                title = {t("BlockedDays")}        
            />
            <ContentBody>
                <InputCard
                    className="input-card-for-emailing"
                >
                    {error ?
                        <Error text={GetApolloErrorText(error)} />
                    :
                        (loading || blockLoading ?
                            <Loading />
                        :
                            (allReservationPlaces && allReservationPlaces.length > 0 ?
                                <>
                                    <div className="form-group">
                                        <label>{t("Place/Person")}</label>
                                        <Select
                                            name="reservationPlaceID"
                                            value={formData.reservationPlaceID}
                                            OnChange = {(e) => SetFormData(e)}
                                        >
                                            {allReservationPlaces && allReservationPlaces.map((item) => (
                                                <option
                                                    key={item.reservationPlaceID}
                                                    value = {item.reservationPlaceID}
                                                >{item.internalName}</option>
                                            ))}
                                            
                                        </Select>
                                    </div>
                                    <div className="form-group">
                                        <Calendar
                                            key={calendarKey}
                                            selectedDate = {selectedDate}
                                            isFloating = {true}
                                            showPast={true}
                                            showLegend={true}
                                            OnClickOnBlockedDay = {(date,dateStr) => {
                                                SetSelectedDate(dateStr);
                                                ShowRemoveNotification(dateStr,formData.reservationPlaceID);
                                            }}
                                            OnSelectDate = {(date,dateDb) => {
                                                
                                                BlockDay(dateDb,formData.reservationPlaceID);
                                                SetSelectedDate(dateDb);
                                            }}
                                            GetBlockedDays = {(month,year) => GetBlockedDays(month,year,formData.reservationPlaceID)}
                                        />
                                    </div>
                                </>
                            :
                                <NoItems text={t("AddSomePlacesToBlockDay")} />
                            )
                        )
                    }
                </InputCard>
                {showRemoveNotify ?
                    <ModalNotification 
                        yesNo={true} 
                        text={'Opravdu chcete odstranit tuto blokaci ?'} 
                        CallBack={RemoveBlockedDay} 
                    />
                :null}
            </ContentBody>
        </>
    )
}

export default BlockedDays;