import { useState,useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_FORM,GENERATE_FORM_LANG } from "../Queries/form";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GET_LINKS } from "../../Content/Queries/link";
import { useApolloClient } from "@apollo/client";
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";

const UseAddEditForm = (selectedFormID,lang,mainLang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const client = useApolloClient();
    const {settings} = UseSettings();
    
    const [selectedProjectID, SetSelectedProjectID] = useState(0);
    const [projectLinks, SetProjectLinks] = useState([]);
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj
    } = UseFormHandle({
        selectedLang:mainLang,
        formThanksPageTypeID:1,
        thanksLinks:[],
        thanksLinkID:0,
        name:"",
        langs:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_FORM_LANG,allLanguageMutations);

    useEffect(() => {

        if(selectedProjectID)
        {
            GetLinks();
        }
    },[selectedProjectID])

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditFormMutation,{error,loading}] = useMutation(ADD_EDIT_FORM,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    formID: d.AddEditForm.formID,
                    isEdit: (selectedFormID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditForm);
                
                const notifyText = (selectedFormID != 0 ? t("SuccessfullyUpdated") : t("NewFormIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("FormHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditForm = () => {

        if(formData.name)
        {
            let langs = [];
            for(const lang of formData.langs)
            {
                langs.push({
                    popUpText:  lang.popUpText,
                    lang:       lang.lang
                });
            }

            AddEditFormMutation({
                variables:{
                    formID:                 selectedFormID,
                    formThanksPageTypeID:   formData.formThanksPageTypeID,
                    thanksLinks:            formData.thanksLinks,
                    name:                   formData.name,
                    langs:                  langs,
                    generateTranslations:   formData.generateTranslations
                }
            })
        }
        else
        {
            AddNotification(t("NoNameNoGo"),false); 
        }
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const GetLinks = async () => {
        const data = await client.query({
            query:GET_LINKS,
            variables:{
                lang,
                projectID: selectedProjectID,
                isEshopCategory:0
            }
        });

        if(data.data)
            SetProjectLinks(data.data.AllLinks);
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        selectedProjectID, 
        projectLinks,
        translatedLangsCount,
        translatedLang,
        SetSelectedProjectID,
        AddEditForm,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj
    }

}

export default UseAddEditForm;