import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import Input from '../../../GlobalComponents/Input';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditCampaignEmail from '../Library/UseAddEditCampaignEmail';
import UseGetCampaignEmail from '../Library/UseGetCampaignEmail';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { TINY_MCE_TOOLBAR_MEDIA } from '../../../Config';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';
import Label from '../../../GlobalComponents/Label';
import UseSendTestEmail from '../../Newsletter/Library/UseSendTestEmail';
import InputCard from '../../../GlobalComponents/InputCard';
import emailIcon from '../../../Media/Images/Icons/email.webp';
import SendTestEmailForm from '../../Newsletter/Components/SendTestEmailForm';

Modal.setAppElement('#root');

const AddEditCampaignEmail = ({
    selectedCampaignEmailID,
    campaignID,
    projectID,
    SetSelectedCampaignEmailID,
    OnAddEditSuccess
}) => {

    const {t} = useTranslation();

    var itemsToTranslate = [
        {name:"Děkovací stránka",param:"popUpText"}
    ];

    const {
        allLanguageMutations,
        mainLang,
        loading:getLoading,
        error:getError
    } = UseGetCampaignEmail(selectedCampaignEmailID,projectID,(d) => {
        InitFormData(d);
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditCampaignEmail,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue
    } = UseAddEditCampaignEmail(selectedCampaignEmailID,campaignID,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    const {loading:testLoading, SendTestEmail} = UseSendTestEmail();

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedCampaignEmailID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content modal-for-emailing"}
        >
            <ModalHeader 
                title = {selectedCampaignEmailID === 0 ? t("AddCampaignEmail") : t("EditCampaignEmail")}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedCampaignEmailID === 0 ? false : true}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedCampaignEmailID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading || testLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        :  
                            <>
                                <div className="form-group">
                                    <label>{t("EmailSubject")} ({formData.selectedLang})</label>
                                    <Input
                                        name="subject"
                                        value={GetFormLangValue("subject")}
                                        OnChange={(e) => SetFormLangData(e)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-50">
                                        <div className="form-group">
                                            <label>{t("Preheader")} ({formData.selectedLang})</label>
                                            <Input
                                                name="preheader"
                                                value={GetFormLangValue("preheader")}
                                                OnChange={(e) => SetFormLangData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="form-group">
                                            <Label 
                                                text = {t("HoursNumber")} 
                                                infoContent = {[t("HoursNumberInfo")]}
                                            />
                                            <Input
                                                name="hours"
                                                value={formData.hours}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                   
                                    <label>{t("MessageText")} ({formData.selectedLang})</label>
                                    <TinyMCEEditor 
                                        value = {GetFormLangValue("text")}
                                        OnEditorChange={(content) => SetFormLangData("text",content)}
                                        toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                                        useMediaEditor={true}
                                        height = {1000}
                                    />

                                </div>
                                <SendTestEmailForm 
                                    className = ""
                                    initTestEmail = {formData.testEmail}
                                    formData = {formData}  
                                    OnEmailChange = {(email) => SetFormData("testEmail",email)}
                                />                           
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditCampaignEmail()}
                    >
                        {(selectedCampaignEmailID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditCampaignEmail;