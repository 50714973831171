import { useMutation } from "@apollo/client";
import { ADD_EDIT_CAMPAIGN } from "../Queries/campaign";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseAddEditCampaign = (selectedCampaignID,projectID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
        
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        FillFormSelectedItemsWithObj,
        RemoveFormDataSelectedItems
    } = UseFormHandle({
        name:                       "",
        linkID:                     0,
        active:                     1,
        isForNewsletter:            0,
        isForTherapy:               0,
        isForAndroidMobileAppID:    0,
        isForIOSMobileAppID:        0,
        useEmailsFromNewsletter:    0,
        selectedForms:              [], 
        selectedProducts:           [] 
    });

    const [AddEditCampaignMutation,{error,loading}] = useMutation(ADD_EDIT_CAMPAIGN,{
        onCompleted: async (d) => {
   
            if(OnCompleted)
                OnCompleted(d.AddEditCampaign);
            
            const notifyText = (selectedCampaignID != 0 ? t("SuccessfullyUpdated") : t("NewCampaignIsInTheWorld"));
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditCampaign = () => {

        if(formData.name)
        {
            AddEditCampaignMutation({
                variables:{
                    campaignID:               selectedCampaignID,
                    projectID :               projectID,
                    linkID:                   formData.linkID,
                    active:                   formData.active, 
                    name:                     formData.name,
                    isForNewsletter:          (formData.isForNewsletter ? 1 : 0),
                    isForTherapy:             (formData.isForTherapy ? 1 : 0),
                    isForAndroidMobileAppID:  formData.isForAndroidMobileAppID,
                    isForIOSMobileAppID:      formData.isForIOSMobileAppID,
                    useEmailsFromNewsletter:  formData.useEmailsFromNewsletter,
                    products:                 GetProductIDs(formData.selectedProducts),        
                    forms:                    GetFormIDs(formData.selectedForms)    
                }
            })
        }
        else
        {
            AddNotification(t("NoNameNoGo"),false); 
        }
    } 

    const GetProductIDs = (products) => {

        var newProducts = [];
        for(let val of products)
        {
            newProducts.push({
                productID:val.productID
            })
        }

        return newProducts;
    }

    const GetFormIDs = (forms) => {

        var newForms = [];
        for(let val of forms)
        {
            newForms.push({
                formID:val.formID
            })
        }

        return newForms;
    }

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error,
        loading,
        formData,
        AddEditCampaign,
        SetFormData,
        InitFormData,
        FillFormSelectedItemsWithObj,
        RemoveFormDataSelectedItems
    }

}

export default UseAddEditCampaign;