import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditReservationPlaceVariation from "./AddEditReservationPlaceVariation";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllReservationPlaceVariations from "../Library/UseGetAllReservationPlaceVariations";
import UseDeleteReservationPlaceVariations from "../Library/UseDeleteReservationVariations";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { useParams } from "react-router-dom";
import { GetProjectIDFromParam } from "../../../GlobalFunctions/header";
import { UseAuth } from "../../Auth/Library/UseAuth";

const ReservationPlaceVariations = () => {

    const params = useParams();
    const projectID = GetProjectIDFromParam(params);

    const {t} = useTranslation();
    const {user} = UseAuth();

    const [selectedReservationPlaceVariationID, SetSelectedReservationPlaceVariationID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedReservationPlaceVariationIDs,
        SelectRow,
        SelectAll,
        UpdateList
    } = UseGetAllReservationPlaceVariations(user.lang,projectID);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteReservationPlaceVariations
    } = UseDeleteReservationPlaceVariations(selectedReservationPlaceVariationIDs,() => UpdateList());

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ReservationPlaceVariationsList")}
                        OnAddButtonClick={() => SetSelectedReservationPlaceVariationID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (reservationPlaceVariationID) => SetSelectedReservationPlaceVariationID(reservationPlaceVariationID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedReservationPlaceVariationID || selectedReservationPlaceVariationID == 0 ?
                <AddEditReservationPlaceVariation
                    projectID = {projectID}
                    selectedReservationPlaceVariationID = {selectedReservationPlaceVariationID}
                    SetSelectedReservationPlaceVariationID = {SetSelectedReservationPlaceVariationID}
                    OnAddEditSuccess = {() => {
                        UpdateList();
                        SetSelectedReservationPlaceVariationID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteReservationPlaceVariations")} 
                    CallBack={DeleteReservationPlaceVariations} 
                />
            :null}

        </>
    )
}

export default ReservationPlaceVariations;