import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Input from '../../../GlobalComponents/Input';
import InputCard from '../../../GlobalComponents/InputCard';
import creditCardIcon from '../../../Media/Images/Icons/credit_card.webp';
import Select from '../../../GlobalComponents/Select';

Modal.setAppElement('#root');

const AddressData = ({
    formData,
    allCountries,
    SetFormData,
}) => {

    const {t} = useTranslation();

    return(

        <div className="row">
            <div className="col-50">
                <InputCard 
                    title={t("Address")}
                    className="form-group"
                    canBeMinimalized={true}
                >
                    <div className="row">
                        <div className={formData.countryID == 209 || formData.countryID == 237 ? "col-50" : "col-100"}>
                            <div className="form-group">
                                <label>{t("Country")}</label>
                                <Select
                                    name="countryID"
                                    value={formData.countryID}
                                    OnChange={(e) => SetFormData(e)}
                                >
                                    <option value={0}>{" - - - " + t("choose") + " - - - "}</option>
                                    {allCountries.map((item) => (
                                        <option 
                                            key={item.countryID}
                                            value={item.countryID}
                                        >{item.name}</option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        {formData.countryID == 209 || formData.countryID == 237 ?
                            <div className="col-50">
                                <div className="form-group">
                                    <label>{(formData.countryID == 209 ? t("Province") : t("State"))}</label>
                                    <Input
                                        name="state"
                                        value={formData.state}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        :null}
                        <div className="col-100">
                            <div className="form-group">
                                <label>{
                                    (formData.countryID == 236 || formData.countryID == 237 ? 
                                        t("StreetLine1") 
                                    : 
                                        t("Street")
                                    )
                                }
                                </label>
                                <Input
                                    name="street"
                                    value={formData.street}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                        {formData.countryID == 236 || formData.countryID == 237 ?
                            <div className="col-100">
                                <div className="form-group">
                                    <label>{t("StreetLine2")}</label>
                                    <Input
                                        name="name"
                                        value={formData.street2}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        :null}
                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("City")}</label>
                                <Input
                                    name="city"
                                    value={formData.city}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("Zip")}</label>
                                <Input
                                    name="zip"
                                    value={formData.zip}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>
                </InputCard>
            </div>
            <div className="col-50">
                <InputCard 
                    title={t("CompanyData")}
                    icon = {creditCardIcon}
                    className="form-group"
                    canBeMinimalized={true}
                >
                    <div className="form-group">
                        <label>{t("CompanyName")}</label>
                        <Input
                            name="company"
                            value={formData.company}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                
                    <div className="form-group">
                        <label>{t("IDnumber")}</label>
                        <Input
                            name="ic"
                            value={formData.ic}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                
                    <div className="form-group">
                        <label>{t("VATnumber")}</label>
                        <Input
                            name="dic"
                            value={formData.dic}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                </InputCard>
            </div>

            <div className="col-100">

                <InputCard 
                    title={t("BankAccount")}
                    icon = {creditCardIcon}
                    canBeMinimalized={true}
                >
                    <div className="row">
                        <div className="col-33">
                            <div className="form-group">
                                <label>{t("AccountNumber")}</label>
                                <Input
                                    name="bankAccount"
                                    value={formData.bankAccount}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                        <div className="col-33">
                            <div className="form-group">
                                <label>{t("SWIFT")}</label>
                                <Input
                                    name="swift"
                                    value={formData.swift}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                        <div className="col-33">
                            <div className="form-group">
                                <label>{t("IBAN")}</label>
                                <Input
                                    name="iban"
                                    value={formData.iban}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>
                </InputCard>

            </div>
                                            
        </div>

    )
}

export default AddressData;