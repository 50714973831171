import { Fragment, useState } from "react";
import { UseContentManager } from "../../../Library/UseContentManager";
import ControlsMenu from "../../ControlsMenu";
import { GetElementMargins } from "../../../Library/functions";
import TinyMCEEditor from "../../TinyMCEEditor";
import Tab from "./Tab";

const Tabs = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {
        showHelpers,
        indexToShowMenu,
        SelectElement,
        SetSettings
    } = UseContentManager();

    const [selectedTab,SetSelectedTab] = useState(0);

    const borderRadiusStyle = {borderRadius:data.borderRadius + "px"};

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);

    const OnEditorChange = (content,index) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu + "-" + index,"name",content,"tab")
    }

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "tabs"
                    index = {lastIndexes} 
                    showAddTab = {true} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={"cm-tabs-element " + margins.styleClassName} style={margins.styles}>
                <ul 
                    className="tabs" 
                    style={borderRadiusStyle}
                >
                    {data.tabs.length > 0 && data.tabs.map((item,index) => {
                        
                        return(
                            <li 
                                key={index}
                                onClick = {() => SetSelectedTab(index)}
                                className={(selectedTab == index ? "selected" : "")}
                            >
                                <TinyMCEEditor 
                                    value = {item.name}
                                    OnEditorChange={(content) => OnEditorChange(content,index)}
                                    toolbar = 'bold italic forecolor removeformat'
                                    forcedRootBlock = {"li"}
                                />
                            </li>
                        )
                    })}
                    <li className="sticky"></li>
                </ul>
                
                <div className="cm-tabs-content">
                    {data.tabs.length > 0 && data.tabs.map((item,index) => {
                        
                        if(selectedTab == index)
                            return(
                                <Fragment key={index} >
                                    
                                    <Tab 
                                        data = {item} 
                                        index = {index} 
                                        tabsCount = {data.tabs.length}
                                        lastIndexes = {lastIndexes} 
                                    />
                                        
                                </Fragment>
                            )
                        else
                            return "";
                    })}
                </div>
               

                {showHelpers ?
                    <div 
                        className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                        onClick={(e) => SelectElement(e,lastIndexes)}
                    ></div>
                :null}

            </div>
        </div>
    )
}

export default Tabs;