const TickInCircle = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M256,512c-68.38,0-132.667-26.629-181.019-74.98C26.628,388.667,0,324.38,0,256S26.628,123.333,74.981,74.98   C123.333,26.629,187.62,0,256,0s132.667,26.629,181.019,74.98C485.372,123.333,512,187.62,512,256s-26.628,132.667-74.981,181.02   C388.667,485.371,324.38,512,256,512z M256,22C126.972,22,22,126.972,22,256s104.972,234,234,234s234-104.972,234-234   S385.028,22,256,22z"/>
                    <path 
                        style={{"fill":secondaryColor}}  
                        d="M219.253,331.194c-2.802,0-5.604-1.063-7.749-3.192l-79.467-78.875   c-4.312-4.28-4.338-11.245-0.058-15.557c4.279-4.311,11.243-4.338,15.556-0.059l71.689,71.155l135.74-135.74   c4.296-4.295,11.261-4.295,15.557,0c4.295,4.296,4.295,11.261,0,15.557L227.032,327.973   C224.884,330.12,222.069,331.194,219.253,331.194z"/>
                </g>
            </svg>
        </>
    )
}

export default TickInCircle;