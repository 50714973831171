import { useState } from "react";
import { DELETE_RESERVATIONS } from "../Queries/reservations";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const UseDeleteReservations = (selectedItems,OnCompleted) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);

    const [DelReservations,{loading}] = useMutation(DELETE_RESERVATIONS,{
        onCompleted:(d) => {

            AddNotification(t("TheyAreGone"),true);
            if(OnCompleted)
                OnCompleted(d.DeleteReservations);

            SetShowDeleteNotification(false);
        },
        onError:(err) => { 
             
            var error = GetApolloErrorText(err);                  
            AddNotification(error,false);

            SetShowDeleteNotification(false);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            AddNotification(t("NoSelectedRows"),false);
        }
    }

    const DeleteReservations = (action) => {

        if(action)
        {              
            DelReservations({
                variables:{
                    reservationIDs:selectedItems.join(",")
                }
            })
        }
        else
            SetShowDeleteNotification(false);
    }

    return{
        loading,
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteReservations
    }
}

export default UseDeleteReservations;