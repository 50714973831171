import gql from 'graphql-tag';

export const GET_ALL_PROJECTS = gql`
    query AllProjects($onlyActive: Boolean){
        AllProjects(onlyActive:$onlyActive){
            projectID
            active
            name
            favicon
        }
    }
`;

export const GET_PROJECT = gql`
    query Project($projectID:ID){
        ProjectWithLangs(projectID:$projectID){
            projectID
            billingInformationID
            active
            name
            logo
            logoFooter
            logoPortal
            logoInvoice
            emailHeader
            favicon	
            dateEdit
            emailLinkColor 
            linkColor
            buttonBgColor
            buttonTextColor
            buttonBorderRadius
            headlineColor
            inputBorderRadius
            stripePublishableKey
            stripeSecretKey
            stripeWebHook
            purchaseInfoEmail
            newRegistrationInfoEmail
            cancelMembershipInTrialPeriodEmail
            completeQuestionnaireEmail
            affiliatePaidOfEmail
            invoiceNumberCharacter
            langs{
                lang
                webEmail
                webTel
                sendersEmail
                sendersName
                emailSignature
                emailFooterText
                emailNewsletterSignature
                unsubscribeText
                metaTitle
            }
            projectPortalModules{
                projectPortalModuleID
                portalModuleID
                portalModule{
                    portalModuleID
                    label
                }
                langs{
                    lang
                    name
                }
            } 
            domains{
                projectDomainID
                gtmID
                lang
                name
                portalSubdomain
            }
            modules{
                moduleID
            }
        }
        AllModulesBy(param:"isForProject",value:1,parentModuleID:0)
        {
            moduleID
            activeByDefault
            label
            subModule{
                moduleID
                activeByDefault
                label
            }
        }   
        AllBillingInformations{
            billingInformationID
            company
        } 
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const ADD_EDIT_PROJECT = gql`
    mutation AddEditProject(
        $projectID:ID,
        $billingInformationID:ID,
        $name:String,
        $langs:[ProjectLangsInput],
        $logo:Upload,
        $logoFooter:Upload,
        $logoPortal:Upload,
        $logoInvoice:Upload,
        $favicon:Upload,
        $emailHeader:Upload,
        $active:Int,
        $emailLinkColor:String 
        $linkColor:String
        $buttonBgColor:String
        $buttonTextColor:String
        $buttonBorderRadius:Int
        $headlineColor:String
        $inputBorderRadius:Int
        $stripePublishableKey:String
        $stripeSecretKey:String
        $stripeWebHook:String
        $purchaseInfoEmail:String
        $newRegistrationInfoEmail:String
        $cancelMembershipInTrialPeriodEmail:String
        $completeQuestionnaireEmail:String
        $affiliatePaidOfEmail:String
        $invoiceNumberCharacter:String
        $domains:[ProjectDomainInput]
        $modules:[ID] 
        $selectedPortalModules:[ProjectPortalModuleInput]
        $generateTranslations:GenerateProjectTranslationsInput
    ){
        AddEditProject(
            projectID:$projectID,
            billingInformationID:$billingInformationID,
            name:$name, 
            langs:$langs,
            logo:$logo, 
            logoFooter:$logoFooter,
            logoPortal:$logoPortal,
            logoInvoice:$logoInvoice,
            favicon:$favicon, 
            emailHeader:$emailHeader, 
            active:$active, 
            emailLinkColor:$emailLinkColor,
            linkColor:$linkColor,
            buttonBgColor:$buttonBgColor,
            buttonTextColor:$buttonTextColor,
            buttonBorderRadius:$buttonBorderRadius,
            headlineColor:$headlineColor,
            inputBorderRadius:$inputBorderRadius,
            stripePublishableKey:$stripePublishableKey
            stripeSecretKey:$stripeSecretKey
            purchaseInfoEmail:$purchaseInfoEmail
            newRegistrationInfoEmail:$newRegistrationInfoEmail
            cancelMembershipInTrialPeriodEmail:$cancelMembershipInTrialPeriodEmail
            completeQuestionnaireEmail:$completeQuestionnaireEmail
            affiliatePaidOfEmail:$affiliatePaidOfEmail
            stripeWebHook:$stripeWebHook
            invoiceNumberCharacter:$invoiceNumberCharacter
            domains:$domains,
            modules:$modules,
            selectedPortalModules:$selectedPortalModules,
            generateTranslations:$generateTranslations
        ){
            projectID
            name
            favicon
            active
        }
    }
`;

export const GENERATE_PROJECT_LANG = gql`
    mutation GenerateProjectLang(
        $projectID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateProjectLang(
            projectID:$projectID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;

export const UPDATE_PROJECT_PRIORITY = gql`

   mutation UpdateProjectPriority($projectID:ID,$fromIndex:Int!,$toIndex:Int!) {
       UpdateProjectPriority(projectID:$projectID,fromIndex:$fromIndex,toIndex:$toIndex)
   }

`;

export const DELETE_PROJECTS = gql`
    mutation DeleteProjects($projectIDs:ID) {
        DeleteProjects(projectIDs:$projectIDs)
    }
`;

