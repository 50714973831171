import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditCover from '../../Library/Covers/UseAddEditCover';
import UseGetCover from '../../Library/Covers/UseGetCover';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import { UseAuth } from '../../../Auth/Library/UseAuth';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import BasicInformations from './BasicInformations';
import CoverPhotos from './CoverPhotos';
import ExcludedProducts from './ExcludedProducts';

Modal.setAppElement('#root');

const AddEditCover = ({selectedCoverID,SetSelectedCoverID}) => {

    const [selectedTab, SetSelectedTab] = useState(1);

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t} = useTranslation();
    const {
        loading,
        error,
        formData,
        AddEditCover,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj,
        SetBooleanFormDataSelectedItemsWithObj,
        RemoveFormDataSelectedItems
    } = UseAddEditCover(selectedCoverID,user.lang,(d,continueToPhotos) => OnAddEditSuccess(d,continueToPhotos));

    const {
        allLanguageMutations,
        allCoverMaterialTypes,
        loading:getLoading,
        error:getError
    } = UseGetCover(selectedCoverID,user.lang,(d) => {
        InitFormData(d)
    });

    const OnAddEditSuccess = async (d,continueToPhotos) => {

        if(continueToPhotos)
        {
            SetSelectedCoverID(d.coverID);
            SetSelectedTab(2);
        }
        else
            SetSelectedCoverID(null);

        await client.refetchQueries({
            include: ["AllCovers"],
        });
    }

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedCoverID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedCoverID === 0 ? t("AddCover") : t("EditCover"))}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedCoverID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
            />

            <div className="modal-body">

                <ul className="tabs">
                    <li 
                        className={(selectedTab == 1 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(1)}
                    >{t("BasicInformations")}</li>
                    <li 
                        className={(selectedTab == 2 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(2)}
                    >{t("Photos")}</li>
                    <li 
                        className={(selectedTab == 3 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(3)}
                    >{t("ExcludeCoverInProducts")}</li>
                </ul>

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                {selectedTab == 1 ?
                                    <BasicInformations
                                        allCoverMaterialTypes = {allCoverMaterialTypes}
                                        formData = {formData}
                                        SetFormLangData = {SetFormLangData}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormData = {SetFormData}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                        RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                    />
                                :null}

                                {selectedTab == 2 ?
                                    <CoverPhotos
                                        coverPhotos = {formData.coverPhotos}
                                        selectedCoverID = {selectedCoverID}
                                        SetFormData = {SetFormData}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                        SetBooleanFormDataSelectedItemsWithObj =  {SetBooleanFormDataSelectedItemsWithObj}
                                        RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                    />
                                :null}

                                {selectedTab == 3 ?
                                    <ExcludedProducts
                                        selectedCoverID = {selectedCoverID}
                                        formData = {formData}
                                        lang = {user.lang}
                                        SetFormData = {SetFormData}
                                    />
                                :null}
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                
                    {selectedCoverID === 0 ?
                        <>
                            {selectedTab == 1 ?
                                <div className="row">
                                    <div className="col-50">
                                        <Button
                                            className={"btn-primary w-100"}
                                            OnClick={() => AddEditCover(false)}
                                        >
                                            {t("Add")}
                                        </Button>
                                    </div>
                                    <div className="col-50">
                                        <Button
                                            className={"btn-primary w-100"}
                                            OnClick={() => AddEditCover(true)}
                                        >
                                            {t("AddAndContinueToPhotos")}
                                        </Button>
                                    </div>
                                </div>
                            :null}
                        </>
                    :
                        <Button
                            className={"btn-primary w-100"}
                            OnClick={() => AddEditCover(false)}
                        >
                            {t("Edit")}
                        </Button>
                    }
                
            </div>

        </Modal>
    )
}

export default AddEditCover;