import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseAuth } from '../../Auth/Library/UseAuth';
import List from '../../../GlobalComponents/List/List';
import { useState } from 'react';
import UseChooseReviews from '../Library/UseChooseReviews';

Modal.setAppElement('#root');

const ChooseReviews = ({
    projectID,
    formData,
    SetShowAddReviews,
    FillFormSelectedItemsWithObj
}) => {

    var selectedReviews = [];
    if(formData.selectedReviews && formData.selectedReviews.length > 0)
    {
        for(let val of formData.selectedReviews)
        {
            selectedReviews.push(val.reviewID);
        }
    }
    
    const {user} = UseAuth();
    const {t} = useTranslation();

    const [showFilter,SetShowFilter] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll
    } = UseChooseReviews(projectID,user.lang,selectedReviews,FillFormSelectedItemsWithObj);

    var err = "";
    if(error)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddReviews(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("AddReviews")}
                OnClose ={() => SetShowAddReviews(false)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <div className="modal-body-no-padding">
                        <List 
                            headerClassName={"no-top-border"}
                            headerData = {headerData} 
                            contentData = {content}
                            totalContentLength = {totalContentLength}
                            paggingOffset = {paggingOffset}
                            paggingLimit = {paggingLimit}
                            FetchMore = {FetchMore}
                            GoToPage = {GoToPage}
                            isSortable = {false}
                            loading = {loading}
                            hideOptions = {true}
                            OnSelectAll = {(e) => SelectAll(e)}
                            options = {{
                                OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                            }}
                        />
                    </div> 
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddReviews(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )
}

export default ChooseReviews;