import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins, GetMediaDimensionsStyles } from "../../Library/functions";
import VideoSource from "../../../../GlobalComponents/Video";

const Video = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);

    const borderRadiusStyle = {borderRadius:data.borderRadius + "px"};

    var videoConent = null;
    if(data.useDimensions == "1")
        videoConent = GetMediaDimensionsStyles(data);

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "video"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            {data.videoTypeID == 3 ?
                <div 
                    className={data.className + " " + margins.styleClassName + (data.mp4File ? " cm-d-flex cm-justify-content-" + data.mp4Align : " d-flex")} 
                    style={margins.styles}
                >
                    {data.mp4File ?
                        <div className={"cm-video-content " + (videoConent && videoConent.styleClassName)} style={videoConent && videoConent.styles}>
                        
                            <VideoSource data = {data} borderRadiusStyle = {borderRadiusStyle} />
                            
                        </div>
                    :
                        <div className="cm-start-info">Pro vložení videa použijte ikonku ozubeného kolečka "Nastavení"</div>
                    }
                </div>
            :
                <div 
                    className={"cm-youtube-vimeo-container " + data.className + " " + margins.styleClassName} 
                    style={margins.styles}
                >
                    <VideoSource data = {data} />
                </div>
            }

            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Video;