import { useTranslation } from 'react-i18next';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import { TINY_MCE_TOOLBAR_MEDIA, TINY_MCE_TOOLBAR_ADVANCED } from '../../../Config';
import Label from '../../../GlobalComponents/Label';
import Input from '../../../GlobalComponents/Input';
import { UseProject } from '../../../AdminProvider';

const MembershipTexts = ({
    formData,
    GetFormLangValue,
    SetFormLangData,
}) => {

    const {t} = useTranslation();
    const {IsModulEnabled} = UseProject();
    
    return(
        <> 
            <div className="row">
                <div className="col-50">
                    <div className="form-group">
                        <Label
                            text = {"*" + t("FreeTrialPeriodButtonName") + " (" + formData.selectedLang + ")"}
                            infoContent={[t("FreeTrialPeriosButtonNameInfo")]}
                        />
                        <Input
                            name="trialPeriodButtonName"
                            type="text"
                            value={GetFormLangValue("trialPeriodButtonName")}
                            OnChange={(e) => SetFormLangData(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <Label
                            text = {"*" + t("ButtonNameInChooseMembershipType") + " (" + formData.selectedLang + ")"}
                            infoContent={[t("ButtonNameInChooseMembershipTypeInfo")]}
                        />
                        
                        <Input
                            name="membershipTypeButtonName"
                            type="text"
                            value={GetFormLangValue("membershipTypeButtonName")}
                            OnChange={(e) => SetFormLangData(e)}
                        />
                    </div>
                </div>
                {IsModulEnabled(68) ?
                    <>
                        <div className="col-50">
                            <div className="form-group">
                                <Label
                                    text = {"*" + t("ButtonNameInChooseReservationPlace") + " (" + formData.selectedLang + ")"}
                                    infoContent={[t("ButtonNameInChooseReservationPlaceInfo")]}
                                />
                                
                                <Input
                                    name="reservationPlaceButtonName"
                                    type="text"
                                    value={GetFormLangValue("reservationPlaceButtonName")}
                                    OnChange={(e) => SetFormLangData(e)}
                                />
                            </div>
                        </div>
                        <div className="col-50">
                            <div className="form-group">
                                <Label
                                    text = {"*" + t("ButtonNameInChooseReservationDate") + " (" + formData.selectedLang + ")"}
                                    infoContent={[t("ButtonNameInChooseReservationDateInfo")]}
                                />
                                
                                <Input
                                    name="reservationDateButtonName"
                                    type="text"
                                    value={GetFormLangValue("reservationDateButtonName")}
                                    OnChange={(e) => SetFormLangData(e)}
                                />
                            </div>
                        </div>
                    </>
                :null}
                <div className="col-50">
                    <div className="form-group">
                        <Label
                            text = {"*" + t("ButtonNameInFillPersonalData") + " (" + formData.selectedLang + ")"}
                            infoContent={[t("ButtonNameInFillPersonalDataInfo")]}
                        />
                        
                        <Input
                            name="personalDataButtonName"
                            type="text"
                            value={GetFormLangValue("personalDataButtonName")}
                            OnChange={(e) => SetFormLangData(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <Label
                            text = {"*" + t("PaymentButtonName") + " (" + formData.selectedLang + ")"}
                            infoContent={[t("PaymentButtonNameInfo")]}
                        />
                        
                        <Input
                            name="paymentButtonName"
                            type="text"
                            value={GetFormLangValue("paymentButtonName")}
                            OnChange={(e) => SetFormLangData(e)}
                        />
                    </div>
                </div>
                {IsModulEnabled(68) ?
                    <div className="col-100">
                        <div className="form-group">
                            <Label
                                text = {t("NoteHeadline") + " (" + formData.selectedLang + ")"}
                                infoContent={[t("NoteHeadlineInfo")]}
                            />
                            
                            <Input
                                name="noteHeadline"
                                type="text"
                                value={GetFormLangValue("noteHeadline")}
                                OnChange={(e) => SetFormLangData(e)}
                            />
                        </div>
                    </div>
                :null}
            </div>
            <div className="form-group">
                <Label 
                    text = {t("TrialPeriodPopUpTextUp") + " (" + formData.selectedLang + ")"} 
                    infoContent={[
                        t("TrialPeriodPopUpTextUpInfo")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("trialPeriodPopUpTextUp")}
                    OnEditorChange={(content) => SetFormLangData("trialPeriodPopUpTextUp",content)}
                    toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                    useMediaEditor={true}
                />
            </div> 
            <div className="form-group">
                <Label 
                    text = {t("TrialPeriodPopUpTextDown") + " (" + formData.selectedLang + ")"} 
                    infoContent={[
                        t("TrialPeriodPopUpTextDownInfo")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("trialPeriodPopUpTextDown")}
                    OnEditorChange={(content) => SetFormLangData("trialPeriodPopUpTextDown",content)}
                    toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                    useMediaEditor={true}
                />
            </div> 
            <div className="form-group">
                <Label 
                    text = {t("PopUpTextUp") + " (" + formData.selectedLang + ")"} 
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("membershipTypePopUpTextUp")}
                    OnEditorChange={(content) => SetFormLangData("membershipTypePopUpTextUp",content)}
                    toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                />
            </div> 
            <div className="form-group">
                <Label 
                    text = {t("PopUpTextDown") + " (" + formData.selectedLang + ")"} 
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("membershipTypePopUpTextDown")}
                    OnEditorChange={(content) => SetFormLangData("membershipTypePopUpTextDown",content)}
                    toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                />
            </div> 
            {IsModulEnabled(68) ?
                <>
                    <div className="form-group">
                        <Label 
                            text = {t("ReservationPlacePopUpTextUp") + " (" + formData.selectedLang + ")"} 
                        />
                        <TinyMCEEditor 
                            value = {GetFormLangValue("reservationPlacePopUpTextUp")}
                            OnEditorChange={(content) => SetFormLangData("reservationPlacePopUpTextUp",content)}
                            toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                        />
                    </div> 
                    <div className="form-group">
                        <Label 
                            text = {t("ReservationPlacePopUpTextDown") + " (" + formData.selectedLang + ")"} 
                        />
                        <TinyMCEEditor 
                            value = {GetFormLangValue("reservationPlacePopUpTextDown")}
                            OnEditorChange={(content) => SetFormLangData("reservationPlacePopUpTextDown",content)}
                            toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                        />
                    </div> 
                </>
            :null}
            <div>
                <Label 
                    text = {t("TextBellowFillCreditCard") + " (" + formData.selectedLang + ")"} 
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("textBellowFillCreditCard")}
                    OnEditorChange={(content) => SetFormLangData("textBellowFillCreditCard",content)}
                    toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                />
            </div> 
                  
        </>
    )
}

export default MembershipTexts;