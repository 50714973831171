import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_CUSTOMER_ROLE } from "../Queries/customerRole";

const UseAddEditCustomerRole = (selectedCustomerRoleID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,OpenImage} = UseFormHandle({
        name:"",
        portalModules:[]
    });

    const [AddEditCustomerRoleMutation,{error,loading}] = useMutation(ADD_EDIT_CUSTOMER_ROLE,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditCustomerRole);

            const notifyText = (selectedCustomerRoleID != 0 ? t("SuccessfullyUpdated") : t("NewCustomerRoleIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditCustomerRole = () => {

        if(formData.name)
        {   
            if(formData.projectID)
            {   
                AddEditCustomerRoleMutation({
                    variables:{
                        customerRoleID: selectedCustomerRoleID,
                        projectID:      formData.projectID,
                        name:           formData.name,
                        portalModules:  formData.portalModules
                    }
                })  
            }
            else
                AddNotification(t("NoProjectNoGo"),false); 
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const SetProject = (projectID,checked) => {

        var newProjects = [...formData.projects];
        
        if(checked)
            newProjects.push(projectID);
        else
        {
            const index = newProjects.indexOf(projectID);
            if(index != -1)
                newProjects.splice(index,1);
        }
        
        SetFormData("projects",newProjects);
    }

    const SetPortalModule = (portalModuleID,subPortalModules,checked) => {

        var newPortalModules = [...formData.portalModules];
        
        if(checked)
            newPortalModules.push(portalModuleID);
        else
        {
            const index = newPortalModules.indexOf(portalModuleID);
            if(index != -1)
                newPortalModules.splice(index,1);

            if(subPortalModules && subPortalModules.length > 0)
            {
                for(let val of subPortalModules)
                {
                    const i = newPortalModules.indexOf(val.portalModuleID);
                    if(i != -1)
                        newPortalModules.splice(i,1);
                }
            }
        }
        
        SetFormData("portalModules",newPortalModules);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditCustomerRole,
        SetFormData,
        InitFormData,
        OpenImage,
        SetPortalModule,
        SetProject
    }

}

export default UseAddEditCustomerRole;