import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditMembership from '../Library/UseAddEditMembership';
import UseGetMembership from '../Library/UseGetMembership';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';
import dayjs from 'dayjs';
import { useState } from 'react';
import BasicInformations from './BasicInformations';
import { UseAuth } from '../../Auth/Library/UseAuth';
import TextsToSend from './TextsToSend';
import TypesAndPrices from './TypesAndPrices';
import FreeTrialPeriod from './FreeTrialPeriod';
import TherapyData from './TherapyData';
import { 
    SERVER_URL,
    MEMBERSHIP_IMAGE_URL
} from '../../../Config';

Modal.setAppElement('#root');

const AddEditMembership = ({
    selectedMembershipID,
    SetSelectedMembershipID,
    OnAddEditSuccess,
    projectID
}) => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    const [selectedTab, SetSelectedTab] = useState(1);

    var itemsToTranslate = [
        {name:"Název",param:"name"},
        {name:"Název ve faktuře",param:"invoiceName"},
        {name:"Popis",param:"description"},
        {name:"Email, který se zašle po nákupu tohoto členství/služby",param:"emailText"},
        {name:"Popis v upsell bombě",param:"upSellBombDescription"},
        {name:"Hláška, po úspěšném objednání členství/služby",param:"successMessage"},
        {name:"Email, při zrušení členství ve zkušební době",param:"trialPeriodCancellationEmail"},
        {name:"Email, který chodí 2 dny před koncem zkušebního období",param:"notificationOfEndingTrialPeriodEmail"},
        {name:"Email pro zákazníka, když je přidán pod Průvodce",param:"emailForCustomerWhenAddedToGuide"},
        {name:"Doplňující zpráva k stavu platby - DOKONČENO",param:"additionalMessageForFirstPaymentStatusCompleted"},
        {name:"Doplňující zpráva k stavu platby - DOKONČENO - pro duhou a každou další platbu",param:"additionalMessageForOtherPaymentStatusCompleted"},
        {name:"Hláška při objednávce, kdy už není možné zakoupit zkušební verzi zdarma",param:"noMoreFreeTrialPeriodWarning"},
        {name:"Hláška při objednávce, kdy není možné zakoupit dané členství.",param:"notPossibleToMakeOrderWarning"},
        {name:"Email, když je úspěšně strhnuta opakující se platba.",param:"recurringPaymentInfoEmail"},
        {name:"Email pro připomenutí rezervace",param: "reservationReminderEmail"}
    ];

    const {
        allLanguageMutations,
        allLinks,
        allQuestionnaires,
        allCustomerRoles,
        allVats,
        mainLang,
        loading:getLoading,
        error:getError
    } = UseGetMembership(selectedMembershipID,projectID,user.lang,(d) => {

        var fileVersion = "";
        if(d.dateEdit != "0000-00-00 00:00:00")
            fileVersion = "?" + dayjs(d.dateEdit).format("YYYYMMDDHHmmss");

        var initData = {...d};
        initData.mainPhoto =  d.mainPhoto ? SERVER_URL + "/" + MEMBERSHIP_IMAGE_URL + "/stredni_" + d.mainPhoto + fileVersion : "";

        InitFormData(initData);
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditMembership,
        InitFormData,
        SetFormData,
        GetFormLangValue,
        SetFormLangData,
        OpenImage,
        FillFormSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        SetFormDataSelectedItemsWithObjAndIndex,
        GetFormSelectedItemsLangValue,
        SetFormSelectedItemsLangValue,
        SetFormDataSelectedItemsWithObj,
        OpenImageToArrayOfObject
    } = UseAddEditMembership(selectedMembershipID,projectID,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedMembershipID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {selectedMembershipID === 0 ? t("AddMembership") : t("EditMembership")}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedMembershipID === 0 ? false : true}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedMembershipID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        :  
                            <>
                                <ul className="tabs">
                                    <li 
                                        className={(selectedTab == 1 ? "selected" : "")} 
                                        onClick = {() => SetSelectedTab(1)}
                                    >{t("BasicInformations")}</li>
                                    {formData.useFreeTrialPeriod == 1 ?
                                        <li 
                                            className={(selectedTab == 5 ? "selected" : "")} 
                                            onClick = {() => SetSelectedTab(5)}
                                        >{t("FreeTrialPeriod")}</li>
                                    :null}
                                    <li 
                                        className={(selectedTab == 2 ? "selected" : "")} 
                                        onClick = {() => SetSelectedTab(2)}
                                    >{t("EmailsAndTexts")}</li>
                                    
                                    {formData.isRecurring == 1 ?
                                        <li 
                                            className={(selectedTab == 3 ? "selected" : "")} 
                                            onClick = {() => SetSelectedTab(3)}
                                        >{t("TypesAndPrices")}</li>
                                    :null}
                                    {formData.isTherapy == 1 ?
                                        <li 
                                            className={(selectedTab == 4 ? "selected" : "")} 
                                            onClick = {() => SetSelectedTab(4)}
                                        >{t("TherapyData")}</li>
                                    :null}
                                    
                                </ul>
                                {selectedTab == 1 ?
                                    <BasicInformations 
                                        allLanguageMutations = {allLanguageMutations}
                                        allLinks = {allLinks}
                                        allVats = {allVats}
                                        allCustomerRoles = {allCustomerRoles}
                                        allQuestionnaires = {allQuestionnaires}
                                        formData = {formData}
                                        SetFormData = {SetFormData}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormLangData = {SetFormLangData}
                                        OpenImage = {OpenImage}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                        RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                        SetFormDataSelectedItemsWithObjAndIndex = {SetFormDataSelectedItemsWithObjAndIndex}
                                        GetFormSelectedItemsLangValue = {GetFormSelectedItemsLangValue}
                                        SetFormSelectedItemsLangValue = {SetFormSelectedItemsLangValue}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                        OpenImageToArrayOfObject = {OpenImageToArrayOfObject}
                                    />
                                :null}   
                                {selectedTab == 5 ?
                                    <FreeTrialPeriod 
                                        formData = {formData}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormLangData = {SetFormLangData}
                                        SetFormData = {SetFormData}
                                    />
                                :null}
                                {selectedTab == 2 ?
                                    <TextsToSend 
                                        formData = {formData}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormLangData = {SetFormLangData}
                                        SetFormData = {SetFormData}
                                    />
                                :null}
                                {selectedTab == 3 && formData.isRecurring == 1 ?
                                    <TypesAndPrices 
                                        selectedMembershipID = {selectedMembershipID}
                                    />
                                :null}  

                                {selectedTab == 4 && formData.isTherapy == 1 ?
                                    <TherapyData 
                                        formData = {formData}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormLangData = {SetFormLangData}
                                    />
                                :null}                                                         
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditMembership()}
                    >
                        {(selectedMembershipID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditMembership;