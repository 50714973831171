import gql from 'graphql-tag';

export const GET_ALL_BILLING_INFORMATIONS = gql`
    query AllBillingInformations{
        AllBillingInformations{
            billingInformationID
            company
        }
    }
`;

export const GET_BILLING_INFORMATION = gql`
    query BillingInformation($billingInformationID:ID){
        BillingInformation(billingInformationID:$billingInformationID){
            billingInformationID
            company
            street1
            street2
            city
            zip
            state
            countryID
            idNumber
            vatNumber
            bankAccountNumber
            bankAccountCountryID
            iban
            swift
            email
        }
        AllCountries{
            countryID
            name
        }
    }
`;

export const ADD_EDIT_BILLING_INFORMATION = gql`
    mutation AddEditBillingInformation(
        $billingInformationID:ID,
        $company:String
        $street1:String
        $street2:String
        $city:String
        $zip:String
        $state:String
        $countryID:ID
        $idNumber:String
        $vatNumber:String
        $bankAccountNumber:String
        $bankAccountCountryID:ID
        $iban:String
        $swift:String
        $email:String
    ){
        AddEditBillingInformation(
            billingInformationID:$billingInformationID
            company:$company
            street1:$street1
            street2:$street2
            city:$city
            zip:$zip
            state:$state
            countryID:$countryID
            idNumber:$idNumber
            vatNumber:$vatNumber
            bankAccountNumber:$bankAccountNumber
            bankAccountCountryID:$bankAccountCountryID
            iban:$iban
            swift:$swift
            email:$email
        ){
            billingInformationID
            company
        }
    }
`;

export const DELETE_BILLING_INFORMATIONS = gql`
    mutation DeleteBillingInformations($billingInformationIDs:ID) {
        DeleteBillingInformations(billingInformationIDs:$billingInformationIDs)
    }
`;

