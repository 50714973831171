import gql from 'graphql-tag';


export const GET_ALL_RESERVATIONS = gql`
    query AllReservations($limit:Int,$offset:Int,$searchText:String,$lang:String){
        AllReservations(limit:$limit,offset:$offset,searchText:$searchText){
            reservationID
            membershipID
            reservationStatusID
            customerHash
            date
            customer{
                name
                surname
                email
                photo
                backgroundColor
            }
            membership(lang:$lang){
                name
            }
            reservationStatus{
                status
            }
            reservationPlace{
                internalName
            }
        }

        AllReservationsCount(searchText:$searchText)
    }
`;

export const DELETE_RESERVATIONS= gql`
    mutation DeleteReservations($reservationIDs:ID) {
        DeleteReservations(reservationIDs:$reservationIDs)
    }
`;

export const GET_RESERVATION_PLACE_RESERVED_DAYS = gql`
    query AllReservationPlaceReservedDays(
        $month:Int,
        $year:Int,
        $reservationPlaceID:ID
    ){
        AllReservationPlaceReservedDays(
            month:$month,
            year:$year,
            reservationPlaceID:$reservationPlaceID
        )
    }
`;

export const GET_RESERVATION_DATA = gql`
    query ReservationData($reservationID:ID,$lang:String){
        ReservationBy(param:"reservationID", value:$reservationID){
            reservationID
            hash
            customerHash
            membershipID
            reservationPlaceID
            reservationPlaceTimeID
            reservationPlaceVariationID
            date
            duration
            customer{
                name
                surname
                email
                lang
            }
        }
        AllReservationPlaces(lang:$lang,onlyActive:true){
            reservationPlaceID
            active
            internalName
            backgroundColor
            times{
                reservationPlaceTimeID
                timeFrom
                timeTo
            }
        }
    }
`;

export const ADD_EDIT_RESERVATION = gql `
    mutation AddEditReservation(
        $reservationID:ID,
        $customerHash: ID,
        $membershipID: ID,
        $orderID:ID,
        $reservationPlaceID: ID,
        $reservationPlaceTimeID: ID,
        $reservationPlaceVariationID:ID,
        $reservationStatusID:ID,
        $isActive:Int,
        $date:String,
        $duration:Int,
        $note:String,
        $possibilityToBeActiveTo:String,
        $sendEmailToCustomer:Boolean
        $showPaymentButtonInEmail:Boolean
    ){
        AddEditReservation(
            reservationID: $reservationID,
            customerHash: $customerHash,
            membershipID: $membershipID,
            orderID:$orderID,
            reservationPlaceID: $reservationPlaceID,
            reservationPlaceTimeID:$reservationPlaceTimeID,
            reservationPlaceVariationID:$reservationPlaceVariationID,
            reservationStatusID:$reservationStatusID,
            isActive:$isActive,
            date:$date,
            duration:$duration,
            note:$note,
            possibilityToBeActiveTo:$possibilityToBeActiveTo,
            sendEmailToCustomer:$sendEmailToCustomer
            showPaymentButtonInEmail:$showPaymentButtonInEmail
        ){
            reservationID
        }
    }
`;

export const CONFIRM_RESERVATION_PAYMENT = gql `
    mutation ConfirmReservationPayment(
        $reservationID:ID,
    ){
        ConfirmReservationPayment(
            reservationID: $reservationID
        )
    }
`;