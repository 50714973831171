import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditProject from "./AddEditProject";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllProjects from "../Library/UseGetAllProjects";
import UseDeleteProjects from "../Library/UseDeleteProjects";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import UseSortList from "../../../GlobalHooks/UseSortList";
import { useApolloClient } from "@apollo/client";
import { UseAuth } from "../../Auth/Library/UseAuth";
import { GET_ALL_PROJECTS,UPDATE_PROJECT_PRIORITY } from "../Queries/project";

const Projects = () => {

    const {user} = UseAuth();
    const client = useApolloClient();
    const {t} = useTranslation();

    const [selectedProjectID, SetSelectedProjectID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedProjectIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    } = UseGetAllProjects();

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteProjects
    } = UseDeleteProjects(selectedProjectIDs,(d) => UpdateListAfterDelete(d));

    const {OnDragEnd} = UseSortList(
        client,
        UPDATE_PROJECT_PRIORITY,
        GET_ALL_PROJECTS,
        "AllProjects",
        "projectID",
        null,
        null,
        {},
        {onlyActive:false},
    );

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ProjectList")}
                        OnAddButtonClick={() => SetSelectedProjectID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {true}
                        loading = {loading}
                        OnDragListRowEnd = {(result) => OnDragEnd(result)}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (projectID) => SetSelectedProjectID(projectID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedProjectID || selectedProjectID == 0 ?
                <AddEditProject
                    selectedProjectID = {selectedProjectID}
                    SetSelectedProjectID = {SetSelectedProjectID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedProjectID,d);
                        SetSelectedProjectID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteProjects")} 
                    CallBack={DeleteProjects} 
                />
            :null}

        </>
    )
}

export default Projects;