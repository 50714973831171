import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import UseGetAllParameters from "../../Library/Parameters/UseGetAllParameters";
import { useState } from "react";
import AddEditParameter from "./AddEditParameter";
import { UseAuth } from '../../../Auth/Library/UseAuth';
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { useApolloClient } from "@apollo/client";
import UseDeleteParameters from "../../Library/Parameters/UseDeleteParameters";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import ContentFilterSidebar from "../../../../GlobalComponents/ContentFilterSidebar";
import ParameterFilter from "./ParameterFIlter";

const Parameters = () => {

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedParameterID, SetSelectedParameterID] = useState(null);
    const [showFilterSidebar,SetShowFilterSidebar] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedParameterIDs,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete,
        Filter
    } = UseGetAllParameters(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteParameters
    } = UseDeleteParameters(selectedParameterIDs,(d) => UpdateListAfterDelete(d));

    
    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ParameterList")}
                        //OnFilterClick = {() => SetShowFilterSidebar(true)}
                        OnAddButtonClick={() => SetSelectedParameterID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                        OnSearch = {(filterData) => Filter(filterData)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (parameterID) => SetSelectedParameterID(parameterID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                    <ContentFilterSidebar
                        isOpen = {showFilterSidebar}
                        OnClose = {() => SetShowFilterSidebar(false)}
                    >
                        <ParameterFilter
                            OnFilter = {(filterData) => Filter(filterData)}
                        />
                    </ContentFilterSidebar>
                </>
            }

            {selectedParameterID || selectedParameterID == 0 ?
                <AddEditParameter
                    selectedParameterID = {selectedParameterID}
                    SetSelectedParameterID = {SetSelectedParameterID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedParameterID,d);
                        SetSelectedParameterID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteParameters")} 
                    CallBack={DeleteParameters} 
                />
            :null}

        </>
    )

}

export default Parameters;