import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditReview from '../Library/UseAddEditReview';
import UseGetReview from '../Library/UseGetReview';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import RadioButton from '../../../GlobalComponents/RadioButton';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import InputCard from '../../../GlobalComponents/InputCard';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';
import dayjs from 'dayjs';
import eye from '../../../Media/Images/Icons/eye.webp';
import FileInput from '../../../GlobalComponents/FileInput';
import imageIcon from '../../../Media/Images/Icons/image.webp';
import { 
    SERVER_URL,
    REVIEW_IMAGE_URL, 
    TINY_MCE_TOOLBAR_MEDIA, 
    TINY_MCE_TOOLBAR_BASIC_FORMAT 
} from '../../../Config';

Modal.setAppElement('#root');

const AddEditReview = ({projectID,selectedReviewID,SetSelectedReviewID,OnAddEditSuccess}) => {

    const {t} = useTranslation();

    var itemsToTranslate = [
        {name:"Text",param:"text"},
        {name:"Podpis",param:"signature"}
    ];

    const {
        allLanguageMutations,
        mainLang,
        loading:getLoading,
        error:getError
    } = UseGetReview(selectedReviewID,(d) => {

        var fileVersion = "";
        if(d.dateEdit != "0000-00-00 00:00:00")
            fileVersion = "?" + dayjs(d.dateEdit).format("YYYYMMDDHHmmss");

        var initData = {...d};
        initData.mainPhoto =  d.mainPhoto ? SERVER_URL + "/" + REVIEW_IMAGE_URL + "/stredni_" + d.mainPhoto + fileVersion : "";

        InitFormData(initData);
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditReview,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenImage 
    } = UseAddEditReview(projectID,selectedReviewID,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedReviewID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {selectedReviewID === 0 ? t("AddReview") : t("EditReview")}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedReviewID === 0 ? false : true}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedReviewID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        :  
                            <>
                                <div className="row">
                                    <div className="col-50">

                                    <InputCard
                                        title = {t("Image")}
                                        icon = {imageIcon}
                                        className = {"form-group flex-1"}
                                    > 
                                        {formData.mainPhoto ?
                                            
                                            <div className="open-image-container form-group">
                                                <img className="no-margin" src = {formData.mainPhoto} />
                                            </div>
                                            
                                        :null}

                                        <div className="form-group">                
                                            <FileInput
                                                name = "photo"
                                                OnChange = {(e) => OpenImage(e,"photo","mainPhoto")}
                                            />
                                        </div>

                                    </InputCard>

                                    </div>
                                    <div className="col-50">

                                        <InputCard
                                            title = {t("Display")}
                                            icon = {eye}
                                            className = {"form-group"}
                                        >   
                                            
                                            <div className="form-group">
                                                
                                                <label>{t("Active")} </label>
                                                <div className="radio-container">
                                                    <RadioButton
                                                        text={t("Yes")}
                                                        name="active"
                                                        id="active-1"
                                                        value={1}
                                                        checked = {formData.active === 1 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                    <RadioButton
                                                        text={t("No")}
                                                        name="active"
                                                        id="active-2"
                                                        value={0}
                                                        checked = {formData.active === 0 ? true : false}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                                                                                                        
                                        </InputCard>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>*{t("Text")} ({formData.selectedLang})</label>
                                    <TinyMCEEditor 
                                        value = {GetFormLangValue("text")}
                                        OnEditorChange={(content) => SetFormLangData("text",content)}
                                        toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                                        useMediaEditor={true}
                                    />
                                </div>
                                <div>
                                    <label>{t("Signature")} ({formData.selectedLang})</label>
                                    <TinyMCEEditor 
                                        value = {GetFormLangValue("signature")}
                                        OnEditorChange={(content) => SetFormLangData("signature",content)}
                                        toolbar = {TINY_MCE_TOOLBAR_BASIC_FORMAT}
                                        useMediaEditor={true}
                                    />
                                </div>
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditReview()}
                    >
                        {(selectedReviewID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditReview;