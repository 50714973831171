import {useQuery} from '@apollo/client';
import { GET_FORMS } from '../Queries/form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

const UseGetAllForms = (lang) => {

    const [variables,SetVariables] = useState({
        lang
    });

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedFormIDs,SetSelectedFormIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_FORMS,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllForms);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("FormName"),
            className:"flex-1"
        }        
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.formID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,formID) => {

        var checked = e.target.checked;
        var newSelectedFormIDs = [...selectedFormIDs];
        const newList = SelectFormID(content,formID,checked,newSelectedFormIDs)

        SetContent(newList);
        SetSelectedFormIDs(newSelectedFormIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedFormIDs = [];
        const newList = SelectFormID(content,"all",checked,newSelectedFormIDs)

        SetSelectedFormIDs(newSelectedFormIDs);
        SetContent(newList);
    }

    const SelectFormID = (list,formID,checked,selectedFormIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(formID == newList[i].rowID || formID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedFormIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedFormIDs.length; j++)
                    {
                        if(selectedFormIDs[j] == newList[i].rowID)
                            selectedFormIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectFormID(newList[i].subData,formID,checked,selectedFormIDs)}
        }

        return newList;
    }

    const UpdateListAfterAddEdit = (selectedFormID,data) => {
        
        const { AllForms } = client.readQuery({ query: GET_FORMS, variables });
        
        var newArray = [...AllForms];

        if(selectedFormID && selectedFormID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].formID == data.formID)
                {
                    newArray[i] = {...newArray[i],
                        name: data.name
                    };
                }
            } 
        }
        else
        {
            newArray.unshift({
                formID: data.formID,
                name: data.name
            })
        }
        
        client.writeQuery({ 
            query:GET_FORMS,
            variables,
            data:{
                AllForms: newArray
            } 
        });
    }

    const UpdateListAfterDelete = (IDs) => {

        var response = IDs.split(",");
        
        const { AllForms } = client.readQuery({ query: GET_FORMS, variables });
        
        var newArray = [...AllForms];
        for(let id of response)
        {
            for(let i in newArray)
            {
                if(newArray[i].formID == id)
                    newArray.splice(i,1);
            } 
        } 
        
        client.writeQuery({ 
            query:GET_FORMS,
            variables,
            data:{
                AllForms: newArray
            } 
        });
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedFormIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    }

}

export default UseGetAllForms;