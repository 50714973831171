import { useTranslation } from "react-i18next";
import SearchInput from "../../../GlobalComponents/SearchInput";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { useEffect } from "react";
import Button from "../../../GlobalComponents/Button";
import Select from "../../../GlobalComponents/Select";

const CampaignContactFilter = ({OnFilter,allLanguages,variables}) => {

    const {t} = useTranslation();

    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle({
        searchText:"",
        lang:"",
        init:true
    })

    useEffect(() => {
        if(variables.lang != formData.lang || variables.searchText != formData.searchText)
            SetCompleteFormData({
                lang:variables.lang,
                searchText:variables.searchText
            })
    },[variables])

    useEffect(() => {

        if(!formData.init)
            OnFilter(formData);

    },[formData])
    
    return(
        <div className="row">

            <div className="col-100">
                <label>{t("SearchEmail")}</label>
                <div className="form-group">
                    <SearchInput
                        initValue = {formData.searchText}
                        placeholder = {t("Search...")}
                        OnChange = {(value) => {
                            SetFormData("searchText",value);
                            SetFormData("init",false);
                        }}
                    />
                </div>
            </div>
            {allLanguages && allLanguages.length > 0 ?
                <div className="col-50">
                    <label>{t("Language")}</label>
                    <div className="form-group">
                        <Select
                            name="lang"
                            value = {formData.lang}
                            OnChange = {(e) => {
                                SetFormData(e);
                                SetFormData("init",false);
                            }}
                        >
                            {allLanguages.map((item) => (
                                <option 
                                    value={item.suffix}
                                    key={item.languageID}
                                >{item.suffix ? item.suffix : t("all")}</option>
                            ))}
                        </Select>
                    </div>
                </div>
            :null}
            
            <div className="col-100">
                
                <Button 
                    className = "btn-brown form-group"
                    OnClick={() => SetCompleteFormData({
                        searchText:"",
                        lang:"",
                        init:false
                    })}
                >{t("ClearFilter")}</Button>
                
            </div>

        </div>
    )
}

export default CampaignContactFilter;