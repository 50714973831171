const GiftCard = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <g>
                            <path style={{"fill":primaryColor}} d="M501,447H11c-6.075,0-11-4.925-11-11V76c0-6.075,4.925-11,11-11h490c6.075,0,11,4.925,11,11v360     C512,442.075,507.075,447,501,447z M22,425h468V87H22V425z"/>
                        </g>
                        <path style={{"fill":primaryColor}} d="M438.987,205h-24.24c3.022-5.684,4.742-12.146,4.742-19c0-22.607-18.615-41-41.497-41    c-12.049,0-22.91,5.104-30.498,13.233C339.906,150.104,329.045,145,316.996,145c-22.882,0-41.498,18.393-41.498,41    c0,6.854,1.72,13.316,4.742,19H256c-6.075,0-11,4.925-11,11v140c0,6.075,4.925,11,11,11h182.987c6.075,0,11-4.925,11-11V216    C449.987,209.925,445.062,205,438.987,205z M427.987,275h-69.493v-48h19.498h49.995V275z M377.992,167    c10.751,0,19.497,8.523,19.497,19c0,10.477-8.746,19-19.497,19h-19.498v-19C358.494,175.523,367.241,167,377.992,167z     M297.498,186c0-10.477,8.747-19,19.498-19s19.498,8.523,19.498,19v19h-19.498C306.245,205,297.498,196.477,297.498,186z     M316.996,227h19.498v48H267v-48H316.996z M267,297h69.494v48H267V297z M358.494,345v-48h69.493v48H358.494z"/>
                        <g>
                            <path style={{"fill":secondaryColor}}  d="M195.004,159H73.012c-6.075,0-11-4.925-11-11c0-6.075,4.925-11,11-11h121.992     c6.075,0,11,4.925,11,11C206.004,154.075,201.079,159,195.004,159z"/>
                        </g>
                        <g>
                            <path style={{"fill":primaryColor}} d="M195.004,231H73.012c-6.075,0-11-4.925-11-11c0-6.075,4.925-11,11-11h121.992c6.075,0,11,4.925,11,11     C206.004,226.075,201.079,231,195.004,231z"/>
                        </g>
                        <g>
                            <path style={{"fill":secondaryColor}}  d="M195.004,303H73.012c-6.075,0-11-4.925-11-11s4.925-11,11-11h121.992c6.075,0,11,4.925,11,11     S201.079,303,195.004,303z"/>
                        </g>
                        <g>
                            <path style={{"fill":primaryColor}} d="M195.004,375H73.012c-6.075,0-11-4.925-11-11s4.925-11,11-11h121.992c6.075,0,11,4.925,11,11S201.079,375,195.004,375z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default GiftCard;
