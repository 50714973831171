import {useQuery} from '@apollo/client';
import { GET_REVIEWS } from '../Queries/review';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,REVIEW_IMAGE_URL } from '../../../Config';
import { ConvertHtmlToText } from '../../../GlobalFunctions/helper';

const UseChooseReviews = (projectID,lang,selectedReviews,FillFormSelectedItemsWithObj) => {

    const [variables,SetVariables] = useState({
        lang,
        projectID,
        limit:40,
        offset:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedReviewIDs,SetSelectedReviewIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_REVIEWS,{
        variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllReviews: [...prev.AllReviews, ...fetchMoreResult.AllReviews]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllReviews);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("Text"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];
        
        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.photo ? SERVER_URL + "/" + REVIEW_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right"
                    },
                    {
                        value:
                            <span 
                                className="cursor-help"
                                title={ConvertHtmlToText(val.text)}
                            >
                                {ConvertHtmlToText(val.text)}
                            </span>,
                        className:"flex-1 one-line-with-dots"
                    }
                ],
            }

            if(selectedReviews.length > 0)
                cData.selected =  (selectedReviews.indexOf(val.reviewID) != -1 ? true : false); 
            else
                cData.selected = false;

            cData.rowText  = val.text;
            cData.rowPhoto = val.photo;
            cData.rowID    = val.reviewID;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,reviewID) => {

        var checked = e.target.checked;
        var newSelectedReviewIDs = [...selectedReviewIDs];
        const newList = SelectReviewID(content,reviewID,checked,newSelectedReviewIDs)

        SetContent(newList);
        SetSelectedReviewIDs(newSelectedReviewIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedReviewIDs = [];
        const newList = SelectReviewID(content,"all",checked,newSelectedReviewIDs)

        SetSelectedReviewIDs(newSelectedReviewIDs);
        SetContent(newList);
    }

    const SelectReviewID = (list,reviewID,checked,rowsToSelect) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(reviewID == newList[i].rowID || reviewID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                {
                    for(let j = 0; j < rowsToSelect.length; j++)
                    {
                        if(!rowsToSelect[j].selected)
                            FillFormSelectedItemsWithObj("selectedReviews","reviewID",{
                                text: rowsToSelect[j].rowText,
                                reviewID: rowsToSelect[j].rowID,
                                photo: rowsToSelect[j].rowPhoto
                            },false);
                    }
                }

                FillFormSelectedItemsWithObj("selectedReviews","reviewID",{
                    text: newList[i].rowText,
                    reviewID: newList[i].rowID,
                    photo: newList[i].rowPhoto
                },false);
            } 
        }

        return newList;
    }

    return{
        content,
        totalContentLength: data && data.AllReviewsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedReviewIDs,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll
    }

}

export default UseChooseReviews;