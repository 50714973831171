import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { useApolloClient } from '@apollo/client';
import UseGetLanguage from '../Library/Language/UseGetLanguage';
import eye from '../../../Media/Images/Icons/eye.webp';
import UseAddEditLanguage from '../Library/Language/UseAddEditLanguage';
import InputCard from '../../../GlobalComponents/InputCard';
import RadioButton from '../../../GlobalComponents/RadioButton';

Modal.setAppElement('#root');

const AddEditLanguage = ({selectedLanguageID,SetSelectedLanguageID}) => {

    const client = useApolloClient();
    const {t} = useTranslation();

    const {
        loading,
        error,
        formData,
        AddEditLanguage,
        InitFormData,
        SetFormData,
    } = UseAddEditLanguage(selectedLanguageID,(d) => OnAddEditSuccess(d));

    const {
        loading:getLoading,
        error:getError
    } = UseGetLanguage(selectedLanguageID,(d) => {
        InitFormData(d);
    });

    const OnAddEditSuccess = async (d) => {

        SetSelectedLanguageID(null);

        await client.refetchQueries({
            include: ["AllLanguageMutations"],
        });
    }

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    console.log(formData);

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedLanguageID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedLanguageID === 0 ? t("AddLanguage") : t("EditLanguage"))}
                OnClose ={() => SetSelectedLanguageID(null)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <InputCard
                                title = {t("Display")}
                                icon = {eye}
                            >   
                                <div className="row">
                                    <div className="col-25">
                                        <div className="form-group">
                                            
                                            <label>{t("Enabled")} </label>
                                            <div className="radio-container">
                                                <RadioButton
                                                    text="Ano"
                                                    name="enabled"
                                                    id="enabled-1"
                                                    value={1}
                                                    checked = {formData.enabled === 1 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                                <RadioButton
                                                    text="Ne"
                                                    name="enabled"
                                                    id="enabled-2"
                                                    value={0}
                                                    checked = {formData.enabled === 0 ? true : false}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>                                                             
                                </div> 
                            </InputCard>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditLanguage()}
                >
                    {(selectedLanguageID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditLanguage;