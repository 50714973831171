import { useQuery } from "@apollo/client";
import { GET_CAMPAIGN } from "../Queries/campaign";
import { useState } from "react";

const UseGetCampaign = (selectedCampaignID,lang,projectID,CallBack) => {

    const [allLinks,SetAllLinks] = useState([]);

    const {loading,error} = useQuery(GET_CAMPAIGN,{
        variables:{
            campaignID:selectedCampaignID,
            lang,
            projectID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            if(selectedCampaignID != 0)
            {                
                CallBack({
                    name:                       d.Campaign.name,
                    linkID:                     d.Campaign.linkID,
                    active:                     d.Campaign.active,
                    isForNewsletter:            d.Campaign.isForNewsletter,
                    isForTherapy:               d.Campaign.isForTherapy,
                    isForAndroidMobileAppID:    d.Campaign.isForAndroidMobileAppID,
                    isForIOSMobileAppID:        d.Campaign.isForIOSMobileAppID,
                    useEmailsFromNewsletter:    d.Campaign.useEmailsFromNewsletter,
                    selectedForms:              GetSelectedForms(d.Campaign.forms),
                    selectedProducts:           GetSelectedProducts(d.Campaign.products),                
                });
            }
            else
            {
                CallBack({
                    name:                       "",
                    linkID:                     0,
                    active:                     1,
                    isForNewsletter:            0,
                    isForTherapy:               0,
                    isForAndroidMobileAppID:    0,
                    isForIOSMobileAppID:        0,
                    useEmailsFromNewsletter:    0,
                    selectedForms:              [],
                    selectedProducts:           [] 
                });
            }

            SetAllLinks(d.AllLinks);
        }
    });

    const GetSelectedForms = (forms) => {
        var newForms = [];
        for(let val of forms)
        {
            newForms.push({
                formID: val.formID,
                name:val.form.name
            })
        }
        return newForms;
    }

    const GetSelectedProducts = (products) => {
        var newProducts = [];
        for(let val of products)
        {
            newProducts.push({
                productID: val.productID,
                name:val.product.name,
                mainPhoto:val.product.mainPhoto
            })
        }
        return newProducts;
    }

    return{
        loading: loading,
        error: error,
        allLinks
    }

}

export default UseGetCampaign;