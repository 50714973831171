import {useQuery} from '@apollo/client';
import { GET_REVIEWS } from '../Queries/review';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,REVIEW_IMAGE_URL } from '../../../Config';
import YesNo from '../../../GlobalComponents/YesNo';
import { ConvertHtmlToText } from '../../../GlobalFunctions/helper';

const UseGetAllReviews = (lang,projectID) => {

    const [variables,SetVariables] = useState({
        lang,
        projectID,
        limit:40,
        offset:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedReviewIDs,SetSelectedReviewIDs] = useState([]);

    const {data,loading,error,refetch,fetchMore} = useQuery(GET_REVIEWS,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllReviews);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("Text"),
            className:"flex-1"
        },
        {
            value: t("Active"),
            className:"static smallest"
        }        
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.photo ? SERVER_URL + "/" + REVIEW_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right"
                    },
                    {
                        value:
                            <span 
                                className="cursor-help"
                                title={ConvertHtmlToText(val.text)}
                            >
                            {ConvertHtmlToText(val.text)}
                            </span>,
                        className:"flex-1 one-line-with-dots"
                    },
                    {
                        value:<YesNo isYes = {!!val.active} />,
                        className:"static smallest"
                    }
                ],
            }

            cData.rowID    = val.reviewID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllReviews: [...prev.AllReviews, ...fetchMoreResult.AllReviews]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    const SelectRow = (e,reviewID) => {

        var checked = e.target.checked;
        var newSelectedReviewIDs = [...selectedReviewIDs];
        const newList = SelectReviewID(content,reviewID,checked,newSelectedReviewIDs)

        SetContent(newList);
        SetSelectedReviewIDs(newSelectedReviewIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedReviewIDs = [];
        const newList = SelectReviewID(content,"all",checked,newSelectedReviewIDs)

        SetSelectedReviewIDs(newSelectedReviewIDs);
        SetContent(newList);
    }

    const SelectReviewID = (list,reviewID,checked,selectedReviewIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(reviewID == newList[i].rowID || reviewID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedReviewIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedReviewIDs.length; j++)
                    {
                        if(selectedReviewIDs[j] == newList[i].rowID)
                            selectedReviewIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectReviewID(newList[i].subData,reviewID,checked,selectedReviewIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        refetch();
    }

    return{
        content,
        headerData,
        totalContentLength: data && data.AllReviewsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        loading,
        error,
        selectedReviewIDs,
        SelectRow,
        SelectAll,
        UpdateList,
        FetchMore,
        GoToPage
    }

}

export default UseGetAllReviews;