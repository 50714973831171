import { useQuery } from "@apollo/client";
import { GET_MEMBERSHIP } from "../Queries/membership";
import { useState } from "react";
import { CompleteLangsArray, GetMainLanguage } from "../../../GlobalFunctions/helper";
import { useTranslation } from "react-i18next";
import UseChooseReservationPlace from "../../Reservation/Library/UseChooseReservationPlace";
import { GetCustomerRoleObj } from "./UseEditCustomerRoles";
import { SERVER_URL,MEMBERSHIP_IMAGE_URL } from "../../../Config";

const UseGetMembership = (selectedMembershipID,projectID,lang,CallBack) => {

    const {t} = useTranslation();

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allLinks, SetAllLinks] = useState([]);
    const [allQuestionnaires, SetAllQuestionnaires] = useState([]);
    const [allCustomerRoles, SetAllCustomerRoles] = useState([]);
    const [allVats,SetAllVats] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {GetEmptyLangs} = UseChooseReservationPlace();

    const {loading,error} = useQuery(GET_MEMBERSHIP,{
        variables:{
            membershipID:selectedMembershipID,
            lang,
            projectID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:"",
                invoiceName:"",
                price:0,
                prepaymentPrice:0,
                priceQrCode:null,
                prepaymentPriceQrCode:null,
                description: "",
                emailText: "",
                upSellBombDescription: "",
                successMessage: "",
                trialPeriodCancellationEmail: "",
                notificationOfEndingTrialPeriodEmail: "",
                emailForCustomerWhenAddedToGuide: "",
                noMoreFreeTrialPeriodWarning: "",
                notPossibleToMakeOrderWarning: "",
                recurringPaymentInfoEmail:"",
                reservationReminderEmail:"",
                therapyTextTemplate:"",
                therapyTextToRead:""
            }

            if(selectedMembershipID != 0)
            {
                const uploadParamNameUrl = SERVER_URL + "/" + MEMBERSHIP_IMAGE_URL + "/stredni_";
                let langs = CompleteLangsArray(d.MembershipWithLangs.langs,d.AllLanguageMutations,emptyLang,["priceQrCode","prepaymentPriceQrCode"],uploadParamNameUrl);
                
                CallBack({
                    linkID:                                         d.MembershipWithLangs.linkID,
                    vatID:                                          d.MembershipWithLangs.vatID,
                    questionnaireID:                                d.MembershipWithLangs.questionnaireID,
                    customerRoleID:                                 d.MembershipWithLangs.customerRoleID,
                    showForPurchase:                                d.MembershipWithLangs.showForPurchase,
                    isRecurring:                                    d.MembershipWithLangs.isRecurring,
                    isTherapy:                                      d.MembershipWithLangs.isTherapy,
                    showInPortal:                                   d.MembershipWithLangs.showInPortal,
                    useGuide:                                       d.MembershipWithLangs.useGuide,
                    useFreeTrialPeriod:                             d.MembershipWithLangs.useFreeTrialPeriod,
                    numberOfFreeTrialDays:                          d.MembershipWithLangs.numberOfFreeTrialDays,
                    numberOfDaysBeforeToSendReservationReminder:    d.MembershipWithLangs.numberOfDaysBeforeToSendReservationReminder,
                    photo:                                          null,
                    mainPhoto:                                      d.MembershipWithLangs.photo,
                    dateEdit:                                       d.MembershipWithLangs.dateEdit,
                    langs:                                          langs,
                    selectedCustomerRoles:                          GetCustomerRoles(d.MembershipWithLangs.customerRoles),
                    selectedMembershipTypes:                        GetMembershipTypes(d.MembershipWithLangs.membershipTypes),
                    selectedReservationPlaces:                      GetReservationPlaces(d.MembershipWithLangs.reservationPlaces,d.AllLanguageMutations),
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);

            var allLinks = [{linkID:"0",name:"- - -" + t("Choose") + "- - -"},...d.AllLinks]
            SetAllLinks(allLinks);

            var allQuestionnaires = [{questionnaireID:"0",internalName:"- - -" + t("Choose") + "- - -"},...d.AllQuestionnaires]
            SetAllQuestionnaires(allQuestionnaires);

            var allCustomerRoles = [{customerRoleID:"0",name:"- - -" + t("Choose") + "- - -"},...d.AllCustomerRolesBy]
            SetAllCustomerRoles(allCustomerRoles);

            SetAllVats(d.AllVats);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    const GetReservationPlaces = (reservationPlaces,allLanguageMutations) => {
        
        if(reservationPlaces)
        {
            const emptyLang = GetEmptyLangs();

            var newReservationPlaces = [];
            for(let val of reservationPlaces)
            {
                let langs = CompleteLangsArray(val.langs,allLanguageMutations,emptyLang);
                newReservationPlaces.push({

                    internalName: val.reservationPlace.internalName,
                    active: val.reservationPlace.active,
                    reservationPlaceID: val.reservationPlaceID,
                    backgroundColor: val.reservationPlace.backgroundColor,
                    times: val.reservationPlace.times,
                    selectedTimes:GetReservationTimes(val.times),
                    langs
                    
                });
            }

            return newReservationPlaces;
        }
        else
            return [];
    }

    const GetReservationTimes = (times) => {
        if(times)
        {
            var newTimes = [];
            for(let val of times)
            {
                newTimes.push(val.reservationPlaceTimeID);
            }

            return newTimes;
        }
        else
            return [];
    }

    const GetMembershipTypes = (membershipTypes) => {
        if(membershipTypes)
        {
            var newMembershipTypes = [];
            for(let val of membershipTypes)
            {
                newMembershipTypes.push({
                    membershipTypeID:val.membershipTypeID,
                    showForPurchaseOnWeb:val.showForPurchaseOnWeb,
                    showForPurchaseInPortal: val.showForPurchaseInPortal,
                    numberOfRepetitions: val.numberOfRepetitions,
                    lengthOfOneRepetition: val.lengthOfOneRepetition,
                    prices:GetPrices(val.prices),
                    langs:val.langs
                });
            }

            return newMembershipTypes;
        }
        else
            return [];
    }

    const GetPrices = (membershipTypePrices) => {

        if(membershipTypePrices)
        {
            var newMembershipTypePrices = [];
            for(let val of membershipTypePrices)
            {
                newMembershipTypePrices.push({
                    membershipTypePriceID:val.membershipTypePriceID,
                    connectedMembershipTypeID:val.connectedMembershipTypeID,
                    connectedMembershipTypePriceID:val.connectedMembershipTypePriceID,
                    isMain:val.isMain == 1 ? true : false,
                    langs:val.langs
                });
            }

            return newMembershipTypePrices;
        }
        else
            return [];
    }

    const GetCustomerRoles = (customerRoles) => {
        if(customerRoles)
        {
            var newCustomerRoles = [];
            for(let val of customerRoles)
            {
                newCustomerRoles.push(
                    {...GetCustomerRoleObj(val),
                        membershipCustomerRoleID: val.membershipCustomerRoleID,
                        name: val.customerRole.name,
                        backgroundColor: val.customerRole.backgroundColor
                    }
                );
            }

            return newCustomerRoles;
        }
        else
            return []
    }

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        mainLang,
        allLinks,
        allVats,
        allQuestionnaires,
        allCustomerRoles
    }

}

export default UseGetMembership;