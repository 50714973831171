import { useQuery } from "@apollo/client";
import { GET_RESERVATION_PLACE_VARIATION } from "../Queries/reservationPlaceVariation";
import { useState } from "react";
import { CompleteLangsArray, GetMainLanguage } from "../../../GlobalFunctions/helper";

const UseGetReservationPlaceVariation = (selectedReservationPlaceVariationID,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_RESERVATION_PLACE_VARIATION,{
        variables:{
            reservationPlaceVariationID:selectedReservationPlaceVariationID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:"",
            }

            if(selectedReservationPlaceVariationID != 0)
            {
                let langs = CompleteLangsArray(d.ReservationPlaceVariationWithLangs.langs,d.AllLanguageMutations,emptyLang);
                
                CallBack({
                    langs: langs,
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        mainLang
    }

}

export default UseGetReservationPlaceVariation;