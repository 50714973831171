import { useTranslation } from "react-i18next";
import ProgressBar from "./ProgressBar";

const TranslationProgressBar = ({translatedLangsCount,totalLangs,translatedLang}) => {
    const {t} = useTranslation();
    return(
        <div>
            <p className = "text-center form-group">{t("Translating")}: {translatedLang}</p>
            <ProgressBar
                progressValue = {translatedLangsCount}
                totalValue = {totalLangs}
            />
        </div>
    )
}

export default TranslationProgressBar;