import {useQuery} from '@apollo/client';
import { GET_ORDER_ITEMS } from '../Queries/order';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { GetPriceString } from '../../../GlobalFunctions/helper';

const UseGetAllOrderItems = (orderID,orderLang) => {

    const [variables,SetVariables] = useState({
        orderID,
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    const {data,loading,error} = useQuery(GET_ORDER_ITEMS,{
        variables,
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllOrderItemsByID);
            SetContent(contentData);
        }

    },[data,orderLang])

    var headerData = [
        {
            value: t("ItemName"),
            className:"flex-1"
        },
        {
            value: t("UnitCount"),
            className:"static text-center"
        },
        {
            value: t("PriceNoVat"),
            className:"static text-center"
        },
        {
            value: t("Price"),
            className:"static text-right"
        }
        
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:<div>
                            <div className="form-group">
                                <h3>{val.title}</h3>
                                <p className="fs-14">{val.productCode}</p>
                            </div>
                            {val.firstCoverID && val.firstCoverID != 0 ?
                                <p><strong>Vzor:</strong> {val.firstCover}</p>
                            :null}
                            {val.secondCoverID && val.secondCoverID != 0 ?
                                <p><strong>Vzor:</strong> {val.secondCover}</p>
                            :null}
                            {val.side ?
                                <p><strong>Orientace:</strong> {val.side}</p>
                            :null}
                        </div>,
                        className:"flex-1"
                    },
                    {
                        value:val.count,
                        className:"static text-center"
                    },
                    {
                        value: GetPriceString(val.priceNoVat  * val.count,orderLang,2),
                        className:"static text-center"
                    },
                    {
                        value: GetPriceString(val.price * val.count,orderLang),
                        className:"static text-right"
                    }
                ],
            }

            cData.rowID    = val.orderItemID;
            cData.totalProductPrice = val.price * val.count;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    return{
        content,
        headerData,
        loading,
        error
    }

}

export default UseGetAllOrderItems;