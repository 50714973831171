import { useEffect,useState } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import TopCategoriesResponziveSettings from "./TopCategoriesResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { TOP_CATEGORIES } from "../../../Library/elements";
import categoryIcon from '../../../../../Media/Images/Icons/category.webp';
import cancelIcon from '../../../../../Media/Images/Icons/cancel.webp';
import List from "../../../../../GlobalComponents/List/List";
import UseEditTopCategories from "../../../Library/UseEditTopCategories";
import ChooseCategories from "../../../../Eshop/Components/Products/ChooseCategories";
import Error from "../../../../../GlobalComponents/Error";

const TopCategoriesSettings = () => {

    const [showAddCategories, SetShowAddCategories] = useState(false);

    const {t} = useTranslation();
    const {
        formData:cmFormData,
        contentManagerProjectID,
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    const emptyTopCategoryObj = TOP_CATEGORIES(contentManagerProjectID);
    const {formData,SetFormData,SetCompleteFormData,FillFormSelectedItemsWithObjToArray,RemoveFormDataSelectedItems} = UseFormHandle(emptyTopCategoryObj.topCategories); 
    
    const {
        headerData,
        content,
        loading,
        error,
        GetSelectedCategories
    } = UseEditTopCategories(cmFormData.selectedLang,formData.selectedCategories[contentManagerProjectID]);

    useEffect(() => {

        const GetSelCategories = async () => {
            const settings = GetSettings(showSettingsElement.index,"topCategories");
            settings.selectedCategories = await GetSelectedCategories(settings.selectedCategories,contentManagerProjectID);
            SetCompleteFormData(settings);
        }

        if(showSettingsElement)
            GetSelCategories();

    },[showSettingsElement])

    useEffect(() => {

        var selectedCategories = [];
        for(let val of formData.selectedCategories[contentManagerProjectID])
        {
            selectedCategories.push(val.linkID);
        }

        SetSettings(showSettingsElement.index,"selectedCategories",selectedCategories,"topCategories");

    },[formData.selectedCategories[contentManagerProjectID]])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"topCategories");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"topCategories");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <TopCategoriesResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <TopCategoriesResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <TopCategoriesResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <TopCategoriesResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <TopCategoriesResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <TopCategoriesResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>

            <div className="form-group">
                <InputCard
                    className = "form-group"
                    title = {t("Categories")}
                    icon = {categoryIcon}
                    noBodyPadding = {true}
                    headerChildren = {
                        <Button 
                            OnClick = {(e) => {
                                e.stopPropagation();
                                SetShowAddCategories(true)
                            }}
                            className="btn-primary narrow"
                        >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>
                    }
                >   
                    {error ? 
                        <Error text={error} />
                    :
                        <List 
                            headerData = {headerData} 
                            headerClassName = {"no-top-border smaller-padding"}
                            rowClassname = {"smaller-padding"}
                            contentData = {content}
                            isSortable = {false}
                            loading = {loading}
                            optionsTitle = {""}
                            optionsClassName = "smallest"
                            replaceDotsImg = {cancelIcon}
                            ReplaceDotsOnClick = {(data) => FillFormSelectedItemsWithObjToArray("selectedCategories","linkID",{linkID:data.rowID},false,contentManagerProjectID)}
                        />
                    }
                </InputCard>
                {showAddCategories ? 
                    <ChooseCategories 
                        projectID = {contentManagerProjectID}
                        formData = {formData}
                        useDeletedAttribute = {false}
                        SetShowAddCategories = {SetShowAddCategories}
                        FillFormSelectedItemsWithObjToArray = {FillFormSelectedItemsWithObjToArray}
                    />
                :null}
            </div>
            
            <div className="form-group">
                <label>{t("ClassName")}</label>
                <Input 
                    type = "text"
                    name = "className"
                    value = {formData.className}
                    OnChange = {(e) => SetS(e)}
                />
            </div>
                
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default TopCategoriesSettings;