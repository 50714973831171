import Modal from 'react-modal';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from "react-i18next";
import Button from '../../../../GlobalComponents/Button';
import FileInput from '../../../../GlobalComponents/FileInput';
import UseUploadVideo from '../../Library/FileManager/UseUploadVideo';
import { useApolloClient } from '@apollo/client';
import Loading from '../../../../GlobalComponents/Loading';
import Input from '../../../../GlobalComponents/Input';
import RadioButton from '../../../../GlobalComponents/RadioButton';

Modal.setAppElement('#root');

const AddVideo = ({showAddVideo,videoTypeID,SetShowAddVideo}) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const {formData,loading,OpenMedia,UploadVideo,SetFormData} = UseUploadVideo(client,videoTypeID,() => {
        SetShowAddVideo(false);
    });

    return(
        <Modal
            isOpen={showAddVideo}
            onRequestClose={() => SetShowAddVideo(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content cm-file-manager"}
        >

            <ModalHeader 
                title = {t("AddVideo")}
                OnClose ={() => SetShowAddVideo(false)}
            />

            <div className="modal-body"> 

                {loading ?
                    <Loading />
                :
                    <div className="row">
                        {videoTypeID == 3 ?
                            <>
                                <div className="col-50">
                                    <div className = "form-group">
                                        <label>{t("Video")}</label>
                                        <FileInput 
                                            OnChange = {(e) => OpenMedia(e,"videoFile")}
                                            label = {(formData.videoFile ? formData.videoFile.name : t("ChooseVideo"))}
                                        />
                                    </div>
                                </div>
                                <div className="col-50">
                                    <div className = "form-group">
                                        <label>{t("VideoCover")}</label>
                                        <FileInput 
                                            OnChange = {(e) => OpenMedia(e,"posterFile")}
                                            label = {(formData.posterFile ? formData.posterFile.name : t("ChoosePhoto"))}
                                        />
                                    </div>
                                </div>
                            </>
                        :
                            <div className="col-50">
                                <div className = "form-group">
                                    <label>{t("Url")}</label>
                                    <Input
                                        name="embedCode"
                                        value={formData.embedCode}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>

                        }

                        <div className="col-50">
                            <div className = "form-group">
                                <label>{t("VideoOrientation")}</label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("LandscapeOrientation")}
                                        name="orientation"
                                        id="orientation-1"
                                        value={1}
                                        checked = {formData.orientation == 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("PortraitOrientation")}
                                        name="orientation"
                                        id="orientation-2"
                                        value={2}
                                        checked = {formData.orientation == 2 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => UploadVideo()}
                >
                    {t("Add")}
                </Button>
            </div>        

        </Modal>
    )
}

export default AddVideo;