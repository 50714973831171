import { useMutation } from "@apollo/client";
import { ADD_CAMPAIGN_CONTACTS } from "../Queries/campaignContacts";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseAddCampaignContact = (campaignID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
        
    const {
        formData, 
        SetFormData
    } = UseFormHandle({
        emails: "",
        lang:   ""
    });

    const [AddEditCampaignContactMutation,{error,loading}] = useMutation(ADD_CAMPAIGN_CONTACTS,{
        onCompleted: async (d) => {
   
            if(OnCompleted)
                OnCompleted(d.AddCampaifnContacts);
            
            const notifyText = (t("NewContactsIsInTheWorld"));
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditCampaignContact = () => {

        if(formData.lang)
        {
            if(formData.emails)
            {
                AddEditCampaignContactMutation({
                    variables:{
                        emails:       formData.emails,
                        campaignID :  campaignID,
                        lang:         formData.lang,   
                    }
                })
            }
            else
                AddNotification(t("NoEmailsNoGo"),false); 
        }
        else
            AddNotification(t("NoLangNoGo"),false); 
    } 

    return {
        error,
        loading,
        formData,
        AddEditCampaignContact,
        SetFormData
    }

}

export default UseAddCampaignContact;