import { SERVER_URL,PRODUCT_IMAGE_URL } from "../../../../../Config";
import { GetProductPrice,GetPriceString } from "../../../Library/functions";

const Product = ({
    productData,
    elementData,
    projectData,
    selectedLangData,
    doNotUseAspectRatio
}) => {

    const buttonBgColor = projectData.buttonBgColor.split("-");
    const price = GetProductPrice(productData);

    var btnClass = "price btn " + elementData.size + " " + elementData.color + " " + elementData.className;
    if(buttonBgColor.length > 1)
        btnClass += " gradient";
    if(productData.discount != 0 || productData.commonPrice != 0)
        btnClass += " two-lines";

    return(
        <div className = {"card product" + (elementData.howToBuy == 0 ? " buy-on-page" : "")}>
            {productData.mainPhoto ?
                <div className={"img-container" + (doNotUseAspectRatio ? " no-aspect-ratio" : "")}>
                    <img
                        src={SERVER_URL + "/" + PRODUCT_IMAGE_URL + "/p-" + productData.productID + "/stredni_" + productData.mainPhoto}
                        alt={productData.name}
                    />
                </div>
            :null}
            <h3>{productData.name}</h3>
            <div className="text-center">
                <button 
                    className={btnClass} 
                >
                    {GetPriceString(price,selectedLangData.suffix,selectedLangData.currencyCode)}
                    {productData.discount != 0 || productData.commonPrice != 0 ? 
                        <span className="old-price">
                            {GetPriceString((productData.commonPrice ? productData.commonPrice : productData.price),selectedLangData.suffix,selectedLangData.currencyCode)}
                        </span>
                    :null}  
                </button>
            </div>
        </div>
    )
}

export default Product;