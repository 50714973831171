import InputCard from '../../../GlobalComponents/InputCard';
import { useTranslation } from 'react-i18next';
import ColorPicker from '../../../GlobalComponents/ColorPicker';
import emailIcon from '../../../Media/Images/Icons/email.webp';
import buttonIcon from '../../../Media/Images/Icons/button.webp';
import inputIcon from '../../../Media/Images/Icons/input.webp';
import headlineIcon from '../../../Media/Images/Icons/letter-h.webp';
import projectIcon from '../../../Media/Images/Icons/projects.webp';
import RadioButton from '../../../GlobalComponents/RadioButton';
import ChooseGradientColor from '../../../GlobalComponents/ChooseGradientColor';
import Input from '../../../GlobalComponents/Input';

const Colors = ({
    formData,
    SetFormData
}) => {

    const {t} = useTranslation();
    
    return(
        <div>
            <InputCard
                className = "form-group"
                title = {t("OnWeb")}
                icon={projectIcon}
            >  
                <div className="form-group">
                    <ColorPicker 
                        OnChange={(color) => SetFormData("linkColor",color)}
                        colorCode = {formData.linkColor}
                        text = {t("Links")}
                    />
                </div>
                <div className="row"> 
                    <div className="col-50">
                        <InputCard
                            className = "form-group"
                            title = {t("Button")}
                            icon={buttonIcon}
                        >  
                            <div className="form-group">
                                <ColorPicker 
                                    OnChange={(color) => SetFormData("buttonTextColor",color)}
                                    colorCode = {formData.buttonTextColor}
                                    text = {t("TextColor")}
                                />
                            </div>
                            <div className="form-group">
                                <label>{t("BorderRadius")}</label>
                                <Input
                                    name="buttonBorderRadius"
                                    value={formData.buttonBorderRadius}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                            <div className="form-group">
                                <label>{t("BackgroundColor")}</label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("SimpleColor")}
                                        name="buttonBgColorType"
                                        id="buttonBgColorType-1"
                                        value={1}
                                        checked = {formData.buttonBgColorType === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("Gradient")}
                                        name="buttonBgColorType"
                                        id="buttonBgColorType-2"
                                        value={2}
                                        checked = {formData.buttonBgColorType === 2 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                            {formData.buttonBgColorType == 1 ?
                                <div className="form-group">
                                    <ColorPicker 
                                        OnChange={(color) => SetFormData("buttonBgColor",color)}
                                        colorCode = {formData.buttonBgColor.split("-")[0]}
                                        text = {t("Color")}
                                    />
                                </div>
                            :
                                <ChooseGradientColor
                                    color = {formData.buttonBgColor}
                                    OnChange = {(from,to) => SetFormData("buttonBgColor",from + "-" + to)} 
                                />
                            }

                                                        
                        
                        </InputCard>
                    </div>
                    <div className="col-50">
                        <InputCard
                            className = "form-group"
                            title = {t("Headline")}
                            icon={headlineIcon}
                        >  
                            <div className="form-group">
                                <label>{t("Type")}</label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("SimpleColor")}
                                        name="headlineColorType"
                                        id="headlineColorType-1"
                                        value={1}
                                        checked = {formData.headlineColorType === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("Gradient")}
                                        name="headlineColorType"
                                        id="headlineColorType-2"
                                        value={2}
                                        checked = {formData.headlineColorType === 2 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                            {formData.headlineColorType == 1 ?
                                <div className="form-group">
                                    <ColorPicker 
                                        OnChange={(color) => SetFormData("headlineColor",color)}
                                        colorCode = {formData.headlineColor.split("-")[0]}
                                        text = {t("Color")}
                                    />
                                </div>
                            :
                                <ChooseGradientColor
                                    color = {formData.headlineColor}
                                    OnChange = {(from,to) => SetFormData("headlineColor",from + "-" + to)} 
                                />
                            }

                        </InputCard>
                        <InputCard
                            className = "form-group"
                            title = {t("InputTextInput")}
                            icon={inputIcon}
                        >  
                            <div className="form-group">
                                <label>{t("BorderRadius")}</label>
                                <Input
                                    name="inputBorderRadius"
                                    value={formData.inputBorderRadius}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>

                        </InputCard>
                    </div>
                </div>
            
            </InputCard>

            <InputCard
                title = {t("Emails")}
                icon={emailIcon}
            >   
                <div className="form-group">
                    <ColorPicker 
                        OnChange={(color) => SetFormData("emailLinkColor",color)}
                        colorCode = {formData.emailLinkColor}
                        text = {t("LinksInEmail")}
                    />
                </div>
            
            </InputCard>

        </div>
    )
}

export default Colors;