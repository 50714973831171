import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditReservationPlace from "./AddEditReservationPlace";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllReservationPlaces from "../Library/UseGetAllReservationPlaces";
import UseDeleteReservationPlaces from "../Library/UseDeleteReservationPlaces";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { UseAuth } from "../../Auth/Library/UseAuth";

const ReservationPlaces = () => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    const [selectedReservationPlaceID, SetSelectedReservationPlaceID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedReservationPlaceIDs,
        SelectRow,
        SelectAll,
        UpdateList
    } = UseGetAllReservationPlaces(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteReservationPlaces
    } = UseDeleteReservationPlaces(selectedReservationPlaceIDs,() => UpdateList());

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ReservationPlacesList")}
                        OnAddButtonClick={() => SetSelectedReservationPlaceID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (reservationPlacesID) => SetSelectedReservationPlaceID(reservationPlacesID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedReservationPlaceID || selectedReservationPlaceID == 0 ?
                <AddEditReservationPlace
                    selectedReservationPlaceID = {selectedReservationPlaceID}
                    SetSelectedReservationPlaceID = {SetSelectedReservationPlaceID}
                    OnAddEditSuccess = {() => {
                        UpdateList();
                        SetSelectedReservationPlaceID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteReservationPlaces")} 
                    CallBack={DeleteReservationPlaces} 
                />
            :null}

        </>
    )
}

export default ReservationPlaces;