import { UseContentManager } from "../../../Library/UseContentManager";
import ControlsMenu from "../../ControlsMenu";
import { GetColumnStyles } from "../../../Library/functions";
import AllElements from "../../AllElements";
import tabsIcon from '../../../../../Media/Images/Icons/tabs.webp';
import { useTranslation } from "react-i18next";

const Tab = ({data,lastIndexes,index,tabsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement} = UseContentManager();
    const {t} = useTranslation();

    var className = "";
    if(data.className) className += " " + data.className;
    if(data.elements && data.elements.length == 0)
        className += " empty";
    
    var stylesData = GetColumnStyles(data);
    className += " " + stylesData.styleClassName;

    var showMenu = false;
    if(indexToShowMenu === lastIndexes + "-" + index)
        showMenu = true;

    return(
        <>
            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "tab"
                    index = {lastIndexes + "-" + index} 
                    showAddContent = {true} 
                    position = {index + 1}
                    elementsCount = {tabsCount}
                />
            :null}
            
            <div className={"cm-tab-elements " + className} style={stylesData.styles}>

                {data.elements.length == 0 ?
                    <div className="cm-start-info big">
                        <img src={tabsIcon} alt="" />
                        <h2 className="cm-no-bottom-margin">{t("TabContent")}</h2>
                        {t("ClickHereToAddFirstTabContent")}
                    </div>
                :

                    (data.elements.map((elmItem,elmIndex) => {
                        return(
                            <AllElements
                                key = {elmIndex}
                                elmItem = {elmItem}
                                elmIndex = {elmIndex}
                                elementsCount = {data.elements.length}
                                index = {lastIndexes + "-" + index}
                            />   
                        )
                    }))
                }
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border col-border" + (showMenu ? " cm-selected" : "")} 
                    onClick={(e) => SelectElement(e,lastIndexes + "-" + index)} 
                ></div>
            :null}
        </>       
    )
}

export default Tab;