import { useQuery } from "@apollo/client";
import { GET_LINK } from "../Queries/link";
import { useState } from "react";
import { CompleteLangsArray } from "../../../GlobalFunctions/helper";
import { useTranslation } from "react-i18next";
import { SERVER_URL,LINK_IMAGE_URL} from '../../../Config/index';

const UseGetLink = (selectedLinkID,lang,isEshopCategory,projectID,CallBack) => {

    const {t} = useTranslation();

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allParameters, SetAllParameters] = useState([]);
    const [allProjects, SetAllProjects] = useState([]);
    const [allLinks, SetAllLinks] = useState([]);

    const {loading,error} = useQuery(GET_LINK,{
        variables:{
            linkID:selectedLinkID,
            lang,
            projectID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:"",                    
                ownUrl:"", 
                description:"",                   
                metaTitle:"",                    
                metaKeywords:"",                    
                metaDescription:"",                    
                metaImage:null,
                metaImageShow:"", 
                membershipAfterBuyEmailText:"",
                trialPeriodButtonName: "",
                trialPeriodPopUpTextUp: "",
                trialPeriodPopUpTextDown: "",
                membershipTypeButtonName: "",
                reservationPlaceButtonName: "",
                reservationPlacePopUpTextUp: "",
                reservationPlacePopUpTextDown:"",
                paymentButtonName: "",
                reservationDateButtonName: "",
                personalDataButtonName: "",
                membershipTypePopUpTextUp: "",
                membershipTypePopUpTextDown: "",
                textBellowFillCreditCard: "",
                noteHeadline:"",
                upSellBombTitle:"",
            }

            if(selectedLinkID != 0)
            {
                const uploadParamNameUrl = SERVER_URL + "/" + LINK_IMAGE_URL + "/fb_";
                let langs = CompleteLangsArray(d.LinkWithLangs.langs,d.AllLanguageMutations,emptyLang,"metaImage",uploadParamNameUrl);

                var selectedParameters = [];
                for(let val of d.LinkWithLangs.parameters)
                {
                    selectedParameters.push({
                        parameterID: val.parameterID,
                        name: val.parameter.name
                    });
                }

                CallBack({
                    active:                     d.LinkWithLangs.active,
                    parentID:                   d.LinkWithLangs.parentID,
                    projectID:                  d.LinkWithLangs.projectID,
                    thanksPageLinkID:           d.LinkWithLangs.thanksPageLinkID,
                    inHeader:                   d.LinkWithLangs.inHeader,
                    inFooter:                   d.LinkWithLangs.inFooter,
                    isBlog:                     d.LinkWithLangs.isBlog,
                    isThisTermsAndConditions:   d.LinkWithLangs.isThisTermsAndConditions,
                    isThisPersonalInformation:  d.LinkWithLangs.isThisPersonalInformation,
                    hideMenu:                   d.LinkWithLangs.hideMenu,
                    hideAddress:                d.LinkWithLangs.hideAddress,
                    hidePassword:               d.LinkWithLangs.hidePassword,
                    noteIsRequired:             d.LinkWithLangs.noteIsRequired,
                    usePriceFilter:             d.LinkWithLangs.usePriceFilter,
                    useDepthFilter:             d.LinkWithLangs.useDepthFilter,
                    useWidthFilter:             d.LinkWithLangs.useWidthFilter,
                    useCreditCardForPayment:    d.LinkWithLangs.useCreditCardForPayment,
                    linkID:                     selectedLinkID,
                    langs:                      langs,
                    isEshopCategory:            d.LinkWithLangs.isEshopCategory,
                    photo:                      null,
                    shortcut:                   d.LinkWithLangs.shortcut,
                    mainPhoto:                  d.LinkWithLangs.photo,
                    primarilySortBy:            d.LinkWithLangs.primarilySortBy,
                    selectedParameters:         selectedParameters,
                    selectedMemberships:        GetMemberships(d.LinkWithLangs.memberships),
                    selectedProducts:           GetProducts(d.LinkWithLangs.upSellBombProducts),
                    selectedServices:           GetServices(d.LinkWithLangs.upSellBombMemberships)
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    projectID: (projectID ? projectID : 0),
                    isEshopCategory:(isEshopCategory ? 1 : 0),
                    langs:langs,
                    mainPhoto:"",
                    primarilySortBy:"pri"
                });
            }

            SetAllParameters(d.AllParameters);
            SetAllLanguageMutations(d.AllLanguageMutations);

            var allLinks = [{linkID:"0",name:"---" + t("Choose") + "---"},...d.AllLinks];
            SetAllLinks(allLinks);

            var allProjects = [{projectID:"0",name:"---" + t("Choose") + "---"},...d.AllProjects]
            SetAllProjects(allProjects);
        }
    });

    const GetProducts = (selectedProducts) => {
        
        var newProducts = [];
        if(selectedProducts)
        {
            for(let val of selectedProducts) {
                newProducts.push({
                    productID:val.productID,
                    mainPhoto: val.product.mainPhoto,
                    name: val.product.name
                });
            }
        }

        return newProducts;
    }

    const GetServices = (selectedServices) => {
        
        var newServices = [];
        if(selectedServices)
        {
            for(let val of selectedServices) {
                newServices.push({
                    membershipID:val.membershipID,
                    name: val.membership.name
                });
            }
        }

        return newServices;
    }

    const GetMemberships = (memberships) => {

        var newMemberships = [];
        for(let val of memberships)
        {
            newMemberships.push({
                membershipID: val.membershipID,
                name: val.membership.name,
                discount:val.discount,
                canBeReserved:val.membership.canBeReserved
            });
        }

        return newMemberships;
    }

    return{
        loading,
        error,
        allLanguageMutations,
        allParameters,
        allProjects,
        allProjectLinks:allLinks
    }

}

export default UseGetLink;