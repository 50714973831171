import React, { useState, useContext, createContext, useEffect } from 'react';
import md5 from 'md5';
import { useApolloClient } from '@apollo/client';
import { GET_ADMIN_USER_CONTEXT } from '../Queries/auth';

const authContext = createContext();
export const tokenName = md5("ls_gl_apttp_!@~`|=-98QhmZm713Dhfgk'§_-11!§_sys");

export const AuthProvider = ({children}) => {

    var token = localStorage.getItem(tokenName);
    if(!token) token = null;
   
    const auth = UseProviderAuth({
        adminUserID:0,
        adminRoleID:0,
        name:"",
        surname:"",
        email:"",
        lang:"",
        language:{},
        adminRoleModules:[],
        adminRoleProjects:[]
    },token);

    return(
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

export const UseAuth = () => {
    return useContext(authContext);
}

const UseProviderAuth = (adminInit,tokenInit) => {

    const client = useApolloClient()

    const [user,SetUser] = useState(adminInit);

    const [token,SetToken]       = useState(tokenInit);
    const [showAuth,SetShowAuth] = useState(false);

    const SaveToken = (token) => {
        localStorage.setItem(tokenName,token);
        SetToken(token);
    }

    const Logout = () => {
        SetUser(adminInit);
        SetShowAuth(true);
        localStorage.removeItem(tokenName);
        SetToken(null);   
    }

    const IsSignedIn = () => {
        return !!token;
    }

    const GetAdminUserContext = (OnExists,OnNotExists) => {

        client.query({
            query:GET_ADMIN_USER_CONTEXT,
            fetchPolicy: 'network-only',
        }).then((d) => {
            
            if(d.data.AdminUserContext && d.data.AdminUserContext.adminUserID && token)
            {
                SetUser({
                    adminUserID:d.data.AdminUserContext.adminUserID,
                    adminRoleID:d.data.AdminUserContext.adminRoleID,
                    name:d.data.AdminUserContext.name,
                    surname:d.data.AdminUserContext.surname,
                    email:d.data.AdminUserContext.email,
                    lang:d.data.AdminUserContext.lang,
                    language:d.data.AdminUserContext.language,
                    adminRoleModules:d.data.AdminUserContext.adminRoleModules,
                    adminRoleProjects:d.data.AdminUserContext.adminRoleProjects
                });

                if(OnExists) 
                    OnExists(d.data.AdminUserContext);
            }
            else if(OnNotExists)
                OnNotExists();

        }).catch((err) => console.log(err))
    }

    const GetAdminUserFirstModule = (type) => {

        var modules = [...user.adminRoleModules];

        if(type == "global")
            modules.sort((a,b) => a.module.isForProject - b.module.isForProject);
        else 
            modules.sort((a,b) => b.module.isForProject - a.module.isForProject);

        var selectedModule = null;
        if(modules && modules[0] && modules[0].module.isForProject == (type == "global" ? 0 : 1))
        {
            selectedModule = modules[0];
            for(let val of modules)
            {
                if(val.module.parentModuleID == modules[0].moduleID)
                {
                    selectedModule = val;
                    break;
                }
            }
        }

        return selectedModule;
    }

    const HasEnabledModule = (moduleID) => {

        var enabled = false;
        for(let val of user.adminRoleModules)
        {
            if(val.moduleID == moduleID)
                enabled = true;
        }

        return enabled;
    }

    return {
        token,
        showAuth,
        user,
        SaveToken,
        IsSignedIn,
        Logout,
        SetShowAuth,
        SetUser,
        GetAdminUserContext,
        GetAdminUserFirstModule,
        HasEnabledModule
    }

}