import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputCard from '../../../GlobalComponents/InputCard';
import membershipIcon from '../../../Media/Images/Icons/membership.webp';
import Button from '../../../GlobalComponents/Button';
import List from '../../../GlobalComponents/List/List';
import NoItems from '../../../GlobalComponents/NoItems';
import UseGetAllMembershipTypes from '../Library/UseGetAllMembershipTypes';
import { UseAuth } from '../../Auth/Library/UseAuth';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import AddEditMembershipType from './AddEditMembershipType';
import UseSortList from '../../../GlobalHooks/UseSortList';
import { GET_MEMBERSHIP_TYPES,UPDATE_MEMBERSHIP_TYPE_PRIORITY } from '../Queries/membership';
import { useApolloClient } from '@apollo/client';

const TypesAndPrices = ({
    selectedMembershipID
}) => {

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t} = useTranslation();
    const [selectedMembershipTypeID, SetSelectedMembershipTypeID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        UpdateList
    } = UseGetAllMembershipTypes(selectedMembershipID,user.lang);

    const {OnDragEnd} = UseSortList(
        client,
        UPDATE_MEMBERSHIP_TYPE_PRIORITY,
        GET_MEMBERSHIP_TYPES,
        "AllMembershipTypes",
        "membershipTypeID",
        null,
        null,
        {membershipID:selectedMembershipID},
        {lang:user.lang,membershipID:selectedMembershipID},
    );

    return(
        <> 
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                (selectedMembershipID && selectedMembershipID != 0 ?
                    <>
                        <InputCard
                            title = {t("MembershipsTypeList")}
                            icon = {membershipIcon}
                            noBodyPadding={true}
                            headerChildren={
                                <Button
                                    className = "btn-primary narrow"
                                    OnClick = {() => SetSelectedMembershipTypeID(0)}
                                >
                                    {t("Add")}
                                </Button>
                            }
                        > 
                            <List 
                                headerData = {headerData} 
                                contentData = {content}
                                isSortable = {true}
                                loading = {loading}
                                OnDragListRowEnd = {(e)=> OnDragEnd(e)}
                                options = {{
                                    OnEditClick: (membershipsID) => SetSelectedMembershipTypeID(membershipsID)
                                }}
                            />   

                        </InputCard>  
                        
                        {selectedMembershipTypeID || selectedMembershipTypeID == 0 ?
                            <AddEditMembershipType
                                selectedMembershipID = {selectedMembershipID}
                                selectedMembershipTypeID = {selectedMembershipTypeID}
                                SetSelectedMembershipTypeID = {SetSelectedMembershipTypeID}
                                OnAddEditSuccess = {() => {
                                    UpdateList();
                                    SetSelectedMembershipTypeID(null);
                                }}
                            />
                        :null}
                    </>
                :   
                <NoItems className="no-margin" text={t("ToAddMembershipTypesSaveMembershipFirst")} /> 
                )
            }
        </>
    )
}

export default TypesAndPrices;