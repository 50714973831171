import { useQuery } from "@apollo/client";
import { GET_MARKETING_BANNER } from "../Queries/marketingBanner";
import { useState } from "react";
import { CompleteLangsArray, GetMainLanguage } from "../../../GlobalFunctions/helper";

const UseGetMarketingBanner = (selectedMarketingBannerID,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [mainLang, SetMainLang] = useState({});
    const [allProjects, SetAllProjects] = useState([]);
    const [allMarketingBannerTypes, SetAllMarketingBannerTypes] = useState([]);

    const {loading,error} = useQuery(GET_MARKETING_BANNER,{
        variables:{
            marketingBannerID:selectedMarketingBannerID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                text:"", 
                buttonText:"", 
                buttonUrl:"", 
            }
            const emptyProjectData = {
                showInProject:1, 
                showInBlog:1, 
                showInAllArticles:1 
            }

            if(selectedMarketingBannerID != 0)
            {
                let langs = CompleteLangsArray(d.MarketingBannerWithLangs.langs,d.AllLanguageMutations,emptyLang);
                let projectData = CompleteLangsArray(d.MarketingBannerWithLangs.projectData,d.AllProjects,emptyProjectData,null,null,"projectID","projectID");
                
                CallBack({
                    name:d.MarketingBannerWithLangs.name,
                    buttonColor:d.MarketingBannerWithLangs.buttonColor,
                    marketingBannerTypeID:d.MarketingBannerWithLangs.marketingBannerTypeID,
                    photo:null,
                    photoPortrait:null,
                    projectData,
                    mainPhoto:d.MarketingBannerWithLangs.photo,
                    mainPhotoPortrait: d.MarketingBannerWithLangs.photoPortrait,
                    dateEdit: d.MarketingBannerWithLangs.dateEdit, 
                    langs,
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);
                let projectData = CompleteLangsArray([],d.AllProjects,emptyProjectData,null,null,"projectID","projectID");

                CallBack({
                    langs,
                    projectData
                });
            }
            SetAllProjects(d.AllProjects);
            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllMarketingBannerTypes(d.AllMarketingBannerTypes);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allProjects,
        allMarketingBannerTypes,
        mainLang
    }

}

export default UseGetMarketingBanner;