import { useState,useEffect  } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_LANGUAGES } from "../../Queries/language";
import { useTranslation } from "react-i18next";
import YesNo from "../../../../GlobalComponents/YesNo";
import { SERVER_URL,FLAG_IMAGE_PATH } from "../../../../Config";

const UseGetAllLanguages = () => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    const {data,loading,error} = useQuery(GET_ALL_LANGUAGES,{
        fetchPolicy:"network-only"
    });

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllLanguageMutations);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("Name"),
            className:"flex-1 no-padding-left"
        },
        {
            value: t("Shortcut"),
            className:"static text-center"
        },
        {
            value: t("Enabled"),
            className:"static text-center"
        },
        
    ];

    
    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.file ? SERVER_URL + "/" + FLAG_IMAGE_PATH + "/" + val.file : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.title,
                        className:"flex-1"
                    },
                    {
                        value: val.suffix,
                        className:"static text-center"
                    },
                    {
                        value: <YesNo isYes = {!!val.enabled} />,
                        className:"static text-center"
                    }
                ],
            }

            cData.rowID    = val.languageID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    return{
        content,
        headerData,
        loading,
        error
    }

}

export default UseGetAllLanguages;