import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import buttonIcon from "../../../../../Media/Images/Icons/button.webp";
import inputIcon from "../../../../../Media/Images/Icons/input.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import FormResponziveSettings from "./FormResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { FORM } from "../../../Library/elements";
import { GET_FORMS } from "../../../../Form/Queries/form";
import { useQuery } from "@apollo/client";
import Loading from "../../../../../GlobalComponents/Loading";
import Select from "../../../../../GlobalComponents/Select";
import Error from "../../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../../GlobalFunctions/helper";
import SelectBoxColors from "../../../../../GlobalComponents/SelectBoxColors";

const FormSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(FORM.form); 
    const {
        formData:cmFormData,
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"form");
            SetCompleteFormData(settings);
        }

    },[showSettingsElement])

    const {data,loading,error} = useQuery(GET_FORMS,{
        variables:{
            lang:cmFormData.userLang
        },
        fetchPolicy:"network-only",
        skip:(cmFormData.userLang == "" ? true : false)
    })

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"form");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"form");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <FormResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <FormResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <FormResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <FormResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <FormResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <FormResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>   
            {error ?
                <Error text = {GetApolloErrorText(error)} />
            :
                (loading ?
                    <Loading />
                :
                    <>   
                        <div className="form-group">
                            <label>{t("Form")}</label>
                            <Select
                                name="formID"
                                value={formData.formID}
                                OnChange = {(e) => SetS(e)}
                            >
                                <option value={0}>---{t("Choose")}---</option>
                                {data.AllForms && data.AllForms.map((item) => (
                                    <option
                                        key={item.formID}
                                        value={item.formID}
                                    >{item.name}</option>
                                ))}
                                
                            </Select>
                        </div>                         
                        
                        <div className="form-group">
                            <label>{t("ClassName")}</label>
                            <Input 
                                type = "text"
                                name = "className"
                                value = {formData.className}
                                OnChange = {(e) => SetS(e)}
                            />
                        </div>

                        <InputCard
                            title = {t("Input")}
                            icon = {inputIcon}
                            className="form-group"
                        >
                            <div className="row">
                                <div className="col-50">
                                    <div className="form-group">
                                        <label>{t("Size")}</label>
                                        <Select
                                            name = {"inputSize"}
                                            OnChange = {(e) => SetS(e)}
                                            value = {formData.inputSize}
                                        >
                                            <option value={""}>{t("Small")}</option>
                                            <option value={"middle"}>{t("Middle")}</option>
                                            <option value={"bigger"}>{t("Big")}</option>
                                            
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </InputCard>

                        <InputCard
                            title = {t("Button")}
                            icon = {buttonIcon}
                            className="form-group"
                        >
                            <div className="row">
                                <div className="col-50">
                                    <div className="form-group">
                                        <label>{t("Color")}</label>
                                        <Select
                                            name = {"color"}
                                            OnChange = {(e) => SetS(e)}
                                            value = {formData.color}
                                        >
                                            <SelectBoxColors />
                                            
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-50">
                                    <div className="form-group">
                                        <label>{t("Size")}</label>
                                        <Select
                                            name = {"size"}
                                            OnChange = {(e) => SetS(e)}
                                            value = {formData.size}
                                        >
                                            <option value={""}>{t("Small")}</option>
                                            <option value={"middle"}>{t("Middle")}</option>
                                            <option value={"bigger"}>{t("Big")}</option>
                                            
                                        </Select>
                                    </div>
                                </div>   
                            </div>
                        </InputCard>
                            
                        <div className="form-group">
                            <InputCard
                                title = {t("ResponziveData")}
                                icon = {responziveIcon}
                                tabsData = {responziveTabs}
                            />
                        </div>
                        
                        <div className="cm-remove-section-elm">
                            <Button 
                                className="btn-brown"
                                OnClick = {() => RemoveElm()}
                            >
                                {t("RemoveElement")}
                            </Button>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default FormSettings;