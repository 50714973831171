import { Link } from "react-router-dom";
import emailIcon from '../../../Media/Images/Icons/email_input.svg';
import keyIcon from '../../../Media/Images/Icons/password_input.svg';
import { useTranslation } from 'react-i18next';
import { UseAuth } from '../Library/UseAuth';
import UseLogin from '../Library/UseLogin';
import Error from '../../../GlobalComponents/Error';
import Button from '../../../GlobalComponents/Button';
import Loading from '../../../GlobalComponents/Loading';
import Input from "../../../GlobalComponents/Input";
import { useEffect } from "react";
import Warning from "../../../GlobalComponents/Warning";

const Login = () => {

	const { showAuth,SetShowAuth,GetAdminUserContext } = UseAuth();
    const {
		error,
		loading,
		formData,
		disabled,
		settData,
		SetFormData,
		Login,
		RedirectTo
	} = UseLogin()

	const {t} = useTranslation();

	useEffect(() => {

		GetAdminUserContext((adminUser) => {
			RedirectTo(adminUser);
		},() => {
			SetShowAuth(true);
		});

	},[])
			  
    return (
        <div className="auth-panel">
			{!showAuth ?
				<Loading />
			:
				<>
					<div className="card">

						<div className="card-body">

							{
							settData && 
							settData.Settings.email && 
							settData.Settings.emailName && 
							settData.Settings.adminUrl && 
							settData.Settings.sendgridApiKey ?
							
								<>
									<div className="form-group">

										<Input 
											OnChange={e => SetFormData(e)}
											value={formData.email}
											type="text" 
											name="email"
											placeholder={t('Email')} 
											ariaLabel="Email" 
											preppend={<img alt="" src={emailIcon} />}
										/>

									</div>
									<div className="form-group">

										<Input 
											OnChange={e => SetFormData(e)}
											value={formData.password}
											type="password" 
											name="password"
											placeholder={t('Password')} 
											ariaLabel="Password" 
											preppend={<img alt="" src={keyIcon} />}
										/>
																
									</div>
									
									<div className="form-group">
										<Button 
											disabled={disabled} 
											loading={loading} 
											OnClick={() => Login()} 
											className="btn btn-primary full-width"
										>{t('LogIn')}</Button>
									</div>
									
									{error ? 
										<Error text = {error} />
									:null}
									
									<div className="text-center">
										<Link to='/reset-password'>{t('ForgottenPwdQuestion')}</Link>
									</div>
								</>
							:
								<Warning 
									className = {"no-margin"}
									text={t("ItIsNecessaryToSetAdminSettings")} 
								/>
							}
							
						</div>
					</div>
				</>
			}
        </div>
    );
  
}

export default Login;
