import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState } from 'react';
import TinyMCEFileManager from './TinyMCEFileManager';
import { useTranslation } from 'react-i18next';
import UseFormHandle from '../../../GlobalHooks/UseFormHandle';
import ChooseProducts from '../../Eshop/Components/Products/ChooseProducts';

const TinyMCEEditor = ({
    toolbar,
    value,
    height,
    OnEditorChange,
    useMediaEditor,
    useProductsAndForms,
    useAddProducts,
    allForms,
    allProducts
}) => {

    const {t} = useTranslation();
    const editorRef = useRef(null);
    const [showManager,SetShowManager] = useState(false);
    const [tinyMCEFileType,SetTinyMCEFileType] = useState(null);
    const [showAddProducts,SetShowAddProducts] = useState(false);
    const [editor,SetEditor] = useState(null);

    const {formData,SetFormData,FillFormSelectedItemsWithObj} = UseFormHandle({
        selectedProducts:[]
    })

    var CallBack = useRef();

    const AddProductToEditor = () => {

        for(let val of formData.selectedProducts)
        {
            editor.insertContent('<p>[---------s:' + val.productID + '@~@' + val.name + '---------]</p><p></p>');
        }
        SetFormData("selectedProducts",[]);
        SetShowAddProducts(false);
    }

    var myButtons = "";
    if(useProductsAndForms)
    {
        if(useAddProducts) myButtons += " allProducts";
        if(allForms && allForms.length > 0) myButtons += " allForms";
    }

    var init = {
        menubar: false,
        branding: false,
        sandbox_iframes: false,
        paste_as_text: true,
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'wordcount'
        ],
        toolbar: toolbar + myButtons,
        license_key: 'gpl',
        promotion: false,
        link_assume_external_targets: true,
        content_style: "p,li,div,td,a{ color: #555} body { font-family: Barlow;font-weight:400 }"
    }

    if(height)
        init.height = height;

    if(useMediaEditor)
    {
        init.file_picker_types = 'image';
        init.file_picker_callback = (callback, value, meta) => {

            if(meta.filetype == "image")
            {
                SetShowManager(true);
                SetTinyMCEFileType(meta.filetype);

                CallBack.current = callback;
            }
        }
    }

    if(useProductsAndForms)
    {
        init.setup = (editor) => {

            if(useAddProducts)
                editor.ui.registry.addButton('allProducts', {
                    text: t('Products'),
                    onAction: function () {
                        SetEditor(editor);
                        SetShowAddProducts(true);
                    }
                });

            if(allForms && allForms.length > 0)
            {
                editor.ui.registry.addMenuButton('allForms', {
                    text: t('Forms'),
                    fetch: (callback) => {

                        var items = [];
                        for(let i = 0; i < allForms.length; i++){

                            items.push({
                                type: 'menuitem',
                                text: allForms[i].name,
                                onAction: function () {
                                    editor.insertContent('<p>[---------f:' + allForms[i].formID + '@~@' + allForms[i].name + '---------]</p><p></p>');
                                }
                            })
                        }
                        callback(items);
                    }
                });
            }
        }
    }
    
    return(
        <>
            <Editor
                key={useProductsAndForms && (allForms.length > 0 || allProducts > 0) ? 1 : 0}
                onInit={(evt, editor) => editorRef.current = editor}
                inline = {false}
                language = {"cs"}
                value = {value}
                onEditorChange={(content) => OnEditorChange(content)}
                init={init}
            />
            {showManager && 
                <TinyMCEFileManager 
                    OnClose={() => SetShowManager(false)} 
                    TinyMCECallBack = {(file) => CallBack.current(file)} 
                    tinyMCEFileType={tinyMCEFileType} 
                />
            }
            {showAddProducts ? 
                <ChooseProducts 
                    formData = {formData}
                    OnChooseClick = {() => AddProductToEditor()}
                    SetShowAddProducts = {SetShowAddProducts}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
        </>
    )
}

export default TinyMCEEditor;