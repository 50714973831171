import gql from 'graphql-tag';

export const ADD_EDIT_RESERVATION_PLACE = gql`
    mutation AddEditReservationPlace(
        $reservationPlaceID:ID,
        $active: Int,
        $email: String,
        $internalName: String,
        $googleCalendarID: String,
        $googleCredentialsFile:Upload,
        $langs:[ReservationPlaceLangsInput],
        $projectData:[ReservationPlaceProjectInput]
        $selectedTimes:[ReservationPlaceTimeInput]
        $selectedCustomers:[ReservationPlaceCustomerInput]
        $generateTranslations:GenerateReservationPlaceTranslationsInput
    ){
        AddEditReservationPlace(
            reservationPlaceID:$reservationPlaceID,
            active: $active, 
            email: $email,  
            internalName:$internalName,
            googleCalendarID:$googleCalendarID,
            googleCredentialsFile:$googleCredentialsFile, 
            langs:$langs,
            projectData:$projectData,
            selectedTimes:$selectedTimes,
            selectedCustomers:$selectedCustomers,
            generateTranslations:$generateTranslations
        ){
            reservationPlaceID
        }
    }
`;

export const GET_RESERVATION_PLACE = gql`
    query ReservationPlaceWithLangs($reservationPlaceID: ID,$lang:String){
        ReservationPlaceWithLangs(reservationPlaceID:$reservationPlaceID){
            reservationPlaceID
            active
            email
            internalName
            googleCalendarID
            googleCredentialsFile
            dateEdit
            projectData{
                projectID
                photo
            }
            times{
                reservationPlaceTimeID
                reservationPlaceID
                timeFrom
                timeTo
                variations{
                    reservationPlaceVariationID
                    reservationPlaceVariation(lang:$lang){
                        name
                    }
                }
            }
            customers{
                reservationPlaceCustomerID	
                customerID
                customer{
                    name
                    surname
                    email
                    photo
                    backgroundColor
                }
            }
            langs{
                lang
                reservationPlaceLangID
                projectData{
                    projectID
                    name
                }
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        AllProjects
        {
            projectID
            name
        }
    }
`;

export const GET_RESERVATION_PLACES = gql`
    query AllReservationPlaces($lang: String,$onlyActive:Boolean){
        AllReservationPlaces(lang:$lang,onlyActive:$onlyActive){
            reservationPlaceID
            active
            internalName
            backgroundColor
            times{
                reservationPlaceTimeID
                timeFrom
                timeTo
            }
        }
    }
`;

export const DELETE_RESERVATION_PLACES = gql`
    mutation DeleteReservationPlaces($reservationPlaceIDs:ID) {
        DeleteReservationPlaces(reservationPlaceIDs:$reservationPlaceIDs)
    }
`;

export const GENERATE_RESERVATION_PLACE_LANG = gql`
    mutation GenerateReservationPlaceLang(
        $reservationPlaceID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateReservationPlaceLang(
            reservationPlaceID:$reservationPlaceID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;