import { useState,useMemo } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditAdminRole from '../Library/UseAddEditAdminRole';
import UseGetAdminRole from '../Library/UseGetAdminRole';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Input from '../../../GlobalComponents/Input';
import InputCard from '../../../GlobalComponents/InputCard';
import Modules from '../../Project/Components/Modules';
import projectIcon from '../../../Media/Images/Icons/projects.webp';
import globalIcon from '../../../Media/Images/Icons/global.webp';
import Checkbox from '../../../GlobalComponents/Checkbox';

Modal.setAppElement('#root');

const AddEditAdminRole = ({selectedAdminRoleID,SetSelectedAdminRoleID,OnAddEditSuccess}) => {

    const {t} = useTranslation();
    
    const {
        loading:getLoading,
        error:getError,
        allModules,
        allProjects
    } = UseGetAdminRole(selectedAdminRoleID,(d) => {
        InitFormData(d)    
    });

    const {
        loading,
        error,
        formData,
        AddEditAdminRole,
        InitFormData,
        SetFormData,
        SetModule,
        SetProject,
    } = UseAddEditAdminRole(selectedAdminRoleID,(d) => OnAddEditSuccess(d));

    const filteredModules = useMemo(() => {
        const globalModules = allModules.filter((item) => item.isForProject == 0);
        const projectModules = allModules.filter((item) => item.isForProject == 1);
        return {
            globalModules,
            projectModules
        }
    },[allModules])

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedAdminRoleID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedAdminRoleID === 0 ? t("AddAdminRole") : t("EditAdminRole"))}
                OnClose ={() => SetSelectedAdminRoleID(null)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                <div className="form-group">
                                    <label>*{t("Name")}</label>
                                    <Input
                                        name="name"
                                        value={formData.name}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                                <InputCard
                                    icon = {projectIcon}
                                    title = {t("AccessToProjects")}
                                    className = "form-group"
                                >
                                    <div className="form-group">
                                        {allProjects && allProjects.length > 0 && allProjects.map((item) => {

                                            var checked = false;
                                            if(formData.projects.indexOf(item.projectID) != -1)
                                                checked = true;

                                            return(
                                                <div 
                                                    key={item.projectID}
                                                    className="form-group"
                                                >
                                                    <Checkbox
                                                        name={"p-"+ item.projectID}
                                                        id={"p-"+ item.projectID}
                                                        text = {item.name}
                                                        checked={checked}
                                                        OnChange={(e) => SetProject(item.projectID,e.target.checked)}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>

                                </InputCard>
                                <div className = "row">
                                    <div className="col-50">
                                        <InputCard
                                            icon = {globalIcon}
                                            title = {t("EnableGlobalModules")}
                                        >
                                            <div className="form-group">
                                                <Modules 
                                                    allModules = {filteredModules.globalModules}
                                                    dontShowWithoutLink = {true}
                                                    modules={formData.modules}
                                                    SetModule= {SetModule}
                                                    showInfoContent = {false}
                                                    mainCheckboxTitle = {t("Enable")}
                                                    moduleParamID = "moduleID"
                                                    subModuleParamID = "subModule"
                                                />
                                            </div>
                                        </InputCard>
                                    </div>
                                    <div className="col-50">
                                        <InputCard
                                            icon = {projectIcon}
                                            title = {t("EnableProjectModules")}
                                        >
                                            <div className="form-group">
                                                <Modules 
                                                    allModules = {filteredModules.projectModules}
                                                    dontShowWithoutLink = {true}
                                                    modules={formData.modules}
                                                    SetModule= {SetModule}
                                                    showInfoContent = {false}
                                                    mainCheckboxTitle = {t("Enable")}
                                                    moduleParamID = "moduleID"
                                                    subModuleParamID = "subModule"
                                                />
                                            </div>
                                        </InputCard>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditAdminRole()}
                >
                    {(selectedAdminRoleID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditAdminRole;