const Membership = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <path style={{"fill":secondaryColor}}  d="M438.987,245h-19.498v-19.498c0-6.075-4.925-11-11-11c-6.075,0-11,4.925-11,11V245h-19.497
                        c-6.075,0-11,4.925-11,11s4.925,11,11,11h19.497v19.498c0,6.075,4.925,11,11,11c6.075,0,11-4.925,11-11V267h19.498
                        c6.075,0,11-4.925,11-11S445.062,245,438.987,245z"/>
                    <g>
                        <path style={{"fill":primaryColor}} d="M438.987,419.49H256c-6.075,0-11-4.925-11-11v-60.996c0-6.075,4.925-11,11-11h182.987c6.075,0,11,4.925,11,11v60.996
                            C449.987,414.565,445.062,419.49,438.987,419.49z M267,397.49h160.987v-38.996H267V397.49z"/>
                    </g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M327.162,236.502H256c-6.075,0-11-4.925-11-11c0-6.075,4.925-11,11-11h71.162c6.075,0,11,4.925,11,11
                            C338.162,231.577,333.237,236.502,327.162,236.502z"/>
                    </g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M327.162,297.498H256c-6.075,0-11-4.925-11-11c0-6.075,4.925-11,11-11h71.162c6.075,0,11,4.925,11,11
                            C338.162,292.573,333.237,297.498,327.162,297.498z"/>
                    </g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M195.004,419.49H73.013c-6.075,0-11-4.925-11-11c0-6.075,4.925-11,11-11h121.991c6.075,0,11,4.925,11,11
                            C206.004,414.565,201.079,419.49,195.004,419.49z"/>
                    </g>
                    <g>
                        <g>
                            <path style={{"fill":primaryColor}} d="M460.336,480.485H51.664C23.177,480.485,0,457.309,0,428.822V154.34c0-28.488,23.177-51.664,51.664-51.664h112.842v22
                                H51.664C35.308,124.676,22,137.983,22,154.34v274.482c0,16.356,13.308,29.664,29.664,29.664h408.672
                                c16.357,0,29.664-13.307,29.664-29.664V154.34c0-16.357-13.308-29.664-29.664-29.664H347.494v-22h112.842
                                c28.487,0,51.664,23.176,51.664,51.664v274.482C512,457.309,488.823,480.485,460.336,480.485z"/>
                        </g>
                        <g>
                            <path style={{"fill":secondaryColor}}  d="M347.494,155.174H164.506c-6.075,0-11-4.925-11-11V83.178c0-6.075,4.925-11,11-11h17.933
                                c9.828,0,18.663-5.461,23.059-14.251c8.146-16.292,24.522-26.413,42.737-26.413h15.531c18.214,0,34.591,10.123,42.737,26.413l0,0
                                c4.396,8.79,13.23,14.251,23.059,14.251h17.933c6.075,0,11,4.925,11,11v60.996C358.494,150.249,353.569,155.174,347.494,155.174z
                                M175.506,133.174h160.988V94.178h-6.933c-18.215,0-34.59-10.121-42.736-26.412l0,0c-4.396-8.791-13.231-14.251-23.06-14.251
                                h-15.531c-9.828,0-18.664,5.46-23.06,14.251c-8.146,16.292-24.521,26.413-42.736,26.413h-6.933V133.174z"/>
                        </g>
                    </g>
                    <path style={{"fill":secondaryColor}}  d="M167.148,263.279c8.304-8.416,13.441-19.965,13.441-32.694c0-25.685-20.896-46.581-46.581-46.581
                        s-46.58,20.896-46.58,46.581c0,12.729,5.137,24.278,13.441,32.694c-23.062,12.013-38.856,36.131-38.856,63.883v20.332
                        c0,6.075,4.925,11,11,11h121.991c6.075,0,11-4.925,11-11v-20.332C206.004,299.409,190.21,275.291,167.148,263.279z
                        M109.428,230.585c0-13.554,11.026-24.581,24.58-24.581s24.581,11.027,24.581,24.581c0,13.554-11.027,24.581-24.581,24.581
                        S109.428,244.139,109.428,230.585z M184.004,336.494H84.013v-9.332c0-27.568,22.428-49.996,49.996-49.996
                        c27.567,0,49.995,22.428,49.995,49.996V336.494z"/>
                </g>
                <g>
                    <path style={{"fill":primaryColor}} d="M256,104.34c-15.811-0.272-13.402-24.605,2.15-21.78C270.885,84.77,268.924,104.668,256,104.34z"/>
                </g>
            </svg>
        </>
    )
}

export default Membership;
