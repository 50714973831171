import { useTranslation } from "react-i18next";

const SelectBoxColors = () => {

    const {t} = useTranslation();

    return(
        <>
            <option value={"btn-primary"}>{t("Basic")}</option>
            <option value={"btn-green"}>{t("Green")}</option>
            <option value={"btn-white"}>{t("White")}</option>
            <option value={"btn-black"}>{t("Black")}</option>
        </>
    )
}

export default SelectBoxColors;