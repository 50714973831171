import gql from 'graphql-tag';

export const GET_HEADER_DATA = gql`
    query HeaderData(
        $isForProject:ID,
        $parentModuleID:ID,
        $adminRoleID:ID
    ){ 
        NotApprovedProductReviewsCount
        AllProjects(onlyActive:true){
            projectID
            name
            niceName
            logo
            favicon
            adminRoleProject(adminRoleID:$adminRoleID){
                adminRoleID
            }
        }
        AllModulesBy(
            param:"isForProject",
            value:$isForProject,
            parentModuleID:$parentModuleID
        ){
            moduleID
            activeByDefault
            label
            link    
            icon
            adminRoleModule(adminRoleID:$adminRoleID){
                adminRoleID
            }
            subModule{
                moduleID
                activeByDefault
                label
                link 
                adminRoleModule(adminRoleID:$adminRoleID){
                    adminRoleID
                }
            }
        }
    }
`;
