import {useQuery} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { GET_CAMPAIGN_EMAILS } from '../Queries/campaignEmail';

const UseGetAllCampaignEmails = (lang,campaignID) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedEmailIDs,SetSelectedEmailIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_CAMPAIGN_EMAILS,{
        variables:{
            lang,
            campaignID
        },
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCampaignEmails);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[data])

    var headerData = [
        {
            value: t("EmailSubject"),
            className:"flex-1"
        }     
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.subject,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.campaignEmailID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,campaignEmailID) => {

        var checked = e.target.checked;
        var newSelectedEmailIDs = [...selectedEmailIDs];
        const newList = SelectEmailID(content,campaignEmailID,checked,newSelectedEmailIDs)

        SetContent(newList);
        SetSelectedEmailIDs(newSelectedEmailIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedEmailIDs = [];
        const newList = SelectEmailID(content,"all",checked,newSelectedEmailIDs)

        SetSelectedEmailIDs(newSelectedEmailIDs);
        SetContent(newList);
    }

    const SelectEmailID = (list,campaignEmailID,checked,selectedEmailIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(campaignEmailID == newList[i].rowID || campaignEmailID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedEmailIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedEmailIDs.length; j++)
                    {
                        if(selectedEmailIDs[j] == newList[i].rowID)
                            selectedEmailIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectEmailID(newList[i].subData,campaignEmailID,checked,selectedEmailIDs)}
        }

        return newList;
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedEmailIDs,
        SelectRow,
        SelectAll
    }

}

export default UseGetAllCampaignEmails;