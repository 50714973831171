import { useQuery } from "@apollo/client";
import { GET_SETTINGS } from "../../Queries/settings";

const UseGetWebSettings = (CallBack) => {

    const {loading,error} = useQuery(GET_SETTINGS,{
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            CallBack({
                openAiApiKey:                d.Settings.openAiApiKey,
                useThirdPartyForTransaltion: d.Settings.useThirdPartyForTransaltion
            });
        }
    });

    return{
        loading,
        error,
    }

}

export default UseGetWebSettings;