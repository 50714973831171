import { useApolloClient } from "@apollo/client";
import { useState } from "react";
import { GetMainLanguage,IsUsAndEnLangEnabledTogether } from "../GlobalFunctions/helper";

const UseGenerateOtherTranslations = (query,allLanguageMutations,responseParam,OnTranslatedText) => {

    const client = useApolloClient();

    const [translatedLangsCount, SetTranslatedLangsCount] = useState(0);
    const [translatedLang, SetTranslatedLang] = useState("");
    const [loading, SetLoading] = useState(false);
    const [error, SetError] = useState("");
    const [translatedData, SetTranslatedData] = useState([]);

    const GenerateOtherTranstalions = (variables) => {

        return new Promise(async (resolve,rejected) => {

            var success = true;

            SetTranslatedLangsCount(1);
            SetLoading(true);

            const mainLang = GetMainLanguage(allLanguageMutations);
            const isEnabled = IsUsAndEnLangEnabledTogether(allLanguageMutations);

            for(let val of allLanguageMutations)
            {
                SetTranslatedLang(val.title);

                try
                {
                    if(
                        (
                            (mainLang.suffix == "en" && val.suffix == "us") || 
                            (mainLang.suffix == "us" && val.suffix == "en")
                        ) && isEnabled
                    )
                    {
                        if(OnTranslatedText)
                            SetTranslatedData((prevValue) => {

                                variables.lang = (mainLang.suffix == "en" ? "us" : "en");
                                const newValue = [...prevValue];
                                newValue.push({...variables});

                                return newValue;
                            })
                        else
                        {
                            variables.lang = (mainLang.suffix == "en" ? "us" : "en");
                            await client.mutate({
                                mutation:query,
                                variables:variables
                            });
                        }
                        
                        SetTranslatedLangsCount((oldCount) => {
                            return oldCount + 1;
                        })
                    }

                    if(val.suffix != mainLang.suffix && (!isEnabled || (isEnabled && val.suffix != (mainLang.suffix == "us" ? "en" : "us"))))
                    {
                        variables.lang = val.suffix;
                        const response = await client.mutate({
                            mutation:query,
                            variables:variables
                        });

                        if(OnTranslatedText)
                            SetTranslatedData((prevValue) => {
                                const newValue = [...prevValue];
                                const res = response.data[responseParam];
    
                                newValue.push(response.data[responseParam]);
    
                                if(isEnabled && (res.lang == "en" || res.lang == "us"))
                                {
                                    var newLang = {...response.data[responseParam]};
                                    newLang.lang = (res.lang == "en" ? "us" : "en");
                                    newValue.push(newLang);
                                }
    
                                return newValue;
                            })

                        SetTranslatedLangsCount((oldCount) => {
                            return oldCount + (val.suffix == "en" && isEnabled ? 2 : 1);
                        })
                    }
                }
                catch(err)
                {
                    SetError(err);
                    success = false;
                    break;
                }
            }

            if(success)
                setTimeout(() => {
                    SetTranslatedLang("");
                    SetLoading(false);
                    SetTranslatedLangsCount(0); 

                    resolve(success);

                    if(OnTranslatedText)
                        SetTranslatedData((prevValue) => {
                            
                            OnTranslatedText(prevValue)
                            return [];
                        })

                },1000)
        });  
    }

    return{
        loading,
        error,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    }
}

export default UseGenerateOtherTranslations;