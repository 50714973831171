import letterH from '../../../Media/Images/Icons/letter-h.webp';
import columns from '../../../Media/Images/Icons/columns.webp';
import tabs from '../../../Media/Images/Icons/tabs.webp';
import text from '../../../Media/Images/Icons/paragraph.webp';
import button from '../../../Media/Images/Icons/button.webp';
import image from '../../../Media/Images/Icons/image.webp';
import imageGallery from '../../../Media/Images/Icons/image_gallery.webp';
import video from '../../../Media/Images/Icons/video.webp';
import videoGallery from '../../../Media/Images/Icons/video_gallery.webp';
import reservationIcon from '../../../Media/Images/Icons/reservation.webp';
import articles from '../../../Media/Images/Icons/articles.webp';
import newsletter from '../../../Media/Images/Icons/newsletter.webp';
import form from '../../../Media/Images/Icons/form.webp';
import faq from '../../../Media/Images/Icons/faq.webp';
import review from '../../../Media/Images/Icons/review.webp';
import attention from '../../../Media/Images/Icons/attention.webp';
import recommendedProducts from '../../../Media/Images/Icons/recommended_products.webp';
import category from '../../../Media/Images/Icons/category.webp';
import html from '../../../Media/Images/Icons/html.webp';
import { UseContentManager } from '../Library/UseContentManager';
import Sidebar from './Sidebar';
import content from '../../../Media/Images/Icons/content.webp';
import { useTranslation } from "react-i18next";
import { 
    COLUMNS,
    TEXT,
    HEADLINE,
    IMAGE,
    IMAGE_GALLERY,
    RECOMMENDED_PRODUCTS,
    TOP_CATEGORIES,
    BUTTON,
    VIDEO,
    VIDEO_GALLERY,
    NEWEST_ARTICLES,
    ALERT,
    HTML,
    FORM,
    REVIEWS,
    NEWSLETTER,
    FAQ,
    RESERVATION_PLACE,
    TABS
} from '../Library/elements';

const AddContent = () => {

    const {t} = useTranslation();
    const {
        indexToAddContent,
        AddElement,
        SetIndexToAddContent,
        contentManagerProjectID
    } = UseContentManager();

    var isOpen  = false
    if(indexToAddContent || indexToAddContent == 0)
        isOpen = true;

    return(
        <>
            <Sidebar 
                isOpen = {isOpen}
                headerIcon = {content}
                headerTitle = {t("AddContent")}
                contentClassName={"no-padding"}
                OnCloseSidebar={() => SetIndexToAddContent(null)}
            >
                <div className="row no-margin">
                    <div onClick={() => AddElement(""+indexToAddContent+"",COLUMNS)} className="cm-add-element">
                        <img src={columns} />
                        <h3>{t("Columns")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",TABS)} className="cm-add-element">
                        <img src={tabs} />
                        <h3>{t("Tabs")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",HEADLINE)} className="cm-add-element">
                        <img src={letterH} />
                        <h3>{t("Headline")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",TEXT)} className="cm-add-element">
                        <img src={text} />
                        <h3>{t("Text")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",IMAGE)} className="cm-add-element">
                        <img src={image} />
                        <h3>{t("Image")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",IMAGE_GALLERY)} className="cm-add-element">
                        <img src={imageGallery} />
                        <h3>{t("Photogallery")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",BUTTON)} className="cm-add-element">
                        <img src={button} />
                        <h3>{t("Button")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",VIDEO)} className="cm-add-element">
                        <img src={video} />
                        <h3>{t("Video")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",VIDEO_GALLERY)} className="cm-add-element">
                        <img src={videoGallery} />
                        <h3>{t("VideoGallery")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",RESERVATION_PLACE)} className="cm-add-element">
                        <img src={reservationIcon} />
                        <h3>{t("ReservationPlace")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",RECOMMENDED_PRODUCTS)} className="cm-add-element">
                        <img src={recommendedProducts} />
                        <h3>{t("RecommendedProducts")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",FORM)} className="cm-add-element">
                        <img src={form} />
                        <h3>{t("Form")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",NEWSLETTER)} className="cm-add-element">
                        <img src={newsletter} />
                        <h3>{t("Newsletter")}</h3>
                    </div>
                    <div  onClick={() => AddElement(""+indexToAddContent+"",FAQ)} className="cm-add-element">
                        <img src={faq} />
                        <h3>{t("FAQ")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",REVIEWS)} className="cm-add-element">
                        <img src={review} />
                        <h3>{t("Reviews")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",NEWEST_ARTICLES)} className="cm-add-element">
                        <img src={articles} />
                        <h3>{t("LatestArticles")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",TOP_CATEGORIES(contentManagerProjectID))} className="cm-add-element">
                        <img src={category} />
                        <h3>{t("TopCategories")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",ALERT)} className="cm-add-element">
                        <img src={attention} />
                        <h3>{t("Warning")}</h3>
                    </div>
                    <div onClick={() => AddElement(""+indexToAddContent+"",HTML)} className="cm-add-element">
                        <img src={html} />
                        <h3>{t("HTML")}</h3>
                    </div>
                   
                </div>
            </Sidebar>
        </>
    )
}

export default AddContent;