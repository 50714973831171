import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_RESERVATION_PLACE_VARIATION,GENERATE_RESERVATION_PLACE_VARIATION_LANG } from "../Queries/reservationPlaceVariation";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";

const UseAddEditReservationPlaceVariation = (selectedReservationPlaceVariationID,mainLang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();
        
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        GetFormLangValue,
        SetFormLangData
    } = UseFormHandle({
        selectedLang:mainLang,
        langs:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_RESERVATION_PLACE_VARIATION_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditReservationPlaceVariationMutation,{error,loading}] = useMutation(ADD_EDIT_RESERVATION_PLACE_VARIATION,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    reservationPlaceVariationID: d.AddEditReservationPlaceVariation.reservationPlaceVariationID,
                    isEdit: (selectedReservationPlaceVariationID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditReservationPlaceVariation);
                
                const notifyText = (selectedReservationPlaceVariationID != 0 ? t("SuccessfullyUpdated") : t("NewReservationPlaceVariationIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("ReservationPlaceVariationHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditReservationPlaceVariation = () => {

        var checkName = true;
        let langs = [];
        for(const lang of formData.langs)
        {
            if(lang.lang == formData.selectedLang && !lang.name)
            {
                checkName = false;
                break;
            }

            langs.push({
                lang: lang.lang,
                name: lang.name 
            });
        }

        if(checkName)
        {
            AddEditReservationPlaceVariationMutation({
                variables:{
                    reservationPlaceVariationID:    selectedReservationPlaceVariationID,
                    langs:                          langs,
                    generateTranslations:           formData.generateTranslations
                }
            })
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditReservationPlaceVariation,
        SetFormData,
        InitFormData,
        GetFormLangValue,
        SetFormLangData
    }
}

export default UseAddEditReservationPlaceVariation;