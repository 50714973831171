import { useQuery } from "@apollo/client";
import { GET_ADMIN_USER } from "../Queries/adminUser";
import { useState } from 'react';

const UseGetAdminUser = (selectedAdminUserID,CallBack) => {

    const [allAdminRoles, SetAllAdminRoles] = useState([]);
    const [allLanguages, SetAllLanguages] = useState([]);
    
    const {loading,error} = useQuery(GET_ADMIN_USER,{
        variables:{
            adminUserID:selectedAdminUserID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {
            if(selectedAdminUserID != 0)
            { 
                CallBack({
                    adminRoleID:    d.AdminUser.adminRoleID,
                    name:           d.AdminUser.name,
                    surname:        d.AdminUser.surname,
                    email:          d.AdminUser.email,
                    password:       d.AdminUser.password,
                    lang:           d.AdminUser.lang
                });
            }

            SetAllAdminRoles(d.AllAdminRoles);
            SetAllLanguages(d.AllLanguageMutations);
        }
    });

    return{
        loading: loading,
        error: error,
        allAdminRoles,
        allLanguages
    }

}

export default UseGetAdminUser;