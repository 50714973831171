import { useTranslation } from 'react-i18next';
import UseFormHandle from '../../../GlobalHooks/UseFormHandle';
import { useEffect,useMemo } from 'react';
import Input from '../../../GlobalComponents/Input';
import Label from '../../../GlobalComponents/Label';
import RadioButton from '../../../GlobalComponents/RadioButton';
import Select from '../../../GlobalComponents/Select';

const MembershipTypePrice = ({
    data,
    disablePrice,
    membershipTypePriceIndex,
    allMembershipTypes,
    selectedLang,
    SetParentIndexData,
    SetBooleanFormDataSelectedItemsWithIndex
}) => {

    const {t} = useTranslation();
    const {
        formData,
        SetFormLangData,
        GetFormLangValue,
        SetFormData
    } = UseFormHandle({
        selectedLang,
        membershipTypePriceID:data.membershipTypePriceID,
        langs:data.langs,
        isMain:data.isMain,
        vatID:data.vatID,
        connectedMembershipTypeID:data.connectedMembershipTypeID,
        connectedMembershipTypePriceID:data.connectedMembershipTypePriceID

    });

    useEffect(() => {
        SetParentIndexData(formData)
    },[formData])

    useEffect(() => {
        SetFormData("selectedLang",selectedLang);
    },[selectedLang])

    useEffect(() => {
        SetFormData("isMain",data.isMain);
    },[data.isMain])

    const otherPrices = useMemo(() => {

        const memTypes =  allMembershipTypes.filter((item) => item.membershipTypeID == formData.connectedMembershipTypeID)

        if(memTypes.length > 0)
            return memTypes[0].prices;
        else
        {
            return [];
        }

    },[formData.connectedMembershipTypeID])

    return(
        <div className="row form-group"> 
            
            <div className="col-25 d-flex align-items-center">
                <RadioButton 
                    text = {"*" + t("MainPrice")}
                    id = {"isMain-" + membershipTypePriceIndex}
                    name = {"isMain-" + membershipTypePriceIndex}
                    value = {1}
                    checked = {formData.isMain}
                    OnChange = {() => SetBooleanFormDataSelectedItemsWithIndex("isMain","prices",membershipTypePriceIndex)}
                />
            </div>
            <div className="col-25">
                <Label 
                    text = {"*" + t("Price") + " (" + selectedLang + ")"}
                    infoContent={[
                        t("MembershipTypeCurrentPriceInfo")
                    ]}
                />
                <Input 
                    name="price"
                    type="number"
                    disabled = {disablePrice}
                    value = {GetFormLangValue("price")}
                    OnChange = {(e) => SetFormLangData(e)}
                />
            </div>
            <div className="col-50">
                <Label 
                    text={t("JoinWithTypeAndPrice")}
                    infoContent={[
                        t("JoinWithTypeAndPriceInfo1"),
                        t("JoinWithTypeAndPriceInfo2"),
                        t("JoinWithTypeAndPriceInfo3"),
                        t("JoinWithTypeAndPriceInfo4")
                    ]}
                />
                <div className="row">
                    <div className="col-50">
                        <Select 
                            name="connectedMembershipTypeID"
                            value = {formData.connectedMembershipTypeID}
                            OnChange = {(e) => {
                                SetFormData(e);
                                if(e.target.value == 0)
                                    SetFormData("connectedMembershipTypePriceID",0);
                            }}
                        >
                            <option value={0}>{"- - -" + t("Choose") + "- - -"}</option>

                            {allMembershipTypes && allMembershipTypes.map((item,index) => {
                                
                                return (
                                    <option
                                        key={index}
                                        value={item.membershipTypeID}
                                    >{item.name}</option>
                                )
                            })}
                        </Select>
                    </div>
                    {formData.connectedMembershipTypeID != 0 ?
                        <div className="col-50">
                            <Select 
                                name="connectedMembershipTypePriceID"
                                value = {formData.connectedMembershipTypePriceID}
                                OnChange = {(e) => SetFormData(e)}
                            >
                                <option value={0}>{"- - -" + t("Choose") + "- - -"}</option>

                                {otherPrices && otherPrices.map((item,index) => {
                                    
                                    return (
                                        <option
                                            key={index}
                                            value={item.membershipTypePriceID}
                                        >{item.price}</option>
                                    )
                                })}
                            </Select>
                        </div>
                    :null}
                </div>
            </div>
            <div className="col-100">
                <label>{t("Description")} ({selectedLang})</label>
                <Input 
                    name="description"
                    type="text"
                    value = {GetFormLangValue("description")}
                    OnChange = {(e) => SetFormLangData(e)}
                />
            </div>

        </div>    
    )
}

export default MembershipTypePrice;