import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Loading from '../../../GlobalComponents/Loading';
import List from '../../../GlobalComponents/List/List';
import UseChooseReservationPlaceVariations from '../Library/UseChooseReservationPlaceVariations';
import { UseAuth } from '../../Auth/Library/UseAuth';

const ChooseReservationPlaceVariations = ({
    formData,
    SetShowAddVariations,
    FillFormSelectedItemsWithObj
}) => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    const {
        content,
        headerData,
        loading,
        error,
        SelectRow,
    } = UseChooseReservationPlaceVariations(user.lang,formData.selectedVariations,FillFormSelectedItemsWithObj);

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddVariations(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("ChooseReservationPlaceVariations")}
                OnClose ={() => SetShowAddVariations(false)}
            />

            <div className="modal-body no-padding">

                {error ?
                    <Error text={GetApolloErrorText(error)} />
                :
                    (loading ?
                        <Loading />
                    :
                        <List 
                            headerData = {headerData} 
                            contentData = {content}
                            isSortable = {false}
                            loading = {loading}
                            hideOptions = {true}
                            options = {{
                                OnSelect: (e,rowData) => SelectRow(e,rowData),
                            }}
                        />
                    )
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddVariations(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )

}

export default ChooseReservationPlaceVariations;