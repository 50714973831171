import { UseContentManager } from "../../../Library/UseContentManager";
import ControlsMenu from "../../ControlsMenu";
import { GetColumnStyles } from "../../../Library/functions";
import AllElements from "../../AllElements";

const Column = ({data,lastIndexes,index,columnsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement} = UseContentManager();

    var className = "cm-column-elements";
    if(data.className) className += " " + data.className;

    if(data.contentAlign != "start")
        className += " cm-column-elements cm-d-flex cm-flex-column cm-justify-content-" + data.contentAlign;
    
    if(data.hasBorder == "1")
        className += " cm-border";

    if(data.backgroundColorClass)
        className += " cm-bg-color-" + data.backgroundColorClass;

    var stylesData = GetColumnStyles(data);
    className += " " + stylesData.styleClassName;

    var showMenu = false;
    if(indexToShowMenu === lastIndexes + "-" + index)
        showMenu = true;

    return(
        <>
            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "column"
                    index = {lastIndexes + "-" + index} 
                    showAddContent = {true} 
                    position = {index + 1}
                    elementsCount = {columnsCount}
                />
            :null}
            <div className={className} style={stylesData.styles}>

                {data.elements && data.elements.map((elmItem,elmIndex) => {
                    return(
                        <AllElements
                            key = {elmIndex}
                            elmItem = {elmItem}
                            elmIndex = {elmIndex}
                            elementsCount = {data.elements.length}
                            index = {lastIndexes + "-" + index}
                        />   
                    )
                })}
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border col-border" + (showMenu ? " cm-selected" : "")} 
                    onClick={(e) => SelectElement(e,lastIndexes + "-" + index)} 
                ></div>
            :null}
        </>       
    )
}

export default Column;