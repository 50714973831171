import { useQuery} from '@apollo/client';
import { useState,useEffect } from 'react';
import { GET_ALL_CUSTOMERS } from '../../Customer/Queries/customer';
import { SERVER_URL,CUSTOMER_IMAGE_URL } from '../../../Config';

const UseSearchCustomer = () => {

    const [content,SetContent] = useState(null);
    const [variables,SetVariables] = useState({
        searchText:"",
        limit:15,
        offset:0
    });

    const {data,loading,error} = useQuery(GET_ALL_CUSTOMERS,{
        variables,
        fetchPolicy:"network-only",
        skip:(variables.searchText == "" ? true : false)
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCustomers);
            SetContent(contentData);
        }

    },[data])

    const SetSearchText = (text) => {
        SetVariables({...variables,searchText:text});
        if(text == "")
            SetContent(null)
    }

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var text = val.email;
            if(val.name || val.surname)
                text = val.name + " " + val.surname;

            var cData = {
                data:[
                    {
                        elm: "lis",
                        backgroundColor: val.backgroundColor,
                        text: text,
                        photo: val.photo ? SERVER_URL + "/" + CUSTOMER_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right flex-1"
                    },
                    {
                        value:val.email,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID   = val.customerID;
            cData.hash    = val.hash;
            cData.photo   = val.photo;
            cData.name    = val.name;
            cData.surname = val.surname;
            cData.email   = val.email;
            cData.lang    = val.lang;

            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }
   
    return{
        content,
        loading,
        error,
        SetSearchText,
        SetContent
    }

}

export default UseSearchCustomer;