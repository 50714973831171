import { GetInitialShortcatFromText } from "../../GlobalFunctions/helper";

const ListInitialShorcut = ({
    text,
    backgroundColor,
    photo
}) => {
    return(
        <div className="d-flex align-items-center" >
            <div className="list-initial-shortcut" style={{backgroundColor}}>
                {photo ?
                    <img src={photo} />
                :
                    GetInitialShortcatFromText(text)
                }
            </div>
            {text}
        </div>
    )
}

export default ListInitialShorcut;