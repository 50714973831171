import infoIcon from '../Media/Images/Icons/info.webp';
import { useRef,useEffect,useState } from 'react';

const Checkbox = ({
    text,
    id,
    name,
    checked,
    style,
    isColor,
    colorCode,
    infoContent,
    OnChange
}) => {

    const containerRef = useRef();

    const [leftPosition, SetLeftPosition] = useState(null)

    useEffect(() => {
        if(infoContent)
        {
            containerRef.current.addEventListener("mouseover",OnMouseOver);
            containerRef.current.addEventListener("mouseleave",OnMouseLeave);
            return () => {
                if(containerRef.current)
                {
                    containerRef.current.removeEventListener("mouseover",OnMouseOver);
                    containerRef.current.removeEventListener("mouseleave",OnMouseLeave);
                }
            }
        }
    },[infoContent]);

    const OnMouseOver = (e) => {
        
        const windowWidth = window.innerWidth;
        const mouseX = e.clientX;
        const contentWidth = 250;

        const parent = e.target.parentNode;
        SetLeftPosition({left: -parent.offsetLeft})

        if(mouseX - parent.offsetLeft + contentWidth > windowWidth)
            SetLeftPosition({left: - contentWidth + containerRef.current.offsetWidth})
        else
            SetLeftPosition({left: -parent.offsetLeft})
    }

    const OnMouseLeave = (e) => {
        SetLeftPosition(null)
    }

    var labelClass = "";

    if(isColor)
        labelClass += " color";

    if(infoContent)
        labelClass += " no-padding-right";

    return(
        <div className={"checkbox" + (checked ? " checked" : "")} style = {style}>
            <input 
                id={id} 
                type="checkbox" 
                name={name} 
                checked = {checked}
                onChange = {(e) => OnChange(e)} 
            />
            {text ?
                <label 
                    htmlFor={id}
                    className={labelClass}
                >
                    {isColor ? 
                        <span 
                            style={{backgroundColor:colorCode}}
                            title = {text}
                        ></span> 
                    : 
                        text
                    }
                </label>
            :null}
            {infoContent ?
                <div 
                    ref={containerRef}
                    className="info-container"
                >
                    <img className="info-icon" src = {infoIcon} alt="" />
                    <div 
                        className="info-content"
                        style={leftPosition}
                    >
                        {infoContent.map((item,index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                </div>
            :null}
        </div>
    )
}

export default Checkbox;