import {useQuery} from '@apollo/client';
import { GET_ALL_MARKETING_BANNERS } from '../Queries/marketingBanner';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,MARKETING_BANNER_IMAGE_URL } from '../../../Config';

const UseChooseMarketingBanners = (selectedMarketingBanners,FillFormSelectedItemsWithObj) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedArticleIDs,SetSelectedArticleIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_ALL_MARKETING_BANNERS,{
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllMarketingBanners);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("Name"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];
        
        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.photo ? SERVER_URL + "/" + MARKETING_BANNER_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }

            if(selectedMarketingBanners.length > 0)
                cData.selected =  (selectedMarketingBanners.indexOf(val.marketingBannerID) != -1 ? true : false); 
            else
                cData.selected = false;

            cData.rowName  = val.title;
            cData.rowPhoto = val.photo;
            cData.rowID    = val.marketingBannerID;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,marketingBannerID) => {

        var checked = e.target.checked;
        var newSelectedArticleIDs = [...selectedArticleIDs];
        const newList = SelectArticleID(content,marketingBannerID,checked,newSelectedArticleIDs)

        SetContent(newList);
        SetSelectedArticleIDs(newSelectedArticleIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedArticleIDs = [];
        const newList = SelectArticleID(content,"all",checked,newSelectedArticleIDs)

        SetSelectedArticleIDs(newSelectedArticleIDs);
        SetContent(newList);
    }

    const SelectArticleID = (list,marketingBannerID,checked,rowsToSelect) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(marketingBannerID == newList[i].rowID || marketingBannerID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                {
                    for(let j = 0; j < rowsToSelect.length; j++)
                    {
                        if(!rowsToSelect[j].selected)
                            FillFormSelectedItemsWithObj("selectedMarketingBanners","marketingBannerID",{
                                name: rowsToSelect[j].rowName,
                                marketingBannerID: rowsToSelect[j].rowID,
                                photo: rowsToSelect[j].rowPhoto
                            },false);
                    }
                }

                FillFormSelectedItemsWithObj("selectedMarketingBanners","marketingBannerID",{
                    name: newList[i].rowName,
                    marketingBannerID: newList[i].rowID,
                    photo: newList[i].rowPhoto
                },false);
            }                
        }

        return newList;
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedArticleIDs,
        SelectRow,
        SelectAll,
    }

}

export default UseChooseMarketingBanners;