import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_ADMIN_USER } from "../Queries/adminUser";
import { ValidateEmail } from "../../../GlobalFunctions/helper";

const UseAddEditAdminUser = (selectedAdminUserID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData,OpenImage} = UseFormHandle({
        name:"",
        adminRoleID:0,
        name: "",
        surname:"",
        email:"",
        password:"",
        lang:""
    });

    const [AddEditAdminUserMutation,{loading}] = useMutation(ADD_EDIT_ADMIN_USER,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditAdminUser);

            const notifyText = (selectedAdminUserID != 0 ? t("SuccessfullyUpdated") : t("NewAdminUserIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            var error = GetApolloErrorText(err);
            console.log(error);
            if(error == "ADMIN_USER_EXISTS")
                error = t("AdminUserExists")

            AddNotification(error,false);  
        }
    });

    const AddEditAdminUser = () => {

        if(formData.name)
        {   
            if(ValidateEmail(formData.email))
            {   
                if(formData.adminRoleID != 0)
                {   
                    if(formData.lang)
                    {   
                        if((selectedAdminUserID == 0 && formData.password) || selectedAdminUserID != 0)
                        {
                            if((selectedAdminUserID == 0 && formData.password == formData.repeatPassword) || selectedAdminUserID != 0)
                            {
                                AddEditAdminUserMutation({
                                    variables:{
                                        adminUserID:    selectedAdminUserID,
                                        adminRoleID:    formData.adminRoleID,
                                        name:           formData.name,
                                        surname:        formData.surname,
                                        email:          formData.email,
                                        password:       formData.password,
                                        lang:           formData.lang
                                    }
                                })
                            }
                            else
                                AddNotification(t("PasswordsAreNotSame"),false);
                        }
                        else
                            AddNotification(t("YouDoNotFillPassword"),false);
                    }
                    else
                        AddNotification(t("PleaseChooseLang"),false);
                }
                else
                    AddNotification(t("PleaseChooseRole"),false);
            }
            else
                AddNotification(t("InvalidEmail"),false);
        }
        else
            AddNotification(t("NoFirstNameNoGo"),false);

    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        loading: loading,
        formData,
        AddEditAdminUser,
        SetFormData,
        InitFormData,
        OpenImage
    }

}

export default UseAddEditAdminUser;