import { useQuery } from "@apollo/client";
import { GET_SCHEDULED_NEWSLETTER } from "../Queries/newsletter";
import { useState } from 'react';
import { GetMainLanguage, CompleteLangsArray } from "../../../GlobalFunctions/helper";
import dayjs from "dayjs";

const UseGetScheduledNewsletter = (selectedScheduledNewsletterID,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allProjects, SetAllProjects] = useState([]);

    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_SCHEDULED_NEWSLETTER,{
        variables:{
            newsletterID:selectedScheduledNewsletterID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                subject:"", 
                preheader:"", 
                text:""
            }

            if(selectedScheduledNewsletterID != 0)
            { 
                let langs = CompleteLangsArray(d.ScheduledNewsletterWithLangs.langs,d.AllLanguageMutations,emptyLang);

                CallBack({
                    newsletterID:   d.ScheduledNewsletterWithLangs.newsletterID,
                    campaignID:     d.ScheduledNewsletterWithLangs.campaignID,
                    projectID:      d.ScheduledNewsletterWithLangs.projectID,
                    plannedDate:    d.ScheduledNewsletterWithLangs.plannedDate,
                    langs:          langs,
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);
                CallBack({
                    langs: langs, 
                    newsletterID: 0, 
                    plannedDate: dayjs().add(1,"d").format("YYYY-MM-DD"),
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllProjects(d.AllProjects);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allProjects,
        mainLang
    }

}

export default UseGetScheduledNewsletter;