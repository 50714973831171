
const Textarea = ({className,name,value,style,OnChange}) => {

    const Change = (e) => {
        if(OnChange)
            OnChange(e);
    }
    
    return(
        <div className="input-group" style={style}>
            
            <textarea 
                name = {name} 
                onChange = {(e) => Change(e)} 
                className={className}
                value = {value}
            />
            
        </div>
    )

}

export default Textarea;