import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditLabel from "./AddEditLabel";
import { UseAuth } from '../../../Auth/Library/UseAuth';
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import UseDeleteLabels from "../../Library/Labels/UseDeleteLabels";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import UseGetAllLabels from "../../Library/Labels/UseGetAllLabels";

const Labels = () => {

    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedLabelID, SetSelectedLabelID] = useState(null);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedLabelIDs,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    } = UseGetAllLabels(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteLabels
    } = UseDeleteLabels(selectedLabelIDs,(d) => UpdateListAfterDelete(d));

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("LabelList")}
                        OnAddButtonClick={() => SetSelectedLabelID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        OnDragListRowEnd = {(result) => OnDragEnd(result)}
                        options = {{
                            OnEditClick: (labelID) => SetSelectedLabelID(labelID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedLabelID || selectedLabelID == 0 ?
                <AddEditLabel
                    selectedLabelID = {selectedLabelID}
                    SetSelectedLabelID = {SetSelectedLabelID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedLabelID,d);
                        SetSelectedLabelID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteLabels")} 
                    CallBack={DeleteLabels} 
                />
            :null}

        </>
    )
}

export default Labels;