import ModalHeader from "../../../../GlobalComponents/Modal/ModalHeader"
import { useTranslation } from "react-i18next";
import { UseContentManager } from "../../Library/UseContentManager";
import FileInput from "../../../../GlobalComponents/FileInput";
import Button from "../../../../GlobalComponents/Button";
import { GET_ALL_IMAGES } from "../../Queries/fileManager";
import Loading from "../../../../GlobalComponents/Loading";
import Error from "../../../../GlobalComponents/Error";
import { useQuery,useApolloClient } from "@apollo/client";
import NoItems from "../../../../GlobalComponents/NoItems";
import UseUploadImages from "../../Library/FileManager/UseUploadImages";
import { CONTENT_MANAGER_IMAGE_URL, SERVER_URL } from "../../../../Config";
import Checkbox from "../../../../GlobalComponents/Checkbox";
import UseDeleteImages from "../../Library/FileManager/UseDeleteImages";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";

const ImagesList = () => {

    const client = useApolloClient();
    const {t}    = useTranslation();
    const {AddNotification} = UseNotification();
    const {openFileManagerData,CloseFileManager,ChooseFileManagerImage} = UseContentManager();

    const { 
        UploadImages,
        loading:uploadLoading,
        error:uploadError, 
    } = UseUploadImages(client);
    const { 
        selectedItems, 
        showDeleteNotification, 
        DeleteImages,
        SelectImage,
        ShowDeleteNotification 
    } = UseDeleteImages(client);

    const {data,loading,error} = useQuery(GET_ALL_IMAGES,{
        fetchPolicy:"network-only"
    });

    const SelectImg = (openFileManagerData,item) => {

        if(openFileManagerData.selectMultiple)
            SelectImage(item);
        else
            ChooseFileManagerImage(openFileManagerData,item.name);
            
    }

    const ChooseImages = () => {

        if(selectedItems.length > 0)
        {
            const fileNames = [];
            for(let val of selectedItems)
            {
                fileNames.push(val.name);
            }

            ChooseFileManagerImage(openFileManagerData,null,fileNames);
        }
        else
        {
            AddNotification(t("NoSelectedImages"),false);
        }
    }

    var err = "";
    if(error || uploadError)
        err = GetApolloErrorText(error || uploadError);

    return(
        <>
            <ModalHeader 
                title = {t("ImagesList")}
                OnClose ={() => CloseFileManager()}
            >
                {openFileManagerData.selectMultiple ?
                    <Button
                        className = "btn-primary"
                        OnClick = {() => ChooseImages(true)}
                    >
                        {t("Choose")}
                    </Button>
                :null}
                <FileInput
                    showOnlyButton = {true}
                    OnChange = {(e) => UploadImages(e.target.files)}
                    buttonTitle = {t("UploadImages")}
                />
                <Button
                    className = "btn-brown"
                    OnClick = {() => ShowDeleteNotification(true)}
                >
                    {t("DeleteSelected")}
                </Button>
            </ModalHeader>

            <div className="modal-body">

                {err ?
                    <Error text = {err} />
                :
                    (loading || uploadLoading ?
                        <Loading />
                    :
                        (data.AllContentManagerImages.length > 0 ?
                            <div className="row">
                                {data.AllContentManagerImages.map((item,index) => {
                                    
                                    var check = false;
                                    for(let val of selectedItems)
                                    {
                                        if(val.contentManagerImageID == item.contentManagerImageID)
                                            check = true;   
                                    }

                                    return(
                                        <div key={item.contentManagerImageID} className="col-20">
                                            <div className="cm-image-container form-group">
                                                <div className="select">
                                                    <Checkbox 
                                                        text = {""}
                                                        name = "selectedImage"
                                                        checked = {check}
                                                        OnChange = {() => SelectImage(item)}
                                                    />
                                                </div>
                                                <img 
                                                    src = {SERVER_URL + "/" + CONTENT_MANAGER_IMAGE_URL + "/stredni_" + item.name} 
                                                    alt={item.name} 
                                                    onClick = {() => SelectImg(openFileManagerData,item)}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        :
                            <NoItems
                                className = {"justify-content-center no-margin"}
                                text={t("NoImagesHereYet")} 
                            />
                        )
                    )
                }
                
            </div>
            <div className="modal-footer"></div>

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteImages")} 
                    CallBack={DeleteImages} 
                />
            :null}
        </>
    )
}

export default ImagesList