import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import UseGetAllForms from "../Library/UseGetAllForms";
import { useState } from "react";
import AddEditForm from "./AddEditForm";
import { UseAuth } from '../../Auth/Library/UseAuth';
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseDeleteForms from "../Library/UseDeleteForms";
import ModalNotification from "../../../GlobalComponents/ModalNotification";

const Forms = () => {

    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedFormID, SetSelectedFormID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedFormIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    } = UseGetAllForms(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteForms
    } = UseDeleteForms(selectedFormIDs,(d) => UpdateListAfterDelete(d));

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("FormList")}
                        OnAddButtonClick={() => SetSelectedFormID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (formID) => SetSelectedFormID(formID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedFormID || selectedFormID == 0 ?
                <AddEditForm
                    selectedFormID = {selectedFormID}
                    SetSelectedFormID = {SetSelectedFormID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedFormID,d);
                        SetSelectedFormID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteForms")} 
                    CallBack={DeleteForms} 
                />
            :null}
        </>
    )

}

export default Forms;