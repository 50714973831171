import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import YesNo from "../../../GlobalComponents/YesNo";

const UseEditMemberships = (selectedMemberships,SetFormData,SetFormDataSelectedItemsWithObj) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedMemberships)
        {
            const contentData = GetContentData(selectedMemberships);
            SetContent(contentData);
        }

    },[selectedMemberships])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        },
        {
            value: t("CanBeReserved"),
            className:"static text-center"
        },
        /*
        {
            value: t("DiscountInPercentage"),
            className:"static text-center"
        }*/
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value:<YesNo isYes={val.canBeReserved ? true : false} />,
                        className:"static text-center"
                    },
                    /*
                    {
                        elm:"input",
                        name:"discount",
                        param:"discount",
                        OnChange: (e) => SetFormDataSelectedItemsWithObj(e,"selectedMemberships","membershipID",val.membershipID),
                        className:"static"
                    }*/
                ],
            }

            cData.canBeReserved = val.canBeReserved;
            cData["discount"]   = val.discount;
            cData.rowID         = val.membershipID;
            cData.selected      = false;

            contentData.push(cData);
        }
        return contentData;
    }

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {
            const startIndex = result.source.index;
            const endIndex = result.destination.index;
            
            var arr = [...selectedMemberships];
            const [removed] = arr.splice(startIndex, 1);
            arr.splice(endIndex, 0, removed);
            
            SetFormData("selectedMemberships",arr);
        }
    } 

    return{
        headerData,
        content,
        OnDragEnd
    }

}

export default UseEditMemberships;