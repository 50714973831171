import gql from 'graphql-tag';

export const GET_ALL_MARKETING_BANNERS = gql`
    query AllMarketingBanners{
        AllMarketingBanners{
            marketingBannerID
            name
            photo
        }
    }
`;

export const GET_MARKETING_BANNER = gql`
    query MarketingBanner($marketingBannerID:ID){
        MarketingBannerWithLangs(marketingBannerID:$marketingBannerID){
            marketingBannerID
            marketingBannerTypeID	
            name	
            buttonColor
            photo	
            photoPortrait
            dateEdit
            langs{
                lang
                text
                buttonText
                buttonUrl
            }
            projectData{
                projectID
                showInProject	
                showInBlog	
                showInAllArticles
            }
        }

        AllMarketingBannerTypes{
            marketingBannerTypeID
            type
        }

        AllProjects{
            projectID
            name
        }
          
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const ADD_EDIT_MARKETING_BANNER = gql`
    mutation AddEditMarketingBanner(
        $marketingBannerID:ID,
        $marketingBannerTypeID:ID,
        $name:String,
        $buttonColor:String,
        $photo:Upload,
        $photoPortrait:Upload,
        $langs:[MarketingBannerLangsInput],
        $projectData:[MarketingBannerProjectDataInput],
        $generateTranslations:GenerateMarketingBannerTranslationsInput
        
    ){
        AddEditMarketingBanner(
            marketingBannerID:$marketingBannerID,
            marketingBannerTypeID:$marketingBannerTypeID,
            name:$name, 
            buttonColor:$buttonColor, 
            photo:$photo,
            photoPortrait:$photoPortrait, 
            langs:$langs,
            projectData:$projectData,
            generateTranslations:$generateTranslations
        ){
            marketingBannerID
            name
            photo
        }
    }
`;

export const DELETE_MARKETING_BANNERS = gql`
    mutation DeleteMarketingBanners($marketingBannerIDs:ID) {
        DeleteMarketingBanners(marketingBannerIDs:$marketingBannerIDs)
    }
`;

export const UPDATE_MARKETING_BANNER_PRIORITY = gql`

   mutation UpdateMarketingBannerPriority($marketingBannerID:ID,$fromIndex:Int!,$toIndex:Int!) {
       UpdateMarketingBannerPriority(marketingBannerID:$marketingBannerID,fromIndex:$fromIndex,toIndex:$toIndex)
   }

`;

export const GENERATE_MARKETING_BANNER_LANG = gql`
    mutation GenerateMarketingBannerLang(
        $marketingBannerID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateMarketingBannerLang(
            marketingBannerID:$marketingBannerID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;

