const IsDev = () => {
    try {
        return process.env.NODE_ENV === "development";
    } catch {
        return false;
    }
};

export const IsServerDev = () => {
    if(window.location.href.includes("vyvoj-tvorime.laskyplnysvet.cz"))
        return true
    else
        return false
};

let graphqlServerUrl     = "https://server2.laskyplnysvet.cz/graphql";
let serverUrl            = "https://server2.laskyplnysvet.cz";

if(IsServerDev())
{
    graphqlServerUrl     = "https://vyvoj-server2.laskyplnysvet.cz/graphql";
    serverUrl            = "https://vyvoj-server2.laskyplnysvet.cz";
}
else if (IsDev()) 
{
    graphqlServerUrl     = "http://localhost:8900/graphql";
    serverUrl            = "http://localhost:8900";
}

export const GRAPHQL_SERVER_URL                 = graphqlServerUrl;
export const SERVER_URL                         = serverUrl;
export const NOTIFI_TIME_VISIBLE                = 6000;
export const MAX_IMAGE_FILE_SIZE_TO_UPLOAD      = 10000000;
export const MAX_VIDEO_FILE_SIZE_TO_UPLOAD      = 10000000;
export const TINY_MCE_API_KEY                   = 'jz3p06tpgpxdb8xvx6lr5c3anw7gg9y1rdnmhl2i74mir8pe';
export const TINYMCE_IMAGE_URL                  = "Public/Images/TinyMCE";
export const TINYMCE_FILE_URL                   = "Public/Files/TinyMCE";
export const MARKETING_BANNER_IMAGE_URL         = "Public/Images/MarketingBanner";
export const ARTICLE_IMAGE_URL                  = "Public/Images/Article";
export const CONTENT_MANAGER_IMAGE_URL          = "Public/Images/ContentManager";
export const CONTENT_MANAGER_VIDEO_URL          = "Public/Videos/ContentManager";
export const CONTENT_MANAGER_VIDEO_POSTER_URL   = "Public/Videos/ContentManager/Posters";
export const NEWSLETTER_IMAGE_PATH              = "Public/Images/Newsletter";
export const REVIEW_IMAGE_URL                   = "Public/Images/Review";
export const SERVICE_IMAGE_URL                  = "Public/Images/Service";
export const LINK_IMAGE_URL                     = "Public/Images/Link";
export const COVER_IMAGE_URL                    = "Public/Images/Cover";
export const PRODUCT_IMAGE_URL                  = "Public/Images/Product";
export const PRODUCT_REVIEW_IMAGE_URL           = "Public/Images/ProductReview";
export const PRODUCT_DIMENSION_TYPE_IMAGE_URL   = "Public/Images/ProductDimensionType";
export const BANNER_IMAGE_URL                   = "Public/Images/Banner";
export const PROJECT_IMAGE_URL                  = "Public/Images/Project";
export const FLAG_IMAGE_PATH                    = "Public/Images/Flag";
export const NEWSLETTER_SETTINGS_IMAGE_URL      = "Public/Images/NewsletterSettings";
export const RESERVATION_PLACE_IMAGE_URL        = "Public/Images/ReservationPlace";
export const MEMBERSHIP_IMAGE_URL               = "Public/Images/Membership";
export const CUSTOMER_IMAGE_URL                 = "Public/Images/Customer";

export const TINY_MCE_TOOLBAR_BASIC_FORMAT      = "bold italic forecolor link alignleft aligncenter alignright alignjustify removeformat code";
export const TINY_MCE_TOOLBAR_BASIC             = "bold italic forecolor fontSize link bullist numlist alignleft aligncenter alignright alignjustify removeformat code";
export const TINY_MCE_TOOLBAR_ADVANCED          = "bold italic forecolor fontSize blocks link bullist numlist alignleft aligncenter alignright alignjustify removeformat code emoticons removeformat code";
export const TINY_MCE_TOOLBAR_MEDIA             = "bold italic forecolor fontSize blocks link bullist numlist alignleft aligncenter alignright alignjustify blockquote image media emoticons removeformat code";

export const BASE_EMAIL_LINK_COLOR             = "#43AAE3";
export const BASE_BUTTON_BG_COLOR              = "#fbe272";
export const BASE_BUTTON_TEXT_COLOR            = "#222222";
export const BASE_HEADLINE_COLOR               = "#222222";
export const BASE_LINK_COLOR                   = "#43AAE3";

export const UNSUBSCRIBE_EMAIL_TEXT = 
`
<p>Tento e-mail ti přišel z nějakého důvodu do života. Naše největší přání se splní ve chvíli, kdy každý, kdo čte tento e-mail skočí a otestuje sám na sobě, zda spolu dokážeme posunout život na novou úroveň bytí a každý den si bude plnit jedno přání za druhým.</p>
<p>Píšeme ti z [~sendersEmail~] na tvůj [~email~]. Pokud však cítíš, že nechceš posunout život na novou úroveň bytí a každý den si plnit jedno přání za druhým, pak pohodově klikni na [~unsubscribe~]. Tvoř svůj svět :)</p>
`


