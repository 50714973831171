import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditCustomer from "./AddEditCustomer";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllCustomers from "../Library/UseGetAllCustomers";
import UseDeleteCustomers from "../Library/UseDeleteCustomers";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import ContentFilterSidebar from "../../../GlobalComponents/ContentFilterSidebar";
import CustomerFilter from "./CustomerFilter";

const Customers = () => {

    const {t} = useTranslation();

    const [selectedCustomerID, SetSelectedCustomerID] = useState(null);
    const [showFilterSidebar,SetShowFilterSidebar] = useState(false);

    const {
        content,
        loading,
        error,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        headerData,
        selectedCustomerIDs,
        SelectRow,
        SelectAll,
        FetchMore,
        GoToPage,
        FilterCustomers,
        UpdateList
    } = UseGetAllCustomers();

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteCustomers
    } = UseDeleteCustomers(selectedCustomerIDs,() => UpdateList());

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("CustomerList")}
                        OnAddButtonClick={() => SetSelectedCustomerID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                        //OnFilterClick = {() => SetShowFilterSidebar(true)}
                        OnSearch = {(filterData) => FilterCustomers(filterData)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (customerID) => SetSelectedCustomerID(customerID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                    <ContentFilterSidebar
                        isOpen = {showFilterSidebar}
                        OnClose = {() => SetShowFilterSidebar(false)}
                    >
                        <CustomerFilter
                            OnFilter = {(filterData) => FilterCustomers(filterData)}
                        />
                    </ContentFilterSidebar>
                </>
            }

            {selectedCustomerID || selectedCustomerID == 0 ?
                <AddEditCustomer
                    selectedCustomerID = {selectedCustomerID}
                    SetSelectedCustomerID = {SetSelectedCustomerID}
                    OnAddEditSuccess = {(d) => {
                        UpdateList();
                        SetSelectedCustomerID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteCustomers")} 
                    CallBack={DeleteCustomers} 
                />
            :null}
        </>
    )
}

export default Customers;