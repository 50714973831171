const Campaign = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <g>
                            <path style={{"fill":secondaryColor}}  d="M182.41,390.17c-2.815,0-5.63-1.073-7.778-3.222l-82.638-82.639
                                c-4.295-4.296-4.295-11.261,0-15.557c4.296-4.296,11.26-4.297,15.557,0l82.638,82.639c4.295,4.296,4.295,11.261,0,15.557
                                C188.04,389.095,185.225,390.17,182.41,390.17z"/>
                        </g>
                        <g>
                            <path style={{"fill":secondaryColor}} d="M404.033,133.424c-18.721,0-36.382-3.605-45.576-8.913c-1.672-0.966-3.061-2.354-4.026-4.026
                                c-8.317-14.403-12.453-49.602-5.091-77.079c4.04-15.077,11.667-27.038,22.059-34.59c9.576-6.96,21.236-9.952,32.831-8.426
                                c19.594,2.579,34.599,16.985,38.562,35.761c18.775,3.963,33.181,18.968,35.761,38.562c1.527,11.595-1.466,23.254-8.425,32.831
                                c-7.552,10.391-19.513,18.019-34.59,22.059c0,0,0,0,0,0C425.596,132.264,414.644,133.424,404.033,133.424z M432.689,118.976h0.01
                                H432.689z M372.207,106.733c10.557,4.078,36.36,7.319,57.635,1.618c22.041-5.906,28.346-19.767,26.897-30.768
                                c-1.298-9.856-9.336-20.465-23.918-20.465c-6.075,0-11-4.925-11-11c0-14.582-10.608-22.62-20.464-23.918
                                c-11.006-1.443-24.862,4.857-30.768,26.897C364.889,70.376,368.13,96.177,372.207,106.733z"/>
                        </g>
                        <g>
                            <path style={{"fill":secondaryColor}}  d="M357.663,280.825c-2.815,0-5.63-1.073-7.778-3.222L201.338,129.056
                                c-4.296-4.296-4.295-11.261,0-15.557s11.259-4.297,15.557,0l148.546,148.547c4.295,4.296,4.295,11.261,0,15.557
                                C363.293,279.751,360.478,280.825,357.663,280.825z"/>
                        </g>
                    </g>
                    <path style={{"fill":primaryColor}} d="M431.773,257.668l-210.5-210.5c-3.146-3.146-7.877-4.087-11.988-2.385c-4.11,1.703-6.791,5.714-6.791,10.163
                        c0,77.787-38.653,151.259-76.96,189.565l-70.003,70.004c-14.492,14.492-22.473,33.828-22.473,54.447s7.981,39.955,22.473,54.447
                        c14.492,14.492,33.829,22.474,54.448,22.474c0.001,0-0.001,0,0,0c17.264,0,33.631-5.596,47.007-15.925l100.098,79.648
                        c2.015,1.604,4.435,2.393,6.846,2.393c2.829,0,5.646-1.087,7.781-3.222l19.092-19.093c7.301-7.302,11.011-16.809,10.446-26.771
                        c-0.568-10.025-5.379-19.126-13.547-25.625l-34.299-27.292c7.275-10.901,10.605-23.677,9.441-36.625
                        c-1.058-11.768-5.647-23.177-13.404-33.501c40.729-33.37,105.964-63.424,174.555-63.424c4.449,0,8.46-2.68,10.163-6.79
                        C435.86,265.545,434.919,260.814,431.773,257.668z M109.978,423.884c-2.608,0-5.187-0.178-7.722-0.529l7.416-7.417
                        c4.295-4.296,4.295-11.261,0-15.557c-4.296-4.295-11.261-4.295-15.557,0l-14.468,14.468c-3.052-2.017-5.92-4.355-8.561-6.996
                        c-2.641-2.641-4.978-5.508-6.996-8.56l14.468-14.467c4.296-4.296,4.296-11.261,0-15.557s-11.261-4.296-15.556,0l-7.417,7.417
                        c-0.351-2.536-0.529-5.115-0.529-7.723c0-14.742,5.693-28.555,16.03-38.891l69.296-69.297l77.782,77.782l-69.297,69.297
                        C138.532,418.191,124.721,423.884,109.978,423.884z M279.285,464.158c0.205,3.62-1.229,7.161-4.038,9.97l-12.149,12.149
                        l-89.959-71.58l22.527-22.527l78.338,62.333C277.2,457.047,279.076,460.475,279.285,464.158z M211.323,376.514l21.74-21.74
                        c9.48,13.679,10.647,28.831,3.04,41.457L211.323,376.514z M306.017,280.092c-26.471,11.576-51.256,26.727-71.587,43.617
                        l-79.197-79.197c16.89-20.331,32.04-45.116,43.616-71.588c13.162-30.097,21.456-61.688,24.419-92.647l175.396,175.396
                        C367.705,258.635,336.114,266.929,306.017,280.092z"/>
                </g>
            </svg>
        </>
    )
}

export default Campaign;
