import Button from "../Button";
import Select from "../Select";
import Checkbox from "../Checkbox";
import { useTranslation } from "react-i18next";
import settingsWhiteIcon from '../../Media/Images/Icons/settings_white.webp';
import { useState } from "react";
import GenerateTranslationsSettings from "../GenerateTranslationsSettings";
import { UseSettings } from "../../AdminProvider";

const ModalHeader = ({
    children,
    hideContent,
    isEdit,
    title,
    allLanguages,
    itemsToTranslate,
    OnClose,
    selectedLang,
    generateTranslations,
    OnLangSelect,
    OnGenerateSelect,
    OnSelectTranslationItems
}) => {

    const {settings} = UseSettings();
    const {t} = useTranslation();
    const [showTranstlationSettings, SetShowTranstlationSettings] = useState(false);

    return(
        <div className={"modal-header" + (hideContent ? " hidden-content" : "")}>

            {!hideContent ?
                <>
                    <h3>{title}</h3>

                    <div className="modal-header-controls">
                        {children}
                        {allLanguages && allLanguages.length > 1 ? 
                            <>
                                {generateTranslations && settings.useThirdPartyForTransaltion != "0" ?
                                    <>
                                        <Checkbox 
                                            text={t("GenerateAllTranslations")}
                                            id = "generate-transaltions"
                                            name = "generateTranslations"
                                            checked = {generateTranslations.enabled}
                                            OnChange = {(e) => OnGenerateSelect(e)}
                                        />
                                        {generateTranslations.enabled && isEdit ?
                                            <Button
                                                className="btn-blue"
                                                OnClick={() => SetShowTranstlationSettings(!showTranstlationSettings)} 
                                            ><img src={settingsWhiteIcon} /></Button>
                                        :null}
                                    </>
                                :null}
                                {settings.useThirdPartyForTransaltion == "0" || !generateTranslations || (generateTranslations && !generateTranslations.enabled) ?
                                    <Select 
                                        name="selectedLang"
                                        value={selectedLang}
                                        OnChange={(e) => OnLangSelect(e)}
                                    >
                                        {allLanguages.map((item) => (
                                            <option key={item.languageID} value = {item.suffix}>{item.suffix}</option>
                                        ))}
                                    </Select>
                                :null}
                            </>
                        :null}
                        <Button 
                            className={"btn-brown"}
                            OnClick={() => OnClose()}
                        >X</Button>
                    </div>
                    {showTranstlationSettings ?
                        <GenerateTranslationsSettings 
                            items = {itemsToTranslate}
                            generateTranslations = {generateTranslations}
                            OnSelectTranslationItems = {OnSelectTranslationItems}
                            OnClose={() => SetShowTranstlationSettings(false)}
                        />
                    :null}
                </>
            :null}
        </div>
    )

}

export default ModalHeader;