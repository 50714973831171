import React from 'react';
import infoIcon from '../Media/Images/Icons/info.webp';
import { useRef,useEffect,useState } from 'react';
import Badge from './Badge';

const Label = ({text,infoContent,className,badgeText}) => {

    const containerRef = useRef();

    const [leftPosition, SetLeftPosition] = useState(null)

    useEffect(() => {
        if(containerRef && infoContent)
        {
            containerRef.current.addEventListener("mouseover",OnMouseOver);
            containerRef.current.addEventListener("mouseleave",OnMouseLeave);
            return () => {
                if(containerRef.current)
                {
                    containerRef.current.removeEventListener("mouseover",OnMouseOver);
                    containerRef.current.removeEventListener("mouseleave",OnMouseLeave);
                }
            }
        }
    },[containerRef]);

    const OnMouseOver = (e) => {
        
        const windowWidth = window.innerWidth;
        const mouseX = e.clientX;
        const contentWidth = 250;

        const parent = e.target.parentNode;
        SetLeftPosition({left: -parent.offsetLeft})

        if(mouseX - parent.offsetLeft + contentWidth > windowWidth)
            SetLeftPosition({left: - contentWidth + containerRef.current.offsetWidth})
        else
            SetLeftPosition({left: -parent.offsetLeft})
    }

    const OnMouseLeave = (e) => {
        SetLeftPosition(null)
    }

    return (
    	<label className={"input-label" + (className ? " " + className : "")}>	
    		{text}
            {badgeText ? 
                <Badge type="warning" text={badgeText} />
            :null}
            {infoContent ?
                <div ref={containerRef} className="info-container">
                    <img className="info-icon" src = {infoIcon} alt="" />
                    <div 
                        className="info-content"
                        style={leftPosition}
                    >
                        {infoContent.map((item,index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                </div>
            :null}
            
    	</label>
    );
}

export default Label;