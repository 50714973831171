const Quotes = ({className,color}) => {

    var colors = ["#dddddd","#efefef"];

    if(color)
        colors = color.split("-");

    return(
        <svg 
            className= {className} 
            width="45" 
            height="39" 
            viewBox="0 0 45 39" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M1.536 38.1895C0.967113 38.1895 0.512 38.0032 0.170667 37.6306C-0.0568889 37.1339 -0.0568889 36.575 0.170667 35.954L12.288 1.49033C12.6293 0.496775 13.2551 0 14.1653 0H20.3093C20.8782 0 21.2764 0.186292 21.504 0.558875C21.8453 0.931458 21.9591 1.42823 21.8453 2.0492L12.288 36.6992C11.9467 37.6927 11.264 38.1895 10.24 38.1895H1.536ZM24.2347 38.1895C23.6658 38.1895 23.2107 38.0032 22.8693 37.6306C22.6418 37.1339 22.6418 36.575 22.8693 35.954L34.9867 1.49033C35.328 0.496775 35.9538 0 36.864 0H43.008C43.5769 0 43.9751 0.186292 44.2027 0.558875C44.544 0.931458 44.6578 1.42823 44.544 2.0492L34.9867 36.6992C34.6453 37.6927 33.9627 38.1895 32.9387 38.1895H24.2347Z" 
                fill={colors.length > 1 ? "url(#gradient)" : colors[0]}
            />

            {colors.length > 1 ?
                <defs>
                    <linearGradient 
                        id="gradient" 
                        x1="-2.23115e-06" 
                        y1="58.5" 
                        x2="1.02682" 
                        y2="-0.847327" 
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor = {colors[0]}/>
                        <stop offset="1" stopColor = {colors[1]}/>
                    </linearGradient>
                </defs>
            :null}
        </svg>
    )
}

export default Quotes;