import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_PROJECT,GENERATE_PROJECT_LANG } from "../Queries/project";
import { GetProjectPortalModuleObj } from "./UseEditProjectPortalMenu";
import { UseSettings } from "../../../AdminProvider";
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";

const UseAddEditProject = (
    selectedProjectID,
    mainLang,
    allLanguageMutations,
    OnCompleted
) => {

    const {AddNotification} = UseNotification();
    const {settings} = UseSettings();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        OpenImage,
        GetEventData,
        GetFormLangValue,
        SetFormLangData,
        SetFormSelectedItemsLangValue,
        GetFormSelectedItemsLangValue,
        SetFormDataSelectedItemsWithObj,
        FillFormSelectedItemsWithObj
    } = UseFormHandle({
        selectedLang:mainLang,
        billingInformationID:0,
        name:"",
        logo:null,
        logoFooter:null,
        logoPortal:null,
        logoInvoice:null,
        favicon:null,
        emailHeader:null,
        active:1,
        buttonBgColorType:1,
        headlineColorType:1,
        inputBorderRadius:10,
        buttonBorderRadius:10,
        stripePublishableKey:"",
        stripeSecretKey:"",
        stripeWebHook:"",
        purchaseInfoEmail:"",
        newRegistrationInfoEmail:"",
        cancelMembershipInTrialPeriodEmail:"",
        completeQuestionnaireEmail:"",
        affiliatePaidOfEmail:"",
        invoiceNumberCharacter:"",
        selectedPortalModules:[],
        langs:[],
        domains:[],
        modules:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_PROJECT_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditProjectMutation,{error,loading}] = useMutation(ADD_EDIT_PROJECT,{
        onCompleted:async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    projectID: d.AddEditProject.projectID,
                    isEdit: (selectedProjectID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            { 
                if(OnCompleted)
                    OnCompleted(d.AddEditProject);

                const notifyText = (selectedProjectID != 0 ? t("SuccessfullyUpdated") : t("NewProjectIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("ProjectHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditProject = () => {

        var noDeletedDomains = formData.domains.filter((item) => !item.isDeleted);

        if(noDeletedDomains.length > 0)
        { 
            if(CheckDomains(noDeletedDomains))
            {
                if(formData.name)
                {   
                    let langs = [];
                    for(const lang of formData.langs)
                    {
                        langs.push({
                            webEmail:                   lang.webEmail,
                            webTel:                     lang.webTel,
                            sendersEmail:               lang.sendersEmail,
                            emailSignature:             lang.emailSignature,
                            emailFooterText:            lang.emailFooterText,
                            emailNewsletterSignature:   lang.emailNewsletterSignature,
                            sendersName:                lang.sendersName,
                            unsubscribeText:            lang.unsubscribeText,
                            metaTitle:                  lang.metaTitle,
                            lang:                       lang.lang
                        });
                    }

                    AddEditProjectMutation({
                        variables:{
                            projectID:                          selectedProjectID,
                            billingInformationID:               formData.billingInformationID,
                            name:                               formData.name,
                            logo:                               formData.logo,
                            logoFooter:                         formData.logoFooter,
                            logoPortal:                         formData.logoPortal,
                            logoInvoice:                        formData.logoInvoice,
                            favicon:                            formData.favicon,
                            emailHeader:                        formData.emailHeader,
                            emailLinkColor:                     formData.emailLinkColor,
                            linkColor:                          formData.linkColor,
                            buttonBgColor:                      GetColor(formData.buttonBgColor,formData.buttonBgColorType),
                            buttonTextColor:                    formData.buttonTextColor,
                            buttonBorderRadius:                 parseInt(formData.buttonBorderRadius),
                            headlineColor:                      GetColor(formData.headlineColor,formData.headlineColorType),
                            inputBorderRadius:                  parseInt(formData.inputBorderRadius),
                            stripePublishableKey:               formData.stripePublishableKey, 
                            stripeSecretKey:                    formData.stripeSecretKey, 
                            stripeWebHook:                      formData.stripeWebHook, 
                            purchaseInfoEmail:                  formData.purchaseInfoEmail, 
                            newRegistrationInfoEmail:           formData.newRegistrationInfoEmail, 
                            cancelMembershipInTrialPeriodEmail: formData.cancelMembershipInTrialPeriodEmail, 
                            completeQuestionnaireEmail:         formData.completeQuestionnaireEmail, 
                            affiliatePaidOfEmail:               formData.affiliatePaidOfEmail, 
                            invoiceNumberCharacter:             formData.invoiceNumberCharacter,
                            langs:                              langs,
                            active:                             parseInt(formData.active),
                            domains:                            GetSelectedDomains(formData.domains),
                            selectedPortalModules:              GetPortalModules(formData.selectedPortalModules),
                            modules:                            formData.modules,
                            generateTranslations:               formData.generateTranslations
                        }
                    })  
                }
                else
                    AddNotification(t("NoNameNoGo"),false);
            }
            else
                AddNotification(t("InvalidDomainData"),false);
        }
        else
            AddNotification(t("NoDomainAdded"),false);
        
    } 

    const GetPortalModules = (selectedPortalModules) => {

        if(selectedPortalModules)
        {
            var newPortalModules = [];
            for(let val of selectedPortalModules)
            {
                newPortalModules.push(GetProjectPortalModuleObj(val));
            }

            return newPortalModules;
        }
        else
            return []
        
    }

    const GetColor = (color,colorType) => {

        var splitedColor = color.split("-");

        if(splitedColor.length > 1 && colorType == 1)
            return splitedColor[0];
        else
            return color;
    }

    const GetSelectedDomains = (domains) => {
        var newDomains = [];
        for(let val of domains)
        {
            newDomains.push({
                projectDomainID: val.projectDomainID, 
                gtmID: val.gtmID, 
                lang: val.lang, 
                name: val.name.replace("www.",""), 
                portalSubdomain: val.portalSubdomain,
                isDeleted: val.isDeleted
            })
        }

        return newDomains;
    }

    const CheckDomains = (noDeletedDomains) => {

        var check = true;

        for(let val of noDeletedDomains)
        {
            if(!val.name)
                check = false;
        }

        return check;

    }

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const SetDomainValue = (e,index) => {

        var {n,v} = GetEventData(e,null);

        var newArr = [...formData.domains];
        newArr[index] = {...newArr[index],[n]:v};

        SetFormData("domains",newArr);
    }

    const AddDomain = () => {

        var newArr = [...formData.domains];

        const post = {
            projectDomainID:0,
            gtmID:"",
            isDeleted:false,
            name:"",
            portalSubdomain:"",
            lang:""
        }

        newArr.push(post);

        SetFormData("domains",newArr);
    }

    const RemoveDomain = (index) => {

        var newArr = [...formData.domains];
        
        if(newArr[index].projectDomainID == 0)
            newArr.splice(index,1);
        else
            newArr[index] = {...newArr[index],isDeleted:true}  

        SetFormData("domains",newArr);
    }

    const SetModule = (moduleID,subModules,checked) => {

        var newModules = [...formData.modules];
        
        if(checked)
            newModules.push(moduleID);
        else
        {
            const index = newModules.indexOf(moduleID);
            if(index != -1)
                newModules.splice(index,1);

            if(subModules && subModules.length > 0)
            {
                for(let val of subModules)
                {
                    const i = newModules.indexOf(val.moduleID);
                    if(i != -1)
                        newModules.splice(i,1);
                }
            }
        }
        
        SetFormData("modules",newModules);
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditProject,
        SetFormData,
        InitFormData,
        OpenImage,
        AddDomain,
        RemoveDomain,
        SetDomainValue,
        SetModule,
        GetFormLangValue,
        SetFormLangData,
        SetFormSelectedItemsLangValue,
        GetFormSelectedItemsLangValue,
        SetFormDataSelectedItemsWithObj,
        FillFormSelectedItemsWithObj
    }

}

export default UseAddEditProject;