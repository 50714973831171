import Modal from 'react-modal';
import Iframe from './Iframe';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { UseContentManager } from '../Library/UseContentManager';
import { useTranslation } from 'react-i18next';
import SectionHelpers from './Section/SectionHelpers';
import ControlsMenu from './ControlsMenu';
import AddContent from './AddContent';
import Settings from './Settings/Settings';
import FileManager from './FIleManager/FileManager';
import Loading from '../../../GlobalComponents/Loading';
import Button from '../../../GlobalComponents/Button';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Select from '../../../GlobalComponents/Select';
import AllElements from './AllElements';
import { 
    GetSectionClassName,
    GetSectionStyles,
    GetContainerStyles, 
    GetProjectRootSytles 
} from '../Library/functions';

const ContentManager = () => {

    const {t} = useTranslation();
    const {
        projectData,
        formData,
        loading,
        allLanguageMutations,
        contentManagerLinkID,
        showHelpers,
        SetFormData,
        showCloseContentWarning,
        SaveContent,
        CloseContentManager,
        CheckContentManagerBeforeClose,
        CopyLangContent,
        ClearSelection,
        SetLangData
    } = UseContentManager();

    const {selectedLang,langForCopyContent,content} = formData;

    const rootStyles = GetProjectRootSytles(projectData);

    return(
        <Modal
            isOpen={!!contentManagerLinkID}
            onRequestClose={() => CheckContentManagerBeforeClose()}
            overlayClassName={"modal-overlay"}
            className={"modal-content maxi"}
        >

            <ModalHeader 
                title = {t("ContentManager")}
                allLanguages = {allLanguageMutations}
                OnClose ={() => CheckContentManagerBeforeClose()}
                selectedLang = {selectedLang}
                OnLangSelect = {(e) => {
                    SetLangData(e.taget.value);
                    ClearSelection();
                    SetFormData(e);
                }}
            >
                <Button 
                    className = "btn-primary"
                    OnClick = {() => SaveContent()}
                >{t("SaveContent")}</Button> 

            </ModalHeader>

            <AddContent />
            <Settings />
            <FileManager />

            {showCloseContentWarning ?
                <ModalNotification
                    yesNo = {true}
                    text = {t("YouMadeSomeChanges")}
                    biggerNo = {true}
                    CallBack = {(act) => CloseContentManager(act)}
                />
            :null}

            {loading ? 
                <Loading />
            :
                <Iframe>  
                    <style>{rootStyles}</style>           
                    {content[selectedLang] && content[selectedLang].sections.map((item,index) => {

                        var containerStyles  = GetContainerStyles(item);
                        var sectionClassName = GetSectionClassName(item,content[selectedLang].sections.length);
                        
                        var stylesData       = GetSectionStyles(item);
                        sectionClassName += " " + stylesData.styleClassName;

                        return(
                            <section 
                                key = {"i - " + index}
                                id = {item.sectionName} 
                                className = {sectionClassName} 
                                style = {stylesData.styles} 
                            >

                                <div className={containerStyles.styleClassName + (item.fullWidth == 1 ? " cm-container-fluid"  : " cm-container")} style = {containerStyles.styles}>
                                    {item.elements && item.elements.map((elmItem,elmIndex) => {

                                        return(
                                            
                                            <AllElements
                                                key = {elmIndex}
                                                elmItem = {elmItem}
                                                elmIndex = {elmIndex}
                                                elementsCount = {item.elements.length}
                                                index = {index}
                                            />  
                                         
                                        )

                                    })}
                                </div>

                                {content[selectedLang].sections.length == 1 && item.elements.length == 0 &&
                                    <div className="cm-start-info">
                                        <p>{t("ToAddNewContent")}</p>
                                        <p>nebo</p>
                                        <p>zkopírujte obsah z jiného jazyka</p>
                                        <div className="copy-container">
                                            <Select 
                                                name="langForCopyContent"
                                                value={langForCopyContent}
                                                OnChange={(e) => SetFormData(e)}
                                            >
                                                {allLanguageMutations.map((item) => (
                                                    <option key={item.languageID} value = {item.suffix}>{item.suffix}</option>
                                                ))}
                                            </Select>
                                            <button onClick = {() => CopyLangContent()} className="cm-button primary">
                                                    Zkopírovat
                                            </button>
                                        </div>
                                    </div>
                                }

                                {showHelpers ?
                                    <>
                                        <SectionHelpers index = {index} addTitle={t("AddSection")} />
                                        <ControlsMenu 
                                            element = "section"
                                            index = {index} 
                                            elementsCount = {content[selectedLang].sections.length}
                                            position = {index + 1}
                                            showAddContent = {true}
                                        />
                                    </>
                                :null}  

                                {item.fadeWithBackground == 1 ?
                                    <>
                                        <div className="cm-section-fade"></div>
                                        <div className="cm-section-fade bottom"></div>
                                    </>
                                :null}

                            </section>
                        )
                        
                    })}

                </Iframe>
            }
            
        </Modal>
    )
}

export default ContentManager;