import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins,GetElementFontSize,GetHeadlineNumberingHeightStyles,GetElementAlign } from "../../Library/functions";
import TinyMCEEditor from '../TinyMCEEditor';

const Headline = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,projectData,indexToShowMenu,SelectElement,SetSettings} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins              = GetElementMargins(data,data.selectedTag);
    var subHeadlineClassName = GetElementFontSize(data,"subH");
    var className            = GetElementFontSize(data,data.selectedTag);
    const numberingStyles    = GetHeadlineNumberingHeightStyles(data);   
    const {flexAlign}        = GetElementAlign(data);         

    const headlineColor      = projectData.headlineColor.split("-");  
    
    var alignClassName = "";
    if(data.align == "center")
        alignClassName = "cm-justify-content-center";

    if(data.align == "end")
        alignClassName = "cm-justify-content-end";

    const OnHeadlineChange = (content) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu,"text",content,"headline");
    }
    const OnSubHeadlineChange = (content) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu,"subText",content,"headline");
    }

    const OnFormatApply = (e) => {

        const regex = new RegExp('h[1-6]');
        if(indexToShowMenu && (regex.test(e.format)))
            SetSettings(indexToShowMenu,"selectedTag",e.format,"headline");
    }

    const OnFormatRemove = (e) => {

        const regex = new RegExp('h[1-6]');
        if(indexToShowMenu && (regex.test(e.format)))
            SetSettings(indexToShowMenu,"selectedTag","h1","headline");
    }

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "headline"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={"cm-headline-element " + margins.styleClassName +  " " + data.className} style={margins.styles} >
                 
                <div className={"cm-d-flex cm-align-items-center" + (className ? " " + className : "") + (flexAlign ? " " + flexAlign : "") + (headlineColor.length > 1 ? " gradient" : "")}>
                    {data.numbering ?
                        <div className = {"number " + numberingStyles.styleClassName} style={numberingStyles.styles}>
                            {data.numbering}
                        </div>
                        
                    :null}
                    <TinyMCEEditor 
                        key = {indexToShowMenu}
                        value = {data.text}
                        forcedRootBlock = {"h1"}
                        OnEditorChange={(content) => OnHeadlineChange(content)}
                        toolbar = 'h1 h2 h3 h4 h5 h6'
                        OnFormatApply = {(e) => OnFormatApply(e)}
                        OnFormatRemove = {(e) => OnFormatRemove(e)}
                    />
                </div>
                
                <div className={"cm-d-flex " + subHeadlineClassName + (flexAlign ? " " + flexAlign : "")}>
                    {data.hasSubText == 1 ?
                        <TinyMCEEditor 
                            key = {indexToShowMenu}
                            value = {data.subText}
                            OnEditorChange={(content) => OnSubHeadlineChange(content)}
                            toolbar = 'bold italic'
                        />
                        
                    :null}
                </div>
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Headline;