import gql from 'graphql-tag';

export const GET_All_PROJECT_DATA = gql`
    query GetProjectData($projectID:ID){ 
        ProjectBy(param:"projectID",value:$projectID)
        {
            projectID,
            name,
            niceName,
            favicon,
            logo
            buttonBgColor
        }

        AllProjectModules(
            projectID:$projectID
        ){
            projectModuleID
            moduleID
        }
    }
`;
