import React from 'react';

const Warning = ({text,className}) => {
    return (
    	<div 
			className={"alert alert-warning text-center" + (className ? " " + className : "")}
			dangerouslySetInnerHTML={{__html:text}}
		>	
    	</div>
    );
}

export default Warning;