import { useState,Fragment,useRef,useEffect } from "react";
import infoIcon from '../Media/Images/Icons/info.webp';
import arrow from '../Media/Images/Icons/arrow.svg';
import ListInitialShorcut from "./List/ListInitialShorcut";
import Badge from "./Badge";

const InputCard = ({
    className,
    noBodyPadding,
    children,
    title,
    initialsShorcut,
    badgeText,
    badgeType,
    icon,
    tabsData,
    topMargin,
    headerChildren,
    headerInfoContent,
    canBeMinimalized,
    minimalized,
    reload
}) => {

    const timeout = useRef();

    const bodyRef = useRef(); 
    const containerRef = useRef();

    const [selectedTab,SetSelectedTab] = useState(0);
    const [closeBody,SetCloseBody] = useState(false);
    const [overflowHidden,SetOverflowHidden] = useState(false);
    const [bodyHeight,SetBodyHeight] = useState("auto");
    const [leftPosition, SetLeftPosition] = useState(null)

    const OnHeaderClick = () => {

        if(timeout.current)
            clearTimeout(timeout.current);

        if(canBeMinimalized)
        {
            SetCloseBody(!closeBody);
            if(!closeBody)
                SetOverflowHidden(true)
            else
                timeout.current = setTimeout(() => SetOverflowHidden(false) ,600);
        }
    }

    useEffect(() => {

        if(timeout.current)
            clearTimeout(timeout.current);
        
        if(canBeMinimalized && !minimalized)
        {
            SetOverflowHidden(true);
            timeout.current = setTimeout(() => SetOverflowHidden(false) ,600);
        }
        
        if(bodyRef.current && (canBeMinimalized || minimalized))
            SetBodyHeight(bodyRef.current.offsetHeight);

    },[children,bodyRef.current,reload])

    useEffect(() => {

        if(minimalized)
        {
            SetCloseBody(true);
            SetOverflowHidden(true)
        }
        else
            SetCloseBody(false);

    },[minimalized])

    useEffect(() => {
        if(headerInfoContent)
        {
            containerRef.current.addEventListener("mouseover",OnMouseOver);
            containerRef.current.addEventListener("mouseleave",OnMouseLeave);
            return () => {
                if(containerRef.current)
                {
                    containerRef.current.removeEventListener("mouseover",OnMouseOver);
                    containerRef.current.removeEventListener("mouseleave",OnMouseLeave);
                }
            }
        }
    },[headerInfoContent]);

    const OnMouseOver = (e) => {
        
        const windowWidth = window.innerWidth;
        const mouseX = e.clientX;
        const contentWidth = 250;

        const parent = e.target.parentNode;
        SetLeftPosition({left: -parent.offsetLeft})

        if(mouseX - parent.offsetLeft + contentWidth > windowWidth)
            SetLeftPosition({left: - contentWidth + containerRef.current.offsetWidth})
        else
            SetLeftPosition({left: -parent.offsetLeft})
    }

    const OnMouseLeave = (e) => {
        SetLeftPosition(null)
    }

    return(
        <div className={"input-card" + (topMargin ? " tm" : "") +  (className ? " " + className : "")}>
            
            {title || icon || initialsShorcut || badgeText ?
                <div 
                    onClick = {() => OnHeaderClick()}
                    className = {"input-card-header" + (canBeMinimalized ? " cursor-pointer" : "")}
                >
                    {initialsShorcut ?
                        <ListInitialShorcut
                            text = {initialsShorcut.text}
                            backgroundColor = {initialsShorcut.backgroundColor}
                        />
                    :null}
                    <h3>
                        {icon ? 
                            <img src={icon} />
                        :null}
                        {title}
                    </h3>

                    {badgeText ? 
                        <Badge type={(badgeType ? badgeType : "warning")} text={badgeText} />
                    :null}
                    
                    {headerInfoContent ?
                        <div ref={containerRef} className="info-container">
                            <img className="info-icon" src = {infoIcon} alt="" />
                            <div 
                                className="info-content"
                                style={leftPosition}
                            >
                                {headerInfoContent.map((item,index) => (
                                    <p key={index}>{item}</p>
                                ))}
                            </div>
                        </div>
                    :null}
                    {headerChildren || canBeMinimalized ?
                        <div className="ml-auto d-flex align-items-center">
                            {headerChildren}
                            {canBeMinimalized ?
                                <img className = {"minimalized" + (closeBody ? " closed" : "")} src={arrow} />
                            :null}
                        </div>
                    :null}
                </div>
            :null}
            {tabsData ?
                <ul className="tabs">
                    {tabsData.map((item,index) => (
                        <li 
                            key = {index}
                            className={(selectedTab === index ? "selected" : "")} 
                            onClick={() => SetSelectedTab(index)}
                        >
                            {item.tabContent}
                        </li>
                    ))}
                </ul>
            :null}
            {children || tabsData ?
                <div 
                    className={
                        "input-card-body " + 
                        (noBodyPadding ? "no-padding" : "") +
                        (closeBody ? " closed" : "") +
                        (canBeMinimalized || minimalized || !minimalized ? " can-be-minimalized" : "") +
                        (overflowHidden ? " overflow-hidden" : "") +
                        (!title && !icon && !initialsShorcut && !badgeText ? " no-top-border" : "")
                    }
                    style={(bodyHeight != "auto" && (canBeMinimalized || minimalized || !minimalized)  ? {height:bodyHeight + "px"} : null)}
                >
                    <div className = {"input-card-body-content" + (noBodyPadding ? " only-bottom-padding" : "")} ref = {bodyRef}>
                        {tabsData ?
                            tabsData.map((item,index) => {
                                if(selectedTab === index)
                                    return(
                                        <Fragment key = {index}>
                                            {item.content}
                                        </Fragment>
                                    )
                            })
                        :
                            (children)
                        }
                    </div>
                </div>
            :null}
        </div>
    )
}
export default InputCard;