const Content = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M501.021,115.262h-93.237c-6.075,0-11-4.925-11-11V11.024c0-6.075,4.925-11,11-11h93.237
                            c6.075,0,11,4.925,11,11v93.237C512.021,110.337,507.096,115.262,501.021,115.262z M418.784,93.262h71.237V22.024h-71.237V93.262z
                            "/>
                    </g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M501.021,0.024c0,0-490,0-490,0c-6.075,0-11,4.925-11,11c0.118,58.499-0.085,423.062,0,490c0,6.075,4.925,11,11,11
                        c0,0,490,0,490,0c6.075,0,11-4.925,11-11c0.085-69.072-0.061-429.44,0-490C512.021,4.949,507.096,0.024,501.021,0.024z
                        M22.021,22.024h468v71.237h-468V22.024z M490.021,490.024h-468V115.262h468V490.024z"/>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M192.583,276.864H86.134c-6.075,0-11-4.925-11-11v-73.559c0-6.075,4.925-11,11-11h106.449
                            c6.075,0,11,4.925,11,11v73.559C203.583,271.939,198.658,276.864,192.583,276.864z M97.134,254.864h84.449v-51.559H97.134V254.864
                            z"/>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M192.583,350.422H86.134c-14.563-0.565-14.614-21.411,0-22c0,0,106.449,0,106.449,0
                            C207.146,328.988,207.198,349.834,192.583,350.422z"/>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M425.908,423.981c0,0-339.774,0-339.774,0c-6.075,0-11-4.925-11-11c0-6.075,4.925-11,11-11c0,0,339.774,0,339.774,0
                            C440.401,402.521,440.598,423.351,425.908,423.981z"/>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M425.908,356.427H256.021c-6.075,0-11-4.925-11-11V192.305c0-6.075,4.925-11,11-11h169.887
                            c6.075,0,11,4.925,11,11v153.122C436.908,351.502,431.983,356.427,425.908,356.427z M267.021,334.427h147.887V203.305H267.021
                            V334.427z"/>
                    </g>
                </g>                
            </svg>
        </>
    )
}

export default Content;