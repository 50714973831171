import {useApolloClient, useQuery} from '@apollo/client';
import { GET_ALL_CUSTOMER_ROLES } from '../Queries/customerRole';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PROJECT_IMAGE_URL, SERVER_URL } from '../../../Config';

const UseGetAllCustomerRoles = () => {

    const client = useApolloClient();

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedCustomerRoleIDs,SetSelectedCustomerRoleIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_ALL_CUSTOMER_ROLES,{
        fetchPolicy:"network-only"
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCustomerRoles);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        },
        {
            value: t("Project"),
            className:"static text-center"
        }      
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"lis",
                        backgroundColor: val.backgroundColor,
                        text: val.name,
                        className:"flex-1"
                    },
                    {
                        elm:"img",
                        src: val.project ? SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + val.project.favicon : "",
                        className:"static d-flex justify-content-center"
                    },
                ],
            }

            cData.rowID    = val.customerRoleID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,orderID) => {

        var checked = e.target.checked;
        var newSelectedCustomerRoleIDs = [...selectedCustomerRoleIDs];
        const newList = SelectCustomerRoleID(content,orderID,checked,newSelectedCustomerRoleIDs)

        SetContent(newList);
        SetSelectedCustomerRoleIDs(newSelectedCustomerRoleIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedCustomerRoleIDs = [];
        const newList = SelectCustomerRoleID(content,"all",checked,newSelectedCustomerRoleIDs)

        SetSelectedCustomerRoleIDs(newSelectedCustomerRoleIDs);
        SetContent(newList);
    }

    const SelectCustomerRoleID = (list,orderID,checked,selectedCustomerRoleIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(orderID == newList[i].rowID || orderID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedCustomerRoleIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedCustomerRoleIDs.length; j++)
                    {
                        if(selectedCustomerRoleIDs[j] == newList[i].rowID)
                            selectedCustomerRoleIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectCustomerRoleID(newList[i].subData,orderID,checked,selectedCustomerRoleIDs)}
        }

        return newList;
    }

    const UpdateListAfterAddEdit = async (selectedCustomerRoleID,data) => {
        
        const { AllCustomerRoles } = client.readQuery({ query: GET_ALL_CUSTOMER_ROLES });
        var newArray = [...AllCustomerRoles];

        if(selectedCustomerRoleID && selectedCustomerRoleID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].customerRoleID == data.customerRoleID)
                {
                    var d = {...newArray[i],
                        name: data.name
                    }

                    newArray[i] = d;
                }
            } 
        }
        else
        {
            newArray.push({
                customerRoleID: data.customerRoleID,
                name: data.name
            })
        }
        
        client.writeQuery({ 
            query:GET_ALL_CUSTOMER_ROLES,
            data:{
                AllCustomerRoles: newArray
            } 
        });
    }

    const UpdateListAfterDelete = async (IDs) => {

        var response = IDs.split(",");
        
        const { AllCustomerRoles} = client.readQuery({ query: GET_ALL_CUSTOMER_ROLES });
        
        var newArray = [...AllCustomerRoles];
        
        for(let id of response)
        {
            for(let i in newArray)
            {
                if(newArray[i].customerRoleID == id)
                {
                    newArray.splice(i,1);
                }
            } 
        } 
        
        client.writeQuery({ 
            query:GET_ALL_CUSTOMER_ROLES,
            data:{
                AllCustomerRoles: newArray
            } 
        });
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedCustomerRoleIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    }

}

export default UseGetAllCustomerRoles;