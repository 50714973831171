import gql from 'graphql-tag';

export const ADD_EDIT_MEMBERSHIP = gql`
    mutation AddEditMembership(
        $membershipID:ID,
        $projectID:ID,
        $linkID:ID,
        $vatID:ID,
        $questionnaireID:ID,
        $customerRoleID:ID,
        $showForPurchase: Int,
        $isRecurring: Int,
        $isTherapy: Int,
        $showInPortal: Int,
        $useGuide: Int,
        $useFreeTrialPeriod: Int,
        $numberOfFreeTrialDays:Int,
        $numberOfDaysBeforeToSendReservationReminder:Int,
        $photo:Upload,
        $langs:[MembershipLangsInput],
        $selectedCustomerRoles:[MembershipCustomerRolesInput],
        $selectedReservationPlaces:[MembershipReservationPlaceInput]
        $generateTranslations:GenerateMembershipTranslationsInput
    ){
        AddEditMembership(
            membershipID:$membershipID,
            projectID:$projectID,
            linkID:$linkID,
            vatID:$vatID,
            questionnaireID:$questionnaireID,
            customerRoleID:$customerRoleID,
            showForPurchase: $showForPurchase, 
            isRecurring: $isRecurring, 
            isTherapy: $isTherapy, 
            showInPortal: $showInPortal, 
            useGuide: $useGuide,
            useFreeTrialPeriod: $useFreeTrialPeriod,
            numberOfFreeTrialDays: $numberOfFreeTrialDays,
            numberOfDaysBeforeToSendReservationReminder: $numberOfDaysBeforeToSendReservationReminder,
            photo:$photo, 
            langs:$langs,
            selectedCustomerRoles:$selectedCustomerRoles,
            selectedReservationPlaces:$selectedReservationPlaces,
            generateTranslations:$generateTranslations
        ){
            membershipID
        }
    }
`;

export const GET_MEMBERSHIP = gql`
    query MembershipWithLangs(
        $membershipID: ID,
        $lang:String,
        $projectID:ID,
    ){
        MembershipWithLangs(membershipID:$membershipID){
            membershipID
            linkID
            questionnaireID
            customerRoleID
            vatID
            showForPurchase
            isRecurring
            isTherapy
            showInPortal
            useGuide
            useFreeTrialPeriod
            numberOfFreeTrialDays
            numberOfDaysBeforeToSendReservationReminder
            photo
            dateEdit
            customerRoles{
                membershipCustomerRoleID
                customerRoleID
                customerRole{
                    name
                    backgroundColor
                }
            }
            reservationPlaces(onlyActive:false)
            {
                membershipReservationPlaceID
                reservationPlaceID
                langs{
                    lang
                    emailText
                    templateTherapyEmailText
                }
                times{
                    reservationPlaceTimeID
                }
                reservationPlace(lang:$lang){
                    reservationPlaceID
                    active
                    backgroundColor
                    internalName
                    times{
                        reservationPlaceTimeID
                        reservationPlaceID
                        timeFrom
                        timeTo
                    }
                }
            }
            langs{
                lang
                membershipLangID
                name
                invoiceName
                price
                prepaymentPrice
                priceQrCode
                prepaymentPriceQrCode
                description
                emailText
                upSellBombDescription
                successMessage
                trialPeriodCancellationEmail
                notificationOfEndingTrialPeriodEmail
                emailForCustomerWhenAddedToGuide
                noMoreFreeTrialPeriodWarning
                notPossibleToMakeOrderWarning
                recurringPaymentInfoEmail
                reservationReminderEmail
                therapyTextTemplate
                therapyTextToRead
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        AllCustomerRolesBy(param:"projectID",value:$projectID)
        {
            customerRoleID
            name
        }
        AllQuestionnaires(projectID:$projectID,lang:$lang)
        {
            questionnaireID
            internalName
        }
        AllLinks(
            lang:$lang,
            projectID:$projectID,
            isEshopCategory:0
        ){
            linkID
            name
            subLink{
                linkID
                name
                subLink{
                    linkID
                    name
                    subLink{
                        linkID
                        name
                        subLink{
                            linkID
                            name
                            subLink{
                                linkID
                                name
                                subLink{
                                    linkID
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
        AllVats{
            vatID
            value
        }
    }
`;

export const GET_MEMBERSHIPS = gql`
    query AllMemberships(
        $lang: String,
        $projectID: ID
    ){
        AllMemberships(
            lang:$lang
            projectID:$projectID
        ){
            membershipID
            canBeReserved
            showForPurchase
            isRecurring
            isTherapy
            showInPortal
            useGuide
            useFreeTrialPeriod
            photo
            name
        }
    }
`;

export const GET_NO_RECCURING_NO_RES_MEMBERSHIPS = gql`
    query AllNoRecurringNoResMemberships(
        $lang: String,
        $projectID: ID
    ){
        AllNoRecurringNoResMemberships(
            lang:$lang
            projectID:$projectID
        ){
            membershipID
            canBeReserved
            showForPurchase
            isRecurring
            isTherapy
            showInPortal
            useGuide
            useFreeTrialPeriod
            photo
            name
        }
    }
`;

export const DELETE_MEMBERSHIPS = gql`
    mutation DeleteMemberships($membershipIDs:ID) {
        DeleteMemberships(membershipIDs:$membershipIDs)
    }
`;

export const GENERATE_MEMBERSHIP_LANG = gql`
    mutation GenerateMembershipLang(
        $membershipID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateMembershipLang(
            membershipID:$membershipID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;

export const ADD_EDIT_MEMBERSHIP_TYPE = gql`
    mutation AddEditMembershipType(
        $membershipTypeID:ID,
        $membershipID:ID,
        $showForPurchaseOnWeb: Int,
        $showForPurchaseInPortal: Int,
        $numberOfRepetitions: Int,
        $lengthOfOneRepetition: Int,
        $langs:[MembershipTypeLangInput],
        $prices:[MembershipTypePriceInput],
        $generateTranslations:GenerateMembershipTypeTranslationsInput
    ){
        AddEditMembershipType(
            membershipTypeID:$membershipTypeID,
            membershipID:$membershipID,
            showForPurchaseOnWeb:$showForPurchaseOnWeb,
            showForPurchaseInPortal:$showForPurchaseInPortal,
            numberOfRepetitions:$numberOfRepetitions,
            lengthOfOneRepetition:$lengthOfOneRepetition,
            langs:$langs,
            prices:$prices,
            generateTranslations:$generateTranslations
        ){
            membershipTypeID
        }
    }
`;

export const GET_MEMBERSHIP_TYPES = gql`
    query AllMembershipTypes(
        $lang: String,
        $membershipID: ID
    ){
        AllMembershipTypes(
            lang:$lang
            membershipID:$membershipID
        ){
            membershipTypeID
            showForPurchaseOnWeb
            showForPurchaseInPortal
            numberOfRepetitions
            lengthOfOneRepetition
            name
        }
    }
`;

export const GET_MEMBERSHIP_TYPE = gql`
    query MembershipType(
        $membershipTypeID: ID
        $membershipID: ID,
        $lang: String
    ){
        MembershipTypeWithLangs(membershipTypeID:$membershipTypeID){
            membershipTypeID
            showForPurchaseOnWeb
            showForPurchaseInPortal
            numberOfRepetitions
            lengthOfOneRepetition                       
            prices{
                membershipTypePriceID
                membershipTypeID
                connectedMembershipTypeID
                connectedMembershipTypePriceID
                isMain
                langs{
                    lang
                    price
                    description
                }
            }
            langs{
                lang
                name
            }   
        }
        AllMembershipTypes(lang:$lang,membershipID:$membershipID){
            membershipTypeID
            name
            lang
            prices{
                membershipTypePriceID
                price
            }
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GENERATE_MEMBERSHIP_TYPE_LANG = gql`
    mutation GenerateMembershipTypeLang(
        $membershipTypeID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateMembershipTypeLang(
            membershipTypeID:$membershipTypeID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;

export const UPDATE_MEMBERSHIP_TYPE_PRIORITY = gql`
    mutation UpdateMembershipTypePriority(
        $membershipTypeID:ID!,
        $membershipID:ID!,
        $fromIndex:Int!,
        $toIndex:Int!
    ){
        UpdateMembershipTypePriority(
            membershipTypeID:$membershipTypeID,
            membershipID:$membershipID,
            fromIndex:$fromIndex,
            toIndex:$toIndex
        )
    }
`;

export const GET_MEMBERSHIPS_BY_RESERVATION_PLACE = gql`
    query AllMembershipsByReservationPlace($lang: String,$reservationPlaceID:ID){
        AllMembershipsByReservationPlace(lang:$lang,reservationPlaceID:$reservationPlaceID){
            membershipID
            projectID
            prepaymentPrice
            name
        }
    }
`;