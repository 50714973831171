import { useQuery } from "@apollo/client";
import { GET_FAQ } from "../Queries/faq";
import { useState } from "react";
import { CompleteLangsArray, GetMainLanguage } from "../../../GlobalFunctions/helper";

const UseGetFaq = (selectedFaqID,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_FAQ,{
        variables:{
            faqID:selectedFaqID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                question:"", 
                answer:""
            }

            if(selectedFaqID != 0)
            {
                let langs = CompleteLangsArray(d.FaqWithLangs.langs,d.AllLanguageMutations,emptyLang);
                
                CallBack({
                    active:                 d.FaqWithLangs.active,
                    dateEdit:               d.FaqWithLangs.dateEdit,
                    langs:                  langs,
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        mainLang
    }

}

export default UseGetFaq;