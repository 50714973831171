import { useQuery } from "@apollo/client";
import { GET_REVIEW } from "../Queries/review";
import { useState } from "react";
import { CompleteLangsArray, GetMainLanguage } from "../../../GlobalFunctions/helper";

const UseGetReview = (selectedReviewID,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_REVIEW,{
        variables:{
            reviewID:selectedReviewID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                text:"", 
                signature:""
            }

            if(selectedReviewID != 0)
            {
                let langs = CompleteLangsArray(d.ReviewWithLangs.langs,d.AllLanguageMutations,emptyLang);
                
                CallBack({
                    active:                 d.ReviewWithLangs.active,
                    photo:                  null,
                    mainPhoto:              d.ReviewWithLangs.photo,
                    dateEdit:               d.ReviewWithLangs.dateEdit,
                    langs:                  langs,
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        mainLang
    }

}

export default UseGetReview;