import Input from '../../../GlobalComponents/Input';
import InputCard from '../../../GlobalComponents/InputCard';
import { useTranslation } from 'react-i18next';
import emailIcon from '../../../Media/Images/Icons/email.webp';
import Label from '../../../GlobalComponents/Label';

const InfoEmailing = ({
    formData,
    SetFormData
}) =>{

    const {t} = useTranslation();

    return(
        <>            
            <InputCard
                title = {t("SendNotificationsToEmails")}
                icon = {emailIcon}
                headerInfoContent={[
                    t("InfoEmailingInfo1"),
                    t("InfoEmailingInfo2"),
                    t("InfoEmailingInfo3")
                ]}
            > 

                <div className="form-group">
                    <Label
                        text = {t("PurchaseInfoEmail")}
                        infoContent={[
                            t("PurchaseInfoEmailInfo1"),
                            t("PurchaseInfoEmailInfo2")
                        ]}
                    />
                    <Input
                        name="purchaseInfoEmail"
                        placeholder = {t("Email")}
                        value={formData.purchaseInfoEmail}
                        OnChange={(e) => SetFormData(e)}
                    />
                </div>

                <div className="form-group">
                    <Label
                        text = {t("NewRegistrationInfoEmail")}
                        infoContent={[
                            t("NewRegistrationInfoEmailInfo1"),
                            t("NewRegistrationInfoEmailInfo2")
                        ]}
                    />
                    <Input
                        name="newRegistrationInfoEmail"
                        placeholder = {t("Email")}
                        value={formData.newRegistrationInfoEmail}
                        OnChange={(e) => SetFormData(e)}
                    />
                </div>

                <div className="form-group">
                    <Label
                        text = {t("CancelMembershipInTrialPeriodEmail")}
                        infoContent={[t("CancelMembershipInTrialPeriodEmailInfo")]}
                    />
                    <Input
                        name="cancelMembershipInTrialPeriodEmail"
                        placeholder = {t("Email")}
                        value={formData.cancelMembershipInTrialPeriodEmail}
                        OnChange={(e) => SetFormData(e)}
                    />
                </div>

                <div className="form-group">
                    <Label
                        text = {t("CompleteQuestionnaireEmail")}
                        infoContent={[t("CompleteQuestionnaireEmailInfo")]}
                    />
                    <Input
                        name="completeQuestionnaireEmail"
                        placeholder = {t("Email")}
                        value={formData.completeQuestionnaireEmail}
                        OnChange={(e) => SetFormData(e)}
                    />
                </div>

                <div className="form-group">
                    <Label
                        text = {t("AffiliatePaidOfEmail")}
                        infoContent={[t("AffiliatePaidOfEmailInfo")]}
                    />
                    <Input
                        name="affiliatePaidOfEmail"
                        placeholder = {t("Email")}
                        value={formData.affiliatePaidOfEmail}
                        OnChange={(e) => SetFormData(e)}
                    />
                </div>

            </InputCard> 
        </>
    )
}

export default InfoEmailing;