import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { GET_RESERVATION_BLOCKED_DAYS } from "../Queries/blockedDays";
import { GET_RESERVATION_PLACES } from "../Queries/reservationPlace";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";

const UseGetAllBlockedDays = (lang) => {
    
    const client = useApolloClient();

    const [calendarKey,SetCalendarKey] = useState(0);
    const [l,SetLang] = useState("");

    const {formData,SetFormData} = UseFormHandle({
        reservationPlaceID:0
    });

    const {data,loading,error} = useQuery(GET_RESERVATION_PLACES,{
        variables:{
            lang:l,
            onlyActive:false
        },
        skip:(l == "" ? true : false),
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(lang)
            SetLang(lang)

    },[lang])

    useEffect(() => {

        if(data && data.AllReservationPlaces[0])
            SetFormData("reservationPlaceID",data.AllReservationPlaces[0].reservationPlaceID)

    },[data])

    useEffect(() => {

        RefreshCalendar();

    },[formData.reservationPlaceID])

    const GetBlockedDays = (month,year,reservationPlaceID) => {

        return new Promise(async (resolve, rejected) => {
            
            var response = await client.query({
                query:GET_RESERVATION_BLOCKED_DAYS,
                variables:{
                    reservationPlaceID,
                    month,
                    year,
                },
                fetchPolicy:"network-only"
            });

            resolve(response.data.BlockedDaysByMonth);
        });
    }

    const RefreshCalendar = () => {
        SetCalendarKey((prevValue) => {
            return prevValue + 1; 
        })
    }

    return{
        calendarKey,
        formData,
        loading,
        error,
        allReservationPlaces:data && data.AllReservationPlaces,
        SetFormData,
        GetBlockedDays,
        RefreshCalendar
    }
}

export default UseGetAllBlockedDays;