import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SERVER_URL,PRODUCT_IMAGE_URL } from "../../../Config";

const UseEditProducts = (selectedProducts) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedProducts)
        {
            const contentData = GetContentData(selectedProducts);
            SetContent(contentData);
        }

    },[selectedProducts])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("ProductName"),
            className:"flex-1 no-padding-left"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.mainPhoto ? SERVER_URL + "/" + PRODUCT_IMAGE_URL + "/p-" + val.productID + "/mala_" + val.mainPhoto : "",
                        className:"no-padding-left no-padding-right"
                    },
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID         = val.productID;
            cData.selected      = false;

            contentData.push(cData);
        }
        return contentData;
    }

    return{
        headerData,
        content
    }

}

export default UseEditProducts;