import {useQuery} from '@apollo/client';
import { GET_COVER_TITLES } from '../../Queries/coverTitles';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

const UseGetAllCoverTitles = (lang) => {

    const [variables,SetVariables] = useState({
        lang
    });

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedCoverTitleIDs,SetSelectedCoverTitleIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_COVER_TITLES,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllCoverTitles: [...prev.AllCoverTitles, ...fetchMoreResult.AllCoverTitles]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCoverTitles);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("CoverTitleName"),
            className:"flex-1"
        },
        {
            value: t("Shortcut"),
            className:"static text-center"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value: val.shortcut,
                        className:"static text-center"
                    }
                ],
            }

            cData.rowID    = val.coverTitleID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,coverTitleID) => {

        var checked = e.target.checked;
        var newSelectedCoverTitleIDs = [...selectedCoverTitleIDs];
        const newList = SelectCoverTitleID(content,coverTitleID,checked,newSelectedCoverTitleIDs)

        SetContent(newList);
        SetSelectedCoverTitleIDs(newSelectedCoverTitleIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedCoverTitleIDs = [];
        const newList = SelectCoverTitleID(content,"all",checked,newSelectedCoverTitleIDs)

        SetSelectedCoverTitleIDs(newSelectedCoverTitleIDs);
        SetContent(newList);
    }

    const SelectCoverTitleID = (list,coverTitleID,checked,selectedCoverTitleIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(coverTitleID == newList[i].rowID || coverTitleID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedCoverTitleIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedCoverTitleIDs.length; j++)
                    {
                        if(selectedCoverTitleIDs[j] == newList[i].rowID)
                            selectedCoverTitleIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectCoverTitleID(newList[i].subData,coverTitleID,checked,selectedCoverTitleIDs)}
        }

        return newList;
    }

    const UpdateListAfterAddEdit = (selectedCoverTitleID,data) => {
        
        const { AllCoverTitles } = client.readQuery({ query: GET_COVER_TITLES, variables });
        var newArray = [...AllCoverTitles];

        if(selectedCoverTitleID && selectedCoverTitleID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].coverTitleID == data.coverTitleID)
                {
                    newArray[i] = {...newArray[i],
                        name: data.name, 
                        shortcut: data.shortcut
                    };
                }
            } 
        }
        else
        {
            newArray.unshift({
                coverTitleID: data.coverTitleID,
                name: data.name,
                shortcut: data.shortcut
            })
        }
        
        client.writeQuery({ 
            query:GET_COVER_TITLES,
            variables,
            data:{
                AllCoverTitles: newArray
            } 
        });
    }

    const UpdateListAfterDelete = (IDs) => {

        var response = IDs.split(",");
        
        const { AllCoverTitles } = client.readQuery({ query: GET_COVER_TITLES, variables });
        
        var newArray = [...AllCoverTitles];
        
        for(let id of response)
        {
            for(let i in newArray)
            {
                if(newArray[i].coverTitleID == id)
                {
                    newArray.splice(i,1);
                }
            } 
        } 
        
        client.writeQuery({ 
            query:GET_COVER_TITLES,
            variables,
            data:{
                AllCoverTitles: newArray
            } 
        });
    }

    return{
        content,
        variables:variables,
        headerData,
        loading,
        error,
        selectedCoverTitleIDs,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    }

}

export default UseGetAllCoverTitles;