import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SERVER_URL,CUSTOMER_IMAGE_URL } from "../../../Config";

const UseEditReservationPlaceCustomers = (selectedCustomers) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedCustomers)
        {
            const contentData = GetContentData(selectedCustomers);
            SetContent(contentData);
        }

    },[selectedCustomers])

    var headerData = [
        {
            value: t("FullName"),
            className:"flex-1"
        },
        {
            value: t("Email"),
            className:"flex-1"
        },
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var text = val.email;
            if(val.name || val.surname)
                text = val.name + " " + val.surname;

            var cData = {
                data:[
                    {
                        elm: "lis",
                        backgroundColor: val.backgroundColor,
                        text: text,
                        photo: val.photo ? SERVER_URL + "/" + CUSTOMER_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right flex-1"
                    },
                    {
                        value:val.email,
                        className:"flex-1"
                    },
                ],
            }

            cData.rowID         = val.customerID;
            cData.deleted       = val.deleted;
            cData.selected      = false;

            contentData.push(cData);
        }
        return contentData;
    }

    return{
        headerData,
        content
    }
}

export const GetCustomerObj = (data) => {

    return{
        customerID: (data.customerID ? data.customerID : 0),
        deleted: (data.deleted ? true : false),
        added: (data.added ? true : false)
    }
}

export default UseEditReservationPlaceCustomers;