import { useApolloClient } from "@apollo/client";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GET_SELECTED_REVIEWS } from "../Queries/contentManager";
import { GetApolloErrorText,ConvertHtmlToText } from "../../../GlobalFunctions/helper";
import { SERVER_URL,REVIEW_IMAGE_URL } from "../../../Config";

const UseEditReviews = (
    lang,
    selectedReviews,
    SetFormData
) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [loading,SetLoading] = useState(false);
    const [error,SetError] = useState("");

    useEffect(() => {

        if(selectedReviews)
        {
            const contentData = GetContentData(selectedReviews);
            SetContent(contentData);
        }

    },[selectedReviews])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("Text"),
            className:"flex-1 no-padding-left"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.photo ? SERVER_URL + "/" + REVIEW_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right"
                    },
                    {
                        value:
                            <span 
                                className="cursor-help"
                                title={ConvertHtmlToText(val.text)}
                            >
                                {ConvertHtmlToText(val.text)}
                            </span>,
                        className:"flex-1 one-line-with-dots"
                    }
                ],
            }
            cData.rowID         = val.reviewID;
            cData.selected      = false;

            contentData.push(cData)
            
        }

        return contentData;
    }

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {          
            var newList = [...selectedReviews];
            const [removed] = newList.splice(result.source.index, 1);
            newList.splice(result.destination.index, 0, removed);

            SetFormData("selectedReviews",newList);
        }
    }

    const GetSelectedReviews = async (selectedReviews) => {
        
        var newSelectedReviews = [];
        if(selectedReviews && selectedReviews.length > 0)
        {
            SetLoading(true);
            
            try{
                var reviewListData = await client.query({
                    query: GET_SELECTED_REVIEWS,
                    variables:{
                        lang:lang,
                        reviewIDs: selectedReviews
                    },
                    fetchPolicy:"network-only"
                });

                if(reviewListData && reviewListData.data.AllReviewsByReviewIDs)
                {
                    for(let id of selectedReviews)
                    {
                        for(let val of reviewListData.data.AllReviewsByReviewIDs)
                        {
                            if(id == val.reviewID)
                                newSelectedReviews.push({
                                    reviewID:val.reviewID,
                                    text:val.text,
                                    photo:val.photo
                                })
                        }
                    }
                }

            }
            catch(err)
            {
                SetLoading(false);
                SetError(GetApolloErrorText(err));
            }

            SetLoading(false);
        }

        return newSelectedReviews;
    }

    return{
        headerData,
        content,
        loading,
        error,
        GetSelectedReviews,
        OnDragEnd
    }

}

export default UseEditReviews;