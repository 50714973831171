import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins,GetColClasses, GetColumnStyles } from "../../Library/functions";
import { SERVER_URL,CONTENT_MANAGER_IMAGE_URL } from "../../../../Config";
import cancelIcon from '../../../../Media/Images/Icons/cancel.webp';

const ImageGallery = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {showHelpers,indexToShowMenu,SelectElement,RemoveElement} = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var className = "cm-column-elements cm-d-flex cm-flex-column cm-justify-content-center";

    const margins    = GetElementMargins(data);
    const colClasses = GetColClasses(data);
    const stylesData = GetColumnStyles(data);

    className += " " + stylesData.styleClassName;

    const borderRadiusStyle = {borderRadius:data.borderRadius + "px"};

    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "imageGallery"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div 
                className={data.className + " " + margins.styleClassName} 
                style={margins.styles}
            >
                {data.images && data.images.length > 0 ?
                    <div className="cm-row cm-wrap">
                        {data.images.map((item,index) => (
                            <div 
                                key={index} 
                                className = {"cm-col " + colClasses}
                            >
                                <div className = {className} style={stylesData.styles}>
                                    <div className={"cm-image-content"}>
                                        <div className="cm-menu">
                                            <div 
                                                className="cm-button"
                                                onClick = {() => RemoveElement(lastIndexes + "-" + index,"imageGalleryImage")}
                                            >
                                                <img src={cancelIcon} />
                                            </div>
                                        </div>
                                        <img style={borderRadiusStyle} src = {SERVER_URL + "/" + CONTENT_MANAGER_IMAGE_URL + "/" + data.imageSize + "_" + item} alt="" />
                                        
                                    </div>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                :
                    <div className="cm-start-info">Pro vložení fotogalerie použijte ikonku ozubeného kolečka "Nastavení"</div>
                }
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default ImageGallery;