import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllExchangeRates = (allCurrencies,EditCurrency) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(allCurrencies)
        {
            const contentData = GetContentData(allCurrencies);
            SetContent(contentData);
        }

    },[allCurrencies])

    var headerData = [
        {
            value: t("Currency"),
            className:"static smaller"
        },
        {
            value: t("StoredExchangeRateInDatabaseAgainst") + " " + (allCurrencies && allCurrencies.length > 0 ? allCurrencies[0].code : ""),
            className:"static bigger text-center"
        },
        {
            value: t("CurrentExchangeRateInTheWorld"),
            className:"static bigger text-center"
        }         
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var data = [
                {
                    value:val.code,
                    className:"static smaller"
                },
                {
                    elm:"input",
                    OnChange: (e) => EditCurrency(val.currencyID,e.target.value),
                    name: "exchangeRate",
                    param: "exchangeRate",
                    type: "number",
                    className:"static bigger text-center"
                },
                {
                    value:val.worldExchangeRate,
                    className:"static bigger text-center"
                },
                
            ];

            var cData = {data}

            cData.exchangeRate      = val.exchangeRate;
            cData.rowID             = val.currencyID;
            cData.selected          = false;

            contentData.push(cData)
        }
        return contentData;
    }

    return{
        content,
        headerData
    }

}

export default UseGetAllExchangeRates;