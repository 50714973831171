import gql from 'graphql-tag';

export const EDIT_SETTINGS = gql`
    mutation SaveSettings(
        $data:SettingsInput
    ){
        SaveSettings(
            data:$data
        )
    }
`;

export const GET_SETTINGS = gql`
    query Settings{
        Settings{
            email
            emailName
            sendgridApiKey
            adminUrl  
            emailForTesting
            useThirdPartyForTransaltion
            openAiApiKey
            adminVersion
        }
    }
`;