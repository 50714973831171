const Eshop = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <polygon style={{"fill":secondaryColor}}  points="468.431,244.308 473.511,222.308 412.434,222.308 409.531,244.308  "/>
                    <polygon style={{"fill":secondaryColor}}  points="387.34,244.308 390.244,222.308 324.11,222.308 324.11,244.308  "/>
                    <polygon style={{"fill":secondaryColor}}  points="216.588,244.308 213.661,222.308 146.248,222.308 152.178,244.308  "/>
                    <polygon style={{"fill":secondaryColor}}  points="302.11,244.308 302.11,222.308 235.854,222.308 238.781,244.308  "/>
                    <path style={{"fill":primaryColor}}  d="M506.253,133.926c-5.042-6.366-12.592-10.036-20.713-10.068l-366.226-1.477l-18.925-70.215   C96.551,37.986,83.61,28.083,68.92,28.083H11c-6.075,0-11,4.925-11,11s4.925,11,11,11h57.92c4.777,0,8.985,3.22,10.23,7.82   l69.959,259.56c0.315,1.163,0.694,2.296,1.121,3.402l-3.898-0.011c-11.778-0.057-22.775,4.559-31.027,12.931   c-8.252,8.373-12.684,19.458-12.479,31.212c0.41,23.506,19.871,42.63,43.381,42.63h29.545c-5.178,7.784-8.203,17.116-8.203,27.146   c0,27.099,22.047,49.146,49.146,49.146s49.146-22.047,49.146-49.146c0-10.029-3.026-19.362-8.204-27.146h110.938   c-5.174,7.782-8.198,17.111-8.198,27.137c0,27.099,22.047,49.146,49.146,49.146c27.1,0,49.146-22.047,49.146-49.146   c0-10.025-3.024-19.355-8.198-27.137h2.032c6.075,0,11-4.925,11-11s-4.925-11-11-11H146.208c-11.589,0-21.182-9.427-21.384-21.014   c-0.101-5.794,2.083-11.258,6.151-15.386c4.068-4.128,9.508-6.396,15.294-6.374l295.053,0.104c15.842,0,29.396-10.78,32.96-26.216   l37.029-160.349C513.139,148.48,511.295,140.291,506.253,133.926z M243.842,434.773c0,14.968-12.178,27.146-27.146,27.146   s-27.146-12.178-27.146-27.146c0-14.956,12.157-27.125,27.108-27.146h0.077C231.685,407.648,243.842,419.817,243.842,434.773z    M436.67,434.764c0,14.968-12.178,27.146-27.146,27.146c-14.968,0-27.146-12.178-27.146-27.146c0-14.846,11.981-26.94,26.78-27.137   h0.731C424.69,407.824,436.67,419.918,436.67,434.764z M324.11,320.958V145.207l76.269,0.308l-23.154,175.443H324.11z    M248.98,320.958L225.543,144.81l76.567,0.309v175.839H248.98z M170.349,311.725l-45.098-167.32l78.087,0.315l23.449,176.238   h-44.33l-0.034,0C176.785,320.957,171.819,317.156,170.349,311.725z M489.876,151.442l-37.029,160.35   c-1.246,5.397-5.985,9.166-11.524,9.166h-41.908l23.143-175.354l62.896,0.254c1.908,0.008,3.047,1.086,3.556,1.728   S490.306,149.583,489.876,151.442z"/>
                </g>
            </svg>
        </>
    )
}

export default Eshop;
