import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_FAQ,GENERATE_FAQ_LANG } from "../Queries/faq";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";

const UseAddEditFaq = (projectID,selectedFaqID,mainLang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();
        
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj,
        OpenImage
    } = UseFormHandle({
        selectedLang:mainLang,
        active:1,
        langs:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_FAQ_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditFaqMutation,{error,loading}] = useMutation(ADD_EDIT_FAQ,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    faqID: d.AddEditFaq.faqID,
                    isEdit: (selectedFaqID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditFaq);
                
                const notifyText = (selectedFaqID != 0 ? t("SuccessfullyUpdated") : t("NewFaqIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("FaqHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditFaq = () => {

        var checkQuestion = true;
        var checkAnswer = true;
        let langs = [];

        for(const lang of formData.langs)
        {
            if(lang.lang == formData.selectedLang && !lang.question)
            {
                checkQuestion = false;
                break;
            }

            if(lang.lang == formData.selectedLang && !lang.answer)
            {
                checkAnswer = false;
                break;
            }

            langs.push({
                question: lang.question,
                answer:   lang.answer,
                lang:     lang.lang
            });
        }

        if(checkQuestion)
        {
            if(checkAnswer)
            {
                AddEditFaqMutation({
                    variables:{
                        faqID:                  selectedFaqID,
                        projectID:              projectID,
                        active:                 parseInt(formData.active),
                        langs:                  langs,
                        generateTranslations:   formData.generateTranslations
                    }
                })
            }
            else
                AddNotification(t("NoAnswerNoGo"),false); 
        }
        else
            AddNotification(t("NoQuestionNoGo"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditFaq,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj,
        OpenImage
    }

}

export default UseAddEditFaq;