import gql from 'graphql-tag';

export const GET_PORTAL_MODULES = gql`
    query AllPortalModulesBy{
        AllPortalModulesBy(
            param:"parentPortalModuleID",
            value:0
        ){
            portalModuleID
            moduleID
            label
            link
        }
    }
`;
