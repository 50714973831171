import { useState, useEffect } from "react";
import arrow from '../Media/Images/Icons/arrow.svg';
import SelectBoxItems from "./SelectBoxItems";

const SelectBox = ({items,value,nameParam,valueParam,subParam,photoParam,photoUrl,OnChange}) => {

    const [showItems, SetShowItems]       = useState(false);
    const [selectedItem, SetSelectedItem] = useState({})

    useEffect(() => {

        if(value && items && items.length > 0)
        {
            GetSelectedItem(items,value);  
        }
        else if(items && items.length > 0)
        {
            SetSelectedItem(items[0]);
            OnChange(items[0][valueParam]);
        }

    },[items,value])

    const GetSelectedItem = (items,value) => {
        for(let val of items)
        {
            if(val[valueParam] == value)
            {
                SetSelectedItem(val);
                break;
            }
            if(val[subParam] && val[subParam].length > 0)
            {
                GetSelectedItem(val[subParam],value);
            }
        } 
    }

    const OnSelect = (item) => {
        OnChange(item[valueParam]);
        SetSelectedItem(item);
    }

    return(
        <div className="input-group select-box" onClick={() => SetShowItems(!showItems)}>
            <div className="value">
                {photoParam && photoUrl ?
                    <img className="item-img" src = {photoUrl + selectedItem[photoParam]} />
                :null}
                {selectedItem[nameParam]}
                <img src={arrow} alt="" />
            </div>
            {showItems ?
                <>
                    <SelectBoxItems 
                        items = {items} 
                        nameParam = {nameParam} 
                        subParam  = {subParam}
                        OnSelect = {OnSelect} 
                        photoParam = {photoParam}
                        photoUrl = {photoUrl}
                    />
                    <div className="overlay" onClick={() => SetShowItems(false)}></div>
                </>
            :null}
        </div>
    )
}

export default SelectBox;