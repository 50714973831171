import { useState } from "react";
import { DELETE_BILLING_INFORMATIONS } from "../Queries/billingInformation";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const UseDeleteBillingInformations = (selectedItems,OnCompleted) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);

    const [DelBillingInformations] = useMutation(DELETE_BILLING_INFORMATIONS,{
        onCompleted:(d) => {

            AddNotification(t("TheyAreGone"),true);
            if(OnCompleted)
                OnCompleted(d.DeleteBillingInformations);
        },
        onError:(err) => {    
            
            var error = GetApolloErrorText(err);
            if(error == "Unexpected error value: \"BILLING_INFORMATION_IS_USED\"")
                error = t("BillingInformationIsUsed");

            AddNotification(error,false);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            AddNotification(t("NoSelectedRows"),false);
        }
    }

    const DeleteBillingInformations = (action) => {

        if(action)
        {              
            DelBillingInformations({
                variables:{
                    billingInformationIDs:selectedItems.join(",")
                }
            })
        }

        SetShowDeleteNotification(false);
    }

    return{
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteBillingInformations
    }
}

export default UseDeleteBillingInformations;