
/**
 * soubor se seznamem veškerých graphql dotazů pro tinyMCE
 */
import gql from 'graphql-tag';

export const GET_TINYMCE_IMAGES = gql`
    query AllTinyMCEImages {
        AllTinyMCEImages{
            name
            imageID
        }
    }
`;

export const UPLOAD_TINYMCE_IMAGES = gql`
    mutation UploadTinyMCEImages($files:[Upload]!) {
        UploadTinyMCEImages(files:$files){
            name
            imageID
        }
    }
`;

/**
 * smazání produktových fotek
 */
export const DELETE_TINYMCE_IMAGES = gql`
    mutation DeleteTinyMCEImages($imagesID:[ID]!) {
        DeleteTinyMCEImages(imagesID:$imagesID)
    }
`;

export const GET_TINYMCE_FILES = gql`
    query AllTinyMCEFiles {
        AllTinyMCEFiles{
            name
            fileID
            type
        }
    }
`;

export const UPLOAD_TINYMCE_FILES = gql`
    mutation UploadTinyMCEFiles($files:[Upload]!) {
        UploadTinyMCEFiles(files:$files){
            name
            fileID
            type
        }
    }
`;

/**
 * smazání produktových fotek
 */
export const DELETE_TINYMCE_FILES = gql`
    mutation DeleteTinyMCEFiles($filesID:[ID]!) {
        DeleteTinyMCEFiles(filesID:$filesID)
    }
`;