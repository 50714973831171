import { useQuery } from "@apollo/client";
import { GET_SETTINGS } from "../../Queries/settings";

const UseGetWebSettings = (CallBack) => {

    const {loading,error} = useQuery(GET_SETTINGS,{
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            CallBack({
                email:d.Settings.email,
                emailName:d.Settings.emailName,
                sendgridApiKey:d.Settings.sendgridApiKey,
                adminUrl:d.Settings.adminUrl,
                emailForTesting:d.Settings.emailForTesting
            });
        }
    });

    return{
        loading,
        error,
    }

}

export default UseGetWebSettings;