import { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditCampaign from '../Library/UseAddEditCampaign';
import UseGetCampaign from '../Library/UseGetCampaign';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseAuth } from '../../Auth/Library/UseAuth';
import BasicInformations from './BasicInformations';
import { useApolloClient } from '@apollo/client';
import CampaignEmails from './CampaignEmails';
import CampaignContacts from './CampaignContacts';

Modal.setAppElement('#root');

const AddEditCampaign = ({projectID,selectedCampaignID,SetSelectedCampaignID}) => {

    const {t} = useTranslation();
    const {user} = UseAuth();
    const client = useApolloClient();

    const [selectedTab, SetSelectedTab] = useState(1);
    
    const {
        loading,
        error,
        formData,
        AddEditCampaign,
        InitFormData,
        SetFormData,
        FillFormSelectedItemsWithObj,
        RemoveFormDataSelectedItems
    } = UseAddEditCampaign(selectedCampaignID,projectID,(d) => OnAddEditSuccess(d));

    const {
        loading:getLoading,
        error:getError,
        allLinks
    } = UseGetCampaign(selectedCampaignID,user.lang,projectID,(d) => {
        InitFormData(d);
    });

    const OnAddEditSuccess = async (d) => {
        await client.refetchQueries({
            include: ["AllCampaigns"],
        });

        if(selectedCampaignID)
            SetSelectedCampaignID(null);
        else
        {
            SetSelectedCampaignID(d.campaignID);
            SetSelectedTab(2);
        } 
    }

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedCampaignID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {selectedCampaignID === 0 ? t("AddCampaign") : t("EditCampaign")}
                OnClose ={() => SetSelectedCampaignID(null)} 
            />

            <div className="modal-body">

                <ul className="tabs">
                    <li 
                        className={(selectedTab == 1 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(1)}
                    >{t("BasicInformations")}</li>
                    <li 
                        className={(selectedTab == 2 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(2)}
                    >{t("Emails")}</li>

                    {formData.useEmailsFromNewsletter == 0 ?
                        <li 
                            className={(selectedTab == 3 ? "selected" : "")} 
                            onClick = {() => SetSelectedTab(3)}
                        >{t("Contacts")}</li>
                    :null}
                </ul>


                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        :  
                            <>
                                {selectedTab == 1 ?
                                    <BasicInformations
                                        formData = {formData}
                                        allLinks = {allLinks}
                                        SetFormData = {SetFormData}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                        RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                    />
                                :null}

                                {selectedTab == 2 ?
                                    <CampaignEmails
                                        selectedCampaignID = {selectedCampaignID}
                                        projectID = {projectID}
                                        formData = {formData}
                                        SetFormData = {SetFormData}
                                    />
                                :null}   

                                {selectedTab == 3 ?
                                    <CampaignContacts
                                        selectedCampaignID = {selectedCampaignID}
                                        formData = {formData}
                                        SetFormData = {SetFormData}
                                    />
                                :null}                      
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditCampaign()}
                >
                    {(selectedCampaignID === 0 ? t("Add") : t("Edit"))}
                </Button>
              
            </div>

        </Modal>
    )
}

export default AddEditCampaign;