import { useRef } from "react";
import { useState,useEffect } from "react";
import Arrow from "../../../../../GlobalComponents/Arrow";

const Review3dCarousel = (props) => {

    const carouselItems = useRef([]);
    var selectedIndex = useRef(0);
    const {data} = props;

    const [width,SetWidth] = useState(500);
    const [height,SetHeight] = useState(600);

    useEffect(() => {

        OnResize();
        document.addEventListener("resize",OnResize);

        SetCarouselHeight();
        
        return () => {
            document.removeEventListener("resize",OnResize);
        }

    },[]);

    const [carouselData, SetCarouselData] = useState({
      style: {},
      angle: 0,
    });

    const SetCarouselHeight = () => {

        if(carouselItems.current.length > 0)
        {
            var height = 0;
            for(let val of carouselItems.current)
            {
                if(val.clientHeight > height)
                    height = val.clientHeight;
            }

            SetHeight(height);
        }
    }

    const OnResize = () => {

        if(window.innerWidth > 1200 && width != 500){
          SetWidth(500);
        }
        if(window.innerWidth <= 1200 && window.innerWidth > 991 && width != 400){
          SetWidth(400);
        }
        if(window.innerWidth <= 991 && window.innerWidth > 767 && width != 350){
          SetWidth(350);
        }
        if(window.innerWidth <= 767 && window.innerWidth > 575 && width != 250){
          SetWidth(200);
        }
        if(window.innerWidth <= 575 && width != 150){
          SetWidth(150);
        }

        SetCarouselHeight();
    }


    function rotateCarousel() {
        var angle = (selectedIndex.current / data.length) * -360;

        SetCarouselData({
          ...carouselData,
          style: { transform: "translateZ(-" + width + "px) rotateY(" + angle + "deg)" },
          angle: angle,
        });
    }

    const RotateToPrev = () => {
      selectedIndex.current--;
      rotateCarousel();
    };
    const RotateToNext = () => {
      selectedIndex.current++;
      rotateCarousel();
    };

    return (
        <>
            <div 
              style = {{height:height + "px"}}
              className="carousel3D-container"
            >
                <div style={carouselData.style} className="carousel3D-content">

                    {data.map((item, index) => {

                        var newIndex = selectedIndex.current;
                        if (selectedIndex.current >= data.length - 1) {
                          newIndex = selectedIndex.current % data.length;
                        }
                        if(selectedIndex.current < 0)
                        {
                            var reversIndex = data.length + selectedIndex.current;
                            if(reversIndex < 0)
                            {
                              var moduloIndex = ((-1)*selectedIndex.current) % data.length;
                              newIndex =  (moduloIndex == 0 ? moduloIndex : data.length - moduloIndex);
                            }
                            else
                              newIndex = reversIndex;
                        }

                        var leftIndex = newIndex - 1;
                        if (leftIndex < 0) leftIndex = data.length - 1;

                        var rightIndex = newIndex + 1;
                        if (rightIndex > data.length - 1) rightIndex = 0;

                        var cellStyle = {
                          transform:
                            "rotateY(" + (index / data.length) * 360 + "deg) translateY(-50%) translateZ(" + width + "px)",
                        };

                        var a = (index / data.length) * -360 - carouselData.angle;
                        var imgStyle = { transform: "rotateY(" + a + "deg)" };

                        imgStyle.filter = "blur(3px)";

                        if (leftIndex == index) {
                          imgStyle.transform = "rotateY(" + a + "deg)";
                          imgStyle.filter = "blur(1px)";
                        }

                        if (rightIndex == index) {
                          imgStyle.transform = "rotateY(" + a + "deg)";
                          imgStyle.filter = "blur(1px)";
                        }

                        var textStyle = { ...imgStyle };

                        if (newIndex == index) {
                          imgStyle.filter = "blur(0px)";
                          textStyle.filter = "blur(0px)";
                        }

                        if(props.borderRadiusStyle)
                          imgStyle = {...imgStyle,...props.borderRadiusStyle};

                        return (
                            <div 
                                ref={(elm) => carouselItems.current[index] = elm}
                                key={index} 
                                style={cellStyle} 
                                className="carousel3D-cell"
                            >
                                <div 
                                  className={"carousel3D-item-content" + (!data[index].photo ? " no-photo" :"")} 
                                  style={imgStyle}
                                >

                                    {data[index].photo ?
                                      <div 
                                        className="img"
                                        style = {props.borderRadiusStyle}
                                      >
                                        <img 
                                            src={data[index].photo} 
                                            style = {props.borderRadiusStyle}
                                        />
                                      </div>
                                    :null}

                                    <div 
                                        className="carousel3D-item-text" 
                                        dangerouslySetInnerHTML={{__html:data[index].text}}
                                    >
                                    </div>
                                    {data[index].signature ?
                                      <div 
                                          
                                          className="carousel3D-item-signature"
                                          dangerouslySetInnerHTML={{__html:data[index].signature}}
                                      >
                                      </div>
                                    :null}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Arrow
                    color = {props.color}
                    className="arrow-left"
                    size = {"big"}
                    OnClick={() => RotateToPrev()}
                />
                <Arrow
                  color = {props.color}
                  className="arrow-right"
                  size = {"big"}
                  OnClick={() => RotateToNext()}
                />
            </div>
        </>
    );
};

export default Review3dCarousel;
