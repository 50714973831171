import ListRow from "./ListRow";
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import Button from "../Button";

const ListSortableContent = ({
    index,
    rowClassname,
    addRowButtonTitle,
    AddRowButtonOnClick,
    data,
    options,
    level,
    isSortable,
    hideOptions,
    optionsClassName,
    OnDragEnd,
    replaceDotsImg,
    ReplaceDotsOnClick
}) => {
    return(
        <DragDropContext onDragEnd={(result) => OnDragEnd(result)}>
            <Droppable droppableId={"droppable"}>
                {(provided,_) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {data && data.map((item,ind) => {

                            if(item.deleted)
                                return (
                                    <Draggable 
                                        key={(item.rowID != 0 ? item.rowID : ind) + (item.parentID ? "-" + item.parentID : "-0")} 
                                        draggableId={(item.rowID != 0 ? item.rowID : ind) + (item.parentID ? "-" + item.parentID : "-0")} 
                                        index={ind}
                                    >
                                        {(provided,_) => (
                                            <div className="data-list-item" 
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <div {...provided.dragHandleProps} ></div>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            else
                                return(
                                    <Draggable 
                                        key={(item.rowID != 0 ? item.rowID : ind) + (item.parentID ? "-" + item.parentID : "-0")} 
                                        draggableId={(item.rowID != 0 ? item.rowID : ind) + (item.parentID ? "-" + item.parentID : "-0")} 
                                        index={ind}
                                    >
                                        {(provided,_) => (
                                            <div className="data-list-item" 
                                                ref={provided.innerRef}
                                                {...provided.draggableProps} 
                                            >
                                                <ListRow 
                                                    rowClassname = {rowClassname}
                                                    index = {ind}
                                                    parentIndex = {index}
                                                    key={ind} 
                                                    data = {item} 
                                                    options = {options} 
                                                    hideOptions = {hideOptions}
                                                    optionsClassName = {optionsClassName}
                                                    isSortable = {isSortable}
                                                    level = {level}
                                                    provided = {provided}
                                                    replaceDotsImg = {replaceDotsImg}
                                                    ReplaceDotsOnClick = {ReplaceDotsOnClick}
                                                />
                                                {item.subData && item.subData.length > 0 ?
                                                    <ListSortableContent 
                                                        rowClassname = {rowClassname}
                                                        addRowButtonTitle = {addRowButtonTitle}
                                                        AddRowButtonOnClick = {AddRowButtonOnClick}
                                                        data = {item.subData} 
                                                        options = {options} 
                                                        hideOptions = {hideOptions}
                                                        optionsClassName = {optionsClassName}
                                                        isSortable = {isSortable}
                                                        level = {level + 1}
                                                        OnDragEnd = {OnDragEnd}
                                                        replaceDotsImg = {replaceDotsImg}
                                                        ReplaceDotsOnClick = {ReplaceDotsOnClick}
                                                        index = {ind}
                                                    />
                                                :null}
                                            </div>
                                        )}
                                    </Draggable>
                                )
                        })}
                        {provided.placeholder}

                        {addRowButtonTitle && AddRowButtonOnClick ?
                            <div className={"list-row"}>
                                <div style={{marginRight : (level === 1 ? 0 : (level - 1) * 1.5) + "rem"}}></div>
                                <Button
                                    className="btn-primary narrow"
                                    OnClick={() => AddRowButtonOnClick(level,index)}
                                >{addRowButtonTitle}</Button>
                            </div>
                        :null}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default ListSortableContent;