import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MARKETING_BANNER_IMAGE_URL,SERVER_URL } from "../../../Config";

const UseEditArticleMarketingBanners = (
    selectedMarketingBanners,
    selectedArticleID,
    SetFormData
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedMarketingBanners)
        {
            const contentData = GetContentData(selectedMarketingBanners);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[selectedMarketingBanners])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("Name"),
            className:"flex-1"
        }
    ];   

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.photo ? SERVER_URL + "/" + MARKETING_BANNER_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
                
            }

            cData.rowID             = val.marketingBannerID;
            cData.selected          = false;
            cData.selectedArticleID = selectedArticleID

            contentData.push(cData)
            
        }
        return contentData;
    }

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {          
            var newList = [...selectedMarketingBanners];
            const [removed] = newList.splice(result.source.index, 1);
            newList.splice(result.destination.index, 0, removed);

            SetFormData("selectedMarketingBanners",newList);
        }
    }

    return{
        headerData,
        content,
        OnDragEnd
    }

}

export default UseEditArticleMarketingBanners;