import { useContext, createContext, useState,useEffect } from 'react';
import UseFormHandle from '../../../GlobalHooks/UseFormHandle';
import { UseAuth } from '../../Auth/Library/UseAuth';
import { SECTION,COLUMN,TAB } from './elements';
import { GetEventData } from './functions';
import { GET_LINK_CONTENT, SAVE_LINK_CONTENT,GET_LANG_DATA } from '../Queries/contentManager';
import { useMutation,useQuery,useApolloClient } from '@apollo/client';
import { UseNotification } from '../../Notification/Library/UseNotification';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';

const contentManagerContext = createContext();

export const ContentManagerProvider = ({children}) => {

    const cm = UseProviderContentManager();

    return(
        <contentManagerContext.Provider value={cm}>
            {children}
        </contentManagerContext.Provider>
    )
}

export const UseContentManager = () => {
    return useContext(contentManagerContext);
}

const UseProviderContentManager = () => {

    const FILE_MANAGER_INIT = {
        element:"",
        type:0,
        index:"",
        CallBack:null
    }

    const client = useApolloClient();

    const {user} = UseAuth();
    const {AddNotification} = UseNotification();

    const [windowWidth, SetWindowWidth]                         = useState(0);
    const [contentManagerLinkID, SetContentManagerLinkID]       = useState(null);
    const [contentManagerProjectID, SetContentManagerProjectID] = useState(null);
    const [showHelpers, SetShowHelpers]                         = useState(true);
    const [indexToShowMenu, SetIndexToShowMenu]                 = useState(null);
    const [showSettingsElement, SetShowSettingsElement]         = useState(null);
    const [openFileManagerData, SetOpenFileManagerData]         = useState(FILE_MANAGER_INIT);
    const [isContentChanged, SetIsContentChanged]               = useState(false);
    const [showCloseContentWarning, SetShowCloseContentWarning] = useState(false);
    const [projectData, SetProjectData]                         = useState(null);
    const [indexToAddContent, SetIndexToAddContent]             = useState(null);
    const [selectedLangData, SetSelectedLangdata]               = useState(null);

    const {formData,SetFormData,SetNewFormdata} = UseFormHandle({
        userLang:user.lang,
        selectedLang:user.lang,
        langForCopyContent:user.lang,
        content:{}
    });

    useEffect(() => {

        if(user.lang)
        {
            SetNewFormdata((prevValue) => {
                return {
                    ...prevValue,
                    "userLang": user.lang,
                    "selectedLang": user.lang,
                    "langForCopyContent": user.lang
                }
            });  

            SetLangData(user.lang);
        }

    },[user.lang])

    useEffect(() => {
        SetWindowWidth(window.innerWidth);
        window.addEventListener("resize",ResizeWindow,{passive: true});
        return () => {
            window.removeEventListener("resize",ResizeWindow,{passive: true});
        }
    },[]);

    const {data,loading,error} = useQuery(GET_LINK_CONTENT,{
        fetchPolicy:'network-only',
        variables:{
            linkID: contentManagerLinkID,
            projectID:contentManagerProjectID
        },
        skip:(contentManagerLinkID == null),
        onCompleted:(d) => {

            if(d.AllLinkLangs && d.AllLinkLangs.length > 0)
            {
                var content = {};
                for(let val of d.AllLinkLangs)
                {
                    if(val.content)
                        content[val.lang] = JSON.parse(val.content);
                    else
                    {
                        content[val.lang] = {
                            sections:[SECTION]    
                        }
                    }
                }

                SetFormData("content",content);
            }

            if(d.ProjectBy)
                SetProjectData(d.ProjectBy);
        }
    });

    const [SaveLinkContent] = useMutation(SAVE_LINK_CONTENT,{
        onCompleted:(d) => {
            SetIsContentChanged(false);
            AddNotification("Uloženo, máš to v suchu :)",true);
        },
        onError:(err) => {
            
            err = GetApolloErrorText(err);
            AddNotification(err,false);
        }
    });

    const SetFormDataContent = (arr) => {

        if(!isContentChanged)
            SetIsContentChanged(true);

        SetFormData("content",
            {...formData.content,
                [formData.selectedLang]:{
                    ...formData.content[formData.selectedLang],
                    sections:arr
                }
            }
        );
    }

    const ResizeWindow = () => {
        SetWindowWidth(window.innerWidth);
    }

    const ClearSelection = () => {
        SetShowSettingsElement(null);
        SetIndexToShowMenu(null);
    }

    const SetLangData = (lang) => {
        return new Promise(async (resolve,rejected) => {

            try
            {
                const {data} = await client.query({
                    query:GET_LANG_DATA,
                    variables:{
                        lang
                    },
                    fetchPolicy:"network-only"
                })

                SetSelectedLangdata(data.LanguageMutation);
                resolve(data.LanguageMutation);
            }
            catch(err)
            {
                rejected(err);
            }
        })
    }

    const SaveContent = () => {

        var data = [];
        for(let i in formData.content)
        {
            data.push({
                lang:i,
                content: JSON.stringify(formData.content[i]),
            })
        }

        SaveLinkContent({
            variables:{
                linkID:contentManagerLinkID,
                data
            }
        })
    }

    const CheckContentManagerBeforeClose = () => {
        if(isContentChanged)
            SetShowCloseContentWarning(true);
        else
        {
            ClearSelection();
            SetContentManagerLinkID(null);
            SetFormData("selectedLang",user.lang);
        }
    }

    const CloseContentManager = (act) => {
        
        if(act)
        {  
            ClearSelection();
            SetContentManagerLinkID(null);
            SetFormData("selectedLang",user.lang);
        }

        SetShowCloseContentWarning(false);
    }

    const AddSection = (index) =>
    {
        var arr = [...formData.content[formData.selectedLang].sections];
        arr.splice(index + 1, 0, SECTION);

        SetFormDataContent(arr);
    }

    const GetObjParamsOfElementWithAddContent = (element) =>
    {
        var firstParam = "columns";
        var secondParam = "cols";

        if(element.tabs)
        {
            firstParam = "tabs";
            secondParam = "tabs";
        }

        return {firstParam,secondParam}
    }

    const SelectElement = (e,index,stopPropagation) => {

        if(e)
            e.preventDefault();
        
        if(stopPropagation)
            e.stopPropagation();

        SetIndexToShowMenu(index);
    }

    const GetSectionSettings = () => {
        return formData.content[formData.selectedLang].sections[showSettingsElement.index];
    }

    const SetSectionSettings = (index,e,val) => {

        var {n,v} = GetEventData(e,val);

        var arr = [...formData.content[formData.selectedLang].sections];
        arr[index] = {...arr[index],[n]:v};

        SetFormDataContent(arr);
    }

    const CopyLangContent = () => {

        var content = {...formData.content[formData.langForCopyContent]};

        SetFormData("content",
            {...formData.content,
                [formData.selectedLang]:content
            }
        );

        if(!isContentChanged)
            SetIsContentChanged(true);
    }

    /**
     * Odstranení sekce
     * @param {*} index index sekce která se odstraňuje (index je string několika čísel oddělených spojovníkem)
     */
    
    const RemoveSection = (index) => {

        var arr = [...formData.content[formData.selectedLang].sections];
        arr.splice(index,1);

        SetFormDataContent(arr);
    }

    /**
     * Změna pozice sekce
     * @param {*} newPosition nová pozice sekce
     * @param {*} oldPosition stará pozice sekce
     */

    const ChangeSectionPosition = (newPosition,oldPosition) => {

        var arr = [...formData.content[formData.selectedLang].sections];

        const section = arr[oldPosition - 1];

        arr = [...arr.slice(0,oldPosition - 1),...arr.slice(oldPosition)];
        arr = [...arr.slice(0,newPosition - 1),section,...arr.slice(newPosition-1)];

        SetFormDataContent(arr);
 
    }

    /**
     * Kopírování sekce
     * @param {*} index index kopírované sekce
     */

    const CopySection = (index) => {

        var arr = [...formData.content[formData.selectedLang].sections];

        const section = arr[index];

        var newArr = [...arr.slice(0,index),section,section,...arr.slice(index + 1)];

        SetFormDataContent(newArr);
 
    }

    /**
     * Přidání elementu
     * @param {*} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
     * @param {*} element element, který se přidává
     */

    const AddElement = (index,element) =>
    {
        index = index.split("-");

        var arr = [...formData.content[formData.selectedLang].sections];

        if(index.length == 1)
        {   
            arr[index[0]] = {...arr[index[0]],elements:[...arr[index[0]].elements,element]};
            SetIndexToShowMenu(index[0] + "-" + (arr[index[0]].elements.length - 1));
        }
        else
            arr[index[0]] = AddElementRecursively(index,arr[index[0]],1,element);

        SetFormDataContent(arr);
        
        SetIndexToAddContent(null);
    }

    /**
     * POkud se element přidává do zanořeých sloupců, tak pro zanořování se používá tato metoda
     * @param {*} index index sekce nebo sloupce, kde se element vkládá (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @param {*} element element, který se přidává
     */

    const AddElementRecursively = (index,arrPrevious,count,element) => {

        var arr  = [...arrPrevious.elements];

        const {firstParam,secondParam} = GetObjParamsOfElementWithAddContent(arr[index[count]])
        
        var ar   =  [...arr[index[count]][firstParam][secondParam]];

        if((count + 2) == index.length)
        {
            ar[index[count + 1]] = {
                ...ar[index[count + 1]],
                elements:[
                    ...ar[index[count + 1]].elements, 
                    element
                ]
            };
            arr[index[count]] = {
                ...arr[index[count]],
                [firstParam]:{
                    ...arr[index[count]][firstParam],
                    [secondParam]:ar
                }
            };

            SetIndexToShowMenu(index.join("-") + "-" + (ar[index[count + 1]].elements.length - 1));

            return {...arrPrevious,elements:arr};
        }
        else
        {
            ar[index[count + 1]] = AddElementRecursively(index,ar[index[count + 1]],count + 2,element);
            arr[index[count]] = {
                ...arr[index[count]],
                [firstParam]:{
                    ...arr[index[count]][firstParam],
                    [secondParam]:ar
                }
            };

            return {...arrPrevious,elements:arr};
        }
    }

    /**
     * Kopírování elementu
     * @param {*} index index elementu který se má kopírovat (index je string několika čísel oddělených spojovníkem)
     */

    const CopyElement = (index,element) => {

        index = index.split("-");

        var count = 0;
        if(element === "column" || element === "tab")
            count = 1;

        var arr = [...formData.content[formData.selectedLang].sections]; 
        arr = CopyElementRecursively(index,arr,count,element);

        SetFormDataContent(arr);
    }

    /**
     * Rekurentně zkopíruje element
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole, které se naplňuje
     * @param {*} c počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     */
    

    const CopyElementRecursively = (index,arrPrevious,count,element) => {

        var c = count;
        if(element === "column" || element === "tab")
            c = count - 1;

        var arr = [...arrPrevious];
        
        const {firstParam,secondParam} = GetObjParamsOfElementWithAddContent(arrPrevious[index[c]].elements[index[c + 1]]);
        
        if((count + 2) == index.length)
        {
            if(element === "column" || element === "tab")
            {
                var ar = [...arrPrevious[index[c]].elements];
                var a = [...arrPrevious[index[c]].elements][index[c + 1]][firstParam][secondParam];

                var newCol = arrPrevious[index[c]].elements[index[c + 1]][firstParam][secondParam][index[c + 2]];
                var cols = [
                    ...a.slice(0,index[c + 2]),
                    newCol,
                    {...newCol},
                    ...a.slice(parseInt(index[c + 2]) + 1)
                ];

                ar[index[c + 1]] = {...ar[index[c + 1]],[firstParam]:{...ar[index[c + 1]][firstParam],[secondParam]:cols}};
                arr[index[c]] = {...arr[index[c]],elements: ar};

                return arr;
            }
            else
            {
                var elm = arrPrevious[index[c]].elements[index[c + 1]][element];
                var elm2 = {...arrPrevious[index[c]].elements[index[c + 1]][element]};
                
                var ar  = [
                    ...arrPrevious[index[c]].elements.slice(0,index[c + 1]),
                    {[element]:elm},
                    {[element]:elm2},
                    ...arrPrevious[index[c]].elements.slice(parseInt(index[c + 1]) + 1)
                ];

                arr[index[c]] = {...arr[index[c]],elements: ar};

                return arr;
            }
        }
        else
        {
            var ar  = [...arrPrevious[index[c]].elements];
            var a   = [...arrPrevious[index[c]].elements[index[c + 1]][firstParam][secondParam]];

            ar[index[c + 1]] = {
                ...ar[index[c + 1]],
                [firstParam]:{
                    ...ar[index[c + 1]][firstParam],
                    [secondParam]:CopyElementRecursively(index,a,count + 2,element)
                }
            };

            arr[index[c]] = {
                ...arr[index[c]],
                elements: ar
            };

            return arr;
        }
    }

    /**
     * Vloží sloupec do elementu sloupce
     * @param {*} lastIndexes index elementu sloupce v kterém se má sloupec vložit
     */

    const AddColumn = (lastIndexes) =>
    {
        lastIndexes = lastIndexes.split("-");

        var arr = [...formData.content[formData.selectedLang].sections]; 
        arr = AddColumnRecursively(lastIndexes,arr,0);

        SetFormDataContent(arr);
    }

    /**
     * Rekurentně vloží sloupec do elementu sloupce
     * @param {*} index index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole do kterého se vše ukládá
     * @param {*} count počet podle kterého se vyýbírá z indexu hodnota, na které se nachází element
     */

    const AddColumnRecursively = (index,arrPrevious,count) => {

        if((count + 2) == index.length)
        {
            var arr  = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements][index[count+1]].columns.cols;

            var cols;
            if(!a)
                cols = [COLUMN]
            else
                cols = [...a,COLUMN]

            ar[index[count+1]] = {
                ...ar[index[count+1]],
                columns:{
                    ...ar[index[count+1]].columns,
                    cols:cols
                }
            };

            arr[index[count]] = {
                ...arr[index[count]],
                elements: ar
            };

            return arr;
        }
        else
        {
            const {firstParam,secondParam} = GetObjParamsOfElementWithAddContent(arrPrevious[index[count]].elements[index[count+1]]);

            var arr = [...arrPrevious];
            var ar  = [...arrPrevious[index[count]].elements];
            var a   = [...arrPrevious[index[count]].elements[index[count+1]][firstParam][secondParam]];

            ar[index[count+1]] = {
                ...ar[index[count+1]],
                [firstParam]:{
                    ...ar[index[count+1]][firstParam],
                    [secondParam]:AddColumnRecursively(index,a,count + 2)
                }
            };

            arr[index[count]] = {
                ...arr[index[count]],
                elements: ar
            };

            return arr;
        }
    }

    /**
     * Vloží obsah zložky do elementu záložky
     * @param {*} lastIndexes index elementu sloupce v kterém se má sloupec vložit
     */

    const AddTab = (lastIndexes) =>
    {
        lastIndexes = lastIndexes.split("-");

        var arr = [...formData.content[formData.selectedLang].sections]; 
        arr = AddTabRecursively(lastIndexes,arr,0);

        SetFormDataContent(arr);
    }

    /**
     * Rekurentně vloží obsah zložky do elementu záložky
     * @param {*} index index sloupce v kerém se hodnota nastavuje (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole do kterého se vše ukládá
     * @param {*} count počet podle kterého se vyýbírá z indexu hodnota, na které se nachází element
     */

    const AddTabRecursively = (index,arrPrevious,count) => {

        if((count + 2) == index.length)
        {
            var arr  = [...arrPrevious];
            var ar = [...arrPrevious[index[count]].elements];
            var a = [...arrPrevious[index[count]].elements][index[count+1]].tabs.tabs;

            var tabs;
            if(!a)
                tabs = [TAB]
            else
                tabs = [...a,TAB]

            ar[index[count+1]] = {
                ...ar[index[count+1]],
                tabs:{
                    ...ar[index[count+1]].tabs,
                    tabs:tabs
                }
            };

            arr[index[count]] = {
                ...arr[index[count]],
                elements: ar
            };

            return arr;
        }
        else
        {
            const {firstParam,secondParam} = GetObjParamsOfElementWithAddContent(arrPrevious[index[count]].elements[index[count+1]]);

            var arr = [...arrPrevious];
            var ar  = [...arrPrevious[index[count]].elements];
            var a   = [...arrPrevious[index[count]].elements[index[count+1]][firstParam][secondParam]];

            ar[index[count+1]] = {
                ...ar[index[count+1]],
                [firstParam]:{
                    ...ar[index[count+1]][firstParam],
                    [secondParam]:AddTabRecursively(index,a,count + 2)
                }
            };

            arr[index[count]] = {
                ...arr[index[count]],
                elements: ar
            };

            return arr;
        }
    }

    /**
     * Změna pozice elementu
     * @param {*} newPosition nová pozice elementu
     * @param {*} oldPosition stará pozice elementu
     */

    const ChangePosition = (newPosition,oldPosition,type) => {

        var lastIndexes = indexToShowMenu.split("-");
        if(type === "column" || type == "tab")
            lastIndexes.pop();

        var arr = [...formData.content[formData.selectedLang].sections]; 
        arr = ChangePositionRecursively(lastIndexes,arr,0,newPosition,oldPosition,type);

        SetFormDataContent(arr);
    }

    /**
     * Změna pozice elementu rekurzivně
     * @param {*} index index elementu který se přesouvá (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole elementů/sekce
     * @param {*} count počet podle kterého se vyýbírá z indexu hodnota, na které se nachází element/sekce
     * @param {*} newPosition nová pozice elementu
     * @param {*} oldPosition stará pozice elementu
     */

    const ChangePositionRecursively = (index,arrPrevious,count,newPosition,oldPosition,type) => {

        var arr = [...arrPrevious];
        var ar = [...arrPrevious[index[count]].elements];

        const {firstParam,secondParam} = GetObjParamsOfElementWithAddContent(arrPrevious[index[count]].elements[index[count+1]]);

        if((count + 2) == index.length)
        {
            if(type === "column" || type === "tab")
            {
                var a = [...arrPrevious[index[count]].elements][index[count + 1]][firstParam][secondParam];

                var cols = [...a];
                const col = cols[oldPosition - 1];
    
                cols = [...cols.slice(0,oldPosition - 1),...cols.slice(oldPosition)];
                cols = [...cols.slice(0,newPosition - 1),col,...cols.slice(newPosition-1)];
    
                ar[index[count+1]] = {
                    ...ar[index[count+1]],
                    [firstParam]:{
                        ...ar[index[count+1]][firstParam],
                        [secondParam]:cols
                    }
                };
            }
            else
            {
                const elm = ar[oldPosition - 1];

                ar = [...ar.slice(0,oldPosition - 1),...ar.slice(oldPosition)];
                ar = [...ar.slice(0,newPosition - 1),elm,...ar.slice(newPosition-1)];
    
            }

            arr[index[count]] = {
                ...arr[index[count]],
                elements: ar
            };
            
            return arr;
        }
        else
        {
            var a = [...arrPrevious[index[count]].elements[index[count+1]][firstParam][secondParam]];

            ar[index[count+1]] = {
                ...ar[index[count+1]],
                [firstParam]:{
                    ...ar[index[count+1]][firstParam],
                    [secondParam]:ChangePositionRecursively(index,a,count + 2,newPosition,oldPosition,type)
                }
            };

            arr[index[count]] = {
                ...arr[index[count]],
                elements: ar
            };

            return arr;
        }
    }

    /**
     * Odstranění elementu
     * @param {*} index index elementu který se má odstranit (index je string několika čísel oddělených spojovníkem)
     */

    const RemoveElement = (index,element) => {

        var count = 0;
        if(element === "column" || element === "tab" || element === "imageGalleryImage")
            count = 1;

        index = index.split("-");

        var arr = [...formData.content[formData.selectedLang].sections]; 
        arr = RemoveElementRecursively(index,arr,count,element);

        SetFormDataContent(arr);
    }

    /**
     * Rekurentně odebere element
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole, které se naplňuje
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     */

    const RemoveElementRecursively = (index,arrPrevious,count,element) => {

        var c = count;
        if(element === "column" || element === "tab" || element === "imageGalleryImage")
            c = count - 1;

        var arr = [...arrPrevious];
        
        const {firstParam,secondParam} = GetObjParamsOfElementWithAddContent(arrPrevious[index[c]].elements[index[c+1]]);

        if((count + 2) == index.length)
        {
            if(element === "column" || element === "tab")
            {
                var ar = [...arrPrevious[index[c]].elements];
                var a = [...arrPrevious[index[c]].elements][index[c + 1]][firstParam][secondParam];

                var cols = [...a.slice(0,index[c + 2]),...a.slice(parseInt(index[c + 2]) + 1)];

                ar[index[c + 1]] = {...ar[index[c + 1]],[firstParam]:{...ar[index[c + 1]][firstParam],[secondParam]:cols}};
                arr[index[c]] = {...arr[index[c]],elements: ar};

                return arr;
            }
            else if(element === "imageGalleryImage")
            {
                var ar = [...arrPrevious[index[c]].elements];
                var a = [...arrPrevious[index[c]].elements][index[c + 1]].imageGallery.images;

                var images = [...a.slice(0,index[c + 2]),...a.slice(parseInt(index[c + 2]) + 1)];

                ar[index[c + 1]] = {...ar[index[c + 1]],imageGallery:{...ar[index[c + 1]].imageGallery,images:images}};
                arr[index[c]] = {...arr[index[c]],elements: ar};

                return arr;
            }
            else
            {
                var ar  = [
                    ...arrPrevious[index[c]].elements.slice(0,index[c + 1]),
                    ...arrPrevious[index[c]].elements.slice(parseInt(index[c + 1]) + 1)
                ];

                arr[index[c]] = {...arr[index[c]],elements: ar};

                return arr;
            }
        }
        else
        {
            
            var ar  = [...arrPrevious[index[c]].elements];
            var a   = [...arrPrevious[index[c]].elements[index[c + 1]][firstParam][secondParam]];

            ar[index[c + 1]] = {
                ...ar[index[c + 1]],
                [firstParam]:{
                    ...ar[index[c + 1]][firstParam],
                    [secondParam]:RemoveElementRecursively(index,a,count + 2,element)
                }
            };

            arr[index[c]] = {
                ...arr[index[c]],
                elements: ar
            };

            return arr;
        }
    }

    /**
     * 
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @returns objekt s nastavením elementu
     */

    const GetSettings = (index,element) => {

        var count = 0;
        if(element === "column" || element === "tab")
            count = 1;

        index = index.split("-");
        var arr = formData.content[formData.selectedLang].sections; 

        return GetSettingsRecursively(index,arr,count,element);
 
    }

    /**
     * 
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole elementů
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @returns objekt s nastavením elementu
     */

    const GetSettingsRecursively = (index,arrPrevious,count,element) => {
        
        var c = count;
        if(element === "column" || element === "tab")
            c = count - 1;

        const {firstParam,secondParam} = GetObjParamsOfElementWithAddContent(arrPrevious[index[c]].elements[index[c + 1]]);

        if((count + 2) == index.length)
        {
            if(element === "column" || element === "tab")
                return arrPrevious[index[c]].elements[index[c + 1]][firstParam][secondParam][index[c + 2]];
            else
                return arrPrevious[index[c]].elements[index[c + 1]][element]
        }
        else
        {
            var a = [...arrPrevious[index[c]].elements[index[c + 1]][firstParam][secondParam]];
            return GetSettingsRecursively(index,a,count + 2,element)
        }
    }

    /**
     * 
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} e event inptut
     * @param {*} val hodnota - defaultně null ale může se ručně nastavit
     * @param {*} element element kterého se nastavení týká
     */

    const SetSettings = (index,e,val,element) => {

        var {n,v} = GetEventData(e,val);

        index = index.split("-");

        if(!isContentChanged)
            SetIsContentChanged(true);

        SetNewFormdata((prevValue) => {

            var count = 0;
            if(element === "column" || element === "tab")
                count = 1;

            var arr = [...prevValue.content[prevValue.selectedLang].sections]; 
            arr = SetSettingsRecursively(index,arr,count,n,v,element);

            return {...prevValue,content:{
                ...prevValue.content,[prevValue.selectedLang]:{
                    ...prevValue.content[prevValue.selectedLang],
                    sections:arr
                }
            }};
        })
    }

    /**
     * Rekurentně odebere element
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole, které se naplňuje
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @param {*} n jméno atributu nastavení
     * @param {*} v hodnota atributu nastavení
     * @param {*} element element kterého se nastavení týká
     */

    const SetSettingsRecursively = (index,arrPrevious,count,n,v,element) => {

        var c = count;
        if(element === "column" || element === "tab")
            c = count - 1;

        var arr  = [...arrPrevious];
        var ar = [...arrPrevious[index[c]].elements];      

        const {firstParam,secondParam} = GetObjParamsOfElementWithAddContent(arrPrevious[index[c]].elements[index[c + 1]]);

        if((count + 2) == index.length)
        {      
            if(element === "column" || element === "tab")
            {
                var a = [...arrPrevious[index[c]].elements[index[c + 1]][firstParam][secondParam]];

                a[index[c + 2]] = {...a[index[c + 2]],[n]:v}
                ar[index[c + 1]] = {...ar[index[c + 1]],[firstParam]: {...ar[index[c + 1]][firstParam],[secondParam]:a}};
                arr[index[c]] = {...arr[index[c]],elements: ar};

                return arr;
            }
            else
            {
                var elm = {...arrPrevious[index[c]].elements[index[c + 1]][element],[n]:v};

                ar[index[c + 1]] = {...ar[index[c + 1]],[element]:elm}
                arr[index[c]] = {...arr[index[c]],elements: ar};
    
                return arr;
            } 
        }
        else
        {
            var arr = [...arrPrevious];
            var ar  = [...arrPrevious[index[c]].elements];
            var a   = [...arrPrevious[index[c]].elements[index[c + 1]][firstParam][secondParam]];

            ar[index[c + 1]] = {
                ...ar[index[c + 1]],
                [firstParam]:{
                    ...ar[index[c + 1]][firstParam],
                    [secondParam]:SetSettingsRecursively(index,a,count + 2,n,v,element)
                }
            };

            arr[index[c]] = {
                ...arr[index[c]],
                elements: ar
            };

            return arr;
        }
    }

    /**
     * Zjistí počet sloupců nebo záložek v elemntu sloupce nebo záložky
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @returns počet sloupců nebo záložek v elementu sloupce nebo záložky
     */

    const GetColumnOrTabCount = (index) => {

        index = index.split("-");

        var arr = formData.content[formData.selectedLang].sections; 
        return GetColumnOrTabCountRecursively(index,arr,1);

    }

    /**
     * Zjistí rekurentně počet sloupců nebo záložek v elemntu sloupce nebo záložky
     * @param {*} index index elementu (index je string několika čísel oddělených spojovníkem)
     * @param {*} arrPrevious předchozí pole, které se prochzí v rámci elementů
     * @param {*} count počet, díky, kterému se zjití hodnota indexu v zanořeném sloupci
     * @returns počet sloupců nebo záložek v elementu sloupce nebo záložky
     */

    const GetColumnOrTabCountRecursively = (index,arrPrevious,count) => {

        const {firstParam,secondParam} = GetObjParamsOfElementWithAddContent(arrPrevious[index[count - 1]].elements[index[count]]);

        if((count + 2) == index.length)
            return arrPrevious[index[count - 1]].elements[index[count]][firstParam][secondParam].length;

        else
        {
            var a = [...arrPrevious[index[count - 1]].elements[index[count]][firstParam][secondParam]];
            return GetColumnOrTabCountRecursively(index,a,count + 2)
        }
    }

    /**
     * Vybere obrázek z File manageru a uloží cekci nebo elementu
     * @param {*} openFileManagerData data dle konstanty FILE_MANAGER_INIT
     * @param {*} fileName název obrázku
     */

    const ChooseFileManagerImage = (openFileManagerData,fileName,files) => {
        
        if(openFileManagerData.element === "section")
            SetSectionSettings(openFileManagerData.index,"backgroundImage",fileName);
        else if(openFileManagerData.element === "imageGallery")
        {
            var elementSettings = GetSettings(openFileManagerData.index,openFileManagerData.element);
            const imgs = [...files,...elementSettings.images];
            SetSettings(openFileManagerData.index,"images",imgs,openFileManagerData.element)
        }
        else
            SetSettings(openFileManagerData.index,"fileName",fileName,openFileManagerData.element)
        
        CloseFileManager();

        if(openFileManagerData.CallBack)
            openFileManagerData.CallBack(fileName);
    }

    /**
     * Vybere video z File manageru a uloží do elementu
     * @param {*} openFileManagerData data dle konstanty FILE_MANAGER_INIT
     * @param {*} videoFile název videa
     * @param {*} posterFile název coveru videa
     */

    const ChooseFileManagerVideo = (openFileManagerData,data) => {
        
        CloseFileManager();

        if(openFileManagerData.CallBack)
            openFileManagerData.CallBack(data);
    }

    /**
     * Uzavře FIle manager
     */

    const CloseFileManager = () => {
        SetOpenFileManagerData(FILE_MANAGER_INIT);
    }

    return {
        formData,
        projectData,
        loading,
        allLanguageMutations: data ? data.AllLanguageMutations : [],
        contentManagerLinkID,
        contentManagerProjectID,
        showHelpers,
        showSettingsElement,
        indexToShowMenu,
        openFileManagerData,
        windowWidth,
        showCloseContentWarning,
        indexToAddContent,
        selectedLangData,
        SetContentManagerLinkID,
        SetContentManagerProjectID,
        SetFormData,
        SetShowHelpers,
        SetIndexToAddContent,
        SetShowSettingsElement,
        SetIndexToShowMenu,
        AddSection,
        AddElement,
        AddColumn,
        SelectElement,
        GetSectionSettings,
        SetSectionSettings,
        GetSettings,
        RemoveSection,
        ChangeSectionPosition,
        ClearSelection,
        ChangePosition,
        RemoveElement,
        SetSettings,
        GetColumnOrTabCount,
        SetOpenFileManagerData,
        CloseFileManager,
        ChooseFileManagerImage,
        ChooseFileManagerVideo,
        SaveContent,
        CloseContentManager,
        CheckContentManagerBeforeClose,
        CopyLangContent,
        CopySection,
        CopyElement,
        AddTab,
        SetLangData
    }

}