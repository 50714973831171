import { useApolloClient } from "@apollo/client";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GET_SELECTED_PRODUCTS } from "../Queries/contentManager";
import { GetApolloErrorText,ConvertHtmlToText } from "../../../GlobalFunctions/helper";
import { SERVER_URL, PRODUCT_IMAGE_URL } from "../../../Config";

const UseEditRecomendedProducts = (
    lang,
    selectedProducts
) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [loading,SetLoading] = useState(false);
    const [error,SetError] = useState("");

    useEffect(() => {

        if(selectedProducts)
        {
            const contentData = GetContentData(selectedProducts);
            SetContent(contentData);
        }

    },[selectedProducts])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("Name"),
            className:"flex-1 no-padding-left"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.mainPhoto ? SERVER_URL + "/" + PRODUCT_IMAGE_URL + "/p-" + val.productID + "/mala_" + val.mainPhoto : "",
                        className:"no-padding-right"
                    },
                    {
                        value:
                            <span 
                                className="cursor-help"
                                title={ConvertHtmlToText(val.name)}
                            >
                                {ConvertHtmlToText(val.name)}
                            </span>,
                        className:"flex-1 one-line-with-dots"
                    }
                ],
            }
            cData.rowID         = val.productID;
            cData.selected      = false;

            contentData.push(cData)
            
        }

        return contentData;
    }

    const GetSelectedProducts = (selectedProducts) => {
        
        return new Promise(async (resolve,rejected) => {

            try
            {
                var newSelectedProducts = [];
                if(selectedProducts && selectedProducts.length > 0)
                {
                    SetLoading(true);
                    
                    try{
                        var productListData = await client.query({
                            query: GET_SELECTED_PRODUCTS,
                            variables:{
                                lang:lang,
                                productIDs: selectedProducts
                            },
                            fetchPolicy:"network-only"
                        });
        
                        if(productListData && productListData.data.AllProductsByProductIDs)
                            for(let val of productListData.data.AllProductsByProductIDs)
                            {
                                newSelectedProducts.push({
                                    productID:val.productID,
                                    name:val.name,
                                    mainPhoto:val.mainPhoto,
                                    price:val.price,
                                    commonPrice:val.commonPrice,
                                    discount:val.discount,
                                    isDiscountPercentage:val.isDiscountPercentage
                                })
                            }
                    }
                    catch(err)
                    {
                        SetLoading(false);
                        SetError(GetApolloErrorText(err));
                    }
        
                    SetLoading(false);
                }

                resolve(newSelectedProducts);
            }
            catch(err)
            {
                rejected(err);
            }
        }); 
    }

    return{
        headerData,
        content,
        loading,
        error,
        GetSelectedProducts
    }

}

export default UseEditRecomendedProducts;