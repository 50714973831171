import Columns from "./Elements/Columns/Columns.js";
import Text from "./Elements/Text.js";
import Headline from "./Elements/Headline.js";
import Image from "./Elements/Image.js";
import ImageGallery from "./Elements/ImageGallery.js";
import Video from "./Elements/Video.js";
import RecommendedProducts from "./Elements/RecommendedProducts/RecommendedProducts.js";
import TopCategories from './Elements/TopCategories.js';
import ButtonElement from './Elements/Button.js';
import NewestArticles from "./Elements/NewestArticles.js";
import Alert from "./Elements/Alert.js";
import Html from "./Elements/Html.js";
import Form from "./Elements/Form.js";
import Reviews from "./Elements/Reviews/Reviews.js";
import Newsletter from "./Elements/Newsletter.js";
import Faq from "./Elements/Faq.js";
import VideoGallery from "./Elements/VideoGallery/VideoGallery.js";
import ReservationPlace from "./Elements/ReservationPlace/ReservationPlace.js";
import Tabs from "./Elements/Tabs/Tabs.js";

const AllElements = ({
    elmItem,
    elmIndex,
    elementsCount,
    index
}) => {

    return(
        <>
            {elmItem.columns &&
                <Columns 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.columns} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.text &&
                <Text 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.text} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.headline &&
                <Headline 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.headline} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.image &&
                <Image 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.image} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.imageGallery &&
                <ImageGallery 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.imageGallery} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.video &&
                <Video 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.video} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.recommendedProducts &&
                <RecommendedProducts 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.recommendedProducts} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.topCategories &&
                <TopCategories 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.topCategories} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.button &&
                <ButtonElement 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.button} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.newestArticles &&
                <NewestArticles 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.newestArticles} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.alert &&
                <Alert 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.alert} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.html &&
                <Html 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.html} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.form &&
                <Form 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.form} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.reviews &&
                <Reviews 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.reviews} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.newsletter &&
                <Newsletter 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.newsletter} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.faq &&
                <Faq 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.faq} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.videoGallery &&
                <VideoGallery
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.videoGallery} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.reservationPlace &&
                <ReservationPlace
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.reservationPlace} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
            {elmItem.tabs &&
                <Tabs 
                    elmIndex = {elmIndex} 
                    elementsCount = {elementsCount}
                    data = {elmItem.tabs} 
                    lastIndexes = {index + "-" + elmIndex} 
                />
            }
        </>
    )
}

export default AllElements
