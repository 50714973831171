import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_MEMBERSHIP,GENERATE_MEMBERSHIP_LANG } from "../Queries/membership";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseProject, UseSettings } from "../../../AdminProvider";
import { GetCustomerRoleObj } from "./UseEditCustomerRoles";

const UseAddEditMembership = (
    selectedMembershipID,
    projectID,
    mainLang,
    allLanguageMutations,
    OnCompleted
) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();
    const {IsModulEnabled} = UseProject();

    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        OpenImage,
        SetFormLangData,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        SetArrayObjValue,
        SetFormDataSelectedItemsWithObjToArray,
        SetFormDataSelectedItemsWithObjAndIndex,
        GetFormSelectedItemsLangValue,
        SetFormSelectedItemsLangValue,
        SetFormDataSelectedItemsWithObj,
        OpenImageToArrayOfObject
    } = UseFormHandle({
        selectedLang:mainLang,
        linkID:0,
        vatID:1,
        questionnaireID:0,
        customerRoleID:0,
        showForPurchase:1,
        isRecurring:1,
        isTherapy:0,
        showInPortal:0,
        useGuide:0,
        useFreeTrialPeriod:0,
        numberOfFreeTrialDays:0,
        numberOfDaysBeforeToSendReservationReminder:0,
        langs:[],
        selectedCustomerRoles:[],
        selectedMembershipTypes:[],
        selectedReservationPlaces:[],
        photo:null,
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_MEMBERSHIP_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditMembershipMutation,{error,loading}] = useMutation(ADD_EDIT_MEMBERSHIP,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    membershipID: d.AddEditMembership.membershipID,
                    isEdit: (selectedMembershipID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditMembership);
                
                const notifyText = (selectedMembershipID != 0 ? t("SuccessfullyUpdated") : t("NewMembershipIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("MembershipHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditMembership = () => {

        var checkName = true;
        var checkPrice = true;

        var isPortalModuleEnabled = IsModulEnabled(14);
        var isFreeTrialPeriodModuleEnabled = IsModulEnabled(11);
        var guideModuleEnabled = IsModulEnabled(66);
        var therpayModulEnabled = IsModulEnabled(73);

        const numberOfFreeTrialDays = parseInt(formData.numberOfFreeTrialDays);
        const numberOfDaysBeforeToSendReservationReminder = parseInt(formData.numberOfDaysBeforeToSendReservationReminder);

        let langs = [];
        for(let lang of formData.langs)
        {
            const l = {...lang};

            if(l.lang == formData.selectedLang && !l.name)
            {
                checkName = false;
                break;
            }

            if(formData.isRecurring == 1)
            {
                l.price = 0;
                l.prepaymentPrice = 0;
            }

            const price = parseFloat(l.price);
            const prepaymentPrice = parseFloat(l.prepaymentPrice);

            if((!price && price != 0) || price < 0)
                checkPrice = false;

            langs.push({
                lang: l.lang,
                name: l.name,
                invoiceName: l.invoiceName,
                price: price,
                prepaymentPrice:prepaymentPrice,
                priceQrCode:l.priceQrCode,
                prepaymentPriceQrCode:l.prepaymentPriceQrCode,
                description: l.description,
                emailText: l.emailText,
                upSellBombDescription: l.upSellBombDescription,
                successMessage: l.successMessage,
                trialPeriodCancellationEmail: l.trialPeriodCancellationEmail,
                notificationOfEndingTrialPeriodEmail: l.notificationOfEndingTrialPeriodEmail,
                emailForCustomerWhenAddedToGuide: l.emailForCustomerWhenAddedToGuide,
                noMoreFreeTrialPeriodWarning: l.noMoreFreeTrialPeriodWarning,
                notPossibleToMakeOrderWarning: l.notPossibleToMakeOrderWarning,
                recurringPaymentInfoEmail: l.recurringPaymentInfoEmail,
                reservationReminderEmail: l.reservationReminderEmail,
                therapyTextTemplate: l.therapyTextTemplate,
                therapyTextToRead: l.therapyTextToRead
            });
        }

        if(checkName)
        {
            if((isPortalModuleEnabled && formData.showInPortal == 1 && formData.customerRoleID != 0) || !isPortalModuleEnabled || formData.showInPortal == 0)
            {
                if((isPortalModuleEnabled && formData.showInPortal == 1 && formData.selectedCustomerRoles.length > 0) || !isPortalModuleEnabled || formData.showInPortal == 0)
                {
                    if(formData.useFreeTrialPeriod && numberOfFreeTrialDays > 0 || !formData.useFreeTrialPeriod)
                    {
                        if(checkPrice)
                        {
                            if(CheckTimes(formData.selectedReservationPlaces))
                            {
                                AddEditMembershipMutation({
                                    variables:{
                                        membershipID:                               selectedMembershipID,
                                        projectID:                                  projectID,
                                        linkID:                                     formData.linkID,
                                        vatID:                                      formData.vatID,
                                        questionnaireID:                            formData.questionnaireID,
                                        customerRoleID:                             (formData.showInPortal == 1 ? formData.customerRoleID : 0),
                                        showForPurchase:                            parseInt(formData.showForPurchase),
                                        isRecurring:                                parseInt(formData.isRecurring),
                                        isTherapy:                                  (therpayModulEnabled ? parseInt(formData.isTherapy) : 0),
                                        showInPortal:                               (isPortalModuleEnabled ? parseInt(formData.showInPortal) : 0),
                                        useGuide:                                   (guideModuleEnabled ? parseInt(formData.useGuide) : 0),
                                        useFreeTrialPeriod:                         (formData.isRecurring == 1 ? parseInt(formData.useFreeTrialPeriod) : 0),
                                        numberOfFreeTrialDays:                      (formData.useFreeTrialPeriod == 1 && isFreeTrialPeriodModuleEnabled ? numberOfFreeTrialDays : 0),
                                        numberOfDaysBeforeToSendReservationReminder:    (formData.selectedReservationPlaces.length > 0 ? numberOfDaysBeforeToSendReservationReminder : 0),
                                        photo:                                      formData.photo,
                                        langs:                                      langs,
                                        selectedCustomerRoles:                      (formData.showInPortal == 1 ? GetCustomerRoles(formData.selectedCustomerRoles) : []),
                                        selectedReservationPlaces:                  GetReservationPlaces(formData.selectedReservationPlaces),
                                        generateTranslations:                       formData.generateTranslations
                                    }
                                })
                            }
                            else
                                AddNotification(t("SomeReservationsDoNotHaveSelectedTimes"),false); 
                        }
                        else
                            AddNotification(t("PriceIsInvalidInSomeLanguage"),false); 
                    }
                    else
                        AddNotification(t("NumberOfFreeTrialDaysIsNecesary"),false); 
                }
                else
                    AddNotification(t("NoCustomerRolesSelected"),false); 
            }
            else
                AddNotification(t("CustomerRoleAfterPurchseIsInvalid"),false); 
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const GetCustomerRoles = (customerRoles) => {

        if(customerRoles)
        {
            var newCustomerRoles = [];
            for(let val of customerRoles)
            {
                newCustomerRoles.push(
                    {...GetCustomerRoleObj(val),
                        membershipCustomerRoleID:(
                            val.membershipCustomerRoleID ? 
                                val.membershipCustomerRoleID 
                            : 0
                        )
                    }
                );
            }

            return newCustomerRoles;
        }
        else
            return []
    }

    const GetReservationPlaces = (reservationPlaces) => {

        if(reservationPlaces)
        {
            var newReservationPlaces = [];
            for(let val of reservationPlaces)
            {
                var langs = [];
                for(let l of val.langs)
                {
                    langs.push({
                        lang:l.lang,
                        emailText: l.emailText,
                        templateTherapyEmailText: l.templateTherapyEmailText
                    });
                }

                newReservationPlaces.push({
                    reservationPlaceID:val.reservationPlaceID,
                    times:val.selectedTimes,                    
                    langs
                });
            }

            return newReservationPlaces;
        }
        else
            return []
    }

    const CheckTimes = (selectedReservationPlaces) => {

        var checkTimes = true;

        for(let rp of selectedReservationPlaces)
        {
            if(rp.selectedTimes.length == 0)
                checkTimes = false;
        }

        return checkTimes;

    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditMembership,
        SetFormData,
        InitFormData,
        OpenImage,
        SetFormLangData,
        GetFormLangValue,
        FillFormSelectedItemsWithObj,
        RemoveFormDataSelectedItems,
        SetArrayObjValue,
        SetFormDataSelectedItemsWithObjToArray,
        SetFormDataSelectedItemsWithObjAndIndex,
        GetFormSelectedItemsLangValue,
        SetFormSelectedItemsLangValue,
        SetFormDataSelectedItemsWithObj,
        OpenImageToArrayOfObject
    }
}

export default UseAddEditMembership;