import { useState,useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { useQuery } from "@apollo/client";
import { GetTimeInString } from "../../../GlobalFunctions/helper";
import UseGetAllBlockedDays from "../../Reservation/Library/UseGetAllBlockedDays";
import { 
    GET_MEMBERSHIP_WITH_RESERVATION_PLACE,
    GET_RESERVATION_PLACE_RESERVED_DAYS,
    GET_RESERVATION_PLACE_DATE_RESERVATIONS
} from "../Queries/contentManager";

const UseReservationPlaceCalendar = (
    reservationPlaceID,
    membershipID,
    projectID,
    lang,
    OnDataChange,
    OnHasVariations
) => {

    const INIT_FORM = {
        reservationDate:"",
        reservationPlaceTimeID:0,
        reservationPlaceTimeName:"",
        reservationPlaceVariationID:0,
        reservationPlaceTimeVariationName:""
    };

    const client = useApolloClient();

    const {GetBlockedDays} = UseGetAllBlockedDays();

    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(INIT_FORM);
    const [timesLoading, SetTimesLoading] = useState(false);
    const [selectedReservationPlace, SetSelectedReservationPlace] = useState(null);
    const [reservationPlaceTimeVariations, SetReservationPlaceTimeVariations] = useState([]);
    const [reservedDatetimes, SetReservedDatetimes] = useState([]);

    useEffect(() => {
        if(OnDataChange && formData.reservationDate != "")
            OnDataChange(formData);
    },[formData])

    useEffect(() => {
        if(OnHasVariations)
            OnHasVariations(reservationPlaceTimeVariations.length > 0 ? true : false);
    },[reservationPlaceTimeVariations])

    const {loading:getLoading} = useQuery(GET_MEMBERSHIP_WITH_RESERVATION_PLACE,{
        variables:{
            lang,
            reservationPlaceID:reservationPlaceID,
            membershipID:membershipID,
            projectID:projectID
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {
            
            if(d.Membership)
                SetSelectedReservationPlace(d.Membership.reservationPlace)
        }
    });

    const GetAllBlockedDays = async (month,year) => {

        if(reservationPlaceID)
        {
            const blockedDays = await GetBlockedDays(month,year,reservationPlaceID);
            const reserverDays = await GetReservationPlaceReservedDays(month,year,reservationPlaceID,membershipID);

            const newBlockedDays = [...blockedDays];
            for(let val of reserverDays)
            {
                var check = false;
                for(let v of blockedDays)
                {
                    if(v.date == val)
                        check = true;
                }

                if(!check)
                    newBlockedDays.push({
                        date:val
                    })
            }

            return newBlockedDays;
        }
        
        return [];
    }

    const GetReservationPlaceReservedDays = (month,year,reservationPlaceID,membershipID) => {

        return new Promise(async (resolve, rejected) => {
            
            try
            {
                var response = await client.query({
                    query:GET_RESERVATION_PLACE_RESERVED_DAYS,
                    variables:{
                        reservationPlaceID,
                        membershipID,
                        month,
                        year,
                    },
                    fetchPolicy:"network-only"
                });

                resolve(response.data.AllReservationPlaceReservedDays);
            }
            catch(err)
            {
                rejected(err); 
            }
        });
    }

    const GetAllReservationDateTimes = (date,reservationPlaceID,membershipID) => {

        return new Promise(async (resolve, rejected) => {
            
            try
            {
                SetTimesLoading(true);

                var response = await client.query({
                    query:GET_RESERVATION_PLACE_DATE_RESERVATIONS,
                    variables:{
                        reservationPlaceID,
                        membershipID,
                        date
                    },
                    fetchPolicy:"network-only"
                });

                SetReservedDatetimes(response.data.AllReservationPlaceDateReservations)
                SetTimesLoading(false);

                resolve(response.data.AllReservationPlaceDateReservations);
            }
            catch(err)
            {
                rejected(err); 
            }
        });
    }

    const SelectReservationDate = (date) =>
    {
        if(date != formData.reservationDate)
        {
            if(formData.reservationPlaceTimeID != 0)
                SelectReservationPlaceTime(0);

            if(formData.reservationPlaceVariationID != 0)
                SetFormData("reservationPlaceVariationID",0);

            if(formData.reservationPlaceTimeName != "")
                SetFormData("reservationPlaceTimeName","");

            if(formData.reservationPlaceTimeVariationName != "")
                SetFormData("reservationPlaceTimeVariationName","");

            if(reservationPlaceTimeVariations != [])
                SetReservationPlaceTimeVariations([]);

            GetAllReservationDateTimes(date,reservationPlaceID,membershipID);

            SetFormData("reservationDate",date)
        }
    }

    const SelectReservationPlaceTime = (reservationPlaceTimeID, reservationPlaceVariationID) => {
        
        if(reservationPlaceTimeID != formData.reservationPlaceTimeID)
        {
            if(formData.reservationPlaceVariationID != 0)
                SetFormData("reservationPlaceVariationID",0);
            
            if(selectedReservationPlace)
                for(let val of selectedReservationPlace.times)
                {
                    if(val.reservationPlaceTimeID == reservationPlaceTimeID)
                    {
                        SetFormData("reservationPlaceTimeName",GetTimeInString(val.reservationPlaceTime.timeFrom) + " - " + GetTimeInString(val.reservationPlaceTime.timeTo));
                        SetReservationPlaceTimeVariations(val.reservationPlaceTime.variations);

                        if(reservationPlaceVariationID)
                        {
                            SetFormData("reservationPlaceVariationID",reservationPlaceVariationID);
                            for(let v of val.reservationPlaceTime.variations)
                            {
                                if(v.reservationPlaceVariationID == reservationPlaceVariationID)
                                    SetFormData("reservationPlaceTimeVariationName",v.reservationPlaceVariation.name);
                            }
                        }
                        else
                            SetFormData("reservationPlaceTimeVariationName","");
                    }
                }

            SetFormData("reservationPlaceTimeID",reservationPlaceTimeID);
        }
    }

    const SelectReservationPlaceTimeVariation = (e) => {

        var variationID = e.target.value;

        if(variationID != formData.reservationPlaceVariationID)
        {
            for(let val of reservationPlaceTimeVariations)
            {
                if(val.reservationPlaceVariationID == variationID)
                    SetFormData("reservationPlaceTimeVariationName",val.reservationPlaceVariation.name);   
            }

            if(variationID == 0)
                SetFormData("reservationPlaceTimeVariationName","");   

            SetFormData(e);
        }
    }
    
    return{
        timesLoading: timesLoading || getLoading,
        formData,
        reservationPlaceTimeVariations,
        selectedReservationPlace,
        reservedDatetimes,
        SetFormData,
        GetAllBlockedDays,
        SelectReservationDate,
        SelectReservationPlaceTime,
        SetCompleteFormData,
        SelectReservationPlaceTimeVariation
    }
}

export default UseReservationPlaceCalendar;