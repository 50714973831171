import {useQuery} from '@apollo/client';
import { GET_PRODUCT_COVERS } from '../../Queries/products';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllProductCovers = (
    selectedExcludedCovers,
    selectedCoversPercentage,
    lang,
    productID,
    SetFormData,
    SetFormDataSelectedItemsWithObj,
    hidePercentage
) => {

    const [variables,SetVariables] = useState({
        lang,
        productID
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedCoverIDs,SetSelectedCoverIDs] = useState((selectedExcludedCovers ? selectedExcludedCovers : []));

    const {data,loading,error} = useQuery(GET_PRODUCT_COVERS,{
        variables,
        fetchPolicy:"network-only"
    })

    var useEffectParams = [data]
    if(!hidePercentage)
        useEffectParams.push(selectedCoversPercentage);

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllProductCovers);
            SetContent(contentData);
        }

    },useEffectParams)

    var headerData = [
        {
            value: t("CoverName"),
            className:"flex-1"
        },
        {
            value: t("Shortcut"),
            className:"static text-center"
        }
    ];

    if(!hidePercentage)
    {
        headerData.push({
            value: t("PercentageIncrease"),
            className:"text-center static "
        })
    }

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value:val.shortcut,
                        className:"static text-center"
                    }
                ]
            }

            if(!hidePercentage)
            {
                cData.data.push({
                    hideWhenSelectedIs:true,
                    elm:"input",
                    type:"number",
                    param:"percentage",
                    name:"percentage",
                    append:"%",
                    OnChange: (e) => SetFormDataSelectedItemsWithObj(e,"selectedCoversPercentage","coverID",val.coverID),
                    className:"static"   
                })
            }

            if(selectedExcludedCovers.length > 0)
                cData.selected =  (selectedExcludedCovers.indexOf(val.coverID) != -1 ? true : false); 
            else
                cData.selected = false;

            cData["percentage"] = 0;
            for(let v of selectedCoversPercentage)
            {
                if(v.coverID == val.coverID)
                    cData["percentage"] = v.percentage;
            }

            cData.rowID = val.coverID;
            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,coverID) => {

        SetSelectedCoverIDs((prevVaue) => {

            var checked = e.target.checked;
            var newSelectedCoverIDs = [...prevVaue];
            const newList = SelectCoverID(content,coverID,checked,newSelectedCoverIDs)

            SetContent(newList);
            SetFormData("selectedExcludedCovers",newSelectedCoverIDs);

            return newSelectedCoverIDs;

        })
        
    }

    const SelectAll = (e) => {

        SetSelectedCoverIDs((prevVaue) => {

            var checked = e.target.checked;
            var newSelectedCoverIDs = [];
            const newList = SelectCoverID(content,"all",checked,newSelectedCoverIDs)

            SetFormData("selectedExcludedCovers",newSelectedCoverIDs);
            SetContent(newList);

            return newSelectedCoverIDs;
        })
    }

    const SelectCoverID = (list,coverID,checked,selectedCoverIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(coverID == newList[i].rowID || coverID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedCoverIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedCoverIDs.length; j++)
                    {
                        if(selectedCoverIDs[j] == newList[i].rowID)
                            selectedCoverIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectCoverID(newList[i].subData,coverID,checked,selectedCoverIDs)}
        }

        return newList;
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedCoverIDs,
        SelectRow,
        SelectAll
    }

}

export default UseGetAllProductCovers;