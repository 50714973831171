import Button from '../../../../GlobalComponents/Button';
import { useState } from 'react';
import imageIcon from '../../../../Media/Images/Icons/image.webp';
import InputCard from '../../../../GlobalComponents/InputCard';
import FileInput from '../../../../GlobalComponents/FileInput';
import Input from '../../../../GlobalComponents/Input';
import RadioButton from '../../../../GlobalComponents/RadioButton';
import priceIcon from '../../../../Media/Images/Icons/price.webp';
import fabricIcon from '../../../../Media/Images/Icons/fabric.webp';
import warehouseIcon from '../../../../Media/Images/Icons/warehouse.webp';
import cancelIcon from '../../../../Media/Images/Icons/cancel.webp';
import labelIcon from '../../../../Media/Images/Icons/label.webp';
import Select from '../../../../GlobalComponents/Select';
import Checkbox from '../../../../GlobalComponents/Checkbox';
import SelectBox from '../../../../GlobalComponents/SelectBox';
import { COVER_IMAGE_URL, SERVER_URL } from '../../../../Config';
import UseEditLabels from '../../Library/Products/UseEditLabels';
import List from '../../../../GlobalComponents/List/List';
import ChooseLabels from './ChooseLabels';
import { useTranslation } from 'react-i18next';

const VariantBasicInformations = ({
    formData,
    SetFormData,
    SetFormDataSelectedItemsWithObj,
    productSides,
    coverTitles,
    allProductCovers,
    coverPhotos,
    parameterColors,
    SelectCover,
    SetFormLangData,
    GetFormLangValue,
    OpenImage,
    RemoveFormDataSelectedItems,
    GeneratePriceAfterDiscount,
    FillFormSelectedItemsWithObj
}) => {

    const {t} = useTranslation();

    const [showAddLabels, SetShowAddLabels] = useState(false);
    const {headerData:labelHeaderData,content:labelContent} = UseEditLabels(formData.selectedLabels,SetFormDataSelectedItemsWithObj);

    return(
        <>
            <div className="row">
                <div className="col-66 d-flex">

                    <InputCard
                        title = {t("Image")}
                        icon = {imageIcon}
                        className = {"form-group flex-1"}
                        topMargin={true}
                    > 
                        {formData.mainPhoto ?
                            (formData.productSideID == 1 ?
                                <div className="row">
                                    <div className="col-50">
                                        <label>{formData.photoForSide == 1 ? t("LeftSide") : t("RightSide")}</label>
                                        <div className="open-image-container form-group">
                                            <img className="no-margin" src = {formData.mainPhoto} />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <label>{formData.photoForSide == 1 ? t("RightSide") : t("LeftSide")}</label>
                                        <div className="open-image-container form-group">
                                            <img className="no-margin mirrored-verticaly" src = {formData.mainPhoto} />
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className="open-image-container form-group">
                                    <img className="no-margin" src = {formData.mainPhoto} />
                                </div>
                            )
                        :null}

                        <div className="form-group">                
                            <FileInput
                                name = "photo"
                                OnChange = {(e) => OpenImage(e)}
                            />
                        </div>

                        {formData.mainPhoto && formData.productSideID == 1 ?

                            <div className="form-group">
                                                                                    
                                <label>{t("UploadedImageIsFor")}</label>
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("LeftSide")}
                                        name="photoForSide"
                                        id="photoForSide-1"
                                        value={1}
                                        checked = {formData.photoForSide == 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("RightSide")}
                                        name="photoForSide"
                                        id="photoForSide-2"
                                        value={2}
                                        checked = {formData.photoForSide == 2 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>

                        :null}
                    
                    </InputCard>

                </div>
                
                <div className={"col-33"}>
                    <div className="row">
                        <div className={"col-100"}>

                            <div className="form-group">
                                    
                                <label>{t("ShowOnEshop")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text="Ano"
                                        name="showOnEshop"
                                        id="showOnEshop-1"
                                        value={1}
                                        checked = {formData.showOnEshop === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text="Ne"
                                        name="showOnEshop"
                                        id="showOnEshop-2"
                                        value={0}
                                        checked = {formData.showOnEshop === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>
                                    *{t("ProductCode")})
                                </label>
                                <Input
                                    name="productCode"
                                    value={formData.productCode}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                                    
                            <div className="form-group">
                                <label>*{t("Side")}</label>
                                <Select
                                    name="productSideID"
                                    value={formData.productSideID}
                                    OnChange={(e) => SetFormData(e)}
                                >
                                    {productSides.map((item) => (
                                        <option 
                                            key={item.productSideID}
                                            value={item.productSideID}
                                        >{item.name}</option>
                                    ))}
                                </Select>
                            </div>

                            <div className="form-group">
                                <label>
                                    {t("VariantName")} ({formData.selectedLang})
                                </label>
                                <Input
                                    name="name"
                                    value={GetFormLangValue("name")}
                                    OnChange={(e) => SetFormLangData(e)}
                                />
                            </div>

                            <div className="form-group">
                                <label>
                                    {t("InternalNote")} ({formData.selectedLang})
                                </label>
                                <Input
                                    name="internalNote"
                                    value={GetFormLangValue("internalNote")}
                                    OnChange={(e) => SetFormLangData(e)}
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>   

            <InputCard
                className = "form-group"
                title = {t("ItemsInStock")}
                icon = {warehouseIcon}
                canBeMinimalized = {true}
                headerInfoContent = {[
                    t("ProductVariantItemsInStockInfo1"),
                    t("ProductVariantItemsInStockInfo2"),
                    t("ProductVariantItemsInStockInfo3")      
                ]}
            >   
                <div className="row">

                    <div className={"col-50"}>
                                
                        <div className="form-group">
                            <label>*{formData.productSideID == 1 ? 
                                    t("QuantityInStockLeftSide")
                                :
                                    t("QuantityInStock")
                                }
                            </label>
                            <Input
                                name="quantityInStock"
                                type = "number"
                                value={formData.quantityInStock}
                                OnChange={(e) => SetFormData(e)}
                            />
                        </div>
                    </div> 

                    <div className={"col-50"}>
                                
                        <div className="form-group">
                            <label>*{formData.productSideID == 1 ? 
                                    t("QuantityInStockAtManufacturerLeftSide")
                                :
                                    t("QuantityInStockAtManufacturer")
                                }
                            </label>
                            <Input
                                name="quantityAtManufacturer"
                                type = "number"
                                value={formData.quantityAtManufacturer}
                                OnChange={(e) => SetFormData(e)}
                            />
                        </div>
                    </div>
                    
                    {formData.productSideID == 1 ?
                        <>
                            <div className={"col-50"}>
                                        
                                <div className="form-group">
                                    <label>*{t("QuantityInStockRightSide")}</label>
                                    <Input
                                        name="quantityInStockRightSide"
                                        type = "number"
                                        value={formData.quantityInStockRightSide}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div> 

                            <div className={"col-50"}>
                                        
                                <div className="form-group">
                                    <label>*{t("QuantityInStockAtManufacturerRightSide")}</label>
                                    <Input
                                        name="quantityAtManufacturerRightSide"
                                        value={formData.quantityAtManufacturerRightSide}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </>
                    :null}

                </div>
            </InputCard>

            <InputCard
                className = "form-group"
                title = {t("Covers")}
                icon = {fabricIcon}
                canBeMinimalized = {true}
                
            >   
                <div className="row">
                    <div className="col-100">

                        <div className="form-group">
                                        
                            <label>{t("PresetCovers")} </label>
                            <div className="radio-container">
                                <RadioButton
                                    text="Ano"
                                    name="presetCovers"
                                    id="presetCovers-1"
                                    value={1}
                                    checked = {formData.presetCovers === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text="Ne"
                                    name="presetCovers"
                                    id="presetCovers-2"
                                    value={2}
                                    checked = {formData.presetCovers === 2 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text={t("WithoutCovers")}
                                    name="presetCovers"
                                    id="presetCovers-3"
                                    value={3}
                                    checked = {formData.presetCovers === 3 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>
                    {formData.presetCovers != 3 ?
                        <>
                            <div className="col-50">
                                <InputCard
                                    className = "form-group"
                                    title = {t("FirstCover")}
                                >   
                                    <div className="form-group">
                                        
                                        <label>{t("Show")} </label>
                                        <div className="radio-container">
                                            <RadioButton
                                                text="Ano"
                                                name="showFirstCover"
                                                id="showFirstCover-1"
                                                value={1}
                                                checked = {formData.showFirstCover === 1 ? true : false}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                            <RadioButton
                                                text="Ne"
                                                name="showFirstCover"
                                                id="showFirstCover-2"
                                                value={0}
                                                checked = {formData.showFirstCover === 0 ? true : false}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>{formData.showFirstCover ? "*" : ""}{t("CoverTitle")}</label>
                                        <Select
                                            name="firstCoverTitleID"
                                            value={formData.firstCoverTitleID}
                                            OnChange={(e) => SetFormData(e)}
                                        >
                                            <option 
                                                key={0}
                                                value={0}
                                            >{t("---chooseCoverTitle---")}</option>
                                            
                                            {coverTitles.map((item) => (
                                                <option 
                                                    key={item.coverTitleID}
                                                    value={item.coverTitleID}
                                                >{item.name}</option>
                                            ))}
                                        </Select>
                                    </div>
                                    {formData.presetCovers == 1 ?
                                        <>
                                            <div className="form-group">
                                                <label>{formData.showFirstCover ? "*" : ""}{t("Cover")}</label>
                                                <Select
                                                    name="firstCoverID"
                                                    value={formData.firstCoverID}
                                                    OnChange={(e) => {
                                                        SetFormData(e);
                                                        SetFormData("firstCoverPhotoID",0);
                                                        SelectCover("firstCoverPhotos",e.target.value, e.target.selectedOptions[0].innerText);
                                                    }}
                                                >
                                                    <option 
                                                        key={0}
                                                        value={0}
                                                    >{t("---chooseCover---")}</option>
                                                    
                                                    {allProductCovers.map((item) => (
                                                        <option 
                                                            key={item.coverID}
                                                            value={item.coverID}
                                                        >{item.name}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                            {formData.firstCoverID != 0 ?
                                                <>
                                                    <div className="form-group">
                                                        <label>*{t("SpecificCover")}</label>
                                                        <SelectBox 
                                                            value={formData.firstCoverPhotoID}
                                                            OnChange={(value) => SetFormData("firstCoverPhotoID",value)}
                                                            nameParam = "number"
                                                            valueParam = "coverPhotoID"
                                                            photoParam = "name"
                                                            photoUrl = {SERVER_URL + "/" + COVER_IMAGE_URL + "/c-" + formData.firstCoverID + "/mala_"}
                                                            items={coverPhotos.firstCoverPhotos}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            {t("Color")} 
                                                        </label>
                                                        <Select
                                                            value = {formData.firstParameterValueID}
                                                            name = "firstParameterValueID"
                                                            OnChange = {(e) => SetFormData(e)}
                                                        >
                                                            <option 
                                                                key={0}
                                                                value={0}
                                                            >{t("---chooseColor---")}</option>
                                                            {parameterColors && parameterColors.map((item) => (
                                                                <option 
                                                                    key={item.parameterValueID}
                                                                    value={item.parameterValueID}
                                                                >{item.value}</option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </>
                                            :null}
                                        </>
                                    :null}
                                
                                </InputCard>
                            </div>

                            <div className="col-50">
                                <InputCard
                                    className = "form-group"
                                    title = {t("SecondCover")}
                                >   
                                    <div className="form-group">
                                        
                                        <label>{t("Show")} </label>
                                        <div className="radio-container">
                                            <RadioButton
                                                text="Ano"
                                                name="showSecondCover"
                                                id="showSecondCover-1"
                                                value={1}
                                                checked = {formData.showSecondCover === 1 ? true : false}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                            <RadioButton
                                                text="Ne"
                                                name="showSecondCover"
                                                id="showSecondCover-2"
                                                value={0}
                                                checked = {formData.showSecondCover === 0 ? true : false}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>{formData.showSecondCover ? "*" : ""}{t("CoverTitle")}</label>
                                        <Select
                                            name="secondCoverTitleID"
                                            value={formData.secondCoverTitleID}
                                            OnChange={(e) => SetFormData(e)}
                                        >
                                            <option 
                                                key={0}
                                                value={0}
                                            >{t("---chooseCoverTitle---")}</option>
                                            
                                            {coverTitles.map((item) => (
                                                <option 
                                                    key={item.coverTitleID}
                                                    value={item.coverTitleID}
                                                >{item.name}</option>
                                            ))}
                                        </Select>
                                    </div>

                                        {formData.presetCovers == 1 ?
                                        <>
                                            <div className="form-group">
                                                <label>{formData.showSecondCover ? "*" : ""}{t("Cover")}</label>
                                                <Select
                                                    name="secondCoverID"
                                                    value={formData.secondCoverID}
                                                    OnChange={(e) => {
                                                        SetFormData(e);
                                                        SetFormData("secondCoverPhotoID",0);
                                                        SelectCover("secondCoverPhotos",e.target.value,e.target.selectedOptions[0].innerText);
                                                    }}
                                                >
                                                    <option 
                                                        key={0}
                                                        value={0}
                                                    >{t("---chooseCover---")}</option>
                                                    
                                                    {allProductCovers.map((item) => (
                                                        <option 
                                                            key={item.coverID}
                                                            value={item.coverID}
                                                        >{item.name}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                            {formData.secondCoverID != 0 ?
                                                <>
                                                    <div className="form-group">
                                                        <label>*{t("SpecificCover")}</label>
                                                        <SelectBox 
                                                            value={formData.secondCoverPhotoID}
                                                            OnChange={(value) => SetFormData("secondCoverPhotoID",value)}
                                                            nameParam = "number"
                                                            valueParam = "coverPhotoID"
                                                            photoParam = "name"
                                                            photoUrl = {SERVER_URL + "/" + COVER_IMAGE_URL + "/c-" + formData.secondCoverID + "/mala_"}
                                                            items={coverPhotos.secondCoverPhotos}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            {t("Color")} 
                                                        </label>
                                                        <Select
                                                            value = {formData.secondParameterValueID}
                                                            name = "secondParameterValueID"
                                                            OnChange = {(e) => SetFormData(e)}
                                                        >
                                                            <option 
                                                                key={0}
                                                                value={0}
                                                            >{t("---chooseColor---")}</option>
                                                            {parameterColors && parameterColors.map((item) => (
                                                                <option 
                                                                    key={item.parameterValueID}
                                                                    value={item.parameterValueID}
                                                                >{item.value}</option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </>
                                            :null}
                                        </>
                                    :null}                                            
                                </InputCard>
                            </div>
                        </>
                    :null}
                </div>
                
            </InputCard>

            <InputCard
                className = "form-group"
                title = {t("Price")}
                icon = {priceIcon}
                canBeMinimalized = {true}
                headerInfoContent = {[
                    t("ProductVariantPriceInfo1"),
                    t("ProductVariantPriceInfo2")      
                ]}
            >   

                <div className="row">

                    <div className={"col-25"}>
                                
                        <div className="form-group">
                            <label>{t("PriceWithVAT")} ({formData.selectedLang})</label>
                            <Input
                                name="price"
                                value={GetFormLangValue("price")}
                                OnChange={(e) => SetFormLangData(e)}
                            />
                        </div>
                    </div> 

                    <div className={"col-25"}>
                                
                        <div className="form-group">
                            <label>{t("Discount")} ({formData.selectedLang})</label>
                            <Input
                                name="discount"
                                value={GetFormLangValue("discount")}
                                OnChange={(e) => SetFormLangData(e)}
                                append = {
                                    <Checkbox
                                        text = {"%"}
                                        id = "d-p"
                                        name = "isDiscountPercentage"
                                        checked = {GetFormLangValue("isDiscountPercentage")}
                                        OnChange={(e) => SetFormLangData(e)}
                                    />
                                }
                            />
                        </div>
                    </div> 

                    <div className={"col-25"}>
                                
                        <div className="form-group">
                            <label>{t("PriceWithVatAfterDiscount")} ({formData.selectedLang})</label>
                            <div className = "form-text">
                                {GeneratePriceAfterDiscount()}
                            </div>
                        </div>
                    </div> 

                    <div className={"col-25"}>
                                
                        <div className="form-group">
                            <label>{t("CommonPrice")} ({formData.selectedLang})</label>
                            <Input
                                name="commonPrice"
                                value={GetFormLangValue("commonPrice")}
                                OnChange={(e) => SetFormLangData(e)}
                            />
                        </div>
                    </div> 

                    
                </div>
            </InputCard>

            <InputCard
                title = {t("Labels")}
                icon = {labelIcon}
                noBodyPadding = {true}
                canBeMinimalized = {true}
                headerInfoContent = {[
                    t("LabelInputCardInfo")    
                ]}
                headerChildren = {
                    <Button 
                        OnClick = {(e) => {
                            e.stopPropagation();
                            SetShowAddLabels(true)
                        }}
                        className="btn-primary narrow"
                    >{formData.selectedLabels.length > 0 ? t("Edit") : t("Choose")}</Button>
                }
            >   
                <List 
                    headerData = {labelHeaderData} 
                    headerClassName = {"no-top-border"}
                    contentData = {labelContent}
                    isSortable = {false}
                    loading = {false}
                    replaceDotsImg = {cancelIcon}
                    ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedLabels","labelID",[data.rowID])}
                />
            
            </InputCard>

            {showAddLabels ? 
                <ChooseLabels 
                    formData = {formData}
                    SetShowAddLabels = {SetShowAddLabels}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
        </>
    )
}

export default VariantBasicInformations;