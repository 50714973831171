import { UseContentManager } from "../../Library/UseContentManager";
import ControlsMenu from "../ControlsMenu";
import { GetElementMargins } from "../../Library/functions";
import TinyMCEEditor from "../TinyMCEEditor";

const Form = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {
        showHelpers,
        projectData,
        indexToShowMenu,
        SelectElement,
        SetSettings
    } = UseContentManager();

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);

    const buttonBgColor = projectData.buttonBgColor.split("-");

    const OnEditorChange = (content) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu,"text",content,"form")
    }
    
    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "form"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div 
                className={margins.styleClassName +  " " + data.className} 
                style={margins.styles}
            >   
                <div className={"cm-form-element"}> 
                    <div className="cm-form-group">
                        <input
                            type="text"
                            className={data.inputSize}
                            placeholder="Email" 
                        />
                    </div>
                        
                    <div className="cm-d-flex cm-justify-content-center">
                        <button 
                            className={"btn " + data.size + " " + data.color + " " + data.className + (buttonBgColor.length > 1 ? " gradient" : "")} 
                        >
                            <TinyMCEEditor 
                                value = {data.text}
                                OnEditorChange={(content) => OnEditorChange(content)}
                                toolbar = 'bold italic forecolor removeformat'
                                forcedRootBlock = {"div"}
                            />
                        </button>
                    </div>
                </div>
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Form;