import { useApolloClient } from "@apollo/client";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GET_SELECTED_FAQS } from "../Queries/contentManager";
import { GetApolloErrorText,ConvertHtmlToText } from "../../../GlobalFunctions/helper";

const UseEditFaqs = (
    lang,
    selectedFaqs,
    SetFormData
) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [loading,SetLoading] = useState(false);
    const [error,SetError] = useState("");

    useEffect(() => {

        if(selectedFaqs)
        {
            const contentData = GetContentData(selectedFaqs);
            SetContent(contentData);
        }

    },[selectedFaqs])

    var headerData = [
        {
            value: t("Question"),
            className:"flex-1 no-padding-left"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            
            var cData = {
                data:[
                    {
                        value:
                            <span 
                                className="cursor-help"
                                title={ConvertHtmlToText(val.question)}
                            >
                                {ConvertHtmlToText(val.question)}
                            </span>,
                        className:"flex-1 one-line-with-dots"
                    }
                ],
            }
            cData.rowID         = val.faqID;
            cData.selected      = false;

            contentData.push(cData)   
        }

        return contentData;
    }

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {          
            var newList = [...selectedFaqs];
            const [removed] = newList.splice(result.source.index, 1);
            newList.splice(result.destination.index, 0, removed);

            SetFormData("selectedFaqs",newList);
        }
    }

    const GetSelectedFaqs = async (selectedFaqs) => {
        
        var newSelectedFaqs = [];
        if(selectedFaqs && selectedFaqs.length > 0)
        {
            SetLoading(true);
            
            try{
                var faqListData = await client.query({
                    query: GET_SELECTED_FAQS,
                    variables:{
                        lang:lang,
                        faqIDs: selectedFaqs
                    },
                    fetchPolicy:"network-only"
                });

                if(faqListData && faqListData.data.AllFaqsByFaqIDs)
                {
                    for(let id of selectedFaqs)
                    {
                        for(let val of faqListData.data.AllFaqsByFaqIDs)
                        {
                            if(id == val.faqID)
                                newSelectedFaqs.push({
                                    faqID:val.faqID,
                                    question:val.question
                                })
                        }
                    }
                }
            }
            catch(err)
            {
                SetLoading(false);
                SetError(GetApolloErrorText(err));
            }

            SetLoading(false);
        }

        return newSelectedFaqs;
    }

    return{
        headerData,
        content,
        loading,
        error,
        GetSelectedFaqs,
        OnDragEnd
    }

}

export default UseEditFaqs;