import React, { useEffect } from 'react';
import {Routes, Route, useLocation } from 'react-router-dom';
import Login from '../Modules/Auth/Components/Login';
import NewPassword from '../Modules/Auth/Components/NewPassword';
import ResetPassword from '../Modules/Auth/Components/ResetPassword';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Links from '../Modules/Content/Components/Links.js';
import Manufacturers from '../Modules/Eshop/Components/Manufacturers/Manufacturers';
import Parameters from '../Modules/Eshop/Components/Parameters/Parameters';
import ParameterTemplates from '../Modules/Eshop/Components/ParameterTemplates/ParameterTemplates';
import Covers from '../Modules/Eshop/Components/Covers/Covers';
import CoverTitles from '../Modules/Eshop/Components/CoverTitles/CoverTitles';
import Products from '../Modules/Eshop/Components/Products/Products';
import ProductReviews from '../Modules/Eshop/Components/Products/ProductReviews';
import Labels from '../Modules/Eshop/Components/Labels/Labels';
import TickLabels from '../Modules/Eshop/Components/TickLabels/TickLabels';
import Layout from '../Layout/Layout.js';
import NoMatch from './NoMatch';
import EmailSettings  from '../Modules/Settings/Components/EmailSettings';
import TranslationSettings  from '../Modules/Settings/Components/TranslationSettings';
import Languages  from '../Modules/Settings/Components/Languages';
import Orders from '../Modules/Orders/Components/Orders';
import BillingInformation from '../Modules/Orders/Components/BillingInformation';
import Coupons from '../Modules/Eshop/Components/Coupons/Coupons';
import Newsletter from '../Modules/Newsletter/Components/Newsletter';
import Projects from '../Modules/Project/Components/Projects';
import Forms from '../Modules/Form/Components/Forms';
import Campaigns from '../Modules/Campaign/Components/Campaigns';
import NewsletterSettings from '../Modules/Newsletter/Components/NewsletterSettings';
import ScheduledNewsletter from '../Modules/Newsletter/Components/ScheduledNewsletter';
import NewsletterStatistics from '../Modules/Newsletter/Components/NewsletterStatistics';
import Articles from '../Modules/Articles/Components/Articles';
import MarketingBanners from '../Modules/Articles/Components/MarketingBanners';
import Reviews from '../Modules/Reviews/Components/Reviews';
import Faq from '../Modules/Faq/Components/Faq';
import AdminRoles from '../Modules/Administrators/Components/AdminRoles';
import { UseProject, UseSettings } from '../AdminProvider';
import AdminUsers from '../Modules/Administrators/Components/AdminUsers';
import Customers from '../Modules/Customer/Components/Customers';
import CustomerRoles from '../Modules/Customer/Components/CustomerRoles';
import { UseAuth } from '../Modules/Auth/Library/UseAuth';
import Loading from '../GlobalComponents/Loading';
import Reservations from '../Modules/Reservation/Components/Reservations';
import ReservationPlaces from '../Modules/Reservation/Components/ReservationPlaces';
import BlockedDays from '../Modules/Reservation/Components/BlockedDays';
import NoEnabledProjectModule from './NoEnabledProjectModule';
import Memberships from '../Modules/Membership/Components/Memberships';
import Currencies from '../Modules/Settings/Components/Currencies';
import ReservationPlaceVariations from '../Modules/Reservation/Components/ReservationPlaceVariations';
import Questionnaires from '../Modules/Questionnaire/Components/Questionnaires';

const Routing = () => {

    const {settings,RefetchSettings} = UseSettings();
    const {pathname} = useLocation();
    const {user,HasEnabledModule} = UseAuth();
    const {IsModulEnabled} = UseProject();

    useEffect(() => {

		if(settings.adminVersion)
		{
			var version = localStorage.getItem("lsAdmVer");
			if(version != settings.adminVersion)
			{
				localStorage.setItem("lsAdmVer",settings.adminVersion);
				window.location.reload(true);
			}  
		}
		  
	},[settings])

    useEffect(() => {
        RefetchSettings();
    },[pathname])

    /*
    useEffect(() => {
 
        const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;
        
        const checkIsIOS = () =>
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        
        if (checkIsIOS()) {
            disableIosTextFieldZoom();
        }

    })

    const addMaximumScaleToMetaViewport = () => {

        const el = document.querySelector('meta[name=viewport]');
      
        if (el !== null) {
          let content = el.getAttribute('content');
          let re = /maximum\-scale=[0-9\.]+/g;
      
          if (re.test(content)) {
              content = content.replace(re, 'maximum-scale=1.0');
          } else {
              content = [content, 'maximum-scale=1.0'].join(', ')
          }
      
          el.setAttribute('content', content);
        }
    };
		 */ 

    const SetComponent = (moduleID,component,checkProjectEnabledModule) => {
        if(user.adminUserID == 0)
            return <Loading />;
        else if(HasEnabledModule(moduleID))
        {
            if(checkProjectEnabledModule && IsModulEnabled(moduleID) || !checkProjectEnabledModule)
                return component;
            else
                return <NoEnabledProjectModule />
        }
        else 
            return <NoMatch />; 
    }

    return (
        <Routes>
        
            <Route exact path='/' element={<Login />}></Route>
            <Route exact path='/reset-password' element={<ResetPassword />}></Route>
            <Route exact path='/new-password/:code' element={<NewPassword />}></Route>
            <Route path='/*' element={<Layout />}>
                <Route exact path='customers/list' element={SetComponent(61,<Customers />)}></Route>
                <Route exact path='customers/roles' element={SetComponent(62,<CustomerRoles />)}></Route>
                <Route exact path='orders/list' element={SetComponent(70,<Orders />)}></Route>
                <Route exact path='orders/billing-information' element={SetComponent(71,<BillingInformation />)}></Route>
                <Route exact path='projects' element={SetComponent(3,<Projects />)}></Route>
                <Route exact path='forms' element={SetComponent(8,<Forms />)}></Route>
                <Route exact path=':project/campaigns' element={SetComponent(7,<Campaigns />,true)}></Route>
                <Route exact path=':project/content/links' element={SetComponent(38,<Links />,true)}></Route>
                <Route exact path=':project/content/reviews' element={SetComponent(39,<Reviews />,true)}></Route>
                <Route exact path=':project/content/faq' element={SetComponent(55,<Faq />,true)}></Route>
                <Route exact path=':project/memberships/list' element={SetComponent(40,<Memberships />,true)}></Route>
                <Route exact path=':project/memberships/questionnaires' element={SetComponent(12,<Questionnaires />,true)}></Route>
                <Route exact path='eshop/parameters' element={SetComponent(23,<Parameters />)}></Route>
                <Route exact path='eshop/parameter-templates' element={SetComponent(24,<ParameterTemplates />)}></Route>
                <Route exact path='eshop/categories' element={SetComponent(22,<Links isEshopCategory = {true} />)}></Route>
                <Route exact path='eshop/manufacturers' element={SetComponent(25,<Manufacturers />)}></Route>
                <Route exact path='eshop/covers' element={SetComponent(26,<Covers />)}></Route>
                <Route exact path='eshop/cover-titles' element={SetComponent(27,<CoverTitles />)}></Route>
                <Route exact path='eshop/products' element={SetComponent(21,<Products />)}></Route>
                <Route exact path='eshop/labels' element={SetComponent(28,<Labels />)}></Route>
                <Route exact path='eshop/ticklabels' element={SetComponent(29,<TickLabels />)}></Route>
                <Route exact path='eshop/coupons' element={SetComponent(30,<Coupons />)}></Route>
                <Route exact path='eshop/product-reviews' element={SetComponent(31,<ProductReviews />)}></Route>
                <Route exact path='newsletter/send' element={SetComponent(33,<Newsletter />)}></Route>
                <Route exact path='newsletter/settings' element={SetComponent(35,<NewsletterSettings />)}></Route>
                <Route exact path='newsletter/schedule' element={SetComponent(34,<ScheduledNewsletter />)}></Route>
                <Route exact path='newsletter/statistics' element={SetComponent(36,<NewsletterStatistics />)}></Route>
                <Route exact path='settings/email' element={SetComponent(19,<EmailSettings />)}></Route>
                <Route exact path='settings/translations' element={SetComponent(54,<TranslationSettings />)}></Route>
                <Route exact path='settings/languages' element={SetComponent(20,<Languages />)}></Route>
                <Route exact path='settings/change-password' element={SetComponent(18,<ChangePassword />)}></Route>
                <Route exact path='settings/currencies' element={SetComponent(67,<Currencies />)}></Route>
                <Route exact path='articles/list' element={SetComponent(37,<Articles />)}></Route>
                <Route exact path='articles/marketing-banners' element={SetComponent(9,<MarketingBanners />)}></Route>
                <Route exact path='administrators/list' element={SetComponent(57,<AdminUsers />)}></Route>
                <Route exact path='administrators/roles' element={SetComponent(58,<AdminRoles />)}></Route>
                <Route exact path='reservations/list' element={SetComponent(74,<Reservations />)}></Route>
                <Route exact path='reservations/places' element={SetComponent(59,<ReservationPlaces />)}></Route>
                <Route exact path='reservations/blocked-days' element={SetComponent(60,<BlockedDays />)}></Route>
                <Route exact path='reservations/variations' element={SetComponent(69,<ReservationPlaceVariations />)}></Route>
                <Route path="*" element={<NoMatch />} />
            </Route>          
	        <Route path="*" element={<NoMatch />} />

	    </Routes>
    );
  
}
export default Routing;