import Diary from "./Diary";
import Settings from "./Settings";
import Care from "./Care";
import User from "./User";
import Logout from "./Logout";
import Moon from "./Moon";
import Sun from "./Sun";
import Edit from "./Edit";
import Select from "./Select";
import Exchange from "./Exchange";
import Menu from "./Menu";
import Email from "./Email";
import Lock from "./Lock";
import Invoice from "./Invoice";
import Content from "./Content";
import Copy from "./Copy";
import Delete from "./Delete";
import TickInCircle from "./TickInCircle";
import Move from "./Move";
import Orders from "./Orders";
import Article from "./Article";
import Eshop from "./Eshop";
import Reservation from "./Reservation";
import Form from "./Form";
import Project from "./Project";
import Newsletter from "./Newsletter";
import Administrator from "./Administrator";
import WebContent from "./WebContent";
import Membership from "./Membership";
import DashBoard from "./Dashboard";
import Campaign from "./Campaign";
import GiftCard from "./GiftCard";
import Cancel from "./Cancel";

const AllIcons = ({
    className,
    name,
    primaryColor,
    secondaryColor
}) => {

    var primaColor = "#43AAE3";
    var secColor = "#43AAE3";

    if(secondaryColor)
        secColor = secondaryColor;

    if(primaryColor)
        primaColor = primaryColor;
    
    switch(name)
    {
        case "diary": return <Diary className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "care": return <Care className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "settings": return <Settings className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "user": return <User className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "logout": return <Logout className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "moon": return <Moon className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "sun": return <Sun className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "edit": return <Edit className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "select": return <Select className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "exchange": return <Exchange className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "menu": return <Menu className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "email": return <Email className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "lock": return <Lock className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "invoice": return <Invoice className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "content": return <Content className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "copy": return <Copy className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "delete": return <Delete className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "tickInCircle": return <TickInCircle className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "move": return <Move className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "orders": return <Orders className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "article": return <Article className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "eshop": return <Eshop className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "reservation": return <Reservation className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "form": return <Form className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "project": return <Project className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "newsletter": return <Newsletter className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "administrator": return <Administrator className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "webContent": return <WebContent className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "membership": return <Membership className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "dashboard": return <DashBoard className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "campaign": return <Campaign className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "giftCard": return <GiftCard className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        case "cancel": return <Cancel className={className} primaryColor = {primaColor} secondaryColor = {secColor} />
        default: return ""
    }
}

export default AllIcons;