import {useQuery} from '@apollo/client';
import { GET_CAMPAIGN_CONTACTS } from '../Queries/campaignContacts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import YesNo from '../../../GlobalComponents/YesNo';
import { GET_ALL_ENABLED_LANGUAGES } from '../../Settings/Queries/language';
import dayjs from 'dayjs';

const UseGetAllCampaignContacts = (campaignID) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedCampaignContactIDs,SetSelectedCampaignIDs] = useState([]);
    const [allLanguages,SetAllLanguages] = useState([]);

    const [variables,SetVariables] = useState({
        lang:"",
        campaignID,
        searchText:"",
        offset:0,
        limit:40
    });

    const {data:languages,loading:langLoading,error:langError} = useQuery(GET_ALL_ENABLED_LANGUAGES,{
        fetchPolicy:"network-only"
    })

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_CAMPAIGN_CONTACTS,{
        variables:variables,
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCampaignContacts);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[data])

    useEffect(() => {

        if(languages)
        {
            var lang = [...languages.AllLanguageMutations];
            lang.unshift({
                languageID:0,
                suffix:""
            })
            SetAllLanguages(lang);
        }

    },[languages])

    var headerData = [
        {
            value: t("Lang"),
            className:"static smallest  text-center"
        },
        {
            value: t("Email"),
            className:"flex-1"
        },
        {
            value: t("Added"),
            className:"static smaller text-center"
        }, 
        {
            value: t("NextSend"),
            className:"static smaller text-center"
        }, 
        {
            value: t("Finished"),
            className:"static smaller text-center"
        }, 
        {
            value: t("Subscribed"),
            className:"static smaller text-center"
        },
        {
            value: t("SubscribedByAdmin"),
            className:"static smaller text-center"
        }       
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            console.log(val);
            var cData = {
                data:[
                    {
                        value:val.lang,
                        className:"static smallest text-center"
                    },
                    {
                        value:val.email,
                        className:"flex-1"
                    },
                    {
                        value: (val.dateAdd != "0000-00-00 00:00:00" ?
                                dayjs(val.dateAdd).format("DD.MM.YYYY HH:mm:ss")
                            :
                                "- - -"),
                        className:"static smaller text-center"
                    },
                    {
                        value: (val.sendDate != "0000-00-00 00:00:00" ?
                                    dayjs(val.sendDate).format("DD.MM.YYYY HH:mm:ss")
                                :
                                    "- - -"),
                        className:"static smaller text-center"
                    },
                    {
                        value:<YesNo isYes={!!val.isFinished} />,
                        className:"static smaller text-center"
                    },
                    {
                        value: (val.dateUnsubscribe != "0000-00-00 00:00:00" ?
                                dayjs(val.dateUnsubscribe).format("DD.MM.YYYY HH:mm:ss")
                            :
                                "- - -"),
                        className:"static smaller text-center"
                    },
                    {
                        value:<YesNo isYes={val.unsubscribeByAdminID == 0 ? false : true} />,
                        className:"static smaller text-center"
                    }
                ],
            }

            cData.rowID    = val.campaignContactID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllCampaignContacts: [...prev.AllCampaignContacts, ...fetchMoreResult.AllCampaignContacts]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    const FilterContacts = (filterData) => {

        var newVariables = {...variables};
        var updated = false;
       
        if(filterData.searchText != "" || (filterData.searchText == "" && newVariables.searchText != ""))
        {
            newVariables.searchText = filterData.searchText;
            updated = true;
        }

        if(newVariables.lang != filterData.lang)
        {
            newVariables.lang = filterData.lang;
            updated = true;
        }
        
        if(updated)
            SetVariables(newVariables)

    }

    const SelectRow = (e,campaignContactID) => {

        var checked = e.target.checked;
        var newSelectedCampaignIDs = [...selectedCampaignContactIDs];
        const newList = SelectCampaignID(content,campaignContactID,checked,newSelectedCampaignIDs)

        SetContent(newList);
        SetSelectedCampaignIDs(newSelectedCampaignIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedCampaignIDs = [];
        const newList = SelectCampaignID(content,"all",checked,newSelectedCampaignIDs)

        SetSelectedCampaignIDs(newSelectedCampaignIDs);
        SetContent(newList);
    }

    const SelectCampaignID = (list,campaignContactID,checked,selectedCampaignContactIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(campaignContactID == newList[i].rowID || campaignContactID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedCampaignContactIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedCampaignContactIDs.length; j++)
                    {
                        if(selectedCampaignContactIDs[j] == newList[i].rowID)
                            selectedCampaignContactIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectCampaignID(newList[i].subData,campaignContactID,checked,selectedCampaignContactIDs)}
        }

        return newList;
    }

    return{
        content,
        variables,
        headerData,
        loading: loading || langLoading,
        error: error || langError,
        selectedCampaignContactIDs,
        totalContentLength: data && data.AllCampaignContactsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        allLanguages,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll,
        FilterContacts
    }

}

export default UseGetAllCampaignContacts;