import Modal from 'react-modal';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditProductReview from '../../Library/Products/UseAddEditProductReview';
import UseGetProductReview from '../../Library/Products/UseGetProductReview';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../../GlobalFunctions/helper';
import RadioButton from '../../../../GlobalComponents/RadioButton';
import Input from '../../../../GlobalComponents/Input';
import Textarea from '../../../../GlobalComponents/Textarea';
import eye from '../../../../Media/Images/Icons/eye.webp';
import plusIcon from '../../../../Media/Images/Icons/plus.webp';
import minusIcon from '../../../../Media/Images/Icons/minus.webp';
import imageIcon from '../../../../Media/Images/Icons/image.webp';
import InputCard from '../../../../GlobalComponents/InputCard';
import Warning from '../../../../GlobalComponents/Warning';
import { useApolloClient } from '@apollo/client';
import UseHeader from '../../../../GlobalHooks/UseHeader';
import { 
    PRODUCT_IMAGE_URL, 
    SERVER_URL,
    PRODUCT_REVIEW_IMAGE_URL
} from '../../../../Config';

Modal.setAppElement('#root');

const AddEditProductReview = ({selectedProductReviewID,SetSelectedProductReviewID}) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const {DecreaseNotApprovedProductReviewCount} = UseHeader();
    const {
        loading,
        error,
        formData,
        AddEditProductReview,
        InitFormData,
        SetFormData
    } = UseAddEditProductReview(selectedProductReviewID,async (d) => {

        await client.refetchQueries({
            include: ["AllProductReviews"],
        });

        DecreaseNotApprovedProductReviewCount();
        SetSelectedProductReviewID(null);
    });

    const {
        loading:getLoading,
        error:getError,
        product
    } = UseGetProductReview(selectedProductReviewID,(d) => {
        InitFormData(d);
    });

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedProductReviewID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedProductReviewID === 0 ? t("AddProductReview") : t("EditProductReview"))}
                OnClose ={() => SetSelectedProductReviewID(null)}
            />

            <div className="modal-body">

                {err ?
                    <Error className = "no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                <div className="row">

                                    <div className="col-33">
                                        <InputCard
                                            title = {t("Product")}
                                            icon = {imageIcon}
                                            className = {"form-group flex-1"}
                                        > 
                                            {product ?
                                                <div className="form-group">
                                                    <div className="open-image-container">
                                                        <img src={SERVER_URL + "/" + PRODUCT_IMAGE_URL + "/p-" + product.productID + "/Variants/velka_" + product.firstVariant.photo} />
                                                    </div>
                                                    <h2>
                                                        <a target={"_blank"} href={"/produkt/" + product.productID + "-" + (product.seoUrl ? product.seoUrl : product.niceName)}>{product.name}</a>
                                                    </h2>
                                                </div>
                                            :null}
                                        
                                        </InputCard>
                                    </div>

                                    <div className="col-66">
                                        <InputCard
                                            title = {t("Display")}
                                            icon = {eye}
                                            className = {"form-group"}
                                        >   
                                            <div className="row">
                                                    
                                                <div className="col-50">
                                                    <div className="form-group">
                                                        
                                                        <label>{t("Approved")}</label>
                                                        <div className="radio-container">
                                                            <RadioButton
                                                                text="Ano"
                                                                name="approved"
                                                                id="approved-1"
                                                                value={2}
                                                                checked = {formData.approved === 2 ? true : false}
                                                                OnChange={(e) => SetFormData(e)}
                                                            />
                                                            <RadioButton
                                                                text="Ne"
                                                                name="approved"
                                                                id="approved-2"
                                                                value={1}
                                                                checked = {formData.approved === 1 ? true : false}
                                                                OnChange={(e) => SetFormData(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-50">
                                                    <div className="form-group">
                                                        
                                                        <label>{t("Pinned")}</label>
                                                        <div className="radio-container">
                                                            <RadioButton
                                                                text="Ano"
                                                                name="pinned"
                                                                id="pinned-1"
                                                                value={1}
                                                                checked = {formData.pinned === 1 ? true : false}
                                                                OnChange={(e) => SetFormData(e)}
                                                            />
                                                            <RadioButton
                                                                text="Ne"
                                                                name="pinned"
                                                                id="pinned-2"
                                                                value={0}
                                                                checked = {formData.pinned === 0 ? true : false}
                                                                OnChange={(e) => SetFormData(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </InputCard>
                                        <div className="row">
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>{t("Rating")}</label>
                                                    <Input 
                                                        value = {formData.rating}
                                                        name = "rating"
                                                        OnChange = {(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>{t("FirstName")}</label>
                                                    <Input 
                                                        value = {formData.name}
                                                        name = "name"
                                                        OnChange = {(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>{t("Email")}</label>
                                                    <Input 
                                                        value = {formData.email}
                                                        name = "email"
                                                        OnChange = {(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>{t("City")}</label>
                                                    <Input 
                                                        value = {formData.city}
                                                        name = "city"
                                                        OnChange = {(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="form-group">
                                    <label>{t("Description")}</label>
                                    <Textarea 
                                        value = {formData.description}
                                        name = "description"
                                        OnChange = {(e) => SetFormData(e)}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-50">
                                        <div className="form-group">
                                            <InputCard
                                                title = {t("Pros")}
                                                icon = {plusIcon}
                                                className = {"form-group"}
                                            > 
                                                {formData.pros && formData.pros.length > 0 ?
                                                   
                                                    <ul className="form-group">
                                                        {formData.pros.map((item,index) => (
                                                            <li key= {index}>{item.value}</li>
                                                        ))}
                                                    </ul>
                                                :
                                                    <Warning text={t("DoNotFilled")} />
                                                }
                                            </InputCard>
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="form-group">
                                            <InputCard
                                                title = {t("Cons")}
                                                icon = {minusIcon}
                                                className = {"form-group"}
                                            > 
                                                {formData.cons && formData.cons.length > 0 ?
                                                    <ul className="form-group">
                                                        {formData.cons.map((item,index) => (
                                                            <li key= {index}>{item.value}</li>
                                                        ))}
                                                    </ul>
                                                :
                                                    <Warning text={t("DoNotFilled")} />
                                                }
                                            </InputCard>
                                        </div>
                                    </div>
                                </div>
                                
                                <InputCard
                                    title = {t("Photos")}
                                    icon = {imageIcon}
                                    className = {""}
                                > 
                                    {formData.photos && formData.photos.length > 0 ?
                                        <div className="row">
                                            {formData.photos.map((photo) => (
                                                <div className="col-50">
                                                    <div className="cm-image-container form-group">
                                                        <img src={SERVER_URL + "/" + PRODUCT_REVIEW_IMAGE_URL + "/pr-" + selectedProductReviewID + "/velka_" + photo.name} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    :
                                        <Warning text={t("DoNotFilled")} />
                                    }
                                </InputCard>
                                
   
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditProductReview()}
                >
                    {(selectedProductReviewID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditProductReview;