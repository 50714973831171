import { useQuery } from "@apollo/client";
import { GET_RESERVATION_PLACE } from "../Queries/reservationPlace";
import { useState } from "react";
import { CompleteLangsArray, GetMainLanguage } from "../../../GlobalFunctions/helper";
import { SERVER_URL, RESERVATION_PLACE_IMAGE_URL } from "../../../Config";
import { GetCustomerObj } from "./UseEditReservationPlaceCustomers";

const UseGetReservationPlace = (selectedReservationPlaceID,lang,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const [allProjects, SetAllProjects] = useState([]);
    const [mainLang, SetMainLang] = useState({});

    const {loading,error} = useQuery(GET_RESERVATION_PLACE,{
        variables:{
            reservationPlaceID:selectedReservationPlaceID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                projectData:[],
            }

            if(selectedReservationPlaceID != 0)
            {
                let langs = CompleteLangsArray(d.ReservationPlaceWithLangs.langs,d.AllLanguageMutations,emptyLang);
                
                CallBack({
                    active:                     d.ReservationPlaceWithLangs.active,
                    email:                      d.ReservationPlaceWithLangs.email,
                    internalName:               d.ReservationPlaceWithLangs.internalName,
                    googleCredentialsFile:      null,
                    googleCredentialsFileName:  d.ReservationPlaceWithLangs.googleCredentialsFile,
                    googleCalendarID:           d.ReservationPlaceWithLangs.googleCalendarID,
                    dateEdit:                   d.ReservationPlaceWithLangs.dateEdit,
                    useGoogleCalendar:          (d.ReservationPlaceWithLangs.googleCalendarID ? 1 : 0),
                    langs:                      langs,
                    projectData:                GetProjectData(d.ReservationPlaceWithLangs.projectData),
                    selectedTimes:              GetTimes(d.ReservationPlaceWithLangs.times),
                    selectedCustomers:          GetCustomers(d.ReservationPlaceWithLangs.customers),
                    generateTranslations:{
                        enabled:false,
                        onlyForParams:[],
                        onlyFor:""
                    },
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
            SetAllProjects(d.AllProjects);

            const mainLang = GetMainLanguage(d.AllLanguageMutations);
            SetMainLang(mainLang);
        }
    });

    const GetCustomers = (customers) => {
    
        if(customers)
        {
            var newCustomers = [];
            for(let val of customers)
            {
                newCustomers.push(
                    {...GetCustomerObj(val),
                        reservationPlaceCustomerID: val.reservationPlaceCustomerID,
                        name:val.customer.name,
                        surname:val.customer.surname,
                        email:val.customer.email,
                        photo:val.customer.photo,
                        backgroundColor: val.customer.backgroundColor
                    }
                );
            }
            return newCustomers;
        }
        else
            return []
    }

    const GetTimes = (selectedTimes) => {

        var newTimes = [];
        for(let val of selectedTimes)
        {
            newTimes.push({
                reservationPlaceTimeID:val.reservationPlaceTimeID,
                timeFrom:val.timeFrom,
                timeTo:val.timeTo,
                selectedVariations:GetVariations(val.variations)
            })
        }

        return newTimes;
    }

    const GetVariations = (selectedVariations) => {

        var newVariations = [];
        for(let val of selectedVariations)
        {
            newVariations.push({
                reservationPlaceVariationID: val.reservationPlaceVariationID,
                name: (val.reservationPlaceVariation ? val.reservationPlaceVariation.name : "")
            })
        }

        return newVariations;
    }

    const GetProjectData = (projectData) => {
        var newProjectData = [];
        for(let val of projectData)
        {
            newProjectData.push({
                mainPhoto: SERVER_URL + "/" + RESERVATION_PLACE_IMAGE_URL + "/stredni_" + val.photo,
                photo:null,
                projectID:val.projectID
            })
        }

        return newProjectData;
    }

    return{
        loading: loading,
        error: error,
        allLanguageMutations,
        allProjects,
        mainLang
    }

}

export default UseGetReservationPlace;