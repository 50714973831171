import { useQuery } from '@apollo/client';
import Error  from '../../../GlobalComponents/Error';
import UseSaveEchanges from "../Library/Currencies/UseSaveEchanges";
import UseGetAllExchangeRates from '../Library/Currencies/UseGetAllExchangeRates';
import ContentHeader from '../../../GlobalComponents/ContentHeader';
import ContentBody from '../../../GlobalComponents/ContentBody';
import { useTranslation } from 'react-i18next';
import InputCard from '../../../GlobalComponents/InputCard';
import Button from '../../../GlobalComponents/Button';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { useEffect } from 'react';
import { GET_CURRENCIES } from '../Queries/currencies';
import List from '../../../GlobalComponents/List/List';

const Currencies = () => {

    const {t} = useTranslation();

    const {data,loading,error} = useQuery(GET_CURRENCIES,{
        fetchPolicy:"network-only",
    })

    const {
        loading:saveLoading,
        error:saveError,
        allCurrencies,
        SaveExchangeRates,
        CopyAndSaveExchangeRates,
        SetAllCurrencies,
        EditCurrency
    } = UseSaveEchanges();

    const {
        content,
        headerData
    } = UseGetAllExchangeRates(allCurrencies,EditCurrency);

    useEffect(() => {
        if(data)
            SetAllCurrencies(data.AllCurrencies)
    },[data])

    return(
        <>
            <ContentHeader
                title = {t("Currencies")}
                OnSaveButtonClick = {() => SaveExchangeRates()}
            />
            <ContentBody>

                <InputCard
                    title = {t("ExangeRates")}
                    noBodyPadding={true}
                    headerChildren={
                        <Button 
                            className="btn-primary narrow" 
                            OnClick={(e) => CopyAndSaveExchangeRates()}
                            title = "Zkopíruje kurzy ze světa do kolonek a uložíá je do databáze"
                        >{t("CopyEchangeRateInWorldAndSave")}</Button>
                    }
                >
                    {error || saveError ?
                        <Error text={GetApolloErrorText(error || saveError)} />
                    :
                        <List 
                            headerData = {headerData} 
                            contentData = {content}
                            isSortable = {false}
                            loading = {loading || saveLoading}
                            hideOptions={true}
                        />
                    }
                    
                </InputCard>
                        
            </ContentBody>
        </>
    )
}

export default Currencies;