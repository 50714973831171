import ModalHeader from "../../../../GlobalComponents/Modal/ModalHeader"
import { useTranslation } from "react-i18next";
import { UseContentManager } from "../../Library/UseContentManager";
import Button from "../../../../GlobalComponents/Button";
import { GET_ALL_VIDEOS } from "../../Queries/fileManager";
import Loading from "../../../../GlobalComponents/Loading";
import Error from "../../../../GlobalComponents/Error";
import { useQuery,useApolloClient } from "@apollo/client";
import NoItems from "../../../../GlobalComponents/NoItems";
import { CONTENT_MANAGER_VIDEO_URL, SERVER_URL } from "../../../../Config";
import Checkbox from "../../../../GlobalComponents/Checkbox";
import UseDeleteVideos from "../../Library/FileManager/UseDeleteVideos";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { useState } from "react";
import AddVideo from "./AddVideo";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import Video from "../../../../GlobalComponents/Video";

const VideosList = ({
    videoTypeID
}) => {

    const client            = useApolloClient();
    const {t}               = useTranslation();
    const {AddNotification} = UseNotification();

    const {openFileManagerData,CloseFileManager,ChooseFileManagerVideo} = UseContentManager();

    const [showAddVideo, SetShowAddVideo] = useState(false);

    const { 
        selectedItems, 
        showDeleteNotification, 
        DeleteVideos,
        SelectVideo,
        ShowDeleteNotification 
    } = UseDeleteVideos(client,videoTypeID);

    const {data,loading,error} = useQuery(GET_ALL_VIDEOS,{
        fetchPolicy:"network-only",
        variables:{
            videoTypeID
        }
    });

    const SelVideo = (openFileManagerData,item) => {
        if(!openFileManagerData.selectMultiple)
            ChooseFileManagerVideo(openFileManagerData,item);
    }

    const ChooseVideos = () => {
        if(selectedItems.length > 0)
            ChooseFileManagerVideo(openFileManagerData,selectedItems);
        else
            AddNotification(t("NoSelectedVideos"),false);
    }

    var err = "";
    if(error)
        err = GetApolloErrorText(error);

    return(
        <>
            <ModalHeader 
                title = {t("VideosList")}
                OnClose ={() => CloseFileManager()}
            >
                {openFileManagerData.selectMultiple ?
                    <Button
                        className = "btn-primary"
                        OnClick = {() => ChooseVideos()}
                    >
                        {t("Choose")}
                    </Button>
                :null}
                <Button
                    className = "btn-primary"
                    OnClick = {() => SetShowAddVideo(true)}
                >
                    {t("UploadVideo")}
                </Button>

                <Button
                    className = "btn-brown"
                    OnClick = {() => ShowDeleteNotification(true)}
                >
                    {t("DeleteSelected")}
                </Button>

            </ModalHeader>

            <div className="modal-body">

                {err ?
                    <Error text = {err} />
                :
                    (loading ?
                        <Loading />
                    :
                        (data.AllContentManagerVideos.length > 0 ?
                            <div className="row">
                                {data.AllContentManagerVideos.map((item,index) => {
                                    
                                    var check = false;
                                    for(let val of selectedItems)
                                    {
                                        if(val.contentManagerVideoID == item.contentManagerVideoID)
                                            check = true;   
                                    }

                                    return(
                                        <div key={item.contentManagerVideoID} className="col-20">
                                            <div className="cm-image-container form-group">
                                                
                                                <div className="select">
                                                    <Checkbox 
                                                        text = {""}
                                                        name = "selectedImage"
                                                        checked = {check}
                                                        OnChange = {() => SelectVideo(item)}
                                                    />
                                                </div>
                                                <Video 
                                                    hideControls = {true}
                                                    data = {{
                                                        videoTypeID:item.contentManagerVideoTypeID,
                                                        embedCode:item.embedCode,
                                                        mp4File: item.name,
                                                        mp4FilePoster: item.poster
                                                    }}
                                                />
                                                <div
                                                    onClick = {() => SelVideo(openFileManagerData,item)}
                                                    className="iframe-overlay"
                                                ></div>
                                                
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        :
                            <NoItems
                                className = {"justify-content-center no-margin"}
                                text={t("NoVideosHereYet")} 
                            />
                        )
                    )
                }
                
            </div>
            <div className="modal-footer"></div>

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteVideos")} 
                    CallBack={DeleteVideos} 
                />
            :null}

            <AddVideo 
                videoTypeID = {videoTypeID}
                showAddVideo = {showAddVideo}
                SetShowAddVideo = {SetShowAddVideo}
            />
        </>
    )
}

export default VideosList