import DatePicker from "../../../GlobalComponents/DatePicker";
import TinyMCEEditor from "../../TinyMCEEditor/Components/TinyMCEEditor";
import { TINY_MCE_TOOLBAR_MEDIA } from "../../../Config";
import { useTranslation } from "react-i18next";
import Input from "../../../GlobalComponents/Input";
import Select from "../../../GlobalComponents/Select";
import Label from "../../../GlobalComponents/Label";
import SendTestEmailForm from "./SendTestEmailForm";

const NewsletterForm = ({
    isPlanned,
    formData,
    allProjects,
    allCampaigns,
    GetFormLangValue,
    SetFormLangData,
    SetFormData
}) => {

    const {t} = useTranslation();

    return(
        <div> 
            <div className="form-group row">
                <div className="col-50">
                    <Label 
                        text={t("EmailDatabase")}
                        infoContent={[t("EmailDatabaseInfo")]}
                    />
                    <Select
                        name="projectID"
                        value={formData.projectID}
                        OnChange={(e) => SetFormData(e)}
                    >
                        <option value={0}>{t("GlobalAllEmails")}</option>
                        {allProjects && allProjects.map((item) => (
                            <option 
                                key={item.projectID}
                                value={item.projectID}
                            >{item.name}</option>
                        ))}
                    </Select>
                </div>
                <div className="col-50">
                    {allCampaigns && allCampaigns.length > 0 ?
                        <>
                            <Label 
                                text={t("Campaign")}
                                infoContent={[
                                    t("NewsletterCampaignInfo1"),
                                    t("NewsletterCampaignInfo2")
                                ]}
                            />
                            <Select
                                name="campaignID"
                                value={formData.campaignID}
                                OnChange={(e) => SetFormData(e)}
                            >
                                <option value={0}>{"- - -" + t("Choose") + "- - -"}</option>
                                {allCampaigns.map((item) => (
                                    <option 
                                        key={item.campaignID}
                                        value={item.campaignID}
                                    >{item.name}</option>
                                ))}
                            </Select>
                        </>
                    :null}
                </div>
            </div>
            {isPlanned ?
                <div className="form-group">
                    <label>{t("PublicationDate")}</label>             
                    <DatePicker 
                        OnChange = {(_,date) => SetFormData("plannedDate",date)}
                        value = {formData.plannedDate}
                        showPast = {false}
                        showLegend = {false}
                    />
                </div>
            :null}
            <div className="form-group">
                <label>{t("EmailSubject")} ({formData.selectedLang})</label>
                <Input
                    name="subject"
                    value={GetFormLangValue("subject")}
                    OnChange={(e) => SetFormLangData(e)}
                />
            </div>
            <div className="form-group">
                <label>{t("Preheader")} ({formData.selectedLang})</label>
                <Input
                    name="preheader"
                    value={GetFormLangValue("preheader")}
                    OnChange={(e) => SetFormLangData(e)}
                />
            </div>
            <div className="form-group">

                <label>{t("MessageText")} ({formData.selectedLang})</label>
                <TinyMCEEditor 
                    value = {GetFormLangValue("text")}
                    OnEditorChange={(content) => SetFormLangData("text",content)}
                    toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                    useMediaEditor={true}
                    height = {1000}
                />

            </div>

            <SendTestEmailForm 
               formData = {formData}  
            />       
               
        </div>
    )
}

export default NewsletterForm;