import { useState,useEffect } from "react";
import Button from "./Button";
import { useTranslation } from 'react-i18next';
import Select from "./Select";
import Checkbox from "./Checkbox";
import settingsWhiteIcon from '../Media/Images/Icons/settings_white.webp';
import GenerateTranslationsSettings from "./GenerateTranslationsSettings";
import { UseSettings } from "../AdminProvider";
import SearchInput from "./SearchInput";
import UseFormHandle from "../GlobalHooks/UseFormHandle";

const ContentHeader = (props) => {

    const {
        title,
        allLanguages,
        allProjects,
        selectedLang,
        selectedProjectID,
        generateTranslations,
        isEdit,
        itemsToTranslate,
        OnLangSelect,
        OnAddButtonClick,
        OnDeleteButtonClick,
        OnFilterClick,
        OnSaveButtonClick,
        OnProjectSelect,
        OnSelectTranslationItems,
        OnGenerateSelect,
        OnTranslateButtonClick,
        OnSearch
    } = props;

    const {settings} = UseSettings();
    const {t} = useTranslation();
    const [showTranstlationSettings, SetShowTranstlationSettings] = useState(false);

    const {formData,SetFormData} = UseFormHandle({
        searchText:"",
        init:true
    })

    useEffect(() => {

        if(!formData.init)
            OnSearch(formData);

    },[formData])

    return(
        <div className="content-header">

            <h1>{title}</h1>

            {OnSearch ?
                <div className="search-container">
                    <SearchInput
                        initValue = {formData.searchText}
                        placeholder = {t("Search...")}
                        OnChange = {(value) => {
                            SetFormData("searchText",value);
                            SetFormData("init",false);
                        }}
                    />
                </div>
            :null}

            <div className="buttons">
                {generateTranslations && settings.useThirdPartyForTransaltion != "0" ?
                    <>
                        <Checkbox 
                            text={t("GenerateAllTranslations")}
                            id = "generate-transaltions"
                            name = "generateTranslations"
                            checked = {generateTranslations.enabled}
                            OnChange = {(e) => OnGenerateSelect(e)}
                        />
                        {generateTranslations.enabled && isEdit ?
                            <Button
                                className="btn-blue"
                                OnClick={() => SetShowTranstlationSettings(!showTranstlationSettings)} 
                            ><img src={settingsWhiteIcon} /></Button>
                        :null}

                        {generateTranslations.enabled && OnTranslateButtonClick ?
                            <Button 
                                OnClick={() => OnTranslateButtonClick()} 
                                className="btn-primary"
                            >
                                {t("Translate")}
                            </Button>
                        :null}
                    </>
                :null}
                {(settings.useThirdPartyForTransaltion == "0" || !generateTranslations || (generateTranslations && !generateTranslations.enabled)) && allLanguages && allLanguages.length > 0 ? 
                    <Select 
                        name="selectedLang"
                        value={selectedLang}
                        OnChange={(e) => OnLangSelect(e)}
                    >
                        {allLanguages.map((item) => (
                            <option key={item.languageID} value = {item.suffix}>{(item.suffix == "" ? t("all") : item.suffix)}</option>
                        ))}
                    </Select>
                :null}
                {allProjects && allProjects.length > 0 ? 
                    <Select 
                        name="selectedProjectID"
                        value={selectedProjectID}
                        OnChange={(e) => OnProjectSelect(e.target.value)}
                    >
                        {allProjects.map((item) => (
                            <option 
                                key={item.projectID} 
                                value = {item.projectID}
                            >{item.name}</option>
                        ))}
                    </Select>
                :null}
                {OnFilterClick ?
                    <Button 
                        className="btn-blue"
                        OnClick={() => OnFilterClick()} 
                    >
                        {t("Filter")}
                    </Button>
                :null}
                {OnAddButtonClick ?
                    <Button 
                        OnClick={() => OnAddButtonClick()} 
                        className="btn-primary"
                    >
                        {t("Add")}
                    </Button>
                :null}
                {OnDeleteButtonClick ?
                    <Button 
                        OnClick={() => OnDeleteButtonClick()} 
                        className="btn-brown"
                    >
                        {t("Delete")}
                    </Button>
                :null}
                {OnSaveButtonClick ?
                    <Button 
                        OnClick={() => OnSaveButtonClick()} 
                        className="btn-primary"
                    >
                        {t("Save")}
                    </Button>
                :null}
                
                {showTranstlationSettings ?
                    <GenerateTranslationsSettings 
                        items = {itemsToTranslate}
                        generateTranslations = {generateTranslations}
                        OnSelectTranslationItems = {OnSelectTranslationItems}
                        OnClose={() => SetShowTranstlationSettings(false)}
                    />
                :null}
            </div>
        </div>
    )
}

export default ContentHeader;