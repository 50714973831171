import {useQuery} from '@apollo/client';
import { GET_ALL_CUSTOMER_ROLES } from '../Queries/customerRole';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseChooseCustomerRoles = (
    selectedCustomerRoles,
    FillFormSelectedItemsWithObj
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    
    const {data,loading,error} = useQuery(GET_ALL_CUSTOMER_ROLES,{
        fetchPolicy:"network-only"
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllCustomerRoles);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[data,selectedCustomerRoles])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"lis",
                        backgroundColor: val.backgroundColor,
                        text: val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.selected = false;
            if(selectedCustomerRoles && selectedCustomerRoles.length > 0)
            {
                for(let v of selectedCustomerRoles)
                {
                    if((v.deleted == undefined || v.deleted == false) && val.customerRoleID == v.customerRoleID)
                        cData.selected = true;
                }
            }

            cData.rowBackgroundColor    = val.backgroundColor;
            cData.rowName               = val.name;
            cData.rowID                 = val.customerRoleID;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,data) => {

        SelectFormID(content,data.rowID);    
    }

    const SelectFormID = (list,rowID) => {

        for(let i = 0; i < list.length; i++)
        {
            if(rowID == list[i].rowID || rowID === "all")
            {              
                FillFormSelectedItemsWithObj("selectedCustomerRoles","customerRoleID",{
                    name: list[i].rowName,
                    customerRoleID: list[i].rowID,
                    add:true,
                    deleted:false,
                    backgroundColor: list[i].rowBackgroundColor
                },false);

                break;
            }
        }
    }

    return{
        content,
        headerData,
        loading,
        error,
        SelectRow
    }

}

export default UseChooseCustomerRoles;