import { useState,useEffect } from "react";
import Calendar from "../Modules/Calendar/Components/Calendar";
import dayjs from "dayjs";

const DatePicker = ({OnChange,value,GetBlockedDays,showPast,showLegend}) => {

    const [showCalednar, SetShowCalendar] = useState(false);
    const [date,SetDate] = useState((value && value != "0000-00-00 00:00:00" ? value : ""));

    useEffect(() => {

        if(value == "0000-00-00 00:00:00")
            value = "";

        if(value || value == "")
            SetDate(value);
    },[value])

    const SelectDate = (dateString,date) => {
        SetDate(date);
        OnChange(dateString,date);
        SetShowCalendar(false);
    }

    const OverlayClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        SetShowCalendar(false);
    }

    return(
        <div className="datepicker">
            <input placeholder = {"Vyberte datum"} type = "text" value = {(date ? dayjs(date).format("DD.MM.YYYY") : "")} onClick = {() => SetShowCalendar(true)} onChange = {(e) => e.preventDefault()} />
            {showCalednar ?
                <>
                    <div className="pop-up-calendar">
                        <Calendar 
                            showLegend      = {showLegend} 
                            GetBlockedDays  = {GetBlockedDays} 
                            OnSelectDate    = {SelectDate} 
                            selectedDate    = {date}
                            showPast        = {showPast}
                        />
                    </div>
                    
                    <div onClick = {(e) => OverlayClick(e)} className="pop-up-calendar-overlay"></div>
                </>
            :null}

        </div>
    )
}

export default DatePicker;