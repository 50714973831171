import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditMarketingBanner from "./AddEditMarketingBanner";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllMarketingBanners from "../Library/UseGetAllMarketingBanners";
import UseDeleteMarketingBanners from "../Library/UseDeleteMarketingBanners";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import UseSortList from "../../../GlobalHooks/UseSortList";
import { useApolloClient } from "@apollo/client";
import { GET_ALL_MARKETING_BANNERS,UPDATE_MARKETING_BANNER_PRIORITY } from "../Queries/marketingBanner";

const MarketingBanners = () => {

    const {t} = useTranslation();
    const client = useApolloClient();

    const [selectedMarketingBannerID, SetSelectedMarketingBannerID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedMarketingBannerIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    } = UseGetAllMarketingBanners();

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteMarketingBanners
    } = UseDeleteMarketingBanners(selectedMarketingBannerIDs,(d) => UpdateListAfterDelete(d));

    const {OnDragEnd} = UseSortList(
        client,
        UPDATE_MARKETING_BANNER_PRIORITY,
        GET_ALL_MARKETING_BANNERS,
        "AllMarketingBanners",
        "marketingBannerID",
        null,
        null,
        {},
        {},
    );

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("MarketingBannerList")}
                        OnAddButtonClick={() => SetSelectedMarketingBannerID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {true}
                        loading = {loading}
                        OnDragListRowEnd = {(result) => OnDragEnd(result)}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (marketingBannerID) => SetSelectedMarketingBannerID(marketingBannerID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedMarketingBannerID || selectedMarketingBannerID == 0 ?
                <AddEditMarketingBanner
                    selectedMarketingBannerID = {selectedMarketingBannerID}
                    SetSelectedMarketingBannerID = {SetSelectedMarketingBannerID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedMarketingBannerID,d);
                        SetSelectedMarketingBannerID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteMarketingBanners")} 
                    CallBack={DeleteMarketingBanners} 
                />
            :null}

        </>
    )
}

export default MarketingBanners;