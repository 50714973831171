import { useQuery } from "@apollo/client";
import { GET_RESERVATION_DATA } from "../Queries/reservations";
import { useState } from "react";
import { GetTimeInMinutes, GetTimeInString } from "../../../GlobalFunctions/helper";
import dayjs from "dayjs";

const UseGetReservation = (selectedReservationID,lang,CallBack) => {

    const [allReservationPlaces, SetAllReservationPlaces] = useState([]);

    const {loading,error} = useQuery(GET_RESERVATION_DATA,{
        variables:{
            reservationID:selectedReservationID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            if(selectedReservationID != 0)
            { 
                var date = d.ReservationBy.date.split(" ");
                var timeFrom = dayjs(d.ReservationBy.date).format("HH:mm");

                CallBack({
                    customerHash:                d.ReservationBy.customerHash,
                    customerName:                d.ReservationBy.customer.name + " " + d.ReservationBy.customer.surname,
                    customerLang:                d.ReservationBy.customer.lang,
                    email:                       d.ReservationBy.customer.email,
                    reservationPlaceID:          d.ReservationBy.reservationPlaceID,
                    membershipID:                d.ReservationBy.membershipID,
                    reservationDate:             date[0],
                    reservationPlaceTimeID:      d.ReservationBy.reservationPlaceTimeID,
                    reservationPlaceTimeName:    timeFrom + " - " + GetTimeInString((GetTimeInMinutes(timeFrom) + d.ReservationBy.duration)),
                    reservationPlaceVariationID: d.ReservationBy.reservationPlaceVariationID,
                });
            }

            SetAllReservationPlaces(d.AllReservationPlaces);
        }
    });

    return{
        loading: loading,
        error: error,
        allReservationPlaces
    }

}

export default UseGetReservation;