import { useState } from "react";
import { DELETE_PRODUCTS } from "../../Queries/products";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";

const UseDeleteProducts = (selectedItems,OnCompleted) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);

    const [DelProducts] = useMutation(DELETE_PRODUCTS,{
        onCompleted:(d) => {

            AddNotification(t("TheyAreGone"),true);
            if(OnCompleted)
                OnCompleted(d.DeleteProducts);
        },
        onError:(err) => {          
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            AddNotification(t("NoSelectedRows"),false);
        }
    }

    const DeleteProducts = (action) => {

        if(action)
        {              
            DelProducts({
                variables:{
                    productIDs:selectedItems.join(",")
                }
            })
        }

        SetShowDeleteNotification(false);
    }

    return{
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteProducts
    }
}

export default UseDeleteProducts;