import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useMutation } from "@apollo/client";
import { UPLOAD_VIDEO,GET_ALL_VIDEOS } from "../../Queries/fileManager";
import { ValidateImageMimeType, ValidateSize } from "../../../../GlobalFunctions/images";
import { ValidateVideoMimeType, ValidateSize as ValidateVideoSize } from "../../../../GlobalFunctions/videos";
import { useTranslation } from "react-i18next";
import { MAX_IMAGE_FILE_SIZE_TO_UPLOAD, MAX_VIDEO_FILE_SIZE_TO_UPLOAD } from "../../../../Config";
import UseFormHandle from "../../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";

const UseUploadVideo = (client,videoTypeID,OnSuccess) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const {formData,OpenMedia,SetFormData} = UseFormHandle({
        contentManagerVideoTypeID:videoTypeID,
        videoFile:null,
        posterFile:null,
        embedCode:"",
        orientation:1
    });

    const [UplVideo,{loading,error}] = useMutation(UPLOAD_VIDEO,{
        onCompleted:(d) => {

            const { AllContentManagerVideos } = client.readQuery({ query: GET_ALL_VIDEOS,variables:{
                videoTypeID
            }});
            
            var newArray = [d.UploadContentManagerVideo,...AllContentManagerVideos];

            client.writeQuery({ 
                query:GET_ALL_VIDEOS,
                variables:{
                    videoTypeID
                },
                data:{
                    AllContentManagerVideos: newArray
                } 
            });

            if(OnSuccess)
                OnSuccess();
        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const UploadVideo = async () => {

        if(videoTypeID == 3 && await CheckMp4VideoData() || videoTypeID != 3)
        {
            if(videoTypeID != 3 && CheckYoutubeVimeoVideoData() || videoTypeID == 3)
            {
                UplVideo({
                    variables:{
                        contentManagerVideoTypeID:formData.contentManagerVideoTypeID,
                        videoFile:formData.videoFile,
                        posterFile: formData.posterFile,
                        embedCode: formData.embedCode,
                        orientation: formData.orientation
                    }
                })
            }
        }
    }

    const CheckYoutubeVimeoVideoData = () => {

        if(formData.embedCode)
        {
            return true;
        }else
            AddNotification(t("VideoUrlIsNecessary"),false);

        return false;
    }

    const CheckMp4VideoData = async () => {

        if(formData.videoFile && formData.posterFile)
        {
            var checkImage = true;
            var isImg = await ValidateImageMimeType(formData.posterFile);

            if(!isImg)
                checkImage = false;

            if(checkImage)
            {
                if(ValidateVideoMimeType(formData.videoFile))
                {
                    if(ValidateSize(formData.posterFile))
                    {
                        if(ValidateVideoSize(formData.videoFile))
                        {
                            return true;
                        }else
                            AddNotification(t("MaxAllowedVideoFileSizeIs") + " " + (MAX_VIDEO_FILE_SIZE_TO_UPLOAD / 1000000) + "MB",false);
                    
                    }else
                        AddNotification(t("MaxAllowedImageFileSizeIs") + " " + (MAX_IMAGE_FILE_SIZE_TO_UPLOAD / 1000000) + "MB",false);
                }else
                    AddNotification(t("WrongVideoFormat"),false);
            }else
                AddNotification(t("VideoCoverIsNotImage"),false);
        }else
            AddNotification(t("VideoOrPosterAreNotChoosen"),false);

        return false;
    }

    return{
        formData,
        loading,
        UploadVideo,
        OpenMedia,
        SetFormData
    }
}

export default UseUploadVideo;