import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditReservationPlaceVariation from '../Library/UseAddEditReservationPlaceVariation';
import UseGetReservationPlaceVariation from '../Library/UseGetReservationPlaceVariation';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Input from '../../../GlobalComponents/Input';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';

Modal.setAppElement('#root');

const AddEditReservationPlaceVariation = ({selectedReservationPlaceVariationID,SetSelectedReservationPlaceVariationID,OnAddEditSuccess}) => {

    const {t} = useTranslation();

    var itemsToTranslate = [
        {name:"Název",param:"name"}
    ];

    const {
        allLanguageMutations,
        mainLang,
        loading:getLoading,
        error:getError
    } = UseGetReservationPlaceVariation(selectedReservationPlaceVariationID,(d) => {
        InitFormData(d);
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditReservationPlaceVariation,
        InitFormData,
        SetFormData,
        GetFormLangValue,
        SetFormLangData

    } = UseAddEditReservationPlaceVariation(selectedReservationPlaceVariationID,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedReservationPlaceVariationID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {selectedReservationPlaceVariationID === 0 ? t("AddReservationPlaceVariation") : t("EditReservationPlaceVariation")}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedReservationPlaceVariationID === 0 ? false : true}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedReservationPlaceVariationID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        :  
                            <>
                                <div>
                                    <label>*{t("Name")}</label>
                                    <Input
                                        name="name"
                                        value={GetFormLangValue("name")}
                                        OnChange={(e) => SetFormLangData(e)}
                                    />
                                </div>
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditReservationPlaceVariation()}
                    >
                        {(selectedReservationPlaceVariationID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditReservationPlaceVariation;