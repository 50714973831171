import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CONFIRM_RESERVATION_PAYMENT } from '../Queries/reservations';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseNotification } from '../../Notification/Library/UseNotification';

const UseConfirmReservationPayment = (allReservations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();

    const [showConfirmPaymentNotifi,SetShowConfirmPaymentNotifi] = useState(0);
    const [confirmPaymentNotifiText,SetConfirmPaymentNotifiText] = useState("");

    const [ConfirmReservationPayment,{loading}] = useMutation(CONFIRM_RESERVATION_PAYMENT,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted();

            const notifyText = t("SuccessfullyConfirmed");
            AddNotification(notifyText,true);
            SetShowConfirmPaymentNotifi(0);

        },
        onError:(err) => {
            err = GetApolloErrorText(err);
            AddNotification(err,false);  
            SetShowConfirmPaymentNotifi(0);
        }
    });

    const ConfirmPayment = (act) => {
        if(act)
        {
            ConfirmReservationPayment({
                variables:{
                    reservationID:showConfirmPaymentNotifi
                }
            })
        }
        else    
            SetShowConfirmPaymentNotifi(0);
        
    }

    const ShowConfirmPayment = (reservationID) => {

        var reservationStatusID = 0;
        for(let val of allReservations)
        {
            if(val.rowID == reservationID)
            {
                reservationStatusID = val.reservationStatusID;
                break;
            }
        }

        var text = "";
        switch(reservationStatusID)
        {
            case "2": text = t("ReallyPaymentIsOnAccount");break;
            case "3": text = t("ReallyRepaymentIsOnAccount");break;
            case "4": text = t("ReallyPaymentIsOnAccount");break;
            case "5": text = t("ReallyAdditionalPaymentIsOnAccount");break;
        }

        SetConfirmPaymentNotifiText(text);
        SetShowConfirmPaymentNotifi(reservationID);
    }


    return{
        loading,
        showConfirmPaymentNotifi,
        confirmPaymentNotifiText,
        ShowConfirmPayment,
        ConfirmPayment
    }

}

export default UseConfirmReservationPayment;