import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { GENERATE_CAMPAIGN_EMAIL_LANG,ADD_EDIT_CAMPAIGN_EMAIL } from "../Queries/campaignEmail";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";

const UseAddEditCampaignEmail = (selectedCampaignEmailID,campaignID,mainLang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();
        
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj
    } = UseFormHandle({
        projectID:0,
        hours:0,
        testEmail:"",
        langs:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_CAMPAIGN_EMAIL_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditCampaignEmailMutation,{error,loading}] = useMutation(ADD_EDIT_CAMPAIGN_EMAIL,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    campaignEmailID: d.AddEditCampaignEmail.campaignEmailID,
                    isEdit: (selectedCampaignEmailID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditCampaignEmail);
                
                const notifyText = (selectedCampaignEmailID != 0 ? t("SuccessfullyUpdated") : t("NewCampaignEmailIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("CampaignEmailHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditCampaignEmail = () => {

        let langs = [];
        for(const lang of formData.langs)
        {
            langs.push({
                subject:    lang.subject,
                preheader:  lang.preheader,
                text:       lang.text,
                lang:       lang.lang
            });
        }

        AddEditCampaignEmailMutation({
            variables:{
                campaignEmailID:        selectedCampaignEmailID,
                campaignID:             campaignID,
                testEmail:              formData.testEmail,
                hours:                  parseInt(formData.hours),
                langs:                  langs,
                generateTranslations:   formData.generateTranslations
            }
        })    
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditCampaignEmail,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        SetFormDataSelectedItemsWithObj
    }

}

export default UseAddEditCampaignEmail;