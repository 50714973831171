import {useApolloClient, useQuery} from '@apollo/client';
import { GET_ALL_ADMIN_USERS } from '../Queries/adminUser';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllAdminUsers = () => {

    const client = useApolloClient();

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedAdminUserIDs,SetSelectedAdminUserIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_ALL_ADMIN_USERS,{
        fetchPolicy:"network-only"
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllAdminUsers);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("FirstName"),
            className:"flex-1"
        },
        {
            value: t("Email"),
            className:"flex-1"
        }, 
        {
            value: t("Role"),
            className:"static text-center"
        },
        {
            value: t("Lang"),
            className:"static text-center"
        },
           
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"lis",
                        backgroundColor: val.backgroundColor,
                        text: val.name + " " + val.surname,
                        className:"flex-1"
                    },
                    {
                        value:val.email,
                        className:"flex-1"
                    },
                    {
                        value:val.adminRole.name,
                        className:"static text-center"
                    },
                    {
                        value:val.lang,
                        className:"static text-center"
                    } 
                ],
            }

            cData.rowID    = val.adminUserID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,orderID) => {

        var checked = e.target.checked;
        var newSelectedAdminUserIDs = [...selectedAdminUserIDs];
        const newList = SelectAdminUserID(content,orderID,checked,newSelectedAdminUserIDs)

        SetContent(newList);
        SetSelectedAdminUserIDs(newSelectedAdminUserIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedAdminUserIDs = [];
        const newList = SelectAdminUserID(content,"all",checked,newSelectedAdminUserIDs)

        SetSelectedAdminUserIDs(newSelectedAdminUserIDs);
        SetContent(newList);
    }

    const SelectAdminUserID = (list,orderID,checked,selectedAdminUserIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(orderID == newList[i].rowID || orderID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedAdminUserIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedAdminUserIDs.length; j++)
                    {
                        if(selectedAdminUserIDs[j] == newList[i].rowID)
                            selectedAdminUserIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectAdminUserID(newList[i].subData,orderID,checked,selectedAdminUserIDs)}
        }

        return newList;
    }

    const UpdateList = async () => {
        
        await client.refetchQueries({
            include: ["AllAdminUsers"],
        });
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedAdminUserIDs,
        SelectRow,
        SelectAll,
        UpdateList
    }

}

export default UseGetAllAdminUsers;