import { useTranslation } from 'react-i18next';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import { TINY_MCE_TOOLBAR_MEDIA, TINY_MCE_TOOLBAR_ADVANCED } from '../../../Config';
import Label from '../../../GlobalComponents/Label';
import Input from '../../../GlobalComponents/Input';

const FreeTrialPeriod = ({
    formData,
    GetFormLangValue,
    SetFormLangData,
    SetFormData
}) => {

    const {t} = useTranslation();
    
    return(
        <>
            
            <div className="form-group">
                <Label
                    text = {"*" + t("NumberOfFreeTrialDays")}
                    infoContent={[t("NumberOfFreeTrialDaysInfo")]}
                />
                
                <Input
                    name="numberOfFreeTrialDays"
                    type="number"
                    value={formData.numberOfFreeTrialDays}
                    OnChange={(e) => SetFormData(e)}
                />
            </div>

            <div className="form-group">
                <Label 
                    text = {t("NotificationOfEndingTrialPeriodEmail") + " (" + formData.selectedLang + ")"} 
                    badgeText = {t("IncludeAddressing")}
                    infoContent={[
                        t("NotificationOfEndingTrialPeriodEmailInfo")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("notificationOfEndingTrialPeriodEmail")}
                    OnEditorChange={(content) => SetFormLangData("notificationOfEndingTrialPeriodEmail",content)}
                    toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                    useMediaEditor={true}
                />
            </div>
            <div>
                <Label 
                    text = {t("TrialPeriodCancellationEmail") + " (" + formData.selectedLang + ")"} 
                    badgeText = {t("IncludeAddressing")}
                    infoContent={[
                        t("TrialPeriodCancellationEmailInfo")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("trialPeriodCancellationEmail")}
                    OnEditorChange={(content) => SetFormLangData("trialPeriodCancellationEmail",content)}
                    toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                    useMediaEditor={true}
                />
            </div>    
        </>
    )
}

export default FreeTrialPeriod;