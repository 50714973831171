import ContentHeader from "../../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../../GlobalComponents/List/List";
import UseGetAllCoupons from "../../Library/Coupons/UseGetAllCoupons";
import { useState } from "react";
import AddEditCoupon from "./AddEditCoupon";
import { UseAuth } from '../../../Auth/Library/UseAuth';
import Error from "../../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { useApolloClient } from "@apollo/client";
import UseDeleteCoupons from "../../Library/Coupons/UseDeleteCoupons";
import ModalNotification from "../../../../GlobalComponents/ModalNotification";

const Coupons = () => {

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t}    = useTranslation();

    const [selectedCouponID, SetSelectedCouponID] = useState(null);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedCouponIDs,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll
    } = UseGetAllCoupons(user.lang);

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteCoupons
    } = UseDeleteCoupons(selectedCouponIDs,async (d) => {
        await client.refetchQueries({
            include: ["AllCoupons"],
        });
    });
    
    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("CouponList")}
                        OnAddButtonClick = {() => SetSelectedCouponID(0)}
                        OnDeleteButtonClick = {() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (couponID) => SetSelectedCouponID(couponID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />                  
                </>
            }


            {selectedCouponID || selectedCouponID == 0 ?
                <AddEditCoupon
                    selectedCouponID = {selectedCouponID}
                    SetSelectedCouponID = {SetSelectedCouponID}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteCoupons")} 
                    CallBack={DeleteCoupons} 
                />
            :null}
        </>
    )

}

export default Coupons;