import { useMutation,useQuery } from "@apollo/client";
import { ADD_EDIT_LINK,GET_LINKS } from "../Queries/link";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const UseAddEditLink = (selectedLinkID,lang,isEshopCategory,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenLangImage,
        OpenImage,
        RemoveFormDataSelectedItems,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj
    } = UseFormHandle({
        selectedLang:lang,
        active:1,
        parentID:0,
        projectID:0,
        isEshopCategory:0,
        photo:null,
        shortcut:"",
        primarilySortBy:"pri",
        inHeader:1,
        inFooter:0,
        linkID:0,
        thanksPageLinkID:0,
        isBlog:0,
        isThisTermsAndConditions:0,
        isThisPersonalInformation:0,
        hideMenu:0,
        hideAddress:0,
        hidePassword:0,
        noteIsRequired:0,
        usePriceFilter:1,
        useDepthFilter:1,
        useWidthFilter:1,
        useCreditCardForPayment:1,
        langs:[],
        selectedParameters:[],
        selectedMemberships:[],
        selectedProducts:[],
        selectedServices:[]
    });

    const [allLinks, SetAllLinks] = useState([]);
    const {loading:linkLoading,error:linkError} = useQuery(GET_LINKS,{
        variables:{
            lang,
            isEshopCategory: (isEshopCategory ? 1 : 0),
            projectID:formData.projectID
        },
        skip:(formData.projectID ? false : true),
        onCompleted:(d) => {

            var allLinks = [{linkID:"0",name:t("Main")},...d.AllLinks]
            SetAllLinks(allLinks);
        }
    });

    const [AddEditLinkMutation,{error,loading}] = useMutation(ADD_EDIT_LINK,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.addEditReview);

            const notifyText = (selectedLinkID != 0 ? t("SuccessfullyUpdated") : t("NewLinkIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditLink = () => {

        var check = true;
        for(let val of formData.langs)
        {
            if(val.name === "" && val.lang === lang)
                check = false;
        }

        if(formData.projectID && formData.projectID != 0)
        {
            if(check)
            {
                if(CheckMembershipsInPackage(formData.selectedMemberships))
                {
                    let langs = [];
                    for(const lang of formData.langs)
                    {
                        langs.push({
                            name:                           lang.name,
                            ownUrl:                         lang.ownUrl,   
                            description:                    lang.description,             
                            metaTitle:                      lang.metaTitle,       
                            metaKeywords:                   lang.metaKeywords,                 
                            metaDescription:                lang.metaDescription,  
                            metaImage:                      lang.metaImage, 
                            lang:                           lang.lang,
                            membershipAfterBuyEmailText:    lang.membershipAfterBuyEmailText,
                            membershipPopUpTextUp:          lang.membershipPopUpTextUp,
                            membershipPopUpTextBottom:      lang.membershipPopUpTextBottom,
                            upSellBombTitle:                lang.upSellBombTitle,
                            trialPeriodButtonName:          lang.trialPeriodButtonName,
                            trialPeriodPopUpTextUp:         lang.trialPeriodPopUpTextUp,
                            trialPeriodPopUpTextDown:       lang.trialPeriodPopUpTextDown,
                            membershipTypeButtonName:       lang.membershipTypeButtonName,
                            reservationPlaceButtonName:     lang.reservationPlaceButtonName,
                            reservationPlacePopUpTextUp:    lang.reservationPlacePopUpTextUp,
                            reservationPlacePopUpTextDown:  lang.reservationPlacePopUpTextDown,
                            paymentButtonName:              lang.paymentButtonName,
                            reservationDateButtonName:      lang.reservationDateButtonName,
                            personalDataButtonName:         lang.personalDataButtonName,
                            membershipTypePopUpTextUp:      lang.membershipTypePopUpTextUp,
                            membershipTypePopUpTextDown:    lang.membershipTypePopUpTextDown,
                            textBellowFillCreditCard:       lang.textBellowFillCreditCard,
                            noteHeadline:                   lang.noteHeadline
                        });
                    }

                    var selectedParameters = [];
                    if(formData.selectedParameters.length > 0)
                        for(let val of formData.selectedParameters)
                        {
                            selectedParameters.push(val.parameterID);
                        }

                    AddEditLinkMutation({
                        variables:{
                            linkID:                     selectedLinkID,
                            parentID:                   formData.parentID,
                            projectID:                  formData.projectID,
                            thanksPageLinkID:           formData.thanksPageLinkID,
                            active:                     parseInt(formData.active),
                            inHeader:                   parseInt(formData.inHeader),
                            inFooter:                   parseInt(formData.inFooter),
                            isBlog:                     parseInt(formData.isBlog),
                            isThisTermsAndConditions:   parseInt(formData.isThisTermsAndConditions),
                            isThisPersonalInformation:  parseInt(formData.isThisPersonalInformation),
                            hideMenu:                   parseInt(formData.hideMenu),
                            hideAddress:                parseInt(formData.hideAddress),
                            hidePassword:               parseInt(formData.hidePassword),
                            noteIsRequired:             parseInt(formData.noteIsRequired),
                            usePriceFilter:             parseInt(formData.usePriceFilter),
                            useDepthFilter:             parseInt(formData.useDepthFilter),
                            useWidthFilter:             parseInt(formData.useWidthFilter),
                            useCreditCardForPayment:    parseInt(formData.useCreditCardForPayment),
                            isEshopCategory:            parseInt(formData.isEshopCategory),
                            photo:                      formData.photo,
                            shortcut:                   formData.shortcut,
                            primarilySortBy:            formData.primarilySortBy,
                            langs:                      langs,
                            selectedParameters:         selectedParameters,
                            selectedMemberships:        GetMemberships(formData.selectedMemberships),
                            upSellProducts:             GetProducts(formData.selectedProducts),
                            upSellServices:             GetServices(formData.selectedServices)
                        }
                    })
                }
                else
                    AddNotification(t("OnlyOneMembershipWithReservationsCanBeAddedToPackage"),false); 
            }
            else
                AddNotification(t("NoNameNoGo"),false); 
        }
        else
            AddNotification(t("PleaseChooseProject"),false); 
    } 

    const GetProducts = (selectedProducts) => {
        
        var newProducts = [];
        if(selectedProducts)
        {
            for(let val of selectedProducts) {
                newProducts.push(val.productID);
            }
        }

        return newProducts;
    }

    const GetServices = (selectedServices) => {
        
        var newServices = [];
        if(selectedServices)
        {
            for(let val of selectedServices) {
                newServices.push(val.membershipID);
            }
        }

        return newServices;
    }

    const GetMemberships = (selectedMemberships) => {
        
        var newMemberships = [];
        if(selectedMemberships)
        {
            for(let val of selectedMemberships) {
                newMemberships.push({
                    membershipID:val.membershipID,
                    discount: parseFloat(val.discount)
                });
            }
        }

        return newMemberships;
    }

    const CheckMembershipsInPackage = (selectedMemberships) => {
        
        var check = true;
        if(selectedMemberships)
        {
            var memWithReservations = 0
            for(let val of selectedMemberships) {
                if(val.canBeReserved)
                    memWithReservations++;
            }
        }

        if(memWithReservations > 1)
            check = false;

        return check;
    }


    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error || linkError,
        loading: loading || linkLoading,
        formData,
        allLinks,
        AddEditLink,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        OpenLangImage,
        OpenImage,
        RemoveFormDataSelectedItems,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj
    }

}

export default UseAddEditLink;