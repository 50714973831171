const Project = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <g>
                            <path style={{"fill":secondaryColor}}  d="M78.971,146.205c-2.815,0-5.63-1.074-7.778-3.222L51.54,123.33
                                c-4.295-4.296-4.295-11.261,0-15.557L71.193,88.12c4.296-4.296,11.261-4.296,15.557,0c4.295,4.296,4.295,11.261,0,15.557
                                l-11.875,11.875l11.875,11.875c4.295,4.296,4.295,11.261,0,15.557C84.602,145.131,81.786,146.205,78.971,146.205z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path style={{"fill":secondaryColor}}  d="M125.359,146.205c-2.815,0-5.63-1.074-7.778-3.222c-4.295-4.296-4.295-11.261,0-15.557
                                l11.875-11.875l-11.875-11.875c-4.295-4.296-4.295-11.261,0-15.557c4.296-4.296,11.261-4.296,15.557,0l19.653,19.653
                                c4.295,4.296,4.295,11.261,0,15.557l-19.653,19.653C130.99,145.131,128.174,146.205,125.359,146.205z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path style={{"fill":secondaryColor}}  d="M145.013,213.812H59.318c-6.075,0-11-4.925-11-11s4.925-11,11-11h85.695c6.075,0,11,4.925,11,11
                                S151.088,213.812,145.013,213.812z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path style={{"fill":secondaryColor}}  d="M145.013,260.334H59.318c-6.075,0-11-4.925-11-11s4.925-11,11-11h85.695c6.075,0,11,4.925,11,11
                                S151.088,260.334,145.013,260.334z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path style={{"fill":primaryColor}} d="M247.583,166.9h198.88c12.596,0,22.844-10.248,22.844-22.844v-41.074c0-12.596-10.248-22.844-22.844-22.844h-198.88
                                c-12.596,0-22.844,10.248-22.844,22.844v41.074C224.74,156.652,234.987,166.9,247.583,166.9z M246.74,102.982
                                c0-0.465,0.378-0.844,0.844-0.844h198.88c0.465,0,0.844,0.378,0.844,0.844v41.074c0,0.465-0.378,0.844-0.844,0.844h-198.88
                                c-0.465,0-0.844-0.378-0.844-0.844V102.982z"/>
                        </g>
                        <g>
                            <path style={{"fill":primaryColor}} d="M451,29.704H61c-33.636,0-61,27.365-61,61v261.583c0,33.635,27.364,61,61,61h108.806l-6.076,47.01h-22.152
                                c-6.075,0-11,4.925-11,11s4.925,11,11,11H173.4H338.6h31.821c6.075,0,11-4.925,11-11s-4.925-11-11-11h-22.151l-6.076-47.01H451
                                c33.636,0,61-27.365,61-61V90.704C512,57.068,484.636,29.704,451,29.704z M185.914,460.296l6.076-47.009H320.01l6.076,47.009
                                H185.914z M490,90.704v215.278H204.331V51.704H451C472.504,51.704,490,69.199,490,90.704z M61,51.704h121.331v254.278H22V90.704
                                C22,69.199,39.496,51.704,61,51.704z M451,391.287H61c-21.504,0-39-17.495-39-39v-24.305h468v24.305
                                C490,373.792,472.504,391.287,451,391.287z"/>
                        </g>
                        <g>
                            <path style={{"fill":primaryColor}} d="M459.823,190.462H237.255c-6.075,0-11,4.925-11,11s4.925,11,11,11h222.568c6.075,0,11-4.925,11-11
                                S465.898,190.462,459.823,190.462z"/>
                        </g>
                        <g>
                            <path style={{"fill":primaryColor}} d="M459.823,236.985H237.255c-6.075,0-11,4.925-11,11s4.925,11,11,11h222.568c6.075,0,11-4.925,11-11
                                S465.898,236.985,459.823,236.985z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Project;
