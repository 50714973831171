import { useTranslation } from "react-i18next";
import Input from "../../../../../GlobalComponents/Input";
import WindowWidthResponziveInfo from "../WindowWidthResponziveInfo";

const ReviewsResponziveSettings = ({size,formData,OnChange}) => {

    console.log(formData.selectedReviews);
    const {t} = useTranslation();

    return (
        <>
            <WindowWidthResponziveInfo size={size} />
            
            <div className="row">
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingTop")}</label>
                        <Input 
                            type = "number"
                            name = {"mt" + size}
                            value = {formData["mt" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("PaddingBottom")}</label>
                        <Input 
                            type = "number"
                            name = {"mb" + size}
                            value = {formData["mb" + size]}
                            OnChange = {(e) => OnChange(e)}
                        />
                    </div>
                </div>
                {formData.selectedReviews.length == 1 ?
                    <>
                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("PaddingLeft")}</label>
                                <Input 
                                    type = "number"
                                    name = {"ml" + size}
                                    value = {formData["ml" + size]}
                                    OnChange = {(e) => OnChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("PaddingRight")}</label>
                                <Input 
                                    type = "number"
                                    name = {"mr" + size}
                                    value = {formData["mr" + size]}
                                    OnChange = {(e) => OnChange(e)}
                                />
                            </div>
                        </div>
                    </>
                :null}
            </div>
        </>
    )
}

export default ReviewsResponziveSettings;