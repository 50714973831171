import gql from 'graphql-tag';

export const GET_LINK_CONTENT = gql`
    query LinkContent($linkID:ID,$projectID:ID){
        AllLinkLangs(linkID:$linkID){
            lang
            content  
        }
        ProjectBy(param:"projectID",value:$projectID){
            buttonBgColor
            buttonTextColor
            buttonBorderRadius
            headlineColor
            inputBorderRadius
            linkColor
            stripePublishableKey
            stripeSecretKey
            stripeWebHook
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            suffix
        }
    }
`;

export const GET_LANG_DATA = gql`
    query LangData($lang:String){
        LanguageMutation(lang:$lang){
            languageID
            countryID
            worldRegionID
            decimal
            main
            currencyID
            currencyTitle
            currencyCode
        }
    }
`;

export const SAVE_LINK_CONTENT = gql`
    mutation SaveLinkContent($linkID:ID,$data:[LinkContentData]){
        SaveLinkContent(linkID:$linkID,data:$data) 
    }
`;

export const GET_SELECTED_CATEGORIES = gql`
    query AllPlainLinksByLinkIDs($linkIDs:[ID],$lang:String){
        AllPlainLinksByLinkIDs(linkIDs:$linkIDs,lang:$lang){
            linkID
            name
        } 
    }
`;

export const GET_SELECTED_PRODUCTS = gql`
    query AllProductsByProductIDs($productIDs:[ID],$lang:String){
        AllProductsByProductIDs(productIDs:$productIDs,lang:$lang){
            productID
            name
            mainPhoto
            price
            commonPrice
            discount
            isDiscountPercentage
        } 
    }
`;

export const GET_SELECTED_REVIEWS = gql`
    query AllReviewsByReviewIDs($reviewIDs:[ID],$lang:String){
        AllReviewsByReviewIDs(reviewIDs:$reviewIDs,lang:$lang){
            reviewID
            text
            signature
            photo
        } 
    }
`;

export const GET_SELECTED_FAQS = gql`
    query AllFaqsByFaqIDs($faqIDs:[ID],$lang:String){
        AllFaqsByFaqIDs(faqIDs:$faqIDs,lang:$lang){
            faqID
            question
            answer
        } 
    }
`;

export const GET_LINK_CAN_BE_RESERVED_MEMBERSHIPS = gql`
    query AllLinkCanBeReservedMemberships($linkID:ID,$lang:String){
        AllLinkCanBeReservedMemberships(linkID:$linkID,lang:$lang){
            membershipID
            name
            reservationPlaces{
                reservationPlaceID
                reservationPlace(lang:$lang){
                    internalName
                }
            }
        } 
    }
`;

export const GET_RESERVATION_PLACE = gql`
    query ReservationPlaceWithProject($reservationPlaceID: ID,$projectID: ID,$lang:String){
        ReservationPlaceWithProject(reservationPlaceID:$reservationPlaceID,projectID:$projectID,lang:$lang){
            reservationPlaceID
            name
            photo
        }
    }
`;

export const GET_MEMBERSHIP_WITH_RESERVATION_PLACE = gql`
    query MembershipWithReservationPlace(
        $membershipID:ID,
        $lang:String,
        $projectID:ID,
        $reservationPlaceID:ID
    ){
        Membership(
            lang:$lang
            membershipID:$membershipID
        ){
            membershipID
            hash
            name
            photo
            lang
            reservationPlace(reservationPlaceID:$reservationPlaceID){
                membershipReservationPlaceID
                reservationPlaceID
                reservationPlaceWithProject(lang:$lang,projectID:$projectID){
                    name
                    photo
                }
                times{
                    reservationPlaceTimeID
                    reservationPlaceTime{
                        reservationPlaceTimeID
                        timeFrom
                        timeTo
                        variations{
                            reservationPlaceVariationID
                            reservationPlaceVariation(lang:$lang){
                                name
                            }
                        }
                    }   
                }
            }
        }   
    }
`;

export const GET_RESERVATION_PLACE_RESERVED_DAYS = gql`
    query AllReservationPlaceReservedDays(
        $month:Int,
        $year:Int,
        $reservationPlaceID:ID
        $membershipID:ID
    ){
        AllReservationPlaceReservedDays(
            month:$month,
            year:$year,
            reservationPlaceID:$reservationPlaceID
            membershipID:$membershipID
        )
    }
`;

export const GET_RESERVATION_PLACE_DATE_RESERVATIONS = gql`
    query AllReservationPlaceDateReservations(
        $date:String,
        $reservationPlaceID:ID
    ){
        AllReservationPlaceDateReservations(
            date:$date,
            reservationPlaceID:$reservationPlaceID
        ){
            date
        }
    }
`;