const Logout = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path 
                            style={{"fill":primaryColor}}  
                            d="M361.929,205.471V74.188c0-33.807-27.508-61.31-61.319-61.31H62.481C49.117,12.57,35.977,16.691,24.847,24.93
                            C9.057,36.621,0,54.604,0,74.269v323.705c0,27.182,17.445,50.768,43.409,58.692l130.266,39.756
                            c5.964,1.82,12.031,2.716,18.042,2.716c12.882,0,25.501-4.116,36.244-12.069c11.832-8.76,19.884-21.054,23.177-34.941h49.471
                            c33.812,0,61.319-27.503,61.319-61.31v-98.349c0-6.075-22-6.075-22,0v98.349c0,21.675-17.639,39.31-39.319,39.31h-47.801V114.026
                            c0-27.182-17.445-50.768-43.409-58.692l-67.025-20.456h158.235c21.681,0,39.319,17.634,39.319,39.31v131.283
                            C339.929,211.546,361.929,211.546,361.929,205.471z M230.809,114.026V437.73c0,12.62-5.809,24.158-15.938,31.657
                            c-10.093,7.472-22.768,9.658-34.773,5.993L49.831,435.624C33.185,430.544,22,415.413,22,397.974V74.269
                            c0-12.62,5.809-24.158,15.938-31.657c6.883-5.096,14.965-7.732,23.219-7.732c3.849,0,7.736,0.574,11.555,1.739l130.266,39.756
                            C219.624,81.456,230.809,96.586,230.809,114.026z"/>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M196.646,275.746c6.075,0,11-4.925,11-11v-31.434c0-6.075-4.925-11-11-11s-11,4.925-11,11v31.434
                            C185.646,270.821,190.571,275.746,196.646,275.746z"/>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path 
                                    style={{"fill":secondaryColor}}  
                                    d="M390.352,337.057c-1.742,0-3.49-0.413-5.092-1.25c-3.631-1.896-5.907-5.653-5.907-9.75v-30.979
                                    h-83.641c-6.075,0-11-4.925-11-11v-70.097c0-6.075,4.925-11,11-11h83.641v-30.979c0-4.097,2.276-7.854,5.907-9.75
                                    s8.015-1.618,11.378,0.722l110.647,77.027c2.954,2.057,4.715,5.428,4.715,9.028s-1.761,6.971-4.715,9.028l-110.647,77.027
                                    C394.758,336.393,392.56,337.057,390.352,337.057z M306.712,273.078h83.641c6.075,0,11,4.925,11,11v20.918l80.395-55.967
                                    l-80.395-55.967v20.918c0,6.075-4.925,11-11,11h-83.641V273.078z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Logout;