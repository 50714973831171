import { UseContentManager } from "../../../Library/UseContentManager";
import ControlsMenu from "../../ControlsMenu";
import { GetElementMargins } from "../../../Library/functions";
import reservationIcon from '../../../../../Media/Images/Icons/reservation.webp';
import { useTranslation } from "react-i18next";
import { GET_RESERVATION_PLACE } from "../../../Queries/contentManager";
import { useApolloClient } from "@apollo/client";
import { useState,useEffect } from "react";
import { RESERVATION_PLACE_IMAGE_URL, SERVER_URL } from "../../../../../Config";
import TinyMCEEditor from '../../TinyMCEEditor';
import ReservationPlaceCalendar from "./ReservationPlaceCalendar";

const ReservationPlace = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {t} = useTranslation();
    const client = useApolloClient();
    const {
        contentManagerProjectID,
        projectData,
        formData,
        showHelpers,
        indexToShowMenu,
        SelectElement,
        SetSettings
    } = UseContentManager();

    const [selectedReservationPlace, SetSelectedReservationPlace] = useState(null);

    useEffect(() => {
        if(data.reservationPlaceID)
            GetReservationPlace();
    },[data.reservationPlaceID])

    const GetReservationPlace = async() => {

        var reservationPlace = await client.query({
            query: GET_RESERVATION_PLACE,
            variables:{
                lang:formData.userLang,
                reservationPlaceID: data.reservationPlaceID,
                projectID: contentManagerProjectID
            },
            fetchPolicy:"network-only"
        });

        SetSelectedReservationPlace(reservationPlace.data.ReservationPlaceWithProject);
    }

    const OnEditorChange = (content,param) => {
        if(indexToShowMenu)
            SetSettings(indexToShowMenu,param,content,"reservationPlace")
    }

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);

    const headlineColor          = projectData.headlineColor.split("-");  
    const buttonBgColor          = projectData.buttonBgColor.split("-");
    const imageBorderRadiusStyle = {borderRadius:data.imageBorderRadius + "px"};
        
    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "reservationPlace"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={"cm-reservation-place-element " + margins.styleClassName + " " + data.className} style={margins.styles}>
                
                {selectedReservationPlace === null ?
                    <div className="cm-start-info big">
                        <img src={reservationIcon} alt="" />
                        <h2 className="cm-no-bottom-margin">{t("ReservationPlace")}</h2>
                        {t("HereWillBeSelectedReservationPlace")}
                    </div>
                :
                    <>
                        {selectedReservationPlace === undefined ?
                            <div className="cm-start-info big">
                                <p>{t("ThisReservationPlaceHasNoDataInThisProject")}</p>
                            </div>
                        :
                            <>
                                <div className="cm-row cm-wrap cols-padding-10">

                                    <div className = {"cm-col " + (data.showCalendar == 1 && data.showImage == 1 ? "cm-col-33" : "cm-col-100" ) + " cm-col-sm-100 cm-justify-content-center cm-d-flex cm-flex-column"}>
                                        {data.showImage == 1 ?
                                            <div className={"cm-image-content cm-justify-content-center"}>
                                                <img 
                                                    style={imageBorderRadiusStyle}
                                                    src={SERVER_URL + "/" + RESERVATION_PLACE_IMAGE_URL + "/stredni_" + selectedReservationPlace.photo} 
                                                />
                                            </div>
                                        :null}

                                        {data.showName == 1 ?
                                            <div className={"cm-d-flex cm-justify-content-center" + (headlineColor.length > 1 ? " gradient" : "")}>
                                                <div>
                                                    <h4 className={"cm-reservation-place-name" + (data.showImage == 0 ? " no-image " : "")}>{selectedReservationPlace.name}</h4>
                                                </div>
                                            </div>
                                        :null}
                                    </div>
                                    
                                    {data.showCalendar == 1 ?
                                        <div className = {"cm-col " + (data.showImage == 1 ? "cm-col-66" : "cm-col-100") + " cm-col-sm-100 cm-justify-content-center cm-d-flex cm-flex-column"}>
                                            <div>
                                                <ReservationPlaceCalendar 
                                                    projectData = {projectData}
                                                    reservationPlaceID = {selectedReservationPlace.reservationPlaceID}
                                                    membershipID = {data.membershipID}
                                                    lang = {formData.userLang}
                                                />
                                            </div>
                                        </div>
                                    :null}

                                </div>
                                {data.showReservationButton == 1 ?
                                    
                                    <div className="cm-row cm-wrap cols-padding-10">
                                        <div className = {"cm-col-pointer-events " + (data.selectedReviews.length == 0 ? " cm-col-33 cm-col-sm-25 cm-col-xs-25 " : " cm-col-25 cm-col-sm-16 cm-col-xs-100 ")}></div>
                                        <div className = {"cm-col-pointer-events " + (data.selectedReviews.length == 0 ? "cm-col-33 cm-col-sm-50 " : " cm-col-25 cm-col-sm-33 cm-col-xs-50 ") + " cm-col-xxs-100"}>
                                            
                                            <button 
                                                className={"cm-w-100 btn " + data.size + " " + data.color + " " + data.className + (buttonBgColor.length > 1 ? " gradient" : "")} 
                                            >
                                                <TinyMCEEditor 
                                                    value = {data.buttonText}
                                                    OnEditorChange={(content) => OnEditorChange(content,"buttonText")}
                                                    toolbar = 'bold italic forecolor removeformat'
                                                    forcedRootBlock = {"div"}
                                                />
                                            </button>
                                            
                                        </div>
                                        {data.selectedReviews.length > 0 ?
                                            <div className = {"cm-col-pointer-events cm-col-25 cm-col-sm-33 cm-col-xs-50 cm-col-xxs-100"}>
                                                
                                                <button 
                                                    className={"cm-w-100 btn " + data.size + " btn-primary " + data.className + (buttonBgColor.length > 1 ? " gradient" : "")} 
                                                >
                                                    <TinyMCEEditor 
                                                        value = {data.reviewText}
                                                        OnEditorChange={(content) => OnEditorChange(content,"reviewText")}
                                                        toolbar = 'bold italic forecolor removeformat'
                                                        forcedRootBlock = {"div"}
                                                    />
                                                </button>
                                                
                                            </div>
                                        :null}
                                        <div className = {"cm-col-pointer-events " + (data.selectedReviews.length == 0 ? " cm-col-33 cm-col-sm-25 cm-col-xs-25 " : " cm-col-25 cm-col-sm-16 cm-col-xs-100 ")}></div>
                                    </div>

                                :null}
                            </>
                        }
                    </>
                }
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default ReservationPlace;