import { useState } from "react";
import { DELETE_ADMIN_ROLES } from "../Queries/adminRole";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";

const UseDeleteAdminRoles = (selectedItems,OnCompleted) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);

    const [DelAdminRoles] = useMutation(DELETE_ADMIN_ROLES,{
        onCompleted:(d) => {

            AddNotification(t("TheyAreGone"),true);
            if(OnCompleted)
                OnCompleted(d.DeleteAdminRoles);
        },
        onError:(err) => {  
            var error = GetApolloErrorText(err);
            if(error == "ADMIN_ROLE_IS_USED")
                error = t("DeleAdministratorsWithThisAdminRoleBeforeYouDelete")
                  
            AddNotification(error,false);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
        {
            AddNotification(t("NoSelectedRows"),false);
        }
    }

    const DeleteAdminRoles = (action) => {

        if(action)
        {              
            DelAdminRoles({
                variables:{
                    adminRoleIDs:selectedItems.join(",")
                }
            })
        }

        SetShowDeleteNotification(false);
    }

    return{
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteAdminRoles
    }
}

export default UseDeleteAdminRoles;