import {useApolloClient, useQuery} from '@apollo/client';
import { GET_ALL_PROJECTS } from '../Queries/project';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,PROJECT_IMAGE_URL } from '../../../Config';
import YesNo from '../../../GlobalComponents/YesNo';

const UseGetAllProjects = () => {

    const client = useApolloClient();

    const [variables,SetVariables] = useState({
        onlyActive:false
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedProjectIDs,SetSelectedProjectIDs] = useState([]);

    const {data,loading,error} = useQuery(GET_ALL_PROJECTS,{
        variables,
        fetchPolicy:"network-only"
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllProjects);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("ProjectName"),
            className:"flex-1"
        },
        {
            value: t("Active"),
            className:"static text-center"
        }
        
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.favicon ? SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + val.favicon : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value: <YesNo isYes = {!!val.active} />,
                        className:"static text-center"
                    }
                    
                ],
            }

            cData.rowID    = val.projectID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,orderID) => {

        var checked = e.target.checked;
        var newSelectedProjectIDs = [...selectedProjectIDs];
        const newList = SelectProjectID(content,orderID,checked,newSelectedProjectIDs)

        SetContent(newList);
        SetSelectedProjectIDs(newSelectedProjectIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedProjectIDs = [];
        const newList = SelectProjectID(content,"all",checked,newSelectedProjectIDs)

        SetSelectedProjectIDs(newSelectedProjectIDs);
        SetContent(newList);
    }

    const SelectProjectID = (list,orderID,checked,selectedProjectIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(orderID == newList[i].rowID || orderID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedProjectIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedProjectIDs.length; j++)
                    {
                        if(selectedProjectIDs[j] == newList[i].rowID)
                            selectedProjectIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectProjectID(newList[i].subData,orderID,checked,selectedProjectIDs)}
        }

        return newList;
    }

    const UpdateListAfterAddEdit = async (selectedProjectID,data) => {

        await client.refetchQueries({
            include: ["HeaderData"],
        });
        
        const { AllProjects } = client.readQuery({ query: GET_ALL_PROJECTS, variables });
        var newArray = [...AllProjects];

        if(selectedProjectID && selectedProjectID != 0)
        {
            for(let i in newArray)
            {
                if(newArray[i].projectID == data.projectID)
                {
                    var d = {...newArray[i],
                        name: data.name,
                        active: data.active,
                    }

                    if(data.favicon)
                        d.favicon = data.favicon;

                    newArray[i] = d;
                }
            } 
        }
        else
        {
            newArray.unshift({
                projectID: data.projectID,
                name: data.name,
                active: data.active,
                favicon: data.favicon
            })
        }
        
        client.writeQuery({ 
            query:GET_ALL_PROJECTS,
            variables,
            data:{
                AllProjects: newArray
            } 
        });
    }

    const UpdateListAfterDelete = async (IDs) => {

        await client.refetchQueries({
            include: ["HeaderData"],
        });

        var response = IDs.split(",");
        
        const { AllProjects} = client.readQuery({ query: GET_ALL_PROJECTS, variables });
        
        var newArray = [...AllProjects];
        
        for(let id of response)
        {
            for(let i in newArray)
            {
                if(newArray[i].projectID == id)
                {
                    newArray.splice(i,1);
                }
            } 
        } 
        
        client.writeQuery({ 
            query:GET_ALL_PROJECTS,
            variables,
            data:{
                AllProjects: newArray
            } 
        });
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedProjectIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    }

}

export default UseGetAllProjects;