import { useState,useEffect } from "react";
import { useQuery,useApolloClient } from "@apollo/client";
import { GET_HEADER_DATA } from "../GlobalQueries/header";
import { UseProject } from "../AdminProvider";
import { UseAuth } from "../Modules/Auth/Library/UseAuth";

const UseHeader = (SetIsShrink,projectID) => {

    const pathName = window.location.pathname;
    const client   = useApolloClient();
    
    const [selectedMenu, SetSelectedMenu] = useState(null);
    const [showProjects, SetShowProjects] = useState(false);
    const [selectedParentID, SetSelectedParentID] = useState(0);
    const {user} = UseAuth();
    const {
        selectedProject,
        SetProjectID
    } = UseProject();

    const {data,loading,error} = useQuery(GET_HEADER_DATA,{
        fetchPolicy:"network-only",
        variables:{
            isForProject: (projectID ? 1 : 0),
            parentModuleID: 0,
            adminRoleID:user.adminRoleID
        },
        skip:(user.adminRoleID == 0 ? true : false)
    })

    useEffect(() => {

        SetProjectID(projectID)
        
    },[projectID])

    useEffect(() => {

        if(data && data.AllModulesBy)
        {
            for(let item of data.AllModulesBy)
            {
                if(item.subModule && item.subModule.length > 0)
                {
                    const link = (item.link && !item.subModule.length ? item.link : "");

                    for(let val of item.subModule)
                    {
                        if(val.link === pathName)
                        {
                            if(!selectedMenu)
                                ShowSubmenu(null,item.moduleID,link)

                            SetSelectedParentID(item.moduleID);
                            break;
                        }
                    }
                }
            }
        }
        
    })

    const Shrink = () => {
        SetIsShrink((prevValue) => {
            return !prevValue;
        })
    }

    const ShowSubmenu = (e,id,link) => {

        SetSelectedParentID(null);

        if(!link)
        {
            if(e)
            {
                e.preventDefault();
                e.stopPropagation();
            }

            if(selectedMenu === id)
                SetSelectedMenu(-1);
            else
                SetSelectedMenu(id);
        }
        else
            SetSelectedMenu(null);
        
    }

    const DecreaseNotApprovedProductReviewCount = () => {

        const { NotApprovedProductReviewsCount } = client.readQuery({ query: GET_HEADER_DATA });

        var newCount = 0;
        if(NotApprovedProductReviewsCount > 0)
            newCount = NotApprovedProductReviewsCount - 1;
        
        client.writeQuery({ 
            query:GET_HEADER_DATA,
            data:{
                NotApprovedProductReviewsCount: newCount
            } 
        });
    }

    return{
        headerData: data,
        loading,
        selectedMenu,
        selectedParentID,
        SetSelectedParentID,
        showProjects,
        selectedProject,
        ShowSubmenu,
        Shrink,
        DecreaseNotApprovedProductReviewCount,
        SetShowProjects
    }
}

export default UseHeader;