const NoItems = ({text, className}) => {
	
    return (
        <div 
            className={"alert alert-warning" + (className ? " " + className : "")}
            dangerouslySetInnerHTML={{__html:text}}
        >	
        </div>
    );
}


export default NoItems;
