import { CompleteLangsArray } from "../../../GlobalFunctions/helper";

const UseMembershipTypePrice = () => {

    const GetEmptyMembershipTypePrice = (allLanguageMutations) => {

        const emptyLang = {
            price:0,
            description:""
        }
        const langs = CompleteLangsArray([],allLanguageMutations,emptyLang);

        const obj = {
            membershipTypePriceID:0,
            langs:langs,
            isMain:0,
            connectedMembershipTypeID:0,
            connectedMembershipTypePriceID:0
        }

        return obj;

    }

    return{
        GetEmptyMembershipTypePrice
    }
}

export default UseMembershipTypePrice;