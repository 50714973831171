import { useEffect } from "react";
import Checkbox from "../../../GlobalComponents/Checkbox";
import InputCard from "../../../GlobalComponents/InputCard";
import { GetTimeInString } from "../../../GlobalFunctions/helper";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import TinyMCEEditor from "../../TinyMCEEditor/Components/TinyMCEEditor";
import { TINY_MCE_TOOLBAR_ADVANCED } from "../../../Config";
import { useTranslation } from "react-i18next";
import Label from "../../../GlobalComponents/Label";

const ChoosenReservationPlace = ({
    selectedLang,
    isTherapy,
    data,
    RemoveFormDataSelectedItems,
    SetParentFormData,
    GetFormSelectedItemsLangValue,
    SetFormSelectedItemsLangValue
}) => {
    
    const {t} = useTranslation();

    const {formData,FillFormSelectedItems} = UseFormHandle({
        selectedTimes:data.selectedTimes,
    });

    useEffect(() => {
        SetParentFormData(formData);
    },[formData])

    return(
        <InputCard
            initialsShorcut = {{
                backgroundColor:data.backgroundColor,
                text:data.internalName
            }}
            badgeText = {(data.active == 0 ? "NEAKTIVNÍ" : "")}
            badgeType = {"danger"}
            headerChildren = {
                <img 
                    onClick = {() => RemoveFormDataSelectedItems("selectedReservationPlaces","reservationPlaceID",[data.reservationPlaceID])}
                    className="cursor-pointer" src={cancelIcon} 
                />
            }
        >
            <div className="row form-group">

                {data.times && data.times.map((item) => {

                    var checked = false;
                    if(formData.selectedTimes.indexOf(item.reservationPlaceTimeID) != -1)
                        checked = true;

                    return (
                        <div key={item.reservationPlaceTimeID} className="col-20">
                            <Checkbox 
                                text = {GetTimeInString(item.timeFrom) + " - " + GetTimeInString(item.timeTo)}
                                id = {"rpt-" + item.reservationPlaceTimeID}
                                name = {"rpt-" + item.reservationPlaceTimeID}
                                checked = {checked}
                                OnChange = {() => FillFormSelectedItems("selectedTimes",item.reservationPlaceTimeID)}
                            />
                        </div>
                    )
                })}
            </div>
            <InputCard
                className="form-group"
                title = {t("MembershipReservationPlaceEmailText") + " " + "(" + selectedLang + ")"}
                canBeMinimalized={true}
                minimalized={true}
                noBodyPadding={true}
                badgeText = {t("IncludeAddressingAndResData")}
                headerInfoContent = {[
                    t("MembershipReservationPlaceEmailTextInfo1"),
                    t("MembershipReservationPlaceEmailTextInfo2"),
                    t("MembershipReservationPlaceEmailTextInfo3")
                ]}
            >
                <div className="tinymce-in-input-card">
                    <TinyMCEEditor 
                        value = {GetFormSelectedItemsLangValue("selectedReservationPlaces","reservationPlaceID",data.reservationPlaceID,"emailText")}
                        OnEditorChange={(content) => SetFormSelectedItemsLangValue("emailText","selectedReservationPlaces","reservationPlaceID",data.reservationPlaceID,content)}
                        toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                        useMediaEditor={false}
                    />
                </div>
            </InputCard>

            {isTherapy ? 
                <InputCard
                    className="form-group"
                    title = {t("MembershipReservationPlaceTherapyTemplateEmailText") + " " + "(" + selectedLang + ")"}
                    canBeMinimalized={true}
                    minimalized={true}
                    noBodyPadding={true}
                    badgeText = {t("IncludeAddressing")}
                    headerInfoContent = {[
                        t("MembershipReservationPlaceTherapyTemplateEmailTextInfo1"),
                        t("MembershipReservationPlaceTherapyTemplateEmailTextInfo2")
                    ]}
                >
                    <div className="tinymce-in-input-card">
                        <TinyMCEEditor 
                            value = {GetFormSelectedItemsLangValue("selectedReservationPlaces","reservationPlaceID",data.reservationPlaceID,"templateTherapyEmailText")}
                            OnEditorChange={(content) => SetFormSelectedItemsLangValue("templateTherapyEmailText","selectedReservationPlaces","reservationPlaceID",data.reservationPlaceID,content)}
                            toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                            useMediaEditor={false}
                        />
                    </div>
                </InputCard>
            :null} 
        </InputCard>
    )
}

export default ChoosenReservationPlace;