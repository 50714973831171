import { useMutation } from "@apollo/client";
import { GetApolloErrorText } from "../GlobalFunctions/helper";
import { UseNotification } from "../Modules/Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseSortList = (
    client,
    updateQuery,
    getQuery,
    listDataParam,
    paramID,
    parentParamID,
    subParam,
    updateVariables,
    listVariables
) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const [UpdateLinkPriority] = useMutation(updateQuery,{
        onCompleted:(d) => {

            const notifyText = t("NewPositionSaved");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const Reorder = (list, startIndex, endIndex) => {
        
        var newSubData = [...list];
    
        const [removed] = newSubData.splice(startIndex, 1);
        newSubData.splice(endIndex, 0, removed);
      
        return newSubData;
    };

    const GoThroughList = (listData,parentID,startIndex, endIndex) => {

        var arr = [...listData];

        if(parentID == 0)
            arr = Reorder(arr,startIndex, endIndex);
        else
        {
            for(let i = 0; i < arr.length; i++)
            {
                if(parentID == arr[i][paramID])
                {
                    var newSubData = Reorder(arr[i][subParam],startIndex, endIndex); 
                    arr[i] = {...arr[i],[subParam]:newSubData}
                }
                else if(arr[i][subParam])
                {
                    arr[i] = {...arr[i],[subParam]:GoThroughList(arr[i][subParam],parentID,startIndex, endIndex)}
                }
            }
        }
        
        return arr;
    }

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {
            const IDs = result.draggableId.split("-");

            const cacheData = client.readQuery({ query: getQuery, variables:listVariables });
            const newArray = GoThroughList(cacheData[listDataParam],IDs[1],result.source.index,result.destination.index);

            client.writeQuery({ 
                query:getQuery,
                variables:listVariables,
                data:{
                    [listDataParam]: newArray
                } 
            });

            const variables     = updateVariables;
            variables.fromIndex = result.source.index;
            variables.toIndex   = result.destination.index;

            if(paramID)
                variables[paramID] = IDs[0];

            if(parentParamID)
                variables[parentParamID] = IDs[1];

            UpdateLinkPriority({
                variables:variables
            })
        }
    } 

    return {
        OnDragEnd
    }

}

export default UseSortList;