import { useQuery} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SERVER_URL,ARTICLE_IMAGE_URL } from '../../../Config';
import { GET_ALL_ARTICLES } from '../Queries/article';
import { PROJECT_IMAGE_URL } from '../../../Config';
import UseCopyToClipboard from '../../../GlobalHooks/UseCopyToClipboard';

const UseGetAllArticles = (lang) => {

    const [variables,SetVariables] = useState({
        lang,
        searchText:"",
        limit:40,
        offset:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedArticleIDs,SetSelectedArticleIDs] = useState([]);
    const {copied,Copy} = UseCopyToClipboard();

    const {data,loading,error,fetchMore} = useQuery(GET_ALL_ARTICLES,{
        variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })
    
    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllArticles);
            SetContent(contentData);
        }

    },[data])

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    var headerData = [
        {
            value: "",
            className:"img-offset"
        },
        {
            value: t("ArticleTitle"),
            className:"flex-1"
        },
        {
            value: t("PublishedIn"),
            className:"static bigger text-center"
        }
        
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"img",
                        src: val.photo ? SERVER_URL + "/" + ARTICLE_IMAGE_URL + "/mala_" + val.photo : "",
                        className:"no-padding-right"
                    },
                    {
                        value:val.title,
                        className:"flex-1"
                    },
                    {
                        value:<>
                            {
                                val.publishedProjects.map((p,index) => {
                                    if(p.project && p.project.domains[0])
                                    {
                                        var link = "https://" + p.project.domains[0].name + "/" + p.linkNiceName + "/" + val.articleID + "-" + val.niceTitle;
                                        return (
                                            <a 
                                                onClick={() => Copy(link)}
                                                key={index} 
                                                href={link} 
                                                target="_blank"
                                            >
                                                <img src={SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + p.project.favicon} />
                                            </a>
                                        )
                                    }
                                })
                            }
                        </>,
                        className:"static bigger text-center"
                    }
                ],
            }

            cData.rowID    = val.articleID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllArticles: [...prev.AllArticles, ...fetchMoreResult.AllArticles]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    const FilterArticles = (filterData) => {

        var newVariables = {...variables};
        var updated = false;
        
        if(filterData.searchText != "" || (filterData.searchText == "" && newVariables.searchText != ""))
        {
            newVariables.searchText = filterData.searchText;
            updated = true;
        }

        if(updated)
            SetVariables(newVariables)

    }

    const SelectRow = (e,articleID) => {

        var checked = e.target.checked;
        var newSelectedArticleIDs = [...selectedArticleIDs];
        const newList = SelectArticleID(content,articleID,checked,newSelectedArticleIDs)

        SetContent(newList);
        SetSelectedArticleIDs(newSelectedArticleIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedArticleIDs = [];
        const newList = SelectArticleID(content,"all",checked,newSelectedArticleIDs)

        SetSelectedArticleIDs(newSelectedArticleIDs);
        SetContent(newList);
    }

    const SelectArticleID = (list,articleID,checked,selectedArticleIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(articleID == newList[i].rowID || articleID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedArticleIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedArticleIDs.length; j++)
                    {
                        if(selectedArticleIDs[j] == newList[i].rowID)
                            selectedArticleIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectArticleID(newList[i].subData,articleID,checked,selectedArticleIDs)}
        }

        return newList;
    }

    return{
        content,
        totalContentLength: data && data.AllArticlesCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedArticleIDs,
        SelectRow,
        SelectAll,
        FetchMore,
        GoToPage,
        FilterArticles
    }

}

export default UseGetAllArticles;