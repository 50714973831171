import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import Label from '../../../GlobalComponents/Label';
import { useTranslation } from 'react-i18next';
import UseAddEditBillingInformation from '../Library/UseAddEditBillingInformation';
import UseGetBillingInformation from '../Library/UseGetBillingInformation';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import InputCard from '../../../GlobalComponents/InputCard';
import Input from '../../../GlobalComponents/Input';
import Select from '../../../GlobalComponents/Select';
import creditCard from '../../../Media/Images/Icons/credit_card.webp';

Modal.setAppElement('#root');

const AddEditBillingInformation = ({selectedBillingInformationID,SetSelectedBillingInformationID,OnAddEditSuccess}) => {

    const {t} = useTranslation();

    const {
        loading:getLoading,
        error:getError,
        allCountries,
    } = UseGetBillingInformation(selectedBillingInformationID,(d) => {
        InitFormData(d)  
    });

    const {
        loading,
        error,
        formData,
        AddEditBillingInformation,
        InitFormData,
        SetFormData,
    } = UseAddEditBillingInformation(selectedBillingInformationID,(d) => OnAddEditSuccess(d));

    
    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedBillingInformationID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedBillingInformationID === 0 ? t("AddBillingInformation") : t("EditBillingInformation"))}
                OnClose ={() => SetSelectedBillingInformationID(null)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <div className="row">
                                <div className="col-60">
                                    <div className="row">
                                        <div className={formData.countryID == 236 || formData.countryID == 237 ? "col-100" : "col-50"}>
                                            <div className="form-group">
                                                <label>*{t("CompanyName")}</label>
                                                <Input
                                                    name="company"
                                                    value={formData.company}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-50">
                                            <div className="form-group">
                                                <label>*{
                                                    (formData.countryID == 236 || formData.countryID == 237 ? 
                                                        t("StreetLine1") 
                                                    : 
                                                        t("Street")
                                                    )
                                                }</label>
                                                <Input
                                                    name="street1"
                                                    value={formData.street1}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                        {formData.countryID == 236 || formData.countryID == 237 ?
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>{t("StreetLine2")}</label>
                                                    <Input
                                                        name="street2"
                                                        value={formData.street2}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        :null}
                                        <div className="col-50">
                                            <div className="form-group">
                                                <label>*{t("City")}</label>
                                                <Input
                                                    name="city"
                                                    value={formData.city}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-50">
                                            <div className="form-group">
                                                <label>*{t("Zip")}</label>
                                                <Input
                                                    name="zip"
                                                    value={formData.zip}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                        
                                        {formData.countryID == 209 || formData.countryID == 237 ?
                                            <div className="col-50">
                                                <div className="form-group">
                                                    <label>{(formData.countryID == 209 ? t("Province") : t("State"))}</label>
                                                    <Input
                                                        name="state"
                                                        value={formData.state}
                                                        OnChange={(e) => SetFormData(e)}
                                                    />
                                                </div>
                                            </div>
                                        :null}

                                        <div className={formData.countryID == 209 || formData.countryID == 237 ? "col-50" : "col-100"}>
                                            <div className="form-group">
                                                <label>*{t("Country")}</label>
                                                <Select
                                                    name="countryID"
                                                    value={formData.countryID}
                                                    OnChange={(e) => SetFormData(e)}
                                                >
                                                    <option value={0}>{" - - - " + t("choose") + " - - - "}</option>
                                                    {allCountries.map((item) => (
                                                        <option 
                                                            key={item.countryID}
                                                            value={item.countryID}
                                                        >{item.name}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-50">
                                            <div className="form-group">
                                                <label>{t("IDnumber")}</label>
                                                <Input
                                                    name="idNumber"
                                                    value={formData.idNumber}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-50">
                                            <div className="form-group">
                                                <label>{t("VATnumber")}</label>
                                                <Input
                                                    name="vatNumber"
                                                    value={formData.vatNumber}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-100">
                                            <div className="form-group">
                                                <label>{t("Email")}</label>
                                                <Input
                                                    name="email"
                                                    value={formData.email}
                                                    OnChange={(e) => SetFormData(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-40 d-flex">
                                    <InputCard
                                        title = {t("BankAccount")}
                                        icon = {creditCard}
                                        className = {"form-group flex-1"}
                                        topMargin={true}
                                    > 
                                        <div className="form-group">
                                            <label>{t("AccountNumber")}</label>
                                            <Input
                                                name="bankAccountNumber"
                                                value={formData.bankAccountNumber}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>{t("IBAN")}</label>
                                            <Input
                                                name="iban"
                                                value={formData.iban}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>{t("BIC/SWIFT")}</label>
                                            <Input
                                                name="swift"
                                                value={formData.swift}
                                                OnChange={(e) => SetFormData(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>{t("BankAccountCountry")}</label>
                                            <Select
                                                name="bankAccountCountryID"
                                                value={formData.bankAccountCountryID}
                                                OnChange={(e) => SetFormData(e)}
                                            >
                                                <option value={0}>{" - - - " + t("choose") + " - - - "}</option>
                                                {allCountries.map((item) => (
                                                    <option 
                                                        key={item.countryID}
                                                        value={item.countryID}
                                                    >{item.name}</option>
                                                ))}
                                            </Select>
                                        </div>
                                    </InputCard>
                                    
                                </div>
                                
                            </div>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditBillingInformation()}
                >
                    {(selectedBillingInformationID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditBillingInformation;