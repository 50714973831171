import {useQuery} from '@apollo/client';
import { GET_RESERVATION_PLACES } from '../Queries/reservationPlace';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { CompleteLangsArray } from '../../../GlobalFunctions/helper';

const UseChooseReservationPlaces = (
    lang,
    selectedReservationPlaces,
    FillFormSelectedItemsWithObj,
    allLanguageMutations
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    
    const {data,loading,error} = useQuery(GET_RESERVATION_PLACES,{
        fetchPolicy:"network-only",
        variables:{
            lang,
            onlyActive:false
        }
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllReservationPlaces);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[data,selectedReservationPlaces])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"lis",
                        backgroundColor: val.backgroundColor,
                        text: val.internalName,
                        className:"flex-1"
                    }
                ],
            }

            cData.selected = false;
            if(selectedReservationPlaces && selectedReservationPlaces.length > 0)
            {
                for(let v of selectedReservationPlaces)
                {
                    if(val.reservationPlaceID == v.reservationPlaceID)
                        cData.selected = true;
                }
            }

            cData.rowBackgroundColor    = val.backgroundColor;
            cData.rowName               = val.internalName;
            cData.rowTimes              = val.times;
            cData.rowID                 = val.reservationPlaceID;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,data) => {

        SelectReservationPlaceID(content,data.rowID);    
    }

    const SelectReservationPlaceID = (list,rowID) => {

        for(let i = 0; i < list.length; i++)
        {
            if(rowID == list[i].rowID || rowID === "all")
            {     
                const emptyLangs = GetEmptyLangs();
                const langs =  CompleteLangsArray([],allLanguageMutations,emptyLangs);  

                FillFormSelectedItemsWithObj("selectedReservationPlaces","reservationPlaceID",{
                    internalName: list[i].rowName,
                    reservationPlaceID: list[i].rowID,
                    backgroundColor: list[i].rowBackgroundColor,
                    times: list[i].rowTimes,
                    selectedTimes:[],
                    langs
                },false);

                break;
            }
        }
    }

    const GetEmptyLangs = () => {
        return {
            emailText:"",
            templateTherapyEmailText:""
        }
    }

    return{
        content,
        headerData,
        loading,
        error,
        SelectRow,
        GetEmptyLangs
    }

}

export default UseChooseReservationPlaces;