import { useState } from 'react';
import RadioButton from '../../../GlobalComponents/RadioButton';
import Select from '../../../GlobalComponents/Select';
import eye from '../../../Media/Images/Icons/eye.webp';
import InputCard from '../../../GlobalComponents/InputCard';
import Checkbox from '../../../GlobalComponents/Checkbox';
import Input from '../../../GlobalComponents/Input';
import ChooseForms from '../../Form/Components/ChooseForms';
import Button from '../../../GlobalComponents/Button';
import { useTranslation } from 'react-i18next';
import List from '../../../GlobalComponents/List/List';
import UseEditForms from '../Library/UseEditForms';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import ChooseProducts from '../../Eshop/Components/Products/ChooseProducts';
import UseEditProducts from '../Library/UseEditProducts';
import startIcon from '../../../Media/Images/Icons/start.webp';

const BasicInformations = ({
    formData,
    allLinks,
    SetFormData,
    FillFormSelectedItemsWithObj,
    RemoveFormDataSelectedItems
}) => {

    const {t} = useTranslation();
    const [showAddForms,SetShowAddForms] = useState(false);
    const [showAddProducts,SetShowAddProducts] = useState(false);

    const {headerData,content} = UseEditForms(formData.selectedForms);
    const {headerData:productHeaderData,content:contentData} = UseEditProducts(formData.selectedProducts);

    return(
        <>
            <div className="form-group">
                <label>*{t("CampaignName")}</label>
                <Input
                    name="name"
                    value={formData.name}
                    OnChange={(e) => SetFormData(e)}
                />
            </div>  
            <InputCard
                title = {t("Display")}
                icon = {eye}
                className = {"form-group"}
            >   
                <div className="row">
                    <div className="col-25">
                        <div className="form-group">
                            
                            <label>{t("Active")} </label>
                            <div className="radio-container">
                                <RadioButton
                                    text={t("Yes")}
                                    name="active"
                                    id="active-1"
                                    value={1}
                                    checked = {formData.active === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text={t("No")}
                                    name="active"
                                    id="active-2"
                                    value={0}
                                    checked = {formData.active === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-25">
                        <div className="form-group">
                            
                            <label>{t("UseEmailsFrom")} </label>
                            <div className="radio-container">
                                <RadioButton
                                    text={t("FromCampaign")}
                                    name="useEmailsFromNewsletter"
                                    id="useEmailsFromNewsletter-1"
                                    value={0}
                                    checked = {formData.useEmailsFromNewsletter === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text={t("FromNewsletter")}
                                    name="useEmailsFromNewsletter"
                                    id="useEmailsFromNewsletter-2"
                                    value={1}
                                    checked = {formData.useEmailsFromNewsletter === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div>
                                            
                </div> 
            </InputCard>
            <InputCard
                title={t("WhenShouldCampaignStart")}
                icon={startIcon}
            >
                <InputCard
                    className="form-group"
                    title={t("Newsletter")}
                >
                    <div className="form-group">
                        <Checkbox 
                            text = {t("ByAddEmailToNewsletter")}
                            id = "isForNewsletter"
                            name = "isForNewsletter"
                            checked = {formData.isForNewsletter}
                            OnChange = {(e) => SetFormData(e)}
                        />
                    </div>
                </InputCard>
                
                <InputCard
                    className="form-group"
                    title={t("ByBuyMembershipOnPage")}
                >
                    <div className="form-group">
                        <Select
                            name="linkID"
                            value={formData.linkID}
                            OnChange = {(e) => SetFormData(e)}
                        >
                            <option value={0}>---{t("Choose")}---</option>
                            {allLinks && allLinks.map((item) => (
                                <option
                                    key={item.linkID}
                                    value = {item.linkID}
                                >{item.name}</option>
                            ))}
                                
                        </Select>
                    </div>
                </InputCard> 

                <InputCard
                    className="form-group"
                    title={t("Therapy")}
                >
                    <div className="form-group">
                        <Checkbox 
                            text = {t("BySendTherapyToClientByEmail")}
                            id = "isForTherapy"
                            name = "isForTherapy"
                            checked = {formData.isForTherapy}
                            OnChange = {(e) => SetFormData(e)}
                        />
                    </div>
                </InputCard>

                <InputCard
                    className="form-group"
                    title={t("ByFillFormBellow")}
                    noBodyPadding={true}
                    headerChildren = {
                        <>
                            <Button 
                                OnClick = {(e) => {
                                    e.stopPropagation();
                                    SetShowAddForms(true)
                                }}
                                className="btn-primary narrow"
                            >{formData.selectedForms && formData.selectedForms.length > 0 ? t("Edit") : t("Choose")}</Button>
                        </>

                    }
                >
                    <List 
                        headerData = {headerData} 
                        headerClassName = {"no-top-border"}
                        contentData = {content}
                        isSortable = {false}
                        loading = {false}
                        replaceDotsImg = {cancelIcon}
                        ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedForms","formID",[data.rowID])}
                    />

                </InputCard> 

                <InputCard
                    className="form-group"
                    title={t("ByBuyProductBellow")}
                    noBodyPadding={true}
                    headerChildren = {
                        <>
                            <Button 
                                OnClick = {(e) => {
                                    e.stopPropagation();
                                    SetShowAddProducts(true)
                                }}
                                className="btn-primary narrow"
                            >{formData.selectedProducts && formData.selectedProducts.length > 0 ? t("Edit") : t("Choose")}</Button>
                        </>

                    }
                >
                    <List 
                        headerData = {productHeaderData} 
                        headerClassName = {"no-top-border"}
                        contentData = {contentData}
                        isSortable = {false}
                        loading = {false}
                        replaceDotsImg = {cancelIcon}
                        ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedProducts","productID",[data.rowID])}
                    />
                
                </InputCard> 
            
            </InputCard>  

            {showAddForms ? 
                <ChooseForms 
                    formData = {formData}
                    SetShowAddForms = {SetShowAddForms}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}

            {showAddProducts ? 
                <ChooseProducts 
                    formData = {formData}
                    SetShowAddProducts = {SetShowAddProducts}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
        </>
    )
}

export default BasicInformations;