import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import overlyByTextIcon from "../../../../../Media/Images/Icons/layers.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import ImageResponziveSettings from "./ImageResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { IMAGE } from "../../../Library/elements";
import Select from "../../../../../GlobalComponents/Select";
import RadioButton from "../../../../../GlobalComponents/RadioButton";

const ImageSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(IMAGE.image); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection,
        SetOpenFileManagerData
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"image");
            SetCompleteFormData(settings);
        }

    },[showSettingsElement])

    const SetS = (e,val) => {
        SetFormData(e,val);
        SetSettings(showSettingsElement.index,e,val,"image");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"image");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <ImageResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <ImageResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <ImageResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <ImageResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <ImageResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <ImageResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="form-group">
                {formData.fileName ?
                    <div className = "row">
                        <div className = "col-50">
                            <Button 
                                className="btn-primary w-100"
                                OnClick = {() => SetOpenFileManagerData({
                                    element:"image",
                                    type:1,
                                    index:showSettingsElement.index,
                                    CallBack: (fileName) => SetS("fileName",fileName)
                                })}
                            >{t("ChangePhoto")}</Button>
                        </div>
                        <div className = "col-50">
                            <Button 
                                className="btn-brown w-100"
                                OnClick = {() => SetS("fileName","")}
                            >{t("RemovePhoto")}</Button>
                        </div>
                    </div>
                :
                    <Button 
                        className="btn-primary w-100"
                        OnClick = {() => SetOpenFileManagerData({
                            element:"image",
                            type:1,
                            index:showSettingsElement.index,
                            CallBack: (fileName) => SetS("fileName",fileName)
                        })}
                    >{t("ChoosePhoto")}</Button>
                }
            </div>
            <div className = "row">
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("ImageSize")}</label>
                        <Select
                            name = {"imageSize"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.imageSize}
                        >
                            <option value={"mala"}>{t("Small")}</option>
                            <option value={"stredni"}>{t("Middle")}</option>
                            <option value={"velka"}>{t("Big")}</option>
                            <option value={"maxi"}>{t("Max")}</option>
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("ImageAlignType")}</label>
                        <Select
                            name = {"imageAlign"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.imageAlign}
                        >
                            <option value={"start"}>{t("Left")}</option>
                            <option value={"center"}>{t("Center")}</option>
                            <option value={"end"}>{t("Right")}</option>
                        </Select>
                    </div>
                </div>

                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Radius")}</label>
                        <Input 
                            type = "number"
                            name = "borderRadius"
                            value = {formData.borderRadius}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>

                <div className="col-50">
                    <div className="form-group">
                        <label>{t("UseDimensions")}</label>
                        <Select
                            name = {"useDimensions"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.useDimensions}
                        >
                            <option value={"1"}>{t("Yes")}</option>
                            <option value={"0"}>{t("No")}</option>
                        </Select>
                    </div>
                </div>                
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("Link")}</label>
                        <Input 
                            type = "text"
                            name = "link"
                            value = {formData.link}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("LinkGoesTo")}</label>
                        <Select
                            name = {"linkTarget"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.linkTarget}
                        >
                            <option value={"_self"}>{t("toSameWindow")}</option>
                            <option value={"_blank"}>{t("toNewWindow")}</option>
                        </Select>
                    </div>
                </div>
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("OverlayByText")}
                    icon = {overlyByTextIcon}
                >
                    <div className="form-group">
                                                    
                        <label>{t("UseOverlayByText")}</label>
                        <div className="radio-container">
                            <RadioButton
                                text="Ano"
                                name="useOverlayByText"
                                id="useOverlayByText-1"
                                value={1}
                                checked = {formData.useOverlayByText === 1 ? true : false}
                                OnChange={(e) => SetS(e)}
                            />
                            <RadioButton
                                text="Ne"
                                name="useOverlayByText"
                                id="useOverlayByText-2"
                                value={0}
                                checked = {formData.useOverlayByText === 0 ? true : false}
                                OnChange={(e) => SetS(e)}
                            />
                        </div>
                    </div>
                    {formData.useOverlayByText == 1 ?
                    
                        <div className="row">
                            <div className="col-50">
                                <div className="form-group">
                                    <label>{t("BackgroundColor")}</label>
                                    <Select
                                        name = {"overlayColor"}
                                        OnChange = {(e) => SetS(e)}
                                        value = {formData.overlayColor}
                                    >
                                        <option value={"black"}>{t("Black")}</option>
                                        <option value={"white"}>{t("White")}</option>
                                    </Select>
                                </div>
                            </div>
                            <div className="col-50">
                                <div className="form-group">
                                    <label>{t("Průhlednost")}</label>
                                    <Select
                                        name = {"overlayOpacity"}
                                        value = {formData.overlayOpacity}
                                        OnChange = {(e) => SetS(e)}
                                    >
                                        <option value = {"10"}>1</option>
                                        <option value = {"9"}>0.9</option>
                                        <option value = {"8"}>0.8</option>
                                        <option value = {"7"}>0.7</option>
                                        <option value = {"6"}>0.6</option>
                                        <option value = {"5"}>0.5</option>
                                        <option value = {"4"}>0.4</option>
                                        <option value = {"3"}>0.3</option>
                                        <option value = {"2"}>0.2</option>
                                        <option value = {"1"}>0.1</option>
                                        <option value = {"0"}>0</option>
                                    </Select>
                                </div>
                            </div>
                            <div className="col-50">
                                <div className="form-group">
                                    <label>{t("HorizontalAlign")}</label>
                                    <Select
                                        name = {"overlayHorizontalAlign"}
                                        OnChange = {(e) => SetS(e)}
                                        value = {formData.overlayHorizontalAlign}
                                    >
                                        <option value={"start"}>{t("Left")}</option>
                                        <option value={"center"}>{t("Center")}</option>
                                        <option value={"end"}>{t("Right")}</option>
                                    </Select>
                                </div>
                            </div>
                            <div className="col-50">
                                <div className="form-group">
                                    <label>{t("VerticalAlign")}</label>
                                    <Select
                                        name = {"overlayVerticalAlign"}
                                        OnChange = {(e) => SetS(e)}
                                        value = {formData.overlayVerticalAlign}
                                    >
                                        <option value={"start"}>{t("ToTop")}</option>
                                        <option value={"center"}>{t("ToCenter")}</option>
                                        <option value={"end"}>{t("ToBottom")}</option>
                                    </Select>
                                </div>
                            </div>
                        </div>



                    
                    :null}
                </InputCard>
                
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default ImageSettings;