import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const UseEditLabels = (selectedLabels,SetFormDataSelectedItemsWithObj) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedLabels)
        {
            const contentData = GetContentData(selectedLabels);
            SetContent(contentData);
        }

    },[selectedLabels])

    var headerData = [
        {
            value: t("LabelName"),
            className:"flex-1"
        },
        {
            value: t("UseDates"),
            className:"static small text-center"
        },
        {
            value: t("DateFrom"),
            className:"static small text-center"
        },
        {
            value: t("DateTo"),
            className:"static small text-center"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        elm:"checkbox",
                        param:"useDates",
                        name:"useDates",
                        OnChange: (e) => SetFormDataSelectedItemsWithObj(e,"selectedLabels","labelID",val.labelID),
                        className:"static small d-flex justify-content-center"
                    },
                    {
                        elm:"datepicker",
                        param:"dateFrom",
                        name:"dateFrom",
                        OnChange: (dateStr,date) => SetFormDataSelectedItemsWithObj("dateFrom","selectedLabels","labelID",val.labelID,date),
                        className:"static small d-flex justify-content-center"
                    },
                    {
                        elm:"datepicker",
                        param:"dateTo",
                        name:"dateTo",
                        OnChange: (dateStr,date) => SetFormDataSelectedItemsWithObj("dateTo","selectedLabels","labelID",val.labelID,date),
                        className:"static small d-flex justify-content-center"
                    }
                ],
            }

            cData["useDates"]   = val.useDates;
            cData["dateFrom"]   = val.dateFrom;
            cData["dateTo"]     = val.dateTo;
            cData.rowID         = val.labelID;
            cData.selected      = false;

            contentData.push(cData);
        }
        return contentData;
    }

    return{
        headerData,
        content
    }

}

export default UseEditLabels;