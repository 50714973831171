const Administrator = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M256,447.403c-25.366,0-46.004-20.637-46.004-46.004s20.638-46.004,46.004-46.004
                            c25.367,0,46.005,20.638,46.005,46.004S281.367,447.403,256,447.403z M256,377.395c-13.235,0-24.004,10.769-24.004,24.004
                            s10.769,24.004,24.004,24.004c13.236,0,24.005-10.768,24.005-24.004S269.236,377.395,256,377.395z"/>
                    </g>
                    <g>
                        <path style={{"fill":primaryColor}} d="M281.555,234.203c-4.238-4.352-11.203-4.445-15.555-0.207c-2.783,2.709-6.455,4.202-10.341,4.202
                            c-3.688,0-7.221-1.361-9.949-3.834c-4.503-4.078-11.458-3.736-15.537,0.766c-4.079,4.502-3.736,11.458,0.766,15.538
                            c6.783,6.146,15.562,9.53,24.721,9.53c9.652,0,18.775-3.708,25.688-10.44C285.701,245.52,285.793,238.556,281.555,234.203z"/>
                        <path style={{"fill":primaryColor}} d="M433.56,381.29v-49.733c0-26.06-21.201-47.26-47.261-47.26H348.91c13.792-18.82,21.623-41.534,22.153-65.249h5.834
                            c20.599,0,37.356-16.758,37.356-37.383c0-7.22-2.065-13.963-5.626-19.683v-9.657C408.627,68.332,340.295,0,256.304,0
                            S103.981,68.332,103.981,152.323v9.664c-3.561,5.724-5.626,12.474-5.626,19.703c0,20.599,16.758,37.356,37.356,37.356h5.833
                            c0.533,23.638,8.375,46.408,22.161,65.249H125.7c-26.06,0-47.26,21.201-47.26,47.26v49.738
                            c-31.643,17.14-51.952,51.357-51.952,87.947v16.751c0,14.341,11.667,26.008,26.007,26.008h407.009
                            c14.341,0,26.008-11.667,26.008-26.008v-16.751C485.512,432.28,465.324,398.466,433.56,381.29z M376.897,197.047h-5.799v-30.739
                            h5.799c8.468,0,15.356,6.889,15.356,15.383C392.253,190.158,385.365,197.047,376.897,197.047z M256.304,22
                            c69.589,0,126.613,54.826,130.148,123.551c-3.052-0.808-6.253-1.244-9.556-1.244c0,0-33.169,1.868-51.14,0.104
                            c-15.681-1.541-37.136-6.205-50.301-19.674c-5.142-5.259-12.052-8.155-19.458-8.155c-7.406,0-14.315,2.897-19.455,8.156
                            c-25.201,25.784-100.832,19.569-100.832,19.569c-3.302,0-6.504,0.436-9.556,1.244C129.691,76.826,186.715,22,256.304,22z
                            M120.355,181.664c0-8.467,6.889-15.356,15.356-15.356h5.799v30.739h-5.799C127.244,197.047,120.355,190.158,120.355,181.664z
                            M163.51,216.47v-8.423v-40.936c21.829,0.798,63.782-1.435,88.766-26.996c1.305-1.334,2.893-1.534,3.724-1.534
                            s2.42,0.199,3.725,1.533c25.184,25.768,68.213,27.736,89.374,26.952v40.979v8.423c0,25.794-10.7,50.288-29.478,67.826H192.983
                            C174.23,266.774,163.51,242.172,163.51,216.47z M48.488,485.992v-16.751c0-24.285,11.416-47.205,29.952-61.726V490H52.495
                            C50.286,490,48.488,488.202,48.488,485.992z M100.44,490V331.556c0-13.929,11.331-25.26,25.26-25.26h198.108
                            c0.009,0,0.019,0.001,0.028,0.001c0.011,0,0.023-0.001,0.034-0.001h62.43c13.929,0,25.261,11.332,25.261,25.26V490H100.44z
                            M463.512,485.992c0,2.21-1.798,4.008-4.008,4.008H433.56v-82.519c18.591,14.474,29.952,37.215,29.952,61.76V485.992z"/>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Administrator;
