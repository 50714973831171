const Edit = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <g>
                            <path 
                                style={{"fill":secondaryColor}}  
                                d="M452.002,168.935c-2.918,0-5.717-1.159-7.78-3.222l-98-98c-4.295-4.295-4.296-11.258-0.003-15.554     c0,0,22.959-22.975,37.5-37.468c18.797-19.509,52.461-19.51,71.263,0.035c11.886,11.861,30.373,30.332,42.273,42.207     c19.48,18.991,19.493,52.32,0.029,71.305c-14.563,14.515-37.501,37.469-37.502,37.471     C457.718,167.775,454.92,168.935,452.002,168.935z M369.555,59.934l82.444,82.443c7.994-7.995,20.536-20.532,29.72-29.686     c10.95-10.685,10.948-29.523-0.035-40.214c-11.886-11.862-30.373-30.333-42.273-42.209c-10.592-11.008-29.54-11.018-40.127-0.029     C390.086,39.408,377.546,51.943,369.555,59.934z"/>
                        </g>
                        <g>
                            <path 
                                style={{"fill":secondaryColor}}  
                                d="M468.333,511.935H354c-24.078,0-43.667-19.589-43.667-43.667v-49     c0-24.078,19.589-43.667,43.667-43.667h114.333c24.078,0,43.667,19.589,43.667,43.667v49     C512,492.346,492.412,511.935,468.333,511.935z M354,397.602c-11.947,0-21.667,9.72-21.667,21.667v49     c0,11.947,9.72,21.667,21.667,21.667h114.333c11.947,0,21.667-9.72,21.667-21.667v-49c0-11.947-9.72-21.667-21.667-21.667H354z"/>
                        </g>
                    </g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M444.222,165.714l-7.778-7.778L141.667,452.712l-33.443-33.443L403,124.492l-15.557-15.557L92.667,403.712l-33.444-33.444   L354,75.492l-7.778-7.778c-4.295-4.295-4.296-11.258-0.003-15.554L35.889,362.491c-1.356,1.374-2.364,3.108-2.847,4.966   C32.876,367.552,0.45,498.145,0.328,498.267c-0.936,3.748,0.161,7.714,2.894,10.446c2.735,2.743,6.74,3.828,10.446,2.893   c0.098,0.002,130.715-32.685,130.807-32.712c1.86-0.483,3.595-1.492,4.97-2.848c0.003,0,310.332-310.335,310.334-310.335   C455.683,169.974,448.319,169.975,444.222,165.714z M26.119,485.817l23.508-94.032l70.524,70.524L26.119,485.817z"/>
                </g>
            </svg>
        </>
    )
}

export default Edit;
