import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import Input from '../../../GlobalComponents/Input';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddCampaignContact from '../Library/UseAddCampaignContact';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Label from '../../../GlobalComponents/Label';
import Select from '../../../GlobalComponents/Select';

Modal.setAppElement('#root');

const AddEditCampaignContact = ({
    campaignID,
    allLanguages,
    SetShowAddContact,
    OnAddEditSuccess
}) => {

    const {t} = useTranslation();

    const {
        loading,
        error,
        formData,
        AddEditCampaignContact,
        SetFormData,
    } = UseAddCampaignContact(campaignID,(d) => OnAddEditSuccess(d));

    var err = "";
    if(error)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedCampaignEmailID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("AddCampaignContact")}
                OnClose ={() => SetShowAddContact(false)}
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading ?
                            <Loading />   
                        :  
                            <>
                                <div className="form-group">
                                    <label>{t("Language")}</label>
                                    <div className="form-group">
                                        <Select
                                            name="lang"
                                            value = {formData.lang}
                                            OnChange = {(e) =>  SetFormData(e)}
                                        >
                                            {allLanguages.map((item) => (
                                                <option 
                                                    value={item.suffix}
                                                    key={item.languageID}
                                                >{item.suffix ? item.suffix : "- - -" + t("Choose") + "- - -"}</option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div>
                                    <Label 
                                        text={t("Emails")}
                                        infoContent={[
                                            t("AddCampaignContactInfo")
                                        ]}
                                    />
                                    <Input
                                        name="emails"
                                        value={formData.emails}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>                            
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditCampaignContact()}
                >
                    {t("Add")}
                </Button>        
            </div>

        </Modal>
    )
}

export default AddEditCampaignContact;