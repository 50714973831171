const Select = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M11,149.339c-6.075,0-11-4.925-11-11V98.432c0.541-14.579,21.464-14.568,22,0v39.907
                            C22,144.415,17.075,149.339,11,149.339z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M11,48.436c-6.075,0-11-4.925-11-11V11.004c0-6.075,4.925-11,11-11h26.33
                            c14.579,0.541,14.568,21.464,0,22H22v15.432C22,43.511,17.075,48.436,11,48.436z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M135.691,22.004H99.907c-14.579-0.541-14.568-21.464,0-22h35.784
                            C150.27,0.545,150.259,21.469,135.691,22.004z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M135.691,236.634H99.907c-14.579-0.541-14.568-21.464,0-22h35.784
                            C150.27,215.175,150.259,236.099,135.691,236.634z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}}  
                            d="M37.865,236.633H11c-6.075,0-11-4.925-11-11v-26.887c0.541-14.579,21.464-14.568,22,0v15.887
                            h15.865C52.444,215.174,52.433,236.098,37.865,236.633z"/>
                    </g>
                    <g>
                        <path
                            style={{"fill":secondaryColor}} 
                            d="M224.448,128.695c-6.075,0-11-4.925-11-11V98.432c0.541-14.579,21.464-14.568,22,0v19.263
                            C235.448,123.77,230.523,128.695,224.448,128.695z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M224.448,48.436c-6.075,0-11-4.925-11-11V22.004h-15.33c-14.579-0.541-14.568-21.464,0-22h26.33
                            c6.075,0,11,4.925,11,11v26.432C235.448,43.511,230.523,48.436,224.448,48.436z"/>
                    </g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M498.902,306.966l-19.876-62.89c-6.921-21.81-30.294-33.921-52.105-27.003c-9.146,2.902-16.835,8.741-22.046,16.641
                        c-2.335-1.962-4.895-3.689-7.669-5.126c-9.84-5.099-21.079-6.061-31.645-2.71c-8.97,2.846-16.533,8.521-21.737,16.19
                        c-2.495-2.181-5.267-4.08-8.285-5.644c-15.649-8.503-36.68-4.97-48.754,7.838l-21.219-66.87
                        c-3.353-10.565-10.619-19.192-20.46-24.293c-9.841-5.102-21.08-6.062-31.645-2.71c-21.81,6.921-33.924,30.295-27.004,52.105
                        l33.824,106.594c-13.634,6.739-24.725,17.6-31.823,31.378c-8.801,17.084-10.398,36.565-4.496,54.854l10.324,31.997
                        c14.131,43.974,51.189,76.142,96.711,83.953c0.603,0.104,1.214,0.156,1.826,0.158l185.175,0.575
                        c4.37,0.014,8.339-2.564,10.101-6.566C515.706,442.757,519.542,372.272,498.902,306.966z M470.76,489.982l-176.926-0.55
                        c-37.01-6.682-67.08-32.988-78.607-68.857l-10.329-32.01c-7.376-21.522,2.455-47.023,22.149-58.15l19.367,61.032
                        c1.838,5.79,8.023,8.998,13.812,7.157c5.79-1.837,8.995-8.021,7.158-13.811l-10.76-33.91c0-0.002-0.001-0.003-0.001-0.005
                        L207.426,195.84c-3.251-10.247,2.44-21.23,12.688-24.482c4.965-1.573,10.245-1.121,14.868,1.273
                        c4.624,2.396,8.038,6.45,9.613,11.415l31.055,97.867c0.143,0.451,0.321,0.879,0.514,1.297c0.216,0.871,0.45,1.741,0.725,2.609
                        l3.396,10.703c1.823,5.843,8.179,8.993,13.812,7.157c5.791-1.837,8.995-8.021,7.158-13.811l-3.396-10.703
                        c-3.251-10.247,2.44-21.23,12.688-24.482c4.964-1.573,10.244-1.121,14.868,1.273c4.624,2.396,8.038,6.45,9.613,11.415l3.397,10.703
                        c0.181,0.57,0.415,1.107,0.676,1.624l2.521,7.944c1.824,5.845,8.179,8.993,13.812,7.157c5.79-1.837,8.995-8.021,7.157-13.811
                        l-3.065-9.659c-3.377-9.924,2.633-21.518,12.688-24.481c4.964-1.573,10.245-1.124,14.869,1.273
                        c4.624,2.396,8.038,6.45,9.613,11.414l3.065,9.659c0.181,0.571,0.416,1.11,0.677,1.628l2.567,8.09
                        c1.837,5.79,8.02,8.996,13.812,7.157c5.79-1.837,8.995-8.021,7.158-13.811l-3.088-9.731c-1.576-4.965-1.124-10.245,1.273-14.87
                        c2.396-4.624,6.45-8.038,11.414-9.613c10.247-3.252,21.23,2.439,24.478,12.675l19.872,62.878
                        C496.233,371.524,493.651,433.875,470.76,489.982z"/>
                </g>
            </svg>
        </>
    )
}

export default Select;
