const Menu = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <path style={{"fill":primaryColor}} d="M470.258 491H41.7415C18.7252 491 0 472.383 0 449.5C0 426.617 18.7252 408 41.7415 408H470.258C493.275 408 512 426.617 512 449.5C512 472.383 493.275 491 470.258 491ZM41.7415 430.001C30.9274 430.001 22.1291 438.748 22.1291 449.5C22.1291 460.252 30.9274 468.999 41.7415 468.999H470.258C481.073 468.999 489.871 460.252 489.871 449.5C489.871 438.748 481.073 430.001 470.258 430.001H41.7415Z" fill="black"/>
                <path style={{"fill":primaryColor}} d="M470.258 103H41.7415C18.7252 103 0 84.3831 0 61.5C0 38.6169 18.7252 20 41.7415 20H470.258C493.275 20 512 38.6169 512 61.5C512 84.3831 493.275 103 470.258 103ZM41.7415 42.0011C30.9274 42.0011 22.1291 50.7485 22.1291 61.5C22.1291 72.2515 30.9274 80.9989 41.7415 80.9989H470.258C481.073 80.9989 489.871 72.2515 489.871 61.5C489.871 50.7485 481.073 42.0011 470.258 42.0011H41.7415Z" fill="black"/>
                <path style={{"fill":primaryColor}} d="M470.258 297H41.7415C18.7252 297 0 278.383 0 255.5C0 232.617 18.7252 214 41.7415 214H470.258C493.275 214 512 232.617 512 255.5C512 278.383 493.275 297 470.258 297ZM41.7415 236.001C30.9274 236.001 22.1291 244.748 22.1291 255.5C22.1291 266.252 30.9274 274.999 41.7415 274.999H470.258C481.073 274.999 489.871 266.252 489.871 255.5C489.871 244.748 481.073 236.001 470.258 236.001H41.7415Z" fill="black"/>
            </svg>
        </>
    )
}

export default Menu;