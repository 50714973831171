import Video from "../../../../../GlobalComponents/Video";

const VideoGalleryItem = ({data,borderRadiusStyle}) => {
    return(
        <div 
            style={(borderRadiusStyle ? borderRadiusStyle : null)}
            className={"video-item " + (data.orientation == 1 ? "landscape" : "portrait")}
        >
            <div 
                style={(borderRadiusStyle ? borderRadiusStyle : null)}
                className="video-iframe"
            >
                <Video data = {data} />
            </div>
        </div>
    )
}

export default VideoGalleryItem;