import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditAdminRole from "./AddEditAdminRole";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllAdminRoles from "../Library/UseGetAllAdminRoles";
import UseDeleteAdminRoles from "../Library/UseDeleteAdminRoles.js";
import ModalNotification from "../../../GlobalComponents/ModalNotification";

const AdminRoles = () => {

    const {t} = useTranslation();

    const [selectedAdminRoleID, SetSelectedAdminRoleID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
        selectedAdminRoleIDs,
        SelectRow,
        SelectAll,
        UpdateListAfterAddEdit,
        UpdateListAfterDelete
    } = UseGetAllAdminRoles();

    const {
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteAdminRoles
    } = UseDeleteAdminRoles(selectedAdminRoleIDs,(d) => UpdateListAfterDelete(d));

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("AdminRoleList")}
                        OnAddButtonClick={() => SetSelectedAdminRoleID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (AdminRoleID) => SetSelectedAdminRoleID(AdminRoleID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                        }}
                    />
                </>
            }

            {selectedAdminRoleID || selectedAdminRoleID == 0 ?
                <AddEditAdminRole
                    selectedAdminRoleID = {selectedAdminRoleID}
                    SetSelectedAdminRoleID = {SetSelectedAdminRoleID}
                    OnAddEditSuccess = {(d) => {
                        UpdateListAfterAddEdit(selectedAdminRoleID,d);
                        SetSelectedAdminRoleID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteAdminRoles")} 
                    CallBack={DeleteAdminRoles} 
                />
            :null}

        </>
    )
}

export default AdminRoles;