import { useTranslation } from 'react-i18next';
import RadioButton from '../../../GlobalComponents/RadioButton';
import Input from '../../../GlobalComponents/Input';
import InputCard from '../../../GlobalComponents/InputCard';
import eye from '../../../Media/Images/Icons/eye.webp';
import qrCode from '../../../Media/Images/Icons/qr_code.webp';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import userIcon from '../../../Media/Images/Icons/user.webp';
import reservationIcon from '../../../Media/Images/Icons/reservation.webp';
import FileInput from '../../../GlobalComponents/FileInput';
import imageIcon from '../../../Media/Images/Icons/image.webp';
import Label from '../../../GlobalComponents/Label';
import SelectBox from '../../../GlobalComponents/SelectBox';
import Select from '../../../GlobalComponents/Select';
import Button from '../../../GlobalComponents/Button';
import { UseProject } from '../../../AdminProvider';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import { TINY_MCE_TOOLBAR_ADVANCED } from '../../../Config';
import { useState } from 'react';
import UseEditCustomerRoles from '../Library/UseEditCustomerRoles';
import List from '../../../GlobalComponents/List/List';
import ChooseCustomerRoles from '../../Customer/Components/ChooseCustomerRoles';
import NoItems from '../../../GlobalComponents/NoItems';
import ChoosenReservationPlace from '../../Reservation/Components/ChoosenReservationPlace';
import ChooseReservationPlaces from '../../Reservation/Components/ChooseReservationPlace';

const BasicInformations = ({
    allLanguageMutations,
    formData,
    allVats,
    allLinks,
    allCustomerRoles,
    allQuestionnaires,
    OpenImage,
    GetFormLangValue,
    SetFormLangData,
    SetFormData,
    FillFormSelectedItemsWithObj,
    RemoveFormDataSelectedItems,
    SetFormDataSelectedItemsWithObjAndIndex,
    GetFormSelectedItemsLangValue,
    SetFormSelectedItemsLangValue,
    SetFormDataSelectedItemsWithObj,
    OpenImageToArrayOfObject
}) => {

    const [showAddCustomerRoles,SetShowAddCustomerRoles] = useState(false);
    const [showAddReservationPlaces,SetShowAddReservationPlaces] = useState(false);

    const {headerData,content} = UseEditCustomerRoles(formData.selectedCustomerRoles);

    const {t} = useTranslation();
    const {IsModulEnabled} = UseProject();
    
    return(
        <>
            <InputCard
                title = {t("Display")}
                icon = {eye}
                className = {"form-group flex-1"}
            >   
                <div className="row">
                    <div className="col-25">
                        <div className="form-group">
                            
                            <Label
                                text= {t("ShowForPurchase")}
                                infoContent={[
                                    t("ShowForPurchaseInfo")
                                ]}
                            />
                            <div className="radio-container">
                                <RadioButton
                                    text={t("Yes")}
                                    name="showForPurchase"
                                    id="showForPurchase-1"
                                    value={1}
                                    checked = {formData.showForPurchase === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text={t("No")}
                                    name="showForPurchase"
                                    id="showForPurchase-2"
                                    value={0}
                                    checked = {formData.showForPurchase === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div> 
                    <div className="col-25">
                        <div className="form-group">
                            
                            <Label
                                text= {t("Recurring")}
                                infoContent={[
                                    t("RecurringInfo1"),
                                    t("RecurringInfo2")
                                ]}
                            />
                            <div className="radio-container">
                                <RadioButton
                                    text={t("Yes")}
                                    name="isRecurring"
                                    id="isRecurring-1"
                                    value={1}
                                    checked = {formData.isRecurring === 1 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                                <RadioButton
                                    text={t("No")}
                                    name="isRecurring"
                                    id="isRecurring-2"
                                    value={0}
                                    checked = {formData.isRecurring === 0 ? true : false}
                                    OnChange={(e) => SetFormData(e)}
                                />
                            </div>
                        </div>
                    </div> 
                    {IsModulEnabled(14) ?

                        <div className="col-25">
                            <div className="form-group">
                                
                                <Label
                                    text= {t("ShowInPortal")}
                                    infoContent={[
                                        t("ShowInPortalInfo")
                                    ]}
                                />
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="showInPortal"
                                        id="showInPortal-1"
                                        value={1}
                                        checked = {formData.showInPortal === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="showInPortal"
                                        id="showInPortal-2"
                                        value={0}
                                        checked = {formData.showInPortal === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div> 
                    :null}
                    
                    {IsModulEnabled(66) ?
                        <div className="col-25">
                            <div className="form-group">
                                
                                <Label
                                    text= {t("UseGuide")}
                                    infoContent={[
                                        t("UseGuideInfo1"),
                                        t("UseGuideInfo2")
                                    ]}
                                />
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="useGuide"
                                        id="useGuide-1"
                                        value={1}
                                        checked = {formData.useGuide === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="useGuide"
                                        id="useGuide-2"
                                        value={0}
                                        checked = {formData.useGuide === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div> 
                    :null}
                    
                    {IsModulEnabled(11) && formData.isRecurring == 1 ?
                        <div className="col-25">
                            <div className="form-group">
                                
                                <Label
                                    text= {t("UseFreeTrialPeriod")}
                                    infoContent={[
                                        t("UseFreeTrialPeriodInfo")
                                    ]}
                                />
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="useFreeTrialPeriod"
                                        id="useFreeTrialPeriod-1"
                                        value={1}
                                        checked = {formData.useFreeTrialPeriod === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="useFreeTrialPeriod"
                                        id="useFreeTrialPeriod-2"
                                        value={0}
                                        checked = {formData.useFreeTrialPeriod === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    :null}  
                    {IsModulEnabled(73) && formData.isRecurring == 0 ?
                        <div className="col-25">
                            <div className="form-group">
                                
                                <Label
                                    text= {t("IsTherapy")}
                                    infoContent={[
                                        t("IsTherapyInfo")
                                    ]}
                                />
                                <div className="radio-container">
                                    <RadioButton
                                        text={t("Yes")}
                                        name="isTherapy"
                                        id="isTherapy-1"
                                        value={1}
                                        checked = {formData.isTherapy === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text={t("No")}
                                        name="isTherapy"
                                        id="isTherapy-2"
                                        value={0}
                                        checked = {formData.isTherapy === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    :null}                                                                            
                </div> 
            </InputCard> 
            <div className="row">
                <div className = "col-50 d-flex">  
                    
                    <InputCard
                        title = {t("Image")}
                        icon = {imageIcon}
                        className = {"form-group flex-1"}
                    > 
                        {formData.mainPhoto ?
                            
                            <div className="open-image-container form-group">
                                <img className="no-margin" src = {formData.mainPhoto} />
                            </div>
                            
                        :null}

                        <div className="form-group">                
                            <FileInput
                                name = "photo"
                                OnChange = {(e) => OpenImage(e,"photo","mainPhoto")}
                            />
                        </div>

                    </InputCard>
                    
                </div>
                <div className = "col-50">  
                    <div className="form-group">
                        <label>*{t("Name")} ({formData.selectedLang})</label>
                        <Input
                            name="name"
                            value={GetFormLangValue("name")}
                            OnChange={(e) => SetFormLangData(e)}
                        />
                    </div>
                    <div className="form-group">
                        <Label 
                            text={t("InvoiceName") + " (" + formData.selectedLang + ")"}
                            infoContent={[
                                t("InvoiceNameInfo1"),
                                t("InvoiceNameInfo2")
                            ]}
                        />
                        <Input
                            name="invoiceName"
                            value={GetFormLangValue("invoiceName")}
                            OnChange={(e) => SetFormLangData(e)}
                        />
                    </div>
                    <div className="row">
                        {formData.isRecurring == 0 ?
                            <>
                                <div className={formData.isTherapy == 1 ? "col-100" : "col-50"}>
                                    <div className="form-group">
                                        <label>*{t("Price")} ({formData.selectedLang})</label>
                                        <Input
                                            name="price"
                                            type = "number"
                                            value={GetFormLangValue("price")}
                                            OnChange={(e) => SetFormLangData(e)}
                                        />
                                    </div>
                                </div>
                                {formData.isTherapy == 1 ?
                                    <div className={"col-50"}>
                                        <div className="form-group">
                                            <label>{t("PrepaymentPrice")} ({formData.selectedLang})</label>
                                            <Input
                                                name="prepaymentPrice"
                                                type = "number"
                                                value={GetFormLangValue("prepaymentPrice")}
                                                OnChange={(e) => SetFormLangData(e)}
                                            />
                                        </div>
                                    </div>
                                :null}
                            </>
                        :null}
                        <div className={formData.isRecurring == 0 ? "col-50" : "col-100"}>
                            <div className="form-group">
                                <label>*{t("VAT")}</label>
                                <Select 
                                    name="vatID"
                                    value = {formData.vatID}
                                    OnChange = {(e) => SetFormData(e)}
                                >
                                    {allVats && allVats.map((item) => (
                                        <option
                                            key={item.vatID}
                                            value={item.vatID}
                                        >{item.value}</option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <Label
                            text = {t("ThanksPage")}
                            infoContent={[t("MembershipThanksPageInfo")]}
                        />
                        <SelectBox 
                            value={formData.linkID}
                            OnChange={(value) => SetFormData("linkID",value)}
                            nameParam = "name"
                            valueParam = "linkID"
                            subParam = "subLink"
                            items={allLinks}
                        />
                    </div>
                    {IsModulEnabled(12) ?
                        <div className="form-group">
                            <Label
                                text = {t("Questionnaire")}
                                infoContent={[t("MembershipQuestionnaireInfo")]}
                            />
                            <Select 
                                value={formData.questionnaireID}
                                OnChange={(e) => SetFormData(e)}
                                name = "questionnaireID"
                            >
                                {allQuestionnaires && allQuestionnaires.map((item) => (
                                    <option
                                        key={item.questionnaireID}
                                        value={item.questionnaireID}
                                    >{item.internalName}</option>
                                ))}
                                
                            </Select>
                        </div>
                    :null}
                    {IsModulEnabled(14) && formData.showInPortal == 1 ?
                        <div className="form-group">
                            <Label
                                text = {"*" + t("CustomerRole")}
                                infoContent={[
                                    t("MembershipCustomerRoleInfo1"),
                                    t("MembershipCustomerRoleInfo2")
                                ]}
                            />
                            <Select 
                                value={formData.customerRoleID}
                                OnChange={(e) => SetFormData(e)}
                                name = "customerRoleID"
                            >
                                {allCustomerRoles && allCustomerRoles.map((item) => (
                                    <option
                                        key={item.customerRoleID}
                                        value={item.customerRoleID}
                                    >{item.name}</option>
                                ))}
                                
                            </Select>
                        </div>
                    :null}
                </div>
            </div>  

            {formData.isRecurring == 0 ?
                <InputCard
                    title = {t("QrCodes")}
                    icon = {qrCode}
                    className = {"form-group"}
                >   
                    <div className="row">
                       
                        <div className = "col-50 d-flex">  
                    
                            <InputCard
                                title = {t("ForPrice")}
                                icon = {imageIcon}
                                className = {"form-group flex-1"}
                            > 
                                {GetFormLangValue("priceQrCodeShow") ?
                                    
                                    <div className="open-image-container form-group">
                                        <img className="no-margin" src = {GetFormLangValue("priceQrCodeShow")} />
                                    </div>
                                    
                                :null}

                                <div className="form-group">                
                                    <FileInput
                                        name = "priceQrCode"
                                        OnChange = {(e) => OpenImageToArrayOfObject(e,"langs","priceQrCode","priceQrCodeShow","lang",formData.selectedLang)}
                                    />
                                </div>

                            </InputCard>
                            

                        </div>
                        {formData.isTherapy == 1 ? 
                            <div className = "col-50 d-flex">  
                        
                                <InputCard
                                    title = {t("ForPrepaymentPrice")}
                                    icon = {imageIcon}
                                    className = {"form-group flex-1"}
                                > 
                                    {GetFormLangValue("prepaymentPriceQrCodeShow") ?
                                        
                                        <div className="open-image-container form-group">
                                            <img className="no-margin" src = {GetFormLangValue("prepaymentPriceQrCodeShow")} />
                                        </div>
                                        
                                    :null}

                                    <div className="form-group">                
                                        <FileInput
                                            name = "prepaymentPriceQrCode"
                                            OnChange = {(e) => OpenImageToArrayOfObject(e,"langs","prepaymentPriceQrCode","prepaymentPriceQrCodeShow","lang",formData.selectedLang)}
                                        />
                                    </div>

                                </InputCard>
                            
                            </div>
                        :null}
                    </div>   
                    
                </InputCard>  
            :null}

            {IsModulEnabled(14) && formData.showInPortal == 1 ?
                <InputCard
                    title = {t("CustomerRoles")}
                    icon = {userIcon}
                    className = {"form-group"}
                    noBodyPadding={true}
                    headerInfoContent={[
                        t("MembershipCustomerRolesInfo1"),
                        t("MembershipCustomerRolesInfo2")
                    ]}
                    headerChildren = {
                        <Button 
                            OnClick = {(e) => {
                                e.stopPropagation();
                                SetShowAddCustomerRoles(true)
                            }}
                            className="btn-primary narrow"
                        >
                            {formData.selectedCustomerRoles && formData.selectedCustomerRoles.length > 0 ? 
                                t("Edit") 
                            : 
                                t("Choose")
                            }
                        </Button>
                    }
                >   

                    <List 
                        headerData = {headerData} 
                        headerClassName = {"no-top-border"}
                        contentData = {content}
                        isSortable = {false}
                        loading = {false}
                        replaceDotsImg = {cancelIcon}
                        ReplaceDotsOnClick = {(data) => SetFormDataSelectedItemsWithObj("deleted","selectedCustomerRoles","customerRoleID",data.rowID,true)}
                    />
                    
                </InputCard>  
            :null}
            
            {IsModulEnabled(68) ?
                <InputCard
                    title = {t("ReservationPlaces")}
                    icon = {reservationIcon}
                    className = {"form-group"}
                    headerInfoContent={[
                        t("MembershipReservationsInfo1"),
                        t("MembershipReservationsInfo2"),
                        t("MembershipReservationsInfo3")
                    ]}
                    headerChildren = {
                        <Button 
                            OnClick = {(e) => {
                                e.stopPropagation();
                                SetShowAddReservationPlaces(true)
                            }}
                            className="btn-primary narrow"
                        >
                            {formData.selectedReservationPlaces && formData.selectedReservationPlaces.length > 0 ? 
                                t("Edit") 
                            : 
                                t("Choose")
                            }
                        </Button>
                    }
                >   

                    {formData.selectedReservationPlaces && formData.selectedReservationPlaces.length > 0 ?
                        (formData.selectedReservationPlaces.map((item,index) => (
                            <div 
                                key={item.reservationPlaceID} 
                                className="form-group"
                            >
                                <ChoosenReservationPlace
                                    selectedLang = {formData.selectedLang}
                                    data = {item}
                                    isTherapy = {(IsModulEnabled(73) && formData.isTherapy == 1 ? true : false)}
                                    SetParentFormData = {(obj) => {
                                        SetFormDataSelectedItemsWithObjAndIndex("selectedReservationPlaces",obj,index)
                                    }}
                                    RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                    GetFormSelectedItemsLangValue = {GetFormSelectedItemsLangValue}
                                    SetFormSelectedItemsLangValue = {SetFormSelectedItemsLangValue}
                                />
                            </div>
                        )))
                    :
                        <NoItems text={t("NoItems")} />
                    }
                    
                </InputCard>  
            :null}
            <div>
                <label>{t("Description")} ({formData.selectedLang})</label>
                <TinyMCEEditor 
                    value = {GetFormLangValue("description")}
                    OnEditorChange={(content) => SetFormLangData("description",content)}
                    toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                    useMediaEditor={false}
                />
            </div>

            {showAddCustomerRoles ? 
                <ChooseCustomerRoles 
                    formData = {formData}
                    SetShowAddCustomerRoles = {SetShowAddCustomerRoles}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}

            {showAddReservationPlaces ? 
                <ChooseReservationPlaces
                    formData = {formData}
                    allLanguageMutations = {allLanguageMutations}
                    SetShowAddReservationPlaces = {SetShowAddReservationPlaces}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
                                    
        </>
    )
}

export default BasicInformations;