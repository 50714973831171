import ContentHeader from "../../../GlobalComponents/ContentHeader";
import { useTranslation } from 'react-i18next';
import List from "../../../GlobalComponents/List/List";
import { useState } from "react";
import AddEditReservation from "./AddEditReservation";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import UseGetAllReservations from "../Library/UseGetAllReservations";
import UseDeleteReservations from "../Library/UseDeleteReservations";
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import ContentFilterSidebar from "../../../GlobalComponents/ContentFilterSidebar";
import ReservationFilter from "./ReservationFilter";
import { UseAuth } from "../../Auth/Library/UseAuth";
import UseConfirmReservationPayment from "../Library/UseConfirmReservationPayment";

const Reservations = () => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    const [selectedReservationID, SetSelectedReservationID] = useState(null);
    const [showFilterSidebar,SetShowFilterSidebar] = useState(false);

    const {
        content,
        loading,
        error,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        headerData,
        selectedReservationIDs,
        SelectRow,
        SelectAll,
        FetchMore,
        GoToPage,
        FilterReservations,
        UpdateList
    } = UseGetAllReservations(user.lang);

    const {
        loading:deleteLoading,
        showDeleteNotification, 
        ShowDeleteNotification,
        DeleteReservations
    } = UseDeleteReservations(selectedReservationIDs,() => UpdateList());

    const {
        loading:confirmLoading,
        showConfirmPaymentNotifi,
        confirmPaymentNotifiText,
        ShowConfirmPayment,
        ConfirmPayment
    } = UseConfirmReservationPayment(content,() => UpdateList());

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("ReservationList")}
                        OnAddButtonClick={() => SetSelectedReservationID(0)}
                        OnDeleteButtonClick={() => ShowDeleteNotification(true)}
                        //OnFilterClick = {() => SetShowFilterSidebar(true)}
                        OnSearch = {(filterData) => FilterReservations(filterData)}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        totalContentLength = {totalContentLength}
                        paggingOffset = {paggingOffset}
                        paggingLimit = {paggingLimit}
                        FetchMore = {FetchMore}
                        GoToPage = {GoToPage}
                        OnSelectAll = {(e) => SelectAll(e)}
                        options = {{
                            OnEditClick: (reservationID) => SetSelectedReservationID(reservationID),
                            OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                            OnConfirmClick: (reservationID) => ShowConfirmPayment(reservationID),
                        }}
                    />
                    <ContentFilterSidebar
                        isOpen = {showFilterSidebar}
                        OnClose = {() => SetShowFilterSidebar(false)}
                    >
                        <ReservationFilter
                            OnFilter = {(filterData) => FilterReservations(filterData)}
                        />
                    </ContentFilterSidebar>
                </>
            }

            {selectedReservationID || selectedReservationID == 0 ?
                <AddEditReservation
                    selectedReservationID = {selectedReservationID}
                    SetSelectedReservationID = {SetSelectedReservationID}
                    OnAddEditSuccess = {(d) => {
                        UpdateList();
                        SetSelectedReservationID(null);
                    }}
                />
            :null}

            {showDeleteNotification ?
                <ModalNotification 
                    loading={deleteLoading}
                    yesNo={true} 
                    text={t("DoYouReallyWantToDeleteReservations")} 
                    CallBack={DeleteReservations} 
                />
            :null}

            {showConfirmPaymentNotifi ?
                <ModalNotification 
                    loading = {confirmLoading}
                    yesNo={true} 
                    text={confirmPaymentNotifiText} 
                    CallBack={ConfirmPayment} 
                />
            :null}
        </>
    )
}

export default Reservations;