import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { CompleteLangsArray } from '../../../GlobalFunctions/helper';

export const GetQuestionnaireStepObj = (data) => {

    var langs = [];
    var items = [];

    for(let l of data.langs)
    {
        langs.push({
            lang:l.lang,
            question:l.question
        })
    }

    if(data.items && data.items.length > 0)
    for(let item of data.items)
    {
        items.push(GetQuestionnaireStepItemObj(item))
    }

    return{
        questionnaireStepID: (data.questionnaireStepID ? data.questionnaireStepID : 0),
        questionTypeID: (data.questionTypeID ? data.questionTypeID : 1),
        deleted: (data.deleted ? true : false),
        added: (data.added ? true : false),
        items: items,
        langs: langs
    }
}

export const GetQuestionnaireStepItemObj = (data) => {

    var langs = [];

    for(let l of data.langs)
    {
        langs.push({
            lang:l.lang,
            text:l.text
        })
    }

    return{
        questionnaireStepItemID: (data.questionnaireStepItemID ? data.questionnaireStepItemID : 0),
        deleted: (data.deleted ? true : false),
        added: (data.added ? true : false),
        langs: langs
    }
}

const UseEditQuestionnaire = (
    questionnaireSteps,
    allQuestionTypes,
    allLanguageMutations,
    selectedLang,
    SetFormData
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(questionnaireSteps)
        {
            const contentData = GetContentData(questionnaireSteps,-1);
            SetContent(contentData); 
        }

    },[questionnaireSteps,allQuestionTypes,selectedLang])

    var headerData = [
        {
            value: t("Question"),
            className:"flex-1"
        },
        {
            value: t("QuestionType"),
            className:"static text-center"
        }        
    ];

    const GetContentData = (d,parentIndex) => {

        var contentData = [];

        for(let i = 0; i < d.length; i++)
        {
            var data = [
               
                {
                    elm: "input",
                    name:(parentIndex == -1 ? "question" : "text"),
                    placeholder: (parentIndex == -1 ? t("Question") : t("Answer")),
                    param:"stepText",
                    OnChange: (e) => SetLangValue(e,parentIndex,i),
                    className:"flex-1"
                },                
            ];

            if(parentIndex == -1)
                data.push({
                    elm: "select",
                    name:"questionTypeID",
                    value:d[i].questionTypeID,
                    OnChange: (e) => SetQuestionType(e,i),
                    options:allQuestionTypes,
                    optionsParamValue:"questionTypeID",
                    optionsParamName:"type",
                    translateOptionsName:true,
                    className:"static"
                })

            var cData = {data}
            cData.selected = false;
            cData.deleted = d[i].deleted ? true : false;

            if(parentIndex == -1)
            {
                cData.rowID = d[i].questionnaireStepID;
                cData.stepText = GetLangValue(parentIndex,i,"question");
            }
            else
            {
                cData.rowID = d[i].questionnaireStepItemID;
                cData.stepText = GetLangValue(parentIndex,i,"text");
            }

            cData.parentID = parentIndex;
            
            if(d[i].items && d[i].questionTypeID != 1)
                cData.subData = GetContentData(d[i].items,i);

            contentData.push(cData)
        }
        return contentData;
    }

    const SetLangValue = (e,parentIndex,index) => {

        var name = e.target.name;
        var value = e.target.value;

        var arr = [...questionnaireSteps];
        if(parentIndex != -1)
        {
            var subArr = [...arr[parentIndex].items];
            var langs = [...arr[parentIndex].items[index].langs];

            for(let i in langs)
            {
                if(langs[i].lang == selectedLang)
                    langs[i] = {...langs[i],[name]:value}
            }

            subArr[index] = {...subArr[index],langs:langs}
            arr[parentIndex] = {...arr[parentIndex],items:subArr};
        }
        else
        {
            var langs = [...arr[index].langs];
            for(let i in langs)
            {
                if(langs[i].lang == selectedLang)
                    langs[i] = {...langs[i],[name]:value}
            }

            arr[index] = {...arr[index],langs:langs}
        }

        SetFormData("questionnaireSteps",arr);
    }

    const GetLangValue = (parentIndex,index,param) => {

        var langs = [];
        if(parentIndex == -1)
            langs = questionnaireSteps[index].langs;
        else
            langs = questionnaireSteps[parentIndex].items[index].langs;

        for(let val of langs)
        {
            if(val.lang == selectedLang)
                return val[param];
        }
    }

    const SetQuestionType = (e,index) => {

        var type = e.target.value;

        if(type == 2 || type == 3)
        {

            if(questionnaireSteps[index] && questionnaireSteps[index].items && questionnaireSteps[index].items.length == 0)
                AddQuestionnaireItem(2,index,type);
            else
            {
                var arr = [...questionnaireSteps];
                arr[index] = {...arr[index],questionTypeID:type};
                SetFormData("questionnaireSteps",arr);
            }

        }
        else
        {
            var arr = [...questionnaireSteps];
            arr[index] = {...questionnaireSteps[index],questionTypeID:type}

            SetFormData("questionnaireSteps",arr);
        }
    }

    const AddQuestionnaireItem = (level,index,questionTypeID) => {

        if(level == 1)
        {
            const emptyLang = {
                question:""
            };

            let langs = CompleteLangsArray([],allLanguageMutations,emptyLang);
            var newStep = {...GetQuestionnaireStepObj({
                questionTypeID:questionTypeID,
                langs:langs,
                added:true
            })};
            
            var arr = [...questionnaireSteps,newStep];

            SetFormData("questionnaireSteps",arr);
        }
        else
        {
            const emptyLang = {
                text:""
            };

            let langs = CompleteLangsArray([],allLanguageMutations,emptyLang);
            var newStepItem = {...GetQuestionnaireStepItemObj({
                langs:langs,
                added:true
            })};

            var items = [...questionnaireSteps[index].items];
            items.push(newStepItem);

            var arr = [...questionnaireSteps];

            if(items.length == 1)
                arr[index] = {...arr[index],items:items,questionTypeID};
            else
                arr[index] = {...arr[index],items:items};

            SetFormData("questionnaireSteps",arr);
        }
    }

    const RemoveQuestionnaireStep = (parentIndex,index) => {

        var arr = [...questionnaireSteps];

        if(parentIndex != -1)
        {
            var subArr = [...arr[parentIndex].items];
            subArr[index] = {...subArr[index],deleted:true}

            arr[parentIndex] = {...arr[parentIndex],items:subArr};
        }
        else
            arr[index] = {...arr[index],deleted:true}

        SetFormData("questionnaireSteps",arr);
    }

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {
            const IDs = result.draggableId.split("-");
            const startIndex = result.source.index;
            const endIndex = result.destination.index;
            
            var arr = [...questionnaireSteps];

            if(IDs[2])
                arr = Reorder(arr,startIndex, endIndex);
            else
            {
                for(let i = 0; i < arr.length; i++)
                {
                    if(IDs[1] == i)
                    {
                        var newSubData = Reorder(arr[i].items,startIndex, endIndex); 
                        arr[i] = {...arr[i],items:newSubData}
                    }
                }
            }
            
            SetFormData("questionnaireSteps",arr);
        }
    } 

    const Reorder = (list, startIndex, endIndex) => {
        
        var newSubData = [...list];
    
        const [removed] = newSubData.splice(startIndex, 1);
        newSubData.splice(endIndex, 0, removed);
      
        return newSubData;
    };

    return{
        content,
        headerData,
        AddQuestionnaireItem,
        RemoveQuestionnaireStep,
        OnDragEnd
    }

}

export default UseEditQuestionnaire;