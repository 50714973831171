import { UseContentManager } from "../../../Library/UseContentManager";
import ControlsMenu from "../../ControlsMenu";
import { GetElementMargins } from "../../../Library/functions";
import reviewIcon from '../../../../../Media/Images/Icons/review.webp';
import { useTranslation } from "react-i18next";
import Review3dCarousel from "./Review3dCarousel";
import { GET_SELECTED_REVIEWS } from "../../../Queries/contentManager";
import { useApolloClient } from "@apollo/client";
import { useState,useEffect } from "react";
import { REVIEW_IMAGE_URL, SERVER_URL } from "../../../../../Config";
import Quotes from "../../../../../GlobalComponents/Quotes";

const Reviews = ({data,lastIndexes,elmIndex,elementsCount}) => {

    const {t} = useTranslation();
    const client = useApolloClient();
    const {formData,showHelpers,projectData,indexToShowMenu,SelectElement} = UseContentManager();
    const [reviewsList, SetReviewList] = useState([]);

    useEffect(() => {
        if(data.selectedReviews.length > 0)
            GetReviews();
    },[data.selectedReviews])

    var showMenu = false;
    if(indexToShowMenu === lastIndexes)
        showMenu = true;

    var margins = GetElementMargins(data);
    const borderRadiusStyle = {borderRadius:data.borderRadius + "px"};

    const GetReviews = async() => {
        var reviewListData = await client.query({
            query: GET_SELECTED_REVIEWS,
            variables:{
                lang:formData.selectedLang,
                reviewIDs: data.selectedReviews
            },
            fetchPolicy:"network-only"
        });

        var items = [];
        for(let id of data.selectedReviews)
        {
            for(let val of reviewListData.data.AllReviewsByReviewIDs)
            {
                if(id == val.reviewID)
                {
                    var item = {...val};
                    if(item.photo)
                        item.photo = SERVER_URL + "/" + REVIEW_IMAGE_URL + "/stredni_" + item.photo;

                    items.push(item);
                }
            }
        }

        SetReviewList(items);
    }
        
    return(
        <div className="cm-element-content">

            {showHelpers && showMenu ?
                <ControlsMenu 
                    element = "reviews"
                    index = {lastIndexes} 
                    elementsCount = {elementsCount}
                    position = {elmIndex + 1}
                />
            :null}

            <div className={margins.styleClassName + " " + data.className} style={margins.styles}>
                
                {reviewsList.length == 0 ?
                    <div className="cm-start-info big">
                        <img src={reviewIcon} alt="" />
                        <h2 className="cm-no-bottom-margin">{t("Reviews")}</h2>
                        {t("HereWillBeSelectedReviews")}
                    </div>
                :
                    <div className="cm-review-element">
                        {reviewsList.length == 1 ?

                            <div className={"review"}>
                                <div 
                                    className="content"
                                    style = {borderRadiusStyle}
                                >
                                    <div dangerouslySetInnerHTML={{__html:reviewsList[0].text}}></div>
                                    {data.withoutQuotationMarks == 0 ?
                                        <>
                                            <Quotes 
                                                color = {projectData.buttonBgColor}
                                                className="quotes" 
                                            />
                                            <Quotes 
                                                color = {projectData.buttonBgColor}
                                                className="quotes bottom" 
                                            />
                                        </>
                                    :null}
                                </div>
                                <div className="signature" dangerouslySetInnerHTML={{__html:reviewsList[0].signature}}></div>

                                
                            </div>
                        :
                            <Review3dCarousel 
                                color = {projectData.buttonBgColor}
                                borderRadiusStyle = {borderRadiusStyle}
                                key={reviewsList.length} 
                                data = {reviewsList} 
                            />
                        }
                    </div>
                }
                
            </div>
            {showHelpers ?
                <div 
                    className={"cm-col-border" + (showMenu ? " cm-selected" : "")}
                    onClick={(e) => SelectElement(e,lastIndexes)}
                ></div>
            :null}
        </div>
    )
}

export default Reviews;