import Modal from 'react-modal';
import ModalHeader from './Modal/ModalHeader';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import Loading from './Loading';

Modal.setAppElement('#root');

const ModalNotification = ({children,text,yesNo,biggerNo,loading,CallBack}) => {

	const {t} = useTranslation();
	
    return (
    	<Modal
	        closeTimeoutMS={150}
			isOpen={true}
            onRequestClose={() => CallBack(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
	    >
			<ModalHeader 
                title = {t("Warning")}
                OnClose ={() => CallBack(false)}
            />

			<div className="modal-body">
			
				{loading ?
					<Loading />
				:
					<>
						<div className={(children ? "form-group" : "")}>
							{yesNo ? 
								<p className="no-margin">{text}</p> 
							: 
								<div className="alert alert-danger">{text}</div>
							}
						</div>
						{children}
					</>
				}	
					
			</div>
			{yesNo ? 
				<div className="modal-footer"> 
					{!loading ?
						(biggerNo ?
							<div className="row">
								<div className="col-75">
									<Button className="btn-brown w-100" OnClick={() => CallBack(false)}>
										{t("NO")}
									</Button>
								</div>
								<div className="col-25">
									<Button className="btn-primary w-100" OnClick={() => CallBack(true)}>
										{t("YES")}
									</Button>
								</div>
							</div>
						:
							<div className="row">
								<div className="col-75">
									<Button className="btn-primary w-100" OnClick={() => CallBack(true)}>
										{t("YES")}
									</Button>
								</div>
								<div className="col-25">
									<Button className="btn-brown w-100" OnClick={() => CallBack(false)}>
										{t("NO")}
									</Button>
								</div>
							</div>
						)
					:null}
				</div>
			:
				<div className="modal-footer"> 
					{!loading ?
						<Button className="btn-danger" onClick={() => CallBack(false)}>
							{t("Close")}
						</Button>
					:null}
				</div>
			}
	       
	    </Modal>
    );
}


export default ModalNotification;
