import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_BILLING_INFORMATION } from "../Queries/billingInformation";

const UseAddEditBillingInformation = (selectedBillingInformationID,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const {formData, SetFormData, SetCompleteFormData} = UseFormHandle({
        company:"",
        street1:"",
        street2:"",
        city:"",
        zip:"",
        state:"",
        countryID:0,
        idNumber:"",
        vatNumber:"",
        bankAccountNumber:"",
        bankAccountCountryID:0,
        iban:"",
        swift:"",
        email:""
    });

    const [AddEditBillingInformationMutation,{error,loading}] = useMutation(ADD_EDIT_BILLING_INFORMATION,{
        onCompleted:(d) => {

            if(OnCompleted)
                OnCompleted(d.AddEditBillingInformation);

            const notifyText = (selectedBillingInformationID != 0 ? t("SuccessfullyUpdated") : t("NewBillingInformationIsInTheWorld"));

            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditBillingInformation = () => {

        if(formData.company)
        {   
            if(formData.street1)
            {   
                if(formData.zip)
                {   
                    if(formData.city)
                    {   
                        if(formData.countryID)
                        {   
                            AddEditBillingInformationMutation({
                                variables:{
                                    billingInformationID:        selectedBillingInformationID,
                                    company:                     formData.company,       
                                    street1:                     formData.street1,
                                    street2:                     (formData.countryID == 236 || formData.countryID == 237 ? formData.street2 : ""),
                                    city:                        formData.city,
                                    zip:                         formData.zip,
                                    state:                       (formData.countryID == 209 || formData.countryID == 237 ? formData.state : ""),
                                    countryID:                   formData.countryID,
                                    idNumber:                    formData.idNumber,
                                    vatNumber:                   formData.vatNumber,
                                    bankAccountNumber:           formData.bankAccountNumber,
                                    bankAccountCountryID:        formData.bankAccountCountryID,
                                    iban:                        formData.iban,
                                    swift:                       formData.swift,
                                    email:                       formData.email
                                }
                            })  
                        }
                        else
                            AddNotification(t("CountryIsImportant"),false);
                    }
                    else
                        AddNotification(t("CityIsInvalid"),false);
                }
                else
                    AddNotification(t("ZipIsInvalid"),false);
            }
            else
                AddNotification(t("StreetIsInvalid"),false);
        }
        else
            AddNotification(t("NoCompanyNoGo"),false);
        
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error,
        loading: loading,
        formData,
        AddEditBillingInformation,
        SetFormData,
        InitFormData
    }

}

export default UseAddEditBillingInformation;