import InputCard from "../../../GlobalComponents/InputCard";
import Button from "../../../GlobalComponents/Button";
import List from "../../../GlobalComponents/List/List";
import { useTranslation } from "react-i18next";
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import UseEditProjectPortalModules from "../Library/UseEditProjectPortalMenu";
import { useState } from "react";
import ChoosePortalModules from "../../Portal/Components/ChoosePortalModule";

const PortalMenu = ({
    allLanguageMutations,
    formData,
    SetFormSelectedItemsLangValue,
    FillFormSelectedItemsWithObj,
    GetFormSelectedItemsLangValue,
    SetFormDataSelectedItemsWithObj,
    SetFormData
}) => {

    const {t} = useTranslation();
    const {headerData,content,OnDragEnd} = UseEditProjectPortalModules(
        formData.selectedLang,
        formData.selectedPortalModules,
        SetFormData,
        SetFormSelectedItemsLangValue,
        GetFormSelectedItemsLangValue
    );

    const [showAddPortalModules,SetShowAddPortalModules] = useState(false);

    return(
        <InputCard
            title={t("PortalMenu")}
            noBodyPadding={true}
            headerInfoContent={[
                t("PortalMenuInfo1"),
                t("PortalMenuInfo2")
            ]}
            headerChildren = {
                <Button 
                    OnClick = {(e) => {
                        e.stopPropagation();
                        SetShowAddPortalModules(true)
                    }}
                    className="btn-primary narrow"
                >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>
            }
        >
            <List 
                headerData = {headerData} 
                headerClassName = {"no-top-border"}
                contentData = {content}
                isSortable = {true}
                loading = {false}
                OnDragListRowEnd={(e) => OnDragEnd(e)}
                replaceDotsImg = {cancelIcon}
                ReplaceDotsOnClick = {(data) => SetFormDataSelectedItemsWithObj("deleted","selectedPortalModules","portalModuleID",data.rowID,true)}
            />

            {showAddPortalModules ?
                <ChoosePortalModules 
                    allLanguageMutations = {allLanguageMutations}
                    formData = {formData}
                    SetShowAddPortalModules = {SetShowAddPortalModules}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}

        </InputCard>
    )
}

export default PortalMenu;