import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Loading from '../../../GlobalComponents/Loading';
import { UseAuth } from '../../Auth/Library/UseAuth';
import List from '../../../GlobalComponents/List/List';
import UseChooseMemberships from '../Library/UseChooseMemberships';

const ChooseMemberships = ({
    noReccuringNoReservations,
    selectedParam,
    formData,
    projectID,
    SetShowAddMemberships,
    FillFormSelectedItemsWithObj
}) => {

    const {t} = useTranslation();
    const {user} = UseAuth();

    var lang = user ? user.lang : "";

    const {
        content,
        headerData,
        loading,
        error,
        SelectRow,
    } = UseChooseMemberships(
        lang,
        projectID,
        (noReccuringNoReservations ? formData.selectedServices : formData.selectedMemberships),
        FillFormSelectedItemsWithObj,
        noReccuringNoReservations,
        selectedParam
    );

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddMemberships(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("ChooseForms")}
                OnClose ={() => SetShowAddMemberships(false)}
            />

            <div className="modal-body no-padding">

                {error ?
                    <Error text={GetApolloErrorText(error)} />
                :
                    (loading ?
                        <Loading />
                    :
                        <List 
                            headerData = {headerData} 
                            contentData = {content}
                            isSortable = {false}
                            loading = {loading}
                            hideOptions = {true}
                            options = {{
                                OnSelect: (e,rowData) => SelectRow(e,rowData),
                            }}
                        />
                    )
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddMemberships(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )

}

export default ChooseMemberships;