import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PROJECT_IMAGE_URL, SERVER_URL } from "../../../../Config";

const UseEditCategories = (
    selectedCategories,
    SetBooleanFormDataSelectedItemsWithObjToArray,
    SetFormDataSelectedItemsWithObjToArray,
    hideMainColumin,
    selectedProjectID
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);

    useEffect(() => {

        if(selectedCategories)
        {
            const contentData = GetContentData(selectedCategories);
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[selectedCategories])

    var headerData = [
        {
            value: t("Project"),
            className:"static smaller text-center"
        },
        {
            value: t("CategoryName"),
            className:"flex-1"
        }
    ];

    if(!hideMainColumin)
        headerData.unshift({
            value: t("Main"),
            className:"static smaller"
        })
    

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            if(val.deleted == undefined || val.deleted == false)
            {
                var cData = {
                    data:[
                        {
                            value: (val.project && val.project.favicon ? 
                                <img 
                                    className="cursor-help"
                                    title={val.project.name} 
                                    src={SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + val.project.favicon} 
                                /> : ""),
                            className:"static smaller text-center"
                        },
                        {
                            value:val.name,
                            className:"flex-1"
                        }
                    ],
                    
                }

                if(!hideMainColumin)
                    cData.data.unshift({
                        elm:"radio",
                        param:"isMain",
                        name:"isMain",
                        OnChange: (e) => {
                            SetBooleanFormDataSelectedItemsWithObjToArray("isMain","selectedCategories","linkID",val.linkID,selectedProjectID);
                            SetFormDataSelectedItemsWithObjToArray("updated","selectedCategories","linkID",val.linkID,true,selectedProjectID);
                        },
                        className:"static smaller"
                    })


                cData["isMain"]         = val.isMain;
                cData.rowID             = val.linkID;
                cData.selected          = false;
                cData.selectedProjectID = selectedProjectID

                contentData.push(cData)
            }
        }
        return contentData;
    }

    return{
        headerData,
        content
    }

}

export default UseEditCategories;