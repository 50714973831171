import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import ImageGalleryResponziveSettings from "./ImageGalleryResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { IMAGE_GALLERY } from "../../../Library/elements";
import Select from "../../../../../GlobalComponents/Select";

const ImageGallerySettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle(IMAGE_GALLERY.imageGallery); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection,
        SetOpenFileManagerData
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"imageGallery");
            SetCompleteFormData(settings);
        }

    },[showSettingsElement])

    const SetS = (e,val) => {
        SetFormData(e,val);
        SetSettings(showSettingsElement.index,e,val,"imageGallery");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"imageGallery");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <ImageGalleryResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <ImageGalleryResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <ImageGalleryResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <ImageGalleryResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <ImageGalleryResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <ImageGalleryResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="form-group">

                <Button 
                    className="btn-primary w-100"
                    OnClick = {() => SetOpenFileManagerData({
                        element:"imageGallery",
                        type:1,
                        index:showSettingsElement.index,
                        selectMultiple:true,
                        CallBack: (fileName) => SetS("fileName",fileName)
                    })}
                >
                    {formData.images && formData.images.length > 0 ?
                        t("AddPhotos")
                        :
                        t("ChoosePhotos")
                    }
                </Button>

            </div>
            <div className = "row">
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("ImageSize")}</label>
                        <Select
                            name = {"imageSize"}
                            OnChange = {(e) => SetS(e)}
                            value = {formData.imageSize}
                        >
                            <option value={"mala"}>{t("Small")}</option>
                            <option value={"stredni"}>{t("Middle")}</option>
                            <option value={"velka"}>{t("Big")}</option>
                            <option value={"maxi"}>{t("Max")}</option>
                        </Select>
                    </div>
                </div>
                
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Radius")}</label>
                        <Input 
                            type = "number"
                            name = "borderRadius"
                            value = {formData.borderRadius}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>

                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default ImageGallerySettings;