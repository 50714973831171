const Exchange = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <g>
                            <path 
                                style={{"fill":primaryColor}} 
                                d="M421,512H91c-50.178,0-91-40.822-91-91V91C0,40.822,40.822,0,91,0h330c50.178,0,91,40.822,91,91v330
                                C512,471.178,471.178,512,421,512z M91,22c-38.047,0-69,30.953-69,69v330c0,38.047,30.953,69,69,69h330c38.047,0,69-30.953,69-69
                                V91c0-38.047-30.953-69-69-69H91z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path 
                                style={{"fill":primaryColor}} 
                                d="M341.744,429.477c-1.642,0-3.292-0.367-4.823-1.114c-3.778-1.845-6.175-5.682-6.175-9.886v-26.402
                                H199.454c-31.145,0-60.488-12.191-82.625-34.328c-22.137-22.138-34.328-51.48-34.328-82.625c0-6.075,4.925-11,11-11h45
                                c6.075,0,11,4.925,11,11c0,13.253,5.221,25.772,14.701,35.252c9.48,9.48,22,14.701,35.251,14.701h131.292V298.67
                                c0-4.205,2.397-8.041,6.175-9.886c3.778-1.844,8.278-1.372,11.593,1.214l76.753,59.903c2.671,2.085,4.232,5.284,4.232,8.672
                                s-1.561,6.587-4.232,8.672l-76.753,59.903C346.542,428.687,344.152,429.477,341.744,429.477z M105.133,286.121
                                c2.443,21.122,11.906,40.722,27.252,56.068c17.981,17.981,41.8,27.885,67.068,27.885h142.292c6.075,0,11,4.925,11,11v14.864
                                l47.875-37.365l-47.875-37.364v14.865c0,6.075-4.925,11-11,11H199.454c-19.128,0-37.172-7.509-50.808-21.145
                                c-10.987-10.986-17.996-24.837-20.305-39.809H105.133z"/>
                        </g>
                        <g>
                            <path 
                                style={{"fill":secondaryColor}} 
                                d="M418.499,247.88h-45c-6.075,0-11-4.925-11-11c0-13.253-5.221-25.772-14.701-35.252
                                c-9.479-9.48-21.999-14.701-35.251-14.701H181.254v26.403c0,4.204-2.397,8.041-6.175,9.886c-3.78,1.842-8.279,1.372-11.592-1.214
                                L86.733,162.1c-2.671-2.085-4.232-5.284-4.232-8.672s1.561-6.587,4.232-8.672l76.753-59.903
                                c3.315-2.586,7.813-3.057,11.593-1.214c3.778,1.845,6.175,5.682,6.175,9.886v26.402h131.292
                                c31.145,0,60.488,12.191,82.625,34.328c22.136,22.136,34.328,51.479,34.328,82.625
                                C429.499,242.955,424.574,247.88,418.499,247.88z M383.659,225.88h23.208c-2.444-21.123-11.906-40.723-27.252-56.068
                                c-17.981-17.981-41.8-27.885-67.069-27.885H170.255c-6.075,0-11-4.925-11-11v-14.864l-47.875,37.365l47.875,37.364v-14.865
                                c0-6.075,4.925-11,11-11h142.292c19.129,0,37.173,7.51,50.808,21.145C374.341,197.058,381.35,210.908,383.659,225.88z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Exchange;
