import { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import Loading from '../../../GlobalComponents/Loading';
import List from '../../../GlobalComponents/List/List';
import UseChooseCustomers from '../Library/UseChooseCustomers';
import ModalFilterPanel from '../../../GlobalComponents/Modal/ModalFIlterPanel';
import CustomerFilter from './CustomerFilter';

const ChooseCustomers = ({
    title,
    formData,
    SetShowAddCustomers,
    FillFormSelectedItemsWithObj
}) => {

    const {t} = useTranslation();

    const [showFilter,SetShowFilter] = useState(false);

    const {
        content,
        headerData,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        SelectRow,
        FetchMore,
        GoToPage,
        FilterCustomers
    } = UseChooseCustomers(formData.selectedCustomers,FillFormSelectedItemsWithObj);

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddCustomers(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {title}
                OnClose ={() => SetShowAddCustomers(false)}
                children={
                    <Button 
                        OnClick = {() => SetShowFilter(!showFilter)}
                        className="btn-blue"
                    >{t("Filter")}</Button>
                }
            />

            <div className="modal-body no-padding">

                {error ?
                    <Error text={GetApolloErrorText(error)} />
                :
                    (loading ?
                        <Loading />
                    :
                        <>
                            {showFilter ?
                                <ModalFilterPanel
                                    OnClose = {() => SetShowFilter(false)}
                                >
                                    <CustomerFilter
                                        OnFilter = {(filterData) => FilterCustomers(filterData)}
                                    />
                                </ModalFilterPanel>
                            :null}
                            <List 
                                headerData = {headerData} 
                                contentData = {content}
                                isSortable = {false}
                                loading = {loading}
                                hideOptions = {true}
                                totalContentLength = {totalContentLength}
                                paggingOffset = {paggingOffset}
                                paggingLimit = {paggingLimit}
                                FetchMore = {FetchMore}
                                GoToPage = {GoToPage}
                                options = {{
                                    OnSelect: (e,rowData) => SelectRow(e,rowData),
                                }}
                            />
                        </>
                    )
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddCustomers(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )

}

export default ChooseCustomers;