import { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import Input from '../../../GlobalComponents/Input';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import seo from '../../../Media/Images/Icons/seo.webp';
import FileInput from '../../../GlobalComponents/FileInput';
import UseAddEditLink from '../Library/UseAddEditLink';
import UseGetLink from '../Library/UseGetLink';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import InputCard from '../../../GlobalComponents/InputCard';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseAuth } from '../../Auth/Library/UseAuth';
import { useApolloClient } from '@apollo/client';
import { SERVER_URL, LINK_IMAGE_URL } from '../../../Config';
import LinkForm from './LinkForm';
import CategoryForm from './CategoryForm';
import LinkProducts from './LinkProducts';
import MembershipOrder from './MembershipOrder';
import MembershipTexts from './MembershipTexts';

Modal.setAppElement('#root');

const AddEditLink = ({
    selectedLinkID,
    SetSelectedLinkID,
    isEshopCategory,
    projectID
}) => {

    const client = useApolloClient();
    const {user} = UseAuth();
    const {t} = useTranslation();

    const [selectedTab, SetSelectedTab] = useState(1);

    const {
        loading,
        error,
        formData,
        allLinks,
        AddEditLink,
        InitFormData,
        SetFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenLangImage,
        OpenImage,
        RemoveFormDataSelectedItems,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObj
    } = UseAddEditLink(selectedLinkID,user.lang,isEshopCategory,(d) => OnAddEditSuccess(d));

    const {
        allLanguageMutations,
        allParameters, 
        allProjects,
        allProjectLinks,
        loading:getLoading,
        error:getError
    } = UseGetLink(selectedLinkID,user.lang,isEshopCategory,projectID,(d) => {
        
        var initData = {...d,mainPhoto : d.mainPhoto ? SERVER_URL + "/" + LINK_IMAGE_URL + "/stredni_" + d.mainPhoto : ""};

        InitFormData(initData);

    });

    const OnAddEditSuccess = async (d) => {
        SetSelectedLinkID(null);
        await client.refetchQueries({
            include: ["AllLinks"],
        });
    }

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedLinkID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedLinkID === 0 ? 
                    (isEshopCategory ? t("AddCategory") : t("AddLink")) 
                : 
                    (isEshopCategory ? t("EditCategory") : t("EditLink"))
                )}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedLinkID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
            />

            <div className="modal-body">

                <ul className="tabs">
                    <li 
                        className={(selectedTab == 1 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(1)}
                    >{t("BasicInformations")}</li>

                    {isEshopCategory ?
                            <li 
                                className={(selectedTab == 2 ? "selected" : "")} 
                                onClick = {() => SetSelectedTab(2)}
                            >{t("Products")}</li>
                    :
                        <>
                            <li 
                                className={(selectedTab == 2 ? "selected" : "")} 
                                onClick = {() => SetSelectedTab(2)}
                            >{t("MembershipOrder")}</li>
                            <li 
                                className={(selectedTab == 3 ? "selected" : "")} 
                                onClick = {() => SetSelectedTab(3)}
                            >{t("MembershipTexts")}</li>
                        </>
                    }
                </ul>

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <Loading />
                        : 
                            <>
                                {selectedTab == 1 ?
                                    <>
                                        {isEshopCategory ?
                                            <CategoryForm 
                                                GetFormLangValue = {GetFormLangValue}
                                                SetFormLangData = {SetFormLangData}
                                                SetFormData = {SetFormData}
                                                formData = {formData} 
                                                allLinks = {allLinks}
                                                allProjects = {allProjects}
                                                allParameters = {allParameters}
                                                OpenImage = {OpenImage}
                                                RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                                FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                            />
                                            :
                                            <LinkForm 
                                                GetFormLangValue = {GetFormLangValue}
                                                SetFormLangData = {SetFormLangData}
                                                SetFormData = {SetFormData}
                                                formData = {formData} 
                                                allLinks = {allLinks}
                                            />
                                        }

                                        <InputCard
                                            title = {t("SEO")}
                                            icon = {seo}
                                        >   
                                            <div className="row">
                                                <div className="col-50">
                            
                                                    <div className="form-group">
                                                        <label>{t("MetaTitle")} ({formData.selectedLang})</label>
                                                        <Input
                                                            name="metaTitle"
                                                            value={GetFormLangValue("metaTitle")}
                                                            OnChange={(e) => SetFormLangData(e)}
                                                        />
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-50">
                                                    <div className="form-group">
                                                        <label>{t("MetaKeys")} ({formData.selectedLang})</label>
                                                        <Input
                                                            name="metaKeywords"
                                                            value={GetFormLangValue("metaKeywords")}
                                                            OnChange={(e) => SetFormLangData(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-100">
                                                    <div className="form-group">
                                                        <label>{t("MetaDescription")} ({formData.selectedLang})</label>
                                                        <Input
                                                            name="metaDescription"
                                                            value={GetFormLangValue("metaDescription")}
                                                            OnChange={(e) => SetFormLangData(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-100">
                                                    <div className="form-group">
                                                        <label>{t("MetaImage")} ({formData.selectedLang})</label>
                                                        <FileInput
                                                            name = "metaImage"
                                                            OnChange = {(e) => OpenLangImage(e)}
                                                        />
                                                        <div className="open-image-container">
                                                            {GetFormLangValue("metaImageShow") ?
                                                                <img src={GetFormLangValue("metaImageShow")} />
                                                            :null}
                                                        </div>
                                                    </div>
                                                </div>
                                                                        
                                            </div>
                                        </InputCard>
                                    </>
                                :null}
                                {selectedTab == 2 ?
                                    (isEshopCategory ?
                                        <LinkProducts
                                            lang = {user.lang}
                                            selectedLinkID = {selectedLinkID}
                                        />
                                    :
                                        <MembershipOrder 
                                            formData = {formData}
                                            allProjectLinks = {allProjectLinks}
                                            projectID = {projectID}
                                            SetFormData = {SetFormData}
                                            GetFormLangValue = {GetFormLangValue}
                                            SetFormLangData = {SetFormLangData}
                                            RemoveFormDataSelectedItems = {RemoveFormDataSelectedItems}
                                            FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                            SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                        />
                                    )
                                :null}

                                {selectedTab == 3 ?
                                    
                                    <MembershipTexts 
                                        formData = {formData}
                                        SetFormData = {SetFormData}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormLangData = {SetFormLangData}
                                    />
                                    
                                :null}

                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditLink()}
                >
                    {(selectedLinkID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditLink;