import {useQuery} from '@apollo/client';
import { GET_RESERVATION_PLACE_VARIATIONS } from '../Queries/reservationPlaceVariation';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllReservationPlaceVariations = (lang) => {

    const [variables,SetVariables] = useState({
        lang
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedReservationPlaceVariationIDs,SetSelectedReservationPlaceVariationIDs] = useState([]);

    const {data,loading,error,refetch} = useQuery(GET_RESERVATION_PLACE_VARIATIONS,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllReservationPlaceVariations);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        }       
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    }
                ],
            }

            cData.rowID    = val.reservationPlaceVariationID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,reservationPlaceVariationID) => {

        var checked = e.target.checked;
        var newSelectedReservationPlaceVariationIDs = [...selectedReservationPlaceVariationIDs];
        const newList = SelectReservationPlaceVariationID(content,reservationPlaceVariationID,checked,newSelectedReservationPlaceVariationIDs)

        SetContent(newList);
        SetSelectedReservationPlaceVariationIDs(newSelectedReservationPlaceVariationIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedReservationPlaceVariationIDs = [];
        const newList = SelectReservationPlaceVariationID(content,"all",checked,newSelectedReservationPlaceVariationIDs)

        SetSelectedReservationPlaceVariationIDs(newSelectedReservationPlaceVariationIDs);
        SetContent(newList);
    }

    const SelectReservationPlaceVariationID = (list,reservationPlaceVariationID,checked,selectedReservationPlaceVariationIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(reservationPlaceVariationID == newList[i].rowID || reservationPlaceVariationID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedReservationPlaceVariationIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedReservationPlaceVariationIDs.length; j++)
                    {
                        if(selectedReservationPlaceVariationIDs[j] == newList[i].rowID)
                            selectedReservationPlaceVariationIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectReservationPlaceVariationID(newList[i].subData,reservationPlaceVariationID,checked,selectedReservationPlaceVariationIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        refetch();
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedReservationPlaceVariationIDs,
        SelectRow,
        SelectAll,
        UpdateList
    }

}

export default UseGetAllReservationPlaceVariations;