import Checkbox from "../Checkbox";
import { useTranslation } from 'react-i18next';
import { useState } from "react";

const ListHeader = ({
    data,
    headerClassName,
    hideOptions,
    isSortable,
    optionsTitle,
    optionsClassName,
    OnSelectAll
}) => {

    const [checkedAll,SetCheckedAll] = useState(false);
    const {t} = useTranslation();

    const CheckAll = (e) => {

        SetCheckedAll(e.target.checked);

        if(OnSelectAll)
            OnSelectAll(e)
    }

    return(
        <div className={"list-header" + (isSortable ? " sortable" : "") + (headerClassName ? " " + headerClassName : "")}>
            {OnSelectAll ?
                <Checkbox 
                    name="select-all"
                    checked = {checkedAll}
                    OnChange={(e) => CheckAll(e)}
                />  
            :null}
            {data && data.map((item,index) => {
                return(
                    <div key={index} className={item.className}>
                        {item.value}
                    </div>
                )
            })}
            {!hideOptions ?
                <div className={"static smaller text-center" + (optionsClassName ? " " + optionsClassName : "")}>
                    {optionsTitle || optionsTitle == "" ? optionsTitle : t("Options")}
                </div>
            :null}
        </div>
    )
}

export default ListHeader;