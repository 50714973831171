import Input from '../../../GlobalComponents/Input';
import InputCard from '../../../GlobalComponents/InputCard';
import eye from '../../../Media/Images/Icons/eye.webp';
import RadioButton from '../../../GlobalComponents/RadioButton';
import { useTranslation } from 'react-i18next';
import imageIcon from '../../../Media/Images/Icons/image.webp';
import FileInput from '../../../GlobalComponents/FileInput';
import Button from '../../../GlobalComponents/Button';
import NoItems from '../../../GlobalComponents/NoItems';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import Select from '../../../GlobalComponents/Select';
import { useState } from 'react';
import DatePicker from '../../../GlobalComponents/DatePicker';
import Checkbox from '../../../GlobalComponents/Checkbox';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import { TINY_MCE_TOOLBAR_MEDIA } from '../../../Config';
import seo from '../../../Media/Images/Icons/seo.webp';
import projects from '../../../Media/Images/Icons/projects.webp';
import categoryIcon from '../../../Media/Images/Icons/category.webp';
import calendarIcon from '../../../Media/Images/Icons/calendar.webp';
import UseEditArticleLinks from '../Library/UseEditArtilceLinks';
import List from '../../../GlobalComponents/List/List';
import ChooseLinks from '../../Content/Components/ChooseLinks';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import UsePublishArticle from '../Library/UsePublishArticle';

const BasicInformations = ({
    formData,
    allProjects,
    allForms,
    selectedArticleID,
    selectedProjectID,
    refetch,
    SetFormData,
    OpenImage,
    GetFormLangValue,
    SetFormLangData,
    FillFormSelectedItemsWithObj,
    SetSelectedProjectID,
    SetFormDataSelectedItemsWithObjToArray,
    FillFormSelectedItemsWithObjToArray
}) =>{

    const {t} = useTranslation();

    const [showAddLinks, SetShowAddLinks] = useState(false);
    const {headerData,content} = UseEditArticleLinks(formData.selectedLinks[selectedProjectID],selectedProjectID);
    const {
        loading,
        unLoading,
        showPublishNotifi,
        showUnPublishNotifi,
        PublishArticle,
        UnPublishArticle,
        ShowPublishNotification,
        ShowUnPublishNotification,
        IsArticlePublishedInSelectedLangAndProject,
        IsArticlePublishedInAnyLangsAndProject,
        IsArticlePublishedInAllLangsAndProject
    } = UsePublishArticle(selectedArticleID,formData,() => {
        
        SetFormData("selectedProjectsForPublication",[]);
        SetFormData("selectedProjectsForUnPublication",[]);
        SetFormData("publishAllLangs",0);
        SetFormData("unPublishAllLangs",0);
        SetFormData("sendNewsletterWhenPublishing",0);

        refetch();
    });

    var numberOfPublishedProjects = 0;
    var numberOfUnPublishedProjects = 0;

    return(
        <div className="row">
            <div className="col-50 d-flex flex-direction-column">

                <div className="form-group">
                    <label>*{t("ArticleTitle")} ({formData.selectedLang})</label>
                    <Input
                        name="title"
                        value={GetFormLangValue("title")}
                        OnChange={(e) => SetFormLangData(e)}
                    />
                </div>
                <InputCard
                    title = {t("Image")}
                    icon = {imageIcon}
                    className = {"form-group flex-1"}
                > 
                    {formData.mainPhoto ?
                        
                        <div className="open-image-container form-group">
                            <img className="no-margin" src = {formData.mainPhoto} />
                        </div>
                        
                    :null}

                    <div className="form-group">                
                        <FileInput
                            name = "photo"
                            OnChange = {(e) => OpenImage(e,"photo","mainPhoto")}
                        />
                    </div>

                </InputCard>
                
            </div>
            <div className="col-50 d-flex flex-direction-column">

                <InputCard
                    title = {t("Display")}
                    icon = {eye}
                    className = {"form-group"}
                    topMargin={true}
                >   
                    <div className="row">
                        <div className="col-50">
                            <div className="form-group">
                                
                                <label>{t("AloneArticle")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text="Ano"
                                        name="alone"
                                        id="alone-1"
                                        value={1}
                                        checked = {formData.alone === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text="Ne"
                                        name="alone"
                                        id="alone-2"
                                        value={0}
                                        checked = {formData.alone === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div> 
                        <div className="col-50">
                            <div className="form-group">
                                
                                <label>{t("WithoutMainImage")} </label>
                                <div className="radio-container">
                                    <RadioButton
                                        text="Ano"
                                        name="withoutMainImage"
                                        id="withoutMainImage-1"
                                        value={1}
                                        checked = {formData.withoutMainImage === 1 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                    <RadioButton
                                        text="Ne"
                                        name="withoutMainImage"
                                        id="withoutMainImage-2"
                                        value={0}
                                        checked = {formData.withoutMainImage === 0 ? true : false}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div>
                            </div>
                        </div>                                                             
                    </div> 
                </InputCard>

                <InputCard
                    title = {t("AssignToProject")}
                    icon = {projects}
                    className = {"form-group"}
                >  
                    {allProjects && allProjects.length > 0 ?
                        <>
                            {allProjects.map((item) => {

                                var checked = false;
                                for(let val of formData.selectedProjects)
                                {
                                    if(val.projectID == item.projectID)
                                        checked = true;
                                }

                                return(
                                    <div className="form-group" key={item.projectID}>
                                        <Checkbox
                                            text = {item.name}
                                            id = {"p" + item.projectID}
                                            name = "projectID"
                                            checked = {checked}
                                            OnChange = {() => FillFormSelectedItemsWithObj("selectedProjects","projectID",item,false)}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    :
                        <NoItems text={t("ToChooseProjectGoToMenuAndAddNewProject")} />
                    }
                    
                </InputCard>

                <InputCard
                    title = {t("PublicationDate") + " (" + formData.selectedLang + ")"}
                    icon = {calendarIcon}
                    className = {"form-group"}
                >  
                    {formData.selectedProjects && formData.selectedProjects.length > 0 ?
                        <div className = {"form-group"}>
                            <DatePicker
                                OnChange = {(_,date) => SetFormLangData("publishDate",date)}
                                value = {GetFormLangValue("publishDate")}
                                showPast = {false}  
                            />
                        </div>
                        :
                        <NoItems text={t("SelectProjectToChoosePublishDate")} />
                    }
                    
                </InputCard>
            </div>
            {selectedArticleID != 0 ?
                <div className="col-100">
                    <InputCard
                        title = {t("Publication")}
                        icon = {eye}
                        canBeMinimalized = {true}
                        className = {"form-group flex-1"}
                    >   
                        <div className="row">
                            <div className="col-50">

                                <InputCard
                                    title = {t("PublishTo")}
                                    icon = {projects}
                                    className = {"form-group"}
                                >  
                                    {formData.selectedProjects.length > 0 ?
                                        <>
                                            {formData.selectedProjects.map((item) => {

                                                var isPublished = IsArticlePublishedInSelectedLangAndProject(item.projectID);
                                                if(formData.publishAllLangs)
                                                    isPublished = IsArticlePublishedInAllLangsAndProject(item.projectID);
                                                
                                                if(!isPublished)
                                                {
                                                    var checked = false;
                                                    for(let val of formData.selectedProjectsForPublication)
                                                    {
                                                        if(val.projectID == item.projectID)
                                                            checked = true;
                                                    }

                                                    return(
                                                        <div className="form-group" key={item.projectID}>
                                                            <Checkbox
                                                                text = {item.name}
                                                                id = {"publish" + item.projectID}
                                                                name = "projectID"
                                                                checked = {checked}
                                                                OnChange = {() => FillFormSelectedItemsWithObj("selectedProjectsForPublication","projectID",item,false)}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                else
                                                {
                                                    numberOfPublishedProjects++;
                                                    return "";
                                                }
                                            })}
                                        </>
                                    :
                                        <NoItems text={t("SelectProjectToChoosePublishDate")} />
                                    }

                                    {numberOfPublishedProjects == formData.selectedProjects.length ?
                                        <NoItems text={formData.publishAllLangs ? t("AllPublicated") : t("AllPublicatedFor") + ":  <span style='text-transform:uppercase'>" + formData.selectedLang + "</span>"} />
                                    :null}
                                    
                                </InputCard>

                                <div className = {"form-group"}>
                                    <Checkbox 
                                        text={t("PublishAllLangs")}
                                        name="publishAllLangs"
                                        id="publishAllLangs"
                                        checked = {formData.publishAllLangs}
                                        OnChange = {(e) => SetFormData(e)}
                                    />
                                </div>
                                <div className = {"form-group"}>
                                    <Checkbox 
                                        text={t("SendNewsletterWhenPublishing")}
                                        name="sendNewsletterWhenPublishing"
                                        id="sendNewsletterWhenPublishing"
                                        checked = {formData.sendNewsletterWhenPublishing}
                                        OnChange = {(e) => SetFormData(e)}
                                    />
                                </div>
                                <div className = {"form-group"}>

                                    <Button
                                        loading = {loading}
                                        className="btn-primary w-100"
                                        OnClick = {() => ShowPublishNotification()}
                                    >{(formData.publishAllLangs ? t("Publish") : t("PublishFor") + ": " + formData.selectedLang)}</Button>
                                   
                                </div>
                            

                            </div>
                            <div className="col-50">

                                <InputCard
                                    title = {t("UnPublishFrom")}
                                    icon = {projects}
                                    className = {"form-group"}
                                >  
                                    {formData.selectedProjects.length > 0 ?
                                        <>
                                            {formData.selectedProjects.map((item) => {

                                                var isPublished = IsArticlePublishedInSelectedLangAndProject(item.projectID);
                                                if(formData.unPublishAllLangs)
                                                    isPublished = IsArticlePublishedInAnyLangsAndProject(item.projectID);

                                                if(isPublished)
                                                {
                                                    var checked = false;
                                                    for(let val of formData.selectedProjectsForUnPublication)
                                                    {
                                                        if(val.projectID == item.projectID)
                                                            checked = true;
                                                    }

                                                    return(
                                                        <div className="form-group" key={item.projectID}>
                                                            <Checkbox
                                                                text = {item.name}
                                                                id = {"unPublish" + item.projectID}
                                                                name = "projectID"
                                                                checked = {checked}
                                                                OnChange = {() => FillFormSelectedItemsWithObj("selectedProjectsForUnPublication","projectID",item,false)}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                else
                                                {
                                                    numberOfUnPublishedProjects++;
                                                    return "";
                                                }
                                            })}
                                        </>
                                    :
                                        <NoItems text={t("SelectProjectToChoosePublishDate")} />
                                    }

                                    {numberOfUnPublishedProjects == formData.selectedProjects.length ?
                                        <NoItems text={formData.unPublishAllLangs ? t("NotPublicatedYet") : t("NotPublicatedYetFor") + ":  <span style='text-transform:uppercase'>" + formData.selectedLang + "</span>"} />
                                    :null}
                                    
                                </InputCard>

                                <div className = {"form-group"}>
                                    <Checkbox 
                                        text={t("UnPublishAllLangs")}
                                        name="unPublishAllLangs"
                                        id="unPublishAllLangs"
                                        checked = {formData.unPublishAllLangs}
                                        OnChange = {(e) => SetFormData(e)}
                                    />
                                </div>
                                
                                <div className = {"form-group"}>

                                    <Button
                                        loading = {unLoading}
                                        className="btn-brown w-100"
                                        OnClick = {() => ShowUnPublishNotification()}
                                        >{(formData.unPublishAllLangs ? t("UnPublish") : t("UnPublishFor") + ": " + formData.selectedLang)}</Button>

                                </div>

                            </div>
                            
                        </div>
                        
                    </InputCard>
                </div>
            :null}
            <div className="col-100">
                <InputCard
                    className = "form-group"
                    title = {"*" + t("Links")}
                    icon = {categoryIcon}
                    noBodyPadding = {formData.selectedProjects && formData.selectedProjects.length > 0 ? true : false}
                    canBeMinimalized = {true}
                    headerChildren = {
                        <>
                            {formData.selectedProjects && formData.selectedProjects.length > 0 ? 
                                <>
                                    <Select 
                                        className = "narrow"
                                        name="selectedProjectID"
                                        value={selectedProjectID}
                                        OnClick = {(e) => {
                                            e.stopPropagation();
                                        }}
                                        OnChange={(e) => {
                                            e.stopPropagation();
                                            SetSelectedProjectID(e.target.value);
                                        }}
                                    >
                                        {formData.selectedProjects.map((item) => (
                                            <option 
                                                key={item.projectID} 
                                                value = {item.projectID}
                                            >{item.name}</option>
                                        ))}
                                    </Select>
                                    <Button 
                                        OnClick = {(e) => {
                                            e.stopPropagation();
                                            SetShowAddLinks(true)
                                        }}
                                        className="btn-primary narrow"
                                    >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>
                                </>
                            :null}  
                        </>
                    }
                >   
                    {formData.selectedProjects && formData.selectedProjects.length > 0 ?
                        <List 
                            headerData = {headerData} 
                            headerClassName = {"no-top-border"}
                            contentData = {content}
                            isSortable = {false}
                            loading = {false}
                            replaceDotsImg = {cancelIcon}
                            ReplaceDotsOnClick = {(data) => SetFormDataSelectedItemsWithObjToArray("deleted","selectedLinks","linkID",data.rowID,true,data.selectedProjectID)}
                        />
                    :
                        <NoItems text={t("SelectProjectToChoosePublishDate")} />
                    }
                
                </InputCard>
            </div>
            <div className="col-100">
                <div className="form-group">

                    <label>*{t("Perex")} ({formData.selectedLang})</label>
                    <TinyMCEEditor 
                        value = {GetFormLangValue("perex")}
                        OnEditorChange={(content) => SetFormLangData("perex",content)}
                        toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                        useMediaEditor={true}
                    />

                </div>
                <div className="form-group">

                    <label>*{t("Text")} ({formData.selectedLang})</label>
                    <TinyMCEEditor 
                        value = {GetFormLangValue("text")}
                        OnEditorChange={(content) => SetFormLangData("text",content)}
                        toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                        useMediaEditor={true}
                        height = {1000}
                        useProductsAndForms={true}
                        useAddProducts={true}
                        allForms = {allForms}
                    />

                </div>
                <InputCard
                    title = {t("SEO")}
                    icon = {seo}
                >   
                    <div className="row">
                        <div className="col-50">
    
                            <div className="form-group">
                                <label>{t("MetaTitle")} ({formData.selectedLang})</label>
                                <Input
                                    name="metaTitle"
                                    value={GetFormLangValue("metaTitle")}
                                    OnChange={(e) => SetFormLangData(e)}
                                />
                            </div>
                            
                        </div>
                        <div className="col-50">
                            <div className="form-group">
                                <label>{t("MetaKeys")} ({formData.selectedLang})</label>
                                <Input
                                    name="metaKeywords"
                                    value={GetFormLangValue("metaKeywords")}
                                    OnChange={(e) => SetFormLangData(e)}
                                />
                            </div>
                        </div>
                        <div className="col-100">
                            <div className="form-group">
                                <label>{t("MetaDescription")} ({formData.selectedLang})</label>
                                <Input
                                    name="metaDescription"
                                    value={GetFormLangValue("metaDescription")}
                                    OnChange={(e) => SetFormLangData(e)}
                                />
                            </div>
                        </div>                                                
                    </div>
                </InputCard>
            </div>

            {showAddLinks? 
                <ChooseLinks
                    formData = {formData}
                    projectID = {selectedProjectID}
                    useDeletedAttribute = {true}
                    SetShowAddLinks = {SetShowAddLinks}
                    FillFormSelectedItemsWithObjToArray = {FillFormSelectedItemsWithObjToArray}
                />
            :null}

            {showPublishNotifi ?
                <ModalNotification 
                    yesNo={true} 
                    text={t('DoYouReallyWantToPublishThisArticle')} 
                    CallBack={(action) => PublishArticle(action)} 
                /> 
            :null}

            {showUnPublishNotifi ?
                <ModalNotification 
                    yesNo={true} 
                    text={t('DoYouReallyWantToUnPublishThisArticle')} 
                    CallBack={(action) => UnPublishArticle(action)} 
                /> 
            :null}
            
        </div>
    )
}

export default BasicInformations;