import { useTranslation } from 'react-i18next';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import { TINY_MCE_TOOLBAR_MEDIA } from '../../../Config';
import Label from '../../../GlobalComponents/Label';
import Input from '../../../GlobalComponents/Input';

const TherapyData = ({
    formData,
    GetFormLangValue,
    SetFormLangData
}) => {

    const {t} = useTranslation();
    
    return(
        <> 
            
            <div className="form-group">
                <Label 
                    text = {t("TherapyTextTemplate") + " (" + formData.selectedLang + ")"} 
                    infoContent={[
                        t("TherapyTextTemplateInfo")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("therapyTextTemplate")}
                    OnEditorChange={(content) => SetFormLangData("therapyTextTemplate",content)}
                    toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                    useMediaEditor={true}
                />
            </div>
            <div>
                <Label 
                    text = {t("TherapyTextToRead") + " (" + formData.selectedLang + ")"} 
                    infoContent={[
                        t("TherapyTextToReadInfo")
                    ]}
                />
                <TinyMCEEditor 
                    value = {GetFormLangValue("therapyTextToRead")}
                    OnEditorChange={(content) => SetFormLangData("therapyTextToRead",content)}
                    toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                    useMediaEditor={true}
                />
            </div>
                       
        </>
    )
}

export default TherapyData;