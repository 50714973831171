const Orders = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M312.356,172.789l-12.781-98c-0.714-5.479-5.382-9.577-10.908-9.577h-54.333c1.967-86.689-122.002-86.614-120,0H60.001
                        c-5.525,0-10.193,4.099-10.908,9.577c-0.562,6.095-45,339.273-49.094,380.42c0.001,15.137,5.896,29.366,16.604,40.075
                        c10.712,10.701,24.941,16.595,40.065,16.595h166.665c6.075,0,11-4.925,11-11s-4.925-11-11-11H56.669
                        c-9.252,0-17.959-3.607-24.513-10.155c-6.549-6.549-10.155-15.254-10.155-24.521c3.877-37.201,40.279-308.885,47.658-367.991
                        h44.675v21.667c0,6.075,4.925,11,11,11s11-4.925,11-11V87.212h76v21.667c0,6.075,4.925,11,11,11c6.075,0,11-4.925,11-11V87.212
                        h44.674l11.532,88.423c0.786,6.023,6.308,10.273,12.33,9.484C308.895,184.334,313.142,178.813,312.356,172.789z M212.335,65.212
                        h-76C133.484,7.674,215.201,7.708,212.335,65.212z"/>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M511.034,445.418c-6.346-35.949-48.2-273.118-48.2-273.118c-0.928-5.256-5.495-9.088-10.832-9.088
                            H223.335c-5.337,0-9.905,3.832-10.832,9.088l-48.201,273.127c-6.767,33.981,19.561,66.729,54.412,66.452c0,0,237.91,0,237.91,0
                            C491.438,512.149,517.822,479.435,511.034,445.418z M480.223,480.1c-6.307,6.307-14.688,9.779-23.599,9.779H218.713
                            c-17.933,0.369-33.753-15.445-33.378-33.386c0-2.421,0.213-4.863,0.633-7.251l46.596-264.03h210.21
                            c8.156,49.341,42.417,234.107,47.23,271.29C490.001,465.412,486.528,473.793,480.223,480.1z"/>
                        
                        <path 
                            style={{"fill":secondaryColor}}  
                            d="M386.668,228.546c-6.075,0-11,4.925-11,11c-0.054,16.867,1.937,29.982-11.124,43.194
                            c-23.653,23.844-64.949,6.768-64.876-26.845c0,0.001,0-16.349,0-16.349c0-6.075-4.925-11-11-11c-6.075,0-11,4.925-11,11v16.35
                            c3.314,79.755,116.872,79.385,120-0.017c0,0.001,0-16.332,0-16.332C397.668,233.471,392.743,228.546,386.668,228.546z"/>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Orders;
