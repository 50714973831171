import { useEffect,useState } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import FaqResponziveSettings from "./FaqResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { FAQ } from "../../../Library/elements";
import ChooseFaq from "../../../../Faq/Components/ChooseFaq";
import faqIcon from '../../../../../Media/Images/Icons/faq.webp';
import cancelIcon from '../../../../../Media/Images/Icons/cancel.webp';
import UseEditFaqs from "../../../Library/UseEditFaqs";
import List from "../../../../../GlobalComponents/List/List";
import Error from "../../../../../GlobalComponents/Error";

const FaqSettings = () => {

    const [showAddFaq, SetShowAddFaq] = useState(false);

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData,FillFormSelectedItemsWithObj,RemoveFormDataSelectedItems} = UseFormHandle(FAQ.faq); 
    const {
        formData:cmFormData,
        showSettingsElement,
        contentManagerProjectID,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection
    } = UseContentManager();

    const {
        headerData,
        content,
        loading,
        error,
        GetSelectedFaqs,
        OnDragEnd
    } = UseEditFaqs(cmFormData.selectedLang,formData.selectedFaqs,SetFormData);

    useEffect(() => {

        const GetSelFaq = async () => {
            const settings = GetSettings(showSettingsElement.index,"faq");
            settings.selectedFaqs = await GetSelectedFaqs(settings.selectedFaqs);
            SetCompleteFormData(settings);
        }

        if(showSettingsElement)
            GetSelFaq();

    },[showSettingsElement])

    useEffect(() => {

        var selectedFaqs = [];
        for(let val of formData.selectedFaqs)
        {
            selectedFaqs.push(val.faqID);
        }

        SetSettings(showSettingsElement.index,"selectedFaqs",selectedFaqs,"faq");

    },[formData.selectedFaqs])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"faq");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"faq");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <FaqResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <FaqResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <FaqResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <FaqResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <FaqResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <FaqResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="form-group">
                <InputCard
                    className = "form-group"
                    title = {t("Faq")}
                    icon = {faqIcon}
                    noBodyPadding = {true}
                    headerChildren = {
                        <Button 
                            OnClick = {(e) => {
                                e.stopPropagation();
                                SetShowAddFaq(true)
                            }}
                            className="btn-primary narrow"
                        >{content && content.length > 0 ? t("Edit") : t("Choose")}</Button>
                    }
                >   
                    {error ? 
                        <Error text={error} />
                    :
                        <List 
                            headerData = {headerData} 
                            headerClassName = {"no-top-border smaller-padding"}
                            rowClassname = {"smaller-padding"}
                            contentData = {content}
                            isSortable = {true}
                            OnDragListRowEnd={(e)=> OnDragEnd(e)}
                            loading = {loading}
                            replaceDotsImg = {cancelIcon}
                            optionsTitle = {""}
                            optionsClassName = "smallest"
                            ReplaceDotsOnClick = {(data) => RemoveFormDataSelectedItems("selectedFaqs","faqID",[data.rowID])}
                        />
                    }
                
                </InputCard>
                {showAddFaq ? 
                    <ChooseFaq 
                        projectID = {contentManagerProjectID}
                        formData = {formData}
                        SetShowAddFaq = {SetShowAddFaq}
                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                    />
                :null}
            </div>   
            <div className="form-group">
                <label>{t("Radius")}</label>
                <Input 
                    type = "number"
                    name = "borderRadius"
                    value = {formData.borderRadius}
                    OnChange = {(e) => SetS(e)}
                />
            </div>        
            <div className="form-group">
                <label>{t("ClassName")}</label>
                <Input 
                    type = "text"
                    name = "className"
                    value = {formData.className}
                    OnChange = {(e) => SetS(e)}
                />
            </div>
                
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            <div className="cm-remove-section-elm">
                <Button 
                    className="btn-brown"
                    OnClick = {() => RemoveElm()}
                >
                    {t("RemoveElement")}
                </Button>
            </div>
        </>
    )
}

export default FaqSettings;