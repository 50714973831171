import Loading from '../../../GlobalComponents/Loading';
import Input from "../../../GlobalComponents/Input";
import { useTranslation } from 'react-i18next';
import keyIcon from '../../../Media/Images/Icons/password_input.svg';
import Button from '../../../GlobalComponents/Button';
import { Link, useParams } from "react-router-dom";
import Success from '../../../GlobalComponents/Success';
import Error from '../../../GlobalComponents/Error';
import UseSavePassword from '../Library/UseSavePassword';


const NewPassword = () => {

    const {t} = useTranslation();

    const params = useParams();

    const {
        formData,
        success,
        loading,
        disabled,
        error,
        Save,
        SetFormData
    } = UseSavePassword(params.code);

    return(
        <div className="auth-panel">
			{loading ?
				<Loading />
			:
				<>
					<div className="card">

						<div className="card-body">

							{success ?
                                <>
                                    <Success 
                                        className = "d-block"
                                        text={t('PasswordSuccessfulySaved')} 
                                    />
                                    <div className="text-center">
                                        <Link to='/'>{t('LogIn')}</Link>
                                    </div>
                                </>
							:
								<>
									<p className="form-group text-center">
										<strong>{t('FillYourNewPassword')}</strong>
									</p>
																	
									<div className="form-group">
                                        
										<Input 
											OnChange={e => SetFormData(e)}
											value={formData.password}
											type="password" 
											name="password"
											placeholder={t('NewPassword')} 
											ariaLabel="Password" 
											preppend={<img alt="" src={keyIcon} />}
										/>

									</div>

                                    <div className="form-group">
                                        
										<Input 
											OnChange={e => SetFormData(e)}
											value={formData.checkPassword}
											type="password" 
											name="checkPassword"
											placeholder={t('RepeatNewPassword')} 
											ariaLabel="Password" 
											preppend={<img alt="" src={keyIcon} />}
										/>

									</div>
																
									<div className="form-group">
										<Button 
											disabled={disabled} 
											loading={loading} 
											OnClick={() => Save()} 
											className="btn btn-primary full-width"
										>{t('Save')}</Button>
									</div>
								</>
							}
							
							{error ? 
								<Error text = {error} />
							:null}
														
						</div>
					</div>
				</>
			}
        </div>
    )
}

export default NewPassword;