import {useQuery} from '@apollo/client';
import { GET_ALL_FAQ } from '../Queries/faq';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ConvertHtmlToText } from '../../../GlobalFunctions/helper';

const UseChooseFaq = (projectID,lang,selectedFaqs,FillFormSelectedItemsWithObj) => {

    const [variables,SetVariables] = useState({
        lang,
        projectID,
        limit:40,
        offset:0
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedFaqIDs,SetSelectedFaqIDs] = useState([]);

    const {data,loading,error,fetchMore,refetch} = useQuery(GET_ALL_FAQ,{
        variables,
        fetchPolicy:"network-only",
        skip:(variables.lang == "" ? true : false)
    })

    const FetchMore = (offset) => {

        fetchMore({
            variables: {
                offset: offset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    AllFaqs: [...prev.AllFaqs, ...fetchMoreResult.AllFaqs]
                });
            }
        });
    }

    const GoToPage = (page) => {

        SetVariables((prevValue) => {

            var newOffset = (page - 1) * prevValue.limit;
            if(newOffset == prevValue.offset)
                refetch();

            return {...prevValue,offset:newOffset}

        })
    }

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllFaqs);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("Question"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];
        
        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:
                            <span 
                                className="cursor-help"
                                title={ConvertHtmlToText(val.question)}
                            >
                                {ConvertHtmlToText(val.question)}
                            </span>,
                        className:"flex-1 one-line-with-dots"
                    }
                ],
            }

            if(selectedFaqs.length > 0)
                cData.selected =  (selectedFaqs.indexOf(val.faqID) != -1 ? true : false); 
            else
                cData.selected = false;

            cData.rowQuestion  = val.question;
            cData.rowID        = val.faqID;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,faqID) => {

        var checked = e.target.checked;
        var newSelectedFaqIDs = [...selectedFaqIDs];
        const newList = SelectFaqID(content,faqID,checked,newSelectedFaqIDs)

        SetContent(newList);
        SetSelectedFaqIDs(newSelectedFaqIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedFaqIDs = [];
        const newList = SelectFaqID(content,"all",checked,newSelectedFaqIDs)

        SetSelectedFaqIDs(newSelectedFaqIDs);
        SetContent(newList);
    }

    const SelectFaqID = (list,faqID,checked,rowsToSelect) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(faqID == newList[i].rowID || faqID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                {
                    for(let j = 0; j < rowsToSelect.length; j++)
                    {
                        if(!rowsToSelect[j].selected)
                            FillFormSelectedItemsWithObj("selectedFaqs","faqID",{
                                question: rowsToSelect[j].rowQuestion,
                                faqID: rowsToSelect[j].rowID
                            },false);
                    }
                }

                FillFormSelectedItemsWithObj("selectedFaqs","faqID",{
                    question: newList[i].rowQuestion,
                    faqID: newList[i].rowID
                },false);
            } 
        }

        return newList;
    }

    return{
        content,
        totalContentLength: data && data.AllFaqsCount || 0,
        paggingOffset: variables.offset,
        paggingLimit: variables.limit,
        headerData,
        loading,
        error,
        selectedFaqIDs,
        GoToPage,
        FetchMore,
        SelectRow,
        SelectAll
    }

}

export default UseChooseFaq;