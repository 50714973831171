import { useState } from 'react';
import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditProject from '../Library/UseAddEditProject';
import UseGetProject from '../Library/UseGetProject';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import BasicInformations from './BasicInformations';
import { SERVER_URL,PROJECT_IMAGE_URL } from '../../../Config';
import Modules from './Modules';
import dayjs from 'dayjs';
import Colors from './Colors';
import SendEmailSettings from '../../../GlobalComponents/SendEmailSettings';
import InfoEmailing from './InfoEmailing';
import PortalMenu from './PortalMenu';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';

Modal.setAppElement('#root');

const AddEditProject = ({selectedProjectID,SetSelectedProjectID,OnAddEditSuccess}) => {

    const {t} = useTranslation();
    const [selectedTab, SetSelectedTab] = useState(1);

    var itemsToTranslate = [
        {name:"Veřejný název projektu",param:"metaTitle"},
        {name:"Jméno odesílatele",param:"sendersName"},
        {name:"Podpis v emailu",param:"emailSignature"},
        {name:"Podpis v newsletteru",param:"emailNewsletterSignature"},
        {name:"Patička emailu",param:"emailFooterText"},
        {name:"Text pro odhlášení se z newsletteru",param:"unsubscribeText"},
        {name:"Portál menu",param:"portalModules"}
    ];

    const {
        loading:getLoading,
        error:getError,
        allLanguageMutations,
        allModules,
        allBillingInformations,
        mainLang
    } = UseGetProject(selectedProjectID,(d) => {

        var fileVersion = "";
        if(d.dateEdit != "0000-00-00 00:00:00")
            fileVersion = "?" + dayjs(d.dateEdit).format("YYYYMMDDHHmmss");

        var initData = {...d};
        initData.mainLogo =  d.mainLogo ? SERVER_URL + "/" + PROJECT_IMAGE_URL + "/stredni_" + d.mainLogo + fileVersion : "";
        initData.mainLogoFooter =  d.mainLogoFooter ? SERVER_URL + "/" + PROJECT_IMAGE_URL + "/stredni_" + d.mainLogoFooter + fileVersion : "";
        initData.mainLogoPortal =  d.mainLogoPortal ? SERVER_URL + "/" + PROJECT_IMAGE_URL + "/stredni_" + d.mainLogoPortal + fileVersion : "";
        initData.mainLogoInvoice =  d.mainLogoInvoice ? SERVER_URL + "/" + PROJECT_IMAGE_URL + "/stredni_" + d.mainLogoInvoice + fileVersion : "";
        initData.mainFavicon =  d.mainFavicon ? SERVER_URL + "/" + PROJECT_IMAGE_URL + "/" + d.mainFavicon + fileVersion : "";
        initData.mainEmailHeader =  d.mainEmailHeader ? SERVER_URL + "/" + PROJECT_IMAGE_URL + "/velka_" + d.mainEmailHeader + fileVersion : "";
       
        InitFormData(initData)
        
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditProject,
        InitFormData,
        SetFormData,
        OpenImage,
        AddDomain,
        RemoveDomain,
        SetDomainValue,
        SetModule,
        GetFormLangValue,
        SetFormLangData,
        SetFormSelectedItemsLangValue,
        GetFormSelectedItemsLangValue,
        SetFormDataSelectedItemsWithObj,
        FillFormSelectedItemsWithObj
    } = UseAddEditProject(selectedProjectID,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedProjectID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {(selectedProjectID === 0 ? t("AddProject") : t("EditProject"))}
                isEdit = {selectedProjectID === 0 ? false : true}
                OnClose ={() => SetSelectedProjectID(null)}
                hideContent = {hideHeaderAndFooter}
                allLanguages={allLanguageMutations}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
            />

            <div className="modal-body">

                <ul className="tabs">
                    <li 
                        className={(selectedTab == 1 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(1)}
                    >{t("BasicInformations")}</li>
                    <li 
                        className={(selectedTab == 2 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(2)}
                    >{t("Emailing")}</li>
                    <li 
                        className={(selectedTab == 3 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(3)}
                    >{t("Modules")}</li>
                    <li 
                        className={(selectedTab == 4 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(4)}
                    >{t("Colors")}</li>
                    <li 
                        className={(selectedTab == 5 ? "selected" : "")} 
                        onClick = {() => SetSelectedTab(5)}
                    >{t("PortalMenu")}</li>
                    
                </ul>

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        : 
                            <>
                                {selectedTab == 1 ?
                                    <BasicInformations 
                                        formData = {formData}
                                        allLanguageMutations = {allLanguageMutations}
                                        allBillingInformations = {allBillingInformations}
                                        SetFormData = {SetFormData}
                                        OpenImage = {OpenImage}
                                        AddDomain = {AddDomain}
                                        RemoveDomain = {RemoveDomain}
                                        SetDomainValue = {SetDomainValue}
                                        GetFormLangValue = {GetFormLangValue}
                                        SetFormLangData= {SetFormLangData}
                                    />
                                :null} 

                                {selectedTab == 2 ?
                                    <>
                                        <div className="form-group">
                                            <SendEmailSettings 
                                                title = {t("SendingEmails")}
                                                headerInfoContent={[t("SendingEmailsInfo")]}
                                                formData = {formData}
                                                OpenImage = {OpenImage}
                                                GetFormLangValue = {GetFormLangValue}
                                                SetFormLangData = {SetFormLangData}
                                            /> 
                                        </div>
                                        <InfoEmailing 
                                            formData = {formData}
                                            SetFormData = {SetFormData}
                                        />
                                    </>
                                :null}

                                {selectedTab == 3 ?
                                    <Modules 
                                        allModules = {allModules}
                                        modules={formData.modules}
                                        SetModule = {SetModule}
                                        showInfoContent = {true}
                                        mainCheckboxTitle = {t("TurnOn")}
                                        moduleParamID = "moduleID"
                                        subModuleParamID = "subModule"
                                    />
                                :null}

                                {selectedTab == 4 ?
                                    <Colors 
                                        formData = {formData}
                                        SetFormData = {SetFormData}
                                    />
                                :null}

                                {selectedTab == 5 ?
                                    <PortalMenu 
                                        formData = {formData}
                                        allLanguageMutations = {allLanguageMutations}
                                        SetFormData = {SetFormData}
                                        SetFormSelectedItemsLangValue = {SetFormSelectedItemsLangValue}
                                        FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                                        GetFormSelectedItemsLangValue = {GetFormSelectedItemsLangValue}
                                        SetFormDataSelectedItemsWithObj = {SetFormDataSelectedItemsWithObj}
                                    />
                                :null} 
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddEditProject()}
                >
                    {(selectedProjectID === 0 ? t("Add") : t("Edit"))}
                </Button>
            </div>

        </Modal>
    )
}

export default AddEditProject;