import gql from 'graphql-tag';

export const GET_RESERVATION_BLOCKED_DAYS = gql`
    query BlockedDaysByMonth($month:Int,$year:Int,$reservationPlaceID: ID){
        BlockedDaysByMonth(
            month:$month,
            year:$year,
            reservationPlaceID:$reservationPlaceID
        ){
            date
        }
    }
`;

export const BLOCK_DAY = gql`
    mutation BlockDay(
        $date:String,
        $reservationPlaceID:ID
    ){
        BlockDay(
            date:$date,
            reservationPlaceID:$reservationPlaceID
        )
    }
`;

export const REMOVE_BLOCKED_DAY = gql`
    mutation RemoveBlockedDay(
        $date:String,
        $reservationPlaceID:ID
    ){
        RemoveBlockedDay(
            date:$date,
            reservationPlaceID:$reservationPlaceID
        )
    }
`;