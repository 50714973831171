import { useTranslation } from "react-i18next";
import InputCard from "../GlobalComponents/InputCard";
import Input from '../GlobalComponents/Input';
import FileInput from '../GlobalComponents/FileInput';
import emailIcon from '../Media/Images/Icons/email.webp';
import { TINY_MCE_TOOLBAR_BASIC } from '../Config';
import TinyMCEEditor from "../Modules/TinyMCEEditor/Components/TinyMCEEditor";
import Label from '../GlobalComponents/Label';

const SendEmailSettings = ({
    formData,
    title,
    headerInfoContent,
    OpenImage,
    GetFormLangValue,
    SetFormLangData
}) => {

    const {t} = useTranslation();

    return(
        <InputCard
            title = {title}
            icon = {emailIcon}
            headerInfoContent={headerInfoContent}
        > 

            <div className="row">
                <div className="col-100">
                    {formData.mainEmailHeader ?
                        
                        <div className="open-image-container form-group">
                            <img className="no-margin" src = {formData.mainEmailHeader} />
                        </div>
                        
                    :null}
                    <label>{t("HeaderInEmail")}</label>
                    <div className="form-group">                
                        <FileInput
                            name = "favicon"
                            OnChange = {(e) => OpenImage(e,"emailHeader","mainEmailHeader")}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("SendersEmail") + " (" + formData.selectedLang+ ")"}</label>
                        <Input
                            name="sendersEmail"
                            value={GetFormLangValue("sendersEmail")}
                            OnChange={(e) => SetFormLangData(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("SendersName") + " (" + formData.selectedLang+ ")"}</label>
                        <Input
                            name="sendersName"
                            value={GetFormLangValue("sendersName")}
                            OnChange={(e) => SetFormLangData(e)}
                        />
                    </div>
                </div>
                
                <div className="col-100">
                    <Label
                        text={t("EmailSignature") + " (" + formData.selectedLang+ ")"}
                        infoContent={[
                            t("EmailSignatureInfo1"),
                            t("EmailSignatureInfo2")
                        ]}
                    />
                    <div className="form-group">                
                        <TinyMCEEditor 
                            value = {GetFormLangValue("emailSignature")}
                            OnEditorChange={(content) => SetFormLangData("emailSignature",content)}
                            toolbar = {TINY_MCE_TOOLBAR_BASIC}
                        />
                    </div>
                </div>

                <div className="col-100">
                    <Label
                        text={t("NewsletterEmailSignature") + " (" + formData.selectedLang+ ")"}
                        infoContent={[
                            t("NewsletterEmailSignatureInfo")
                        ]}
                    />
                    <div className="form-group">                
                        <TinyMCEEditor 
                            value = {GetFormLangValue("emailNewsletterSignature")}
                            OnEditorChange={(content) => SetFormLangData("emailNewsletterSignature",content)}
                            toolbar = {TINY_MCE_TOOLBAR_BASIC}
                        />
                    </div>
                </div>

                <div className="col-100">
                    <Label 
                        text = {t("EmailFooterText") + " (" + formData.selectedLang+ ")"}
                        infoContent={[
                            t("EmailFooterTextInfo1"),
                            t("EmailFooterTextInfo2")
                        ]}
                    />
                    <div className="form-group">                
                        <TinyMCEEditor 
                            value = {GetFormLangValue("emailFooterText")}
                            OnEditorChange={(content) => SetFormLangData("emailFooterText",content)}
                            toolbar = {TINY_MCE_TOOLBAR_BASIC}
                        />
                    </div>
                </div>

                <div className="col-100">
                    <Label 
                        text = {t("UnsubscribeText") + " (" + formData.selectedLang+ ")"}
                        infoContent={[
                            t("UnsubscribeTextInfo1"),
                            t("UnsubscribeTextInfo2"),
                            t("UnsubscribeTextInfo3"),
                            t("UnsubscribeTextInfo4"),
                            t("UnsubscribeTextInfo5"),
                            t("UnsubscribeTextInfo6")
                        ]}
                    />
                    <div className="form-group">                
                        <TinyMCEEditor 
                            value = {GetFormLangValue("unsubscribeText")}
                            OnEditorChange={(content) => SetFormLangData("unsubscribeText",content)}
                            toolbar = {TINY_MCE_TOOLBAR_BASIC}
                        />
                    </div>
                </div>
            </div>

        </InputCard> 
    )
}

export default SendEmailSettings;