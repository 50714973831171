import { UseContentManager } from "../../Library/UseContentManager";
import { useTranslation } from "react-i18next";
import SectionSettings from "./SectionSettings";
import ColumnsSettings from "./Columns/ColumnsSettings";
import ColumnSettings from "./Columns/ColumnSettings";
import TextSettings from "./Text/TextSettings";
import HeadlineSettings from "./Headline/HeadlineSettings";
import ImageSettings from "./Image/ImageSettings";
import ImageGallerySettings from "./ImageGallery/ImageGallerySettings";
import VideoSettings from "./Video/VideoSettings";
import Sidebar from "../Sidebar";
import settings from '../../../../Media/Images/Icons/settings.webp';
import RecommendedProductsSettings from "./RecommendedProducts/RecommendedProductsSettings";
import NewestArticlesSettings from "./NewestArticles/NewestArticlesSettings";
import TopCategoriesSettings from "./TopCategories/TopCategoriesSettings";
import ButtonSettings from "./Button/ButtonSettings";
import AlertSettings from "./Alert/AlertSettings";
import HtmlSettings from "./Html/HtmlSettings";
import FormSettings from "./Form/FormSettings";
import ReviewsSettings from "./Reviews/ReviewsSettings";
import NewsletterSettings from "./Newsletter/NewsletterSettings";
import FaqSettings from "./Faq/FaqSettings";
import VideoGallerySettings from "./VideoGallery/VideoGallerySettings";
import ReservationPlaceSettings from "./ReservationPlace/ReservationPlaceSettings";
import TabsSettings from "./Tabs/TabsSettings";
import TabSettings from "./Tabs/TabSettings";

const Settings = () => {

    const {t} = useTranslation();
    const {showSettingsElement,SetShowSettingsElement} = UseContentManager();

    var isOpen  = false;
    if(showSettingsElement)
        isOpen = true;

    return(
        <Sidebar
            isOpen = {isOpen}
            headerIcon = {settings}
            headerTitle = {t("Settings")}
            OnCloseSidebar={() => SetShowSettingsElement(null)}
        >
            {showSettingsElement && showSettingsElement.element === "section" ? <SectionSettings /> :null}
            {showSettingsElement && showSettingsElement.element === "columns" ? <ColumnsSettings /> :null}
            {showSettingsElement && showSettingsElement.element === "column" ?  <ColumnSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "text" ?  <TextSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "headline" ?  <HeadlineSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "image" ?  <ImageSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "imageGallery" ?  <ImageGallerySettings />  :null}
            {showSettingsElement && showSettingsElement.element === "recommendedProducts" ?  <RecommendedProductsSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "topCategories" ?  <TopCategoriesSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "button" ?  <ButtonSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "video" ?  <VideoSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "newestArticles" ?  <NewestArticlesSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "alert" ?  <AlertSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "html" ?  <HtmlSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "form" ?  <FormSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "reviews" ?  <ReviewsSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "newsletter" ?  <NewsletterSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "faq" ?  <FaqSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "videoGallery" ?  <VideoGallerySettings />  :null}
            {showSettingsElement && showSettingsElement.element === "reservationPlace" ?  <ReservationPlaceSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "tabs" ?  <TabsSettings />  :null}
            {showSettingsElement && showSettingsElement.element === "tab" ?  <TabSettings />  :null}

        </Sidebar>
    )
}

export default Settings;