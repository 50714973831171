import { useTranslation } from "react-i18next";
import SearchInput from "../../../GlobalComponents/SearchInput";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { useEffect } from "react";
import Button from "../../../GlobalComponents/Button";

const ReservationFilter = ({OnFilter}) => {

    const {t} = useTranslation();

    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle({
        searchText:"",
        init:true
    })

    useEffect(() => {

        if(!formData.init)
            OnFilter(formData);

    },[formData])
    
    return(
        <div className="row">

            <div className="col-100">
                <label>{t("SearchReservation")}</label>
                <div className="form-group">
                    <SearchInput
                        initValue = {formData.searchText}
                        placeholder = {t("Search...")}
                        OnChange = {(value) => {
                            SetFormData("searchText",value);
                            SetFormData("init",false);
                        }}
                    />
                </div>
            </div>
        
            <div className="col-100">
                
                <Button 
                    className = "btn-brown form-group"
                    OnClick={() => SetCompleteFormData({
                        searchText:"",
                        init:false
                    })}
                >{t("ClearFilter")}</Button>
                
            </div>

        </div>
    )
}

export default ReservationFilter;