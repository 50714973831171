import { useState,useEffect } from "react";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_RESERVATION_PLACE,GENERATE_RESERVATION_PLACE_LANG } from "../Queries/reservationPlace";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";
import { GetCustomerObj } from "./UseEditReservationPlaceCustomers";

const UseAddEditReservationPlace = (selectedReservationPlaceID,mainLang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();

    const [selectedProjectID, SetSelectedProjectID] = useState(0);
        
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormDataSelectedItemsWithObj,
        OpenImage,
        OpenImageToArrayOfObject,
        GetFormArrayValueByParam,
        GetFormLangArrayValue,
        SetFormLangArrayData,
        OpenMedia,
        SetFormDataSelectedItemsWithObjAndIndex,
        FillFormSelectedItemsWithObj
    } = UseFormHandle({
        selectedLang:mainLang,
        active:1,
        useGoogleCalendar:0,
        langs:[],
        email:"",
        internalName:"",
        projectData:[],
        selectedTimes:[],
        selectedCustomers:[],
        googleCredentialsFile:null,
        googleCredentialsFileName:"",
        googleCalendarID: "",
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_RESERVATION_PLACE_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditReservationPlaceMutation,{error,loading}] = useMutation(ADD_EDIT_RESERVATION_PLACE,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    reservationPlaceID: d.AddEditReservationPlace.reservationPlaceID,
                    isEdit: (selectedReservationPlaceID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditReservationPlace);
                
                const notifyText = (selectedReservationPlaceID != 0 ? t("SuccessfullyUpdated") : t("NewReservationPlaceIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("ReservationPlaceHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditReservationPlace = () => {

        let langs = [];
        for(const lang of formData.langs)
        {
            var projectData = [];
            for(let p of lang.projectData)
            {
                projectData.push({
                    projectID:p.projectID,
                    name:p.name,
                })
            }

            langs.push({
                lang:               lang.lang,
                projectData
            });
        }

        if(formData.internalName)
        {
            if(
                !formData.useGoogleCalendar || 
                (
                    formData.useGoogleCalendar && 
                    (
                        (formData.googleCredentialsFile && selectedReservationPlaceID == 0) || 
                        selectedReservationPlaceID != 0
                    ) && 
                    formData.email && 
                    formData.googleCalendarID
                )
            )
            {
                const filteredTimes = formData.selectedTimes.filter((item) => !item.deleted);
                if(filteredTimes.length > 0)
                {
                    for(let val of filteredTimes)
                    {
                        val.timeFrom = parseInt(val.timeFrom);
                        val.timeTo = parseInt(val.timeTo);
                    }

                    const err = CheckTimes(filteredTimes);
                    if(!err)
                    {
                        AddEditReservationPlaceMutation({
                            variables:{
                                reservationPlaceID:     selectedReservationPlaceID,
                                active:                 parseInt(formData.active),
                                email:                  formData.email,
                                internalName:           formData.internalName,
                                googleCalendarID:       formData.googleCalendarID,
                                googleCredentialsFile:  formData.googleCredentialsFile,
                                langs:                  langs,
                                generateTranslations:   formData.generateTranslations,
                                projectData:            GetProjectImages(formData.projectData),
                                selectedTimes:          GetTimes(formData.selectedTimes),
                                selectedCustomers:      GetCustomers(formData.selectedCustomers),
                            }
                        })
                    }
                    else
                        AddNotification(err,false); 
                }
                else
                    AddNotification(t("TimesAreNecessary"),false); 
            }
            else
                AddNotification(t("ToUseGoogleCalendarFillAlInputs"),false); 
        }
        else
            AddNotification(t("NoNameNoGo"),false); 
    } 

    const GetCustomers = (customers) => {
    
        if(customers)
        {
            var newCustomers = [];
            for(let val of customers)
            {
                newCustomers.push(
                    {...GetCustomerObj(val),
                        reservationPlaceCustomerID:(
                            val.reservationPlaceCustomerID ? 
                                val.reservationPlaceCustomerID 
                            : 0
                        )
                    }
                );
            }

            return newCustomers;
        }
        else
            return []
    }

    const CheckTimes = (selectedTimes) => {

        var err = "";
        for(let val of selectedTimes)
        {
            if(val.timeFrom >= val.timeTo)
            {
                err = t("SomeTimeHasTimeFromGreatherThenThimeTo");
                break;
            }
        }

        const filteredTime = selectedTimes.sort((a,b) => a.timeFrom - b.timeFrom);
        for(let ti = 0; ti < filteredTime.length; ti++)
        {
            if(filteredTime[ti + 1] && filteredTime[ti].timeTo > filteredTime[ti + 1].timeFrom)
            {
                err = t("OneTimeInterferesWithAnother");
                break;   
            }
        }

        return err;
    }

    const GetTimes = (selectedTimes) => {

        var newTimes = [];
        for(let val of selectedTimes)
        {
            newTimes.push({
                reservationPlaceTimeID:val.reservationPlaceTimeID,
                timeFrom:val.timeFrom,
                timeTo:val.timeTo,
                deleted: (val.deleted ? true : false),
                variations:GetVariations(val.selectedVariations)
            })
        }

        return newTimes;
    }

    const GetVariations = (selectedVariations) => {

        var newVariations = [];
        for(let val of selectedVariations)
        {
            newVariations.push(val.reservationPlaceVariationID)
        }

        return newVariations;
    }

    const GetProjectImages = (projectData) => {
        var newProjectData = [];
        for(let val of projectData)
        {
            newProjectData.push({
                photo:val.photo,
                projectID:val.projectID
            })
        }

        return newProjectData;
    }

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        selectedProjectID,
        AddEditReservationPlace,
        SetFormData,
        InitFormData,
        SetFormDataSelectedItemsWithObj,
        OpenImage,
        OpenImageToArrayOfObject,
        SetSelectedProjectID,
        GetFormArrayValueByParam,
        GetFormLangArrayValue,
        SetFormLangArrayData,
        OpenMedia,
        SetFormDataSelectedItemsWithObjAndIndex,
        FillFormSelectedItemsWithObj
    }

}

export default UseAddEditReservationPlace;