import { useEffect } from "react";
import Input from "../../../../../GlobalComponents/Input";
import { UseContentManager } from "../../../Library/UseContentManager";
import UseFormHandle from "../../../../../GlobalHooks/UseFormHandle";
import Button from "../../../../../GlobalComponents/Button";
import { useTranslation } from "react-i18next";
import responziveIcon from "../../../../../Media/Images/Icons/responsive.webp";
import InputCard from "../../../../../GlobalComponents/InputCard";
import TabResponziveSettings from "./TabResponziveSettings";
import { GetTabsForResponsiveDesign } from "../../../Library/functions";
import { TAB } from "../../../Library/elements";

const TabSettings = () => {

    const {t} = useTranslation();
    const {formData,SetFormData,SetCompleteFormData} = UseFormHandle({...TAB,tabCount:0}); 
    const {
        showSettingsElement,
        GetSettings,
        SetSettings,
        RemoveElement,
        ClearSelection,
        GetColumnOrTabCount
    } = UseContentManager();

    useEffect(() => {

        if(showSettingsElement)
        {
            const settings = GetSettings(showSettingsElement.index,"tab");
            const tabCount = GetColumnOrTabCount(showSettingsElement.index);

            console.log(tabCount);

            SetCompleteFormData({...settings,tabCount});
        }

    },[showSettingsElement])

    const SetS = (e) => {
        SetFormData(e);
        SetSettings(showSettingsElement.index,e,null,"tab");
    }

    const RemoveElm = () => {
        RemoveElement(showSettingsElement.index,"tab");
        ClearSelection();
    }

    const responziveTabs = GetTabsForResponsiveDesign({
        XL : <TabResponziveSettings size = "XL" formData = {formData} OnChange = {(e) => SetS(e)} />,
        LG : <TabResponziveSettings size = "LG" formData = {formData} OnChange = {(e) => SetS(e)} />,
        MD : <TabResponziveSettings size = "MD" formData = {formData} OnChange = {(e) => SetS(e)} />,
        SM : <TabResponziveSettings size = "SM" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XS : <TabResponziveSettings size = "XS" formData = {formData} OnChange = {(e) => SetS(e)} />,
        XXS : <TabResponziveSettings size = "XXS" formData = {formData} OnChange = {(e) => SetS(e)} />,
    });

    return(
        <>
            <div className="row">
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("ClassName")}</label>
                        <Input 
                            type = "text"
                            name = "className"
                            value = {formData.className}
                            OnChange = {(e) => SetS(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <InputCard
                    title = {t("ResponziveData")}
                    icon = {responziveIcon}
                    tabsData = {responziveTabs}
                />
            </div>
            
            {formData.tabCount > 1 ?
                <div className="cm-remove-section-elm">
                    <Button 
                        className="btn-brown"
                        OnClick = {() => RemoveElm()}
                    >
                        {t("RemoveElement")}
                    </Button>
                </div>
            :null}
        </>
    )
}

export default TabSettings;