import { useState,useEffect } from "react";
import { useMutation } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";
import { 
    ADD_EDIT_MARKETING_BANNER,
    GENERATE_MARKETING_BANNER_LANG 
} from "../Queries/marketingBanner";

const UseAddEditMarketingBanner = (selectedMarketingBannerID,mainLang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();
    
    const [selectedProjectID, SetSelectedProjectID] = useState(0);
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        SetFormLangData,
        GetFormLangValue,
        OpenImage,
        SetFormDataSelectedItemsWithObj,
        GetFormArrayValueByParam
    } = UseFormHandle({
        selectedLang:mainLang,
        name:"",
        buttonColor:"",
        marketingBannerTypeID:0,
        photo:null,
        photoPortrait:null,
        langs:[],
        projectData:[],
        generateTranslations:{
            enabled:(settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_MARKETING_BANNER_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    const [AddEditMarketingBannerMutation,{error,loading}] = useMutation(ADD_EDIT_MARKETING_BANNER,{
        onCompleted: async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    marketingBannerID: d.AddEditMarketingBanner.marketingBannerID,
                    isEdit: (selectedMarketingBannerID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditMarketingBanner);
                
                const notifyText = (selectedMarketingBannerID != 0 ? t("SuccessfullyUpdated") : t("NewMarketingBannerIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("MarketingBannerHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditMarketingBanner = () => {

        if(formData.name)
        {
            if(formData.marketingBannerTypeID)
            {
                if(selectedMarketingBannerID || (formData.photo && formData.photoPortrait && selectedMarketingBannerID == 0))
                {
                    AddEditMarketingBannerMutation({
                        variables:{
                            marketingBannerID:      selectedMarketingBannerID,
                            marketingBannerTypeID:  formData.marketingBannerTypeID,
                            name:                   formData.name,
                            buttonColor:            formData.buttonColor,
                            photo:                  formData.photo,
                            photoPortrait:          formData.photoPortrait,
                            langs:                  GetLangsData(formData),
                            projectData:            GetProjectData(formData),
                            generateTranslations:   formData.generateTranslations
                        }
                    })
                }
                else
                    AddNotification(t("BothImagesAreImportantForMarketing"),false); 
            }
            else
                AddNotification(t("PleaseChooseBannerType"),false); 

        }
        else
            AddNotification(t("NoNameNoGo"),false); 
    } 

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    const GetLangsData = (formData) => {

        var langs = [];
        for(const lang of formData.langs)
        {
            langs.push({
                text:       lang.text,
                buttonText: lang.buttonText,
                buttonUrl:  lang.buttonUrl,
                lang:       lang.lang
            });
        }
        return langs;
    }

    const GetProjectData = (formData) => {

        var projectData = [];
        for(const p of formData.projectData)
        {
            projectData.push({
                projectID:          p.projectID,
                showInProject:      p.showInProject,
                showInBlog:         p.showInBlog,
                showInAllArticles:  p.showInAllArticles
            });
        }
        return projectData;
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        selectedProjectID, 
        translatedLangsCount,
        translatedLang,
        SetSelectedProjectID,
        AddEditMarketingBanner,
        SetFormData,
        SetFormLangData,
        InitFormData,
        GetFormLangValue,
        OpenImage,
        SetFormDataSelectedItemsWithObj,
        GetFormArrayValueByParam
    }

}

export default UseAddEditMarketingBanner;