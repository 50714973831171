import {useQuery} from '@apollo/client';
import { GET_MEMBERSHIPS,GET_NO_RECCURING_NO_RES_MEMBERSHIPS } from '../Queries/membership';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import YesNo from '../../../GlobalComponents/YesNo';

const UseChooseMemberships = (
    lang,
    projectID,
    selectedMemberships,
    FillFormSelectedItemsWithObj,
    noReccuringNoReservations,
    selectedParam
) => {

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    
    var query = GET_MEMBERSHIPS;
    if(noReccuringNoReservations)
        query = GET_NO_RECCURING_NO_RES_MEMBERSHIPS;

    const {data,loading,error} = useQuery(query,{
        variables:{
            lang,
            projectID
        },
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData((noReccuringNoReservations ? 
                data.AllNoRecurringNoResMemberships 
                : 
                data.AllMemberships
            ));
            SetContent(contentData);
        }
        else
            SetContent([]);

    },[data,selectedMemberships])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        },
        {
            value: t("CanBeReserved"),
            className:"static text-center"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];
        console.log(data);

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        value:val.name,
                        className:"flex-1"
                    },
                    {
                        value:<YesNo isYes={val.canBeReserved ? true : false} />,
                        className:"static text-center"
                    }
                ],
            }

            cData.selected = false;
            if(selectedMemberships && selectedMemberships.length > 0)
            {
                for(let v of selectedMemberships)
                {
                    if(val.membershipID == v.membershipID)
                        cData.selected = true;
                }
            }

            cData.rowName       = val.name;
            cData.rowID         = val.membershipID;
            cData.canBeReserved = val.canBeReserved;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,data) => {

        SelectmembershipID(content,data.rowID);    
    }

    const SelectmembershipID = (list,rowID) => {

        for(let i = 0; i < list.length; i++)
        {
            if(rowID == list[i].rowID || rowID === "all")
            {              
                FillFormSelectedItemsWithObj(selectedParam ? selectedParam : "selectedMemberships","membershipID",{
                    name: list[i].rowName,
                    membershipID: list[i].rowID,
                    canBeReserved: list[i].canBeReserved,
                    discount:0
                },false);

                break;
            }
        }
    }

    return{
        content,
        headerData,
        loading,
        error,
        SelectRow
    }

}

export default UseChooseMemberships;