import gql from 'graphql-tag';

export const ADD_EDIT_CAMPAIGN = gql`
    mutation AddEditCampaign(
        $campaignID:ID,
        $projectID:ID,
        $linkID:ID,
        $name: String,
        $active: Int,
        $isForNewsletter: Int,
        $isForTherapy: Int,
        $isForAndroidMobileAppID: Int,
        $isForIOSMobileAppID: Int,
        $useEmailsFromNewsletter: Int,
        $products:[CampaignProductsInput],
        $forms:[CampaignFormsInput]
    ){
        AddEditCampaign(
            campaignID:$campaignID,
            projectID:$projectID,
            linkID:$linkID,
            name: $name,
            active: $active,
            isForNewsletter: $isForNewsletter,
            isForTherapy: $isForTherapy,
            isForAndroidMobileAppID: $isForAndroidMobileAppID,
            isForIOSMobileAppID: $isForIOSMobileAppID,
            useEmailsFromNewsletter: $useEmailsFromNewsletter,
            products:$products,
            forms:$forms
        ){
            campaignID
            name
        }
    }
`;

export const GET_CAMPAIGN = gql`
    query CampaignData(
        $campaignID: ID,
        $projectID:ID,
        $lang:String
    ){
        Campaign(campaignID:$campaignID){
            campaignID
            name
            active 
            isForNewsletter 
            isForTherapy
            isForIOSMobileAppID
            isForAndroidMobileAppID
            useEmailsFromNewsletter
            linkID
            products{
                productID
                product(lang:$lang){
                    name
                    mainPhoto
                }
            }
            forms{
                formID
                form(lang:$lang){
                    name
                }
            }
        }
        AllLinks(lang:$lang,projectID:$projectID,isEshopCategory:0,onlyActive:true){
            linkID
            name
        }
    }
`;

export const GET_CAMPAIGNS = gql`
    query AllCampaigns($projectID:ID,$lang: String,$onlyActive:Boolean){
        AllCampaigns(projectID:$projectID,onlyActive:$onlyActive){
            campaignID
            lang
            name
            active
            contactCount(lang:$lang)
            activeContactCount(lang:$lang)
            uniqueContactCount(lang:$lang)
            contactCountWithoutUnsubscribed(lang:$lang)
        }
    }
`;

export const GET_CAMPAIGNS_WITH_BASIC_DATA = gql`
    query AllCampaigns($projectID:ID,$onlyActive:Boolean){
        AllCampaigns(projectID:$projectID,onlyActive:$onlyActive){
            campaignID
            name
        }
    }
`;

export const DELETE_CAMPAIGNS = gql`
    mutation DeleteCampaigns($campaignIDs:ID) {
        DeleteCampaigns(campaignIDs:$campaignIDs)
    }
`;

export const COPY_CAMPAIGN = gql`
    mutation CopyCampaign($campaignID:ID) {
        CopyCampaign(campaignID:$campaignID)
    }
`;