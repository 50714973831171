import { useState } from 'react';
import Button from "../../../GlobalComponents/Button";
import NoItems from "../../../GlobalComponents/NoItems";
import InputCard from "../../../GlobalComponents/InputCard";
import emailIcon from '../../../Media/Images/Icons/email.webp';
import { useTranslation } from "react-i18next";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import List from '../../../GlobalComponents/List/List';
import AddEditCampaignContact from './AddEditCampaignContact';
import { useApolloClient } from '@apollo/client';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import UseGetAllCampaignContacts from '../Library/UseGetAllCampaignContacts';
import UseDeleteCampaignContacts from '../Library/UseDeleteCampaignContacts';
import ModalFilterPanel from '../../../GlobalComponents/Modal/ModalFIlterPanel';
import CampaignContactFilter from './CampaignContactFilter';

const CampaignContacts = ({
    selectedCampaignID
}) => {

    const client = useApolloClient();
    const {t} = useTranslation();

    const [showAddContact, SetShowAddContact] = useState(false);
    const [showFilterSidebar, SetShowFilterSidebar] = useState(false);

    const {
        content,
        variables,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading,
        error,
        headerData,
        selectedCampaignContactIDs,
        allLanguages,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        FilterContacts
    } = UseGetAllCampaignContacts(selectedCampaignID);

    
    const {
        showDeleteNotification,
        ShowDeleteNotification,
        DeleteCampaignContacts
    } = UseDeleteCampaignContacts(selectedCampaignContactIDs,(d) => UpdateList(d));

    const UpdateList = async () => {

        SetShowAddContact(false);
        await client.refetchQueries({
            include: ["GetCampaignContacts"],
        });
    }

    return(
        <div>
            {selectedCampaignID && selectedCampaignID != 0 ?

                <>
                    {error ? 
                        <Error className="no-margin" text={GetApolloErrorText(error)} />
                    :
                        <InputCard
                            title = {t("Emails")}
                            icon = {emailIcon}
                            noBodyPadding={true}
                            headerChildren = {
                                <>
                                    <Button
                                        className="btn-blue narrow"
                                        OnClick = {() => SetShowFilterSidebar(true)}
                                    >
                                        {t("Filter")}     
                                    </Button>
                                    <Button
                                        className="btn-primary narrow"
                                        OnClick = {() => SetShowAddContact(true)}
                                    >
                                        {t("Add")}     
                                    </Button>
                                
                                    <Button
                                        className="btn-brown narrow"
                                        OnClick = {() => ShowDeleteNotification(true)}
                                    >
                                        {t("UnsubscribeSelected")}     
                                    </Button>
                                </>
                            }
                        > 
                            <List 
                                headerData = {headerData} 
                                contentData = {content}
                                loading = {loading}
                                OnSelectAll = {(e) => SelectAll(e)}
                                hideOptions={true}
                                totalContentLength = {totalContentLength}
                                paggingOffset = {paggingOffset}
                                paggingLimit = {paggingLimit}
                                FetchMore = {FetchMore}
                                GoToPage = {GoToPage}
                                options = {{
                                    OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                                }}
                            /> 

                            {showFilterSidebar ?
                                <ModalFilterPanel
                                    OnClose = {() => SetShowFilterSidebar(false)}
                                >
                                    <CampaignContactFilter
                                        OnFilter = {(filterData) => FilterContacts(filterData)}
                                        allLanguages = {allLanguages}
                                        variables = {variables}
                                    />
                                </ModalFilterPanel>
                            :null}

                        </InputCard>
                    }

                    {showAddContact ?
                        <AddEditCampaignContact
                            campaignID = {selectedCampaignID}
                            allLanguages = {allLanguages}
                            SetShowAddContact = {SetShowAddContact}
                            OnAddEditSuccess = {() => UpdateList()}
                        />
                    :null}

                    {showDeleteNotification ?
                        <ModalNotification 
                            yesNo={true} 
                            text={t("DoYouReallyWantToDeleteEmails")} 
                            CallBack={DeleteCampaignContacts} 
                        />
                    :null}
                </>
            :
                <NoItems
                    className = {"no-margin justify-content-center"}
                    text={t("ToAddEmailsSaveBasicInformationsFirst")} 
                />   
            }
        </div>
    )
}

export default CampaignContacts;