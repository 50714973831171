import { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Input from '../../../GlobalComponents/Input';
import Select from '../../../GlobalComponents/Select';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import { TINY_MCE_TOOLBAR_ADVANCED } from '../../../Config';
import InputCard from '../../../GlobalComponents/InputCard';
import Button from '../../../GlobalComponents/Button';
import List from '../../../GlobalComponents/List/List';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import userIcon from '../../../Media/Images/Icons/user.webp';
import UseEditCustomerRoles from '../../Membership/Library/UseEditCustomerRoles';
import ChooseCustomerRoles from './ChooseCustomerRoles';

Modal.setAppElement('#root');

const BasicInformations = ({
    formData,
    allLanguages,
    SetFormData,
    FillFormSelectedItemsWithObj,
    SetFormDataSelectedItemsWithObj
}) => {

    const {t} = useTranslation();
    const [showAddCustomerRoles,SetShowAddCustomerRoles] = useState(false);

    const {headerData,content} = UseEditCustomerRoles(formData.selectedCustomerRoles);

    return(
        <>
            <div className="row">
                <div className="col-33">
                    <div className="form-group">
                        <label>{t("FirstName")}</label>
                        <Input
                            name="name"
                            value={formData.name}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                </div>
                <div className="col-33">
                    <div className="form-group">
                        <label>{t("Surname")}</label>
                        <Input
                            name="surname"
                            value={formData.surname}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                </div>
                <div className="col-33">
                    <div className="form-group">
                        <label>{t("Language")}</label>
                        <Select
                            name="lang"
                            value={formData.lang}
                            OnChange={(e) => SetFormData(e)}
                        >
                            <option value={""}>{" - - - " + t("choose") + " - - - "}</option>
                            {allLanguages.map((item) => (
                                <option 
                                    key={item.languageID}
                                    value={item.suffix}
                                >{item.suffix}</option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Email")}</label>
                        <Input
                            name="email"
                            value={formData.email}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                </div>
                <div className="col-50">
                    <div className="form-group">
                        <label>{t("Tel")}</label>
                        <Input
                            name="tel"
                            value={formData.tel}
                            OnChange={(e) => SetFormData(e)}
                        />
                    </div>
                </div>  
                <div className="col-100">
                    <div className="form-group">
                        <label>{t("InternalNote")}</label>
                        <TinyMCEEditor 
                            value = {formData.internalNote}
                            OnEditorChange={(content) => SetFormData("internalNote",content)}
                            toolbar = {TINY_MCE_TOOLBAR_ADVANCED}
                            useMediaEditor={false}
                        />
                    </div>
                </div>                            
            </div>

            <InputCard
                title = {t("CustomerRoles")}
                icon = {userIcon}
                noBodyPadding={true}
                headerChildren = {
                    <Button 
                        OnClick = {(e) => {
                            e.stopPropagation();
                            SetShowAddCustomerRoles(true)
                        }}
                        className="btn-primary narrow"
                    >
                        {formData.selectedCustomerRoles && formData.selectedCustomerRoles.length > 0 ? 
                            t("Edit") 
                        : 
                            t("Choose")
                        }
                    </Button>
                }
            >   
                <List 
                    headerData = {headerData} 
                    headerClassName = {"no-top-border"}
                    contentData = {content}
                    isSortable = {false}
                    loading = {false}
                    replaceDotsImg = {cancelIcon}
                    ReplaceDotsOnClick = {(data) => SetFormDataSelectedItemsWithObj("deleted","selectedCustomerRoles","customerRoleID",data.rowID,true)}
                />
                
            </InputCard>  

            {showAddCustomerRoles ? 
                <ChooseCustomerRoles 
                    formData = {formData}
                    SetShowAddCustomerRoles = {SetShowAddCustomerRoles}
                    FillFormSelectedItemsWithObj = {FillFormSelectedItemsWithObj}
                />
            :null}
        </>
    )
}

export default BasicInformations;