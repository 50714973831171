const Move = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g id="Move_1_">
                    <g id="Scroll">
                        <path 
                            style={{"fill":secondaryColor}}  
                            d="M360.995,123.991c-2.519,0-5.05-0.859-7.119-2.618L241,25.436l-112.876,95.937    c-4.628,3.935-11.57,3.37-15.505-1.258c-3.934-4.629-3.371-11.571,1.258-15.506l120-101.991c4.107-3.49,10.14-3.49,14.247,0    l120,101.991c4.629,3.935,5.192,10.877,1.258,15.506C367.206,122.674,364.11,123.991,360.995,123.991z"/>
                        <path 
                            style={{"fill":secondaryColor}}  
                            d="M241,482c-2.535,0-5.07-0.873-7.123-2.618l-120-101.991c-4.629-3.935-5.192-10.877-1.258-15.506    c3.935-4.629,10.878-5.191,15.505-1.258L241,456.563l112.876-95.936c4.629-3.934,11.571-3.371,15.506,1.258    c3.935,4.629,3.371,11.571-1.258,15.506l-120,101.991C246.07,481.127,243.535,482,241,482z"/>
                    </g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M241,332c-50.178,0-91-40.822-91-91s40.822-91,91-91s91,40.822,91,91S291.177,332,241,332z M241,172   c-38.047,0-69,30.953-69,69s30.953,69,69,69s69-30.953,69-69S279.047,172,241,172z"/>
                </g>
            </svg>
        </>
    )
}

export default Move;