import { useApolloClient } from "@apollo/client";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GET_SELECTED_CATEGORIES } from "../Queries/contentManager";
import { GetApolloErrorText,ConvertHtmlToText } from "../../../GlobalFunctions/helper";

const UseEditTopCategories = (
    lang,
    selectedCategories
) => {

    const client = useApolloClient();
    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [loading,SetLoading] = useState(false);
    const [error,SetError] = useState("");

    useEffect(() => {

        if(selectedCategories)
        {
            const contentData = GetContentData(selectedCategories);
            SetContent(contentData);
        }

    },[selectedCategories])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        }
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            
            var cData = {
                data:[
                    {
                        value:
                            <span 
                                className="cursor-help"
                                title={ConvertHtmlToText(val.name)}
                            >
                                {ConvertHtmlToText(val.name)}
                            </span>,
                        className:"flex-1 one-line-with-dots"
                    }
                ],
            }

            cData.rowID         = val.linkID;
            cData.selected      = false;

            contentData.push(cData)
            
        }

        return contentData;
    }

    const GetSelectedCategories = async (selectedCategories,projectID) => {

        var newSelectedCategories = {[projectID]:[]};

        if(selectedCategories && selectedCategories.length > 0)
        {
            SetLoading(true);
            
            try
            {
                var linkListData = await client.query({
                    query: GET_SELECTED_CATEGORIES,
                    variables:{
                        lang:lang,
                        linkIDs: selectedCategories
                    },
                    fetchPolicy:"network-only"
                });

                if(linkListData && linkListData.data.AllPlainLinksByLinkIDs)
                    for(let val of linkListData.data.AllPlainLinksByLinkIDs)
                    {
                        newSelectedCategories[projectID].push({
                            linkID:val.linkID,
                            name:val.name
                        })
                    }
                SetLoading(false);
            }
            catch(err)
            {
                SetLoading(false);
                SetError(GetApolloErrorText(err));
            }
        }

        return newSelectedCategories;
    }

    return{
        headerData,
        content,
        loading,
        error,
        GetSelectedCategories
    }

}

export default UseEditTopCategories;