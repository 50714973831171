import {useQuery} from '@apollo/client';
import { GET_RESERVATION_PLACES } from '../Queries/reservationPlace';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import YesNo from '../../../GlobalComponents/YesNo';

const UseGetAllReservationPlaces = (lang) => {

    const [variables,SetVariables] = useState({
        lang,
        onlyActive:false
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedReservationPlaceIDs,SetSelectedReservationPlaceIDs] = useState([]);

    const {data,loading,error,refetch} = useQuery(GET_RESERVATION_PLACES,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllReservationPlaces);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("InternalName"),
            className:"flex-1"
        },
        {
            value: t("Active"),
            className:"static text-center"
        }        
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var cData = {
                data:[
                    {
                        elm:"lis",
                        backgroundColor: val.backgroundColor,
                        text: val.internalName,
                        className:"flex-1 no-padding-right"
                    },
                    {
                        value:<YesNo isYes = {!!val.active} />,
                        className:"static text-center"
                    }
                ],
            }

            cData.rowID    = val.reservationPlaceID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,reservationPlaceID) => {

        var checked = e.target.checked;
        var newSelectedReservationPlaceIDs = [...selectedReservationPlaceIDs];
        const newList = SelectReservationPlaceID(content,reservationPlaceID,checked,newSelectedReservationPlaceIDs)

        SetContent(newList);
        SetSelectedReservationPlaceIDs(newSelectedReservationPlaceIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedReservationPlaceIDs = [];
        const newList = SelectReservationPlaceID(content,"all",checked,newSelectedReservationPlaceIDs)

        SetSelectedReservationPlaceIDs(newSelectedReservationPlaceIDs);
        SetContent(newList);
    }

    const SelectReservationPlaceID = (list,reservationPlaceID,checked,selectedReservationPlaceIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(reservationPlaceID == newList[i].rowID || reservationPlaceID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedReservationPlaceIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedReservationPlaceIDs.length; j++)
                    {
                        if(selectedReservationPlaceIDs[j] == newList[i].rowID)
                            selectedReservationPlaceIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectReservationPlaceID(newList[i].subData,reservationPlaceID,checked,selectedReservationPlaceIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        refetch();
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedReservationPlaceIDs,
        SelectRow,
        SelectAll,
        UpdateList
    }

}

export default UseGetAllReservationPlaces;