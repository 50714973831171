import ListContent from "./ListContent";
import ListSortableContent from "./ListSortableContent";
import ListHeader from "./ListHeader";
import Loading from '../Loading';
import NoItems from "../NoItems";
import { useTranslation } from 'react-i18next';
import Button from "../Button";

const List = ({
    headerData,
    contentData,
    addRowButtonTitle,
    AddRowButtonOnClick,
    totalContentLength,
    paggingOffset,
    paggingLimit,
    options,
    hideOptions,
    optionsTitle,
    optionsClassName,
    headerClassName,
    rowClassname,
    isSortable,
    loading,
    FetchMore,
    GoToPage,
    OnDragListRowEnd,
    OnSelectAll,
    replaceDotsImg,
    ReplaceDotsOnClick
}) => {

    const {t} = useTranslation();

    var filteredContent;
    if(contentData)
        filteredContent = contentData.filter((item) => item.deleted == undefined || item.deleted == false)

    return(
        <div className = "list">
            {headerData ?
                <ListHeader 
                    data = {headerData} 
                    headerClassName = {headerClassName}
                    OnSelectAll = {OnSelectAll}
                    hideOptions = {hideOptions}
                    optionsTitle = {optionsTitle}
                    optionsClassName = {optionsClassName}
                    isSortable = {isSortable}
                />
            :null}
            <div className="list-content">
                {loading || !filteredContent ? 
                    <Loading />
                :
                    (filteredContent && filteredContent.length > 0 ?
                        (isSortable ?
                            <ListSortableContent 
                                rowClassname = {rowClassname}
                                addRowButtonTitle = {addRowButtonTitle}
                                AddRowButtonOnClick = {AddRowButtonOnClick}
                                data = {contentData} 
                                options = {options} 
                                hideOptions = {hideOptions}
                                optionsClassName = {optionsClassName}
                                isSortable = {true} 
                                level = {1}
                                OnDragEnd = {OnDragListRowEnd}
                                replaceDotsImg = {replaceDotsImg}
                                ReplaceDotsOnClick = {ReplaceDotsOnClick}
                                index = {-1}
                            />
                        :
                            <ListContent 
                                rowClassname = {rowClassname}
                                addRowButtonTitle = {addRowButtonTitle}
                                AddRowButtonOnClick = {AddRowButtonOnClick}
                                data = {contentData} 
                                options = {options} 
                                hideOptions = {hideOptions}
                                optionsClassName = {optionsClassName}
                                level = {1}
                                totalContentLength = {totalContentLength}
                                paggingOffset = {paggingOffset}
                                paggingLimit = {paggingLimit}
                                FetchMore = {FetchMore}
                                GoToPage = {GoToPage}
                                replaceDotsImg = {replaceDotsImg}
                                ReplaceDotsOnClick = {ReplaceDotsOnClick}
                                index = {-1}
                            />
                        )
                    :
                        (addRowButtonTitle && AddRowButtonOnClick ?
                            <div className={"list-row"}>
                                <Button
                                    className="btn-primary narrow"
                                    OnClick={() => AddRowButtonOnClick(1,0)}
                                >{addRowButtonTitle}</Button>
                            </div>
                        :
                            <NoItems 
                                text={t("NoItems")} 
                            />
                        )
                        
                    )
                }
            </div>
        </div>
    )
}

export default List;