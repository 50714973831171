import ContentHeader from "../../../GlobalComponents/ContentHeader";
import List from "../../../GlobalComponents/List/List";
import UseGetAllLanguages from "../Library/Language/UseGetAllLanguages";
import { useState } from "react";
import Error from "../../../GlobalComponents/Error";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import AddEditLanguage from "./AddEditLanguage";
import { useTranslation } from "react-i18next";

const Languages = () => {

    const {t} = useTranslation();
    const [selectedLanguageID, SetSelectedLanguageID] = useState(null);

    const {
        content,
        loading,
        error,
        headerData,
    } = UseGetAllLanguages();    

    return(
        <>
            {error ?
                <Error text={GetApolloErrorText(error)} />
            :
                <>
                    <ContentHeader
                        title = {t("LanguageList")}
                    />
                    <List 
                        headerData = {headerData} 
                        contentData = {content}
                        isSortable = {false}
                        loading = {loading}
                        options = {{
                            OnEditClick: (languageID) => SetSelectedLanguageID(languageID)
                        }}
                    />
                </>
            }

            {selectedLanguageID || selectedLanguageID == 0 ?
                <AddEditLanguage 
                    selectedLanguageID = {selectedLanguageID}
                    SetSelectedLanguageID = {SetSelectedLanguageID}
                />
            :null}
        </>
    )
}

export default Languages;