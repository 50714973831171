import Modal from 'react-modal';
import { useQuery } from '@apollo/client';
import Button from '../../../../GlobalComponents/Button';
import ModalHeader from '../../../../GlobalComponents/Modal/ModalHeader';
import Checkbox from '../../../../GlobalComponents/Checkbox';
import { useTranslation } from 'react-i18next';
import { UseAuth } from '../../../Auth/Library/UseAuth';
import Loading from '../../../../GlobalComponents/Loading';
import Error from '../../../../GlobalComponents/Error';
import { GET_MANUFACTURERS } from '../../Queries/manufacturers';

const ChooseManufacturers = ({
    formData,
    SetShowAddManufacturer,
    FillFormSelectedItemsWithObj
}) => {

    const {t} = useTranslation();

    const {user} = UseAuth();

    var lang = user ? user.lang : "";

    const {data,loading,error} = useQuery(GET_MANUFACTURERS,{
        variables:{
            lang: lang,
            limit:1000000,
            offset:0
        },
        fetchPolicy:"network-only",
        skip:(lang == "" ? true : false)
    })

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddManufacturer(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("ChooseManufacturer")}
                OnClose ={() => SetShowAddManufacturer(false)}
            />

            <div className="modal-body">

                {error ?
                    <Error text={GetApolloErrorText(error)} />
                :
                    (loading ?
                        <Loading />
                    :
                        <>
                            {data.AllManufacturers && data.AllManufacturers.length > 0 ?
                                
                                <div className='row'>

                                    {data.AllManufacturers.map((item) => {

                                        var check = false;
                                        for(let val of formData.selectedManufacturers)
                                        {
                                            if(val.manufacturerID == item.manufacturerID)
                                                check = true;
                                        }

                                        return(
                                            <div key={item.manufacturerID} className="col-20">
                                                <div className="form-group">
                                                    <Checkbox 
                                                        name = "manufacturers"
                                                        id = {"m-" + item.manufacturerID}
                                                        checked = {check}
                                                        OnChange={() => FillFormSelectedItemsWithObj("selectedManufacturers","manufacturerID",{
                                                            name:item.name,
                                                            manufacturerID:item.manufacturerID,
                                                            percentage:0
                                                        })}
                                                        text = {item.name}
                                                    /> 
                                                </div>
                                            </div>
                                        )
                                        
                                    })}
                                    
                                </div>
                                
                            :null}

                        </>
                    )
                }

            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => SetShowAddManufacturer(false)}
                >
                    {t("Choose")}
                </Button>
            </div>

        </Modal>
    )

}

export default ChooseManufacturers;