import {useQuery} from '@apollo/client';
import { GET_QUESTIONNAIRES } from '../Queries/questionnaire';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const UseGetAllQuestionnaires = (projectID,lang) => {

    const [variables,SetVariables] = useState({
        lang,
        projectID
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedQuestionnaireIDs,SetSelectedQuestionnaireIDs] = useState([]);

    const {data,loading,error,refetch} = useQuery(GET_QUESTIONNAIRES,{
        fetchPolicy:"network-only",
        variables,
        skip:(lang == "" ? true : false)
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        if(data)
        {
            const contentData = GetContentData(data.AllQuestionnaires);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("InternalName"),
            className:"flex-1"
        }       
    ];

    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var data = [
               
                {
                    value:val.internalName,
                    className:"flex-1"
                }
                
            ];

            var cData = {data}

            cData.rowID    = val.questionnaireID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,questionnaireID) => {

        var checked = e.target.checked;
        var newSelectedQuestionnaireIDs = [...selectedQuestionnaireIDs];
        const newList = SelectQuestionnaireID(content,questionnaireID,checked,newSelectedQuestionnaireIDs)

        SetContent(newList);
        SetSelectedQuestionnaireIDs(newSelectedQuestionnaireIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedQuestionnaireIDs = [];
        const newList = SelectQuestionnaireID(content,"all",checked,newSelectedQuestionnaireIDs)

        SetSelectedQuestionnaireIDs(newSelectedQuestionnaireIDs);
        SetContent(newList);
    }

    const SelectQuestionnaireID = (list,questionnaireID,checked,selectedQuestionnaireIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(questionnaireID == newList[i].rowID || questionnaireID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedQuestionnaireIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedQuestionnaireIDs.length; j++)
                    {
                        if(selectedQuestionnaireIDs[j] == newList[i].rowID)
                            selectedQuestionnaireIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectQuestionnaireID(newList[i].subData,questionnaireID,checked,selectedQuestionnaireIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        refetch();
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedQuestionnaireIDs,
        SelectRow,
        SelectAll,
        UpdateList
    }

}

export default UseGetAllQuestionnaires;