const UseTimes = () => {

    const GetTimesObject = (data) => {

        const obj = {
            reservationPlaceTimeID: (data ? data.reservationPlaceTimeID : 0),
            timeFrom: (data ? data.timeFrom : 0),
            timeTo: (data ? data.timeTo : 0),
            selectedVariations:(data ? data.variations : [])
        }

        return obj;
    }

    return{
        GetTimesObject
    }
}

export default UseTimes;