import React, { useState, useContext, createContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { GET_SETTINGS } from './Modules/Settings/Queries/settings';
import { GET_All_PROJECT_DATA } from './GlobalQueries/modules';

const sesttingsContext = createContext();
const projectContext = createContext();

export const AdminProvider = ({children}) => {

    const settings = UseSettingsProvider();
    settings.RefetchSettings();

    const project = UseProjectProvider();

    return(
        <sesttingsContext.Provider value={settings}>
            <projectContext.Provider value={project}>
                {children}
            </projectContext.Provider>
        </sesttingsContext.Provider>
    )
}

export const UseSettings = () => {
    return useContext(sesttingsContext);
}
export const UseProject = () => {
    return useContext(projectContext);
}

const UseSettingsProvider = () => {

    const client = useApolloClient()

    const [settings,SetSettings] = useState({});

    const RefetchSettings = () => {

        client.query({
            query:GET_SETTINGS,
            fetchPolicy: 'network-only',
        }).then((d) => {
            
            if(d.data.Settings)
                SetSettings(d.data.Settings);

        }).catch((err) => console.log(err))
    }

    return {
        settings,
        RefetchSettings
    }

}

const UseProjectProvider = () => {

    const client = useApolloClient()

    const [projectID,SetProjectID] = useState(0);
    const [modules,SetModules] = useState([]);
    const [selectedProject,SetSelectedProject] = useState(null);

    useEffect(() => {
        
        if(projectID)
        {
            client.query({
                query:GET_All_PROJECT_DATA,
                fetchPolicy: 'network-only',
                variables:{
                    projectID
                }
            }).then((d) => {
                
                if(d.data.AllProjectModules)
                    SetModules(d.data.AllProjectModules);

                if(d.data.ProjectBy)
                    SetSelectedProject(d.data.ProjectBy);
    
            }).catch((err) => console.log(err))
        }
        else
        {
            SetModules([]);
            SetSelectedProject(null);
        }
    },[projectID])

    const IsModulEnabled = (moduleID) => {

        var enabled = false;
        for(let val of modules)
        {
            if(val.moduleID == moduleID)
                enabled = true;
        }

        return enabled;
    }

    return {
        modules,
        selectedProject,
        SetProjectID,
        IsModulEnabled
    }

}