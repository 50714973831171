import { useQuery } from "@apollo/client";
import { useState } from "react";
import { CompleteLangsArray } from "../../../../GlobalFunctions/helper";
import { GET_LABEL } from "../../Queries/labels";

const UseGetLabel = (selectedLabelID,lang,CallBack) => {

    const [allLanguageMutations, SetAllLanguageMutations] = useState([]);
    const {loading,error} = useQuery(GET_LABEL,{
        variables:{
            labelID:selectedLabelID,
            lang
        },
        fetchPolicy:"network-only",
        onCompleted:(d) => {

            const emptyLang = {
                name:"",                    
            }

            if(selectedLabelID != 0)
            {
                let langs = CompleteLangsArray(d.LabelWithLangs.langs,d.AllLanguageMutations,emptyLang);

                CallBack({
                    selectedLang: lang,
                    active:       d.LabelWithLangs.active,
                    isPlace:      d.LabelWithLangs.isPlace,
                    langs:        langs,
                    
                });
            }
            else
            {
                let langs = CompleteLangsArray([],d.AllLanguageMutations,emptyLang);

                CallBack({
                    langs:langs
                });
            }

            SetAllLanguageMutations(d.AllLanguageMutations);
        }
    });

    return{
        loading: (loading),
        error: (error),
        allLanguageMutations
    }

}

export default UseGetLabel;