import gql from 'graphql-tag';

export const ADD_EDIT_RESERVATION_PLACE_VARIATION = gql`
    mutation AddEditReservationPlaceVariation(
        $reservationPlaceVariationID:ID,
        $langs: [ReservationPlaceVariationLangsInput],
        $generateTranslations:GenerateReservationPlaceVariationTranslationsInput
    ){
        AddEditReservationPlaceVariation(
            reservationPlaceVariationID:$reservationPlaceVariationID,
            langs:$langs,
            generateTranslations:$generateTranslations
        ){
            reservationPlaceVariationID
        }
    }
`;

export const GET_RESERVATION_PLACE_VARIATION = gql`
    query ReservationPlaceVariationWithLangs($reservationPlaceVariationID: ID){
        ReservationPlaceVariationWithLangs(reservationPlaceVariationID:$reservationPlaceVariationID){
            reservationPlaceVariationID            
            langs{
                lang
                name
            }    
        }
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

export const GET_RESERVATION_PLACE_VARIATIONS = gql`
    query AllReservationPlaceVariations(
        $lang: String
    ){
        AllReservationPlaceVariations(
            lang:$lang
        ){
            reservationPlaceVariationID
            name
        }
    }
`;

export const DELETE_RESERVATION_PLACE_VARIATIONS = gql`
    mutation DeleteReservationPlaceVariations($reservationPlaceVariationIDs:ID) {
        DeleteReservationPlaceVariations(reservationPlaceVariationIDs:$reservationPlaceVariationIDs)
    }
`;

export const GENERATE_RESERVATION_PLACE_VARIATION_LANG = gql`
    mutation GenerateReservationPlaceVariationLang(
        $reservationPlaceVariationID:ID,
        $lang:String,
        $isEdit:Boolean
        $onlyForParams:[String]
    ) {
        GenerateReservationPlaceVariationLang(
            reservationPlaceVariationID:$reservationPlaceVariationID,
            lang:$lang,
            isEdit:$isEdit
            onlyForParams:$onlyForParams
        )
    }
`;