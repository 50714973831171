import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UseNotification } from "../Modules/Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { GetApolloErrorText } from "../GlobalFunctions/helper";

const UseDeletePhotos = (query,paramIDs,OnSuccess) => {

    const {t} = useTranslation();
    const {AddNotification} = UseNotification();

    const [showDeleteNotification,SetShowDeleteNotification] = useState(false);
    const [selectedItems, SetSelectedItems] = useState([]);

    const [DelPhotos] = useMutation(query,{
        onCompleted:(d) => {

            AddNotification(t("TheyAreGone"),true);
            if(OnSuccess)
                OnSuccess(d);
            
        },
        onError:(err) => {          
            AddNotification(GetApolloErrorText(err),false);
        }
    });

    const ShowDeleteNotification = (value) => {

        if(selectedItems.length > 0)
            SetShowDeleteNotification(value)
        else
            AddNotification(t("NoSelectedImages"),false);
    }

    const DeletePhotos = (action) => {

        if(action)
        {       
            DelPhotos({
                variables:{
                    [paramIDs]:selectedItems
                }
            })
        }

        SetShowDeleteNotification(false);
    }

    const SelectPhoto = (photoID) => {

        var arr = [...selectedItems];

        var check = false;
        for(let i in arr)
        {
            if(photoID == arr[i])
            {
                check = true;
                arr.splice(i,1);
            }
        }

        if(!check)
            arr.push(photoID);

            SetSelectedItems(arr);
    }

    return{
        selectedItems,
        showDeleteNotification,
        ShowDeleteNotification,
        DeletePhotos,
        SelectPhoto
    }
}

export default UseDeletePhotos;