import { useMutation } from "@apollo/client";
import { UPDATE_PRODUCT_VARIANT_PRIORITY,GET_PRODUCT_VARIANTS } from "../../Queries/products";
import { GetApolloErrorText } from "../../../../GlobalFunctions/helper";
import { UseNotification } from "../../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';

const UseSortProductVariant = (client,lang,productID) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    
    const [UpdateLinkPriority] = useMutation(UPDATE_PRODUCT_VARIANT_PRIORITY,{
        onCompleted:(d) => {

            const notifyText = t("NewPositionSaved");
            AddNotification(notifyText,true);

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const Reorder = (list, startIndex, endIndex) => {
        
        var newVariants = [...list];
    
        const [removed] = newVariants.splice(startIndex, 1);
        newVariants.splice(endIndex, 0, removed);
      
        return newVariants;
    };

    const OnDragEnd = (result) => {

        if(result.source && result.destination)
        {
            const variables = {
                lang,
                productID:productID
            }

            const { AllProductVariants } = client.readQuery({ query: GET_PRODUCT_VARIANTS, variables:variables });
            const newArray = Reorder(AllProductVariants,result.source.index,result.destination.index);

            client.writeQuery({ 
                query:GET_PRODUCT_VARIANTS,
                variables:variables,
                data:{
                    AllProductVariants: newArray
                } 
            });

            UpdateLinkPriority({
                variables:{
                    productVariantID: result.draggableId,
                    productID: productID,
                    fromIndex:result.source.index,
                    toIndex:  result.destination.index
                }
            })
        }
    } 

    return {
        OnDragEnd
    }

}

export default UseSortProductVariant;