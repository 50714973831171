import gql from 'graphql-tag';

export const GET_ALL_CUSTOMER_ROLES = gql`
    query AllCustomerRoles{
        AllCustomerRoles{
            customerRoleID
            projectID
            name
            backgroundColor
            project{
                favicon
            }
        }
    }
`;

export const GET_CUSTOMER_ROLE = gql`
    query CustomerRole($customerRoleID:ID){
        CustomerRole(customerRoleID:$customerRoleID){
            customerRoleID
            projectID
            name
            portalModules{
                portalModuleID
            }
        }
        AllPortalModulesBy(param:"parentPortalModuleID",value:0)
        {
            portalModuleID
            label
            link
            subPortalModule{
                portalModuleID
                label
                link
            }
        }  
        AllProjects{
            projectID
            name
        } 
    }
`;

export const ADD_EDIT_CUSTOMER_ROLE = gql`
    mutation AddEditCustomerRole(
        $customerRoleID:ID,
        $projectID:ID,
        $name:String,
        $portalModules:[ID]
    ){
        AddEditCustomerRole(
            customerRoleID:$customerRoleID,
            projectID:$projectID,
            name:$name, 
            portalModules:$portalModules
        ){
            customerRoleID
        }
    }
`;

export const DELETE_CUSTOMER_ROLES = gql`
    mutation DeleteCustomerRoles($customerRoleIDs:ID) {
        DeleteCustomerRoles(customerRoleIDs:$customerRoleIDs)
    }
`;

