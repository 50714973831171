const Arrow = ({
    className,
    size,
    color,
    OnClick
}) => {

    const Click = () => {
        if(OnClick)
            OnClick()
    }
    var colors = ["#bbbbbb","#dddddd"];
    if(color)
        colors = color.split("-");
    else
        color = "#bbbbbb-#dddddd";

    return(
        <>
            {size == "big" ?
                <svg 
                    onClick= {() => Click()}
                    className={className} 
                    width="50" 
                    height="59" 
                    viewBox="0 0 50 59" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path 
                        fillRule="evenodd" 
                        clipRule="evenodd" 
                        d="M50 6.73049L11.5051 29.25L50 51.7695L50 58.5L-5.09325e-06 29.25L50 0L50 6.73049Z" 
                        fill={colors.length > 1 ? "url(#"+color+")" : colors[0]}
                    />
                    {colors.length > 1 ?
                        <defs>
                            <linearGradient 
                                id={color} 
                                x1="-2.23115e-06" 
                                y1="58.5" 
                                x2="1.02682" 
                                y2="-0.847327" 
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor = {colors[0]}/>
                                <stop offset="1" stopColor = {colors[1]}/>
                            </linearGradient>
                        </defs>
                    :null}
                </svg>
            :null}

            {size == "thin-small" ?
                <svg 
                    onClick= {() => Click()}
                    className={className} 
                    width="23" 
                    height="21" 
                    viewBox="0 0 50 59" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path 
                        fillRule="evenodd" 
                        clipRule="evenodd" 
                        d="M50 6.73049L11.5051 29.25L50 51.7695L50 58.5L-5.09325e-06 29.25L50 0L50 6.73049Z" 
                        fill={colors.length > 1 ? "url(#"+color+")" : colors[0]}
                    />
                    {colors.length > 1 ?
                        <defs>
                            <linearGradient 
                                id={color} 
                                x1="-2.23115e-06" 
                                y1="58.5" 
                                x2="1.02682" 
                                y2="-0.847327" 
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor = {colors[0]}/>
                                <stop offset="1" stopColor = {colors[1]}/>
                            </linearGradient>
                        </defs>
                    :null}
                </svg>
            :null}

            {size == "small" ?
                <svg 
                    onClick= {() => Click()}
                    className={className} 
                    width="23" 
                    height="21" 
                    viewBox="0 0 23 21" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path 
                        fillRule="evenodd" 
                        clipRule="evenodd" 
                        d="M17.895 20.25L11.6826 8.85205L5.47008 20.25L0.781515 20.25L11.6826 0.249999L22.5836 20.25L17.895 20.25Z" 
                        fill={colors.length > 1 ? "url(#"+color+")" : colors[0]}
                    />
                    {colors.length > 1 ?
                        <defs>
                            <linearGradient 
                                id={color} 
                                x1="5.52279" 
                                y1="11.3138" 
                                x2="24.7064" 
                                y2="20.6278" 
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor = {colors[0]}/>
                                <stop offset="1" stopColor = {colors[1]}/>
                            </linearGradient>
                        </defs>
                    :null}
                </svg>
            :null}
        </>
    )
}

export default Arrow;