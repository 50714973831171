import Checkbox from "../Checkbox";
import dots from '../../Media/Images/Icons/dots.svg';
import { useState } from "react";
import ListRowOptions from "./ListRowOptions";
import Input from "../Input";
import RadioButton from "../RadioButton";
import DatePicker from "../DatePicker";
import noImageIcon from '../../Media/Images/Icons/no_image.webp';
import ListInitialShorcut from "./ListInitialShorcut";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import AllIcons from "../Icons/AllIcons";

const ListRow = ({
    rowClassname,
    parentIndex,
    data,
    options,
    hideOptions,
    optionsClassName,
    level,
    provided,
    isSortable,
    replaceDotsImg,
    paggingOffset,
    index,
    ReplaceDotsOnClick
}) => {

    const {t} = useTranslation();
    const [showOptions, SetShowOptions] = useState(false);

    return(
        
        <div 
            className={"list-row" + (rowClassname ? " " + rowClassname : "")}
            onClick = {(e) => options && options.OnRowClick && options.OnRowClick(e,data)}
        >
            {options && options.OnSelect ?
                (data.allowDelete == undefined || data.allowDelete == true ?
                    <Checkbox 
                        name="select"
                        checked = {data.selected}
                        OnChange={(e) => options.OnSelect(e,data)}
                        style={{marginRight : (level === 1 ? 0 : (level - 1) * 1.5) + "rem"}}
                    />  
                :
                    <div className="empty-checkbox"></div>
                )
            :
                (isSortable ?
                    <div style={{marginRight : (level === 1 ? 0 : (level - 1) * 1.5) + "rem"}}></div>
                :null)
            }

            {isSortable ?
                <span {...provided.dragHandleProps}>
                    <AllIcons name = "move" className={"move" + (!options || !options.OnSelect ? " no-left-margin" : "")} />
                </span>
            :null}

            {data.data && data.data.map((item,index) => {

                return(
                    <div key={index} className={"list-row-column " + item.className}>

                        {item.hideWhenSelectedIs === undefined || item.hideWhenSelectedIs != data.selected ?
                            <>
                                {item.elm == "img" ?
                                    <div className = "img aspect-ratio-16-9">
                                        {item.src != "" ?
                                            <img src = {item.src} title = {item.title ? item.title : ""} />
                                        :
                                            <img src = {noImageIcon} />
                                        }
                                    </div>
                                    
                                :null}
                                {item.elm == "input" ?
                                    <Input
                                        OnChange = {item.OnChange}
                                        name = {item.name}
                                        value = {data[item.param]}
                                        type = {item.type}
                                        placeholder = {item.placeholder ? item.placeholder : ""}
                                        append = {item.append}
                                    />
                                :null}

                                {item.elm == "select" ?
                                    <Select
                                        OnChange = {item.OnChange}
                                        name = {item.name}
                                        value = {item.value}
                                        append = {item.append}
                                    >
                                        {item.options && item.options.length > 0 ?
                                            (item.options.map((it) => {
                                                return(
                                                    <option
                                                        key={it[item.optionsParamValue]}
                                                        value={it[item.optionsParamValue]}
                                                    >{(item.translateOptionsName ? t(it[item.optionsParamName]) : it[item.optionsParamName])}</option>
                                                )
                                            }))
                                        :null}
                                    </Select>
                                :null}

                                {item.elm == "checkbox" ?
                                    <Checkbox
                                        id = {data.rowID}
                                        OnChange = {item.OnChange}
                                        name = {item.name}
                                        checked = {data[item.param]}
                                    />
                                :null}

                                {item.elm == "datepicker" ?
                                    <DatePicker
                                        value = {data[item.param]}
                                        showPast = {true}
                                        OnChange = {item.OnChange}
                                    />
                                :null}

                                {item.elm == "radio" ?
                                    <RadioButton
                                        id = {data.rowID}
                                        OnChange = {item.OnChange}
                                        name = {item.name}
                                        checked = {data[item.param]}
                                    />
                                :null}

                                {item.elm == "lis" ?
                                    <ListInitialShorcut
                                        backgroundColor = {item.backgroundColor}
                                        text = {item.text}
                                        photo = {item.photo}
                                    />
                                :null}

                                {!item.elm ?
                                    item.value
                                :null}
                            </>
                        :null}
                    </div>
                )
            })}

            {!hideOptions ?
                <div className={"static options text-center" + (optionsClassName ? " " + optionsClassName : "")}>
                    {data.allowEdit == undefined || data.allowEdit == true ?
                        <span className="dots" onClick={() => ReplaceDotsOnClick ? ReplaceDotsOnClick(data,parentIndex,index) : SetShowOptions(!showOptions)}>
                            <img src={replaceDotsImg ? replaceDotsImg : dots} />
                        </span>
                    :null}
                </div>
            :null}

            {showOptions ?
                <>
                    <ListRowOptions
                        options = {options}
                        data = {data}
                        SetShowOptions = {SetShowOptions}
                        priority = {index + paggingOffset + 1}
                    />
                    <div className="list-row-options-overlay" onClick={() => SetShowOptions(false)}></div>
                </>
            :null}
        </div>
    )
}

export default ListRow;