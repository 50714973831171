import gql from 'graphql-tag';

export const GET_ALL_LANGUAGES = gql`
    query AllLanguageMutations{
        AllLanguageMutations(onlyEnabled:false){
            languageID
            title
            suffix
            file
            enabled
            langTitle
        }
    }
`;

export const GET_ALL_ENABLED_LANGUAGES = gql`
    query AllLanguageMutations{
        AllLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            enabled
            langTitle
        }
    }
`;

export const GET_LANGUAGE = gql`
    query LanguageMutationByID($languageID:ID){
        LanguageMutationByID(languageID:$languageID){
            languageID
            title
            suffix
            file
            enabled
            langTitle
        }
    }
`;

export const ADD_EDIT_LANGUAGE = gql`
    mutation AddEditLanguage(
        $languageID:ID
        $enabled:Int
    ){
        AddEditLanguage(
            languageID:$languageID,
            enabled:$enabled
        ){
            languageID
            enabled
        }
    }
`;