import { useEffect } from "react";
import UseReservationPlaceCalendar from "../../../Library/UseReservationPlaceCalendar";
import Calendar from "../../../../Calendar/Components/Calendar";
import { GetTimeInString, GetTimeInMinutes } from "../../../../../GlobalFunctions/helper";
import { useTranslation } from "react-i18next";
import Loading from "../../../../../GlobalComponents/Loading";
import Warning from "../../../../../GlobalComponents/Warning";
import Select from "../../../../../GlobalComponents/Select";

const ReservationPlaceCalendar = ({
    initData,
    projectData,
    reservationPlaceID,
    membershipID,
    lang,
    OnDataChange,
    OnHasVariations
}) => {

    const {t} = useTranslation();

    const {
        timesLoading,
        reservedDatetimes,
        formData,
        selectedReservationPlace,
        reservationPlaceTimeVariations,
        SelectReservationDate,
        GetAllBlockedDays,
        SelectReservationPlaceTime,
        SelectReservationPlaceTimeVariation
    } = UseReservationPlaceCalendar(reservationPlaceID,membershipID,projectData.projectID,lang,OnDataChange,OnHasVariations);

    useEffect(() => {
        if(initData && selectedReservationPlace)
        {
            if(initData.reservationDate && initData.reservationDate != formData.reservationDate)
                SelectReservationDate(initData.reservationDate);

            if(initData.reservationPlaceTimeID && initData.reservationPlaceTimeID != formData.reservationPlaceTimeID)
                SelectReservationPlaceTime(initData.reservationPlaceTimeID,initData.reservationPlaceVariationID)

        }
    },[initData,selectedReservationPlace])

    //třídy row, cm-50, atd. jsou tady z zoho důvodu, že se tato komponenta používá i v přidávání rezervací
    return(
        <div className="row cm-row cm-wrap cols-padding-10 cm-reservation-place-calendar reservation-place-calendar">
            <div className = "col-50 cm-col cm-col-50 cm-col-xxs-100">
                <div className="cm-form-group-xxs">
                    <Calendar 
                        callendarClassName  = "with-green-buttons"
                        key                 = {membershipID + "-" + reservationPlaceID}
                        arrowColor          = {projectData.buttonBgColor}
                        showLegend          = {true} 
                        GetBlockedDays      = {(month,year) => GetAllBlockedDays(month,year)}
                        OnSelectDate        = {(_,date) => SelectReservationDate(date)}
                        selectedDate        = {formData.reservationDate}
                        showPast            = {false}
                        isFloating          = {true}
                    />
                </div>
            </div>
            <div className = "col-50 cm-col cm-col-50 cm-col-xxs-100">
 
                <h4 className="text-left">{t("ReservationTime")}</h4>
                {formData.reservationDate && selectedReservationPlace ?
                    <>
                        {timesLoading ? 
                            <Loading />
                        :
                            <>
                                <div className="cm-form-group">
                                    {selectedReservationPlace.times.map((item) => {
                                        
                                        var blocked = false;
                                        for(let val of reservedDatetimes)
                                        {
                                            var d = val.date.split(" ");
                                            
                                            if(GetTimeInMinutes(d[1]) == item.reservationPlaceTime.timeFrom)
                                                blocked = true;
                                        }

                                        if(selectedReservationPlace.times.length == 1)
                                        {
                                            if(blocked)
                                                SelectReservationPlaceTime(0);
                                            else
                                                SelectReservationPlaceTime(item.reservationPlaceTimeID);  
                                        }

                                        return(
                                            <div className={"calendar-time" + (blocked ? " blocked" : "") +  (item.reservationPlaceTimeID == formData.reservationPlaceTimeID ? " selected" : "")} 
                                                key={item.reservationPlaceTimeID}
                                                onClick = {() => !blocked && SelectReservationPlaceTime(item.reservationPlaceTimeID)}
                                            >
                                                {GetTimeInString(item.reservationPlaceTime.timeFrom) + " - " + GetTimeInString(item.reservationPlaceTime.timeTo)}
                                            </div>
                                        )
                                    })}
                                </div>
                                {reservationPlaceTimeVariations.length > 0 ?
                                    <>
                                        
                                        <h4 className="text-left">{t("Place")}</h4>
                                        <Select
                                            name={"reservationPlaceVariationID"}
                                            value = {formData.reservationPlaceVariationID}
                                            OnChange = {(e) => SelectReservationPlaceTimeVariation(e)}
                                        >
                                            <option value={0}> - - {t("choose")} - - </option>
                                            {reservationPlaceTimeVariations.map((item) => (
                                                <option 
                                                    key={item.reservationPlaceVariationID}
                                                    value={item.reservationPlaceVariationID}
                                                >{item.reservationPlaceVariation.name}</option>
                                            ))}
                                        </Select>
                                        
                                    </>
                                :null}
                            </>
                        }
                    </>
                :
                    <Warning text={t("ChooseFreeDateFirstFromCalendar")} />
                }
            </div>
            
        </div>
    )
}

export default ReservationPlaceCalendar;