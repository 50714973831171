import { useEffect,useState } from "react";
import { useMutation,useQuery } from "@apollo/client";
import UseFormHandle from "../../../GlobalHooks/UseFormHandle";
import { GetApolloErrorText } from "../../../GlobalFunctions/helper";
import { UseNotification } from "../../Notification/Library/UseNotification";
import { useTranslation } from 'react-i18next';
import { ADD_EDIT_ARTICLE,GENERATE_ARTICLE_LANG } from "../Queries/article";
import UseGenerateOtherTranslations from "../../../GlobalHooks/UseGenerateOtherTranslations";
import { UseSettings } from "../../../AdminProvider";

const UseAddEditArticle = (selectedArticleID,mainLang,allLanguageMutations,OnCompleted) => {

    const {AddNotification} = UseNotification();
    const {t} = useTranslation();
    const {settings} = UseSettings();

    const [selectedProjectID, SetSelectedProjectID] = useState(0);
    
    const {
        formData, 
        SetFormData, 
        SetCompleteFormData,
        GetFormLangValue,
        SetFormLangData,
        OpenImage,
        FillFormSelectedItemsWithObj,
        SetFormDataSelectedItemsWithObjToArray,
        FillFormSelectedItemsWithObjToArray,
        RemoveFormDataSelectedItems
    } = UseFormHandle({
        selectedLang:mainLang,
        photo:                          null,
        generateRandomRecommended:      1,
        alone:                          0,
        withoutMainImage:               0,
        publishAllLangs:                0,
        unPublishAllLangs:              0,
        sendNewsletterWhenPublishing:   0,
        selectedArticles:[],
        selectedProjects:[],
        selectedProjectsForPublication:[],
        selectedProjectsForUnPublication:[],
        selectedMarketingBanners:[],
        selectedLinks:{},
        langs:[],
        generateTranslations:{
            enabled: (settings.useThirdPartyForTransaltion != "0" ? true : false),
            onlyForParams:[],
            onlyFor:""
        },
    });

    const {
        loading:generateLoading,
        error:generateError,
        translatedLangsCount,
        translatedLang,
        GenerateOtherTranstalions
    } = UseGenerateOtherTranslations(GENERATE_ARTICLE_LANG,allLanguageMutations);

    useEffect(() => {

        if(mainLang)
            SetFormData("selectedLang",mainLang);
        
    },[mainLang])

    useEffect(() => {

        if(formData.selectedProjects && formData.selectedProjects.length > 0)
            SetSelectedProjectID(formData.selectedProjects[0].projectID);
        else
            SetSelectedProjectID(0);
        
    },[formData.selectedProjects])

    const [AddEditArticleMutation,{error,loading}] = useMutation(ADD_EDIT_ARTICLE,{
        onCompleted:async (d) => {

            var success = true;
            if(formData.generateTranslations.enabled && allLanguageMutations.length > 1)
            {
                success = await GenerateOtherTranstalions({
                    articleID: d.AddEditArticle.articleID,
                    isEdit: (selectedArticleID === 0 ? false : true),
                    onlyForParams:formData.generateTranslations.onlyForParams
                });
            }

            if(success)
            {   
                if(OnCompleted)
                    OnCompleted(d.AddEditArticle);
                
                const notifyText = (selectedArticleID != 0 ? t("SuccessfullyUpdated") : t("NewArticleIsInTheWorld"));
                AddNotification(notifyText,true);
            }
            else
            {
                const notifyText = t("ArticleHasNotAllLangMutations");
                AddNotification(notifyText,false);
            }

        },
        onError:(err) => {
            AddNotification(GetApolloErrorText(err),false);  
        }
    });

    const AddEditArticle = () => {

        var checkTitle = false;
        var checkPerex = false;
        var checkText = false;
        var checkLinks = true;

        for(const pr of formData.selectedProjects)
        {
            if(!formData.selectedLinks[pr.projectID])
                checkLinks = false;
            else{
                var check = false;
                for(let val of formData.selectedLinks[pr.projectID])
                {
                    if(!val.deleted)
                        check = true;
                }
                if(!check)
                    checkLinks = false;
            }
        }
   
        let langs = [];
        for(const lang of formData.langs)
        {
            if(lang.title)
                checkTitle = true;

            if(lang.text)
                checkText = true;

            if(lang.perex)
                checkPerex = true;

            langs.push({
                lang:            lang.lang,
                title:           lang.title,
                perex:           lang.perex,
                text:            lang.text,
                publishDate:     lang.publishDate,
                metaTitle:       lang.metaTitle,
                metaKeywords:    lang.metaKeywords,
                metaDescription: lang.metaDescription
            });
        }

        if(checkTitle)
        {
            if(checkLinks)
            {
                if(checkPerex)
                {
                    if(checkText)
                    {
                        AddEditArticleMutation({
                            variables:{
                                articleID:                  selectedArticleID,
                                langs:                      langs,
                                links:                      GetSelectedLinks(formData.selectedLinks),
                                recommnededArticles:        GetSelectedArticles(formData.selectedArticles),
                                marketingBanners:           GetSelectedMarketingBanners(formData.selectedMarketingBanners),
                                photo:                      formData.photo,
                                generateRandomRecommended:  (formData.generateRandomRecommended ? 1 : 0),
                                alone:                      formData.alone,
                                withoutMainImage:           formData.withoutMainImage,
                                generateTranslations:       formData.generateTranslations
                            }
                        }) 
                    }
                    else
                        AddNotification(t("ArticleTextNeedsToBeFilled"),false); 
                }
                else
                    AddNotification(t("ArticlePerexNeedsToBeFilled"),false);    
            }
            else
                AddNotification(t("SomeProjectHasNotChoosenLinks"),false);    
        } 
        else
            AddNotification(t("ArticleTitleNeedsToBeFilled"),false);  
    } 

    const GetSelectedLinks = (selectedLinks) => {

        
        var links = [];
        for(let i in selectedLinks)
        {
            var check = false;
            for(let val of formData.selectedProjects)
            {
                if(i == val.projectID)
                    check = true;
            }

            for(let val of selectedLinks[i])
            {
                var obj = {
                    linkID: val.linkID,
                    projectID: i
                }
    
                if(val.add)
                    obj.add = true; 
                if(val.deleted || !check)
                    obj.deleted = true; 
                if(val.updated)
                    obj.updated = true; 
    
                links.push(obj);
            }
        }

        return links;
    }

    const GetSelectedArticles = (selectedArticles) => {

        var articles = [];
        for(let val of selectedArticles)
        {
            articles.push(val.articleID)
        }

        return articles;
    }

    const GetSelectedMarketingBanners = (selectedMarketingBanners) => {

        var marketingBanners = [];
        for(let val of selectedMarketingBanners)
        {
            marketingBanners.push(val.marketingBannerID)
        }

        return marketingBanners;
    }

    const InitFormData = (data) => {
        SetCompleteFormData(data);
    }

    return {
        error: error || generateError,
        loading: loading || generateLoading,
        formData,
        translatedLangsCount,
        translatedLang,
        selectedProjectID,
        AddEditArticle,
        SetFormData,
        InitFormData,
        OpenImage,
        GetFormLangValue,
        SetFormLangData,
        FillFormSelectedItemsWithObj,
        SetSelectedProjectID,
        SetFormDataSelectedItemsWithObjToArray,
        FillFormSelectedItemsWithObjToArray,
        RemoveFormDataSelectedItems
    }

}

export default UseAddEditArticle;