import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddMultipleProducts from '../Library/UseAddMultipleProducts';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import { UseAuth } from '../../Auth/Library/UseAuth';
import List from '../../../GlobalComponents/List/List';
import UseGetAllProducts from '../../Eshop/Library/Products/UseGetAllProducts';
import ProductFilter from '../../Eshop/Components/Products/ProductFilter';
import { useState } from 'react';
import ModalFilterPanel from '../../../GlobalComponents/Modal/ModalFIlterPanel';
import { useApolloClient } from '@apollo/client';

Modal.setAppElement('#root');

const AddMultipleProducts = ({linkID,SetShowAddMultipleProducts}) => {
    
    const {user} = UseAuth();
    const {t} = useTranslation();
    const client = useApolloClient();

    const [showFilter,SetShowFilter] = useState(false);

    const {
        content,
        totalContentLength,
        paggingOffset,
        paggingLimit,
        loading: getLoading,
        error:getError,
        headerData,
        selectedProductIDs,
        allLinks,
        FetchMore,
        GoToPage,
        SelectRow,
        SelectAll,
        FilterProducts
    } = UseGetAllProducts(user.lang);

    const {
        loading,
        error,
        AddRelatedProduct,
    } = UseAddMultipleProducts(linkID,selectedProductIDs,async (d) => {
        await client.refetchQueries({
            include: ["AllProducts"],
        });
        SetShowAddMultipleProducts(false);
    });

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetShowAddMultipleProducts(false)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {t("AddProducts")}
                OnClose ={() => SetShowAddMultipleProducts(false)}
                children={
                    <Button 
                        OnClick = {() => SetShowFilter(!showFilter)}
                        className="btn-blue"
                    >{t("Filter")}</Button>
                }
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {showFilter ?
                            <ModalFilterPanel
                                OnClose = {() => SetShowFilter(false)}
                            >
                                <ProductFilter
                                    allLinks = {allLinks}
                                    OnFilter = {(filterData) => FilterProducts(filterData)}
                                />
                            </ModalFilterPanel>
                        :null}

                        <div className="modal-body-no-padding">
                            <List 
                                headerClassName={"no-top-border"}
                                headerData = {headerData} 
                                contentData = {content}
                                totalContentLength = {totalContentLength}
                                paggingOffset = {paggingOffset}
                                paggingLimit = {paggingLimit}
                                FetchMore = {FetchMore}
                                GoToPage = {GoToPage}
                                isSortable = {false}
                                loading = {loading || getLoading}
                                hideOptions = {true}
                                OnSelectAll = {(e) => SelectAll(e)}
                                options = {{
                                    OnSelect: (e,rowData) => SelectRow(e,rowData.rowID),
                                }}
                            />
                        </div> 
                        
                    </>
                }
            </div>
            <div className="modal-footer">
                <Button
                    className={"btn-primary w-100"}
                    OnClick={() => AddRelatedProduct()}
                >
                    {t("Add")}
                </Button>
            </div>

        </Modal>
    )
}

export default AddMultipleProducts;