const Diary = ({className,primaryColor,secondaryColor}) => {

    return(
        <>
            <svg 
                className={className}
                x="0px" 
                y="0px" 
                viewBox="0 0 512 512" 
                width="512" 
                height="512" 
            >
                <g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M443.054,128.743h-56.929c-6.075,0-11-4.925-11-11V79.946h-37.797c-6.075,0-11-4.925-11-11V11h22    v46.946h37.797c6.075,0,11,4.925,11,11v37.797h45.929V128.743z"
                        />
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M348.328,501h-22v-57.946c0-6.075,4.925-11,11-11h37.797v-37.797c0-6.075,4.925-11,11-11h56.929v22    h-45.929v37.797c0,6.075-4.925,11-11,11h-37.797V501z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M402.41,267c-6.075,0-11.005-4.925-11.005-11s4.92-11,10.995-11h0.01c6.075,0,11,4.925,11,11S408.485,267,402.41,267z"/>
                    </g>
                    <path 
                        style={{"fill":primaryColor}} 
                        d="M454.054,179.958V11c0-6.075-4.925-11-11-11H52.681c-6.075,0-11,4.925-11,11v71.345H28.282c-6.075,0-11,4.925-11,11v81.327   c0,6.075,4.925,11,11,11h13.398v140.656H28.282c-6.075,0-11,4.925-11,11v81.327c0,6.075,4.925,11,11,11h13.398V501   c0,6.075,4.925,11,11,11h390.373c6.075,0,11-4.925,11-11V332.042c22.498-0.448,40.664-18.875,40.664-41.477v-69.129   C494.718,198.833,476.551,180.406,454.054,179.958z M63.681,22h43.062v69.748c-8.402-5.916-18.63-9.403-29.664-9.403H63.681V22z    M39.282,104.345h37.796c16.357,0,29.664,13.307,29.664,29.663c0,16.357-13.307,29.664-29.664,29.664H39.282V104.345z    M63.681,185.672h13.398c11.034,0,21.262-3.488,29.664-9.403v159.463c-8.402-5.915-18.63-9.403-29.664-9.403H63.681V185.672z    M39.282,348.328h37.797c16.356,0,29.664,13.308,29.664,29.664c0,16.356-13.307,29.663-29.664,29.663H39.282V348.328z    M63.681,429.655h13.398c11.033,0,21.261-3.488,29.664-9.403V490H63.681V429.655z M128.743,490V22h303.311v157.938H402.39   c-41.941,0-76.062,34.121-76.062,76.062s34.122,76.062,76.062,76.062h29.664V490H128.743z M472.718,290.565   c0,10.751-8.747,19.498-19.498,19.498h-50.83c-29.81,0-54.062-24.252-54.062-54.062s24.253-54.062,54.062-54.062h50.83   c10.751,0,19.498,8.747,19.498,19.498V290.565z"/>
                    <g>
                        <path 
                            style={{"fill":primaryColor}} 
                            d="M304.797,128.743H190.938c-6.075,0-11-4.925-11-11c0-6.075,4.925-11,11-11h113.859c6.075,0,11,4.925,11,11    C315.797,123.818,310.872,128.743,304.797,128.743z"/>
                    </g>
                    <g>
                        <path 
                            style={{"fill":secondaryColor}} 
                            d="M288.531,193.805h-81.328c-6.075,0-11-4.925-11-11s4.925-11,11-11h81.328c6.075,0,11,4.925,11,11    S294.606,193.805,288.531,193.805z"/>
                    </g>
                </g>
            </svg>
        </>
    )
}

export default Diary;