import Modal from 'react-modal';
import Button from '../../../GlobalComponents/Button';
import ModalHeader from '../../../GlobalComponents/Modal/ModalHeader';
import { useTranslation } from 'react-i18next';
import UseAddEditQuestionnaire from '../Library/UseAddEditQuestionnaire';
import UseGetQuestionnaire from '../Library/UseGetQuestionnaire';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import { GetApolloErrorText } from '../../../GlobalFunctions/helper';
import TranslationProgressBar from '../../../GlobalComponents/TranslationProgressBar';
import { UseAuth } from '../../Auth/Library/UseAuth';
import Label from '../../../GlobalComponents/Label';
import TinyMCEEditor from '../../TinyMCEEditor/Components/TinyMCEEditor';
import Input from '../../../GlobalComponents/Input';
import { TINY_MCE_TOOLBAR_MEDIA } from '../../../Config';
import NoItems from '../../../GlobalComponents/NoItems';
import InputCard from '../../../GlobalComponents/InputCard';
import List from '../../../GlobalComponents/List/List';
import cancelIcon from '../../../Media/Images/Icons/cancel.webp';
import UseEditQuestionnaire from '../Library/UseEditQuestionnaire';

Modal.setAppElement('#root');

const AddEditQuestionnaire = ({
    selectedQuestionnaireID,
    SetSelectedQuestionnaireID,
    OnAddEditSuccess,
    projectID
}) => {

    const {t} = useTranslation();
    const {user} = UseAuth();
    
    var itemsToTranslate = [
        {name:"Uvítací zpráva",param:"welcomeMessage"},
        {name:"Položky dotazníku",param:"questionnaireSteps"}
    ];

    const {
        allLanguageMutations,
        allQuestionTypes,
        mainLang,
        loading:getLoading,
        error:getError
    } = UseGetQuestionnaire(selectedQuestionnaireID,projectID,user.lang,(d) => {
        InitFormData(d);
    });

    const {
        loading,
        error,
        formData,
        translatedLangsCount,
        translatedLang,
        AddEditQuestionnaire,
        InitFormData,
        SetFormData,
        GetFormLangValue,
        SetFormLangData,
    } = UseAddEditQuestionnaire(selectedQuestionnaireID,projectID,mainLang.suffix,allLanguageMutations,(d) => OnAddEditSuccess(d));

    const {
        content,
        headerData,
        AddQuestionnaireItem,
        RemoveQuestionnaireStep,
        OnDragEnd
    } = UseEditQuestionnaire(
        formData.questionnaireSteps,
        allQuestionTypes,
        allLanguageMutations,
        formData.selectedLang,
        SetFormData
    );

    var hideHeaderAndFooter = (translatedLangsCount != 0 ? true :  false);

    var err = "";
    if(error || getError)
        err = GetApolloErrorText(error || getError)

    return(
        <Modal
            isOpen={true}
            onRequestClose={() => SetSelectedQuestionnaireID(null)}
            overlayClassName={"modal-overlay"}
            className={"modal-content"}
        >
            <ModalHeader 
                title = {selectedQuestionnaireID === 0 ? t("AddQuestionnaire") : t("EditQuestionnaire")}
                hideContent = {hideHeaderAndFooter}
                isEdit = {selectedQuestionnaireID === 0 ? false : true}
                allLanguages = {allLanguageMutations}
                OnClose ={() => SetSelectedQuestionnaireID(null)}
                selectedLang = {formData.selectedLang}
                OnLangSelect = {(e) => SetFormData(e)}
                generateTranslations = {formData.generateTranslations}
                itemsToTranslate = {itemsToTranslate}
                OnGenerateSelect = {(e) => {
                    SetFormData("generateTranslations",{...formData.generateTranslations,enabled:e.target.checked});
                    SetFormData("selectedLang",mainLang.suffix);
                }}
                OnSelectTranslationItems = {(params) => SetFormData("generateTranslations",{...formData.generateTranslations,onlyForParams:params})}
                
            />

            <div className="modal-body">

                {err ?
                    <Error className="no-margin" text={err} />
                :
                    <>
                        {loading || getLoading ?
                            <>  
                                {translatedLangsCount != 0 ?
                                    <TranslationProgressBar
                                        translatedLang = {translatedLang}
                                        translatedLangsCount = {translatedLangsCount}
                                        totalLangs = {allLanguageMutations.length}
                                    />
                                :
                                    <Loading />
                                }
                            </>
                        :  
                            <>
                                <div className="form-group">
                                    <label>*{t("InternalName")}</label>
                                    <Input
                                        type = "text"
                                        name="internalName"
                                        value={formData.internalName}
                                        OnChange={(e) => SetFormData(e)}
                                    />
                                </div> 
                                <div className="form-group">
                                    <Label 
                                        text = {t("WelcomeMessage") + " (" + formData.selectedLang + ")"} 
                                        infoContent={[
                                            t("WelcomeMessageInfo"),
                                        ]}
                                    />
                                    <TinyMCEEditor 
                                        value = {GetFormLangValue("welcomeMessage")}
                                        OnEditorChange={(content) => SetFormLangData("welcomeMessage",content)}
                                        toolbar = {TINY_MCE_TOOLBAR_MEDIA}
                                        useMediaEditor={true}
                                    />
                                </div>  
                                <InputCard
                                    title = {t("QuestionnaireItems")}
                                    noBodyPadding={true}
                                >

                                    <List 
                                        headerData = {headerData} 
                                        contentData = {content}
                                        isSortable = {true}
                                        loading = {false}
                                        replaceDotsImg = {cancelIcon}
                                        OnDragListRowEnd={(e) => OnDragEnd(e)}
                                        ReplaceDotsOnClick = {(_,parentIndex,index) => RemoveQuestionnaireStep(parentIndex,index)}
                                        addRowButtonTitle = {t("Add")}
                                        AddRowButtonOnClick = {(level,index) => AddQuestionnaireItem(level,index,1)}
                                       
                                    />
                                      
                                </InputCard>                                                 
                            </>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!hideHeaderAndFooter ?
                    <Button
                        className={"btn-primary w-100"}
                        OnClick={() => AddEditQuestionnaire()}
                    >
                        {(selectedQuestionnaireID === 0 ? t("Add") : t("Edit"))}
                    </Button>
                :null}
            </div>

        </Modal>
    )
}

export default AddEditQuestionnaire;