import { useTranslation } from "react-i18next";
import { GetResponsiveDimensions } from "../../../../GlobalFunctions/helper";

const WindowWidthResponziveInfo = ({size}) => {

    const {t} = useTranslation();

    return(
        <div className="responzive-info">
            {t("DeviceWindowWidth")}: {GetResponsiveDimensions(size)}
        </div>
    )
}

export default WindowWidthResponziveInfo;