import {useQuery} from '@apollo/client';
import { GET_MEMBERSHIP_TYPES } from '../Queries/membership';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import YesNo from '../../../GlobalComponents/YesNo';

const UseGetAllMembershipTypes = (membershipID,lang) => {

    const [variables,SetVariables] = useState({
        lang,
        membershipID
    });

    const {t} = useTranslation();
    const [content,SetContent] = useState(null);
    const [selectedMembershipTypeIDs,SetSelectedMembershipTypeIDs] = useState([]);

    const {data,loading,error,refetch} = useQuery(GET_MEMBERSHIP_TYPES,{
        variables:variables,
        fetchPolicy:"network-only",
        skip:(lang == "" || membershipID == 0 ? true : false)
    })

    useEffect(() => {

        if(lang)
            SetVariables({...variables,lang:lang})

    },[lang])

    useEffect(() => {

        console.log(data);
        if(data)
        {
            const contentData = GetContentData(data.AllMembershipTypes);
            SetContent(contentData);
        }

    },[data])

    var headerData = [
        {
            value: t("Name"),
            className:"flex-1"
        },
        {
            value: t("ShowForPurchaseOnWeb"),
            className:"static text-center"
        },
        {
            value: t("ShowForPurchaseInPortal"),
            className:"static text-center"
        }        
    ];


    const GetContentData = (data) => {

        var contentData = [];

        for(let val of data)
        {
            var data = [
                
                {
                    value:val.name,
                    className:"flex-1"
                },
                {
                    value:<YesNo isYes = {!!val.showForPurchaseOnWeb} />,
                    className:"static text-center"
                },
                {
                    value:<YesNo isYes = {!!val.showForPurchaseInPortal} />,
                    className:"static text-center"
                },
                
            ];

            var cData = {data}

            cData.rowID    = val.membershipTypeID;
            cData.selected = false;

            contentData.push(cData)
        }
        return contentData;
    }

    const SelectRow = (e,membershipTypeID) => {

        var checked = e.target.checked;
        var newSelectedMembershipTypeIDs = [...selectedMembershipTypeIDs];
        const newList = SelectMembershipTypeID(content,membershipTypeID,checked,newSelectedMembershipTypeIDs)

        SetContent(newList);
        SetSelectedMembershipTypeIDs(newSelectedMembershipTypeIDs);
    }

    const SelectAll = (e) => {

        var checked = e.target.checked;
        var newSelectedMembershipTypeIDs = [];
        const newList = SelectMembershipTypeID(content,"all",checked,newSelectedMembershipTypeIDs)

        SetSelectedMembershipTypeIDs(newSelectedMembershipTypeIDs);
        SetContent(newList);
    }

    const SelectMembershipTypeID = (list,membershipTypeID,checked,selectedMembershipTypeIDs) => {

        const newList = [...list];
        for(let i = 0; i < newList.length; i++)
        {
            if(membershipTypeID == newList[i].rowID || membershipTypeID === "all")
            {
                newList[i] = {...newList[i],selected:checked}
                if(checked)
                    selectedMembershipTypeIDs.push(newList[i].rowID);
                else
                {
                    for(let j = 0; j < selectedMembershipTypeIDs.length; j++)
                    {
                        if(selectedMembershipTypeIDs[j] == newList[i].rowID)
                            selectedMembershipTypeIDs.splice(j,1);  
                    } 
                }
            }

            if(newList[i].subData)
                newList[i] = {...newList[i],subData:SelectMembershipTypeID(newList[i].subData,membershipTypeID,checked,selectedMembershipTypeIDs)}
        }

        return newList;
    }

    const UpdateList = () => {
        refetch();
    }

    return{
        content,
        headerData,
        loading,
        error,
        selectedMembershipTypeIDs,
        SelectRow,
        SelectAll,
        UpdateList
    }

}

export default UseGetAllMembershipTypes;