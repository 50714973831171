export const GetProjectIDFromParam = (param) => {
    
    if(param.project)
    {
        var projectID = param.project.split("-");
        
        var re = /^\d+$/;
        if(re.test(projectID[0]))
            return projectID[0]
        else    
            return 0;
    }
    else
        return 0;

}

export const GetModuleLink = (module,selectedProject) => {
    
    var link = "";
    if(module.link && (!module.subModule || module.subModule.length == 0))
    {
        link = module.link;
        if(selectedProject)
        {
            link = selectedProject.projectID + "-" + selectedProject.niceName + module.link;
        }
    }

    return link;

}


